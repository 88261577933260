const keyDownArrowRight = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    gotoNextChapter,
  } = params

  if(!shiftKey && !modifierKey) {
    event.preventDefault()
    gotoNextChapter()
  }

}

export default keyDownArrowRight