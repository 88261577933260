import { memo } from 'react'
import styled from 'styled-components'
import { Virtuoso } from 'react-virtuoso'
import { i18n } from 'inline-i18n'

import useVersionInfos from '../../../hooks/useVersionInfos'

import Loading from '../../common/Loading'
import AddVersionsListLine from './AddVersionsListLine'
import SearchResultsError from '../../search/SearchResultsError'

const Container = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`

const HeaderSpacer = styled.div`
  height: 80px;
`

const FooterSpacer = styled.div`
  height: 200px;
`

const AddVersionsList = ({
  searchText,
}) => {

  const { selectedVersionInfos } = useVersionInfos()

  const searchTextWithNotIds = (
    selectedVersionInfos.length > 0
      ? `${searchText} not-id:"${selectedVersionInfos.map(({ id }) => id).sort().join(' ')}"`
      : searchText
  )

  const { count } = useVersionInfos({ searchText: searchTextWithNotIds })

  if(count === undefined) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  if(count === 0) {
    return (
      <Container>
        <SearchResultsError>
          {i18n("No versions found")}
        </SearchResultsError>
      </Container>
    )
  }

  return (
    <Container>

      <Virtuoso
        totalCount={count}
        itemContent={index => (
          <AddVersionsListLine
            key={index}
            index={index}
            searchTextWithNotIds={searchTextWithNotIds}
          />
        )}
        components={{
          Header: () => <HeaderSpacer />,
          Footer: () => <FooterSpacer />,
        }}
        tabIndex={-1}
      />

    </Container>
  )
}

export default memo(AddVersionsList)