import { gql } from '@apollo/client'

import channel_fields from '../fragments/channel_fields'

const channels = gql`
  query channels($query: String, $order: String, $offset: Int, $limit: Int) {
    channels(query: $query, order: $order, offset: $offset, limit: $limit) {
      channels {
        ${channel_fields}
      }
      count
    }
  }
`

export default channels