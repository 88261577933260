import React, { memo, useCallback, useRef, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useMeasure } from 'react-use'
import Slider from '@material-ui/core/Slider'

import useInstanceValue from '../../hooks/useInstanceValue'
import useEqualObjsMemo from '../../hooks/useEqualObjsMemo'

import SpanWithRemovedTabIndex from './SpanWithRemovedTabIndex'

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`

const ResizerDots = styled(Slider)`
  position: absolute;
  left: 1px;
  right: -3px;
  top: -1px;
  padding: 0;
  height: 0;
  z-index: 4;

  .MuiSlider-rail {
    display: none;
  }

  .MuiSlider-thumb {
    width: 11px;
    height: 11px;
    background-color: ${({ theme }) => theme.palette.grey[400]};
    border: 1px solid white;
    z-index: 2;
  }
`

const ResizableWidthContainer = ({
  defaultMinimumColumnWidth=0,
  onResizeComplete,
  children,
}) => {

  children = useMemo(() => React.Children.toArray(children).filter(React.isValidElement), [ children ])

  let [ ref, { width: totalWidth } ] = useMeasure()
  totalWidth = Math.round(totalWidth)  // Safari sometimes gives a slightly inaccurate decimal size for this
  const dragDirection = useRef()

  const [ resizingWidths, setResizingWidths ] = useState()
  const getResizingWidths = useInstanceValue(resizingWidths)

  const extraChildrenWidthProps = useEqualObjsMemo(
    children.map(({ props }) => ({
      hiddenWidth: props.hiddenWidth,
      minWidth: props.minWidth,
      xPositionAdjustment: props.xPositionAdjustment || 0,
    }))
  )

  const smallestPossibleWidths = useMemo(
    () => extraChildrenWidthProps.map(props => (
      props.hiddenWidth
      || props.minWidth
      || defaultMinimumColumnWidth
    )),
    [ extraChildrenWidthProps, defaultMinimumColumnWidth ],
  )

  const minWidths = useMemo(
    () => extraChildrenWidthProps.map(props => (
      props.minWidth
      || defaultMinimumColumnWidth
    )),
    [ extraChildrenWidthProps, defaultMinimumColumnWidth ],
  )

  const getMinNeededForRemainingColumns = useCallback(
    (idx, doReverse) => (
      (
        doReverse
          ? smallestPossibleWidths.slice().reverse()
          : smallestPossibleWidths
      )
        .slice(idx + 1)
        .reduce((total, smallestPossibleWidth) => total + smallestPossibleWidth, 0)
    ),
    [ smallestPossibleWidths ],
  )

  const getSnapWidthPerHiddenAndMinWidth = useCallback(
    ({ width, props }) => {
      const { minWidth=defaultMinimumColumnWidth, hiddenWidth } = props

      if(width < minWidth) {
        width = hiddenWidth || minWidth
      }

      return width
    },
    [ defaultMinimumColumnWidth ],
  )

  // adjust column widths according to total width
  let passedInColumnWidths = useEqualObjsMemo(children.map(({ props }) => props.width || 1))
  const columnWidths = useMemo(
    () => {
      if(resizingWidths) return resizingWidths

      const totalPassedInWidth = passedInColumnWidths.reduce((total, width) => total + width, 0)
      let totalRemaining = totalWidth

      const adjustedColumnWidths = passedInColumnWidths.map((width, idx) => {

        const minNeededForRemainingColumns = getMinNeededForRemainingColumns(idx)
        const maxAvailableWidth = totalRemaining - minNeededForRemainingColumns

        let updatedWidth = Math.min(
          parseInt((width / totalPassedInWidth) * totalWidth, 10),  // the proportionate width for this column
          maxAvailableWidth,
        )

        updatedWidth = getSnapWidthPerHiddenAndMinWidth({
          width: updatedWidth,
          props: extraChildrenWidthProps[idx],
        })

        totalRemaining -= updatedWidth

        return updatedWidth
      })

      adjustedColumnWidths[0] += totalRemaining

      return adjustedColumnWidths
    },
    [ resizingWidths, passedInColumnWidths, totalWidth, getMinNeededForRemainingColumns, getSnapWidthPerHiddenAndMinWidth, extraChildrenWidthProps ],
  )

  let total = 0
  const sliderValues = columnWidths.slice(0, -1).map((width, idx) => {
    total += width
    return total + extraChildrenWidthProps[idx].xPositionAdjustment
  })

  const getSliderValues = useInstanceValue(sliderValues)

  const onChange = useCallback(
    (event, values) => {

      const valuesWithoutXPositionAdjustment = values.map((value, idx) => (
        value - extraChildrenWidthProps[idx].xPositionAdjustment
      ))

      let lastValue = 0
      const allWidthsExceedMinimum = minWidths.every((minWidth, idx) => {
        const exceeds = (valuesWithoutXPositionAdjustment[idx] || totalWidth) - lastValue >= minWidth
        lastValue = valuesWithoutXPositionAdjustment[idx]
        return exceeds
      })

      if(!dragDirection.current || allWidthsExceedMinimum) {
        const sliderValues = getSliderValues()
        const getColIsHidden = idx => sliderValues[idx] - (sliderValues[idx-1] || 0) === extraChildrenWidthProps[idx].hiddenWidth
        sliderValues.some((oldValue, idx) => {
          if(oldValue !== values[idx]) {
            if(!dragDirection.current && getColIsHidden(idx)) {
              dragDirection.current = 'left'
            } else if(!dragDirection.current && getColIsHidden(idx+1)) {
              dragDirection.current = 'right'
            } else {
              dragDirection.current = values[idx] < oldValue ? 'left' : 'right'
            }
            return true
          }
          return false
        })
      }

      const adjustedValues = (
        dragDirection.current === 'left'
          ? valuesWithoutXPositionAdjustment.map(value => totalWidth - value).reverse()
          : valuesWithoutXPositionAdjustment
      )

      let total = 0
      const newColsInfo = []

      const setColInfo = ({
        preSnapWidth,
        idx,
      }) => {
        const width = getSnapWidthPerHiddenAndMinWidth({
          width: preSnapWidth,
          props: extraChildrenWidthProps[idx],
        })

        if(preSnapWidth > width) {
          const firstPrevNonHiddenColInfo = newColsInfo.slice().reverse().filter(({ isHidden }) => !isHidden)[0]
          if(firstPrevNonHiddenColInfo) {
            firstPrevNonHiddenColInfo.width += preSnapWidth - width
            total += preSnapWidth - width
          }
        }

        total += width
        newColsInfo.push({ width, isHidden: extraChildrenWidthProps[idx].hiddenWidth === width })
      }

      adjustedValues.forEach((value, idx) => {
        const origIdx = dragDirection.current === 'left' ? (adjustedValues.length - idx) : idx
        const remainingSpaceAfterRemainderGetMinimum = totalWidth - total - getMinNeededForRemainingColumns(idx, dragDirection.current === 'left')

        const preSnapWidth = Math.min(
          value - total,
          remainingSpaceAfterRemainderGetMinimum
        )

        setColInfo({
          preSnapWidth,
          idx: origIdx,
        })
      })

      setColInfo({
        preSnapWidth: totalWidth - total,
        idx: dragDirection.current === 'left' ? 0 : adjustedValues.length,
        maxAvailableWidth: totalWidth - total,
      })

      const newWidths = newColsInfo.map(({ width }) => width)

      if(dragDirection.current === 'left') newWidths.reverse()

      setResizingWidths(newWidths)
    },
    [ getSliderValues, minWidths, totalWidth, getMinNeededForRemainingColumns, getSnapWidthPerHiddenAndMinWidth, extraChildrenWidthProps, setResizingWidths ],
  )

  const onChangeCommitted = useCallback(
    () => {
      dragDirection.current = undefined
      onResizeComplete && onResizeComplete(getResizingWidths())
      setResizingWidths()
    },
    [ onResizeComplete, getResizingWidths, setResizingWidths ],
  )

  return (
    <Container ref={ref}>

      {!!totalWidth &&
        children.map((child, idx) => (
          React.cloneElement(child, { width: columnWidths[idx] })
        ))
      }

      {!!totalWidth && sliderValues.length > 0 &&
        <ResizerDots
          track={false}
          min={0}
          max={totalWidth}
          value={sliderValues}
          onChange={onChange}
          onChangeCommitted={onChangeCommitted}
          ThumbComponent={SpanWithRemovedTabIndex}
        />
      }

    </Container>
  )
}

export default memo(ResizableWidthContainer)