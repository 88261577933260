import { memo, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import UndoIcon from '@material-ui/icons/Undo'
import CloseIcon from '@material-ui/icons/Close'
import ReplayIcon from '@material-ui/icons/Replay'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import useInstanceValue from '../../hooks/useInstanceValue'

import { formattingColors, sketchUtensils } from '../../utils/formatting'

const FORMATTING_COLORS_WITH_BLACK = [ ...formattingColors, `black` ]

const Container = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  position: relative;
  margin: 5px 0 0;
`

const FakeIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Utensil = styled.div`
  background-color: black;
  width: 18px;
  margin: -2px;

  ${({ $utensil }) => ({
    MARKER: `
      height: 10px;
      border-radius: 5px;
    `,
    SHARPY: `
      height: 6px;
      border-radius: 5px;
    `,
    FOUNTAIN: `
      height: 3px;
      border-radius: 2px;
    `,
    BALLPOINT: `
      height: 1px;
    `,
    HIGHLIGHTER: `
      height: 10px;
      opacity: .2;
    `,
    MINIHIGHLIGHTER: `
      height: 6px;
      opacity: .2;
    `,
  })[$utensil]};
`

const Color = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 1px;
  background-color: ${({ theme, $color, $utensil }) => (theme.palette.markupColors[$color] || {})[$utensil === `HIGHLIGHTER` ? `highlight` : `arrow`] || $color};
`

const StyledReplayIcon = styled(ReplayIcon)`
  transform: rotate(180deg) scaleX(-1);
`

const StyledIconButton = styled(IconButton)`
  color: ${({ $color, theme }) => (theme.palette[$color] || {}).main || (theme.palette.markupColors[$color] || {}).line || $color || `black`};
  padding: 10px;

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
`

const StudyBiblePageEditorOptions = ({
  options,
  setOptions,
  svgStrokes,
  updateSvgStrokes,
  selectedStrokeIdx,
  setSelectedStrokeIdx,
  toggleThroughStrokes,
}) => {

  const { utensil=`MARKER`, color } = options

  const getSvgStrokes = useInstanceValue(svgStrokes)
  const getSelectedStrokeIdx = useInstanceValue(selectedStrokeIdx)

  const toggleUtensil = useCallback(
    () => {
      setOptions({
        ...options,
        utensil: sketchUtensils[(Math.max(0, sketchUtensils.indexOf(utensil)) + 1) % sketchUtensils.length],
      })
    },
    [ options, utensil, setOptions ],
  )

  const toggleColor = useCallback(
    () => {
      setOptions({
        ...options,
        color: FORMATTING_COLORS_WITH_BLACK[(Math.max(0, FORMATTING_COLORS_WITH_BLACK.indexOf(color)) + 1) % FORMATTING_COLORS_WITH_BLACK.length],
      })
    },
    [ options, color, setOptions ],
  )

  const deleteStroke = useCallback(
    () => {
      const selectedStrokeIdx = getSelectedStrokeIdx()
      const newSvgStrokes = [ ...getSvgStrokes() ]
      if(newSvgStrokes[selectedStrokeIdx]) {
        newSvgStrokes.splice(selectedStrokeIdx, 1)
        updateSvgStrokes(newSvgStrokes)
        setSelectedStrokeIdx(selectedStrokeIdx - 1)
      }
    },
    [ updateSvgStrokes, getSvgStrokes, getSelectedStrokeIdx, setSelectedStrokeIdx ],
  )

  const moveStrokeToStart = useCallback(
    () => {
      const selectedStrokeIdx = getSelectedStrokeIdx()
      const newSvgStrokes = [ ...getSvgStrokes() ]
      if(newSvgStrokes[selectedStrokeIdx]) {
        const selectedStroke = newSvgStrokes.splice(selectedStrokeIdx, 1)
        newSvgStrokes.unshift(selectedStroke[0])
        updateSvgStrokes(newSvgStrokes)
        setSelectedStrokeIdx()
      }
    },
    [ updateSvgStrokes, getSvgStrokes, getSelectedStrokeIdx, setSelectedStrokeIdx ],
  )

  const undo = useCallback(
    () => {
      updateSvgStrokes([ ...getSvgStrokes().slice(0,-1) ])
    },
    [ updateSvgStrokes, getSvgStrokes ],
  )

  const hasSelectedStroke = !!(svgStrokes || [])[selectedStrokeIdx]

  return (
    <Container>

      <StyledIconButton
        onClick={toggleUtensil}
      >
        <FakeIcon className="MuiSvgIcon-root">
          <Utensil
            $utensil={utensil}
          />
        </FakeIcon>
      </StyledIconButton>

      <StyledIconButton
        onClick={toggleColor}
        $color={color}
      >
        <FakeIcon className="MuiSvgIcon-root">
          <Color
            $color={color}
            $utensil={utensil}
          />
        </FakeIcon>
      </StyledIconButton>

      <StyledIconButton
        onClick={toggleThroughStrokes}
        disabled={(svgStrokes || []).length === 0}
      >
        <StyledReplayIcon />
      </StyledIconButton>

      {hasSelectedStroke &&
        <StyledIconButton
          onClick={deleteStroke}
        >
          <CloseIcon />
        </StyledIconButton>
      }

      {!hasSelectedStroke &&
        <StyledIconButton
          disabled={(svgStrokes || []).length === 0}
          onClick={undo}
        >
          <UndoIcon />
        </StyledIconButton>
      }

      <StyledIconButton
        onClick={moveStrokeToStart}
        disabled={!hasSelectedStroke || selectedStrokeIdx === 0}
      >
        <SkipPreviousIcon />
      </StyledIconButton>

    </Container>
  )
}

export default memo(StudyBiblePageEditorOptions)