import { gql } from '@apollo/client'

import { getGroupSubscriptionFields } from '../fragments/groupSubscription_fields'

export default gql
`
  mutation updateGroupSubscription($id: ID!, $input: GroupSubscriptionInput!) {
    updateGroupSubscription(id: $id, input: $input) {
      ${getGroupSubscriptionFields({ includeSubscriptions: false })}
    }
  }
`