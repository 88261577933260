import { useState, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { useMutation, useApolloClient } from '@apollo/client'

import useMutationContext from './useMutationContext'
import { getModuleTypeLabel } from '../utils/misc'

import FadedLoading from "../components/common/FadedLoading"
import CustomSnackbar from '../components/common/CustomSnackbar'

import restoreModuleMutation from '../graphql/mutations/restoreModule'

const useRestoreDeletedModuleByProject = ({
  projectId,
  goUpdateProject,
}) => {

  const [ restoreModule ] = useMutation(restoreModuleMutation)
  const context = useMutationContext()
  const client = useApolloClient()
  const history = useHistory()

  const [ snackbarInfo, setSnackbarInfo ] = useState({ open: false })
  const [ lockAndShowLoading, setLockAndShowLoading ] = useState(false)

  const goRestoreModuleByProject = useCallback(
    async moduleByProject => {

      const { ordering, module } = moduleByProject
      const { id } = module

      setLockAndShowLoading(true)

      await restoreModule({
        variables: {
          id,
          projectId,
          ordering,
        },
        context,
      })

      await client.refetchQueries({
        include: [ 'project' ],
        onQueryUpdated: ({ options: { variables }}) => projectId === variables.id,
      })

      if(module.type === 'NOTES') {
        goUpdateProject({ notesModuleId: id })
      }

      setLockAndShowLoading(false)

    },
    [ projectId, restoreModule, context, client, goUpdateProject ],
  )

  const onDeleteModuleByProject = useCallback(
    async ({ moduleByProject, project }) => {

      // requestAnimationFrame needed in case they delete two in a row
      // without this, the old closeSnackbar is called after the new setSnackbarInfo call
      await new Promise(resolve => requestAnimationFrame(resolve))

      const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
      const { label } = moduleByProject.module
      const snackbarInfo = {
        open: true,
        message: (
          project
            ? (
              i18n("{{label}} moved to {{project_name}}", {
                label: label || getModuleTypeLabel(moduleByProject.module),
                project_name: project.name ? `“${project.name}”` : i18n("another project")
              })
            )
            : i18n("{{label}} deleted", { label: label || getModuleTypeLabel(moduleByProject.module) })
        ),
        onClose: closeSnackbar,
        buttonLabel: project ? i18n("Go there") : undefined,
        onClick: () => {
          closeSnackbar()
          if(project) {
            history.push(`/project/${project.id}`)
          } else {
            goRestoreModuleByProject(moduleByProject)
          }
        },
      }
      setSnackbarInfo(snackbarInfo)
    },
    [ goRestoreModuleByProject, history ],
  )

  return {
    onDeleteModuleByProject,
    restoreComponents: (
      <>
        <CustomSnackbar {...snackbarInfo} />
        {lockAndShowLoading && <FadedLoading />}
      </>
    ),
  }
}

export default useRestoreDeletedModuleByProject
