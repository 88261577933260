import { gql } from '@apollo/client'

import globalAlertsItem_fields from '../fragments/globalAlertsItem_fields'

export default gql
`
  mutation deleteGlobalAlertsItem($id: ID!) {
    deleteGlobalAlertsItem(id: $id) {
      ${globalAlertsItem_fields}
    }
  }
`