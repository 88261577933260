import { gql } from '@apollo/client'

import channelItem_fields from '../fragments/channelItem_fields'

export default gql
`
  mutation updateChannelItemBookmark($channelItemId: ID!, $oldMs: Int!, $newMs: Int!) {
    updateChannelItemBookmark(channelItemId: $channelItemId, oldMs: $oldMs, newMs: $newMs) {
      ${channelItem_fields}
    }
  }
`