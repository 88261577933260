import { memo, useCallback, useState, useRef } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'

import useInstanceValue from '../../hooks/useInstanceValue'
import usePassageRef from '../../hooks/usePassageRef'
import useCreateAndActionPdf from '../../hooks/useCreateAndActionPdf'
import useAppSize from '../../hooks/useAppSize'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import { downloadLink, equalObjs, getLocalStorage, setLocalStorage, getModuleTypeLabel } from '../../utils/misc'

import PrintAndPdfOptions from './PrintAndPdfOptions'
import ImageOptions from './ImageOptions'
import CaptureArea from './CaptureArea'
import PrintAndPdfPreviewPages from './PrintAndPdfPreviewPages'
import ImagePreview from './ImagePreview'
import FadedLoading from './FadedLoading'

const MIN_WIDTH_FOR_FULL_PREVIEW = 500

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 100vw;
    max-width: none;
    max-height: none;
    height: 100vh;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background: ${({ theme }) => theme.palette.grey[500]};
    overflow: hidden;
  }

  @media print {
    display: none;
  }
`

const OptionsPanel = styled.div`
  width: ${({ $fullWidth }) => $fullWidth ? `100%` : `300px`};
  background: white;
  box-shadow: 0 0 40px rgb(0 0 0/.5);
  display: flex;
  flex-direction: column;
`

const OptionsHeader = styled.div`
  background: ${({ theme }) => theme.palette.secondary.main};
  color: white;
  font-weight: 600;
  padding: 10px 15px;
`

const Options = styled.div`
  padding: 15px;
  flex-shrink: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`

const Note = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-bottom: 10px;
  font-size: 13px;
`

const SamplePageHeading = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin: 15px 0 0;
  font-size: 13px;
  text-align: center;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin: 0 0 10px;
`

const Page = styled.div`
  padding: 15px 0 10px;
  position: relative;
  display: flex;
  min-height: 200px;
  ${({ $format }) => ![ `png` ].includes($format) ? `` : `
    overflow-x: auto;
  `}
  ${({ $format }) => [ `png` ].includes($format) ? `` : `
    justify-content: center;
  `}
`

const Pages = styled.div`
  flex: 1;
  overflow: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`

const PrintPreviewOrDownload = ({
  info,
  close,
}) => {

  const {
    module,
    // projectId,
    action,
    defaultImageWidth=800,
    // extraSettings=[],
    // doPageByPageRender=false,
    // passageRefs=[],  // for printing a particular range
    defaultOptions,
    note,
    filterElements,
    closing,
  } = info || {}

  const [ options, setOptions ] = useState({})
  const getOptions = useInstanceValue(options)

  const [ captureInfo, setCaptureInfo ] = useState({})
  const getCaptureInfo = useInstanceValue(captureInfo)

  const [ gettingDataForPdf, setGettingDataForPdf ] = useState(false)

  const { width } = useAppSize()
  const scrollRef = useRef()

  const { modulePassages=[] } = module || {}
  const { fromLoc, toLoc, info: modulePassageInfo={} } = modulePassages[0] || {}
  const { versionId } = modulePassageInfo
  const passageRef = usePassageRef({
    fromLoc,
    toLoc,
    convertToVersionId: versionId,
  })

  const updateInfo = useCallback(
    updates => {
      setOptions({
        ...getOptions(),
        ...updates,
      })
    },
    [ getOptions ],
  )

  const { createAndActionPdf, creatingPdf } = useCreateAndActionPdf(options)

  const doAction = useCallback(
    async () => {
      const options = getOptions()
      const { format, moduleWidth } = options

      setLocalStorage(
        `initialValue:${action === `print` ? `print` : format}Options`,
        options,
      )

      if(action === `download`) {
        setLocalStorage(`initialValue:downloadFormat`, format)
      }

      setLocalStorage(`initialValue:moduleWidth:${format}`, moduleWidth)

      const name = i18n("{{module_type}} of {{passages}}", {
        module_type: getModuleTypeLabel(module),
        passages: passageRef,
      })

      const { dataUrl, numPages, getDataUrlForPage } = getCaptureInfo()

      if([ `png` ].includes(format)) {
        downloadLink({
          href: dataUrl,
          name: `${name}.${format}`,
        })
        close()
      } else {  // pdf or print
        setGettingDataForPdf(true)
        const dataUrls = []
        const headerDataUrl = await getDataUrlForPage(0, true)
        for(let pageNum=1; pageNum<=numPages; pageNum++) {
          dataUrls.push(await getDataUrlForPage(pageNum, true))
        }
        createAndActionPdf({
          action,
          headerDataUrl,
          dataUrls,
          name,
          close,
        })
      }
    },
    [ action, passageRef, getOptions, createAndActionPdf, close, getCaptureInfo, module ],
  )

  useLayoutEffectAsync(
    () => {
      if(!closing) {
        const initialOptions = {
          pageSize: `letter`,
          orientation: `portrait`,
          margins: 36,  // in pixels given base 72 ppi (i.e. default margins === 0.5 inches)
          moduleWidth: action === `print` ? 800 : defaultImageWidth,
          format: action === `print` ? `pdf` : `png`,  // or pdf
          imageWidth: defaultImageWidth * 2,
          showLogo: true,
          blackAndWhite: false,
          whiteBackground: false,
          ...defaultOptions,
          ...getLocalStorage(
            (
              `initialValue:${
                action === `print`
                  ? `print`
                  : getLocalStorage(`initialValue:downloadFormat`, `png`)
              }Options`
            ),
            {},
          ),
        }
        initialOptions.moduleWidth = getLocalStorage(`initialValue:moduleWidth:${initialOptions.format}`, initialOptions.moduleWidth)
        initialOptions.marginsInChange = initialOptions.margins
        initialOptions.moduleWidthInChange = initialOptions.moduleWidth
        initialOptions.imageWidthInChange = initialOptions.imageWidth
        setOptions(initialOptions)
        setCaptureInfo({})
        setGettingDataForPdf(false)
      }
    },
    [ action, closing ]
  )

  const OptionsComponent = (
    (action === `print` || options.format === `pdf`)
      ? PrintAndPdfOptions
      : ImageOptions
  )

  const PreviewComponent = (
    (action === `print` || options.format === `pdf`)
      ? PrintAndPdfPreviewPages
      : ImagePreview
  )

  const readyToDoAction = equalObjs(options, captureInfo.options)
  const preppingPdf = gettingDataForPdf || creatingPdf

  return (
    <StyledDialog
      onClose={close}
      open={!!info && !closing}
    >

      <OptionsPanel $fullWidth={width < MIN_WIDTH_FOR_FULL_PREVIEW}>

        <OptionsHeader>
          {action === `print` && i18n("Print Options")}
          {action === `download` && i18n("Download Options")}
        </OptionsHeader>

        <Options>

          {!!note && <Note>{note}</Note>}

          <OptionsComponent
            {...options}
            action={action}
            updateInfo={updateInfo}
            doAction={doAction}
            captureInfo={captureInfo}
            defaultImageWidth={defaultImageWidth}
          />

          <StyledButton
            variant="contained"
            disableElevation
            fullWidth
            color="secondary"
            onClick={doAction}
            disabled={!readyToDoAction}
          >
            {action === `print` && i18n("Next")}
            {action === `download` && i18n("Download")}
          </StyledButton>

          <StyledButton
            variant="contained"
            disableElevation
            fullWidth
            onClick={close}
          >
            {i18n("Cancel")}
          </StyledButton>

          {width < MIN_WIDTH_FOR_FULL_PREVIEW &&
            <>
              {options.format === `pdf` && captureInfo.numPages > 1 &&
                <SamplePageHeading>
                  {i18n("Sample Page", "", "outline")}
                </SamplePageHeading>
              }

              <Page $format={options.format}>
                <PreviewComponent
                  {...info}
                  options={options}
                  module={module}
                  captureInfo={captureInfo}
                  maxOutWidth={width - 20*2}
                />
              </Page>

            </>
          }

          {preppingPdf && <FadedLoading />}

        </Options>

      </OptionsPanel>

      <CaptureArea
        {...info}
        options={options}
        filterElements={filterElements}
        setCaptureInfo={setCaptureInfo}
      />

      {width >= MIN_WIDTH_FOR_FULL_PREVIEW &&
        <Pages ref={scrollRef}>
          <PreviewComponent
            {...info}
            options={options}
            module={module}
            captureInfo={captureInfo}
            scrollRef={scrollRef}
            maxOutWidth={width - (300 + 15*2)}
          />
        </Pages>
      }

    </StyledDialog>
  )

}

export default memo(PrintPreviewOrDownload)