import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import SubtleDivider from '../../common/SubtleDivider'
import OutlineKeyOptions from './OutlineKeyOptions'
// import FormattingKeyOptionsForModuleSettings from '../shared/FormattingKeyOptionsForModuleSettings'
import CheckBoxModuleSetting from '../shared/CheckBoxModuleSetting'
import SliderModuleSetting from '../shared/SliderModuleSetting'
import GreekPunctuationOptionsForModuleSettings from '../shared/GreekPunctuationOptionsForModuleSettings'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 15px 15px 5px;
`

const StyledSubtleDivider = styled(SubtleDivider)`
  margin: 10px -15px 0;
`

const OutlineSettings = ({
  projectId,
  versionId,
  languageId,
  displayedTextSizes,
  displayedLineSpacings,
  setDisplayedTextSizes,
  setDisplayedLineSpacings,
  textSizesSetting,
  lineSpacingsSetting,
  showVsNumsSetting,
  showCfsSetting,
  showNotesSetting,
  showCantillationSetting,
  showHebrewVowelsSetting,
  showGreekAccentsSetting,
  greekPunctuationSetting,
  outlineStyleSetting,
  outlineVisibilitySetting,
  // formattingKeyIdSetting,
  // formattingKeyViewSetting,
}) => {

  return (
    <Container>

      <SliderModuleSetting
        projectId={projectId}
        moduleSetting={textSizesSetting}
        displayedValue={displayedTextSizes}
        setDisplayedValue={setDisplayedTextSizes}
        label={i18n("Text Size", "", "outline")}
        className="module-settings-textsizes"
      />

      <SliderModuleSetting
        projectId={projectId}
        moduleSetting={lineSpacingsSetting}
        displayedValue={displayedLineSpacings}
        setDisplayedValue={setDisplayedLineSpacings}
        label={i18n("Line Spacing", "", "outline")}
        max={350}
        className="module-settings-linespacings"
      />

      <CheckBoxModuleSetting
        projectId={projectId}
        moduleSetting={showVsNumsSetting}
        label={i18n("Show verse numbers", "", "outline")}
      />

      {versionId !== `original` &&
        <CheckBoxModuleSetting
          projectId={projectId}
          moduleSetting={showCfsSetting}
          label={i18n("Show cross-reference dots", "", "outline")}
        />
      }

      <CheckBoxModuleSetting
        projectId={projectId}
        moduleSetting={showNotesSetting}
        label={i18n("Show translation note dots", "", "outline")}
      />

      {versionId === `original` && languageId === `heb` &&
        <>
          <CheckBoxModuleSetting
            projectId={projectId}
            moduleSetting={showHebrewVowelsSetting}
            label={i18n("Show vowels", "", "outline")}
          />

          <CheckBoxModuleSetting
            projectId={projectId}
            moduleSetting={showCantillationSetting}
            label={i18n("Show cantillation", "", "outline")}
          />
        </>
      }

      {versionId === `original` && languageId === `grc` &&
        <>
          <CheckBoxModuleSetting
            projectId={projectId}
            moduleSetting={showGreekAccentsSetting}
            label={i18n("Show accents", "", "outline")}
          />

          <GreekPunctuationOptionsForModuleSettings
            projectId={projectId}
            moduleSetting={greekPunctuationSetting}
          />
        </>
      }

      <StyledSubtleDivider />

      <OutlineKeyOptions
        outlineStyleSetting={outlineStyleSetting}
        outlineVisibilitySetting={outlineVisibilitySetting}
        projectId={projectId}
      />

      {/* <StyledSubtleDivider />

      <FormattingKeyOptionsForModuleSettings
        formattingKeyIdSetting={formattingKeyIdSetting}
        formattingKeyViewSetting={formattingKeyViewSetting}
        projectId={projectId}
        hideShowButton
      /> */}

    </Container>
  )
}

export default memo(OutlineSettings)