/*

Three ways to keep things "sticky":

1. useStickyRefState (localstorage)

  PARTICULARS:
  * device-specific
  - syncronous
  - works offline
  - not oversized
  * no instant sync (across uses throughout code and tabs)
  - works with non-general ids
  - "unlimited" update frequency
  
  USES:
  - initial values
  - in place of useState when you want it sticky
  
2. useAccountSetting (cloud/indexedDB)

  PARTICULARS:
  * account-specific (works with no login as well)
  - asyncronous
  - works offline
  - not oversized
  * instant sync (across uses throughout code and tabs)
  - general ids only
  - limited update frequency

  USES:
  - user customization

3. localInfo (indexedDB)

  PARTICULARS:
  * device-specific
  - pseudo-syncronous
  - works offline
  - can be large
  * instant sync (across uses throughout code and tabs)
  - general ids only
  - "unlimited" update frequency
  
  USES:
  - device customization

*/

import React, { useMemo } from 'react'
import { useLiveQuery } from "dexie-react-hooks"

import { db } from '../utils/database'

const defaultValue = {
  offlineSetupStatus: "off",  // possible values: off, on-query-sync, on-ready, on-mutation-sync
  recentSearches: [],
  lastOfflineUpdateInfo: null,
  passageTextSizes: { base: 2.8, grc: 2.95, heb: 2.95 },  // base: 2-4; grc/heb: 2-4
  passageLineSpacingSizes: { base: 2.6, grc: 2.5, heb: 2.7 },  // base: 2-3.5; grc/heb: 2-3
  passageShowHighlights: true,
  passageShowCfs: true,
  passageShowNotes: true,
  passageShowCantillation: true,
  passageShowHebrewVowels: true,
  passageShowGreekAccents: true,
  passageGreekPunctuation: "ENGLISH",  // possible values: ENGLISH, GREEK, NONE
  darkMode: `AUTO`,  // possible values: AUTO, ON, OFF
}

const emptyArray = []  // this needs to be immutable

export const OfflineSetupStatusContext = React.createContext(defaultValue.offlineSetupStatus)
export const RecentSearchesContext = React.createContext(defaultValue.recentSearches)
export const LastOfflineUpdateInfoContext = React.createContext(defaultValue.lastOfflineUpdateInfo)
export const OfflineVersionsContext = React.createContext(emptyArray)
export const SaveStatusContext = React.createContext(`saved`)
export const PassageTextSizesContext = React.createContext(defaultValue.passageTextSizes)
export const PassageLineSpacingSizesContext = React.createContext(defaultValue.passageLineSpacingSizes)
export const PassageShowHighlightsContext = React.createContext(defaultValue.passageShowHighlights)
export const PassageShowCfsContext = React.createContext(defaultValue.passageShowCfs)
export const PassageShowNotesContext = React.createContext(defaultValue.passageShowNotes)
export const PassageShowCantillationContext = React.createContext(defaultValue.passageShowCantillation)
export const PassageShowHebrewVowelsContext = React.createContext(defaultValue.passageShowHebrewVowels)
export const PassageShowGreekAccentsContext = React.createContext(defaultValue.passageShowGreekAccents)
export const PassageGreekPunctuationContext = React.createContext(defaultValue.passageGreekPunctuation)
export const DarkModeContext = React.createContext(defaultValue.darkMode)

export const LocalInfoContextProvider = ({
  children,
}) => {

  const localInfoRows = useLiveQuery(() => (
    db.localInfo
      .toArray()
  )) || emptyArray

  const localInfo = useMemo(
    () => {
      const localInfo = { ...defaultValue }

      localInfoRows.forEach(({ id, value }) => {
        localInfo[id] = value
      })

      return localInfo
    },
    [ localInfoRows ],
  )

  const offlineVersions = useLiveQuery(() => (
    db.offlineVersions
      .orderBy('ordering')
      .toArray()
  )) || emptyArray

  const numQueuedMutations = useLiveQuery(() => (
    db.queuedMutations
      .count()
  )) || 0

  const saveStatus = numQueuedMutations ? `not-saved` : `saved`

  const contextValueCombos = [
    [ OfflineSetupStatusContext, localInfo.offlineSetupStatus ],
    [ RecentSearchesContext, localInfo.recentSearches ],
    [ LastOfflineUpdateInfoContext, localInfo.lastOfflineUpdateInfo ],
    [ OfflineVersionsContext, offlineVersions ],
    [ SaveStatusContext, saveStatus ],
    [ PassageTextSizesContext, localInfo.passageTextSizes ],
    [ PassageLineSpacingSizesContext, localInfo.passageLineSpacingSizes ],
    [ PassageShowHighlightsContext, localInfo.passageShowHighlights ],
    [ PassageShowCfsContext, localInfo.passageShowCfs ],
    [ PassageShowNotesContext, localInfo.passageShowNotes ],
    [ PassageShowCantillationContext, localInfo.passageShowCantillation ],
    [ PassageShowHebrewVowelsContext, localInfo.passageShowHebrewVowels ],
    [ PassageShowGreekAccentsContext, localInfo.passageShowGreekAccents ],
    [ PassageGreekPunctuationContext, localInfo.passageGreekPunctuation ],
    [ DarkModeContext, localInfo.darkMode ],
  ]

  let toReturn = children

  contextValueCombos.reverse().forEach(([ Context, value ]) => {
    toReturn = (
      <Context.Provider value={value}>
        {toReturn}
      </Context.Provider>
    )
  })

  return toReturn

}



// Usage example:

  // const passageChooserParallelOpen = useContext(PassageChooserParallelOpenContext)
  // const getPassageChooserParallelOpen = useInstanceValue(passageChooserParallelOpen)

  // const togglePassageChooserParallelOpen = useCallback(
  //   () => {
  //     db.localInfo.put({
  //       id: 'passageChooserParallelOpen',
  //       value: !getPassageChooserParallelOpen(),
  //     })
  //   },
  //   [ getPassageChooserParallelOpen ],
  // )