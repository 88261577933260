import { useMemo } from 'react'

import useBookIds from './useBookIds'
import useNumChapters from './useNumChapters'


const useNextPrevChapters = ({
  bookId,
  chapter,
  versionId,
}) => {

  const bookIds = useBookIds(versionId)

  const numChapters = useNumChapters({
    versionId,
    bookId,
  })

  const prevNumChapters = useNumChapters({
    versionId,
    bookId: bookIds[ bookIds.indexOf(bookId) - 1 ],
  })

  const previousChapter = useMemo(
    () => {
      let newBookId = bookId
      let newChapter = chapter - 1

      if(newChapter <= 0) {
        newBookId = bookIds[ bookIds.indexOf(bookId) - 1 ]
        newChapter = prevNumChapters
      }

      if(!newBookId) return null

      return {
        bookId: newBookId,
        chapter: newChapter,
      }
    },
    [ bookId, chapter, prevNumChapters, bookIds ],
  )

  const nextChapter = useMemo(
    () => {
      let newBookId = bookId
      let newChapter = chapter + 1

      if(chapter >= numChapters) {
        newBookId = bookIds[ bookIds.indexOf(bookId) + 1 ]
        newChapter = 1
      }

      if(!newBookId) return null

      return {
        bookId: newBookId,
        chapter: newChapter,
      }
    },
    [ bookId, chapter, numChapters, bookIds ],
  )

  return {
    previousChapter,
    nextChapter,
  }
}

export default useNextPrevChapters