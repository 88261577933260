import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { getBibleBookName } from '@bibletags/bibletags-ui-helper'
import Tooltip from '@material-ui/core/Tooltip'

import useDataQuery from '../../../hooks/useDataQuery'

import versionProgressByBookIdQuery from '../../../graphql/queries/versionProgressByBookId'
import myTagSetSubmissionStatsByVersionIdAndBookIdQuery from '../../../graphql/queries/myTagSetSubmissionStatsByVersionIdAndBookId'

const Container = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgb(0 0 0/.6);
  color: white;
  font-size: 11px;
  padding: 5px 7px;
  border-radius: 2px;
  line-height: 1.3;
`

const Heading = styled.div`
  font-weight: 700;
  margin-bottom: 3px;
`

const GeneralTaggingStats = styled.div`
`

const VersionTaggingStats = styled.div`
  opacity: .9;
`

const BookTaggingStats = styled.div`
  font-weight: 300;
  opacity: .7;
`

const MySubmissionStats = styled.div`
  margin-top: 5px;
`

const MySubmissionVersion = styled.div`
  opacity: .9;
`

// const MySubmissionUsed = styled.span`
//   font-weight: 300;
//   opacity: .7;
// `

const Bar = styled.div`
  opacity: .5;
  display: inline-block;
  margin: 0 3px;
`

const TaggerStats = ({
  versionId,
  loc,
  ...otherProps
}) => {

  const { bookId } = getRefFromLoc(loc || ``)

  // const loggedInUser = useContext(LoggedInUserContext)
  const { myTagSetSubmissionStatsByVersionIdAndBookId } = useDataQuery({
    myTagSetSubmissionStatsByVersionIdAndBookIdQuery,
    fetchPolicy: 'network-only',
  })

  const { versionProgressByBookId } = useDataQuery({
    versionProgressByBookIdQuery,
    variables: {
      versionId,
    },
    fetchPolicy: 'network-only',
  })


  if(!myTagSetSubmissionStatsByVersionIdAndBookId) return null
  if(!versionProgressByBookId) return null

  const totalVerses = { ot: 0, nt: 0 }
  const totalTagged = { ot: 0, nt: 0 }
  const totalConfirmed = { ot: 0, nt: 0 }

  ;(versionProgressByBookId || []).slice(1).forEach(({ total, tagged, confirmed }, idx) => {
    totalVerses[idx+1 < 40 ? `ot` : `nt`] += total
    totalTagged[idx+1 < 40 ? `ot` : `nt`] += tagged
    totalConfirmed[idx+1 < 40 ? `ot` : `nt`] += confirmed
  })

  return (
    <Container {...otherProps}>

      <Heading>
        {i18n("Tagging Stats")}
      </Heading>

      <GeneralTaggingStats>
        <VersionTaggingStats>
          <Tooltip
            title={
              i18n("{{num}} verses left", {
                num: (totalVerses.ot + totalVerses.nt) - (totalTagged.ot + totalTagged.nt),
              })
            }
          >
            <span>
              {i18n("{{category}}:", {
                category: versionId.toUpperCase(),
              })}
              {` `}
              {i18n("{{percent}}%", {
                percent: parseInt((totalTagged.ot + totalTagged.nt) / (totalVerses.ot + totalVerses.nt) * 100),
              })}
            </span>
          </Tooltip>
        </VersionTaggingStats>
        <BookTaggingStats>
          <Tooltip
            title={
              i18n("{{num}} verses left", {
                num: totalVerses.ot - totalTagged.ot,
              })
            }
          >
            <span>
              {i18n("OT: {{percent}}%", {
                percent: parseInt((totalTagged.ot / totalVerses.ot) * 100),
              })}
            </span>
          </Tooltip>
          <Bar>|</Bar>
          <Tooltip
            title={
              i18n("{{num}} verses left", {
                num: totalVerses.nt - totalTagged.nt,
              })
            }
          >
            <span>
              {i18n("NT: {{percent}}%", {
                percent: parseInt((totalTagged.nt / totalVerses.nt) * 100),
              })}
            </span>
          </Tooltip>
          {!!bookId &&
            <>
              <Bar>|</Bar>
              <Tooltip
                title={
                  i18n("{{num}} verses left", {
                    num: (versionProgressByBookId || [])[bookId].total - (versionProgressByBookId || [])[bookId].tagged,
                  })
                }
              >
                <span>
                  {i18n("{{category}}:", {
                    category: getBibleBookName(bookId),
                  })}
                  {` `}
                  {i18n("{{percent}}%", {
                    percent: parseInt(((versionProgressByBookId || [])[bookId].tagged / (versionProgressByBookId || [])[bookId].total) * 100),
                  })}
                </span>
              </Tooltip>
            </>
          }
        </BookTaggingStats>
      </GeneralTaggingStats>

      <MySubmissionStats>
        <Heading>
          {i18n("My Submissions")}
        </Heading>
        {Object.keys(myTagSetSubmissionStatsByVersionIdAndBookId).map(versionId => (
          <MySubmissionVersion key={versionId}>
            {i18n("{{category}}:", {
              category: versionId.toUpperCase(),
            })}
            {` `}
            {myTagSetSubmissionStatsByVersionIdAndBookId[versionId].reduce((total, book) => total + (book || {}).submissions || 0, 0)}
            {/* {` `}
            <MySubmissionUsed>
              {i18n("({{num_used}} accepted)", {
                num_used: myTagSetSubmissionStatsByVersionIdAndBookId[versionId].reduce((total, book) => total + (book || {}).used || 0, 0),
              })}
            </MySubmissionUsed> */}
          </MySubmissionVersion>
        ))}
      </MySubmissionStats>

    </Container>
  )
}

export default memo(TaggerStats)