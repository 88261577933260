import ViewModuleIcon from '@material-ui/icons/ViewModule'
import VideocamIcon from '@material-ui/icons/Videocam'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import CodeIcon from '@material-ui/icons/Code'
import ViewStreamIcon from '@material-ui/icons/ViewStream'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
import TableChartIcon from '@material-ui/icons/TableChart'
import CropLandscapeIcon from '@material-ui/icons/CropLandscape'
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import ImageIcon from '@material-ui/icons/Image'

import getTableInfoFromData from '../insert/Table/getTableInfoFromData'

import Image from '../insert/Image'
import ImageList from '../insert/ImageList'
import Document from '../insert/Document'
import ButtonLinkSet from '../insert/ButtonLinkSet'
import PullQuote from '../insert/PullQuote'
import AudioPlayer from '../insert/AudioPlayer'
import Sidebar from '../insert/Sidebar'
import FramedSection from '../insert/FramedSection'
import Table from '../insert/Table'

let videoIdIterator = 0

const removeIndentAndBlankLines = str => {
  const lines = str.split(`\n`)
  const numSpacesInIndent = (lines[0] || lines[1]).match(/^ */)[0].length
  return (
    lines
      .map(line => line.replace(new RegExp(` {1,${numSpacesInIndent}}`), ``))
      .filter(Boolean)
      .join(`\n`)
  )
}

const filterCustomComponentTypes = prefilteredCustomComponentTypes => {

  const filterComponentsSection = (customComponentTypesSection=[]) => (
    customComponentTypesSection.filter(({ type, group }) => (
      !['framedSection', 'sidebar', 'clearSidebar', 'mapWithPins'].includes(type)
    ))
  )

  return {
    specialBlockSectionAdditions: filterComponentsSection(prefilteredCustomComponentTypes.specialBlockSectionAdditions),
    insertSection: filterComponentsSection(prefilteredCustomComponentTypes.insertSection),
  }
}

const getOutOfTheBoxCustomComponentTypes = ({ locale }) => ({

  image: {
    type: 'image',
    group: 'media',
    component: Image,
    dataStructure: [
      {
        name: 'imgSrc',
        type: 'string',
        inputVariant: 'asset',
        autoFocus: true,
        lang: {
          en: 'Image URL',
        },
      },
      {
        name: 'placement',
        type: 'string',
        inputOptions: [
          {
            value: 'alone-left',
            lang: {
              en: 'Alone on a line (left-justified)',
            },
          },
          {
            value: 'alone-center',
            lang: {
              en: 'Alone on a line (centered)',
            },
          },
          {
            value: 'alone-right',
            lang: {
              en: 'Alone on a line (right-justified)',
            },
          },
          // {
          //   value: 'left',
          //   lang: {
          //     en: 'Float to the left of text that follows',
          //   },
          // },
          // {
          //   value: 'right',
          //   lang: {
          //     en: 'Float to the right of text that follows',
          //   },
          // },
        ],
        lang: {
          en: 'Placement',
        },
      },
      {
        name: 'width',
        type: 'string',
        lang: {
          en: 'Width (optional)',
        },
        placeholder: 'Eg. 50%, 300px, 40vw',
      },
      {
        name: 'maintainWidth',
        type: 'boolean',
        lang: {
          en: 'Maintain width, even on small devices',
        },
      },
      {
        name: 'linkHref',
        type: 'string',
        lang: {
          en: 'Link URL (optional)',
        },
      },
      {
        name: 'openInNewTab',
        type: 'boolean',
        lang: {
          en: 'Open in a new tab',
        },
      },
    ],
    defaultData: {
      placement: 'alone-center',
    },
    defaultProps: {
      className: 'flipeditor-image'
    },
    openSettingsOnCreate: true,
    Icon: ImageIcon,
    lang: {
      en: 'Image',
    },
  },

  imageList: {
    type: 'imageList',
    group: 'media',
    component: ImageList,
    dataStructure: [
      {
        name: 'height',
        type: 'string',
        lang: {
          en: 'Row height',
        },
        placeholder: 'Eg. 200px',
      },
      {
        name: 'placement',
        type: 'string',
        inputOptions: [
          {
            value: 'left',
            lang: {
              en: 'Left-justified',
            },
          },
          {
            value: 'center',
            lang: {
              en: 'Centered',
            },
          },
          {
            value: 'right',
            lang: {
              en: 'Right-justified',
            },
          },
        ],
        lang: {
          en: 'Placement',
        },
      },
      {
        name: 'customSpacing',
        type: 'string',
        lang: {
          en: 'Custom spacing',
        },
        placeholder: 'Eg. 10px',
      },
      {
        name: 'images',
        type: [
          {
            name: 'imgSrc',
            type: 'string',
            inputVariant: 'asset',
            lang: {
              en: 'Image URL',
            },
          },
          {
            name: 'linkHref',
            type: 'string',
            lang: {
              en: 'Link URL (optional)',
            },
          },
          {
            name: 'openInNewTab',
            type: 'boolean',
            lang: {
              en: 'Open in a new tab',
            },
          },
        ],
        lang: {
          en: 'Images',
        },
      },
    ],
    defaultData: {
      images: [
        {},
        {},
        {},
      ],
    },
    openSettingsOnCreate: true,
    Icon: ViewModuleIcon,
    lang: {
      en: 'Images in rows',
    },
  },

  video: {
    type: 'video',
    group: 'media',
    getHTML: ({ key, videoLink='', placement="left", maxWidth, width=maxWidth, maintainWidth, alwaysShow, subtitlesOn, startTime, endTime, playbackSpeed }) => {
      // width=maxWidth is due to maxWidth being the old parameter that is no longer used

      const containerId = `flipeditor-video-id:${key}`

      const vimeoRegex = /^https:\/\/(?:www\.)?vimeo\.com\/(?:.*?\/video\/|channels\/[^\/]*\/)?([0-9]+)(?:\/[^\/]*)?\/?$/
      const youtubeRegex = /^https:\/\/(?:www\.)?youtube\.com\/watch\?v=([^&]*).*$/
      const shortYoutubeRegex = /^https:\/\/youtu\.be\/([^?]+).*$/

      let iframeAttributes = false

      const getSecondsFromTimeString = timeString => (
        String(timeString || '')
          .split(":")
          .reverse()
          .reduce(
            (total, timeSegment, idx) => (
              total + (parseInt(timeSegment || 0, 10) || 0) * [1, 60, 60*60, 60*60*24][idx]
            ),
            0
          )
      )

      let startSecs = getSecondsFromTimeString(startTime)
      let endSecs = getSecondsFromTimeString(endTime)

      if(videoLink.match(vimeoRegex)) {

        const vimeoIframeId = `vimeoiframe-${videoLink.replace(/"/g, '')}-${startTime}-${endTime}`

        if((startSecs || endSecs || playbackSpeed) && typeof window !== 'undefined') {

          const setupStartEnd = () => {
            try {
              const player = new Vimeo.Player(document.getElementById(vimeoIframeId))

              if(startSecs) {
                player.setCurrentTime(startSecs)
                startSecs = 0
              }

              if(endSecs) {
                player.on("timeupdate", data => {
                  if(endSecs && data.seconds >= endSecs) {
                    player.pause()
                    endSecs = 0
                  }
                })
              }

              if(playbackSpeed && playbackSpeed !== 1) {
                player.setPlaybackRate(playbackSpeed)
              }

            } catch(e) {}
          }

          document.flipEditorSetupVimeoStartEndFuncs = [
            ...(document.flipEditorSetupVimeoStartEndFuncs || []),
            setupStartEnd,
          ]

          document.flipEditorSetupVimeoStartEnd = () => {
            if(typeof Vimeo === 'undefined') return

            const func = document.flipEditorSetupVimeoStartEndFuncs.pop()

            if(func) {
              func()
              document.flipEditorSetupVimeoStartEnd()
            }
          }

          if(!document.getElementById("flipeditor-vimeoscript")) {
            const script = document.createElement('script')
            script.id = 'flipeditor-vimeoscript'
            script.type = 'text/javascript'
            script.async = true
            script.onload = document.flipEditorSetupVimeoStartEnd
            script.src = 'https://player.vimeo.com/api/player.js'
            document.getElementsByTagName('head')[0].appendChild(script)
          } else {
            setTimeout(document.flipEditorSetupVimeoStartEnd)  // this needs to get run after the insertion of the iframe, hence the timeout
          }

        }

        iframeAttributes = 
          videoLink.replace(
            vimeoRegex, 
            `src="`
              + `https://player.vimeo.com/video/$1`
                + `?portrait=0`
                + `&autoplay=0`
                + (subtitlesOn ? `&texttrack=${locale || `en`}` : ``)
              + `" `
              + `id="${vimeoIframeId}" `
              + `frameborder="0" `
              + `webkitallowfullscreen `
              + `mozallowfullscreen `
              + `allowfullscreen `
          )

      } else {
        let regex = youtubeRegex
        let matches = videoLink.match(regex)

        if(!matches) {
          regex = shortYoutubeRegex
          matches = videoLink.match(regex)
        }

        if(matches && matches[1]) {

          const iframeId = `flipeditor-youtubeiframe-${videoIdIterator++}`

          iframeAttributes = 
            videoLink.replace(
              regex, 
              `src="`
                + `https://www.youtube.com/embed/$1`
                  + `?rel=0`
                  + `&modestbranding=1`
                  + `&enablejsapi=1`
                  + (startSecs ? `&start=${startSecs}` : ``)
                  + (endSecs ? `&end=${endSecs}` : ``)
                  + (subtitlesOn ? `&cc_load_policy=${1}&cc_lang_pref=${locale || `en`}` : ``)
                  + (alwaysShow ? `&playsinline=1` : ``)
              + `" `
              + `frameborder="0" `
              + `allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" `
              + `allowfullscreen `
              + `id="${iframeId}" `
            )

            if(playbackSpeed && playbackSpeed !== 1) {

              const setupYouTube = () => {
                const player = new YT.Player(iframeId, {
                  events: {
                    'onReady': event => event.target.setPlaybackRate(playbackSpeed),
                  }
                })
              }
  
              document.flipEditorSetupYouTubeFuncs = [
                ...(document.flipEditorSetupYouTubeFuncs || []),
                setupYouTube,
              ]
    
              window.onYouTubeIframeAPIReady = () => {
                const func = document.flipEditorSetupYouTubeFuncs.pop()
    
                if(func) {
                  func()
                  window.onYouTubeIframeAPIReady()
                }
              }
  
              if(!document.getElementById("flipeditor-youtubescript")) {
                const script = document.createElement('script')
                script.id = 'flipeditor-youtubescript'
                script.src = 'https://www.youtube.com/iframe_api'
                document.getElementsByTagName('head')[0].appendChild(script)
              } else {
                setTimeout(window.onYouTubeIframeAPIReady)  // this needs to get run after the insertion of the iframe, hence the timeout
              }

            }
  
        }
      }

      if(typeof window !== 'undefined') {  // only execute on the client
        requestAnimationFrame(() => {
          if("IntersectionObserver" in window && alwaysShow) {
            const container = document.getElementById(`${containerId}`)
            if(!container) return
            const observer = new IntersectionObserver(entries => {
              if(entries[0].intersectionRatio < 1) {
                container.classList.add("flipeditor-video-fixed")
              } else {
                container.classList.remove("flipeditor-video-fixed")
              }
              document.body.classList[document.body.querySelector(".flipeditor-video-fixed") ? "add" : "remove"]("body-flipeditor-video-fixed")
            }, { threshold: 1 })
            observer.observe(container)
          }
        })
      }

      return (
        `<div
          id="${containerId}"
          style="
            text-align: ${placement};
            overflow-x: auto;
          "
        >` +
          `<div
            class="flipeditor-video-container2"
            style="
              position: absolute;
              width: ${width || '700px'}; 
              ${!maintainWidth ? `max-width: 100%;` : ``}
              vertical-align: middle;
              display: inline-block;
              background: rgba(0,0,0,.025);
              background: repeating-linear-gradient(
                -55deg,
                rgba(0,0,0,.2),
                rgba(0,0,0,.01), 1px,
                rgba(0,0,0,.01) 7px
              );
            "
          >` +
            `<div
              style="
                position: relative; 
                padding-bottom: 56.25%; 
                width: 100%; 
              "
            ></div>` +
          `</div>` +
          `<div
            class="flipeditor-video-container3"
            style="
              position: relative;
              width: ${width || '700px'}; 
              ${!maintainWidth ? `max-width: 100%;` : ``}
              vertical-align: middle;
              display: inline-block;
            "
          >` +
            `<div
              style="
                position: relative; 
                padding-bottom: 56.25%; 
                width: 100%; 
              "
            >` +
              (iframeAttributes
                ? (
                  `<iframe
                    style="
                      position: absolute; 
                      top: 0; 
                      left: 0; 
                      width: 100%;
                      height: 100%;
                    " 
                    ${iframeAttributes}
                  ></iframe>`
                )
                : (
                  `<div
                    style="
                      position: absolute;
                      left: 0;
                      right: 0;
                      top: 0;
                      bottom: 0;
                      border: 1px solid rgba(0,0,0,.1);
                    "
                  >` +
                    `<div
                      style="
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: calc(50% - 35px);
                        font-size: 50px;
                        line-height: 70px;
                        color: rgba(0,0,0,.15);
                        text-align: center;
                      "
                    >▶</div>` +
                  `</div>`
                )
              ) +
            `</div>` +
          `</div>` +
        `</div>`
      )
    },
    dataStructure: [
      {
        name: 'videoLink',
        type: 'string',
        autoFocus: true,
        lang: {
          en: 'YouTube or Vimeo video link',
        },
      },
      {
        name: 'placement',
        type: 'string',
        inputOptions: [
          {
            value: 'left',
            lang: {
              en: 'Left-justified',
            },
          },
          {
            value: 'center',
            lang: {
              en: 'Centered',
            },
          },
          {
            value: 'right',
            lang: {
              en: 'Right-justified',
            },
          },
        ],
        lang: {
          en: 'Placement',
        },
      },
      {
        name: 'width',
        type: 'string',
        lang: {
          en: 'Width',
        },
        placeholder: 'Eg. 50%, 300px',
      },
      {
        name: 'maintainWidth',
        type: 'boolean',
        lang: {
          en: 'Maintain width, even on small devices',
        },
      },
      {
        name: 'alwaysShow',
        type: 'boolean',
        lang: {
          en: 'Show in corner when scrolled out of view',
        },
      },
      {
        name: 'subtitlesOn',
        type: 'boolean',
        lang: {
          en: 'Subtitles on by default',
        },
      },
      {
        name: 'startTime',
        type: 'string',
        lang: {
          en: 'Start time',
        },
        placeholder: 'Eg. 3:12',
      },
      {
        name: 'endTime',
        type: 'string',
        lang: {
          en: 'End time',
        },
        placeholder: 'Eg. 12:14',
      },
      {
        name: 'playbackSpeed',
        type: 'slider',
        lang: {
          en: 'Playback Speed',
        },
        sliderParams: {
          step: .25,
          min: .25,
          max: 2,
          marks: [
            {
              value: .25,
              label: '.25x',
            },
            {
              value: .5,
              label: '.5x',
            },
            {
              value: 1,
              label: `normal`,
            },
            {
              value: 1.5,
              label: '1.5x',
            },
            {
              value: 2,
              label: '2x',
            },
          ],
          valueLabelDisplay: "auto",
          valueLabelFormat: value => `${value}x`,
        },
      },
    ],
    defaultData: {
      placement: 'center',
      playbackSpeed: 1,
    },
    openSettingsOnCreate: true,
    Icon: VideocamIcon,
    lang: {
      en: 'Video',
    },
  },

  audioPlayer: {
    type: 'audioPlayer',
    group: 'media',
    component: AudioPlayer,
    dataStructure: [
      {
        name: 'src',
        type: 'string',
        inputVariant: 'asset',
        autoFocusAndSelect: true,
        lang: {
          en: 'MP3 URL',
        },
      },
    ],
    openSettingsOnCreate: true,
    Icon: AudiotrackIcon,
    lang: {
      en: 'MP3 audio',
    },
  },

  document: {
    type: 'document',
    group: 'media',
    component: Document,
    dataStructure: [
      {
        name: 'title',
        type: 'string',
        autoFocus: true,
        lang: {
          en: 'Title',
        },
      },
      {
        name: 'description',
        type: 'string',
        inputVariant: 'long',
        lang: {
          en: 'Description',
        },
      },
      {
        name: 'docHref',
        type: 'string',
        inputVariant: 'asset',
        lang: {
          en: 'Document URL',
        },
      },
      {
        name: 'thumbImgSrc',
        type: 'string',
        inputVariant: 'asset',
        lang: {
          en: 'Thumbnail image URL',
        },
      },
    ],
    openSettingsOnCreate: true,
    Icon: InsertDriveFileIcon,
    lang: {
      en: 'Document',
    },
  },

  rawCode: {
    type: 'rawCode',
    isExtendedType: true,
    group: 'media',
    getHTML: ({ code }) => code,
    dataStructure: [
      {
        name: 'code',
        type: 'string',
        inputVariant: 'long',
        autoFocusAndSelect: true,
        lang: {
          en: 'HTML Code (WARNING: Never copy in code from an untrusted source.)',
        },
      },
    ],
    defaultData: {
      code: removeIndentAndBlankLines(`
        <div style='
          color: rgba(0,0,0,.3);
          font-size: 18px;
          text-align: center;
          background: rgba(0,0,0,.03);
          border: 2px dashed rgba(0,0,0,.1);
          padding: 50px;
        '></div>
      `)
    },
    openSettingsOnCreate: true,
    Icon: CodeIcon,
    lang: {
      en: 'Embed code',
    },
  },

  buttonLinkSet: {
    type: 'buttonLinkSet',
    group: 'util',
    component: ButtonLinkSet,
    dataStructure: [
      {
        name: 'alignment',
        type: 'string',
        inputOptions: [
          {
            value: 'center',
            lang: {
              en: 'Centered',
            },
          },
          {
            value: 'left',
            lang: {
              en: 'Left-justified',
            },
          },
          {
            value: 'right',
            lang: {
              en: 'Right-justified',
            },
          },
        ],
        lang: {
          en: 'Alignment',
        },
      },
      {
        name: 'buttons',
        type: [
          {
            name: 'label',
            type: 'string',
            autoFocus: true,
            lang: {
              en: 'Label',
            },
          },
          {
            name: 'href',
            type: 'string',
            lang: {
              en: 'URL',
            },
          },
          {
            name: 'contained',
            type: 'boolean',
            lang: {
              en: '“Contained” style',
            },
          },
          {
            name: 'color',
            type: 'string',
            inputOptions: [
              {
                value: 'normal',
                lang: {
                  en: 'Normal',
                },
              },
              {
                value: 'emphasis',
                lang: {
                  en: 'Emphasis',
                },
              },
              {
                value: 'scary',
                lang: {
                  en: 'Scary',
                },
              },
            ],
            lang: {
              en: 'Color',
            },
          },
          {
            name: 'openInNewTab',
            type: 'boolean',
            lang: {
              en: 'Open in a new tab',
            },
          },
        ],
        lang: {
          en: 'Button links',
        },
      },
    ],
    defaultData: {
      alignment: 'center',
      buttons: [
        {},
      ],
    },
    openSettingsOnCreate: true,
    Icon: ViewStreamIcon,
    lang: {
      en: 'Button link set',
    },
  },

  pullQuote: {
    type: 'pullQuote',
    group: 'util',
    component: PullQuote,
    dataStructure: [
      {
        name: 'quote',
        type: 'string',
        inputVariant: 'long',
        autoFocus: true,
        lang: {
          en: 'Quote',
        },
      },
      {
        name: 'citation',
        type: 'string',
        inputVariant: 'long',
        lang: {
          en: 'Citation',
        },
      },
      // {
      //   name: 'placement',
      //   type: 'string',
      //   inputOptions: [
      //     {
      //       value: 'alone',
      //       lang: {
      //         en: 'Alone on a line',
      //       },
      //     },
      //     {
      //       value: 'left',
      //       lang: {
      //         en: 'Float to the left of text that follows',
      //       },
      //     },
      //     {
      //       value: 'right',
      //       lang: {
      //         en: 'Float to the right of text that follows',
      //       },
      //     },
      //   ],
      //   lang: {
      //     en: 'Placement',
      //   },
      // },
    ],
    openSettingsOnCreate: true,
    Icon: FormatQuoteIcon,
    lang: {
      en: 'Pull quote',
    },
  },

  table: {
    type: 'table',
    group: 'util',
    component: Table,
    dataStructure: [
      {
        name: 'tableHeader',
        type: 'string',
        lang: {
          en: 'Table header',
        },
      },
      {
        name: 'tableData',
        type: 'string',
        inputVariant: 'long',
        lang: {
          en: 'Table data',
        },
      },
      {
        name: 'width',
        type: 'string',
        lang: {
          en: 'Width (optional)',
        },
        placeholder: 'Eg. 100%, 400px, 40vw',
      },
      {
        name: 'maintainWidth',
        type: 'boolean',
        lang: {
          en: 'Maintain width, even on small devices',
        },
      },
      {
        name: 'placement',
        type: 'string',
        inputOptions: [
          {
            value: 'left',
            lang: {
              en: 'Left-justified',
            },
          },
          {
            value: 'center',
            lang: {
              en: 'Centered',
            },
          },
          {
            value: 'right',
            lang: {
              en: 'Right-justified',
            },
          },
        ],
        lang: {
          en: 'Placement',
        },
      },
      {
        name: 'sortable',
        type: 'boolean',
        lang: {
          en: 'Sortable',
        },
      },
      {
        name: 'filterable',
        type: 'boolean',
        lang: {
          en: 'Filterable',
        },
      },
      {
        name: 'tableFooter',
        type: 'string',
        inputVariant: 'long',
        lang: {
          en: 'Table footer',
        },
      },
      {
        name: 'columnAlignments',
        type: ['string'],
        inputOptions: [
          {
            value: 'left',
            lang: {
              en: 'Left-justified',
            },
          },
          {
            value: 'center',
            lang: {
              en: 'Centered',
            },
          },
          {
            value: 'right',
            lang: {
              en: 'Right-justified',
            },
          },
        ],
        lang: {
          en: 'Column alignments',
        },
        hideWhen: ({ componentData: { tableData } }) => getTableInfoFromData(tableData).columnNames.length === 0,
        hideChildReorderWhen: () => true,
        bindNumber: ({ componentData: { tableData } }) => getTableInfoFromData(tableData).columnNames.length,
      },
    ],
    defaultData: {
      tableData:
        `Name: The Hulk\n` +
        `Powers:\n` +
        `- Really strong\n` +
        `- Green\n` +
        `Awesome index: 56\n` +
        `\n` +
        `Name: Spiderman\n` +
        `Powers:\n` +
        `1. Webs\n` +
        `2. Climbing\n` +
        `3. Strength\n` +
        `Awesome index: 45\n` +
        `\n` +
        `Name: Batman\n` +
        `Powers:\n` +
        `- Cool car\n` +
        `- Gadgets\n` +
        `Awesome index: 52\n` +
        `\n` +
        `Name: Ant-Man\n` +
        `Powers:\n` +
        `- Shrinking\n` +
        `- Strength\n` +
        `Awesome index: 72`,
      placement: 'center',
      sortable: true,
    },
    Icon: TableChartIcon,
    openSettingsOnCreate: true,
    lang: {
      en: 'Table',
    },
  },

  framedSection: {
    type: 'framedSection',
    isExtendedType: true,
    group: 'util',
    component: FramedSection,
    defaultProps: ({ blockKey }) => ({
      id: `framedSection-${blockKey}`,
      title: {
        lang: {
          en: 'Framed section',
        },
      },
      className: 'flipeditor-framedSection',
      filterCustomComponentTypes,
    }),
    dataStructure: [
      {
        name: 'framing',
        type: 'string',
        inputOptions: [
          {
            value: 'containedSubtle',
            lang: {
              en: 'Contained (subtle)',
            },
          },
          {
            value: 'containedEmphasized',
            lang: {
              en: 'Contained (emphasized)',
            },
          },
          {
            value: 'fullWidthSubtle',
            lang: {
              en: 'Full width (subtle)',
            },
          },
          {
            value: 'fullWidthEmphasized',
            lang: {
              en: 'Full width (emphasized)',
            },
          },
        ],
        lang: {
          en: 'Framing',
        },
      },
    ],
    defaultData: {
      initialContent: '',
      framing: 'fullWidthSubtle',
    },
    Icon: CropLandscapeIcon,
    lang: {
      en: 'Framed section',
    },
  },

  sidebar: {
    type: 'sidebar',
    isExtendedType: true,
    group: 'util',
    component: Sidebar,
    defaultProps: ({ blockKey }) => ({
      id: `sidebar-${blockKey}`,
      title: {
        lang: {
          en: 'Sidebar',
        },
      },
      className: 'flipeditor-sidebar',
      filterCustomComponentTypes,
    }),
    dataStructure: [
      {
        name: 'framing',
        type: 'string',
        inputOptions: [
          {
            value: 'none',
            lang: {
              en: 'None',
            },
          },
          {
            value: 'dividedOff',
            lang: {
              en: 'Divided off',
            },
          },
          {
            value: 'containedSubtle',
            lang: {
              en: 'Contained (subtle)',
            },
          },
          {
            value: 'containedEmphasized',
            lang: {
              en: 'Contained (emphasized)',
            },
          },
        ],
        lang: {
          en: 'Framing',
        },
      },
      {
        name: 'hideOnMobile',
        type: 'boolean',
        lang: {
          en: 'Hide on mobile',
        },
      },
    ],
    defaultData: {
      initialContent: '',
      framing: 'none',
    },
    Icon: VerticalSplitIcon,
    lang: {
      en: 'Sidebar (floats next to content which follows)',
    },
  },

  clearSidebar: {
    type: 'clearSidebar',
    isExtendedType: true,
    group: 'util',
    getHTML: () => ``,
    defaultProps: {
      className: 'flipeditor-clearSidebar'
    },
    Icon: SubdirectoryArrowRightIcon,
    lang: {
      en: 'Section break (clear from the Sidebar)',
    },
  },

})

export default getOutOfTheBoxCustomComponentTypes