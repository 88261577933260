import { gql } from '@apollo/client'

import studyBibleItem_fields from '../fragments/studyBibleItem_fields'

export default gql
`
  mutation updateStudyBibleItem($id: ID!, $input: StudyBibleItemInput!) {
    updateStudyBibleItem(id: $id, input: $input) {
      ${studyBibleItem_fields}
    }
  }
`