import { memo } from 'react'
// import styled from 'styled-components'

import PassageInNotesOptionsChangePassage from './PassageInNotesOptionsChangePassage'
import PassageInNotesSettings from './PassageInNotesSettings'
import PassageInNotesActions from './PassageInNotesActions'
import ModuleOptionsPopover from '../shared/ModuleOptionsPopover'

const PassageInNotesOptionsPopover = ({
  refs,
  versionId,
  languageId,
  onClose,
  updatePassage,
  updateMarkup,
  settings,
  setDisplayedTextSize,
  setDisplayedLineSpacing,
  commitSettings,
  anchorEl,
  flipEditorDeleteThisBlock,
  ...otherProps
}) => {

  // const user = useContext(LoggedInUserContext)
  // const { hasToolsPlan } = user || {}

  return (
    <ModuleOptionsPopover
      {...otherProps}
      anchorEl={anchorEl}
      onClose={onClose}
      topSection={
        <PassageInNotesOptionsChangePassage
          refs={refs}
          versionId={versionId}
          updatePassage={updatePassage}
          onClose={onClose}
        />
      }
      settingsSection={
        <PassageInNotesSettings
          versionId={versionId}
          languageId={languageId}
          settings={settings}
          setDisplayedTextSize={setDisplayedTextSize}
          setDisplayedLineSpacing={setDisplayedLineSpacing}
          commitSettings={commitSettings}
        />
      }
      customActionsSection={
        <PassageInNotesActions
          updateMarkup={updateMarkup}
          flipEditorDeleteThisBlock={flipEditorDeleteThisBlock}
          onClose={onClose}
        />
      }
    />
  )

}

export default memo(PassageInNotesOptionsPopover)