import { gql } from '@apollo/client'

const nextAndPreviousLocsWithoutTagging = gql`
  query nextAndPreviousLocsWithoutTagging($bookId: Int!, $chapter: Int!, $verse: Int!, $versionId: ID!) {
    nextAndPreviousLocsWithoutTagging(bookId: $bookId, chapter: $chapter, verse: $verse, versionId: $versionId) {
      previousLoc
      nextLoc
    }
  }
`

export default nextAndPreviousLocsWithoutTagging