import { db } from '../../utils/database'

const accountSettings = async args => {

  if(!args.idPrefix) throw new Error(`Unexpected accountSettings query missing idPrefix`)

  const results = await db.accountSettings.where(`id`).startsWith(args.idPrefix).toArray()
  
  return results

}

export default accountSettings