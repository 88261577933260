import { memo } from 'react'
// import { i18n } from 'inline-i18n'
// import styled from 'styled-components'

import OriginalLanguageWordInfo from '../common/OriginalLanguageWordInfo'
import PassagePopperContainer from './PassagePopperContainer'
import MorphInfo from './MorphInfo'

const OriginalLanguageWordPopperContent = ({
  hasHeader,
  ...props
}) => {

  // TODO: Show what this word is translated to in different versions (like on the BT app)

  return (
    <PassagePopperContainer $hasHeader={hasHeader} >

      <MorphInfo
        morph={props.morph}
        strong={props.strong}
        hasHeader={hasHeader}
      />

      <OriginalLanguageWordInfo
        {...props}
      />

    </PassagePopperContainer>
  )
}

export default memo(OriginalLanguageWordPopperContent)