import { gql } from '@apollo/client'

import user_fields from '../fragments/user_fields'

export default gql
`
  mutation createStudyBibleFollow($input: StudyBibleFollowInput!) {
    createStudyBibleFollow(input: $input) {
      ${user_fields}
    }
  }
`