import { useCallback } from 'react'

import useShortcuts from './useShortcuts'

import keyDownP from '../components/pages/shared/shortcuts/keyDownP'
import keyDownD from '../components/pages/shared/shortcuts/keyDownD'

const keyDownFuncs = {
  keyDownP,
  keyDownD,
}

const keysToUseWithNonModuleFocusOnly = [
  "p",
]

const useSharedShortcuts = params => {

  const inCorrectContext = useCallback(
    event => {

      // check that we are not focused on a module
      if(
        keysToUseWithNonModuleFocusOnly.includes(event.key)
        && document.activeElement.getAttribute('data-module-id')
      ) return false

      return true

    },
    [],
  )

  useShortcuts({
    keyDownFuncs,
    inCorrectContext,
    ...params,
  })

}

export default useSharedShortcuts