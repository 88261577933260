import useLayoutEffectAsync from './useLayoutEffectAsync'

export const setThemeColor = ({ effectiveDarkModeOn }) => {
  const metaEl = document.head.querySelector(`meta[name="theme-color"]`)
  if(metaEl) {
    metaEl.setAttribute(
      `content`,
      (
        effectiveDarkModeOn
          ? `#262626`
          : `#ffffff`
      ),
    )
  }
}

const useThemeColor = ({
  effectiveDarkModeOn,
}) => {

  useLayoutEffectAsync(
    () => setThemeColor({ effectiveDarkModeOn }),
    [ effectiveDarkModeOn ],
  )

  return null
}

export default useThemeColor