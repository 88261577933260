import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import LabelIcon from '@material-ui/icons/Label'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { getLocFromRef, getRefFromLoc } from '@bibletags/bibletags-versification'

// import { LoggedInUserContext } from '../../../context/LoggedInUser'
import useAppSize from '../../../hooks/useAppSize'
import useModalAnchor from '../../../hooks/useModalAnchor'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { equalTags } from '../../../utils/misc'

import Header from "../../common/Header"
import TaggerMiniPassageRefNavigator from './TaggerMiniPassageRefNavigator'
import PassageRefButton from '../../passage/PassageRefButton'
import MenuDivider from '../../common/MenuDivider'
import TaggerRules from './TaggerRules'
import TaggerStats from './TaggerStats'

const HeaderText = styled.div`
  font-weight: 300;
  line-height: 48px;
  font-size: 18px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`

const TagLogo = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 -10px 0 2px;

  .MuiSvgIcon-root {
    transform: rotate(90deg);
    font-size: 37px;
    color: ${({ theme }) => theme.palette.primary.dark};
  }

  &::before {
    content: "${({ $ot }) => $ot ? "א" : "α"}";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: ${({ $ot }) => $ot ? 3 : 8}px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: ${({ $ot }) => $ot ? 17 : 24}px;
  }
`

const MiniTagLogo = styled(TagLogo)`
  position: absolute;
  left: 48px;
  height: 48px;
  top: 5px;
  z-index: 1;
`

const OptionsIconButton = styled(IconButton)`
  padding: 10px;
  display: block;
  position: relative;
  margin-left: -10px;

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const MiniOptionsIconButton = styled(IconButton)`
  position: absolute;
  right: 48px;
  z-index: 1;

  @media (max-width: 370px) {
    z-index: 0;
    opacity: .4;
  }
`

const StyledCheckBoxIcon = styled(CheckBoxIcon)`
  font-size: 22px;
  margin: -5px 7px -5px -4px;
  color: ${({ theme }) =>theme.palette.primary.main};
`

const StyledCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)`
  font-size: 22px;
  margin: -5px 7px -5px -4px;
  color: ${({ theme }) => theme.palette.grey[400]};
`

const StyledTaggerRules = styled(TaggerRules)`
  max-width: 500px;
  padding: 0 16px 5px;
`

const RulesHeader = styled.div`
  font-weight: bold;
  text-align: center;
  padding: 7px 16px 0;
`

const TaggerHeader = ({
  versionId,
  setVersionId,
  loc,
  setLoc,
  tags,
  setTags,
  undoInfo,
  myTagSet,
  currentlyAcceptedTags,
  tagSetStatus,
  onClickPrevious,
  onClickNext,
  viewUntaggedOnly,
  setViewUntaggedOnly,
  setRulesOpenedAtLeastOnce,
  ready,
  ...otherProps
}) => {

  // const loggedInUser = useContext(LoggedInUserContext)

  const { miniHeaderSize } = useAppSize()
  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const toggleViewUntaggedOnly = useCallback(() => setViewUntaggedOnly(!viewUntaggedOnly), [ setViewUntaggedOnly, viewUntaggedOnly ])
  const ot = /^[0-3]/.test(loc)

  const updatePassageBookmark = useCallback(
    ({ versionId, ref, refs }) => {
      ref = ref || (refs || [])[0]
      if(ref) {
        setLoc(getLocFromRef(ref))
      }
      if(versionId) {
        setVersionId(versionId)
      }
    },
    [ setVersionId, setLoc ],
  )

  const clearOutTags = useCallback(() => { setTags([]); closeModal() }, [ setTags, closeModal ])
  const goUndo = useCallback(() => setTags(undoInfo.stack[undoInfo.idx], { isUndo: true }), [ setTags, undoInfo ])
  const goRedo = useCallback(() => setTags(undoInfo.stack[undoInfo.idx+2], { isRedo: true }), [ setTags, undoInfo ])
  const switchToMine = useCallback(() => { setTags(myTagSet.tags); closeModal() }, [ setTags, myTagSet, closeModal ])
  const switchToCurrentlyAccepted = useCallback(() => { setTags(currentlyAcceptedTags); closeModal() }, [ setTags, currentlyAcceptedTags, closeModal ])
  const returnToInstructions = useCallback(() => { setLoc(); closeModal() }, [ setLoc, closeModal ])

  useEffectAsync(() => anchorEl && setRulesOpenedAtLeastOnce(true), [ anchorEl ])

  return (
    <>

      <Header
        {...otherProps}
        centerExtraComponent={
          miniHeaderSize
          && (
            <TaggerMiniPassageRefNavigator
              versionId={versionId}
              loc={loc}
              {...(loc ? getRefFromLoc(loc) : {})}
              updatePassageBookmark={updatePassageBookmark}
              onClickPrevious={onClickPrevious}
              onClickNext={onClickNext}
              disabled={!ready}
            />
          )
        }
        leftExtraComponent={miniHeaderSize && <MiniTagLogo $ot={ot}><LabelIcon /></MiniTagLogo>}
        rightExtraComponent={
          miniHeaderSize
          && (
            <MiniOptionsIconButton
              onClick={openModal}
            >
              <MoreVertIcon />
            </MiniOptionsIconButton>
          )
        }
      >
        <HeaderText>
          <TagLogo $ot={ot}><LabelIcon /></TagLogo>
          <PassageRefButton
            versionId={versionId}
            {...(loc ? getRefFromLoc(loc) : {})}
            updatePassageBookmark={updatePassageBookmark}
            type="verse"
            showParallel={false}
            hideOriginals
            disabled={!ready}
          />
          <Tooltip
            title={i18n("Options")}
          >
            <span>
              <OptionsIconButton
                onClick={openModal}
              >
                <MoreVertIcon />
              </OptionsIconButton>
            </span>
          </Tooltip>
        </HeaderText>
      </Header>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeModal}
      >
        <MenuItem
          onClick={goUndo}
          disabled={!undoInfo.stack[undoInfo.idx]}
        >
          {i18n("Undo")}
        </MenuItem>
        <MenuItem
          onClick={goRedo}
          disabled={!undoInfo.stack[undoInfo.idx+2]}
        >
          {i18n("Redo")}
        </MenuItem>
        <MenuDivider />
        {!!myTagSet && !equalTags(currentlyAcceptedTags, myTagSet.tags) &&
          <MenuItem
            onClick={switchToMine}
            disabled={equalTags(tags, myTagSet.tags)}
          >
            {i18n("Change to your previous tag submission")}
          </MenuItem>
        }
        {!!currentlyAcceptedTags &&
          <MenuItem
            onClick={switchToCurrentlyAccepted}
            disabled={equalTags(tags, currentlyAcceptedTags)}
          >
            {[ `confirmed`, `unconfirmed` ].includes(tagSetStatus)
              ? i18n("Revert to accepted tags")
              : i18n("Revert to auto-match tagging")
            }
          </MenuItem>
        }
        <MenuItem
          onClick={clearOutTags}
          disabled={tags.length === 0}
        >
          {i18n("Clear Tags")}
        </MenuItem>
        <MenuDivider />
        <MenuItem
          onClick={toggleViewUntaggedOnly}
        >
          {viewUntaggedOnly && <StyledCheckBoxIcon />}
          {!viewUntaggedOnly && <StyledCheckBoxOutlineBlankIcon />}
          {i18n("Browse untagged only")}
        </MenuItem>
        <MenuItem
          onClick={returnToInstructions}
          disabled={!loc || !ready}
        >
          {i18n("Return to Vision and Instructions")}
        </MenuItem>
        <MenuDivider />
        <RulesHeader>
          {i18n("Tagging Rules")}
        </RulesHeader>
        <StyledTaggerRules
          hebrewOnly={ot}
          greekOnly={!ot}
        />
        <TaggerStats
          versionId={versionId}
          loc={loc}
        />
      </Menu>

    </>
  )
}

export default memo(TaggerHeader)