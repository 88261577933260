import { useState } from "react"
import { useApolloClient } from '@apollo/client'

import useGoUpdateModuleByProject from "./useGoUpdateModuleByProject"
import useEffectAsync from "./useEffectAsync"

import projectQuery from "../graphql/queries/project"
import moduleQuery from "../graphql/queries/module"

const useMoveModuleTo = ({
  module,
  onMoveComplete,  
}) => {

  const [ moduleMoveInfo, setModuleMoveInfo ] = useState({})

  const {
    project={},
    ordering,
  } = moduleMoveInfo
  
  const client = useApolloClient()
  const [ goCreateModuleByProject ] = useGoUpdateModuleByProject({ projectId: project.id })

  useEffectAsync(
    () => {
      if(project.id) {

        client.writeQuery({
          query: projectQuery,
          data: {
            project,
          },
          variables: {
            id: project.id,
          },
        })

        client.writeQuery({
          query: moduleQuery,
          data: {
            module: {
              ...module,
              moduleByProjects: [],
              // Does possibly not including some of the moduleByProjects cause any issues?
              // If so, evict this query from the cache.
            },
          },
          variables: {
            id: module.id,
          },
        })

        goCreateModuleByProject({
          moduleId: module.id,
          ordering,
        })

        setModuleMoveInfo({})

        onMoveComplete && onMoveComplete({ project })

      }
    },
    [ project.id ],
  )

  return setModuleMoveInfo
}

export default useMoveModuleTo
