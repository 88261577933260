import { memo, useMemo } from 'react'
import styled from 'styled-components'

import statsQuery from '../../../graphql/queries/stats'
import useDataQuery from '../../../hooks/useDataQuery'

import Loading from '../../common/Loading'
import StatsBarChart from './StatsBarChart'
import StatsLineChart from './StatsLineChart'
import { cloneObj } from '../../../utils/misc'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Heading = styled.div`
  font-size: 25px;
  font-weight: 300;
  margin: 40px 0 0;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin: 30px 0 5px;
`

const zeroOutDates = rows => {
  const adjusted = cloneObj(rows || [])
  adjusted.forEach(row => {
    if([ `Aug 29`, `Aug 29, 2023` ].includes(row.date)) {
      for(let key in row) {
        if(key !== `date`) {
          row[key] = 0
        }
      }
    }
  })
  return adjusted
}

const StatsTab = () => {

  const { stats, loading } = useDataQuery({
    statsQuery,
    fetchPolicy: 'network-only',
  })

  const {
    subscribersByLevel,
    toolByType,
    equipByType,
    userCreation,
    created,
    published,
    nondeferredEntriesByAuthor,
    ntChapterProgress,
    otChapterProgress,
  } = stats || {}

  const totalNondeferredByAuthor = useMemo(
    () => {
      const totalsObj = {}
      ;(nondeferredEntriesByAuthor || []).forEach(({ date, ...authors }) => {
        for(let author in authors) {
          totalsObj[author] = totalsObj[author] || { total: 0, Author: author }
          totalsObj[author].total += authors[author]
        }
      })
      return Object.values(totalsObj)
    },
    [ nondeferredEntriesByAuthor ],
  )

  const adjustedCreated = useMemo(() => zeroOutDates(created), [ created ])
  const adjustedNondeferredEntriesByAuthor = useMemo(() => zeroOutDates(nondeferredEntriesByAuthor), [ nondeferredEntriesByAuthor ])

  if(loading) {
    <Loading />
  }

  return (
    <Container>

      <Heading>
        Subscriptions & Accounts
      </Heading>

      <Title>
        Subscribers by Plan
      </Title>
      <StatsBarChart
        data={subscribersByLevel}
        xAxisLabel="Plan"
        color="#aaaaaa"
        width={400}
      />

      <Title>
        Biblearc TOOLS Subscriber Breakdown
      </Title>
      <StatsBarChart
        data={toolByType}
        xAxisLabel="Type"
        color="#5c829a"
      />

      <Title>
        Biblearc EQUIP Subscriber Breakdown
      </Title>
      <StatsBarChart
        data={equipByType}
        xAxisLabel="Type"
        color="#bdac59"
        width={700}
      />

      <Title>
        Account Creation
      </Title>
      <StatsLineChart
        data={userCreation}
        width={900}
      />

      <Heading>
        Study Bible Entries
      </Heading>

      <Title>
        Created by Testament
      </Title>
      <StatsLineChart
        data={adjustedCreated}
      />

      <Title>
        (Non-Deferred) Created by Author
      </Title>
      <StatsLineChart
        data={adjustedNondeferredEntriesByAuthor}
      />

      <Title>
        Total (Non-Deferred) by Author
      </Title>
      <StatsBarChart
        data={totalNondeferredByAuthor}
        xAxisLabel="Author"
        color="#95bb80"
      />

      <Title>
        Published
      </Title>
      <StatsLineChart
        data={published}
      />

      <Title>
        OT Progress by Book
      </Title>
      <StatsBarChart
        data={otChapterProgress}
        xAxisLabel="Book"
        width={900}
        totalKey="Total Chapters"
        color="#bdac59"
      />

      <Title>
        NT Progress by Book
      </Title>
      <StatsBarChart
        data={ntChapterProgress}
        xAxisLabel="Book"
        width={700}
        totalKey="Total Chapters"
        color="#d68945"
      />

    </Container>
  )
}

export default memo(StatsTab)