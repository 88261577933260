import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { getTextLanguageId, isRTLText } from "@bibletags/bibletags-ui-helper"
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// import useInstanceValue from '../../../hooks/useInstanceValue'
import useVersionInfo from '../../../hooks/useVersionInfo'
import useModalAnchor from '../../../hooks/useModalAnchor'
import useRefState from '../../../hooks/useRefState'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useEffectAsync from '../../../hooks/useEffectAsync'

// import Loading from '../../common/Loading'
import PassageRef from '../../common/PassageRef'
import TextContent from '../../common/TextContent'
import TextContentMarkupContainer from '../../markup/TextContentMarkupContainer'
import PassageInNotesOptionsPopover from './PassageInNotesOptionsPopover'
import { IS_EMBED } from '../../../utils/constants'
import { getEmbedMode, getEmbedSettings } from '../../../graphql/links/embedLink'

const Container = styled.div`
  padding: 10px;
  flex: 1;
`

const PassageRefAndVersionAbbrContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
`

const StyledTextContentMarkupContainer = styled(TextContentMarkupContainer)`
  align-self: flex-start;
  min-height: ${({ $minHeight }) => $minHeight};
  font-size: ${({ $textSize }) => $textSize}px;
  line-height: ${({ $lineSpacing }) => $lineSpacing};
`

const PassageRefContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`

const VersionAbbrs = styled.div`
  margin: 1px 5px 0 8px;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
`

const PassageRefAndVersionAbbrButton = styled(Button)`
  text-transform: none;
  padding: 3px 6px;
  margin: -3px -6px;

  ${({ disabled }) => !disabled ? `` : `
    &.Mui-disabled {
      color: inherit;
    }
    .MuiSvgIcon-root {
      display: none;
    }
  `}
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  height: 18px;
  width: 18px;
  color: ${({ theme }) => theme.palette.grey[600]};
  margin-right: -2px;
`

const PassageInNotesContent = ({
  refs=[],
  versionId,
  pieces,
  settings,
  onLoad,
  setPopoverComponent,
  markup,
  updateMarkup,
  width,
  mode,
  goSetPopperInfo,
  updateAtomicComponentSettings,
  blockKey,
  flipEditorDeleteThisBlock,
}) => {

  const { bookId } = refs[0] || {}
  const { safeVersionAbbr, version={} } = useVersionInfo(versionId)
  const languageId = getTextLanguageId({ ...version, bookId })
  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const updatePassage = useCallback(
    params => {
      if(
        !params.markup
        && params.versionId
        && params.versionId !== versionId
      ) {
        params.markup = []
      }
      updateAtomicComponentSettings(params)
    },
    [ updateAtomicComponentSettings, versionId ],
  )

  const getSettings = useInstanceValue(settings)
  const { textSize, lineSpacing, showCfs, showNotes, showHebrewVowels, showCantillation, showGreekAccents, greekPunctuation } = settings
  const [ displayedTextSize, setDisplayedTextSize, getDisplayedTextSize ] = useRefState(textSize)
  const [ displayedLineSpacing, setDisplayedLineSpacing, getDisplayedLineSpacing ] = useRefState(lineSpacing)
  const adjustmentType = (  // See note below
    (displayedTextSize !== textSize && `textsizes`)
    || (displayedLineSpacing !== lineSpacing && `linespacings`)
  )

  const commitSettings = useCallback(
    (newSettings={}) => {
      const filteredNewSettings = {}
      for(let key in newSettings) {
        if(
          [
            `showCfs`,
            `showNotes`,
            `showHebrewVowels`,
            `showCantillation`,
            `showGreekAccents`,
            `greekPunctuation`,
            `formattingKeyId`,
            `formattingKeyView`,
          ].includes(key)
        ) {
          filteredNewSettings[key] = newSettings[key]
        }
      }
      updateAtomicComponentSettings({
        settings: {
          ...getSettings(),
          textSize: getDisplayedTextSize(),
          lineSpacing: getDisplayedLineSpacing(),
          ...filteredNewSettings,
        }
      })
    },
    [ updateAtomicComponentSettings, getSettings, getDisplayedTextSize, getDisplayedLineSpacing ],
  )

  useEffectAsync(
    () => {
      setDisplayedTextSize(textSize)
      setDisplayedLineSpacing(lineSpacing)
    },
    [ setDisplayedTextSize, setDisplayedLineSpacing, textSize, lineSpacing ],
  )

  useEffectAsync(
    () => {
      setPopoverComponent({
        blockKey,
        popoverComponent: (
          <PassageInNotesOptionsPopover
            key={blockKey}
            refs={refs}
            versionId={versionId}
            languageId={languageId}
            anchorEl={anchorEl}
            onClose={closeModal}
            updatePassage={updatePassage}
            updateMarkup={updateMarkup}
            settings={settings}
            setDisplayedTextSize={setDisplayedTextSize}
            setDisplayedLineSpacing={setDisplayedLineSpacing}
            commitSettings={commitSettings}
            flipEditorDeleteThisBlock={flipEditorDeleteThisBlock}
            $adjustmentType={adjustmentType}  // this does not work because this value is not updated as it changes; yet I am leaving it here in case I look for a way to update this in the future
          />
        ),
      })
    },
    [ setPopoverComponent, blockKey, refs, versionId, anchorEl, closeModal, updateAtomicComponentSettings, updateMarkup, settings, setDisplayedTextSize, setDisplayedLineSpacing, commitSettings ],
  )

  return (
    <Container>

      <PassageRefAndVersionAbbrContainer>

        <PassageRefAndVersionAbbrButton
          onClick={openModal}
          disabled={mode === `display` || (IS_EMBED && getEmbedSettings().embedType === `passages` && getEmbedMode() !== `prep`)}
        >

          <PassageRefContainer>
            <PassageRef
              refs={refs}
              versionId={versionId}
              abbreviated={width < 320}
            />
          </PassageRefContainer>

          <VersionAbbrs>
            {safeVersionAbbr || versionId.toUpperCase()}
          </VersionAbbrs>

          <StyledExpandMoreIcon />

        </PassageRefAndVersionAbbrButton>

      </PassageRefAndVersionAbbrContainer>

      <StyledTextContentMarkupContainer
        $minHeight={!pieces ? `75px` : `auto`}
        $textSize={displayedTextSize}
        $lineSpacing={displayedLineSpacing}
        isRTL={isRTLText({ languageId, pieces })}
        markup={markup}
        onLoad={onLoad}
        // NOTE: If I end up allowing paragraph blocks, then I will need to remove
        // print-break-text-block from here and have it just in TextContent
        className="passage-in-notes-content-text-container print-break-text-block"
        data-blockkey={blockKey}
        selectMarkupOnDoubleClick
      >

        <TextContent
          pieces={pieces}
          versionId={versionId}
          bookId={bookId}
          startChapter={refs[0].chapter}
          showChapterNumbers={refs[0].chapter !== refs.at(-1).chapter}
          textSize={displayedTextSize}
          lineSpacing={displayedLineSpacing}
          showCfs={showCfs}
          showNotes={showNotes}
          showHebrewVowels={showHebrewVowels}
          showCantillation={showCantillation}
          showGreekAccents={showGreekAccents}
          greekPunctuation={greekPunctuation}
          loadingSize={20}
          goSetPopperInfo={goSetPopperInfo}
          showExpand
        />

      </StyledTextContentMarkupContainer>

    </Container>
  )

}

export default memo(PassageInNotesContent)


/*
{
  "blocks": [
    {
      "key": "fdr3n",
      "text": "",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "fg886",
      "text": " ",
      "type": "atomic",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [
        {
          "offset": 0,
          "length": 1,
          "key": 0
        }
      ],
      "data": {}
    },
  ],
  "entityMap": {
    "0": {
      "type": "passage",
      "mutability": "IMMUTABLE",
      "data": {
        "refs": [
          {
            "bookId": 2,
            "chapter": 1,
            "verse": 8
          },
          {
            "bookId": 2,
            "chapter": 1,
            "verse": 10
          }
        ],
        "versionId": "esv",
        "pastedInVerses": {
          "02001008": "No king there.",
          "02001010": "Let's be mean."
        },
        "markup": [
          {
            "start": {
              "loc": "02001008",
              "wordNumberInVerse": 3,
            },
            "end": {
              "loc": "02001010",
              "wordNumberInVerse": 2,
            },
            "style": "NORMAL-UNDERLINE",
            // "elevation": 1,  // 1-20
            "color": "BLUE"  // 
          },
          {
            "wordPart": {
              "loc": "02001008",
              "wordNumberInVerse": 3,
              "startChar": 2,
              "endChar": 4,
            },
            "style": "ZIG-ZAG-UNDERLINE",
            "color": "BLUE"  // 
          },
        ]
      }
    }
  }
}

  "inlineStyleRanges": [
    {
      "offset": 9,
      "length": 4,
      "style": "BOLD"
    },
    {
      "offset": 9,
      "length": 11,
      "style": "ITALIC"
    }
  ],

  RED
  BLUE
  YELLOW
  ORANGE
  GREEN
  PURPLE

  PINK
  LIGHT-BLUE






{
  "passages": [
    {
      "passage": {
        "testament": "NT",
        "bookindex": 51,
        "startchap": 1,
        "startvs": 17,
        "endchap": 1,
        "endvs": 17,
        "ref": "Colossians 1:17"
      },
      "text": "esv",
      "hidden": false,
      "textsize": 120,
      "linespacing": 170,
      "content": "<sup class=\"vsnum\" data-loc=\"51001017\">17</sup>And <sup class=\"cf\" data-cf=\"[John 8:58]; See John 1:1\" data-loc=\"51001017\">p</sup>he is before all things, and <span class=\"lhighlightstart\" data-lhighlightinfo=\"1,1,6:1:1,0:0,0:0:0,0\">in</span> him all things <sup class=\"cf\" data-cf=\"[Heb. 1:3]\" data-loc=\"51001017\">q</sup>hold <span class=\"lhighlightend lhe1\" data-endlhighlightinfo=\"1\">together</span>.  "
    }
  ],
  "texts": "",
  "settings": {
    "showcfs": true,
    "shownotes": true,
    "linespacing": 190,
    "textsize": 100,
    "showwoc": false,
    "showheadings": false
  }
}

{
  "passages": [
    {
      "passage": {
        "testament": "NT",
        "bookindex": 51,
        "startchap": 1,
        "startvs": 16,
        "endchap": 1,
        "endvs": 16,
        "ref": "Colossians 1:16"
      },
      "text": "esv",
      "hidden": false,
      "textsize": 120,
      "linespacing": 170,
      "content": "<sup class=\"vsnum\" data-loc=\"51001016\">16</sup>For by<sup data-loc=\"51001016\" class=\"note explanation\" data-note=\"That is, <span class='catch-word'>by</span> means of; or <i>in</i>\">1</sup> him all things were created, <sup class=\"cf\" data-cf=\"Eph. 1:10\" data-loc=\"51001016\">l</sup>in heaven and on earth, visible and invisible, whether <sup class=\"cf\" data-cf=\"[Ezek. 10:1]\" data-loc=\"51001016\">m</sup>thrones or <sup class=\"cf\" data-cf=\"Eph. 1:21\" data-loc=\"51001016\">n</sup>dominions or rulers or authorities—<span class=\"\">all</span> <span class=\"\">things</span> were <span class=\"\">created</span> <sup class=\"cf\" data-cf=\"Rom. 11:36; 1 Cor. 8:6\" data-loc=\"51001016\">o</sup><span class=\"lhighlightstart\" data-lhighlightinfo=\"4,1,6:1:1,0:0,0:0:0,0\">through</span> <span class=\"lhighlightend lhe4\" data-endlhighlightinfo=\"4\">him</span> and <span class=\"lhighlightstart\" data-lhighlightinfo=\"3,1,6:1:1,0:0,0:0:0,0\">for</span> <span class=\"lhighlightend lhe3\" data-endlhighlightinfo=\"3\">him</span>.  "
    }
  ],
  "texts": "",
  "settings": {
    "showcfs": true,
    "shownotes": true,
    "linespacing": 190,
    "textsize": 100,
    "showwoc": false,
    "showheadings": false
  }
}
*/


