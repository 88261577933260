import { memo } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import LinkIcon from '@material-ui/icons/Link'
import CheckIcon from '@material-ui/icons/Check'
import ShareIcon from '@material-ui/icons/Share'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useShareOrCopy from '../../hooks/useShareOrCopy'

const StyledIconButton = styled(IconButton)`
  padding: 6px;
  margin: -20px 0 -20px 3px;
`

const StyledLinkIcon = styled(LinkIcon)`
  height: 15px;
  width: 15px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const StyledCheckIcon = styled(CheckIcon)`
  height: 15px;
  width: 15px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const StyledShareIcon = styled(ShareIcon)`
  height: 15px;
  width: 15px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const CopySearchLinkIconButton = ({
  searchText,
  versionIds,
  includeOrIn,
  includeOrInRegex,
  hitsInfo,
}) => {

  const includeAddOn = ` ${includeOrIn}:${versionIds.length > 0 ? versionIds.join(',').toUpperCase() : `none`}`
  const newSearchText = `${searchText.replace(includeOrInRegex, '').replace(/  +/g, ' ')}${includeAddOn}`
  const url = `${window.location.origin}/#search=${encodeURIComponent(newSearchText)}`

  const { copyOrShare, willShare, justCopiedOrShared } = useShareOrCopy({
    shareData: {
      title: i18n("Biblearc Search: {{search_text}}", { search_text: newSearchText }),
      text: hitsInfo,
      url,
    },
  })

  return (
    <Tooltip
      title={i18n("Copy search link")}
    >
      <StyledIconButton
        onClick={copyOrShare}
      >
        {justCopiedOrShared ? <StyledCheckIcon /> : (willShare ? <StyledShareIcon /> : <StyledLinkIcon />)}
      </StyledIconButton>
    </Tooltip>
  )
}

export default memo(CopySearchLinkIconButton)