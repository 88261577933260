import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { useHistory, useLocation } from "react-router-dom"
import Button from '@material-ui/core/Button'

const Container = styled.div`
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NoneFound = styled.div`
  font-weight: 100;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 30px;
  font-size: 19px;
  text-align: center;
`

const ProjectsListNone = ({
  message,
}) => {

  const history = useHistory()
  const location = useLocation()
  const slug = location.pathname.split('/')[2] || 'projects'
  const search = (new URLSearchParams(location.search).get(`search`) || ``).trim()

  const clearSearch = useCallback(
    () => {
      history.replace({
        search: ``,
      })
    },
    [ history ],
  )

  return (
    <Container>

      <NoneFound>
        {
          message
          || {
            projects: [
              i18n("You do not have any projects."),
              i18n("No projects match your search."),
            ],
            starred: [
              i18n("You do not have any starred projects."),
              i18n("No starred projects match your search."),
            ],
            "shared-with-me": [
              i18n("No projects have been shared with you."),
              i18n("No projects shared with you match your search."),
            ],
            trash: [
              i18n("Your trash is empty."),
              i18n("No projects in your trash match your search."),
            ],
          }[slug][search ? 1 : 0]
        }
      </NoneFound>

      {!!search &&
        <Button
          onClick={clearSearch}
          variant="outlined"
        >
          {i18n("Clear search")}
        </Button>

      }

    </Container>
  )
}

export default memo(ProjectsListNone)