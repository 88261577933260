import React from "react"
import { i18n } from 'inline-i18n'

import {
  BORDER_COLOR,
  DEFAULT_ACTIVE_COLOR,
  LINK_FLOATER_WIDTH,
} from './utils/constants'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'

import FolderIcon from '@material-ui/icons/Folder'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  background: white;
  border: 1px solid ${BORDER_COLOR};
  padding: 10px;
  width: ${LINK_FLOATER_WIDTH}px;
  z-index: 1;
`

const TextFieldLine = styled.div`
  display: flex;
`

const InstructionsLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 4px;
  font-size: 14px;
`

const Instructions = styled.div`
  margin-right: 5px;
  visibility: hidden;
  color: rgba(0,0,0,.7);
`

const SwitchContainer = styled.div`
  margin: -4px 0;

  .MuiFormControlLabel-label {
    font-size: 13px;
    font-weight: 300;
  }
`

const StyledSwitch = styled(props => <Switch {...props} />)`
  margin-left: 7px;
`

const Actions = styled.div`
  white-space: nowrap;
`

const ActionA = styled.a`
  margin: 0 5px;
  color: ${DEFAULT_ACTIVE_COLOR};
`

const AssetOptions = styled.div`
  margin: -10px -5px 0 5px;
  display: inline-flex;
  flex-direction: row;
`

class LinkFloater extends React.PureComponent {

  state = {
    hasFocus: false,
  }

  handleFocus = () => this.setState({ hasFocus: true })

  handleBlur = () => this.setState({ hasFocus: false })

  handleChange = ({ target }) => {
    const { onChange } = this.props

    onChange({
      url: target.value.trim(),
    })
  }

  handleSwitch = ({ target }) => {
    const { onChange } = this.props

    onChange({
      openInNewTab: target.checked,
    })
  }

  handleMouseDown = event => {
    if(!event.target.closest('#flipeditor-link-url')) {
      event.preventDefault()
    }
  }

  openAssetListDialog = () => {
    const { openAssetListDialog, onChange } = this.props

    openAssetListDialog({
      setUrl: url => onChange({ url }),
    })
  }

  openUploadAssetDialog = ({ currentTarget }) => {
    const { openUploadAssetDialog, onChange } = this.props

    openUploadAssetDialog({
      setUrl: url => onChange({ url }),
    })
  }

  render() {
    const { style, url, openInNewTab, openAssetListDialog, openUploadAssetDialog, onRemove } = this.props
    const { hasFocus } = this.state

    const instructions =
      !hasFocus
        ? i18n("TAB to add a url")
        : (
          url === ""
            ? i18n("TYPE a url or TAB to remove")
            : ""
        )

    return (
      <Container
        style={style}
        id="flipeditor-link"
        onMouseDown={this.handleMouseDown}
      >
        <TextFieldLine>
          <TextField
            id="flipeditor-link-url"
            value={url}
            placeholder="https://..."
            fullWidth
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
          <AssetOptions>
            {!!openAssetListDialog &&
              <IconButton
                className="openAssetListDialog"
                onClick={this.openAssetListDialog}
              >
                <FolderIcon/>
              </IconButton>
            }
            {!!openUploadAssetDialog &&
              <IconButton
                className="openUploadAssetDialog"
                onClick={this.openUploadAssetDialog}
              >
                <CloudUploadIcon/>
              </IconButton>
            }
          </AssetOptions>

        </TextFieldLine>
        <InstructionsLine>
          {!url &&
            <Instructions>
              {instructions}
            </Instructions>
          }
          {!!url &&
            <SwitchContainer>
              <FormControlLabel
                control={
                  <StyledSwitch
                    id="flipeditor-link-open-in-new-tab"
                    color="primary"
                    size="small"
                    checked={openInNewTab}
                    onChange={this.handleSwitch}
                  />
                }
                label={i18n("Open in a new tab")}
              />
            </SwitchContainer>
          }
          <Actions>
            {!!url &&
              <ActionA
                onClick={this.testLink}
                href={url}
                target="_blank"
              >
                {i18n("test")}
              </ActionA>
            }
            <ActionA
              onMouseDown={onRemove}
              href="#"
            >
              {i18n("remove")}
            </ActionA>
          </Actions>
        </InstructionsLine>
      </Container>
    )
  }
}

export default LinkFloater