import { useCallback, useRef } from 'react'
import { useMeasure } from 'react-use'

import useRefState from './useRefState'
import useSetTimeout from './useSetTimeout'
import useStickyRefState from './useStickyRefState'
import useComplexInteractions from './useComplexInteractions'
import useInstanceValuesCallback from './useInstanceValuesCallback'
import useAppSize from './useAppSize'
import { getBoundedValue, getEaseInOutValue, isIOSOrAndroid } from '../utils/misc'

export const MAP_INTRINSIC_WIDTH = 248
export const MAP_INTRINSIC_HEIGHT = 150
export const MAX_MAP_SCALE = 100
const TRANSITION_MS = 300

// let clockMs = []
let clockMs = false

const clock = () => {
  // The following is used to clock the zoom speed in order to test stuff out and find jank
  if(clockMs) {
    clockMs.push(Date.now())
    if(clockMs.length === 10) {
      console.log('Average ms between wheel events', (clockMs.at(-1) - clockMs.at(0)) / (clockMs.length - 1))
      setTimeout(() => {
        clockMs = []
        console.log('clockMs cleared')
      }, 1000)
    }
  }
}

const useDragMap = ({ stage }) => {

  clock()

  let [ ref, { width, height, left, top } ] = useMeasure()
  const { width: windowWidth, height: windowHeight } = useAppSize()
  width = width || windowWidth
  height = height || windowHeight

  const mapProportion = MAP_INTRINSIC_WIDTH / MAP_INTRINSIC_HEIGHT
  const proportion = width ? (width / height) : 1
  const mapIsWider = mapProportion > proportion
  const baseMapWidth = mapIsWider ? (mapProportion * height) : width
  const baseMapHeight = !mapIsWider ? (width / mapProportion) : height

  const getTransformValues = useInstanceValuesCallback(
    mapTransformValues => {

      const x = .737
      const y = .62
      const scale = 15
      const maxMapTranslateX = (width - baseMapWidth * scale)
      const maxMapTranslateY = (height - baseMapHeight * scale)
      let translateX = -(baseMapWidth * scale * x - width / 2)
      let translateY = -(baseMapHeight * scale * y - height / 2)
      translateX = getBoundedValue(translateX, { min: maxMapTranslateX, max: 0 })
      translateY = getBoundedValue(translateY, { min: maxMapTranslateY, max: 0 })

      const values = {
        scale,
        translateX,
        translateY,
        ...(mapTransformValues || {}),
      }
      return values
    },
  )

  const [ mapTransformValues, setMapTransformValues, getMapTransformValues ] = useStickyRefState({ id: `useDragMap:mapTransformValues`, defaultValue: getTransformValues() })
  const [ moving, setMoving, getMoving ] = useRefState(false)
  let { scale, translateX, translateY } = getTransformValues(mapTransformValues)

  scale = getBoundedValue(scale, { min: 1, max: MAX_MAP_SCALE })

  const maxMapTranslateX = (width - baseMapWidth * scale)
  const maxMapTranslateY = (height - baseMapHeight * scale)
  translateX = getBoundedValue(translateX, { min: maxMapTranslateX, max: 0 })
  translateY = getBoundedValue(translateY, { min: maxMapTranslateY, max: 0 })

  const [ beyondBoundary, setBeyondBoundary, getBeyondBoundary ] = useRefState(``)
  const [ setBeyondBoundaryTimeout ] = useSetTimeout()

  const moveInfo = useRef()

  const setMapTransformValuesWithTransition = useInstanceValuesCallback(
    newValues => {
      const startTime = Date.now()
      const scaleAdjustment = newValues.scale - scale
      const translateXAdjustment = newValues.translateX - translateX
      const translateYAdjustment = newValues.translateY - translateY
      const doFrame = () => {
        const portionOfTimeElapsed = Math.min((Date.now() - startTime) / TRANSITION_MS, 1)
        const multiple = getEaseInOutValue(portionOfTimeElapsed)
        setMapTransformValues({
          scale: scale + scaleAdjustment * multiple,
          translateX: translateX + translateXAdjustment * multiple,
          translateY: translateY + translateYAdjustment * multiple,
        })
        if(portionOfTimeElapsed < 1) {
          requestAnimationFrame(doFrame)
        }
      }
      requestAnimationFrame(doFrame)
    }
  )

  const goScale = useInstanceValuesCallback(
    ({ newScale, x, y, doTransition }) => {
      let { scale, translateX, translateY } = getTransformValues(getMapTransformValues())
      const newScaleBounded = getBoundedValue(newScale, { max: MAX_MAP_SCALE, min: 1 })

      if(newScaleBounded !== scale) {

        const maxMapTranslateX = (width - baseMapWidth * newScale)
        const maxMapTranslateY = (height - baseMapHeight * newScale)
        const mouseX = x - left
        const mouseY = y - top
        const absX = mouseX - translateX
        const absY = mouseY - translateY
        const changeInScale = newScaleBounded / scale
        translateX -= changeInScale * absX - absX
        translateY -= changeInScale * absY - absY
        translateX = getBoundedValue(translateX, { min: maxMapTranslateX, max: 0 })
        translateY = getBoundedValue(translateY, { min: maxMapTranslateY, max: 0 })
        const setMapTransformValuesFn = doTransition ? setMapTransformValuesWithTransition : setMapTransformValues

        setMapTransformValuesFn({
          scale: newScaleBounded,
          translateX,
          translateY,
        })

      } else if(
        [ 1, MAX_MAP_SCALE ].includes(newScaleBounded)
        && !getBeyondBoundary()
        && Math.abs(1 - (newScale / scale)) > .1
      ) {
        setBeyondBoundary(`scale`)
        setBeyondBoundaryTimeout(() => setBeyondBoundary(``), 250)
      }

    },
  )

  const goCenterAndScale = useInstanceValuesCallback(
    ({ scale: newScale, x, y, doTransition }) => {
      newScale = newScale || scale
      const newScaleBounded = getBoundedValue(newScale, { max: MAX_MAP_SCALE, min: 1 })
      const maxMapTranslateX = (width - baseMapWidth * newScaleBounded)
      const maxMapTranslateY = (height - baseMapHeight * newScaleBounded)
      let translateX = -(baseMapWidth * newScaleBounded * x - width / 2)
      let translateY = -(baseMapHeight * newScaleBounded * y - height / 2)
      translateX = getBoundedValue(translateX, { min: maxMapTranslateX, max: 0 })
      translateY = getBoundedValue(translateY, { min: maxMapTranslateY, max: 0 })
      const setMapTransformValuesFn = doTransition ? setMapTransformValuesWithTransition : setMapTransformValues

      setMapTransformValuesFn({
        scale: newScaleBounded,
        translateX,
        translateY,
      })

    }
  )

  const goMove = useCallback(
    ({ newTranslateX, newTranslateY }) => {
      const { scale, translateX, translateY } = getTransformValues(getMapTransformValues())
      const newTranslateXBounded = getBoundedValue(newTranslateX, { min: maxMapTranslateX, max: 0 })
      const newTranslateYBounded = getBoundedValue(newTranslateY, { min: maxMapTranslateY, max: 0 })

      if(
        newTranslateXBounded !== translateX
        || newTranslateYBounded !== translateY
      ) {
        setMapTransformValues({
          scale,
          translateX: newTranslateXBounded,
          translateY: newTranslateYBounded,
        })
      }

      const beyondX = (
        Math.abs(newTranslateX - translateX) > 15
        && [ maxMapTranslateX, 0 ].includes(newTranslateXBounded)
        && maxMapTranslateX < -100
      )
      const beyondY = (
        Math.abs(newTranslateY - translateY) > 15
        && [ maxMapTranslateY, 0 ].includes(newTranslateYBounded)
        && maxMapTranslateY < -100
      )

      const newBeyondBoundary = `${beyondX ? `x` : ``}${beyondY ? `y` : ``}`
      if(getBeyondBoundary() !== newBeyondBoundary) setBeyondBoundary(newBeyondBoundary)
      if(!getMoving()) setMoving(true)

    },
    [ getBeyondBoundary, setBeyondBoundary, getMapTransformValues, setMapTransformValues, getTransformValues, maxMapTranslateX, maxMapTranslateY, getMoving, setMoving ],
  )

  const onWheel = useCallback(
    event => {
      const { scale } = getTransformValues(getMapTransformValues())
      const { deltaY, clientX: x, clientY: y } = event
      const newScale = scale - (deltaY/1000) * scale
      goScale({ newScale, x, y })
    },
    [ goScale, getTransformValues, getMapTransformValues ],
  )

  const doubleClickEvents = useComplexInteractions({
    onDoubleClick: event => {
      const { clientX: x, clientY: y } = event
      let { scale } = getTransformValues(getMapTransformValues())
      goScale({ newScale: scale * 2, x, y, doTransition: !!TRANSITION_MS })
    },
    onStartInteraction: event => {
      const { clientX, clientY } = event
      let { translateX, translateY } = getTransformValues(getMapTransformValues())
      moveInfo.current = {
        translateX,
        translateY,
        clientX,
        clientY,
        scale,
      }
    },
    onInteractionComplete: () => {
      moveInfo.current = null
      if(getBeyondBoundary()) setBeyondBoundary(``)
      setTimeout(() => setMoving(false))
    },
    onMove: event => {
      const { translateX, translateY, clientX, clientY } = moveInfo.current
      const newTranslateX = translateX + (event.clientX - clientX)
      const newTranslateY = translateY + (event.clientY - clientY)
      goMove({ newTranslateX, newTranslateY })
    },
    onPinchToZoom: ({ pinchChangeInPixels, x, y }) => {
      const { scale } = moveInfo.current
      const newScale = scale + pinchChangeInPixels * scale * .005
      goScale({
        newScale,
        x,
        y,
      })
    },
    skipPreventDefaultOnTouch: true,
  })

  const adjustedScale = scale / MAX_MAP_SCALE

  const transition = isIOSOrAndroid ? `` : `opacity .2s ease-in-out`

  const scaleAdjusterStyle = baseMapWidth && {
    width: baseMapWidth * MAX_MAP_SCALE,
    height: baseMapHeight * MAX_MAP_SCALE,
    transform: `scale(${adjustedScale})`,
    transition,
  }

  const positionAdjusterStyle = {
    transform: `translate(${translateX}px, ${translateY}px)`,
    transition,
  }

  const getPinStyle = ({ locations: [{ x, y }], opacity=1, visibleFromScaleLevel=1, type, events, about }) => {
    const effectiveOpacity = scale >= visibleFromScaleLevel ? opacity : 0
    const effectiveHasEventsOrAbout = stage === `DRAFT` || (events || []).length > 0 || !!about || [ `CITY`, `MOUNTAIN`, `HOUSE`, `GARDEN`, `SPOT` ].includes(type)
    return {
      transform: `scale(${1/adjustedScale})`,
      transition,
      left: `${Math.round(baseMapWidth * x * 100)}px`,
      top: `${Math.round(baseMapHeight * y * 100)}px`,
      opacity: effectiveOpacity,
      pointerEvents: (effectiveOpacity && effectiveHasEventsOrAbout) ? `auto` : `none`,
      cursor: effectiveHasEventsOrAbout ? `pointer` : `move`,
    }
  }

  const getPlaceInfo = useCallback(
    ({ place: { locations: [{ x, y }] }, mapScale }) => ({
      scale: 1 / (mapScale / MAX_MAP_SCALE),
      left: Math.round(baseMapWidth * x * 100),
      top: Math.round(baseMapHeight * y * 100),
    }),
    [ baseMapWidth, baseMapHeight],
  )

  const lowestX = (translateX/-scale)/baseMapWidth
  const lowestY = (translateY/-scale)/baseMapHeight
  const highestX = (width/baseMapWidth)/scale + lowestX
  const highestY = (height/baseMapHeight)/scale + lowestY

  const mapViewInfo = {
    scale,
    translateX,
    translateY,
    top,
    left,
    baseMapWidth,
    baseMapHeight,
    width,
    height,
    lowestX,
    lowestY,
    highestX,
    highestY,
  }

  return {
    scaleAdjusterStyle,
    positionAdjusterStyle,
    getPinStyle,
    getPlaceInfo,
    goCenterAndScale,
    mapViewInfo,
    moving,
    ref,
    onWheel,
    ...doubleClickEvents,
    $opacity: beyondBoundary ? .4 : 1,
  }

}

export default useDragMap