import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

const userUpdate = gql`
  query userUpdate($updatedSince: Milliseconds!) {
    userUpdate(updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`

export default userUpdate