import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'

import useInstanceValue from '../../hooks/useInstanceValue'
import useDownloadImageShortcuts from '../../hooks/useDownloadImageShortcuts'
import { getLocalStorage } from '../../utils/misc'

import CustomSlider from './CustomSlider'
import CustomSwitch from './CustomSwitch'

export const MIN_IMAGE_WIDTH = 500
export const MAX_IMAGE_WIDTH = 4000

const Container = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const StyledTextField = styled(TextField)`
  margin: 10px 0 15px;
`

const StyledCustomSlider = styled(CustomSlider)`
  margin: 5px 0;
`

// const Note = styled.div`
//   color: ${({ theme }) => theme.palette.grey[500]};
//   margin-bottom: 10px;
//   font-size: 13px;
// `

const Pixels = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin: -10px 0 5px;
  font-size: 12px;
  align-self: flex-end;
  visibility: ${({ $hide }) => !$hide ? `visible` : `hidden`};
  cursor: default;
`

const ImageOptions = ({
  format,
  moduleWidth,
  moduleWidthInChange,
  // imageWidth,
  imageWidthInChange,
  showLogo,
  blackAndWhite,
  whiteBackground,
  updateInfo,
  captureInfo,
}) => {

  const onChangeFormat = useCallback(
    ({ target }) => {
      const moduleWidth = getLocalStorage(`initialValue:moduleWidth:${target.value}`, 800)
      updateInfo({
        ...getLocalStorage(`initialValue:${target.value}Options`, {}),
        format: target.value,
        moduleWidth,
        moduleWidthInChange: moduleWidth,
      })
    },
    [ updateInfo ],
  )

  const updateImageWidthInChange = useCallback((event, value) => updateInfo({ imageWidthInChange: value }), [ updateInfo ])
  const getImageWidthInChange = useInstanceValue(imageWidthInChange)
  const commitImageWidth = useCallback(
    () => {
      updateInfo({
        imageWidth: getImageWidthInChange(),
      })
    },
    [ updateInfo, getImageWidthInChange ],
  )

  useDownloadImageShortcuts({
    getImageWidthInChange,
    updateImageWidthInChange,
    commitImageWidth,
  })

  const updateModuleWidthInChange = useCallback((event, value) => updateInfo({ moduleWidthInChange: -value }), [ updateInfo ])
  const getModuleWidthInChange = useInstanceValue(moduleWidthInChange)
  const commitModuleWidth = useCallback(
    () => {
      updateInfo({
        moduleWidth: getModuleWidthInChange(),
      })
    },
    [ updateInfo, getModuleWidthInChange ],
  )

  const toggleBlackAndWhite = useCallback(({ target }) => updateInfo({ blackAndWhite: target.checked }), [ updateInfo ])
  const toggleWhiteBackground = useCallback(({ target }) => updateInfo({ whiteBackground: target.checked }), [ updateInfo ])
  const toggleShowLogo = useCallback(({ target }) => updateInfo({ showLogo: target.checked }), [ updateInfo ])

  const resizingModuleWidth = moduleWidth !== moduleWidthInChange
  const waitingToRenderNewModuleWidth = moduleWidth !== (captureInfo.options || {}).moduleWidth
  const resizingOrRenderingModuleWidth = resizingModuleWidth || waitingToRenderNewModuleWidth

  return (
    <Container>

      <StyledTextField
        select
        label={i18n("Format")}
        fullWidth
        variant="outlined"
        value={format}
        color="secondary"
        size="small"
        onChange={onChangeFormat}
      >
        <MenuItem
          value="png"
        >
          {i18n("PNG (image)")}
        </MenuItem>
        <MenuItem
          value="pdf"
        >
          {i18n("PDF (document)")}
        </MenuItem>
        {/* <MenuItem
          value="svg"
        >
          {i18n("SVG (vector graphic)")}
        </MenuItem> */}
      </StyledTextField>

      <StyledCustomSlider
        label={i18n("Zoom")}
        min={-1985}  // use negatives so it is reverse direction
        max={-285}
        step={17}
        color="secondary"
        value={-moduleWidthInChange}
        onChange={updateModuleWidthInChange}
        onChangeCommitted={commitModuleWidth}
      />

      <StyledCustomSlider
        label={i18n("Image Size")}
        min={MIN_IMAGE_WIDTH}
        max={MAX_IMAGE_WIDTH}
        step={2}
        color="secondary"
        value={imageWidthInChange}
        onChange={updateImageWidthInChange}
        onChangeCommitted={commitImageWidth}
        disabled={format === `svg`}
      />

      {/* {format === `svg` &&
        <Note>
          {i 18n("Note: As a vector graphic, you will be able to resize this image without loss of sharpness.")}
        </Note>
      } */}

      {format === `png` &&
        <Tooltip
          title={i18n("Use arrow keys for fine-grain adjustments")}
        >
          <Pixels
            $hide={resizingOrRenderingModuleWidth}
          >
            {i18n("{{width}}px × {{height}}px", {
              width: imageWidthInChange,
              height: Math.round(captureInfo.imageHeight * (imageWidthInChange/(captureInfo.options || {}).imageWidth)),
            })}
          </Pixels>
        </Tooltip>
      }

      <CustomSwitch
        checked={blackAndWhite}
        onChange={toggleBlackAndWhite}
        label={i18n("Black and white")}
      />

      <CustomSwitch
        checked={whiteBackground}
        onChange={toggleWhiteBackground}
        label={i18n("Add white background")}
      />

      <CustomSwitch
        checked={showLogo}
        onChange={toggleShowLogo}
        label={i18n("Show Biblearc logo")}
      />

    </Container>
  )
}

export default memo(ImageOptions)