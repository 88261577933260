import { useMemo } from "react"
import { getStartAndEndVersesByChapter } from "@bibletags/bibletags-versification"

import useVersionInfo from './useVersionInfo'
import { KJV_VERSION } from "../utils/constants"

const useStartAndEndVersesByChapter = ({
  versionId,
  bookId,
 }) => {

  const { version=KJV_VERSION } = useVersionInfo(versionId)

  const info = useMemo(
    () => getStartAndEndVersesByChapter({ versionInfo: version, bookId }),
    [ version, bookId ]
  )

  return info
}

export default useStartAndEndVersesByChapter
