import React, { useContext, useMemo, useCallback } from 'react'

import useDataQuery from '../hooks/useDataQuery'
import useGoSetAccountSetting from '../hooks/useGoSetAccountSetting'
import useIsLoggedIn from '../hooks/useIsLoggedIn'
import useVersionInfos from '../hooks/useVersionInfos'
import { LoggedInUserContext } from './LoggedInUser'
import { OfflineVersionsContext } from './LocalInfo'
import { cloneObj, getObjFromArrayOfObjs } from '../utils/misc'
import { SELECTED_VERSION_LIST_LENGTH_LIMIT } from '../utils/constants'
import { db } from '../utils/database'

import accountSettingQuery from '../graphql/queries/accountSetting'

// setupStatus options: `online-only`, `downloading`, `offline-ready`

export const SelectedVersionsContext = React.createContext([])

export const SelectedVersionsContextProvider = ({
  children,
}) => {

  const user = useContext(LoggedInUserContext)
  const offlineVersions = useContext(OfflineVersionsContext)
  const isLoggedIn = useIsLoggedIn()

  const accountSettingId = `${(user || {}).id}:selected-versions`
  let { accountSetting } = useDataQuery({
    accountSettingQuery,
    skip: !isLoggedIn,
    variables: {
      id: accountSettingId,
    },
  })

  const goSetSelectedVersions = useGoSetAccountSetting(accountSettingId)
  const selectedVersionsFromAccountSetting = (accountSetting || {}).value

  const partialVersionInfos = useMemo(
    () => {

      const offlineVersionsById = getObjFromArrayOfObjs(offlineVersions)

      const alteredSelectedVersion = cloneObj(
        selectedVersionsFromAccountSetting
          ? (
            selectedVersionsFromAccountSetting.map(version => ({
              setupStatus: (offlineVersionsById[version.id] || {}).setupStatus || `online-only`,
              ...version,
            }))
          )
          : (offlineVersions || [])  // for users who are not logged in or have never had accountSettings/[userId]:selected-versions set
      )

      return (
        alteredSelectedVersion
          .map((version, idx) => ({
            ...version,
            ordering: idx + 1,
          }))
          .slice(0, SELECTED_VERSION_LIST_LENGTH_LIMIT)
      )

    },
    [ offlineVersions, selectedVersionsFromAccountSetting ],
  )

  const updateSelectedVersionIds = useCallback(
    async newSelectedVersionIds => {

      if(isLoggedIn) {
        const versionInfosById = getObjFromArrayOfObjs(partialVersionInfos)

        goSetSelectedVersions(
          newSelectedVersionIds.map(id => {
            const selectedVersion = { id }
            if((versionInfosById[id] || {}).hide) {
              selectedVersion.hide = true
            }
            return selectedVersion
          })
        )

      } else {

        await db.transaction(
          'rw',
          db.offlineVersions,
          async () => {

            const offlineVersionsById = getObjFromArrayOfObjs(offlineVersions)

            await Promise.all([

              ...newSelectedVersionIds.map(async (versionId, idx) => {
                if((offlineVersionsById[versionId] || {}).ordering !== idx + 1) {
                  await db.offlineVersions.put({
                    id: versionId,
                    setupStatus: 'online-only',
                    ...(offlineVersionsById[versionId] || {}),
                    ordering: idx+1,
                  })
                }
              }),

              ...(
                offlineVersions
                  .filter(({ id }) => !(newSelectedVersionIds || []).includes(id))
                  .map(async ({ id }) => {
                    await db.offlineVersions.delete(id)
                  })
              ),

            ])

          }
        )

      }
    },
    [ isLoggedIn, goSetSelectedVersions, offlineVersions, partialVersionInfos ],
  )

  const toggleHideSelectedVersion = useCallback(
    async versionId => {

      if(isLoggedIn) {
        const versionInfosById = getObjFromArrayOfObjs(partialVersionInfos)

        goSetSelectedVersions(
          partialVersionInfos.map(({ id }) => {
            const selectedVersion = { id }
            if(!!versionInfosById[id].hide !== (id === versionId)) {
              selectedVersion.hide = true
            }
            return selectedVersion
          })
        )

      } else {
        const newHide = !offlineVersions.find(({ id }) => id === versionId).hide
        await db.offlineVersions.update(versionId, { hide: newHide })

      }
    },
    [ isLoggedIn, goSetSelectedVersions, offlineVersions, partialVersionInfos ],
  )

  const { versionInfos } = useVersionInfos({
    versionIds: partialVersionInfos.map(({ id }) => id),
    limit: SELECTED_VERSION_LIST_LENGTH_LIMIT,
  })

  const selectedVersionsAndUpdateFunc = useMemo(
    () => {
      const selectedVersionInfos = cloneObj(partialVersionInfos)
      const selectedVersionInfoIdxById = {}
      selectedVersionInfos.forEach(({ id }, idx) => {
        selectedVersionInfoIdxById[id] = idx
      })

      ;(versionInfos || []).forEach(({ id, safeVersionAbbr, version }) => {
        selectedVersionInfos[selectedVersionInfoIdxById[id]].safeVersionAbbr = safeVersionAbbr
        selectedVersionInfos[selectedVersionInfoIdxById[id]].version = version
      })

      selectedVersionInfos.forEach(alteredVersionInfo => {
        alteredVersionInfo.safeVersionAbbr = (
          alteredVersionInfo.safeVersionAbbr
          || alteredVersionInfo.abbr
          || alteredVersionInfo.id.toUpperCase()
        )
      })

      return {
        selectedVersionInfos,
        updateSelectedVersionIds,
        toggleHideSelectedVersion,
      }
    },
    [ partialVersionInfos, versionInfos, updateSelectedVersionIds, toggleHideSelectedVersion ],
  )

  return (
    <SelectedVersionsContext.Provider value={selectedVersionsAndUpdateFunc}>
      {children}
    </SelectedVersionsContext.Provider>
  )
}