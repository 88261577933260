import { memo, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import FormatSizeIcon from '@material-ui/icons/FormatSize'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import WebIcon from '@material-ui/icons/Web'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
// import { useNetworkState } from 'react-use'

import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import { ChannelIdInPWAContext } from '../../../context/ChannelIdInPWA'

const Container = styled.div`
  width: 300px;
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    background-color: white;
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    transition: left .2s ease-in-out;
    left: ${({ $expanded }) => $expanded ? 0 : `-300px`};
  }
`

const SectionNavLink = styled(NavLinkOrAWithDisable)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }

  &.active .MuiListItem-button {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    cursor: default;
  }
`

const styleIcon = () => `
  margin-right: 10px;
`

const StyledMenuBookIcon = styled(MenuBookIcon)(styleIcon)
const StyledFormatSizeIcon = styled(FormatSizeIcon)(styleIcon)
const StyledOndemandVideoIcon = styled(OndemandVideoIcon)(styleIcon)
const StyledWebIcon = styled(WebIcon)(styleIcon)
const StyledNotificationsActiveIcon = styled(NotificationsActiveIcon)(styleIcon)

const SettingsLeftPanel = ({
  expanded,
  close,
  openDisplaySettings,
}) => {

  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  // const { online } = useNetworkState()

  return (
    <Container $expanded={expanded}>

      <List>

        <SectionNavLink to="/settings/versions">
          <ListItem
            button
            onClick={close}
          >
            <StyledMenuBookIcon />
            <ListItemText primary={i18n("Bible Versions")} />
          </ListItem>
        </SectionNavLink>

        <ListItem
          button
          onClick={openDisplaySettings}
        >
          <StyledFormatSizeIcon />
          <ListItemText primary={i18n("Bible Display Settings")} />
        </ListItem>

        <SectionNavLink to="/settings/ui">
          <ListItem
            button
            onClick={close}
          >
            <StyledWebIcon />
            <ListItemText primary={i18n("User Interface Settings")} />
          </ListItem>
        </SectionNavLink>

        {!channelIdInPWA &&
          <SectionNavLink to="/settings/channels">
            <ListItem
              button
              onClick={close}
            >
              <StyledOndemandVideoIcon />
              <ListItemText primary={i18n("Channels You Follow")} />
            </ListItem>
          </SectionNavLink>
        }

        <SectionNavLink to="/settings/notifications">
          <ListItem
            button
            onClick={close}
          >
            <StyledNotificationsActiveIcon />
            <ListItemText primary={i18n("Notifications")} />
          </ListItem>
        </SectionNavLink>

      </List>

    </Container>
  )
}

export default memo(SettingsLeftPanel)