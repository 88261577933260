import { memo, useCallback, useContext, useMemo, useState } from 'react'
import { useCounter } from 'react-use'
import { useLocation } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import { useMutation, useApolloClient } from '@apollo/client'
// import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import SnoozeIcon from '@material-ui/icons/Snooze'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Popper from '@material-ui/core/Popper'
import Menu from '@material-ui/core/Menu'
import Tooltip from '@material-ui/core/Tooltip'

import { LoggedInUserContext } from '../../context/LoggedInUser'
import { GET_STUDY_BIBLE_ITEM_TYPES, GET_EDITOR_UNHIDDEN_STUDY_BIBLE_ITEM_KEYS } from '../../utils/constants'
import { equalObjs, equalObjsWithIgnores } from '../../utils/misc'
import useStickyRefState from '../../hooks/useStickyRefState'
import useMutationContext from '../../hooks/useMutationContext'
import useModalAnchor from '../../hooks/useModalAnchor'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import useDataQuery from '../../hooks/useDataQuery'
import useSetTimeout from '../../hooks/useSetTimeout'

import StudyBibleItemEditorInsightInfo from './StudyBibleItemEditorInsightInfo'
import StudyBibleItemEditorSermonInfo from './StudyBibleItemEditorSermonInfo'
import StudyBibleItemEditorImageInfo from './StudyBibleItemEditorImageInfo'
import StudyBibleItemEditorMapInfo from './StudyBibleItemEditorMapInfo'
import StudyBibleItemEditorGenealogyInfo from './StudyBibleItemEditorGenealogyInfo'
import StudyBibleItemEditorTimelineInfo from './StudyBibleItemEditorTimelineInfo'
import StudyBibleItemEditorIntroductionInfo from './StudyBibleItemEditorIntroductionInfo'
import StudyBibleItem from './StudyBibleItem'
import StudyBibleItemComments from './StudyBibleItemComments'
import StudyBibleItemHistoryTooltip from './StudyBibleItemHistoryTooltip'
import StudyBibleItemTooltip from './StudyBibleItemTooltip'
import PassageRef from '../common/PassageRef'
import ImageUrlTextField from '../common/ImageUrlTextField'
import RelativeTime from '../common/RelativeTime'
import Loading from '../common/Loading'
import DangerMenuItem from '../common/DangerMenuItem'
import MenuDivider from '../common/MenuDivider'
import TagEditor from '../common/TagEditor'
import Avatar from '../common/Avatar'

import studyBibleItemTagsQuery from '../../graphql/queries/studyBibleItemTags'
import studyBibleItemQuery from '../../graphql/queries/studyBibleItem'
import updateStudyBibleItemMutation from '../../graphql/mutations/updateStudyBibleItem'
import deleteStudyBibleItemMutation from '../../graphql/mutations/deleteStudyBibleItem'

const REACTION_TYPES = [ `THUMBS-UP`, `EXCLAMATION` ]

let prevOnClose = null

const StyledPopper = styled(Popper)`
  right: 10px !important;
  bottom: 10px !important;
  top: auto !important;
  left: auto !important;
  transform: none !important;
  z-index: 1200;
  width: 400px;
  height: 600px;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);

  ${({ $allTemporarilyDisabled }) => !$allTemporarilyDisabled ? `` : `
    visibility: hidden;
  `}

`

const BackCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;
`

const Header = styled.div`
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  padding: 5px;
`

const HeaderInfo = styled.div`
  flex: 1;
  line-height: 1.1;
  padding: 10px;
`

const CommentsIconContainer = styled.div`
  position: relative;
`

const CommentsCount = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid white;
  color: white;
  font-size: 7px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  pointer-events: none;
`

const EditorTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
`

const PassageRefContainer = styled.div`
  font-size: 13px;
`

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 0 0 4px 4px;
  display: flex;
  gap: 10px;
  padding: 10px 10px 3px;
`

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
  padding: 10px 0;
`

const Div = styled.div``

const StyledDivider = styled(Divider)`
  margin: 10px 0;
`

const StyledTextField = styled(ImageUrlTextField)`
  margin: 10px 15px;
  width: calc(100% - 30px);
`

const ContributorLine = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
`

const Dates = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px 5px;
  font-size: 10px;
  font-weight: 300;
`

const CreatedAt = styled.div`
  user-select: none;
`

const UpdatedAt = styled.div`
  font-style: italic;
  user-select: none;
`

const StatusIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: 1;

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const nonstatusStyle = {
  opacity: .2,
}

const StudyBibleItemEditor = ({
  open,
  id,
  versionId,
  onClose,
}) => {

  const { adminLevel, id: loggedInUserId } = useContext(LoggedInUserContext) || {}
  const client = useApolloClient()

  const location = useLocation()
  const showInternalComments = /#internal-comments(?:#|$)/.test(location.hash)

  const [ updateStudyBibleItem ] = useMutation(updateStudyBibleItemMutation)
  const [ deleteStudyBibleItem ] = useMutation(deleteStudyBibleItemMutation)
  const context = useMutationContext()

  const { studyBibleItem } = useDataQuery({
    studyBibleItemQuery,
    variables: {
      id,
    },
    skip: !id,
  })

  const { studyBibleItemTags=[] } = useDataQuery({
    studyBibleItemTagsQuery,
    fetchPolicy: `cache-and-network`,
    skip: !open,
  })
  const publicTagOptions = useMemo(() => studyBibleItemTags.filter(({ tag }) => !/^INTERNAL:/.test(tag)), [ studyBibleItemTags ])
  const internalTagOptions = useMemo(
    () => (
      studyBibleItemTags
        .filter(({ tag }) => /^INTERNAL:/.test(tag))
        .map(tagObj => ({ ...tagObj, label: `#${tagObj.tag.replace(/^INTERNAL:/, ``)}` }))
    ),
    [ studyBibleItemTags ],
  )

  const [ loading, setLoading ] = useState(false)
  const [ allTemporarilyDisabled, toggleAllTemporarilyDisabled ] = useSimpleToggle()
  const [ studyBibleItemInEdit, setStudyBibleItemInEdit, getStudyBibleItemInEdit ] = useStickyRefState({
    id: `StudyBibleItemEditor:${JSON.stringify(id)}`,
    defaultValue: studyBibleItem || {},
  })
  const studyBibleItemInEditPublicTags = useMemo(() => (studyBibleItemInEdit.tags || []).filter(({ tag }) => !/^INTERNAL:/.test(tag)), [ studyBibleItemInEdit ])
  const studyBibleItemInEditInternalTags = useMemo(() => (studyBibleItemInEdit.tags || []).filter(({ tag }) => /^INTERNAL:/.test(tag)), [ studyBibleItemInEdit ])
  const [ showComments, toggleShowComments ] = useSimpleToggle()

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const [ previewKey, { inc }] = useCounter(1)
  const updatePreview = useCallback(() => inc(), [ inc ])

  const { status } = studyBibleItem || {}
  const isEditor = [ 'ADMIN', 'EDITOR' ].includes(adminLevel)
  const allDisabled = (
    (
      status !== `DRAFT-UNLOCKED`
      && !isEditor
    )
    || status === `DEFERRED`
    || allTemporarilyDisabled
  )

  const commonTextFieldProps = useMemo(
    () => ({
      variant: "outlined",
      size: "small",
      fullWidth: true,
      InputLabelProps: {
        shrink: true,
      },
      disabled: allDisabled,
    }),
    [ allDisabled ],
  )

  const { id: studyBibleItemId } = studyBibleItem || {}
  const defaultInputForNew = useMemo(
    () => ({
      type: `INTERNAL`,
      studyBibleItemId,
    }),
    [ studyBibleItemId ],
  )

  const onChange = useCallback(
    key => ({ target }) => {
      setStudyBibleItemInEdit({
        ...getStudyBibleItemInEdit(),
        [key]: target.value,
      })
    },
    [ getStudyBibleItemInEdit, setStudyBibleItemInEdit ],
  )

  const onChangeTitle = useCallback(onChange(`title`), [ onChange ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeHeading = useCallback(onChange(`heading`), [ onChange ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeThumbnailImgUrl = useCallback(onChange(`thumbnailImageUrl`), [ onChange ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeAdminRating = useCallback(onChange(`adminRating`), [ onChange ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeUserId = useCallback(onChange(`userId`), [ onChange ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeType = useCallback(
    event => {
      onChange(`type`)(event)
      updatePreview()
    },
    [ onChange, updatePreview ],
  )

  const updatePublicTags = useCallback(
    tags => {
      onChange(`tags`)({ target: { value: [ ...tags, ...studyBibleItemInEditInternalTags ] }})
      updatePreview()
    },
    [ onChange, studyBibleItemInEditInternalTags, updatePreview ],
  )
  const updateInternalTags = useCallback(
    tags => {
      onChange(`tags`)({ target: { value: [ ...studyBibleItemInEditPublicTags, ...tags ] }})
      updatePreview()
    },
    [ onChange, studyBibleItemInEditPublicTags, updatePreview ],
  )

  const onChangeInfo = useCallback(
    key => ({ target }) => {
      onChange(`info`)({
        target: {
          value: {
            ...(getStudyBibleItemInEdit().info || {}),
            [key]: target.value,
          },
        },
      })
    },
    [ onChange, getStudyBibleItemInEdit ],
  )

  const onChangeDetails = useCallback(
    value => {
      onChange(`details`)({
        target: {
          value,
        },
      })
    },
    [ onChange ],
  )

  const refreshQueriesAndClose = useCallback(
    async () => {

      await client.refetchQueries({
        include: [ 'studyBibleItems' ],
        onQueryUpdated: ({ options: { variables }}) => {
          const chapterLoc = studyBibleItem.fromLoc.slice(0,5)
          const bookLoc = studyBibleItem.fromLoc.slice(0,2)
          const queryLoc = (((variables.query || ``).match(/(?:^| )[0-9]{2,5}(?:$| )/g) || [])[0] || ``).trim()
          return [ chapterLoc, bookLoc, `` ].includes(queryLoc)
        },
      })

      onClose()

    },
    [ studyBibleItem, client, onClose ],
  )

  const goUpdateStudyBibleItem = useCallback(
    async input => {

      const { data: { updateStudyBibleItem: updatedStudyBibleItem } } = await updateStudyBibleItem({
        variables: {
          id: studyBibleItemId,
          input,
        },
        context,
      })

      if(
        input.userId
        && input.userId !== loggedInUserId
        && !isEditor
      ) {
        await refreshQueriesAndClose()
        return
      }

      setStudyBibleItemInEdit(updatedStudyBibleItem)

    },
    [ updateStudyBibleItem, studyBibleItemId, context, loggedInUserId, isEditor, refreshQueriesAndClose, setStudyBibleItemInEdit ],
  )

  const onSave = useCallback(
    async () => {
      setLoading(true)

      const input = { ...getStudyBibleItemInEdit() }
      for(let key in input) {
        if(
          [ `reactions`, `numOpenComments`, `editHistory` ].includes(key)
          || equalObjs(input[key], studyBibleItem[key])
        ) {
          delete input[key]
        }
      }
      await goUpdateStudyBibleItem(input)

      setLoading(false)
    },
    [ studyBibleItem, goUpdateStudyBibleItem, getStudyBibleItemInEdit ],
  )

  const updateStatus = useCallback(
    async ({ status }) => {

      closeModal()
      setLoading(true)

      await goUpdateStudyBibleItem({ status })

      setLoading(false)

    },
    [ closeModal, goUpdateStudyBibleItem ],
  )

  const onDelete = useCallback(
    async () => {
      closeModal()
      setLoading(true)

      await deleteStudyBibleItem({
        variables: {
          id: studyBibleItem.id,
        },
        context,
      })

      await refreshQueriesAndClose()

      setLoading(false)

    },
    [ closeModal, studyBibleItem, deleteStudyBibleItem, context, refreshQueriesAndClose ],
  )

  const refetchStudyBibleItem = useCallback(
    async () => {
      // this is needed to update numOpenComments
      await client.query({
        query: studyBibleItemQuery,
        variables: {
          id: studyBibleItemId,
        },
        fetchPolicy: `network-only`,
        context,
      })
    },
    [ client, studyBibleItemId, context ],
  )

  const [ setDiscardTimeout ] = useSetTimeout()
  const discardEdits = useCallback(
    () => {
      closeModal()
      setDiscardTimeout(() => {
        if(studyBibleItem) {
          setStudyBibleItemInEdit(studyBibleItem)
        }
        updatePreview()
      }, 250)
    },
    [ setDiscardTimeout, studyBibleItem, setStudyBibleItemInEdit, closeModal, updatePreview ],
  )

  useLayoutEffectAsync(
    () => {
      if(
        studyBibleItem
        && Object.values(getStudyBibleItemInEdit()).length === 0
      ) {
        setStudyBibleItemInEdit(studyBibleItem)
      }
    },
    [ studyBibleItemId ],
  )

  useLayoutEffectAsync(
    () => {
      // only allow one StudyBibleItemEditor to be open at once
      if(open) {
        try {
          prevOnClose && prevOnClose()
        } catch(e) {}
        prevOnClose = onClose
      } else if(prevOnClose === onClose) {
        prevOnClose = null
      }
    },
    [ open ],
  )

  useLayoutEffectAsync(
    () => {
      if(showInternalComments && studyBibleItem) {
        toggleShowComments({ force: true })
      }
    },
    [ showInternalComments, !!studyBibleItem ],
  )

  const studyBibleItemTypes = GET_STUDY_BIBLE_ITEM_TYPES()

  const type = studyBibleItemInEdit.type || `INSIGHT`
  const StudyBibleItemEditorInfo = {
    INSIGHT: StudyBibleItemEditorInsightInfo,
    QUOTE: StudyBibleItemEditorInsightInfo,
    SERMON: StudyBibleItemEditorSermonInfo,
    LECTURE: StudyBibleItemEditorSermonInfo,
    PODCAST: StudyBibleItemEditorSermonInfo,
    COURSE: StudyBibleItemEditorSermonInfo,
    IMAGE: StudyBibleItemEditorImageInfo,
    MAP: StudyBibleItemEditorMapInfo,
    GENEALOGY: StudyBibleItemEditorGenealogyInfo,
    TIMELINE: StudyBibleItemEditorTimelineInfo,
    CREED: StudyBibleItemEditorInsightInfo,
    INTRODUCTION: StudyBibleItemEditorIntroductionInfo,
  }[type] || Div

  const saved = equalObjsWithIgnores(studyBibleItem, studyBibleItemInEdit, [ `reactions`, `numOpenComments`, `editHistory` ])
  const disableOptions = saved && [ 'PUBLISHED' ].includes(status)
  const info = studyBibleItemInEdit.info || {}
  const notReadyToPublish = (
    !saved
    || !(studyBibleItemInEdit.title || ``).trim()
    || !(studyBibleItemInEdit.heading || ``).trim()
    || (
      [ `INSIGHT`, `QUOTE`, `IMAGE`, `CREED` ].includes(type)
      && (
        !(info.imageUrl || ``).trim()
        || !(info.imageDimensions || {}).width
        || !(info.imageDimensions || {}).height
        || (
          (info.sourceThumbnailImgUrl || ``).trim()
          && (
            !(info.sourceThumbnailImgDimensions || {}).width
            || !(info.sourceThumbnailImgDimensions || {}).height
          )
        )
      )
    )
    || (
      [ `SERMON`, `LECTURE`, `PODCAST`, `COURSE` ].includes(type)
      && (
        !(info.mediaUrl || ``).trim()
        || !(info.quote || ``).trim()
        || (
          (info.sourceThumbnailImgUrl || ``).trim()
          && (
            !(info.sourceThumbnailImgDimensions || {}).width
            || !(info.sourceThumbnailImgDimensions || {}).height
          )
        )
      )
    )
    || ![ `INSIGHT`, `QUOTE`, `SERMON`, `LECTURE`, `PODCAST`, `COURSE`, `IMAGE`, `CREED` ].includes(type)  // TODO
  )

  return (
    <>

      <StyledPopper
        open={open}
        anchorEl={document.body}
      >

        <StudyBibleItemTooltip
          open={open && !allTemporarilyDisabled}
          resizeKey={previewKey}
          title={
            <StudyBibleItem
              studyBibleItem={studyBibleItemInEdit}
              versionId={versionId}
            />
          }
        >
          <Container
            $allTemporarilyDisabled={allTemporarilyDisabled}
          >

            <Header>

              <HeaderInfo>

                <EditorTitle>
                  {i18n("Edit “{{name}}”", {
                    name: (studyBibleItem || {}).title || i18n("[ title ]"),
                  })}
                </EditorTitle>

                <PassageRefContainer>
                  <PassageRef
                    fromLoc={studyBibleItemInEdit.fromLoc}
                    toLoc={studyBibleItemInEdit.toLoc}
                    convertToVersionId={versionId}
                  />
                </PassageRefContainer>

              </HeaderInfo>

              <Tooltip
                title={
                  showComments
                    ? i18n("Hide internal comments", "", "admin")
                    : i18n("Show internal comments", "", "admin")
                }
              >
                <CommentsIconContainer>
                  <IconButton
                    onClick={toggleShowComments}
                    color={showComments ? `primary` : undefined}
                  >
                    <ChatOutlinedIcon />
                  </IconButton>
                  {(studyBibleItem || {}).numOpenComments > 0 &&
                    <CommentsCount>
                      {(studyBibleItem || {}).numOpenComments}
                    </CommentsCount>
                  }
                </CommentsIconContainer>
              </Tooltip>

              <IconButton
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>

            </Header>

            {!showComments &&
              <Content>

                <StyledTextField
                  {...commonTextFieldProps}
                  label={i18n("Title")}
                  placeholder={i18n("e.g. What Was the Sign of Jonah?")}
                  value={studyBibleItemInEdit.title || ``}
                  onChange={onChangeTitle}
                  onBlur={updatePreview}
                />

                <StyledTextField
                  {...commonTextFieldProps}
                  label={i18n("Heading")}
                  placeholder={i18n("e.g. Sermon by John Piper")}
                  value={studyBibleItemInEdit.heading || ``}
                  onChange={onChangeHeading}
                  onBlur={updatePreview}
                />

                <TagEditor
                  options={publicTagOptions}
                  tags={studyBibleItemInEditPublicTags}
                  updateTags={updatePublicTags}
                  StyledTextField={StyledTextField}
                  textFieldProps={{
                    label: i18n("Add Public Tags", "", "admin"),
                    ...commonTextFieldProps
                  }}
                />

                <TagEditor
                  options={internalTagOptions}
                  tags={studyBibleItemInEditInternalTags}
                  updateTags={updateInternalTags}
                  prefix="INTERNAL:"
                  StyledTextField={StyledTextField}
                  chipColor="primary"
                  textFieldProps={{
                    label: i18n("Add Internal Tags", "", "admin"),
                    ...commonTextFieldProps
                  }}
                />

                <StyledTextField
                  {...commonTextFieldProps}
                  select
                  label={i18n("Type")}
                  value={type}
                  onChange={onChangeType}
                  disabled={status === `PUBLISHED` || allDisabled}
                >
                  {GET_EDITOR_UNHIDDEN_STUDY_BIBLE_ITEM_KEYS().map(type => (
                    <MenuItem
                      key={type}
                      value={type}
                    >
                      {studyBibleItemTypes[type].label}
                    </MenuItem>
                  ))}
                </StyledTextField>

                <StudyBibleItemEditorInfo
                  StyledTextField={StyledTextField}
                  commonTextFieldProps={commonTextFieldProps}
                  StyledDivider={StyledDivider}
                  type={type}
                  info={info}
                  details={studyBibleItemInEdit.details}
                  onChangeInfo={onChangeInfo}
                  onChangeDetails={onChangeDetails}
                  allDisabled={allDisabled}
                  allTemporarilyDisabled={allTemporarilyDisabled}
                  toggleAllTemporarilyDisabled={toggleAllTemporarilyDisabled}
                  updatePreview={updatePreview}
                />

                <StyledDivider />

                <StyledTextField
                  {...commonTextFieldProps}
                  label={i18n("Thumbnail Image")}
                  value={studyBibleItemInEdit.thumbnailImageUrl || ``}
                  onChange={onChangeThumbnailImgUrl}
                  onBlur={updatePreview}
                  isImageUrl
                  includeUploadButton
                  disabled
                />

                <StyledTextField
                  {...commonTextFieldProps}
                  select
                  label={i18n("Rating (not seen by end-users)")}
                  value={studyBibleItemInEdit.adminRating || 1}
                  onChange={onChangeAdminRating}
                >
                  {Array(10).fill().map((x, idx) => (
                    <MenuItem
                      key={idx}
                      value={idx + 1}
                    >
                      {i18n("{{num}} stars", {
                        num: (idx+1) * .1 + 4,
                      })}
                    </MenuItem>
                  ))}
                </StyledTextField>

                <ContributorLine>
                  <StyledTextField
                    {...commonTextFieldProps}
                    label={i18n("Contributor ID")}
                    value={studyBibleItemInEdit.userId}
                    onChange={onChangeUserId}
                  />
                  <Tooltip title={`${(studyBibleItemInEdit.user || {}).name} [user:${studyBibleItemInEdit.userId}]`}>
                    <span>
                      <Avatar
                        user={studyBibleItemInEdit.user || {}}
                        size="small"
                      />
                    </span>
                  </Tooltip>
                </ContributorLine>

              </Content>
            }

            {showComments && !!studyBibleItem &&
              <Content>
                <StudyBibleItemComments
                  defaultInputForNew={defaultInputForNew}
                  query={`studyBibleItemId:${studyBibleItem.id} type:INTERNAL`}
                  reactionTypes={REACTION_TYPES}
                  allowReactionsToSelf
                  enableCheckmark
                  refetchItem={refetchStudyBibleItem}
                />
              </Content>
            }

            <Footer>

              <Button
                disableElevation
                variant="contained"
                color="secondary"
                onClick={onSave}
                disabled={saved || allDisabled}
              >
                {i18n("Save")}
              </Button>

              <Button
                disableElevation
                variant={status === `PUBLISHED` ? "outlined" : "contained"}
                color="secondary"
                onClick={
                  () => {
                    updateStatus({
                      status: (
                        status === `DRAFT-LOCKED`
                          ? `PUBLISHED`
                          : `DRAFT-LOCKED`
                      ),
                    })

                  }
                }
                disabled={notReadyToPublish || allDisabled}
              >
                {
                  (isEditor && [ 'DRAFT-LOCKED', 'PUBLISHED' ].includes(status))
                    ? (
                      status === `PUBLISHED`
                        ? i18n("Unpublish")
                        : i18n("Publish")
                    )
                    : i18n("Submit")
                }
              </Button>

              <StatusIcons>
                <Tooltip title={i18n("Deferred")}>
                  <SnoozeIcon style={status !== `DEFERRED` ? nonstatusStyle : null} />
                </Tooltip>
                <Tooltip title={i18n("Contributor Draft")}>
                  <LockOpenIcon style={status !== `DRAFT-UNLOCKED` ? nonstatusStyle : null} />
                </Tooltip>
                <Tooltip title={i18n("Submitted to Editor")}>
                  <LockIcon style={status !== `DRAFT-LOCKED` ? nonstatusStyle : null} />
                </Tooltip>
                <Tooltip title={i18n("Published")}>
                  <CloudDoneIcon style={status !== `PUBLISHED` ? nonstatusStyle : null} />
                </Tooltip>
              </StatusIcons>

              <Tooltip
                title={(disableOptions && isEditor) ? i18n("Unpublish to edit options.", "", "admin") : ``}
              >
                <span>
                  <IconButton
                    onClick={openModal}
                    disabled={disableOptions || allTemporarilyDisabled}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={closeModal}
                disabled={allTemporarilyDisabled}
              >
                {!saved &&
                  <MenuItem
                    onClick={discardEdits}
                  >
                    {i18n("Discard Edits")}
                  </MenuItem>
                }
                {saved && status === `DRAFT-LOCKED` && isEditor &&
                  <MenuItem
                    onClick={() => updateStatus({ status: `DRAFT-UNLOCKED` })}
                  >
                    {i18n("Return to Contributor")}
                  </MenuItem>
                }
                {saved && status === `DEFERRED` &&
                  <MenuItem
                    onClick={() => updateStatus({ status: `DRAFT-UNLOCKED` })}
                  >
                    {i18n("Restore as Draft")}
                  </MenuItem>
                }
                {saved && (status === `DRAFT-UNLOCKED` || ([ `DRAFT-UNLOCKED`, `DRAFT-LOCKED`].includes(status) && isEditor)) &&
                  <MenuItem
                    onClick={() => updateStatus({ status: `DEFERRED` })}
                  >
                    {i18n("Defer")}
                  </MenuItem>
                }
                {saved && ([ `DRAFT-UNLOCKED`, `DEFERRED`].includes(status) || ([ `DRAFT-LOCKED`, `DEFERRED`].includes(status) && isEditor)) && <MenuDivider />}
                {saved && (isEditor || [ `DRAFT-UNLOCKED`, `DEFERRED`].includes(status)) &&
                  <DangerMenuItem
                    onClick={onDelete}
                  >
                    {i18n("Delete")}
                  </DangerMenuItem>
                }
              </Menu>

            </Footer>

            <Dates>
              <CreatedAt>
                {i18nReact("Created: {{date}}", {
                  date: (
                    <RelativeTime date={studyBibleItemInEdit.createdAt} />
                  ),
                })}
              </CreatedAt>
              <StudyBibleItemHistoryTooltip
                editHistory={studyBibleItemInEdit.editHistory}
              >
                <UpdatedAt>
                  {i18nReact("Updated: {{date}}", {
                    date: (
                      <RelativeTime date={studyBibleItemInEdit.updatedAt} />
                    ),
                  })}
                </UpdatedAt>
              </StudyBibleItemHistoryTooltip>
            </Dates>

          </Container>
        </StudyBibleItemTooltip>

        {loading && <Loading bgOpacity={0} />}

      </StyledPopper>

      {allTemporarilyDisabled && <BackCover />}

    </>
  )
}

export default memo(StudyBibleItemEditor)