export const getGroupSubscriptionFields = ({ includeSubscriptions=true }={}) => `
  id
  name
  term
  plan
  quantity
  stripePaymentIntentId
  userId
  createdAt
  updatedAt
  ${!includeSubscriptions ? `` : `
    subscriptions {
      id
      createdAt
      user {
        id
        name
        email
      }
    }
  `}
`

export default getGroupSubscriptionFields()