import { ContentBlock, EditorState, genKey } from 'draft-js'

const handleAtomicKeyDown = ({
  event,
  editorState,
  onChange,
  toggleSettings,
  getComponentDefinition,
  deleteAtomicBlock,
}) => {

  if(
    ![
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
    ].includes(event.key)
    && !event.ctrlKey
    && !event.metaKey
    && !event.altKey
  ) {

    event.preventDefault()
    event.stopPropagation()

    const content = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const anchorKey = selection.getAnchorKey()
    const focusKey = selection.getFocusKey()

    switch(event.key) {

      case "Backspace": {

        const focusBlock = content.getBlockForKey(focusKey)
        deleteAtomicBlock(focusBlock)

        break
      }

      case "Enter": {
        const blockMap = content.getBlockMap()

        const baseBlock = blockMap.get(anchorKey)

        const blocksBefore = blockMap
          .toSeq()
          .takeUntil(v => v === baseBlock)

        const blocksAfter = blockMap
          .toSeq()
          .skipUntil(v => v === baseBlock)
          .rest()

        const newKey = genKey()
        const newBlock = new ContentBlock({
          key: newKey,
          text: '',
        })

        const newBlocks = blocksBefore
          .concat(
            [
              [anchorKey, baseBlock],
              [newKey, newBlock],
            ],
            blocksAfter,
          )
          .toOrderedMap()

        const newContent = content.merge({
          blockMap: newBlocks,
          selectionBefore: selection,
          selectionAfter: selection.merge({
            anchorKey: newKey,
            anchorOffset: 0,
            focusKey: newKey,
            focusOffset: 0,
            isBackward: false,
          }),
        })

        onChange(
          EditorState.push(editorState, newContent, 'split-block')
        )

        break
      }

      case "Tab": {

        const focusBlock = content.getBlockForKey(focusKey)
    
        const entity = content.getEntity(
          focusBlock.getEntityAt(0)
        )
        const { dataStructure=[], lang } = getComponentDefinition(entity.getType())
    
        toggleSettings({
          block: focusBlock,
          dataStructure,
          lang,
        })

        break
      }

    }
  }
}

export default handleAtomicKeyDown