import { v4 as uuidv4 } from 'uuid'

// See this spreadsheet for data flow information: https://docs.google.com/spreadsheets/d/1HQTbo3YS0IShtKfuxyfFrklCnmju9V432iQmQH2wSzk/edit?usp=sharing

const getMutationSettings = ({
  operationName,
  offlineSetupStatus,
  presetQueuedMutationId,
}) => {

  const online = navigator.onLine

  let saveFirstToDexie = false
  let dexieRowLimit = null
  let updatedSinceType = null
  let runThroughQueuedMutations = false
  let runThroughServer = false
  let saveServerResultToDexie = false
  let isUserUpdateMutation = false

  switch (operationName) {  // eslint-disable-line default-case

    // must be online to do; does NOT change logged in user's offline data
    case 'dismissAlert':
    case 'createStripeCheckoutSession':
    case 'createStripePortalSession':
    case 'createUser':
    case 'updateUser':
    case 'cancelSubscription':
    case 'createSubscription':
    case 'addToMailingList':
    case 'inviteGroupSubscriptionMembers':
    case 'updateGroupSubscription':
    case 'deleteUser':
    case 'emailMeMyUserData':
    case 'createLoginLink':
    case 'addAlertsItemToUsers':
    case 'populateWords':
    case 'createLearningResourcesItem':
    case 'updateLearningResourcesItem':
    case 'deleteLearningResourcesItem':
    case 'createStudyBibleItem':
    case 'updateStudyBibleItem':
    case 'deleteStudyBibleItem':
    case 'createStudyBibleAssetUploadLink':
    case 'editStudyBibleImage':
    case 'createCustomStudyBibleItem':
    case 'updateCustomStudyBibleItem':
    case 'deleteCustomStudyBibleItem':
    case 'createStudyBibleItemComment':
    case 'updateStudyBibleItemComment':
    case 'deleteStudyBibleItemComment':
    case 'addReactionToStudyBibleItem':
    case 'removeReactionFromStudyBibleItem':
    case 'addReactionToCustomStudyBibleItem':
    case 'removeReactionFromCustomStudyBibleItem':
    case 'addReactionToStudyBibleItemComment':
    case 'removeReactionFromStudyBibleItemComment':
    case 'addReactionToChannelItem':
    case 'removeReactionFromChannelItem':
    case 'updateChannelItemBookmark':
    case 'updateNotificationsDevice':
    case 'requestEditingLock':
    case 'releaseEditingLock':
    case 'updateMapLayer':
    case 'publishMapLayer':
    case 'updateGlobalAlertsItem':
    case 'deleteGlobalAlertsItem':
    case 'createChannel':
    case 'updateChannel':
    case 'deleteChannel':
    case 'createChannelAdmin':
    case 'updateChannelAdmin':
    case 'deleteChannelAdmin': {
      runThroughServer = online
      break
    }

    // must be online to do; changes logged in user's `user`
    case 'createStudyBibleFollow':
    case 'deleteStudyBibleFollow':
    case 'submitActionCode':
    case 'updateStripeSubscription':
    case 'requestFreeToolsSubscription': {
      runThroughServer = online
      saveServerResultToDexie = true
      break
    }

    // must be online to do; returns a `userUpdate` but should not change anything
    case 'emptyTrash':
    case 'permanentlyDeleteProject':
    case 'permanentlyDeleteModule': {
      runThroughServer = online
      updatedSinceType = `userUpdate`
      break
    }

    // must be online to do; returns a `userUpdate` with content to save to dexie
    case 'restoreProject':
    case 'restoreModule': {
      runThroughServer = online
      updatedSinceType = `userUpdate`
      saveServerResultToDexie = true
      break
    }

    // stuff that works offline of type `userUpdate`
    case 'updateProject':
    case 'deleteProject':
    case 'updateFolder':
    case 'deleteFolder':
    case 'createTag':
    case 'deleteTag':
    case 'updateModule':
    case 'deleteModule':
    case 'updateModuleByProject':
    case 'deleteModuleByProject':
    case 'updateModulePassages':
    case 'deleteModulePassages':
    case 'setModuleSetting':
    case 'updateModulePieces':
    case 'deleteModulePieces':
    case 'updateModuleDots':
    case 'deleteModuleDots':
    case 'updateModuleMarkups':
    case 'deleteModuleMarkups':
    case 'updateFormattingKey':
    case 'deleteFormattingKey':
    case 'updateHighlights':
    case 'deleteHighlights': {
      saveFirstToDexie = !presetQueuedMutationId
      updatedSinceType = (online && !presetQueuedMutationId) ? `userUpdate` : null
      runThroughServer = online && (presetQueuedMutationId || offlineSetupStatus !== `on-mutation-sync`)
      runThroughQueuedMutations = true
      saveServerResultToDexie = true
      isUserUpdateMutation = true
      break
    }

    // stuff that works offline, NOT of type `userUpdate`
    case 'setAccountSettings': {
      saveFirstToDexie = !presetQueuedMutationId
      updatedSinceType = `accountSettings`
      runThroughServer = online && (presetQueuedMutationId || offlineSetupStatus !== `on-mutation-sync`)
      runThroughQueuedMutations = true
      saveServerResultToDexie = true
      break
    }

    case 'submitTagSet': {
      // saveFirstToDexie = !presetQueuedMutationId
      runThroughServer = online
      runThroughQueuedMutations = true
      // saveServerResultToDexie = true
      break
    }

    // logging
    case 'logProjectPassageHistoryItem':
    case 'logQueryItem': {
      saveFirstToDexie = ![ `off` ].includes(offlineSetupStatus)
      dexieRowLimit = 1000
      runThroughQueuedMutations = true
      runThroughServer = online
      break
    }

  }

  const failDueToNoConnection = !online && !runThroughQueuedMutations

  return {
    failDueToNoConnection,
    saveFirstToDexie,
    dexieRowLimit,
    updatedSinceType,
    runThroughQueuedMutations,
    attemptingAt: (runThroughQueuedMutations && runThroughServer) ? Date.now() : 0,
    queuedMutationId: runThroughQueuedMutations ? (presetQueuedMutationId || uuidv4()) : null,
    runThroughServer,
    saveServerResultToDexie,
    isUserUpdateMutation,
  }
}

export default getMutationSettings