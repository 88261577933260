const getSubscriptionFields = ({ isAdmin }={}) => `
  id
  type
  plan
  ${isAdmin ? `stripeSubscriptionId` : ``}
  stripeCurrentCoupon
  term
  status
  cancelAtPeriodEnd
  additionalDonationAmount
  currentPeriodEndsAt
  createdAt
  updatedAt
  groupSubscription {
    id
    name
  }
`

export const getUserFields = ({ isAdmin }={}) => `
  id
  email
  name
  image
  adminLevel
  blockedFromSharing
  stripeBalance
  languageId
  createdAt
  updatedAt
  deletedAt
  activeSubscriptions { ${getSubscriptionFields({ isAdmin })} }
  ${isAdmin ? `internalNotes` : ``}
  ${isAdmin ? `stripeCustomerId` : ``}
  ${isAdmin ? `subscriptions { ${getSubscriptionFields({ isAdmin })} }` : ``}
  studyBibleFollows {
    id
    channelUrl
    channelId
  }
`

export default getUserFields()