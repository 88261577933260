import { memo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useModalAnchor from '../../hooks/useModalAnchor'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import useAppSize from '../../hooks/useAppSize'

import StudyBibleItemThumbnail from './StudyBibleItemThumbnail'
import OptionsPopper from '../common/OptionsPopper'

const gap = 15

const StyledOptionsPopper = styled(OptionsPopper)`
  .OptionsPopper-Container {
    padding: ${gap}px;
    overflow-y: auto;
    max-height: min(calc(100vh - 16px), 700px);
    color: black;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: ${gap}px;
    flex-wrap: wrap;
    max-width: min(100vw, ${({ $cols }) => ((208 + gap) * $cols + gap)}px);
  }
`

const Link = styled.div`
  display: inline-block;
  font-size: 13px;
  line-height: 25px;
  transform: translateY(-3px);
  transition: opacity .15s ease-in-out;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;

  ${({ $open }) => !$open ? `` : `
    color: black;
    text-decoration: none;
  `};
  
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      opacity: .4;
    }
  }
`

const Entry = styled.div`
  width: 208px;
  margin: -5px 0 -10px;
`

const PassageStudyBibleViewOrMoreLink = ({
  isMoreLink,
  isRTL,
  hiddenStudyBibleItems,
  setStudyBibleItemInfo,
  setChannelItemInfo,
  versionId,
  children,
  ...otherProps
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const [ numCols, setNumCols ] = useState(0)
  const { width } = useAppSize()

  useLayoutEffectAsync(
    () => {
      if(anchorEl) {
        setNumCols(
          Math.max(
            Math.min(
              (
                isRTL
                  ? Math.floor(((width - anchorEl.getBoundingClientRect().right) - gap - 25) / (208 + gap))
                  : Math.floor((anchorEl.getBoundingClientRect().left - gap - 25) / (208 + gap))
              ),
              Math.ceil(hiddenStudyBibleItems.length / 3),
              3,
            ),
            1
          )
        )
      }
    },
    [ anchorEl, isRTL, width ],
  )

  const setItemInfoAndCloseModal = useCallback(
    info => {
      if((info.studyBibleItem || {}).type === `CHANNEL`) {
        setChannelItemInfo(info)
      } else {
        setStudyBibleItemInfo(info)
      }
      closeModal()
    },
    [ setStudyBibleItemInfo, setChannelItemInfo, closeModal ],
  )

  return (
    <>

      <Link
        onClick={openModal}
        $open={!!anchorEl}
        {...otherProps}
      >
        {
          children
          || (
            isMoreLink
              ? i18n("View {{num}} more", { num: hiddenStudyBibleItems.length })
              : i18n("View {{num}} item(s)", { num: hiddenStudyBibleItems.length })
          )
        }
      </Link>

      <StyledOptionsPopper
        open={!!anchorEl && numCols > 0}
        anchorEl={anchorEl}
        onClose={closeModal}
        bgColor="white"
        placement={isRTL ? `right` : `left`}
        onTopOfAll
        isModal
        $cols={numCols}
        allowDarkMode
      >

        {hiddenStudyBibleItems.map(studyBibleItem => (

          <Entry key={studyBibleItem.id}>

            <StudyBibleItemThumbnail
              studyBibleItem={studyBibleItem}
              versionId={versionId}
              setItemInfo={setItemInfoAndCloseModal}
              fromViewOrMoreLink
            />

          </Entry>

        ))}

      </StyledOptionsPopper>

    </>
  )
}

export default memo(PassageStudyBibleViewOrMoreLink)