import React, { useMemo, useCallback } from 'react'

import useRefState from '../hooks/useRefState'

export const ChannelItemInfoContext = React.createContext({})

export const ChannelItemInfoContextProvider = ({ children }) => {

  const [ channelItemInfo, setChannelItemInfo, getChannelItemInfo ] = useRefState({})
  const closeChannelItemInfo = useCallback(
    () => {
      setChannelItemInfo({
        ...getChannelItemInfo(),
        anchorEl: null,
      })
    },
    [ setChannelItemInfo, getChannelItemInfo ],
  )

  const value = useMemo(
    () => ({
      channelItemInfo: {
        ...channelItemInfo,
        onClose: closeChannelItemInfo,
      },
      setChannelItemInfo,
      getChannelItemInfo,
      closeChannelItemInfo,
    }),
    [ channelItemInfo, setChannelItemInfo, getChannelItemInfo, closeChannelItemInfo ]
  )

  return (
    <ChannelItemInfoContext.Provider value={value}>
      {children}
    </ChannelItemInfoContext.Provider>
  )
}