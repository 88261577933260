import { ApolloLink } from '@apollo/client'
import { asyncMap } from "@apollo/client/utilities"

import {
  getLocalStorage,
  loginCallback,
  setLocalStorage,
  removeLocalStorage,
  getUpdatedSinceUserUpdateInMemory,
  setUpdatedSinceUserUpdateInMemory,
  equalQueryResultObjs,
  cloneObj,
} from '../../utils/misc'
import AsyncLock from '../../utils/AsyncLock'
import { db } from '../../utils/database'
import { TAB_ID } from '../../utils/constants'

import userUpdateQuery from '../../graphql/queries/userUpdate'
import userQuery from '../../graphql/queries/user'
import studyBibleItemQuery from '../../graphql/queries/studyBibleItem'
import customStudyBibleItemQuery from '../../graphql/queries/customStudyBibleItem'
import channelItemQuery from '../../graphql/queries/channelItem'
import saveDataToDexie from './saveDataToDexie'

const lock = new AsyncLock()

const dexieUpdateResponseLink = new ApolloLink((operation, forward) => {

  const { variables, query } = operation
  const { 
    operationName,
    isUserUpdateMutation,
    isRepeatedUserUpdateMutation,
    updatedSinceType,
    runThroughQueuedMutations,
    attemptingAt,
    fetchingAt,
    queuedMutationId,
    runThroughServer,
    saveServerResultToDexie,
    alsoSaveToApolloAsSingularQueries,
    client,
    expectedResponse,
    offlineSetupStatus,
    setLastApolloResponse,
    outstandingExpectedResponsesWhenExecuted,
  } = operation.getContext()

  return asyncMap(forward(operation), async response => {

    if(response.errors) {

      if(isUserUpdateMutation && !isRepeatedUserUpdateMutation) {
        // wait 5 seconds, then retry once (or throw error on second failure)

        console.warn(`A server mutation failed (${operationName}). This could be due to a rapid succession of mutations. Thus, waiting five seconds to try one more time.`)

        await new Promise(resolve => setTimeout(resolve, 1000 * 5))

        const newResponse = await client.mutate({
          mutation: query,
          variables,
          context: {
            offlineSetupStatus,
            setLastApolloResponse,
            expectedResponse,
            presetQueuedMutationId: queuedMutationId,
            isRepeatedUserUpdateMutation: true,
          },
        })

        console.log(`After a server mutation fail (${operationName}), it was rerun five seconds later and succeeded.`)

        return newResponse

      } else {
        return response
      }

    }

    const disableLock = await lock.enable()

    const responseData = response.data[operationName]
    const { newUpdatedSince, moreToGet } = responseData || {}

    if(updatedSinceType === `userUpdate` && typeof newUpdatedSince !== 'number') {
      throw new Error(`Unexpected server response. Expected UserUpdate with newUpdatedSince. (${operationName})`,)
    }

    if(updatedSinceType !== `userUpdate` && newUpdatedSince !== undefined) {
      throw new Error(`Unexpected server response. Did not expect UserUpdate with newUpdatedSince. (${operationName})`,)
    }

    // save locally when relevant
    if(saveServerResultToDexie && runThroughServer) {

      if(
        (  // if it was a user query
          operationName === `user`
          && !variables.id
        )
        || (  // it was a mutation that updated the logged-in `user`
          responseData
          && responseData.__typename === `User`
          && responseData.id === getLocalStorage('user.id')
        )
      ) {
        // re-run loginCallback with full user data (which also saves to dexie)
        loginCallback(responseData)
        removeLocalStorage('inLoginProcess')
      } else {
        await saveDataToDexie({
          data: responseData,
        })
      }

    }

    // save certain server-only plural queries to client as individual queries
    if(alsoSaveToApolloAsSingularQueries) {
      try {
        const singularQueryName = operationName === `myBookmarkedChannelItems` ? `channelItem` : operationName.replace(/s$/, ``)
        ;(responseData instanceof Array ? responseData : responseData[operationName]).forEach(row => {
          client.writeQuery({
            query: {
              studyBibleItem: studyBibleItemQuery,
              customStudyBibleItem: customStudyBibleItemQuery,
              channelItem: channelItemQuery,
            }[singularQueryName],
            data: {
              [singularQueryName]: cloneObj(row),
            },
            variables: {
              id: row.id,
            },
          })
        })
      } catch(err) {
        console.error(`Failed attempt to save plural query to apollo as singular queries.`, responseData)
      }
    }

    // clear from queuedMutations
    if(runThroughQueuedMutations && runThroughServer) {
      await db.queuedMutations.delete(queuedMutationId)
    }

    if(operationName === `user` && responseData) {
      const { user: existingUser } = client.readQuery({
        query: userQuery,
        variables,
      }) || {}

      if(existingUser) {
        const clonedResponseData = cloneObj(responseData)
        ;(clonedResponseData.activeSubscriptions || []).forEach(activeSubscription => {
          // not sure why this __typename is not preserved in the existingUser, but it isn't
          delete activeSubscription.__typename
        })
        if(equalQueryResultObjs(clonedResponseData, existingUser)) {
          response.data.user = null
          console.log('Skipped user update due to no change.')
        }
      }
    }

    // userUpdate management
    if(updatedSinceType === `userUpdate` && runThroughServer) {

      if(moreToGet && newUpdatedSince === variables.updatedSince) {
        throw new Error(`There was a critical infinite loop error related to user update.`)
      }

      const currentUpdatedSince = getLocalStorage('updatedSince:userUpdate', 'use-from-memory')
      const currentUpdatedSinceForcedIntVal = parseInt(currentUpdatedSince, 10) || 0

      setUpdatedSinceUserUpdateInMemory(Math.max(currentUpdatedSinceForcedIntVal, newUpdatedSince))

      if(currentUpdatedSince !== 'use-from-memory') {  // offline is on

        setLocalStorage('updatedSince:userUpdate', getUpdatedSinceUserUpdateInMemory())

        // get updated value of offlineSetupStatus
        const { value: offlineSetupStatus='off' } = await db.localInfo.get('offlineSetupStatus') || {}

        if(!moreToGet && offlineSetupStatus === 'on-query-sync') {
          await db.localInfo.put({
            id: 'offlineSetupStatus',
            value: 'on-ready',
          })
        }

        const hasActualUpdatedValues = Object.values(responseData).some(val => val && typeof val === 'object')
        if(hasActualUpdatedValues) {
          await db.localInfo.put({
            id: 'lastOfflineUpdateInfo',
            value: {
              tabId: TAB_ID,
              time: Date.now(),
            },
          })
        }

      }

      if(expectedResponse) {

        // Weed out responseData, removing items which were already accounted for.
        for(let key in responseData) {
          if(![ 'newUpdatedSince', 'moreToGet', '__typename' ].includes(key)) {
            if(equalQueryResultObjs(expectedResponse[operationName][key], responseData[key])) {
              responseData[key] = null
            }
          }
        }

        // For situations where a second set of batched mutations get sent off prior to the first batch returning,
        // try to determine if the response is expected (i.e. there were no updates from other devices or tabs)
        // For keys still with unaccounted for items...
        for(let key in responseData) {
          if(
            ![ 'newUpdatedSince', 'moreToGet', '__typename' ].includes(key)
            && responseData[key] !== null
          ) {

            // First, merge in outstandingExpectedResponsesWhenExecuted to this expectedResponse
            const newPreDelayExpectedResponseForThisKey = (
              cloneObj(expectedResponse[operationName][key])
              || {
                rows: [],
                __typename: responseData[key].__typename,
              }
            )
            if(responseData[key].deletedIds && !newPreDelayExpectedResponseForThisKey.deletedIds) {
              newPreDelayExpectedResponseForThisKey.deletedIds = []
            }

            outstandingExpectedResponsesWhenExecuted.forEach(userUpdate => {
              const { rows=[], deletedIds=[] } = userUpdate[key] || {}
              if(rows.length > 0) {
                newPreDelayExpectedResponseForThisKey.rows = [
                  ...(rows.filter(({ id }) => !newPreDelayExpectedResponseForThisKey.rows.some(row => row.id === id))),
                  ...newPreDelayExpectedResponseForThisKey.rows,
                ]
              }
              if(deletedIds.length > 0) {
                newPreDelayExpectedResponseForThisKey.deletedIds = [
                  ...new Set([
                    ...deletedIds,
                    ...newPreDelayExpectedResponseForThisKey.deletedIds,
                  ])
                ]
              }
            })

            // Then, see if we can weed out responseData now
            if(equalQueryResultObjs(newPreDelayExpectedResponseForThisKey, responseData[key])) {
              responseData[key] = null
            }

          }
        }
        
      }

      if(
        offlineSetupStatus === `on-query-sync`
          ? !moreToGet
          : (
            Object.keys(responseData).some(
              key => ![ 'newUpdatedSince', 'moreToGet', '__typename' ].includes(key)
              && responseData[key] !== null
            )
          )
      ) {

        // if unexpected data is returned (i.e. they are also working on another device or browser), then refetch queries

        if(offlineSetupStatus !== `on-query-sync`) {
          console.warn(
            `Server returned result not contained in expectedResponse, giving need to refetch plural queries. (Operation name: ${operationName})`,
            `\n\nResponse data not accounted for:\n`,
            responseData,
            `\n\nExpected response:\n`,
            (expectedResponse || {})[operationName],
            `\n\nOutstanding expected responses when this mutation was executed:\n`,
            outstandingExpectedResponsesWhenExecuted,
          )  
        }

        const pluralQueries = [
          `formattingKeys`,
          `highlights`,
          `project`,  // plural since it has an array of moduleByProjects
          `projects`,
          `folders`,
          `folderAncestry`,
          `tags`,
          `modules`,
          `moduleSetting`,  // TODO: is this really needed?
          `modulePieces`,
          `moduleDots`,
          `moduleMarkups`,
        ]

        const activeQueryFieldNames = []

        client.refetchQueries({
          include: 'active',
          onQueryUpdated: ({ queryName, queryInfo: { variables } }) => {
            activeQueryFieldNames.push(`${queryName}(${JSON.stringify(variables)})`)
            return pluralQueries.includes(queryName)
          },
        })

        // flush all inactive plural queries from cache
        // NOTE: If this doesn't work sometimes due to divergent order of variable keys, use equalObjs
        const rootQuery = client.cache.extract().ROOT_QUERY
        const evictedFieldNames = []
        for(let fieldName in rootQuery) {
          if(
            pluralQueries.includes(fieldName.split('(')[0])
            && !activeQueryFieldNames.includes(fieldName)
          ) {
            evictedFieldNames.push(fieldName)
            client.cache.evict({ id: 'ROOT_QUERY', fieldName })
          }
        }
        console.log(`Evicted the following inactive plural queries:`, evictedFieldNames)

      }

      if(moreToGet || newUpdatedSince < currentUpdatedSinceForcedIntVal) {
        // need to run userUpdate again; requestAnimationFrame needed for it to run
        requestAnimationFrame(
          () => client.query({
            query: userUpdateQuery,
            fetchPolicy: `network-only`,
            context: {
              offlineSetupStatus,
            },
          })
        )
        // context: neither offlineSetupStatus nor offlineVersions needed for this query
      }

      setLastApolloResponse && setLastApolloResponse(responseData)
    }

    // update updatedSince time for relevant non-userUpdate queries
    if(updatedSinceType && updatedSinceType !== `userUpdate`) {  // `bookIdsWithUpdates` or `accountSettings`
      setLocalStorage(`updatedSince:${updatedSinceType}`, attemptingAt || fetchingAt || 0)
    }

    await disableLock()

    return response
  })

})

export default dexieUpdateResponseLink