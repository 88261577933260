import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import Header from "../../common/Header"
import AppContent from "../../common/AppContent"
import LinkButton from "../../common/LinkButton"

const StyledAppContent = styled(AppContent)`
  padding-bottom: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Message = styled.div`
  font-size: 20px;
  margin-bottom: 30px;
`

const Buttons = styled.div`
`

const StyledLinkButton = styled(LinkButton)`
  margin: 0 5px;
`

const StudyBibleItemPageNotFound = ({
  ...otherProps
}) => {

  return (
    <>

      <Header
        showStudyBibleLogo
        {...otherProps}
      />

      <StyledAppContent>

        <Message>
          {i18n("Study Bible entry not found.")}
        </Message>

        <Buttons>

          <StyledLinkButton
            to="/"
            color="primary"
            variant={"contained"}
            disableElevation
          >
            {i18n("Back to Reading")}
          </StyledLinkButton>

        </Buttons>

      </StyledAppContent>

    </>
  )
}


export default memo(StudyBibleItemPageNotFound)