import IconButton from '@material-ui/core/IconButton'
import NotesIcon from '@material-ui/icons/UnfoldMore'
import { memo, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import PassageRefPopperContent from "./PassageRefPopperContent"

const StyledIconButton = styled(IconButton)`
  padding: 6px;
  margin: -24px 0 -22px 0;
`

const StyledNotesIcon = styled(NotesIcon)`
  height: 13px;
  width: 13px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const ViewChapterIconButton = ({
  bookId,
  startChapter,
  endChapter,
  versionId,
  contextRefs,
  goSetPopperInfo,
  closeAndClearSearch,
  className,
  ...otherProps
}) => {

  const onClick = useCallback(
    event => {
      event.preventDefault()

      const refSets = [[{
        bookId,
        chapter: startChapter,
      }]]

      if(endChapter === startChapter + 1) {  // do not do more than two chapters
        refSets[0].push({
          bookId,
          chapter: endChapter,
        })
      }

      goSetPopperInfo(event, {
        Component: PassageRefPopperContent,
        props: {
          versionId,
          refSets,
          contextRefs,
          isChapterContext: true,
          goSetPopperInfo,
          closeAndClearSearch,
        },
      })
    },
    [ goSetPopperInfo, versionId, bookId, startChapter, endChapter, contextRefs, closeAndClearSearch ],
  )

  return (
    <StyledIconButton
      className={`ViewChapterIconButton ${className || ``}`}
      onClick={onClick}
      {...otherProps}
    >
      <StyledNotesIcon />
    </StyledIconButton>
  )
}

export default memo(ViewChapterIconButton)