const fields = `
  id
  abbr
  name
  type
  wordDividerRegex
  copyright
  versificationModel
  skipsUnlikelyOriginals
  extraVerseMappings
  hebrewOrdering  
  partialScope  
  languageId
  updatedAt
`

export default fields