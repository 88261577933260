import { gql } from '@apollo/client'

import { getUserFields } from '../fragments/user_fields'

export default gql
`
  mutation updateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      ${getUserFields({ isAdmin: true })}
    }
  }
`