// this is a hack derived from here: https://github.com/atlassian/react-beautiful-dnd/issues/2399

import { memo, useState } from 'react'
import { Droppable as DNDDroppable } from "react-beautiful-dnd"
import useEffectAsync from '../../hooks/useEffectAsync';

const Droppable = props => {

  const [ enabled, setEnabled ] = useState(false);
  useEffectAsync(() => requestAnimationFrame(() => setEnabled(true)), [])

  return (
    <DNDDroppable {...props} key={enabled ? `nonfunctional` : `functional`} />
  );
}

export default memo(Droppable)