import { memo, useContext } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { i18n } from 'inline-i18n'
import { NavLink } from 'react-router-dom'

import useGoUpdateProject from "../../../hooks/useGoUpdateProject"
import useModalAnchor from "../../../hooks/useModalAnchor"
import useDuplicateProject from "../../../hooks/useDuplicateProject"
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import MenuDivider from '../../common/MenuDivider'
import FadedLoading from '../../common/FadedLoading'
import DangerMenuItem from '../../common/DangerMenuItem'

const Container = styled.div`
  padding: 0 5px;
`

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.palette.text.primary} !important;
  text-decoration: none;
`

const ProjectsListMenu = ({
  project,
  onDuplicateDone,
  onDelete,
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const [ x, goDeleteProject ] = useGoUpdateProject({ project, onDelete })  // eslint-disable-line no-unused-vars

  const [ goDuplicateProject, duplicating ] = useDuplicateProject({
    project,
    onComplete: async () => {
      await onDuplicateDone()
      closeModal()
    },
    skipNav: true,
  })

  return (
    <Container>

      <IconButton
        onClick={openModal}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={duplicating ? null : closeModal}
      >
        <StyledNavLink exact to={`/project/${project.id}`}>
          <MenuItem>
            {i18n("Open")}
          </MenuItem>
        </StyledNavLink>
        {/* <MenuItem
          onClick={handleClose}
        >
          {i18n("Rename")}
        </MenuItem> */}
        <MenuItem
          onClick={goDuplicateProject}
          disabled={!hasToolsPlan}
        >
          {i18n("Duplicate")}
        </MenuItem>
        {duplicating && <FadedLoading />}
        <MenuDivider />
        <DangerMenuItem
          onClick={goDeleteProject}
        >
          {i18n("Delete")}
        </DangerMenuItem>
      </Menu>

    </Container>
  )
}

export default memo(ProjectsListMenu)