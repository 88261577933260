import React from "react"
import { getLocale } from 'inline-i18n'
import {
  BORDER_COLOR,
  DEFAULT_ACTIVE_COLOR,
} from './utils/constants'

import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import FolderIcon from '@material-ui/icons/Folder'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import AddIcon from '@material-ui/icons/Add'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MenuItem from '@material-ui/core/MenuItem'
import Slider from '@material-ui/core/Slider'

const SettingsEditorContainer = styled.div`
  position: relative;
  background: white;
  border: 1px solid ${BORDER_COLOR};
  border-top: none;
  padding: 20px;
  max-height: 40vh;
  overflow: auto;
`

const ComponentSet = styled.div`
`

const ComponentSetInArray = styled.div`
  position: relative;
  border-bottom: 1px dashed rgba(0,0,0,.2);
  padding: 15px 63px 15px 0;
  :first-child {
    padding-top: 0;
  }
`

const MarginInMiddle = styled.div`
  margin: 20px 0;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
`

const ArrayGroup = styled(MarginInMiddle)`
  padding: 15px;
  background: rgba(0,0,0,.03);
`

const ArrayGroupLabel = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px dashed rgba(0,0,0,.2);
`

const AddIconContainer = styled.div`
  margin-top: 15px;
`

const ArrayItemActionsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  ${({ $simpleArray }) => $simpleArray ? `` : `
    flex-direction: column;
    top: 15px;
    div:first-child > & {
      top: 0;
    }
  `}
`

const SimpleArrayContainer = styled(MarginInMiddle)`
  margin: 15px 0;
  padding-right: 159px;
  position: relative;
`

const TextFieldContainer = styled(MarginInMiddle)`
`

const SwitchContainer = styled(MarginInMiddle)`
  margin: 5px 0;
`

const StyledTextField = styled(TextField)`
  min-width: 170px;
  ${({ fullWidth, select }) => (fullWidth || select) ? `` : `
    width: 400px;
    max-width: 100%;
  `}
`

const StyledSwitch = styled(Switch)`
  ${({ checked }) => !checked ? `` : `
    & > span:nth-child(1) {
      color: ${DEFAULT_ACTIVE_COLOR};
    }
    & > span:nth-child(2) {
      background-color: ${DEFAULT_ACTIVE_COLOR};
    }
  `}
`

const SliderContainer = styled.div`
`

const SliderLabel = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 3px;
`

const StyledSlider = styled(Slider)`
  max-width: 300px;
`

const AssetOptions = styled.div`
  margin-left: 8px;
  vertical-align: bottom;
  display: inline-flex;
  flex-direction: row;

  @media (max-width: 700px) {
    margin: 10px 0;
    display: flex;
  }
`

const isArray = a => a instanceof Array
const isSimpleArray = type => (type.length === 1 && typeof type[0] === 'string')

class SettingsEditor extends React.Component {

  componentDidMount() {
    this.indicateSettingsHeight()
  }

  componentDidUpdate(prevProps) {
    const { data, dataStructure } = this.props

    this.indicateSettingsHeight()

    // force the rules to run after load
    if(data && dataStructure && !(prevProps.data && prevProps.dataStructure)) {
      this.adjustToRulesAndUpdate(data)
    }
  }

  indicateSettingsHeight = () => {
    const { open, indicateSettingsHeight } = this.props

    if(!open || !this.ref) return

    const settingsHeight = this.ref.getBoundingClientRect().height
    indicateSettingsHeight(settingsHeight)
  }

  getDataInfoAtSegment = ({ target, currentTarget }) => {
    const { data, onChange } = this.props
    const dataNameStack = (
      target.id
      || currentTarget.getAttribute('data-selectid')
      || currentTarget.getAttribute('data-itemid')
    ).split('.').slice(1)

    const newData = JSON.parse(JSON.stringify(data))
    let dataSegment = newData

    while(dataNameStack.length > 1) {
      let structureSegment = dataNameStack.shift()
      let defaultValue = []

      if(/^[0-9]+$/.test(structureSegment)) {
        structureSegment = parseInt(structureSegment, 10)
        defaultValue = {}
      }

      dataSegment = dataSegment[structureSegment] = (dataSegment[structureSegment] || defaultValue)
    }

    return {
      newData,
      dataSegment, 
      dataSegmentKey: dataNameStack[0],
    }
  }

  onChange = event => {
    const { target } = event
    const { dataSegment, dataSegmentKey, newData } = this.getDataInfoAtSegment(event)

    dataSegment[dataSegmentKey] =
      target.type === 'checkbox'
        ? target.checked
        : target.value

    this.adjustToRulesAndUpdate(newData)
  }

  onChangeSlider = ({ id, value }) => {
    const { dataSegment, dataSegmentKey, newData } = this.getDataInfoAtSegment({ target: { id } })

    dataSegment[dataSegmentKey] = value

    this.adjustToRulesAndUpdate(newData)
  }

  onAddArrayItem = event => {
    const { currentTarget } = event
    const { dataSegment, dataSegmentKey, newData } = this.getDataInfoAtSegment(event)

    const simpleArray = currentTarget.getAttribute('data-issimple')

    if(!dataSegment[dataSegmentKey]) {
      dataSegment[dataSegmentKey] = [simpleArray ? "" : {}]
    }
    dataSegment[dataSegmentKey].push(simpleArray ? "" : {})

    this.adjustToRulesAndUpdate(newData)
  }

  onDeleteArrayItem = event => {
    const { dataSegment, dataSegmentKey, newData } = this.getDataInfoAtSegment(event)

    dataSegment.splice(parseInt(dataSegmentKey, 10), 1)

    this.adjustToRulesAndUpdate(newData)
  }

  onArrayItemMove = ({ event, positionChange }) => {
    const { dataSegment, dataSegmentKey, newData } = this.getDataInfoAtSegment(event)
    const idx = parseInt(dataSegmentKey, 10)

    const itemToMove = dataSegment.splice(idx, 1)[0]
    dataSegment.splice(idx + positionChange, 0, itemToMove)

    this.adjustToRulesAndUpdate(newData)
  }

  onArrayItemUp = event => this.onArrayItemMove({ event, positionChange: -1 })
  onArrayItemDown = event => this.onArrayItemMove({ event, positionChange: 1 })

  adjustToRulesAndUpdate = newData => {
    const { dataStructure, onChange } = this.props

    const adjustToRules = ({ dataStructureArray, dataObj }) => {

      dataStructureArray.forEach(({ name, type, bindNumber }) => {

        if(isArray(type) && bindNumber) {
          const dataItem = dataObj[name] = dataObj[name] || []
          const forcedArrayLength = bindNumber({ componentData: newData, dataItem })
          const simpleArray = isSimpleArray(type)
  
          dataItem.splice(
            Math.min(forcedArrayLength, dataItem.length),
            dataItem.length,
            ...Array(Math.max(forcedArrayLength - dataItem.length, 0)).fill(simpleArray ? '' : {})
          )
  
          if(!simpleArray) {
            dataItem.forEach(newDataObj => {
              adjustToRules({
                dataStructureArray: type,
                dataObj: newDataObj,
              })
            })
          }
        }

      })

    }

    adjustToRules({
      dataStructureArray: dataStructure,
      dataObj: newData,
    })

    onChange({ newData })
  }

  openAssetListDialog = ({ currentTarget }) => {
    const { openAssetListDialog } = this.props
    const id = currentTarget.getAttribute('data-selectid')

    if(!openAssetListDialog) return

    openAssetListDialog({
      setUrl: value => {
        this.onChange({
          target: {
            id,
            value,
          },
        })
      }
    })
  }

  openUploadAssetDialog = ({ currentTarget }) => {
    const { openUploadAssetDialog } = this.props
    const id = currentTarget.getAttribute('data-selectid')

    if(!openUploadAssetDialog) return

    openUploadAssetDialog({
      setUrl: value => {
        this.onChange({
          target: {
            id,
            value,
          },
        })
      }
    })
  }

  onFocus = ({ target }) => target.select()

  setRef = ref => this.ref = ref

  render() {
    const { open, dataStructure, openAssetListDialog, openUploadAssetDialog } = this.props

    if(!open || dataStructure.length === 0) {
      return null
    }

    const locale = getLocale()

    const getSettingsEditorComponentSet = ({ dataStructure, data, dataNameStack=[] }) => (
      <ComponentSet>
        {dataStructure.map(({
          name,
          type,
          autoFocus,
          autoFocusAndSelect,
          lang={},
          placeholder,
          inputVariant,
          inputOptions,
          bindNumber,
          hideWhen,
          hideChildReorderWhen,
          sliderParams,
        }) => {

          const id = ['settingseditor', ...dataNameStack, name].join('.')

          if(hideWhen && hideWhen({ componentData: this.props.data, itemData: data })) {
            return null
          }

          switch(type) {

            case 'string': {
              return (
                <TextFieldContainer key={id}>
                  <StyledTextField
                    id={id}
                    value={data[name] || ""}
                    label={lang[locale] || lang.en}
                    placeholder={placeholder}
                    multiline={inputVariant === 'long'}
                    fullWidth={inputVariant === 'long'}
                    onChange={this.onChange}
                    select={!!inputOptions}
                    autoFocus={!!((autoFocus && !data[name]) || autoFocusAndSelect)}
                    onFocus={autoFocusAndSelect ? this.onFocus : null}
                  >
                    {(inputOptions || []).map(({ value, lang }, idx) => (
                      <MenuItem
                        key={value}
                        data-selectid={id}
                        value={value}
                      >
                        {lang[locale] || lang.en}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                  {inputVariant === 'asset' &&
                    <AssetOptions>
                      {!!openAssetListDialog &&
                        <IconButton
                          data-selectid={id}
                          className="openAssetListDialog"
                          onClick={this.openAssetListDialog}
                        >
                          <FolderIcon />
                        </IconButton>
                      }
                      {!!openUploadAssetDialog &&
                        <IconButton
                          data-selectid={id}
                          className="openUploadAssetDialog"
                          onClick={this.openUploadAssetDialog}
                        >
                          <CloudUploadIcon />
                        </IconButton>
                      }
                    </AssetOptions>
                  }
                </TextFieldContainer>
              )
            }

            case 'boolean': {
              return (
                <SwitchContainer key={id}>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        id={id}
                        checked={!!data[name]}
                        onChange={this.onChange}
                      />
                    }
                    label={lang[locale] || lang.en}
                  />
                </SwitchContainer>
              )
            }

            case 'slider': {
              return (
                <SliderContainer key={id}>
                  <SliderLabel>
                    {lang[locale] || lang.en}
                  </SliderLabel>
                  <StyledSlider
                    data-id={id}
                    label={lang[locale] || lang.en}
                    defaultValue={data[name] || 1}
                    onChangeCommitted={(event, value) => this.onChangeSlider({ id, value })}
                    {...sliderParams}
                  />
                </SliderContainer>
              )
            }

            default: {  // should be an array

              if(isArray(type)) {

                const simpleArray = isSimpleArray(type)
                const arrayLabel = lang[locale] || lang.en
                const dataArray = data[name] || [simpleArray ? "" : {}]

                const getActionIcons = ({ idx }) => (
                  <ArrayItemActionsContainer
                    $simpleArray={simpleArray}
                  >
                    {!bindNumber &&
                      <IconButton
                        data-itemid={`${id}.${idx}`}
                        onClick={this.onDeleteArrayItem}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                    {!(hideChildReorderWhen && hideChildReorderWhen({ componentData: this.props.data, childData: dataArray[idx] })) &&
                      <React.Fragment>
                        <IconButton
                          data-itemid={`${id}.${idx}`}
                          onClick={this.onArrayItemUp}
                          disabled={idx === 0}
                        >
                          <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton
                          data-itemid={`${id}.${idx}`}
                          onClick={this.onArrayItemDown}
                          disabled={idx === dataArray.length - 1}
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </React.Fragment>
                    }
                  </ArrayItemActionsContainer>
                )

                return (
                  <ArrayGroup key={id}>
                    {!!arrayLabel &&
                      <ArrayGroupLabel>
                        {arrayLabel}
                      </ArrayGroupLabel>
                    }
                    {simpleArray
                      ? (
                        dataArray.map((x, idx) => (
                          <SimpleArrayContainer key={idx}>
                            {getSettingsEditorComponentSet({
                              dataStructure: [{
                                name: idx,
                                type: type[0],
                                placeholder,
                                inputVariant,
                                inputOptions,
                              }],
                              data: dataArray,
                              dataNameStack: [ ...dataNameStack, name ],
                            })}
                            {getActionIcons({ idx })}
                          </SimpleArrayContainer>
                        ))
                      )
                      : (
                        dataArray.map((item, idx) => (
                          <ComponentSetInArray key={idx}>
                            {getSettingsEditorComponentSet({
                              dataStructure: type,
                              data: item,
                              dataNameStack: [ ...dataNameStack, name, idx ],
                            })}
                            {getActionIcons({ idx })}
                          </ComponentSetInArray>
                        ))
                      )
                    }
                    {!bindNumber &&
                      <AddIconContainer>
                        <IconButton
                          data-itemid={id}
                          data-issimple={simpleArray ? `1` : ``}
                          onClick={this.onAddArrayItem}
                        >
                          <AddIcon />
                        </IconButton>
                      </AddIconContainer>
                    }
                  </ArrayGroup>
                )
              }
            }

          }
        })}
      </ComponentSet>
    )

    return (
      <SettingsEditorContainer
        ref={this.setRef}
      >
        {getSettingsEditorComponentSet(this.props)}
      </SettingsEditorContainer>
    )
  }

}

export default SettingsEditor


// Basic types: string, boolean. Possible future additions: integer, number, date, time, datetime.
// 'type' can be set to an array with a single item holding the value of either 'string' or another array.
// An array with an single array item indicates a grouping which may be repeated 1+ times.
// 'string' type has optional inputVariant of 'long'
// Including an 'inputOptions' key turns the input into a select
// Use an array to allow for unlimited number of entries for a particular data key.

// Example:

// dataStructure: [
//   {
//     name: 'data1',
//     type: 'string',
//     lang: {
//       'en': 'First data item',
//     },
//   },
//   {
//     name: 'data2',
//     type: ['string'],
//     lang: {
//       'en': 'Second data item',
//     },
//   },
//   {
//     name: 'data3',
//     type: [
//       [
//         {
//           name: 'data3a',
//           type: 'string',
//           inputVariant: 'long',
//           lang: {
//             'en': 'First part of third data item',
//           },
//         },
//         {
//           name: 'data3b',
//           type: 'string',
//           lang: {
//             'en': 'Second part of third data item',
//           },
//           inputOptions: [
//             {
//               value: 'one',
//               lang: {
//                 'en': 'One',
//               },
//             },
//             {
//               value: 'two',
//               lang: {
//                 'en': 'Two',
//               },
//             },
//             {
//               value: 'three',
//               lang: {
//                 'en': 'Three',
//               },
//             },
//           ],
//         },
//       ],
//     ],
//   },
//   {
//     name: 'data4',
//     type: 'boolean',
//     lang: {
//       'en': 'Fourth data item',
//     },
//   },
// },
