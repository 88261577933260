import { gql } from '@apollo/client'
import version_fields from '../fragments/version_fields'

const version = gql`
  query version($id: ID!) {
    version(id: $id) {
      ${version_fields}
    }
  }
`

export default version