import { gql } from '@apollo/client'
import modulePiece_fields from '../fragments/modulePiece_fields'

const modulePieces = gql`
  query modulePieces($moduleId: ID!, $position: String, $orderingFrom: BigInt, $orderingTo: BigInt, $offset: Int) {
    modulePieces(moduleId: $moduleId, position: $position, orderingFrom: $orderingFrom, orderingTo: $orderingTo, offset: $offset) {
      ${modulePiece_fields}
    }
  }
`

export default modulePieces