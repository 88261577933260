import { useCallback } from 'react'
import useInstanceValue from './useInstanceValue'

const useInstanceValuesCallback = callback => {

  const getCallback = useInstanceValue(callback)
  const staticCallback = useCallback((...params) => getCallback()(...params), [ getCallback ])

  return staticCallback
}

export default useInstanceValuesCallback