import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateModuleSubtables from './useGoUpdateModuleSubtables'
import { cloneObj } from '../utils/misc'

import moduleMarkupQuery from '../graphql/queries/moduleMarkup'
import moduleMarkupsQuery from '../graphql/queries/moduleMarkups'
import updateModuleMarkupsMutation from '../graphql/mutations/updateModuleMarkups'
import deleteModuleMarkupsMutation from '../graphql/mutations/deleteModuleMarkups'

export const getDefaultModuleMarkup = () => ({
  __typename: `ModuleMarkup`,
  container: `none`,
  positionInfo: null,
  type: 'BASE-HIGHLIGHT',
  color: 'YELLOW',
})

const useGoUpdateModuleMarkups = ({
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  const client = useApolloClient()

  const [ updateModuleMarkups, updateModuleMarkupsResult ] = useMutation(updateModuleMarkupsMutation)
  const [ deleteModuleMarkups, deleteModuleMarkupsResult ] = useMutation(deleteModuleMarkupsMutation)

  const onUpdate = useCallback(
    ({ oldData, newData, isNew }) => {

      newData.forEach((updatedModuleMarkup, idx) => {

        const oldModuleMarkup = (oldData || [])[idx]

        const updateQuery = ({ container, doRemove }={}) => {

          const queryParams = {
            query: moduleMarkupsQuery,
            variables: {
              moduleId,
              container,
            },
          }
  
          const { moduleMarkups } = client.readQuery(queryParams) || {}
  
          if(moduleMarkups) {
            if(!doRemove && !moduleMarkups.some(({ id }) => id === updatedModuleMarkup.id)) {
              client.writeQuery({
                ...queryParams,
                data: {
                  moduleMarkups: [ ...cloneObj(moduleMarkups), updatedModuleMarkup ].sort((a,b) => a.id < b.id ? -1 : 1),
                },
              })
            } else if(doRemove && moduleMarkups.some(({ id }) => id === updatedModuleMarkup.id)) {
              client.writeQuery({
                ...queryParams,
                data: {
                  moduleMarkups: cloneObj(moduleMarkups).filter(({ id }) => id === oldModuleMarkup.id),
                },
              })
            }
          }
  
        }
  
        if(!isNew) {
          if(oldModuleMarkup.container !== updatedModuleMarkup.container) {
            // remove it from the old container
            updateQuery({
              container: oldModuleMarkup.container,
              doRemove: true,
            })
          }
        }
  
        // add it to the new container
        updateQuery({ container: updatedModuleMarkup.container })

      })

    },
    [ moduleId, client ],
  )

  const updateAndDeleteFuncs = useGoUpdateModuleSubtables({
    updateFunc: updateModuleMarkups,
    updateResult: updateModuleMarkupsResult,
    deleteFunc: deleteModuleMarkups,
    deleteResult: deleteModuleMarkupsResult,
    singleItemByIdQuery: moduleMarkupQuery,
    pluralQuery: moduleMarkupsQuery,
    onUpdate,
    // onDelete,
    moduleId,
    projectId,
    undoRedoStack,
    getDefault: getDefaultModuleMarkup,
  })

  return updateAndDeleteFuncs
}

export default useGoUpdateModuleMarkups