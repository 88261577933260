import { gql } from '@apollo/client'

import customStudyBibleItem_fields from '../fragments/customStudyBibleItem_fields'

export default gql
`
  mutation addReactionToCustomStudyBibleItem($customStudyBibleItemId: ID!, $input: ReactionInput!) {
    addReactionToCustomStudyBibleItem(customStudyBibleItemId: $customStudyBibleItemId, input: $input) {
      ${customStudyBibleItem_fields}
    }
  }
`