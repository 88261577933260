import { memo, useContext } from 'react'
import styled from 'styled-components'

import { LoggedInUserContext } from '../../../context/LoggedInUser'

import { IS_EMBED } from '../../../utils/constants'
import LegacyDiscourseOptionsColumn from './LegacyDiscourseOptionsColumn'
import LegacyDiscourseOptionsNewColumn from './LegacyDiscourseOptionsNewColumn'

const Container = styled.div`
  padding: 15px;
  white-space: nowrap;
  max-width: 500px;
  overflow-x: auto;
`

const LegacyDiscourseOptionsColumns = ({
  modulePassages,
  visibilityById,
  ...otherProps
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  const firstModulePassage = modulePassages[0]
  const hide = (
    modulePassages.length >= 5  // Switch to 10 with non-legacy
    || parseInt(firstModulePassage.fromLoc, 10) > 67000000
    || (!hasToolsPlan && !IS_EMBED)
  )

  return (
    <Container>

      {modulePassages.map(modulePassage => (
        <LegacyDiscourseOptionsColumn
          key={modulePassage.id}
          modulePassages={modulePassages}
          modulePassage={modulePassage}
          visibilityById={visibilityById}
          {...otherProps}
        />
      ))}

      {!hide &&
        <LegacyDiscourseOptionsNewColumn
          modulePassages={modulePassages}
          {...otherProps}
        />
      }

    </Container>
  )
}

export default memo(LegacyDiscourseOptionsColumns)