import { memo, useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useLocation } from "react-router-dom"

import useDataQuery from '../../../hooks/useDataQuery'
import useSharedShortcuts from '../../../hooks/useSharedShortcuts'
// import { LoggedInUserContext } from '../../../context/LoggedInUser'
import moduleComponentsByType from '../../modules/shared/moduleComponentsByType'

import SharedHeader from "./SharedHeader"
import SharedNotFound from "./SharedNotFound"
import AppContent from "../../common/AppContent"
import Loading from "../../common/Loading"

import sharedModuleQuery from '../../../graphql/queries/sharedModule'

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  height: calc(${({ $height }) => `${$height - 150}px`} + 100vh);
`

const Shared = ({
  goPrintOrDownload,
  ...otherProps
}) => {

  const location = useLocation()
  const id = location.pathname.split('/')[2]

  // const user = useContext(LoggedInUserContext)
  // const { hasToolsPlan } = user || {}

  const { sharedModule={}, loading: loadingSharedModule } = useDataQuery({
    sharedModuleQuery,
    fetchPolicy: `network-only`,
    refetchOnFocus: true,
    variables: {
      id,
    },
  })

  const Module = useMemo(() => moduleComponentsByType[(sharedModule || {}).type], [ sharedModule ])

  const [ height, setHeight ] = useState(0)
  const [ printDialogInfo, setPrintDialogInfo ] = useState()
  const clearSharedPrintDialogInfo = useCallback(() => setPrintDialogInfo(), [ setPrintDialogInfo ])

  const goPrintOrDownloadWithBaseParams = useCallback(
    params => {
      if([ `DISCOURSE`, `PHRASING`, `DIAGRAMMING` ].includes(sharedModule.type)) {
        setPrintDialogInfo(params)
      } else {
        goPrintOrDownload({
          module: sharedModule,
          ...params,
        })
      }
    },
    [ goPrintOrDownload, sharedModule ],
  )

  useSharedShortcuts({
    goPrintOrDownload: goPrintOrDownloadWithBaseParams,
  })

  if(!sharedModule) {
    return (
      <SharedNotFound
        id={id}
        {...otherProps}
      />
    )
  }

  return (
    <>

      <SharedHeader
        module={sharedModule}
        goPrintOrDownload={goPrintOrDownloadWithBaseParams}
        loading={loadingSharedModule}
        {...otherProps}
      />

      <AppContent key={id}>

        {loadingSharedModule && <Loading />}

        {!loadingSharedModule &&
          <ScrollContainer>
            <Container $height={height}>
              <Module
                key={sharedModule.id}
                module={sharedModule}
                noneditableViewingMode="shared-page"
                sharedPrintDialogInfo={printDialogInfo}
                clearSharedPrintDialogInfo={clearSharedPrintDialogInfo}
                goPrintOrDownload={goPrintOrDownloadWithBaseParams}
                setHeight={setHeight}
              />
            </Container>
          </ScrollContainer>
        }

      </AppContent>

    </>
  )
}


export default memo(Shared)