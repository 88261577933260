import { useCallback, useState, useMemo } from "react"
import { i18n } from "inline-i18n"
import { useHistory } from "react-router-dom"

import useGoUpdateProject from "./useGoUpdateProject"
import useDuplicateModule from "./useDuplicateModule"
import useEffectAsync from "./useEffectAsync"
import useDataQuery from "./useDataQuery"
import { cloneObj } from '../utils/misc'

import projectQuery from "../graphql/queries/project"

const useDuplicateProject = ({
  project,
  onComplete,
  skipNav,
}) => {

  const [ newProjectId, setNewProjectId ] = useState()
  const [ moduleByProjectsIdx, setModuleByProjectsIdx ] = useState(0)
  const history = useHistory()

  // This is needed to mark this query as active, lest it be evicted in the midst of the process
  useDataQuery({
    projectQuery,
    variables: {
      id: newProjectId,
    },
    skip: !newProjectId,
  })

  const [ goCreateProject ] = useGoUpdateProject()

  const duplicateModuleParams = useMemo(
    () => {
      const oldModuleByProject = (newProjectId && (project.moduleByProjects || [])[moduleByProjectsIdx]) || { id: `` }

      return {
        module: oldModuleByProject.module || {},
        moduleByProject: {
          ...oldModuleByProject,
          id: oldModuleByProject.id.replace(/:.*$/, `:${newProjectId}`),
        },
        newOrdering: oldModuleByProject.ordering,
      }
    },
    [ project, moduleByProjectsIdx, newProjectId ],
  )

  const goDuplicateModule = useDuplicateModule(duplicateModuleParams)

  const duplicating = !!newProjectId

  useEffectAsync(
    async () => {
      if(!duplicating) {
        // nothing to do
      } else if(duplicateModuleParams.moduleByProject.id) {
        // go duplicate this module
        await goDuplicateModule()
        setModuleByProjectsIdx(moduleByProjectsIdx + 1)
      } else {
        // done duplicating
        onComplete && await onComplete()
        setNewProjectId()
        setModuleByProjectsIdx(0)
        if(!skipNav) {
          history.push(`/project/${newProjectId}`)
        }
      }
    },
    [ duplicating, moduleByProjectsIdx ],
  )

  const goDuplicateProject = useCallback(
    async () => {

      const newProjectData = cloneObj(project)
      delete newProjectData.id
      delete newProjectData.createdAt
      delete newProjectData.savedAt
      delete newProjectData.modifiedAt
      delete newProjectData.openedOrModifiedAt
      delete newProjectData.deletedAt
      delete newProjectData.userId
      delete newProjectData.__typename
      delete newProjectData.moduleByProjects
      newProjectData.name = `${newProjectData.name || i18n("Untitled")}${i18n(" (copy)")}`
      newProjectData.starred = false
      newProjectData.studyModule1Id = null  // TODO
      newProjectData.studyModule2Id = null  // TODO
      newProjectData.notesModuleId = null  // TODO
      delete newProjectData.folderAncestry  // TODO
      
      const newProject = goCreateProject(newProjectData, { skipNav: true })
      setNewProjectId(newProject.id)

    },
    [ project, goCreateProject ],
  )

  return [
    goDuplicateProject,
    duplicating,
  ]
}

export default useDuplicateProject