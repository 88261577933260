import { useCallback } from 'react'
// import { i18n } from 'inline-i18n'

import useInstanceValue from './useInstanceValue'

export const useUpdatePassageBookmark = ({
  getPassageBookmarks,
  setPassageBookmarks,
  unhiddenSelectedVersionInfos,
  defaultVersionId,
}) => {

  const updatePassageBookmark = useCallback(
    ({ columnIdx=0, ...updates }) => {
      const newPassageBookmarks = [ ...getPassageBookmarks() ]
      const oldPassageBookmark = newPassageBookmarks[columnIdx]

      newPassageBookmarks[columnIdx] = {
        ...oldPassageBookmark,
        ...updates,
      }

      const { versionId=defaultVersionId, parallelVersionId } = newPassageBookmarks[columnIdx]
      if(versionId === parallelVersionId && unhiddenSelectedVersionInfos.length > 1) {
        if(versionId !== (oldPassageBookmark.versionId || defaultVersionId)) {
          newPassageBookmarks[columnIdx].parallelVersionId = oldPassageBookmark.versionId || defaultVersionId
        } else if(!oldPassageBookmark.parallelVersionId) {
          newPassageBookmarks[columnIdx].versionId = unhiddenSelectedVersionInfos.find(({ id }) => id !== parallelVersionId).id
        } else {
          newPassageBookmarks[columnIdx].versionId = oldPassageBookmark.parallelVersionId
        }
      }

      setPassageBookmarks(newPassageBookmarks)

      return {
        oldPassageBookmark,
      }
    },
    [ getPassageBookmarks, setPassageBookmarks, unhiddenSelectedVersionInfos, defaultVersionId ],
  )

  const getUpdatePassageBookmark = useInstanceValue(updatePassageBookmark)

  return {
    updatePassageBookmark,
    getUpdatePassageBookmark,
  }
}

export default useUpdatePassageBookmark