import { selectTextOfEl } from "../../../utils/misc"

const keyDownA = async params => {

  const {
    event,
    modifierKey,
    columnIdx
  } = params

  if(!modifierKey) return

  event.preventDefault()

  const passageColumnEl = document.body.querySelector(`[data-passage-column-idx="${columnIdx}"]`)

  if(passageColumnEl) {
    selectTextOfEl(passageColumnEl)
  }

}

export default keyDownA