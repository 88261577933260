import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { getPiecesFromUSFM, getTextLanguageId, isRTLText } from '@bibletags/bibletags-ui-helper'
import { useMeasure } from 'react-use'
import { getCorrespondingRefs, getRefFromLoc } from '@bibletags/bibletags-versification'

import useBibleSearchResults from '../../hooks/useBibleSearchResults'
import useVersionInfos from '../../hooks/useVersionInfos'
import useEffectAsync from '../../hooks/useEffectAsync'
import { getOrigVersionInfo } from '../../utils/misc'

import PassageRef from '../common/PassageRef'
import TextContent from '../common/TextContent'

const Container = styled.div`
  padding: 0 15px 10px;
`

const PassageRefContainer = styled.div`
  display: flex;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.grey[500]};
  padding-bottom: 3px;
`

const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  position: relative;
  top: 10px;
  margin-left: 10px;
`

const TextContainer = styled.div`
  direction: ${({ $isRTL }) => $isRTL ? "rtl" : "ltr"};
  padding: 3px 0;
  font-size: ${({ $fontSize }) => $fontSize}px;
`

const RowPlaceholder = styled.div`
  height: ${({ $height }) => $height}px;
`

const BibleSearchResultsOriginalRow = ({
  searchText,
  index,
  bookId,
  placeholderHeight,
  setPlaceholderHeight,
  goSetPopperInfo,
  closeAndClearSearch,
}) => {

  const { bibleSearchResult } = useBibleSearchResults({ searchText, index })
  const { originalLoc, versionResults=[] } = bibleSearchResult || {}

  const { getVersionByIdSync } = useVersionInfos({ versionIds: versionResults.map(({ versionId }) => versionId) })

  const [ ref, { width, height } ] = useMeasure()

  useEffectAsync(
    () => {
      if(index === 0 && height) {
        setPlaceholderHeight(height)
      }
    },
    [ setPlaceholderHeight, height, index ],
  )

  const origWordIdOfHits = useMemo(
    () => {
      if(!versionResults[0]) return []

      const { usfm } = versionResults[0]

      const pieces = getPiecesFromUSFM({
        usfm,
        inlineMarkersOnly: true,
        searchText,
      })

      return pieces.map(word => word.isHit ? word[`x-id`] : null).filter(Boolean)
    },
    [ versionResults, searchText ],
  )

  if(!bibleSearchResult) {
    return (
      <RowPlaceholder $height={placeholderHeight} />
    )
  }

  return (
    <Container ref={ref}>

      <PassageRefContainer>
        <PassageRef
          fromLoc={originalLoc}
          versionId={getOrigVersionInfo()}
        />
        <Line />
      </PassageRefContainer>

      {versionResults.map(({ usfm, versionId, tagSets }, idx) => {

        const version = getVersionByIdSync(versionId) || {}
        const { wordDividerRegex, languageId: preadjustedLanguageId } = version
        const languageId = getTextLanguageId({ languageId: preadjustedLanguageId, bookId })

        const startRef = getCorrespondingRefs({
          baseVersion: {
            ref: getRefFromLoc(originalLoc),
            info: getOrigVersionInfo(),
          },
          lookupVersionInfo: version,
        })[0]

        const wordNumberInVerseOfHitsByLoc = {}
        if(tagSets) {
          tagSets.forEach(({ id, tags }) => {
            const [ loc ] = id.split('-')
            wordNumberInVerseOfHitsByLoc[loc] = (
              tags
                .map(tag => (
                  tag.o.map(wordIdAndPartNumber => wordIdAndPartNumber.split('|')[0]).some(wordId => origWordIdOfHits.includes(wordId))
                    ? tag.t
                    : []
                ))
                .flat()
            )
          })
        }

        const fontSize = [ `heb`, `grc` ].includes(languageId) ? 18 : 16

        return (
          <TextContainer
            key={versionId}
            $isRTL={isRTLText({ languageId, bookId })}
            $fontSize={fontSize}
            className="options-popper-no-pointer-events"
            data-versionid={version.id}
          >
            <TextContent
              pieces={
                getPiecesFromUSFM({
                  usfm,
                  inlineMarkersOnly: true,
                  wordDividerRegex,
                  searchText: idx === 0 ? searchText : null,
                  wordNumberInVerseOfHitsByLoc,
                  startRef,
                  splitIntoWords: true,
                })
              }
              versionId={versionId}
              bookId={bookId}
              startChapter={startRef.chapter}
              width={width}
              goSetPopperInfo={goSetPopperInfo}
              showExpand
              closeAndClearSearch={closeAndClearSearch}
            />
          </TextContainer>
        )
      })}

    </Container>
  )
}

export default memo(BibleSearchResultsOriginalRow)