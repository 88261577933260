import { memo, useState, useCallback } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { HexColorPicker } from "react-colorful"

import ImageUrlTextField from '../../common/ImageUrlTextField'
import CSSBImagePreview from './CSSBImagePreview'

const StyledImageUrlTextField = styled(ImageUrlTextField)`
  margin: 12px 0;

  .MuiInputAdornment-root {
    margin-right: 0;
  }

  @media (max-width: 450px) {
    .MuiInputAdornment-root {
      overflow: hidden;
      height: 1.2em;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
  }
`

const UploadButtonContainer = styled.div`
  margin-top: 10px;
  position: relative;
  align-self: flex-start;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  gap: 10px;
`

const BaseImageUrlTextField = styled(StyledImageUrlTextField)`
  padding: 5px 10px;
  font-size: 12px;
`

const AppIconHeading = styled.div`
  font-weight: 500;
  margin: 10px 0 0;
`

const BGColorHeading = styled.div`
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0 10px;
`

const AppIconInstructionOl = styled.ol`
  font-size: 14px;
  padding-inline-start: 25px;
  margin: 5px 0 8px;
`

const AppIconInstructionLi = styled.li`
  margin: 5px 0;
`

const AppIconInstructionB = styled.span`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-weight: bold;
`

const IconExamples = styled.div`
  margin: 5px 0;
  display: flex;
  gap: 8px;
`

const IconExampleImage = styled.img`
  width: 48px;
  height: 48px;
`

const AppIconExamples = styled.div`
  font-size: 14px;
  font-weight: 300;
`

const AppIconCreator = ({
  shortName,
  imageEditInfo,
  setImageEditInfo,
}) => {

  const [ baseImage, setBaseImage ] = useState(``)

  const onBaseImageChange = useCallback(({ target }) => setBaseImage(target.value.trim()), [])
  const setBGColor = useCallback(bgColor => setImageEditInfo({ ...imageEditInfo, bgColor }), [ imageEditInfo, setImageEditInfo ])

  return (
    <>

      <AppIconHeading>
        {i18n("App Icon")}
      </AppIconHeading>

      <AppIconInstructionOl>
        <AppIconInstructionLi>
          <AppIconInstructionB>
            {i18n("Simpler is better.")}
          </AppIconInstructionB>
          {` `}
          {i18n("Just a solid color that matches your church logo is often best.")}
        </AppIconInstructionLi>
        <AppIconInstructionLi>
          <AppIconInstructionB>
            {i18n("Avoid light colors.")}
          </AppIconInstructionB>
          {` `}
          {i18n("If you use an image, that image should NOT contain white or other very light colors as this will obscure the bookmark.")}
        </AppIconInstructionLi>
        <AppIconInstructionLi>
          <AppIconInstructionB>
            {i18n("Do NOT use your logo.")}
          </AppIconInstructionB>
          {` `}
          {i18n("Logos typically make your app icon look too busy.")}
        </AppIconInstructionLi>
      </AppIconInstructionOl>

      <AppIconExamples>
        {i18n("Examples")}
      </AppIconExamples>

      <IconExamples>
        <IconExampleImage src="/icon_example_1.png" className="dark-mode-exempt" />
        <IconExampleImage src="/icon_example_2.png" className="dark-mode-exempt" />
        <IconExampleImage src="/icon_example_3.png" className="dark-mode-exempt" />
      </IconExamples>

      <BGColorHeading>
        {i18n("Background Color")}
      </BGColorHeading>

      <HexColorPicker
        className="dark-mode-exempt"
        color={imageEditInfo.bgColor}
        onChange={setBGColor}
      />

      <UploadButtonContainer>
        <BaseImageUrlTextField
          data-keys={`baseImage`}
          label={i18n("Upload image for background")}
          variant="contained"
          disableElevation
          renderAsSimpleButton
          onChange={onBaseImageChange}
          isImageUrl
          includeUploadButton
          minWidth={512}
          minHeight={512}
        />
        {i18n("(optional)")}
      </UploadButtonContainer>

      <CSSBImagePreview
        bgColor={imageEditInfo.bgColor}
        baseImage={baseImage}
        setBaseImage={setBaseImage}
        shortName={shortName}
        setImageEditInfo={setImageEditInfo}
      />

    </>
  )
}

export default memo(AppIconCreator)