import { useContext } from 'react'
import styled from 'styled-components'
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined'
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import CloudDoneIcon from '@material-ui/icons/CloudDoneOutlined'
import CloudOffIcon from '@material-ui/icons/CloudOffOutlined'
import CloudOffTwoTone from '@material-ui/icons/CloudOffTwoTone'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import { i18n } from 'inline-i18n'
import { useNetworkState } from 'react-use'

import { OfflineSetupStatusContext, SaveStatusContext } from '../context/LocalInfo'

const RedCloudOffTwoTone = styled(CloudOffTwoTone)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const useSyncStatusItems = () => {

  const offlineSetupStatus = useContext(OfflineSetupStatusContext)
  const saveStatus = useContext(SaveStatusContext)
  const { online } = useNetworkState()

  let statusMessage, iconComponent = null

  switch(offlineSetupStatus) {

    case 'off': {
      if(online) {
        if(saveStatus !== `saved`) {
          statusMessage = i18n("Saving in the cloud...")
          iconComponent = <CloudUploadIcon />
        } else {
          statusMessage = i18n("Saved in the cloud")
          iconComponent = <CloudDoneIcon />
        }
      } else {
        if(saveStatus !== `saved`) {
          statusMessage = i18n("Not saved. Waiting for a connection to save in the cloud...")
          iconComponent = <RedCloudOffTwoTone />
        } else {
          statusMessage = i18n("Saved in the cloud")
          iconComponent = <CloudOffIcon />
        }
      }
      break
    }

    case 'on-mutation-sync': {
      if(online) {
        iconComponent = <CloudUploadIcon />
        statusMessage = i18n("Saved on device. Syncing to the cloud...")
      } else {
        if(saveStatus !== `saved`) {
          statusMessage = i18n("Saved on device. Waiting for a connection to sync to the cloud...")
          iconComponent = <CheckIcon />
        } else {
          statusMessage = i18n("Saved on device and in the cloud")
          iconComponent = <CheckIcon />
        }
      }
      break
    }

    case 'on-query-sync': {
      iconComponent = <CloudDownloadIcon />
      statusMessage = i18n("Downloading projects to make available offline...")
      break
    }

    default: {  // 'on-ready'
      if(saveStatus !== `saved`) {
        statusMessage = i18n("Saved on device. Syncing to the cloud...")
        iconComponent = <CloudUploadIcon />
      } else {
        statusMessage = i18n("Saved on device and in the cloud")
        iconComponent = <CloudDoneIcon />
      }
      break
    }

  }

  return {
    statusMessage,
    iconComponent
  }
}

export default useSyncStatusItems
