import { useMemo } from "react"

import { addJourneyColorToEvents, cloneObj, getPrimaryDate, sortEventsByDate } from "../utils/misc"

const useJourneyInfo = ({
  mapLayer,
 }) => {

  const toReturn = useMemo(
    () => {
      const { places=[], journeys=[] } = mapLayer.data || {}
      const placesClone = cloneObj(places)

      const colorByJourneyId = {}
      const eventsByJourneyId = {}
      const journeyDateRangeById = {}

      journeys.forEach(({ id, color }) => {
        colorByJourneyId[id] = color || `#282828`
      })
      addJourneyColorToEvents({ places: placesClone, colorByJourneyId })

      journeys.forEach(({ id }) => {
        eventsByJourneyId[id] = []
        placesClone.forEach(place => {
          place.events.forEach(event => {
            if(event.journeyId === id) {
              eventsByJourneyId[id].push({
                ...event,
                place,
              })
            }
          })
        })
        sortEventsByDate({ events: eventsByJourneyId[id] })

        journeyDateRangeById[id] = `${getPrimaryDate(eventsByJourneyId[id][0]).split(` - `)[0]} - ${getPrimaryDate(eventsByJourneyId[id].at(-1)).split(` - `).at(-1)}`

      })

      return {
        colorByJourneyId,
        eventsByJourneyId,
        journeyDateRangeById,
      }
    },
    [ mapLayer ],
  )

  return toReturn
}

export default useJourneyInfo