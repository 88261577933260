import { memo, useCallback, useState } from 'react'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Divider from '@material-ui/core/Divider'
import { i18n } from 'inline-i18n'

import { preventDefaultEvent, cloneObj } from '../../utils/misc'
import { formattingComponentByType } from '../../utils/formatting'
import useFormattingKeys from '../../hooks/useFormattingKeys'
import useTextSelectionMarkup from '../../hooks/useTextSelectionMarkup'
import useEffectAsync from '../../hooks/useEffectAsync'
import useEqualObjsMemo from '../../hooks/useEqualObjsMemo'
import { getCombinedColors } from '../modules/markup/MarkupKeyPages'
import { getBaseHighlightBGImage } from './BaseHighlight'

import ColorChooser from '../common/ColorChooser'

const defaultFormattingProps = {
  top: 0,
  left: 0,
  width: 16,
  height: 18,
  style: {
    position: `relative`,
    zIndex: 1,
    fontSize: 13,
  },
}

const formattingPropsByComponentType = {
  "NORMAL-UNDERLINE": {
    ...cloneObj(defaultFormattingProps),
    height: 23,
  },
  "ZIG-ZAG-UNDERLINE": {
    ...cloneObj(defaultFormattingProps),
    height: 23,
  },
  "DASH-UNDERLINE": {
    ...cloneObj(defaultFormattingProps),
    height: 22,
  },
  "DOTTED-UNDERLINE": {
    ...cloneObj(defaultFormattingProps),
    height: 22,
  },
  "ARROW-HIGHLIGHT-DOUBLE": {
    ...cloneObj(defaultFormattingProps),
    width: 24,
  },
  "CIRCLE": {
    ...cloneObj(defaultFormattingProps),
    width: 25,
    height: 21,
    left: 2,
    top: 1,
  },
}

;[ ...Object.values(formattingPropsByComponentType), defaultFormattingProps ].forEach(props => {
  props.style.width = props.width
  props.style.height = props.height
})

const defaultBottomNavProps = {
  top: 0,
  left: 0,
  width: 14,
  height: 14.5,
  style: {
    position: `relative`,
    zIndex: 1,
    fontSize: 10,
  },
}

const bottomNavPropsByComponentType = {
  "NORMAL-UNDERLINE": {
    ...cloneObj(defaultBottomNavProps),
    height: 17,
  },
  "ZIG-ZAG-UNDERLINE": {
    ...cloneObj(defaultBottomNavProps),
    height: 16,
  },
  "DASH-UNDERLINE": {
    ...cloneObj(defaultBottomNavProps),
    height: 15,
  },
  "DOTTED-UNDERLINE": {
    ...cloneObj(defaultBottomNavProps),
    height: 15,
  },
  "ARROW-HIGHLIGHT-DOUBLE": {
    ...cloneObj(defaultBottomNavProps),
    width: 18,
  },
  "CIRCLE": {
    ...cloneObj(defaultBottomNavProps),
    width: 18,
    height: 15,
    left: 1.5,
  },
}

;[ ...Object.values(bottomNavPropsByComponentType), defaultBottomNavProps ].forEach(props => {
  props.style.width = props.width
  props.style.height = props.height
})

const FormattingKeyName = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 15px;
  padding: 2px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  max-width: calc(100% - 20px);
  align-self: center;
`

const StyleName = styled(FormattingKeyName)`
  font-weight: 300;
  font-size: 11.5px;
  max-width: ${({ $width }) => $width - 26}px;
  padding: 0 0 2px;
`

const StyleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Styles = styled.div`
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const StyledMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: content-box;
  width: ${({ $width }) => $width}px;
  margin-bottom: 2px;

  .dark-mode-transform-markup-RED {
    filter: brightness(3.5) saturate(4);
  }

  .dark-mode-transform-markup-RED.dark-mode-transform-markup-arrow {
    filter: brightness(.8) saturate(1.5);
  }

  .dark-mode-transform-markup-ORANGE {
    filter: saturate(1.5);
  }

  .dark-mode-transform-markup-ORANGE.dark-mode-transform-markup-highlight {
    background-color: #e8960088;
  }

  .dark-mode-transform-markup-ORANGE.dark-mode-transform-markup-base-highlight {
    background-image: ${getBaseHighlightBGImage(`#e8960088`)};
  }

  .dark-mode-transform-markup-PURPLE:not(.dark-mode-transform-markup-arrow) {
    filter: brightness(3) saturate(.5);
  }

  .dark-mode-transform-markup-PURPLE.dark-mode-transform-markup-arrow {
    filter: brightness(.7) saturate(2);
  }

  .dark-mode-transform-markup-BLUE:not(.dark-mode-transform-markup-arrow) {
    filter: contrast(.1) brightness(1.5) saturate(5);
  }

  .dark-mode-transform-markup-PINK:not(.dark-mode-transform-markup-arrow) {
    filter: brightness(1.4);
  }

  .dark-mode-transform-markup-PINK.dark-mode-transform-markup-arrow {
    filter: brightness(1.1) saturate(1.2);
  }

  .dark-mode-transform-markup-GREY {
    filter: invert(1) brightness(0.8);
  }

  .dark-mode-transform-markup-GREY.dark-mode-transform-markup-circle {
    filter: brightness(.8);
    border-color: rgb(255 255 255/.2);
  }

  .dark-mode-transform-markup-GREY.dark-mode-transform-markup-line {
    filter: invert(1) brightness(0.6);
  }

  .dark-mode-transform-markup-GREY.dark-mode-transform-markup-arrow {
    filter: brightness(.6);
  }
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 10px;
  gap: 8px;
`

const FormattingMenuItemContainer = styled.div`
  position: relative;
`

const FormattingMenuItem = styled(MenuItem)`
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 5px;
  border: 1px solid ${({ $selected }) => $selected ? `white` : `rgb(255 255 255/.1)`};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .2s ease-in-out;
`

const Name = styled.div`
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  text-align: center;
  font-size: 8px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
`

const T = styled.div`
  z-index: 2;
  position: relative;
  color: black;
`

const StyledBottomNavigation = styled(BottomNavigation)`
  margin: 5px 5px 0;
  background-color: transparent;
  flex-wrap: wrap;
  max-width: 250px;
  height: auto;
`

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  min-width: 34px;
  max-width: 34px;
  width: 34px;
  height: 34px;
  padding: 0 !important;
  transition: transform .2s ease-in-out;
  font-size: 14.5px;
  overflow: hidden;

  &:hover {
    transform: translateY(-3px);

    &.Mui-selected {
      transform: none;
      cursor: default;
    }
  }
`

const ComponentContainer = styled.div`
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NavIcon = styled.div`
  position: relative;

  & > div::before {
    background: none;
  }
`

const NoLenses = styled.div`
  padding: 30px;
  color: ${({ theme }) => theme.palette.grey[500]};
  text-align: center;
`

const MaxedOut = styled.div`
  padding: 0 12px 15px;
  color: ${({ theme }) => theme.palette.tertiary.light};
  font-size: 12px;
  text-align: center;
  max-width: 240px;
  line-height: 1.2;
`

let lastPageIdx = 0

const FormattingMenuList = ({
  formattingKeyInfo,
  updateFormattingKeyInfo,
  maxNumItems,
  className,
  ...otherProps
}) => {

  const { formattingKeysById } = useFormattingKeys()
  const formattingKey = formattingKeysById[(formattingKeyInfo || {}).id] || {}
  const { styles=[] } = formattingKey.info || {}

  const { markupByTypeAndColor, toggleMarkup, clearMarkup } = useTextSelectionMarkup({
    formattingKeyInfo,
    ...otherProps,
  })

  const selectionStartAndEnd = useEqualObjsMemo([ otherProps.selectionStart, otherProps.selectionEnd ])

  const [ pageIdx, setPageIdx ] = useState(lastPageIdx)
  lastPageIdx = pageIdx

  const onChange = useCallback(
    ({ keys, newValue }) => {
      const newFormattingKeyInfo = cloneObj(formattingKeyInfo)
      newFormattingKeyInfo.colors.push({
        color: newValue,
        value: ``,
      })
      updateFormattingKeyInfo(newFormattingKeyInfo)
      toggleMarkup({ markupType: keys.markupType, color: newValue })
      // setSelectedColor(newValue)  // this is more confusing than helpful, I think
    },
    // [ formattingKeyInfo, updateFormattingKeyInfo, setSelectedColor, toggleMarkup ],
    [ formattingKeyInfo, updateFormattingKeyInfo, toggleMarkup ],
  )

  const onChangePage = useCallback(
    (event, newValue) => {
      event.preventDefault()
      setPageIdx(newValue)
    },
    [],
  )

  // create pages of sections
  const colors = getCombinedColors({ formattingKey, formattingKeyInfo })
  const colorButtonsPerStyle = Math.min(colors.length + 1, 8)
  const colorButtonsPerRow = [ 1, 2, 3, 5, 6 ].includes(colorButtonsPerStyle) ? 3 : 4
  const rowsPerStyle = Math.ceil(colorButtonsPerStyle / colorButtonsPerRow)
  const width = colorButtonsPerRow * (56 + 10) - 10 + 16
  const totalRows = styles.length * rowsPerStyle
  const pagesOfSections = (
    totalRows > 4
      ? styles.map(style => ([ style ]))
      : [ styles ]
  )

  const effectivePageIdx = pageIdx < pagesOfSections.length ? pageIdx : 0
  const currentPage = pagesOfSections[effectivePageIdx]
  const reachedMax = !!maxNumItems && (otherProps.highlights || otherProps.moduleMarkups).length >= maxNumItems

  useEffectAsync(
    () => {
      // automatically go to the first style page with markup (unless the current page has markup)
      const markupTypes = Object.keys(markupByTypeAndColor).map(key => key.split(` `)[0])
      if(!markupTypes.includes(pagesOfSections[pageIdx][0].markupType)) {
        const idxOfFirstPageWithMarkup = pagesOfSections.findIndex(([ style ]) => markupTypes.includes(style.markupType))
        if(
          idxOfFirstPageWithMarkup !== -1
          && pageIdx !== idxOfFirstPageWithMarkup
        ) {
          setPageIdx(idxOfFirstPageWithMarkup)
        }
      }
    },
    [ selectionStartAndEnd ],
  )

  return (
    <>

      {pagesOfSections.flat().length > 0 &&
        <StyledMenuList
          $width={width}
          className={`${className} FormattingMenuList`}
        >

          {!!formattingKey.name &&
            <FormattingKeyName>
              {formattingKey.name}
            </FormattingKeyName>
          }

          <Page>
            {currentPage.map(style => {
              const Component = formattingComponentByType[style.markupType]

              return (

                <StyleSection key={style.markupType}>

                  {!!style.label &&
                    <StyleName $width={width}>
                      {style.label}
                    </StyleName>
                  }

                  <Styles className="FormattingMenuList-Styles">

                    {colors.map(({ color, value, type }) => (
                      <FormattingMenuItemContainer key={color}>
                        <FormattingMenuItem
                          onMouseDown={preventDefaultEvent}
                          onClick={toggleMarkup}
                          tabIndex={-1}
                          $selected={!!markupByTypeAndColor[`${style.markupType} ${color}`]}
                          data-type={style.markupType}
                          data-color={color}
                          disabled={reachedMax && !markupByTypeAndColor[`${style.markupType} ${color}`]}
                        >
                          <Name>{value || ``}</Name>
                          <T>T</T>
                          <ComponentContainer>
                            <Component
                              color={color}
                              {...(formattingPropsByComponentType[style.markupType] || defaultFormattingProps)}
                            />
                          </ComponentContainer>
                        </FormattingMenuItem>
                      </FormattingMenuItemContainer>
                    ))}

                    {colors.length < 8 &&
                      <ColorChooser
                        skipColors={[ ...colors.map(({ color }) => color), `GREY` ]}
                        onChange={onChange}
                        keys={style}
                        disableAutoFocus
                        forceBelow
                      >
                        <FormattingMenuItemContainer>
                          <FormattingMenuItem
                            onMouseDown={preventDefaultEvent}
                          >
                            <T>T</T>
                            <ComponentContainer>
                              <Component
                                color={`GREY`}
                                {...(formattingPropsByComponentType[style.markupType] || defaultFormattingProps)}
                              />
                            </ComponentContainer>
                          </FormattingMenuItem>
                        </FormattingMenuItemContainer>
                      </ColorChooser>
                    }

                  </Styles>

                </StyleSection>

              )
            })}
          </Page>

          {pagesOfSections.length > 1 &&
            <StyledBottomNavigation
              value={effectivePageIdx}
              onMouseDown={preventDefaultEvent}
              onChange={onChangePage}
            >
              {pagesOfSections.map(([ style ], idx) => {
                const Component = formattingComponentByType[style.markupType]

                return (
                  <StyledBottomNavigationAction
                    key={style.markupType}
                    icon={
                      <NavIcon>
                        <T>T</T>
                        <ComponentContainer>
                          <Component
                            color={idx === effectivePageIdx ? `WHITE` : `GREY`}
                            {...(bottomNavPropsByComponentType[style.markupType] || defaultBottomNavProps)}
                          />
                        </ComponentContainer>
                      </NavIcon>
                    }
                  />
                )
              })}
            </StyledBottomNavigation>
          }

        </StyledMenuList>
      }

      {pagesOfSections.flat().length === 0 &&
        <NoLenses>
          {i18n("You do not have any lenses set up.", "", "markup")}
        </NoLenses>
      }

      {reachedMax &&
        <MaxedOut>
          {i18n("You have reached the maximum number of markups for a single chapter.")}
        </MaxedOut>
      }

      {!!clearMarkup && <Divider />}

      {!!clearMarkup &&
        <MenuList>

          <MenuItem
            onClick={clearMarkup}
            tabIndex={-1}
          >
            {i18n("Clear markup", "", "markup")}
          </MenuItem>

        </MenuList>
      }

    </>
  )
}


export default memo(FormattingMenuList)