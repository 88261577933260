import React from "react"
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from "@material-ui/core/IconButton"
import PauseIcon from "@material-ui/icons/Pause"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"

const Container = styled.div`
  width: 100%;
  min-width: 200px;
  height: 48px;
  box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 5px;
  border-radius: 4px;
  user-select: none;
  -webkit-user-select: none;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid rgba(0,0,0,.1);
  margin: 10px 0;
`

const Controls = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: #55606E;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
`

const CurrentTime = styled.div`
  margin-right: 10px;
`

const TotalTime = styled.div`
  margin-left: 10px;
`

const StyledIconButton = styled(IconButton)`
  width: 38px;
  height: 38px;
  box-sizing: border-box;
`

const Slider = styled.div`
  flex-grow: 1;
  background-color: #D8D8D8;
  position: relative;
  margin: 0 8px;
  height: 1px;
`

const Progress = styled.div`
  background-color: rgb(50, 50, 50);
  border-radius: inherit;
  position: absolute;
  pointer-events: none;
  width: 0;
  height: 100%;
`

const Pin = styled.div`
  border-radius: 8px;
  position: absolute;
  pointer-events: all;
  right: -13px;
  top: -12px;
  padding: 5px;
`

const PinDot = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.32);
  background-color: rgb(50, 50, 50);
`

const StyledCircularProgress = styled(CircularProgress)`
  margin: 0 7px;
`

const getTimeStringFromSeconds = seconds => {
  seconds = parseInt(seconds, 10) || 0
  var min = Math.floor(seconds / 60)
  var sec = Math.floor(seconds % 60)
  return min + ':' + (sec < 10 ? '0' + sec : sec)
}

class AudioPlayer extends React.Component {

  constructor(props) {
    super(props)

    const { src } = props

    this.state = {
      loading: true,
      playing: false,
      currentTime: 0,
      duration: 0,
      previousSrc: src,
    }
  }

  componentDidMount() {
    this.audioRef.addEventListener('timeupdate', this.updateProgress)
    this.audioRef.addEventListener('loadedmetadata', this.loadMetaData)
    this.audioRef.addEventListener('canplay', this.indicatedLoaded)
    this.audioRef.addEventListener('play', this.indicatePlaying)
    this.audioRef.addEventListener('pause', this.indicatePaused)
    this.audioRef.addEventListener('ended', this.onEnded)

    this.audioRef.pause()
    this.audioRef.load()
  }

  componentDidUpdate() {
    const { src } = this.props
    const { previousSrc } = this.state

    if(src !== previousSrc) {
      this.audioRef.load()
      this.setState({
        previousSrc: src,
        loading: true,
      })
    }
  }

  componentWillUnmount() {
    this.audioRef.removeEventListener('timeupdate', this.updateProgress)
    this.audioRef.removeEventListener('loadedmetadata', this.loadMetaData)
    this.audioRef.removeEventListener('canplay', this.indicatedLoaded)
    this.audioRef.removeEventListener('play', this.indicatePlaying)
    this.audioRef.removeEventListener('pause', this.indicatePaused)
    this.audioRef.removeEventListener('ended', this.onEnded)
  }

  startDrag = event => {
    event.preventDefault()
    event.stopPropagation()

    this.seek(event)

    var isMouseEvent = event.type === 'mousedown'
    var moveEvent = isMouseEvent ? 'mousemove' : 'touchmove'
    var endEvent = isMouseEvent ? 'mouseup' : 'touchend'

    const endDrag = event2 => {
      event2.preventDefault()
      event2.stopPropagation()

      window.removeEventListener(moveEvent, this.seek, false)
      window.removeEventListener(endEvent, endDrag, false)
    }

    window.addEventListener(moveEvent, this.seek, false)
    window.addEventListener(endEvent, endDrag, false)
  }

  seek = event => {
    const { duration } = this.state

    if(!duration) return

    if(this.inRange(event)) {

      const clientX = event.clientX || event.touches[0].clientX
      let K = 0
      const offsetX = clientX - this.sliderRef.getBoundingClientRect().x
      const width = this.sliderRef.clientWidth
      K = offsetX / width
    
      this.audioRef.currentTime = duration * K
    }
  }

  inRange = event => {
    const { x: min, width } = this.sliderRef.getBoundingClientRect()
    const max = min + width
    const clientX = event.clientX || event.touches[0].clientX
    return clientX >= min && clientX <= max
  }

  endDrag = () => {

  }

  updateProgress = () => {
    const { currentTime } = this.audioRef
    this.setState({ currentTime })
  }

  loadMetaData = () => {
    const { duration } = this.audioRef
    this.setState({ duration })
  }

  indicatedLoaded = () => {
    this.setState({ loading: false })
  }

  indicatePlaying = () => {
    this.setState({ playing: true })
  }

  indicatePaused = () => {
    this.setState({ playing: false })
  }

  onEnded = () => {
    this.audioRef.currentTime = 0
    this.updateProgress()
  }

  togglePlay = () => {
    const { playing } = this.state

    if(!playing) {
      document.querySelectorAll('.AudioPlayer-audio-tag')
        .forEach(audioTag => audioTag.pause())
      this.audioRef.play()
    } else {
      this.audioRef.pause()
    }
  }

  setSliderRef = ref => this.sliderRef = ref
  setAudioRef = ref => this.audioRef = ref

  render() {
    const { src } = this.props
    const { loading, playing, currentTime, duration } = this.state

    const percentage = currentTime / duration * 100

    return (
      <Container>
        {loading && <StyledCircularProgress size={24} />}
        {!loading && 
          <StyledIconButton
            size="small"
            onClick={this.togglePlay}
          >
            {playing ? <PauseIcon /> : <PlayArrowIcon />}
          </StyledIconButton>
        }
        <Controls
          onMouseDown={this.startDrag}
          onTouchStart={this.startDrag}
        >
          <CurrentTime>{getTimeStringFromSeconds(currentTime)}</CurrentTime>
          <Slider
            ref={this.setSliderRef}
          >
            <Progress
              style={{
                  width: `${percentage}%`
              }}
            >
              <Pin>
                <PinDot />
              </Pin>
            </Progress>
          </Slider>
          <TotalTime>{getTimeStringFromSeconds(duration)}</TotalTime>
        </Controls>
        <audio
          className="AudioPlayer-audio-tag"
          ref={this.setAudioRef}
        >
          <source
            type="audio/mpeg"
            src={src}
          />
        </audio>

      </Container>
    )
  }
}

export default AudioPlayer