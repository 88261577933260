import { memo, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { getBoxToBoxArrow } from 'curved-arrows'
import { getArrow } from 'perfect-arrows'
import useSetTimeout from '../../hooks/useSetTimeout'
import useEffectAsync from '../../hooks/useEffectAsync'
import useRefState from '../../hooks/useRefState'
import useInstanceValue from '../../hooks/useInstanceValue'
import { equalObjs } from '../../utils/misc'

const ContextEl = styled.div`
  display: none;
`

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

const SvgArrow = ({
  ref1,
  ref2,
  type=`right-angles`,  // right-angles or single-curve
  color=`black`,
  arrowHeadSize=7,
  padStart=0,
  padEnd=0,
  minArrowLength,

  // relevant only for right-angles
  controlPointStretch=70,

  // relevant only for single-curve
  bow=0.2,
  stretch=0,
  stretchMin=0,
  stretchMax=500,

  ...otherProps
}) => {

  minArrowLength = minArrowLength != null ? minArrowLength : (type === `right-angles` ? 0 : arrowHeadSize * 2)

  const contextRef = useRef()
  const getRefs = useInstanceValue({ ref1, ref2, contextRef })
  const [ setCheckElsTimeout ] = useSetTimeout()
  const [ el1, setEl1, getEl1 ] = useRefState((ref1 || {}).current)
  const [ el2, setEl2, getEl2 ] = useRefState((ref2 || {}).current)
  const [ contextEl, setContextEl, getContextEl ] = useRefState((ref2 || {}).current)
  const [ arrowPositionDetails, setArrowPositionDetails, getArrowPositionDetails ] = useRefState()
  let arrowD, arrowHeadTransform, arrowLength
  // get the distance between the two points
  if(type === `right-angles`) {
    const [ sx, sy, c1x, c1y, c2x, c2y, ex, ey, ae ] = arrowPositionDetails || []
    arrowD = `M ${sx} ${sy} C ${c1x} ${c1y}, ${c2x} ${c2y}, ${ex} ${ey}`
    arrowHeadTransform = `translate(${ex}, ${ey}) rotate(${ae})`
    arrowLength = Math.sqrt(Math.pow(sx - ex, 2) + Math.pow(sy - ey, 2))
  } else {
    const [ sx, sy, cx, cy, ex, ey, ae ] = arrowPositionDetails || []  // two more params not presently used: as, ec
    const endAngleAsDegrees = ae * (180 / Math.PI)
    arrowD = `M${sx},${sy} Q${cx},${cy} ${ex},${ey}`
    arrowHeadTransform = `translate(${ex},${ey}) rotate(${endAngleAsDegrees})`
    arrowLength = Math.sqrt(Math.pow(sx - ex, 2) + Math.pow(sy - ey, 2))
  }

  useEffect(
    () => {

      if(
        !(el1 instanceof Element)
        || !(el2 instanceof Element)
        || !(contextEl instanceof Element)
      ) {
        setArrowPositionDetails()
        return
      }

      const determineArrowDetails = () => {

        if(
          !document.body.contains(el1)
          || !document.body.contains(el2)
        ) {
          setArrowPositionDetails()
          return
        }

        const rect1 = el1.getBoundingClientRect()
        const rect2 = el2.getBoundingClientRect()

        let positionedParentEl = contextEl
        while(
          positionedParentEl.parentElement
          && window.getComputedStyle(positionedParentEl).getPropertyValue(`position`) === 'static'
        ) {
          positionedParentEl = positionedParentEl.parentElement
        }
        const containerRect = positionedParentEl.getBoundingClientRect()

        rect1.x -= containerRect.x
        rect1.y -= containerRect.y
        rect2.x -= containerRect.x
        rect2.y -= containerRect.y

        if(type === `single-curve`) {
          rect1.x += rect1.width / 2
          rect1.y += rect1.height / 2
          rect2.x += rect2.width / 2
          rect2.y += rect2.height / 2
        }

        const rightAngleOptions = {
          padStart,
          padEnd,
          controlPointStretch,
        }

        const singleCurveOptions = {
          padStart,
          padEnd,
          bow,
          stretch,
          stretchMin,
          stretchMax,
          // flip: false,
        }

        const newArrowPositionDetails = (
          type === `right-angles`
            ? getBoxToBoxArrow(rect1.x, rect1.y, rect1.width, rect1.height, rect2.x, rect2.y, rect2.width, rect2.height, rightAngleOptions)
            : getArrow(rect1.x, rect1.y, rect2.x, rect2.y, singleCurveOptions)
        )

        if(!equalObjs(newArrowPositionDetails, getArrowPositionDetails())) {
          setArrowPositionDetails(newArrowPositionDetails)
        }

      }

      const interval = setInterval(determineArrowDetails, 20)

      return () => {
        clearInterval(interval)
      }

    },
    [ el1, el2, contextEl, padStart, padEnd, controlPointStretch, getArrowPositionDetails, setArrowPositionDetails, bow, stretch, stretchMin, stretchMax, type ],
  )

  useEffectAsync(
    () => {
      const checkEls = () => {
        const { ref1, ref2, contextRef } = getRefs()
        const el1 = (ref1 || {}).current
        if(el1 !== getEl1()) setEl1(el1)
        const el2 = (ref2 || {}).current
        if(el2 !== getEl2()) setEl2(el2)
        const contextEl = contextRef.current
        if(contextEl !== getContextEl()) setContextEl(contextEl)
        setCheckElsTimeout(checkEls, 50)
      }
      checkEls()
    },
    [],
  )

  return (
    <>
      <ContextEl ref={contextRef} />

      {(arrowPositionDetails || [])[0] !== undefined && arrowLength >= minArrowLength &&
        <Svg
          {...otherProps}
          xmlns="http://www.w3.org/2000/svg">
          <path
            d={arrowD}
            stroke={color}
            strokeWidth={arrowHeadSize / 2}
            fill="none"
          />
          <polygon
            points={`0,${-arrowHeadSize} ${arrowHeadSize * 2},0, 0,${arrowHeadSize}`}
            transform={arrowHeadTransform}
            fill={color}
          />
        </Svg>
      }
    </>
  )

}

export default memo(SvgArrow)