import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import useIsLoggedIn from '../../../hooks/useIsLoggedIn'
import useOnInstall from '../../../hooks/useOnInstall'
import { isAndroid, isIOS, shouldRecommendInstall } from '../../../utils/misc'

import SignInButton from '../../common/SignInButton'
import LinkButton from '../../common/LinkButton'
import JoinTheMailingListButton from '../../common/JoinTheMailingListButton'

const APP_ICON_SIZE = 40

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.3;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px 0;
  gap: 7px;
  flex-wrap: wrap;

  & > div {
    margin: 0;
  }
`

const JoinContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  gap: 5px;
`

const MailingListDiscount = styled.div`
  font-weight: 300;
  font-size: 12px;
`

const StyledButton = styled(Button)`
  background: rgb(0 0 0/.7);
  color: white;
  font-size: 18px;
  margin: -2px 0 13px;
  white-space: nowrap;

  &:hover {
    background: black;
  }
`

const InstallContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 30px;
  margin: 5px 0 0;
`

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AndroidPreview = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999;
`

const AndroidPreviewImage = styled.img`
  width: ${APP_ICON_SIZE * 1.1}px;
  height: ${APP_ICON_SIZE * 1.1}px;
  margin: -${APP_ICON_SIZE * .1}px;
`

const IOSPreviewImage = styled.img`
  width: ${APP_ICON_SIZE}px;
  height: ${APP_ICON_SIZE}px;
  vertical-align: middle;
`

const StyledLinkButton = styled(LinkButton)`
  white-space: nowrap;
`

const HomeSubscribeSignIn = ({
  fullScreen,
  toggleSliderSectionOpen,
}) => {

  const isLoggedIn = useIsLoggedIn()
  const onInstall = useOnInstall()

  const showInstall = shouldRecommendInstall && fullScreen

  return (
    <Container>

      <ButtonContainer>

        <StyledLinkButton
          to="/subscribe"
          variant="contained"
          color="secondary"
          disableElevation
          size="small"
        >
          {i18n("Subscribe")}
        </StyledLinkButton>

        {!isLoggedIn &&
          <SignInButton
            size="small"
          />
        }

      </ButtonContainer>

      {fullScreen &&
        <StyledButton
          onClick={toggleSliderSectionOpen}
          variant="contained"
          disableElevation
        >
          {i18n("Read the Study Bible")}
        </StyledButton>
      }

      {showInstall &&
        <InstallContainer>

          {isIOS &&
            <Preview>
              <IOSPreviewImage
                src={`/favicon_192.png`}
                className="dark-mode-exempt"
              />
            </Preview>
          }

          {isAndroid &&
            <Preview>
              <AndroidPreview>
                <AndroidPreviewImage
                  src={`/app_icon_maskable_512.png`}
                  className="dark-mode-exempt"
                />
              </AndroidPreview>
            </Preview>
          }

          <StyledLinkButton
            color="secondary"
            size="small"
            disableElevation
            variant="contained"
            navInSameTab
            href={`/install`}
            onClick={onInstall}
          >
            {i18n("Install the App")}
          </StyledLinkButton>

        </InstallContainer>
      }

      {!isLoggedIn && !showInstall &&
        <JoinContainer>
          <JoinTheMailingListButton />
          <MailingListDiscount>
            {i18n("...and get 50% off your first month")}
          </MailingListDiscount>
        </JoinContainer>
      }

    </Container>
  )
}

export default memo(HomeSubscribeSignIn)