import { useMemo } from 'react'
// import { i18n } from 'inline-i18n'

import { getPrimaryName } from '../utils/misc'
import useMapLayers from './useMapLayers'
import useJourneyInfo from './useJourneyInfo'
import usePersonInfo from './usePersonInfo'

const useMapSearchItems = () => {

  const layer = `BIBLE`
  const stage = `PUBLISHED`
  const { getMapLayerState } = useMapLayers({ layer, stage })
  const [ mapLayer ] = getMapLayerState({ layer, stage })

  const { eventsByJourneyId } = useJourneyInfo({ mapLayer })
  const { eventsByPersonId } = usePersonInfo({ mapLayer })

  const mapItemsForSearch = useMemo(
    () => {

      const { places=[], journeys=[], persons=[] } = mapLayer.data || {}

      const mapItemsForSearch = []

      journeys.forEach(journey => {
        if(eventsByJourneyId[journey.id].length > 0) {
          mapItemsForSearch.push({
            suggestedQuery: getPrimaryName(journey),
            path: `/map?journeyId=${journey.id}`,
          })
        }
      })

      places.forEach(place => {
        mapItemsForSearch.push({
          suggestedQuery: getPrimaryName(place),
          path: `/map?placeId=${place.id}`,
        })
      })

      places.map(({ events }) => events).flat().forEach(event => {
        mapItemsForSearch.push({
          suggestedQuery: getPrimaryName(event),
          path: `/map?eventId=${event.id}`,
        })
      })

      persons.forEach(person => {
        if(eventsByPersonId[person.id].length > 0) {
          mapItemsForSearch.push({
            suggestedQuery: getPrimaryName(person),
            path: `/map?personId=${person.id}`,
          })
        }
      })

      mapItemsForSearch.forEach(suggestion => {
        suggestion.actionType = "map"
        suggestion.action = "open"
      })

      return mapItemsForSearch
    },
    [ mapLayer, eventsByJourneyId, eventsByPersonId ],
  )

  return mapItemsForSearch

}

export default useMapSearchItems