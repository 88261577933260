import { memo, useState, useCallback, useRef } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"
import { usePrevious } from "react-use"

const variants = {
  enter: direction => ({
    x: direction > 0 ? 500 : -500,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => ({
    zIndex: 0,
    x: direction < 0 ? 500 : -500,
    opacity: 0,
  }),
}

const transition = {
  x: {
    type: "spring",
    stiffness: 300,
    damping: 30,
  },
  opacity: {
    duration: 0.2,
  }
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  width: calc(${({ $width=500 }) => `${$width}px`} - 20px);
  max-width: min(500px, calc(100vw - 20px));
  min-height: ${({ $lockedHeight }) => $lockedHeight ? parseInt($lockedHeight, 10) : 0}px;
  transition: min-height .3s ease-in-out;
  direction: ltr;  // we do not want to inherit the rtl of the container if reading Hebrew
  line-height: 1.3;
`

const PageContainer = styled(motion.div)`
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  margin-right: -100%;
  display: flex;
  flex-direction: column;
`

const PassagePopperLazyLoadPageViewer = ({
  pageKey,
  getPageByKey,
  previousPageKey,
  nextPageKey,
  width,
}) => {

  const ref = useRef()
  const [ lockedHeight, setLockedHeight ] = useState()

  const lastPageKey = usePrevious(pageKey)

  const direction = (
    lastPageKey === previousPageKey
      ? 1
      : (
        lastPageKey === nextPageKey
          ? -1
          : 0
      )
  )

  const onAnimationStart = useCallback(() => setLockedHeight(ref.current.getBoundingClientRect().height), [ ref ])
  const onAnimationComplete = useCallback(() => setLockedHeight(), [])

  return (
    <Container $width={width} $lockedHeight={lockedHeight}>
      <AnimatePresence initial={false} custom={direction}>
        <PageContainer
          ref={ref}
          key={pageKey}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={transition}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
          className="OptionsPopper-max-height-child"
        >
          {getPageByKey(pageKey)}
        </PageContainer>
      </AnimatePresence>
    </Container>
  )
}

export default memo(PassagePopperLazyLoadPageViewer)