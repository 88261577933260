const keyDownD = async params => {

  const {
    event,
    modifierKey,
    setPrintDialogInfo,
  } = params

  if(!modifierKey) return

  event.preventDefault()

  setPrintDialogInfo({ action: `download` })
}

export default keyDownD