const fields = `
  id
  content
  positionInfo
  savedAt
  moduleId
  modulePieceId
  color
`

// Not included here, but available:
  // module

export default fields