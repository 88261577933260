import React from 'react'

import useDataQuery from '../hooks/useDataQuery'
import useIsLoggedIn from '../hooks/useIsLoggedIn'

import formattingKeysQuery from '../graphql/queries/formattingKeys'

export const FormattingKeysContext = React.createContext([])

export const FormattingKeysContextProvider = ({
  children,
}) => {

  const isLoggedIn = useIsLoggedIn()

  const { formattingKeys=[] } = useDataQuery({
    formattingKeysQuery,
    skip: !isLoggedIn,
  })

  return (
    <FormattingKeysContext.Provider value={formattingKeys}>
      {children}
    </FormattingKeysContext.Provider>
  )
}