import { useMemo } from 'react'

import useGoUpdateModulePieces from './useGoUpdateModulePieces'

const useGoUpdateModulePiece = ({
  modulePiece,
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  moduleId = moduleId || modulePiece.moduleId

  const [ goUpdate, goDelete ] = useGoUpdateModulePieces({
    projectId,
    moduleId,
    undoRedoStack,
  })

  const toReturn = useMemo(
    () => ([
      (updateObj={}, options) => (
        goUpdate(
          [ updateObj ],
          modulePiece && [ modulePiece ],
          options,
        )[0]
      ),
      ...(modulePiece ? [ () => goDelete([ modulePiece ]) ] : []),
    ]),
    [ modulePiece, goUpdate, goDelete ],
  )

  return toReturn
}

export default useGoUpdateModulePiece