import { memo, useMemo, useRef, useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Virtuoso } from 'react-virtuoso'
import { useMeasure } from 'react-use'

import { IS_EMBED } from '../../../utils/constants'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useOutlineShortcuts from '../../../hooks/useOutlineShortcuts'
import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import useSetTimeout from '../../../hooks/useSetTimeout'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { equalObjs } from '../../../utils/misc'
import { getEmbedMode } from '../../../graphql/links/embedLink'

import OutlineRow from './OutlineRow'
import Loading from '../../common/Loading'

const StyledVirtuoso = styled(Virtuoso)`

  margin: 0 -15px;
  flex: 1;

  ${({ $embedFullScreen }) => !(IS_EMBED && getEmbedMode() !== `prep` && !$embedFullScreen) ? `` : `
    overflow: hidden !important;
  `}

`

const Container = styled.div`
  flex: 1;
  max-width: 960px;
  position: relative;
  display: flex;
  flex-direction: column;

  font-size: ${({ $textFontSize }) => $textFontSize}px;
  line-height: ${({ $lineSpacing }) => $lineSpacing};

  ${({ $showVsNums }) => $showVsNums ? `` : `
    .TextContent-tag-v,
    .TextContent-tag-vp {
      display: none;
    }
  `}
`

const HeaderSpacer = styled.div`
  height: 60px;
`

const FooterSpacer = styled.div`
  height: ${IS_EMBED ? `75px` : `calc(100vh - 200px)`};
`

const OutlineContent = ({
  module,
  projectId,
  textFontSize,
  lineSpacing,
  showVsNumsSetting,
  showCfsSetting,
  showNotesSetting,
  showCantillationSetting,
  showHebrewVowelsSetting,
  showGreekAccentsSetting,
  greekPunctuationSetting,
  lastScrollPositionSetting,
  // containerRef,
  roughNumberOfVerses,
  rows,
  pastedInVerses,
  chaptersReady,
  goCreateModulePiece,
  inEditingMode,
  noneditableViewingMode,
  goSetPopperInfo,
  goUndo,
  goRedo,
  goPrintOrDownload,
  virtuoso,
  setHeight,
  embedFullScreen,
}) => {

  const { modulePassages, id: moduleId } = module
  const { versionId } = modulePassages[0].info

  const containerRef = useRef(null)
  const scrollerRef = useRef(null)
  const setScrollerRef = useCallback(ref => { scrollerRef.current = ref }, [ scrollerRef ])
  const [ isScrolling, setIsScrolling ] = useState()

  const [ setContainerRef2, { width: contentWidth } ] = useMeasure()
  const setContainerRef = useCallback(
    ref => {
      setContainerRef2(ref)
      containerRef.current = ref
    },
    [ setContainerRef2 ],
  )

  const defaultItemHeight = useMemo(
    () => {
      const numHeadings = rows.reduce((total, row) => total + Object.values(row.modulePieces || {x:0}).length - 1, 0)
      const roughTotalHeightOfHeadings = numHeadings * textFontSize * 2.6
      const singleLineHeight = textFontSize * lineSpacing
      const roughTotalHeightOfText = roughNumberOfVerses * (textFontSize / 14) * singleLineHeight * (740 / contentWidth) * (versionId === `original` ? .85 : 1)
      return (roughTotalHeightOfText + roughTotalHeightOfHeadings) / rows.length
    },
    [ rows, textFontSize, lineSpacing, roughNumberOfVerses, contentWidth, versionId ],
  )

  const ready = chaptersReady && contentWidth > 0

  const [ goUpdateModule ] = useGoUpdateModule({ module })
  const getModule = useInstanceValue(module)

  const [ goSetLastScrollPositionSetting ] = useGoSetModuleSetting({
    moduleSetting: lastScrollPositionSetting,
    projectId,
  })

  useOutlineShortcuts({
    getModule,
    goUpdateModule,
    goUndo,
    goRedo,
    goPrintOrDownload,
    noneditableViewingMode,
  })

  const Components = useMemo(
    () => {
      return {
        Header: () => <HeaderSpacer />,
        Footer: () => <FooterSpacer />,
      }
    },
    [],
  )

  useEffectAsync(
    () => {
      if(!ready) return
      if(noneditableViewingMode) return
      if(IS_EMBED && getEmbedMode() !== `prep`) return
      virtuoso.current.scrollToIndex({ ...lastScrollPositionSetting.value })
    },
    [ ready ],
  )

  const currentLastScrollPositionSettingValue = useRef(lastScrollPositionSetting.value)
  const newLastScrollPositionSettingValue = useRef(lastScrollPositionSetting.value)
  const [ setUpdateLastScrollPositionTimeout ] = useSetTimeout()
  const updateLastScrollPosition = () => {
    if(!equalObjs(currentLastScrollPositionSettingValue.current, newLastScrollPositionSettingValue.current)) {
      goSetLastScrollPositionSetting({
        value: newLastScrollPositionSettingValue.current,
      })
      currentLastScrollPositionSettingValue.current = newLastScrollPositionSettingValue.current
    }

  }
  useEffect(() => updateLastScrollPosition, [])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffectAsync(
    () => {
      if(!isScrolling && scrollerRef.current && !noneditableViewingMode) {
        const rowEls = scrollerRef.current.querySelectorAll(`[data-index]`)
        const containerTop = containerRef.current.getBoundingClientRect().top
        for(let idx=0; idx<rowEls.length; idx++) {
          const { top } = rowEls[idx].getBoundingClientRect()
          const offset = (top - containerTop) * -1
          if(offset < 0 || idx === rowEls.length - 1) {
            newLastScrollPositionSettingValue.current = {
              align: `start`,
              index: parseInt(rowEls[idx].getAttribute(`data-index`), 10),
              offset,
            }
            setUpdateLastScrollPositionTimeout(updateLastScrollPosition, 5000)
            break
          }
        }
      }
    },
    [ isScrolling ],
  )

  return (
    <Container
      className="options-popper-no-pointer-events-exempt"
      $textFontSize={textFontSize}
      $lineSpacing={lineSpacing}
      $showVsNums={showVsNumsSetting.value}
      ref={setContainerRef}
    >

      {ready &&
        <StyledVirtuoso
          ref={virtuoso}
          scrollerRef={setScrollerRef}
          totalCount={rows.length}
          computeItemKey={index => ((rows[index].modulePieces || {}).break || {}).id || `${rows[index].fromLoc}`}
          isScrolling={setIsScrolling}
          defaultItemHeight={defaultItemHeight}
          totalListHeightChanged={IS_EMBED ? setHeight : null}
          itemContent={index => (
            <OutlineRow
              {...rows[index]}
              pastedInVerses={pastedInVerses}
              projectId={projectId}
              nextBreakIsAnAutoBreak={!!rows[index+1] && !rows[index+1].modulePieces}
              versionId={versionId}
              goCreateModulePiece={goCreateModulePiece}
              isScrolling={isScrolling}
              goSetPopperInfo={goSetPopperInfo}
              inEditingMode={inEditingMode}
              showCfs={showCfsSetting.value}
              showNotes={showNotesSetting.value}
              showCantillation={showCantillationSetting.value}
              showHebrewVowels={showHebrewVowelsSetting.value}
              showGreekAccents={showGreekAccentsSetting.value}
              greekPunctuation={greekPunctuationSetting.value}
            />
          )}
          components={Components}
          data-module-id={moduleId}
          className="OutlineContent-StyledVirtuoso"
          $embedFullScreen={embedFullScreen}
        />
      }

      {!ready && <Loading />}

    </Container>
  )
}

export default memo(OutlineContent)