import { db } from '../../utils/database'

const modulePieces = async args => (

  await db.transaction(
    'r',
    db.modulePieces,
    async () => {

      const { moduleId, position, orderingFrom, orderingTo, offset } = args

      let modulePieces = db.modulePieces

      if(orderingFrom == null && orderingTo == null) {

        modulePieces = modulePieces.where({
          moduleId,
          ...(position == null ? {} : {
            position,
          }),
        })

      } else if(position) {

        modulePieces = modulePieces
          .where([ "moduleId", "position", "ordering" ])
          .between(
            [
              moduleId,
              position,
              orderingFrom != null ? orderingFrom : -Infinity
            ],
            [
              moduleId,
              position,
              orderingTo != null ? orderingTo + 1 : -Infinity
            ],
            true,
            false,
          )

      } else {

        modulePieces = modulePieces
          .where([ "moduleId", "ordering" ])
          .between(
            [
              moduleId,
              orderingFrom != null ? orderingFrom : -Infinity
            ],
            [
              moduleId,
              orderingTo != null ? orderingTo + 1 : -Infinity
            ],
            true,
            false,
          )

      }

      modulePieces = await modulePieces.toArray()

      modulePieces = modulePieces.sort((a, b) => a.ordering < b.ordering ? -1 : 1)

      if(offset != null) {
        modulePieces = modulePieces.slice(offset, offset + 1)
      }

      return modulePieces

    }
  )

)

export default modulePieces