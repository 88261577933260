import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

const Event = styled.div`
`

const BibleMapPrecisionScroll = ({
  events,
}) => {


  return (
    <>

      {/* <Virtuoso
        key={selectedBookId}
        totalCount={startAndEndVersesByChapter.length}
        itemContent={index => <Chapter index={index} />}
        components={{
          Footer: () => <FooterSpacer />,
        }}
        tabIndex={-1}
      /> */}

      <Event />

    </>
  )
}

export default memo(BibleMapPrecisionScroll)