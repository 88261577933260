const getOgInfo = async url => {

  try {
    const response = await fetch(`https://app-data.biblearc.com/apis/og-info?url=${encodeURIComponent(url)}`)
    const json = await response.json()
    return json
  } catch(err) {
    return {}
  }

}

export default getOgInfo