import { useMemo, useState } from 'react'

import useLayoutEffectAsync from './useLayoutEffectAsync'
import useGoUpdateModuleMarkup from './useGoUpdateModuleMarkup'
import useGoUpdateHighlight from './useGoUpdateHighlight'
import useInstanceValuesCallback from './useInstanceValuesCallback'

export const moduleMarkupOnActiveLens = ({ lenses, formattingKeyInfoId, selectedColor }) => ({ container, positionInfo, color }) => (
  lenses === `OFF`
  || (
    container === `formattingKeyId:${formattingKeyInfoId}`
    && !!positionInfo
    && [ color, `ALL`, undefined, null ].includes(selectedColor)
  )
)

export const wordIsSameOrBefore = (a,b) => (
  a.loc < b.loc
  || (
    a.loc === b.loc
    && a.wordNumberInVerse <= b.wordNumberInVerse
  )
)

export const getWordObjFromEl = el => {
  if(!el) return {}
  const [ loc, wordNumberInVerse ] = el.getAttribute(`data-word-loc`).split(':')
  return {
    loc,
    wordNumberInVerse: parseInt(wordNumberInVerse, 10),
  }
}

export const getHighlightOrModuleMarkupInfo = item => {
  const { __typename, positionInfo={}, info, ...other } = item || {}

  const getLocInfo = prefix => ({
    loc: other[`${prefix}Loc`],
    wordNumberInVerse: other[`${prefix}WordNumber`],
    characterNumberInWord: other[`${prefix}CharacterNumber`],
  })

  if(__typename === `Highlight`) {
    return {
      ...info,
      start: getLocInfo(`from`),
      end: getLocInfo(`to`),
    }
  }

  return {
    ...item,
    ...positionInfo,
  }
}

const convertLocInfoToHighlightDetails = ({ prefix, loc, wordNumberInVerse, characterNumberInWord }) => ({
  [`${prefix}Loc`]: loc,
  [`${prefix}WordNumber`]: wordNumberInVerse,
  [`${prefix}CharacterNumber`]: characterNumberInWord || (prefix === `from` ? 1 : 0),
})

export const convertMarkupModuleToHighlight = ({ markupModule: { positionInfo, type, color }, versionId, extraInfo={} }) => ({
  info: {
    ...extraInfo,
    type,
    color,
  },
  ...convertLocInfoToHighlightDetails({ prefix: `from`, ...positionInfo.start }),
  ...convertLocInfoToHighlightDetails({ prefix: `to`, ...positionInfo.end }),
  versionId,
})

const useTextSelectionMarkup = ({
  projectId,
  moduleId,
  moduleMarkups,
  highlights,
  formattingKeyInfo,
  lenses,
  selectedColor,
  setSelectedColor,
  selectionStart,
  selectionEnd,
  firstWordEl,
  lastWordEl,
  versionId,
}) => {

  const mode = moduleMarkups ? `markup-module` : `highlights`  // `tools` will be another option
  const items = moduleMarkups || highlights

  const formattingKeyInfoId = (formattingKeyInfo || {}).id

  const [ moduleMarkupsToDelete, setModuleMarkupsToDelete ] = useState([])
  const [ goCreateModuleMarkup, goDeleteModuleMarkup ] = useGoUpdateModuleMarkup({
    projectId,
    moduleId,
    moduleMarkup: moduleMarkupsToDelete[0],
  })

  const [ highlightsToDelete, setHighlightsToDelete ] = useState([])
  const [ goCreateHighlight, goDeleteHighlight ] = useGoUpdateHighlight({
    highlight: highlightsToDelete[0],
  })

  const markupByTypeAndColor = useMemo(
    () => {

      const markupByTypeAndColor = {}

      const markupRangeMatches = ({ start, end }) => (
        start.loc === selectionStart.loc
        && start.wordNumberInVerse === selectionStart.wordNumberInVerse
        && end.loc === selectionEnd.loc
        && end.wordNumberInVerse === selectionEnd.wordNumberInVerse
      )

      items.forEach(item => {
        const { type, color, start, end } = getHighlightOrModuleMarkupInfo(item)
        if(
          (
            mode !== `markup-module`
            || moduleMarkupOnActiveLens({ lenses, formattingKeyInfoId })(item)
          )
          && markupRangeMatches({ start, end })
        ) {
          markupByTypeAndColor[`${type} ${color}`] = item
        }
      })

      return markupByTypeAndColor

    },
    [ selectionStart, selectionEnd, items, lenses, formattingKeyInfoId, mode ],
  )

  const convertNewMarkupModuleToNewHighlight = useInstanceValuesCallback(markupModule => convertMarkupModuleToHighlight({ markupModule, versionId }))

  const toggleMarkup = useInstanceValuesCallback(
    event => {
      event.preventDefault && event.preventDefault()
      const type = event.markupType || event.target.closest(`[data-type]`).getAttribute(`data-type`)
      const color = event.color || event.target.closest(`[data-color]`).getAttribute(`data-color`)
      const markupToRemove = markupByTypeAndColor[`${type} ${color}`]
      if(markupToRemove) {
        if(mode === `highlights`) {
          setHighlightsToDelete([ markupToRemove ])
        } else {
          setModuleMarkupsToDelete([ markupToRemove ])
        }
      } else {
        const newModuleMarkup = {
          container: `formattingKeyId:${formattingKeyInfoId}`,
          positionInfo: {
            start: selectionStart,
            end: selectionEnd,
          },
          type,
          color,
        }
        if(mode === `highlights`) {
          goCreateHighlight(
            convertNewMarkupModuleToNewHighlight(
              newModuleMarkup
            )
          )
          const highlightsToRemove = Object.keys(markupByTypeAndColor).filter(key => key.split(` `)[0] === type).map(key => markupByTypeAndColor[key])
          setHighlightsToDelete(highlightsToRemove)
        } else {
          goCreateModuleMarkup(newModuleMarkup)
          if(
            mode === `markup-module`
            && ![ color, `ALL` ].includes(selectedColor)
          ) {
            setSelectedColor(`ALL`)
          }
        }
      }
    },
  )

  const clearMarkup = useMemo(
    () => {

      const markupsToRemove = []
      const markupsToCreate = []

      items.forEach(item => {
        const { container, type, color, start, end } = getHighlightOrModuleMarkupInfo(item)
        if(
          mode !== `markup-module`
          || moduleMarkupOnActiveLens({ lenses, formattingKeyInfoId, selectedColor })(item)
        ) {
          if(
            wordIsSameOrBefore(selectionStart, end)
            && wordIsSameOrBefore(start, selectionEnd)
          ) {

            markupsToRemove.push(item)

            const allWordsInThisMarkupModule = [ ...firstWordEl.closest(`.TextContentMarkupContainer-Container`).querySelectorAll(`.text-content-word[data-word-loc]`) ]
            const wordElBeforeSelectionStart = allWordsInThisMarkupModule[allWordsInThisMarkupModule.findIndex(el => el === firstWordEl) - 1]
            const wordBeforeSelectionStart = getWordObjFromEl(wordElBeforeSelectionStart)
            const lastWordElIdx = allWordsInThisMarkupModule.findIndex(el => el === lastWordEl)
            const wordElAfterSelectionStart = lastWordElIdx !== -1 ? allWordsInThisMarkupModule[lastWordElIdx + 1] : null
            const wordAfterSelectionEnd = getWordObjFromEl(wordElAfterSelectionStart)

            if(wordIsSameOrBefore(start, wordBeforeSelectionStart)) {
              markupsToCreate.push({
                container,
                positionInfo: {
                  start,
                  end: wordBeforeSelectionStart,
                },
                type,
                color,
              })
            }

            if(wordIsSameOrBefore(wordAfterSelectionEnd, end)) {
              markupsToCreate.push({
                container,
                positionInfo: {
                  start: wordAfterSelectionEnd,
                  end,
                },
                type,
                color,
              })
            }

          }
        }
      })

      if(markupsToRemove.length > 0) {
        return () => {
          if(mode === `highlights`) {
            setHighlightsToDelete(markupsToRemove)
            markupsToCreate.forEach(item => {
              goCreateHighlight(
                convertNewMarkupModuleToNewHighlight(
                  item
                )
              )
            })
          } else {
            setModuleMarkupsToDelete(markupsToRemove)
            markupsToCreate.forEach(goCreateModuleMarkup)
          }
        }
      }

    },
    [ selectionStart, selectionEnd, lenses, formattingKeyInfoId, selectedColor, goCreateModuleMarkup, firstWordEl, lastWordEl, convertNewMarkupModuleToNewHighlight, goCreateHighlight, items, mode ],
  )

  useLayoutEffectAsync(
    () => {
      if(moduleMarkupsToDelete[0]) {
        goDeleteModuleMarkup()
        const newModuleMarkupsToDelete = [ ...moduleMarkupsToDelete ]
        newModuleMarkupsToDelete.shift()
        setModuleMarkupsToDelete(newModuleMarkupsToDelete)
      }
    },
    [ moduleMarkupsToDelete ],
  )

  useLayoutEffectAsync(
    () => {
      if(highlightsToDelete[0]) {
        goDeleteHighlight()
        const newHighlightToDelete = [ ...highlightsToDelete ]
        newHighlightToDelete.shift()
        setHighlightsToDelete(newHighlightToDelete)
      }
    },
    [ highlightsToDelete ],
  )

  return {
    markupByTypeAndColor,
    toggleMarkup,
    clearMarkup,
  }
}

export default useTextSelectionMarkup
