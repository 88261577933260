import { memo } from 'react'
import styled from 'styled-components'
import { getArrow } from 'perfect-arrows'

const MIN_DISTANCE_FOR_ARROWHEAD = 50

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: ${({ $opacity }) => $opacity || 1};
`

const JourneyArrow = ({
  placeA,
  placeB,
  getPlaceInfo,
  color=`black`,
  scale,
  nthRouteBetweenThesePlaces,
  ...otherProps
}) => {

  const multiplier = 100 / scale
  const arrowHeadSize = 7 * multiplier

  const placeAInfo = getPlaceInfo({ place: placeA })
  const placeBInfo = getPlaceInfo({ place: placeB })

  // if points close enough, drop the arrowhead
  const distanceBetweenPoints = Math.sqrt(Math.pow(placeAInfo.left - placeBInfo.left, 2) + Math.pow(placeAInfo.top - placeBInfo.top, 2))
  const visualDistanceBetweenPoints = distanceBetweenPoints / multiplier
  const hideArrowHead = visualDistanceBetweenPoints < MIN_DISTANCE_FOR_ARROWHEAD

  const singleCurveOptions = {
    padStart: 10 * multiplier,
    padEnd: (hideArrowHead ? 10 : 24) * multiplier,
    bow: 0.1 + ((nthRouteBetweenThesePlaces - 1) * 0.1),
    stretch: 0,
    // stretchMin: 0,
    // stretchMax: 500,
    // flip: false,
  }

  const arrowPositionDetails = getArrow(placeAInfo.left, placeAInfo.top, placeBInfo.left, placeBInfo.top, singleCurveOptions)

  const [ sx, sy, cx, cy, ex, ey, ae ] = arrowPositionDetails || []  // two more params not presently used: as, ec
  const endAngleAsDegrees = ae * (180 / Math.PI)
  const arrowD = `M${sx},${sy} Q${cx},${cy} ${ex},${ey}`
  const arrowHeadTransform = `translate(${ex},${ey}) rotate(${endAngleAsDegrees})`

  return (
    <>
      {visualDistanceBetweenPoints > 20 &&
        <Svg
          {...otherProps}
          xmlns="http://www.w3.org/2000/svg">
          <path
            d={arrowD}
            stroke={color}
            strokeWidth={arrowHeadSize / 2}
            fill="none"
          />
          {!hideArrowHead &&
            <polygon
              points={`0,${-arrowHeadSize} ${arrowHeadSize * 2},0, 0,${arrowHeadSize}`}
              transform={arrowHeadTransform}
              fill={color}
            />
          }
        </Svg>
      }
    </>
  )

}

export default memo(JourneyArrow)