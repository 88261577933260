import { memo } from 'react'
import styled from 'styled-components'

const Dots = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 15px 0;
  position: relative;
  z-index: 3;
`

const Dot = styled.div`
  width: 20px;
  height: 20px;
  border: 4px solid white;
  border-radius: 10px;
  transition: background-color .15s ease-in-out;

  ${({ $selected, theme }) => !$selected ? `` : `
    background-color: ${theme.palette.primary.main};
  `}

  ${({ $selected, theme }) => $selected ? `` : `
    background-color: ${theme.palette.grey[300]};

    &:hover {
      cursor: pointer;
      background-color: ${theme.palette.grey[400]};
    }
  `}
`

const StudyBibleDots = ({
  numDots,
  pageKey,
  setPageKey,
}) => {

  return (
    <Dots>
      {Array(numDots).fill().map((x, idx) => (
        <Dot
          key={idx}
          $selected={idx === pageKey}
          onClick={() => setPageKey(idx)}
        />
      ))}
    </Dots>
  )
}

export default memo(StudyBibleDots)