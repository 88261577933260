import { useRef, useCallback } from "react"
import { useUnmount, useMountedState } from "react-use"

import useInstanceValue from "./useInstanceValue"

const useSetTimeout = ({ fireOnUnmount }={}) => {

  const isMounted = useMountedState()
  const timeout = useRef()
  const timeoutFunc = useRef(() => {})
  const getFireOnUnmount = useInstanceValue(fireOnUnmount)

  useUnmount(() => {
    if(getFireOnUnmount()) {
      timeoutFunc.current()
    }
    clear()
  })

  const getIsActive = useCallback(() => timeout.current !== undefined, [])

  const set = (func, ...otherProps) => {
    if(!isMounted()) return
    clear()
    timeoutFunc.current = func
    timeout.current = setTimeout(
      () => {
        timeout.current = undefined
        func()
      },
      ...otherProps
    )
  }

  const clear = () => {
    clearTimeout(timeout.current)
    timeout.current = undefined
    timeoutFunc.current = () => {}
  }

  return [ set, clear, getIsActive ]
}

export default useSetTimeout