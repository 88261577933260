import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import countries from '../../../utils/countries'

const StyledAutocomplete = styled(Autocomplete)`
  width: 300px;
  margin: 10px 0 30px;
`

const Flag = styled.span`
  display: inline-block;
  margin-right: 10px;
`

const countryToFlag = isoCode => (
  isoCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
)

const CountrySelect = ({
  label=i18n("Your country of residence"),
  ...otherProps
}) => {

  return (

    <StyledAutocomplete
      {...otherProps}
      options={countries}
      getOptionLabel={option => option.label}
      renderOption={(option) => (
        <>
          <Flag>{countryToFlag(option.code)}</Flag>
          {option.label}
        </>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off', // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
}


export default memo(CountrySelect)