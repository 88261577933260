import { memo } from 'react'

import ArrowHighlight from './ArrowHighlight'

const ArrowHighlightLeft = ({
  startType,
  endType,
  ...otherProps
}) => (
  <ArrowHighlight
    {...otherProps}
    startType="point"
    endType="tail"
  />
)


export default memo(ArrowHighlightLeft)