import React, { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { getPOSTerm } from '@bibletags/bibletags-ui-helper'
import Tooltip from '@material-ui/core/Tooltip'

import { IS_EMBED } from '../../utils/constants'
import { getEmbedFullScreen, getEmbedSettings } from "../../graphql/links/embedLink"
import useMorphPos from '../../hooks/useMorphPos'
import useDefinition from '../../hooks/useDefinition'
// import { TODO_defaultLanguageId } from '../../utils/constants'

import Loading from './Loading'
import OriginalLanguageWordSearchMenu from './OriginalLanguageWordSearchMenu'
import OriginalLanguageWordAltLexicons from './OriginalLanguageWordAltLexicons'

const Container = styled.div`
  flex: 1;
  margin: 0 -16px -16px;
  display: flex;
  background-color: rgb(86 86 86);
`

const WordInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const BasicInfo = styled.div`
  padding: 8px 16px;
`

const WordInfoLine = styled.div`
  display: flex;
  margin: 8px 0;
`

const WordInfoLineContent = styled.div`
`

const Lex = styled.span`
  display: inline-block;
  margin-right: 7px;
  font-size: 17px;
  line-height: 20px;

  ${({ $isGreek }) => !$isGreek ? `` : `
    font-size: 19px;
  `}
`

const Vocal = styled.span`
  display: inline-block;
  margin-right: 5px;
  color: ${({ theme }) => theme.palette.grey[400]};
`

const Strongs = styled.span`
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
`

const Hash = styled.span`
  color: ${({ theme }) => theme.palette.grey[500]};
`

const Hits = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 13px;
  margin-right: 5px;
`

const LxxHits = styled.span`
  margin-left: 3px;
  display: inline-block;
  font-size: 10px;
  opacity: .5;
`

const Gloss = styled.span`
  display: inline-block;
  font-weight: 500;
  margin-right: 7px;
  position: relative;
  min-width: 20px;
  min-height: 10px;
`

const PosGroup = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.palette.grey[600]};
`

const Pos = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.palette.grey[600]};

  ${({ $isMorphPos, theme }) => !$isMorphPos ? `` : `
    color: ${theme.palette.grey[400]};
  `}
`

// const Usage = styled.div`
//   width: 120px;
//   background-color: rgb(101 101 101);
//   padding: 16px;
// `

// const UsageHeading = styled.div`
// `

// const UsageLine = styled.div`
// `

const OriginalLanguageWordInfo = ({
  lemma,
  strong,
  morph,
  form,
  // id,
  contextRef,
  // goSetPopperInfo,
  basicInfoOnly,
  disabled,
  ...otherProps
}) => {

  disabled = (
    disabled
    || (
      IS_EMBED
      && !getEmbedFullScreen()
      && getEmbedSettings().embedType !== `search`
    )
  )

  const { nakedStrongs, definition } = useDefinition(strong)
  const { morphPos, morphLang } = useMorphPos({ morph })

  const languageId = /^G/.test(strong) ? `grc` : `heb`

  const {
    lex,
    // lexUnique,
    vocal,
    hits,
    gloss,
    pos,
    // syn,
    // rel,
    lxx,
    // lemmas,  // eventually show with poss info
    // forms,
    lexEntry,
  } = definition || {}

  let posAsInMorph
  if(!pos) {
    posAsInMorph = [ morphPos || `` ]
  } else {
    if(languageId === `grc`) {
      // swap P and R to be like in `morphPos` and `fullParsing`
      posAsInMorph = pos.map(posCode => ({
        P: `R`,
        R: `P`,
      }[posCode] || posCode))
    } else {
      posAsInMorph = pos
    }
  }

  const {
    // poss,
    // root,  // eventually show with poss info
    // isAramaic,  // eventually show with poss info
    alts=[],
  } = lexEntry || {}

  return (
    <Container {...otherProps} >

      <WordInfo>

        <BasicInfo>

          <WordInfoLine>

            <WordInfoLineContent>

              <Lex
                $isGreek={morphLang === `Gr`}
                className="OriginalLanguageWordInfo-Lex"
              >
                {lemma || lex || ``}
              </Lex>

              {` `}

              {!!vocal &&
                <>
                  <Vocal className="OriginalLanguageWordInfo-Vocal">
                    {vocal}
                  </Vocal>
                  {` `}
                </>
              }

              <Strongs>
                <Hash className="OriginalLanguageWordInfo-Hash">#</Hash>
                {nakedStrongs}
              </Strongs>

              {` `}

              {hits !== undefined &&
                <Hits className="OriginalLanguageWordInfo-Hits">
                  {i18n("{{count}}x", { count: hits })}
                  {Number.isInteger(lxx) &&
                    <>
                      {` `}
                      <Tooltip title={i18n("Hits in canonical portions of the LXX")}>
                        <LxxHits>
                          {i18n("(LXX: {{count}}x)", { count: lxx })}
                        </LxxHits>
                      </Tooltip>
                    </>
                  }
                </Hits>
              }

            </WordInfoLineContent>

            {!disabled &&
              <OriginalLanguageWordSearchMenu
                strong={strong}
                morph={morph}
                form={form}
                lemma={lemma}
                bookId={(contextRef || {}).bookId}
                disabled={disabled}
              />
            }

          </WordInfoLine>

          <WordInfoLine>
            <WordInfoLineContent>

              {!(definition && !gloss) &&
                <>
                <Gloss>
                  {definition ? gloss : <Loading size={10} bgOpacity={0} />}
                </Gloss>
                {` `}
                </>
              }

              <PosGroup className="OriginalLanguageWordInfo-PosGroup">
                {posAsInMorph.map((posCode, idx) => (
                  <React.Fragment key={posCode}>
                    {idx > 0 && i18n("/", "alternatives separator")}
                    <Pos
                      $isMorphPos={posCode === morphPos}
                      className="OriginalLanguageWordInfo-Pos"
                    >
                      {getPOSTerm({ languageId, posCode })}
                    </Pos>
                  </React.Fragment>
                ))}
              </PosGroup>

            </WordInfoLineContent>
          </WordInfoLine>

        </BasicInfo>

        {!basicInfoOnly && alts[0] &&
          <OriginalLanguageWordAltLexicons
            alts={alts}
            disabled={disabled}
          />
        }

      </WordInfo>

      {/* <Usage className="OriginalLanguageWordInfo-Usage">

        <UsageHeading>
        </UsageHeading>

        <UsageLine>
        </UsageLine>

      </Usage> */}

    </Container>
  )
}

export default memo(OriginalLanguageWordInfo)