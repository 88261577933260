import { memo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'

import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'

const Container = styled.div`
  margin: 3px 0 5px;
  border-radius: 15px;
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 12px 17px;
  line-height: 1.15;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  width: 420px;
`

const TitleAndBody = styled.div`
  flex: 1;
  min-width: 0;
`

const NotificationTitle = styled.div`
  font-weight: bold;
`

const IconImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
`

const ImageImg = styled.img`
  width: 100%;
  display: block;
  margin: 10px 0 5px;
  border-radius: 10px;
`

const NotificationsItem = ({
  id,
  title,
  info,
  notificationBody: notificationBodyFromProps,
  notificationClickUrl,
  notificationIcon,
  notificationImage,
}) => {

  let url = (id || ``).split('\n')[1] || notificationClickUrl

  const {
    icon=notificationIcon,
    image=notificationImage,
    notificationBody=notificationBodyFromProps,
  } = info || {}

  if(/^\/equip\//.test(url)) {
    url += `${/\?/.test(url) ? `&` : `?`}title=${encodeURIComponent(title)}&body=${encodeURIComponent(notificationBody)}`
  }

  return (
    <NavLinkOrAWithDisable
      to={url}
    >
      <Container>
        <TitleAndBody>
          <NotificationTitle>
            {title}
          </NotificationTitle>
          {notificationBody}
          {image && <ImageImg src={image} />}
        </TitleAndBody>
        {icon && <IconImg src={icon} />}
      </Container>
    </NavLinkOrAWithDisable>
  )
}

export default memo(NotificationsItem)