import React from "react"
import { i18n, getLocale } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import {
  BORDER_COLOR,
  DEFAULT_ACTIVE_COLOR,
  INACTIVE_COLOR,
  DEFAULT_MODES,
  getToolbarHeight,
  getToolbarHeightWithBorder,
  getToolbarSectionMinimumWidth,
  getToolbarIconFontSize,
} from './utils/constants'
import styled from 'styled-components'

import DoneIcon from '@material-ui/icons/Done'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import SettingsEditor from './SettingsEditor'

import toolbarSections from './utils/toolbarSections'

const ToolbarAndSettingsEditorContainer = styled.div`
  position: relative;
  z-index: 10;
`

const ToolbarContainer = styled.div`
  border: 1px solid ${BORDER_COLOR};
  height: ${getToolbarHeight};
  display: flex;
  background: white;
  position: relative;
  z-index: 20;
  padding-right: 0;

  &.noFlipButton > div:last-child {
    margin-right: -1px;
  }
`

const ToolbarSection = styled.div`
  display: flex;
  white-space: nowrap;
  border-right: 1px solid ${BORDER_COLOR};
  flex-shrink: 1;
  min-width: ${getToolbarSectionMinimumWidth};

  ${({ $shrinkMode }) => $shrinkMode !== `first` ? `` : `
    flex-shrink: 999999999;
  `}

  ${({ $shrinkMode }) => $shrinkMode !== `never` ? `` : `
    flex-shrink: 0;
  `}
`

const ToolbarDivider = styled.div`
  width: 1px;
  min-width: 1px;
  background: ${BORDER_COLOR};
  margin: 0 5px;
`

const SectionInstructions = styled.div`
  font-size: 9px;
  background: ${INACTIVE_COLOR};
  color: ${INACTIVE_COLOR};
  line-height: 14px;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 3px;
  font-weight: 500;
  margin: -1px;
  user-select: none;
  ${({ $active }) => !$active ? `` : `
    {
      background: ${DEFAULT_ACTIVE_COLOR};
      color: white;
    }
  `}
`

const Type1SectionInstructions = styled(SectionInstructions)`
`

const Type2SectionInstructions = styled(SectionInstructions)`
  width: 3px;
  min-width: 3px;
  padding: 0;
  & > div {
    transform: rotate(270deg);
    display: block;
    width: 0;
    transform-origin: center;
    margin: 0;
    height: 5px;
    margin-top: 57px;
  }
`

const SettingsHeading = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`

const SectionButtonsContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`

const SectionButtons = styled.div`
  padding: 0 5px;
  display: flex;
`

const FlipButtonCloseIcon = styled(props => <DoneIcon {...props} />)`
  color: white;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  padding: 26%;
  box-sizing: border-box;
`

const TextLabel = styled.span`
  font-size: ${getToolbarIconFontSize};
  width: ${getToolbarHeight};
  margin: 0 -12px;
  ${({ $active }) => !$active ? `` : `
    {
      color: ${DEFAULT_ACTIVE_COLOR};
    }
  `}
`

// TODO: shift-space to click this.
const FlipButton = styled.div`
  position: absolute;
  z-index: 30;
  right: 0;
  top: 0;
  background-color: ${DEFAULT_ACTIVE_COLOR};
  width: ${getToolbarHeightWithBorder};
  height: ${getToolbarHeightWithBorder};
  transition: transform .1s ease-in-out;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`

const FlipLogo = styled.div`
  color: white;
  transition: transform .3s ease-in-out;
  transform: rotate(${({ $rotateIterator }) => 360 * $rotateIterator}deg);
  font-size: 15px;
  font-weight: 300;
`

const Spacer = styled.div`
  min-width: ${getToolbarHeight};  // for the size of the flip button
  flex-grow: 1;
`

const TooltipAnchor = styled.span`
  display: inline-block;
  border-radius: 50%;
`

const SettingsLabel = styled.div`
  align-self: center;
  line-height: 36px;
  padding: 0 15px;
  font-size: ${getToolbarIconFontSize};
  font-weight: 100;
  white-space: nowrap;
  overflow-x: auto;
`

const SettingsTitle = styled.span`
  font-weight: 500;
`

const StyledIconButton = styled(IconButton)`
  width: ${getToolbarHeight};
  height: ${getToolbarHeight};
  box-sizing: border-box;

  ${({ $toolbarSize }) => $toolbarSize !== `small` ? `` : `
    svg {
      width: 18px;
      height: 18px;
    }
  `}
`

const TrashIconButton = styled(StyledIconButton)`
  margin: 0 ${getToolbarHeightWithBorder} 0 6px;
`

const tooltipPopperProps = {
  style: {
    pointerEvents: 'none',
    top: '-30px',
  }
}

class Toolbar extends React.PureComponent {

  state = {
    modes: [ ...DEFAULT_MODES ],
    mode: 'write',
    modeIterator: 0,
  }

  handleMouseDown = event => {
    event.preventDefault()
  }

  handleClick = ({ currentTarget }) => {
    const { toggleType } = this.props

    toggleType({
      sectionType: currentTarget.getAttribute('data-sectiontype'),
      type: currentTarget.getAttribute('data-type'),
      isEntity: !!currentTarget.getAttribute('data-isentity'),
    })
  }

  handleInsertClick = ({ currentTarget }) => {
    const { insertType } = this.props

    insertType({
      type: currentTarget.getAttribute('data-type'),
    })
  }

  flipMode = () => {
    const { modes, mode, modeIterator } = this.state

    this.setState({
      mode: modes[ (modes.indexOf(mode) + 1) % modes.length ],
      modeIterator: modeIterator + 1,
    })
  }

  render() {
    const { options, tabMode, currentBlockType, currentStyle, disabledEntities, blurred,
            settingsOpen, settingsComponentTypeLabel, closeSettings, deleteSettingsBlock,
            updateComponentSettings, settingsComponentDataStructure, indicateSettingsHeight,
            settingsComponentData, currentEntityType, customComponentTypes, style,
            openAssetListDialog, openUploadAssetDialog, toolbarSize } = this.props
    const { mode, modeIterator } = this.state

    const locale = getLocale()

    let toolbarContent
    let flipButtonContent = (
      <FlipLogo
        $rotateIterator={modeIterator}
      >
        Flip
      </FlipLogo>
    )

    if(settingsOpen) {

      toolbarContent = (
        <SettingsHeading>
          <Type2SectionInstructions
            $active={true}
          />
          <SettingsLabel $toolbarSize={toolbarSize}>
            {i18nReact("Settings: {{type}}", {
              type: (
                <SettingsTitle>
                  {settingsComponentTypeLabel}
                </SettingsTitle>
              ),
            })}
          </SettingsLabel>
        </SettingsHeading>
      )

      flipButtonContent = (
        <FlipButtonCloseIcon />
      )

    } else {

      switch(mode) {

        case 'write': {
          const { specialBlockSectionAdditions, insertSection=[] } = customComponentTypes

          const toolbarSectionsWithAddition = [ ...toolbarSections ]
          const numSections = toolbarSectionsWithAddition.length

          if(specialBlockSectionAdditions) {
            toolbarSectionsWithAddition[numSections-1] = {
              ...toolbarSectionsWithAddition[numSections-1],
              buttons: [
                ...toolbarSectionsWithAddition[numSections-1].buttons,
                ...specialBlockSectionAdditions,
              ],
            }
          }

          const insertSectionButtonsToInclude = insertSection.filter(({ showOnBasicToolbarSide }) => showOnBasicToolbarSide)
          if(insertSectionButtonsToInclude.length > 0) {
            toolbarSectionsWithAddition.push({
              type: 'insertBlockBasicSide',
              style: { flexDirection: 'column' },
              buttons: insertSectionButtonsToInclude.map(button => ({
                ...button,
                isInsertType: true,
              })),
            })
          }

          toolbarContent = toolbarSectionsWithAddition.map(({ type: sectionType, activeTabMode, instructions, style, shrinkMode, buttons }, idx) => {
            let SectionInstructions = Type1SectionInstructions

            if(style && style.flexDirection === 'row') {
              SectionInstructions = Type2SectionInstructions
            }
    
            const isActive = type => (
              currentStyle.has(type)
              || type === currentBlockType
              || type === currentEntityType
            )

            const getToolbarIconActiveStyles = type => (!isActive(type) ? {} : {
              color: DEFAULT_ACTIVE_COLOR,
            })

            return (
              <ToolbarSection
                key={idx}
                style={style}
                $shrinkMode={shrinkMode}
                $toolbarSize={toolbarSize}
              >
                {/* <SectionInstructions
                  $active={tabMode === activeTabMode}
                >
                  <div>{instructions}</div>
                </SectionInstructions> */}
                <SectionButtonsContainer>
                  <SectionButtons>
                    {buttons.map(({ Icon, label, style, group, type, lang, isEntity, isInsertType }) => (
                      <Tooltip
                        key={type}
                        title={lang[locale] || lang.en || ``}
                        placement="top"
                        PopperProps={tooltipPopperProps}
                      >
                        <TooltipAnchor>
                          <StyledIconButton
                            $toolbarSize={toolbarSize}
                            onClick={isInsertType ? this.handleInsertClick : this.handleClick}
                            onMouseDown={this.handleMouseDown}
                            data-sectiontype={sectionType}
                            data-type={type}
                            data-isentity={isEntity ? 1 : ''}
                            disabled={blurred || disabledEntities.includes(type)}
                          >
                            {Icon
                              ? (
                                <Icon
                                  style={getToolbarIconActiveStyles(type)}
                                />
                              )
                              : (
                                <TextLabel
                                  style={{
                                    ...style,
                                    ...getToolbarIconActiveStyles(type),
                                  }}
                                  $toolbarSize={toolbarSize}
                                >
                                  {label}
                                </TextLabel>
                              )
                            }
                          </StyledIconButton>
                        </TooltipAnchor>
                      </Tooltip>
                    ))}
                  </SectionButtons>
                </SectionButtonsContainer>
              </ToolbarSection>
            )
          })

          break
        }

        case 'insert': {
          const insertSection = (customComponentTypes.insertSection || []).filter(({ showOnBasicToolbarSide }) => !showOnBasicToolbarSide)
          let lastGroup = (insertSection[0] || {}).group

          toolbarContent = (
            <ToolbarSection
              style={{ flexDirection: 'column' }}
            >
              {/* <Type1SectionInstructions
                $active={false}
              >
                <div>{i18n("ENTER to select")}</div>
              </Type1SectionInstructions> */}
              <SectionButtonsContainer>
                <SectionButtons>
                  {insertSection.map(({ type, Icon, label, style, group, lang={} }) => {

                    const divider = group !== lastGroup ? <ToolbarDivider /> : null
                    lastGroup = group

                    return (
                      <React.Fragment key={type}>
                        {divider}
                        <Tooltip
                          title={lang[locale] || lang.en}
                          placement="top"
                          PopperProps={tooltipPopperProps}
                        >
                          <TooltipAnchor>
                            <StyledIconButton
                              key={type}
                              onClick={this.handleInsertClick}
                              onMouseDown={this.handleMouseDown}
                              $toolbarSize={toolbarSize}
                              data-type={type}
                              disabled={blurred || disabledEntities.includes(type)}
                            >
                              {Icon
                                ? (
                                  <Icon />
                                )
                                : (
                                  <TextLabel
                                    style={style}
                                    $toolbarSize={toolbarSize}
                                  >
                                    {label}
                                  </TextLabel>
                                )
                              }
                            </StyledIconButton>
                          </TooltipAnchor>
                        </Tooltip>
                      </React.Fragment>
                    )
                  })}
                </SectionButtons>
              </SectionButtonsContainer>
            </ToolbarSection>
          )
        }

        break
      }
    }

    const hasInsertSection = (customComponentTypes.insertSection || []).filter(({ showOnBasicToolbarSide }) => !showOnBasicToolbarSide).length > 0

    return (
      <ToolbarAndSettingsEditorContainer
        style={style}
      >
        {!hasInsertSection &&
          <ToolbarContainer className="noFlipButton" $toolbarSize={toolbarSize}>
            {toolbarContent}
          </ToolbarContainer>
        }
        {hasInsertSection &&
          <>
            <ToolbarContainer $toolbarSize={toolbarSize}>
              {toolbarContent}
              <Spacer $toolbarSize={toolbarSize} />
              {settingsOpen &&
                <TrashIconButton
                  onMouseDown={this.handleMouseDown}
                  onClick={deleteSettingsBlock}
                  $toolbarSize={toolbarSize}
                >
                  <DeleteIcon />
                </TrashIconButton>
              }
            </ToolbarContainer>
            <FlipButton
              onMouseDown={this.handleMouseDown}
              onClick={settingsOpen ? closeSettings : this.flipMode}
              $toolbarSize={toolbarSize}
            >
              {flipButtonContent}
            </FlipButton>
            <SettingsEditor
              open={settingsOpen}
              onClose={closeSettings}
              onChange={updateComponentSettings}
              dataStructure={settingsComponentDataStructure}
              data={settingsComponentData}
              indicateSettingsHeight={indicateSettingsHeight}
              openAssetListDialog={openAssetListDialog}
              openUploadAssetDialog={openUploadAssetDialog}
            />
          </>
        }
      </ToolbarAndSettingsEditorContainer>
    )
  }

}

export default Toolbar