import { memo, useCallback, useRef, useMemo, useContext } from 'react'
import FlipEditor from 'flip-editor'
import styled from 'styled-components'
import { useApolloClient } from '@apollo/client'
import { useMeasure } from 'react-use'
import { i18n } from 'inline-i18n'

import { IS_EMBED } from '../../../utils/constants'
import useDataQuery from '../../../hooks/useDataQuery'
import useChangeIndex from '../../../hooks/useChangeIndex'
import useGoUpdateModulePiece from '../../../hooks/useGoUpdateModulePiece'
import useRefState from '../../../hooks/useRefState'
import useFlipEditorContent from '../../../hooks/useFlipEditorContent'
import useFlipEditorGetDecorators from '../../../hooks/useFlipEditorGetDecorators'
import useAppSize from '../../../hooks/useAppSize'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import theme from '../../../utils/theme'
import customComponentTypes from './customComponentTypes'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { getEmbedMode } from '../../../graphql/links/embedLink'
import { isAndroid } from '../../../utils/misc'

import Loading from '../../common/Loading'

import modulePiecesQuery from '../../../graphql/queries/modulePieces'

const Container = styled.div`
  flex: 1;
  min-height: 0;
  position: relative;
  z-index: 1;

  ${({ $showBorderTopAndMakeScrollable, theme }) => !$showBorderTopAndMakeScrollable ? `` : `
    border-top: 1px solid ${theme.palette.divider};
    overflow-y: auto;
  `}

  blockquote,
  .public-DraftStyleDefault-block,
  .public-DraftStyleDefault-unorderedListItem,
  .public-DraftStyleDefault-orderedListItem {
    position: static;
  }

  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    left: auto;
    transform: translateX(-36px);
  }

  blockquote:before {
    top: auto;
    left: 50%;
    transform: translate(-50%, calc(-1.5rem - 50% + 3px));
  }

  .flipeditor-editor-container {
    display: block;
  }

  .DraftEditor-root {
    padding-bottom: ${({ $embedOverflowHeight }) => $embedOverflowHeight}px;
  }

  .DraftEditor-root {
    overflow: auto;
    min-height: 100%;
  }

  .flipeditor {
    font-size: 15px;
  }

  .flipeditor h1 {
    text-align: left;
    font-size: 26px;
    font-weight: 600;
    border-bottom: 1px solid #eae5cb;
    margin: .85em 0 .6em;
  }

  .flipeditor h2 {
    font-weight: 400;
    font-size: 22px;
    margin: .85em 0 .6em;
  }

  .flipeditor h3 {
    font-weight: bold;
    font-size: 19px;
    margin: .85em 0 .6em;
  }

  .flipeditor h4 {
    font-weight: bold;
    font-size: 19px;
    margin: .85em 0 .6em;
  }

  .flipeditor h4>.public-DraftStyleDefault-block {
    text-align: center;
    margin: .6em 0;
  }

  .flipeditor h5 {
    font-weight: 500;
    font-size: 17px;
    margin: .65em 0 .4em;
  }

  .flipeditor h5>.public-DraftStyleDefault-block {
    text-align: center;
    margin: .6em 0;
  }

  .flipeditor h6 {
    font-weight: 300;
    font-size: 12px;
    margin: 0;
  }

  .flipeditor h6>.public-DraftStyleDefault-block {
    text-align: center;
  }

  .flipeditor aside {
    font-weight: 300;
    color: inherit;
  }

  @page {
    margin: 30px;
  }

  @media print {
    .flipeditor-toolbar-container {
      display: none;
    }
    .flipeditor {
      display: block;
    }
    .public-DraftEditor-content {
      padding: 0;
    }
  }

`

const AndroidNonEditable = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 10px 15px;
  font-weight: 300;
`

const NotesEditor = ({
  projectId,
  module,
  goPrintOrDownload,
  onLoad,
  disableLegacyModules,
  isShared,
  embedType,
  embedSetHeight,
  embedOverflowHeight,
  ...otherProps
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  const editableAccountStatus = hasToolsPlan || (IS_EMBED && getEmbedMode() !== `frozen`)
  const mode = (!isShared && editableAccountStatus && !isAndroid && embedType !== `passages`) ? `edit` : `display`
  const modeForPassageInNotes = (!isShared && editableAccountStatus) ? `edit` : `display`

  const { id: moduleId } = module

  const [ popoverComponentsById, setPopoverComponentsById, getPopoverComponentsById ] = useRefState({})

  const client = useApolloClient()
  const attemptedCreate = useRef(false)
  const latestUpdateContentValue = useRef()
  const [ ref, { bottom, width, height } ] = useMeasure()
  const { height: windowHeight } = useAppSize()
  const top = windowHeight - bottom
  const topOffset = top + 38  // 38 is the Notes toolbar height
  const getTopOffset = useInstanceValue(topOffset)
  const getWidth = useInstanceValue(width)

  const { modulePieces: [ modulePiece ]=[], loading: loadingModulePieces } = useDataQuery({
    modulePiecesQuery,
    variables: {
      moduleId,
    },
  })

  const content = useFlipEditorContent({ modulePiece })
  const flipEditorGetDecorators = useFlipEditorGetDecorators({ getTopOffset, getWidth })

  const [ goUpdateModulePiece ] = useGoUpdateModulePiece({ modulePiece, projectId, moduleId })

  const numThingsLeftToLoad = useRef(0)
  useLayoutEffectAsync(
    () => {
      if(!loadingModulePieces) {
        try {
          numThingsLeftToLoad.current = (
            Object.values(JSON.parse(content).entityMap)
              .filter(({ type }) => [ `studyModule`, `passage` ].includes(type))
              .length
          )
        } catch(err) {}
        if(numThingsLeftToLoad.current === 0) {
          onLoad && requestAnimationFrame(onLoad)
        }
    }
    },
    [ loadingModulePieces ],
  )

  useLayoutEffectAsync(() => (embedSetHeight && embedSetHeight(height)), [ height ])

  const setPopoverComponent = useCallback(
    ({ blockKey, popoverComponent }) => {
      setPopoverComponentsById({
        ...getPopoverComponentsById(),
        [blockKey]: popoverComponent,
      })
    },
    [ setPopoverComponentsById, getPopoverComponentsById ],
  )

  const onLoadForAtomic = useCallback(
    () => {
      numThingsLeftToLoad.current--
      if(numThingsLeftToLoad.current === 0) {
        onLoad && onLoad()
      }
    },
    [ onLoad ],
  )

  const customComponentDataByComponentType = useMemo(
    () => {

      return {
        studyModule: {
          goPrintOrDownload,
          onLoad: onLoadForAtomic,
          setPopoverComponent,
          projectId,
          disableLegacyModules,
        },
        passage: {
          onLoad: onLoadForAtomic,
          setPopoverComponent,
          topOffset,
          effectiveMode: modeForPassageInNotes,
        },
      }

    },
    [ goPrintOrDownload, onLoadForAtomic, setPopoverComponent, projectId, topOffset, disableLegacyModules, modeForPassageInNotes ],
  )

  useEffectAsync(
    () => {
      if(!loadingModulePieces && !modulePiece && !attemptedCreate.current) {

        attemptedCreate.current = true  // prevent double create

        const newData = goUpdateModulePiece({
          content: ``,
          ordering: 1,
        })

        client.writeQuery({
          query: modulePiecesQuery,
          data: {
            modulePieces: [
              newData,
            ],
          },
          variables: {
            moduleId,
          },
        })

      }
    },
    [ client, loadingModulePieces, modulePiece, moduleId, goUpdateModulePiece ],
  )

  // update the key (to force rerender of editor) whenever there is a change to content not caused by updateContent
  const key = useChangeIndex(
    content,
    (prev, current) => (
      prev !== current
      && current !== latestUpdateContentValue.current
    )
  )

  const updateContent = useCallback(
    content => {
      latestUpdateContentValue.current = content
      goUpdateModulePiece({ content })
    },
    [ goUpdateModulePiece ],
  )

  if(loadingModulePieces || !modulePiece) {
    return (
      <Container $showBorderTopAndMakeScrollable>
        <Loading />
      </Container>
    )
  }

  return (
    <Container
      ref={ref}
      $showBorderTopAndMakeScrollable={mode === `display` && !isShared && !embedSetHeight}
      $embedOverflowHeight={embedOverflowHeight || 0}
    >

      {isAndroid && editableAccountStatus && !isShared &&
        <AndroidNonEditable>
          {i18n("Editing a Notes tab is not available on Android.")}
        </AndroidNonEditable>
      }

      <FlipEditor
        key={key}
        mode={mode}
        showDisabledToolbar={mode === `display` && embedType === `richtextbox`}
        initialContent={content}
        updateContent={updateContent}
        throttleUpdateContent
        fillHeight={!embedSetHeight}
        toolbarSize="small"
        hideAllSettingsIcons
        focusOnLoad={attemptedCreate.current}
        // muiJss={muiJss}
        muiTheme={theme}
        customComponentTypes={customComponentTypes}
        customComponentDataByComponentType={customComponentDataByComponentType}
        getDecorators={flipEditorGetDecorators}
        {...otherProps}
      />

      {Object.values(popoverComponentsById)}

    </Container>
  )
}

export default memo(NotesEditor)