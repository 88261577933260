import { i18n } from 'inline-i18n'

const eras = [
  {
    id: `bible`,
    getEraLabel: () => i18n("All Bible History"),
    timelineStartDate: `4004 BC`,
    timelineEndDate: `AD 100`,
  },
  {
    id: `patriarchs`,
    getEraLabel: () => i18n("Patriarchs"),
    timelineStartDate: `2166 BC`,
    timelineEndDate: `1876 BC`,
    color: `#905303`,
  },
  {
    id: `exodus`,
    getEraLabel: () => i18n("Exodus and Conquest"),
    timelineStartDate: `1446 BC`,
    timelineEndDate: `1389 BC`,
    color: `#00646E`,
  },
  {
    id: `judges`,
    getEraLabel: () => i18n("Judges"),
    timelineStartDate: `1382 BC`,
    timelineEndDate: `1050 BC`,
    color: `#911F00`,
  },
  {
    id: `kings`,
    getEraLabel: () => i18n("Kings"),
    timelineStartDate: `1050 BC`,
    timelineEndDate: `586 BC`,
    color: `#0F481C`,
  },
  {
    id: `exile`,
    getEraLabel: () => i18n("Exile and Return"),
    timelineStartDate: `597 BC`,
    timelineEndDate: `350 BC`,
    color: `#989113`,
  },
  {
    id: `jesus`,
    getEraLabel: () => i18n("Life of Jesus"),
    timelineStartDate: `6 BC`,
    timelineEndDate: `AD 30`,
    color: `#78006F`,
  },
  {
    id: `apostles`,
    getEraLabel: () => i18n("Apostles"),
    timelineStartDate: `AD 30`,
    timelineEndDate: `AD 100`,
    color: `#00646E`,
  },
]

export default eras