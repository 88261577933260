import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import { useMutation } from '@apollo/client'

import { STUDY_BIBLE_SOURCE_THUMBNAILS_MAX_SIZE } from '../../utils/constants'
import getOgInfo from '../../utils/getOgInfo'
import useInstanceValue from '../../hooks/useInstanceValue'
import useOnChangeImage from '../../hooks/useOnChangeImage'

import editStudyBibleImageMutation from '../../graphql/mutations/editStudyBibleImage'

const StudyBibleItemEditorSourceSection = ({
  StyledTextField,
  commonTextFieldProps,
  info,
  onChangeInfo,
  updatePreview,
}) => {

  const { sourceUrl, sourceText, sourceThumbnailImgUrl } = info || {}

  const [ editStudyBibleImage ] = useMutation(editStudyBibleImageMutation)

  const onChangeSourceUrl = useCallback(onChangeInfo(`sourceUrl`), [ onChangeInfo ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeSourceText = useCallback(onChangeInfo(`sourceText`), [ onChangeInfo ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeImage = useOnChangeImage({ onChangeInfo, editStudyBibleImage })
  const onChangeSourceThumbnailImgUrl = useCallback(onChangeImage(`sourceThumbnailImg`, STUDY_BIBLE_SOURCE_THUMBNAILS_MAX_SIZE), [ onChangeImage ])  // eslint-disable-line react-hooks/exhaustive-deps

  const getInfo = useInstanceValue(info)

  const onBlurSourceUrl = useCallback(
    async () => {
      const { sourceUrl=``, sourceText=``, sourceThumbnailImgUrl=`` } = getInfo() || {}
      if(!sourceText.trim() || !sourceThumbnailImgUrl.trim()) {
        const { ogTitle, ogImage } = await getOgInfo(sourceUrl)
        if(!sourceText.trim() && ogTitle) {
          onChangeSourceText({ target: { value: ogTitle } })
        }
        if(!sourceThumbnailImgUrl.trim() && (ogImage || [])[0]) {
          onChangeSourceThumbnailImgUrl({ target: { value: ogImage[0].url || `` } })
        }
      }
      updatePreview()
    },
    [ getInfo, onChangeSourceText, onChangeSourceThumbnailImgUrl, updatePreview ],
  )

  const getSourceThumbnailAutoEditInfo = useCallback(
    ({ width, height }) => {
      const scale = Math.min(STUDY_BIBLE_SOURCE_THUMBNAILS_MAX_SIZE / Math.max(width, height), 1)
      return {
        resize: [
          width * scale,
          height * scale,
        ],
      }
    },
    [],
  )

  return (
    <>

      <StyledTextField
        {...commonTextFieldProps}
        label={i18n("Source URL")}
        placeholder={i18n("e.g. https://a.co/d/0Vu0Y08")}
        value={sourceUrl}
        onChange={onChangeSourceUrl}
        onBlur={onBlurSourceUrl}
      />

      <StyledTextField
        {...commonTextFieldProps}
        label={i18n("Source Text")}
        placeholder={i18n("e.g. Timothy Z. Witmer, The Shepherd Leader: Achieving Effective Shepherding in Your Church, 161.")}
        value={sourceText}
        onChange={onChangeSourceText}
        onBlur={updatePreview}
      />

      <StyledTextField
        {...commonTextFieldProps}
        label={i18n("Source Thumbnail Image")}
        value={sourceThumbnailImgUrl}
        onChange={onChangeSourceThumbnailImgUrl}
        onBlur={onBlurSourceUrl}
        isImageUrl
        includeUploadButton
        getAutoEditInfo={getSourceThumbnailAutoEditInfo}
        minWidth={STUDY_BIBLE_SOURCE_THUMBNAILS_MAX_SIZE/2}
        minHeight={STUDY_BIBLE_SOURCE_THUMBNAILS_MAX_SIZE/2}
        minWidthOrHeight={STUDY_BIBLE_SOURCE_THUMBNAILS_MAX_SIZE}
      />

    </>
  )
}

export default memo(StudyBibleItemEditorSourceSection)