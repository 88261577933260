import { useCallback } from "react"
import { useApolloClient } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'

import { cloneObj } from '../utils/misc'
import useMutationContext from './useMutationContext'
import useInstanceValue from "./useInstanceValue"
import useGoUpdateModulePieces from "./useGoUpdateModulePieces"

import modulePiecesQuery from '../graphql/queries/modulePieces'

const useDuplicateModulePieces = ({
  moduleToCopyFrom,
  newModuleId,
  projectId,
}) => {

  const context = useMutationContext()
  const getContext = useInstanceValue(context)
  const client = useApolloClient()

  const [ goUpdateModulePieces ] = useGoUpdateModulePieces({
    projectId,
    moduleId: newModuleId,
  })

  const goDuplicateModulePieces = useCallback(
    async () => {

      // get all pieces
      const { data: { modulePieces } } = await client.query({
        query: modulePiecesQuery,
        variables: {
          moduleId: moduleToCopyFrom.id,
        },
        context: getContext(),
      })

      const modulePieceIdMap = {}

      const newModulePieces = modulePieces.map(row => {
        const newModulePiece = cloneObj(row)
        modulePieceIdMap[row.id] = newModulePiece.id = uuidv4()
        return newModulePiece
      })

      goUpdateModulePieces(newModulePieces)

      return { modulePieceIdMap }

    },
    [ moduleToCopyFrom, client, getContext, goUpdateModulePieces ],
  )

  return goDuplicateModulePieces
}

export default useDuplicateModulePieces