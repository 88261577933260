import { memo } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'
import { getPassageStr } from "@bibletags/bibletags-ui-helper"
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import { KJV_VERSION } from '../../utils/constants'

import RelativeTime from '../common/RelativeTime'

const MAX_ROWS = 10

const Container = styled.div`
`

const Line = styled.div`
  padding: 5px 0;
`

const Updates = styled.div`
  font-weight: 400;
  font-size: 12px;
`

const WhoAndWhen = styled.div`
`

const Who = styled.div`
  display: inline-block;
  font-weight: 300;
`

const When = styled.div`
  display: inline-block;
  margin-left: 10px;
`

const getPsgStr = locs => getPassageStr({ refs: locs.map(getRefFromLoc), versionId: KJV_VERSION })

const StudyBibleItemHistoryTooltip = ({
  editHistory,
  ...otherProps
}) => {

  const { time, userId, updates:{ fromLoc, toLoc }={} } = (editHistory || [])[0] || {}

  if(!fromLoc) return otherProps.children

  return (
    <Tooltip
      interactive
      {...otherProps}
      title={
        <Container>

          <Line>
            <Updates>
              Created for {getPsgStr([ fromLoc, toLoc ])}
            </Updates>
            <WhoAndWhen>
              <Who>
                By User ID #{userId}
              </Who>
              <When>
                <RelativeTime date={time} />
              </When>
            </WhoAndWhen>
          </Line>

          {editHistory.length > MAX_ROWS &&
            <Line>
              ...
            </Line>
          }

          {editHistory.slice(1).slice(-(MAX_ROWS - 1)).map(({ time, userId, updates={} }, idx) => {

            let updatePieces = []
            const fieldsUpdates = []

            Object.keys(updates).forEach(key => {
              switch(key) {
                case `heading`:
                case `title`:
                case `type`:
                case `status`: {
                  updatePieces.push(`${key} changed to ${updates[key]}`)
                  break
                }
                case `fromLoc`: {
                  updatePieces.push(`passage start changed to ${getPsgStr([ updates.fromLoc ])}`)
                  break
                }
                case `toLoc`: {
                  updatePieces.push(`passage end changed to ${getPsgStr([ updates.toLoc ])}`)
                  break
                }
                case `adminRating`: {
                  updatePieces.push(`rating changed to ${(updates[key] / 10 + 4).toFixed(1)}`)
                  break
                }
                default: {
                  fieldsUpdates.push(key)
                }
              }
            })

            if(fieldsUpdates.length > 0) {
              updatePieces.push(`other fields updated: ${fieldsUpdates.join(`, `)}`)
            }

            return (
              <Line key={idx}>
                <Updates>
                  {updatePieces.join(` + `)}
                </Updates>
                <WhoAndWhen>
                  <Who>
                    By User ID #{userId}
                  </Who>
                  <When>
                    <RelativeTime date={time} />
                  </When>
                </WhoAndWhen>
              </Line>
            )
        })}
        </Container>
      }
    />
  )
}

export default memo(StudyBibleItemHistoryTooltip)