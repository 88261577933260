import { memo } from 'react'
import styled from 'styled-components'
import Slider from '@material-ui/core/Slider'

const SliderHeading = styled.div`
`

const SliderContainer = styled.div`
  padding: 0 2px;
  margin: -6px 0 -4px 0;

  @media (pointer: coarse) {
    .MuiSlider-root {
      padding: 13px 0;
    }
  }
`

const CustomSlider = ({
  label,
  className,
  ...otherProps
}) => (

  <div className={className}>

    <SliderHeading
      className="CustomSlider-SliderHeading"
    >
      {label}
    </SliderHeading>

    <SliderContainer>
      <Slider
        {...otherProps}
      />
    </SliderContainer>

  </div>
)

export default memo(CustomSlider)