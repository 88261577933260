import { useCallback } from 'react'

import useShortcuts from './useShortcuts'
import keyDownSpace from '../components/modules/legacy-diagramming/shortcuts/keyDownSpace'
import keyDownP from '../components/modules/legacy-diagramming/shortcuts/keyDownP'

const keyDownFuncs = {
  keyDownSpace,
  keyDownP,
}

const useDiagrammingShortcuts = params => {

  const {
    getModule,
    cancelClickOrMoveRef,
    noneditableViewingMode,
  } = params

  const inCorrectContext = useCallback(
    event => {

      if(noneditableViewingMode === `within-notes`) return false

      // check that we are focused on this module
      if(![ `shared-page`, `sketch`].includes(noneditableViewingMode)) {
        if(!document.activeElement) return false
        if(document.activeElement.getAttribute('data-module-id') !== getModule().id) return false
      }

      if(cancelClickOrMoveRef.current) {
        event.preventDefault()
        if(event.key === 'Escape') {
          cancelClickOrMoveRef.current()
        }
        return false
      }

      return true

    },
    [ getModule, cancelClickOrMoveRef, noneditableViewingMode ],
  )

  useShortcuts({
    keyDownFuncs,
    inCorrectContext,
    ...params,
  })

}

export default useDiagrammingShortcuts