import { useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'

import moduleQuery from '../graphql/queries/module'
import projectQuery from '../graphql/queries/project'
import updateModuleByProjectMutation from '../graphql/mutations/updateModuleByProject'
import deleteModuleByProjectMutation from '../graphql/mutations/deleteModuleByProject'

export const getDefaultModuleByProject = () => ({
  __typename: `ModuleByProject`,
})

const useGoUpdateModuleByProject = ({
  moduleByProject,
  projectId,
}) => {

  projectId = projectId || (moduleByProject && moduleByProject.id.split(':')[1])

  const client = useApolloClient()

  const [ updateModuleByProject, updateModuleByProjectResult ] = useMutation(updateModuleByProjectMutation)
  const [ deleteModuleByProject, deleteModuleByProjectResult ] = useMutation(deleteModuleByProjectMutation)

  const goUpdate = useGoUpdateTable({
    currentData: moduleByProject || getDefaultModuleByProject(),
    updateFunc: updateModuleByProject,
    updateResult: updateModuleByProjectResult,
    deleteFunc: deleteModuleByProject,
    deleteResult: deleteModuleByProjectResult,
    projectId,
  })

  const toReturn = useMemo(
    () => {
      if(!moduleByProject) {
        return [
          ({ ordering, moduleId }) => {
            // Create a new moduleByProject

            const id = `${moduleId}:${projectId}`

            const newData = goUpdate[0]({
              id,
              ordering,
            })

            const { module } = client.readQuery({
              query: moduleQuery,
              variables: {
                id: moduleId,
              },
            })

            const { project } = client.readQuery({
              query: projectQuery,
              variables: {
                id: projectId,
              },
            })

            let insertIndex = project.moduleByProjects.findIndex(moduleByProject => moduleByProject.ordering > ordering)
            if(insertIndex === -1) {
              insertIndex = project.moduleByProjects.length
            }

            const data = {
              project: {
                ...project,
                moduleByProjects: [
                  ...project.moduleByProjects.slice(0, insertIndex),
                  {
                    ...newData,
                    module: {
                      modulePassages: [],
                      moduleByProjects: [],
                      ...module,
                    },
                  },
                  ...project.moduleByProjects.slice(insertIndex),
                ],
              },
            }

            client.writeQuery({
              query: projectQuery,
              data,
              variables: {
                id: newData.projectId,
              },
            })

            return newData
          },
        ]
      }

      return goUpdate
    },
    [ moduleByProject, projectId, goUpdate, client ],
  )

  return toReturn
}

export default useGoUpdateModuleByProject