import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import TextField from '@material-ui/core/TextField'
import { ReactSortable } from "react-sortablejs"
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import CloseIcon from '@material-ui/icons/Delete'

import useInstanceValue from '../../../hooks/useInstanceValue'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import { cloneObj, getShortUuid } from '../../../utils/misc'

const Container = styled.div`
  margin: -25px 0 0;
  .sortable-ghost {
    opacity: 0;
  }
`

const SummaryContainer = styled.div`
  margin-top: 5px;
  border-right: 3px solid ${({ theme }) => theme.palette.divider};
  padding-right: 10px;
`

const Name = styled.div`
  margin: 30px 0;
  border-right: 3px solid ${({ theme }) => theme.palette.secondary.main};
  padding-right: 10px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`

const SummaryContent = styled(Row)`
  margin: 0;
`

const Summary = styled.div`
  font-size: 11px;
  padding: 0 7px;
  line-height: 1.2;
`

const SummaryNamePart = styled.span`
  display: inline-block;
  margin-right: 4px;
`

const SummaryName = styled.span`
  font-weight: bold;
`

const SummaryLine = styled.div`
  margin-top: 5px;
`

const SummaryNotes = styled.div`
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;

  .MuiSvgIcon-root {
    font-size: 17px;
  }
`

const StyledButton = styled(Button)`
  margin: -10px 0 10px;
`

const StyledTextField = styled(TextField)`
`

const BibleMapEditorNames = ({
  names,
  onChange,
  disabled,
  mapLayerIds,
}) => {

  const getNames = useInstanceValue(names)
  const [ showSummary, toggleShowSummary ] = useSimpleToggle(true)

  const setList = useCallback(
    list => {
      onChange({
        keys: [ `names` ],
        newValue: list.map(({ chosen, selected, filtered, ...info }) => info),
      })
    },
    [ onChange ],
  )

  const addName = useCallback(
    () => {
      onChange({
        keys: [ `names` ],
        newValue: [
          ...getNames(),
          {
            id: getShortUuid(mapLayerIds),
            name: ``,
          },
        ],
      })
    },
    [ onChange, getNames, mapLayerIds ],
  )

  if(showSummary) {
    return (
      <SummaryContainer>

        <SummaryContent>

          <StyledTextField
            variant="outlined"
            size="small"
            value={names[0].name || ``}
            data-keys={`names 0 name`}
            onChange={onChange}
            fullWidth
            disabled={disabled}
            label={`${i18n("Primary Name")}*`}
            placeholder="e.g. Salt\Sea"
            error={!names[0].name}
          />

          <StyledIconButton
            onClick={toggleShowSummary}
          >
            <ExpandMoreIcon />
          </StyledIconButton>

        </SummaryContent>

        <Summary>

          {names.map(({ id, name, meaning, language, notes }, idx) => (
            (meaning || notes || idx > 0)
              ? (
                <SummaryLine key={id}>
                  {idx > 0 &&
                    <SummaryNamePart>
                      {i18nReact("Also: {{extra}}", {
                        extra: (
                          <SummaryName>
                            {name || `?`}
                          </SummaryName>
                        ),
                      })}
                    </SummaryNamePart>
                  }
                  {!!meaning &&
                    <>
                      {i18n("“{{meaning}}” in {{language}}", {
                        meaning: meaning,
                        language: (
                          {
                            heb: i18n("Hebrew"),
                            arc: i18n("Aramaic"),
                            grc: i18n("Greek"),
                          }[language] || `?`
                        ),
                      })}
                      {` `}
                    </>
                  }
                  {!!notes &&
                    <SummaryNotes>
                      {notes}
                    </SummaryNotes>
                  }
                </SummaryLine>
              )
              : null
          ))}

        </Summary>

      </SummaryContainer>
    )
  }

  return (
    <Container>

      <ReactSortable
        list={cloneObj(names)}
        setList={setList}
        delayOnTouchStart
      >
        {names.map(({ id, name, meaning, language, notes }, idx) => (
          <Name key={id}>

            <Row>

              <StyledTextField
                variant="outlined"
                size="small"
                value={name || ``}
                data-keys={`names ${idx} name`}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                label={
                  idx === 0
                    ? `${i18n("Primary Name")}*`
                    : `${i18n("Alternative Name {{num}}", { num: idx })}*`
                }
                error={!name}
              />

              {idx === 0 &&
                <StyledIconButton
                  onClick={toggleShowSummary}
                >
                  <ExpandLessIcon />
                </StyledIconButton>
              }

              {idx !== 0 &&
                <StyledIconButton
                  onClick={() => {
                    const newValue = [ ...getNames() ]
                    newValue.splice(idx, 1)
                    onChange({
                      keys: [ `names` ],
                      newValue,
                    })
                  }}
                  disabled={disabled}
                >
                  <CloseIcon />
                </StyledIconButton>
              }

            </Row>

            <Row>

              <StyledTextField
                variant="outlined"
                size="small"
                value={meaning || ``}
                data-keys={`names ${idx} meaning`}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                label={i18n("Meaning")}
              />

              <StyledTextField
                variant="outlined"
                size="small"
                value={`names ${idx} language ${language || ``}`}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                select
                label={i18n("Language")}
              >
                <MenuItem
                  value={`names ${idx} language heb`}
                >
                  {i18n("Hebrew")}
                </MenuItem>
                <MenuItem
                  value={`names ${idx} language arc`}
                >
                  {i18n("Aramaic")}
                </MenuItem>
                <MenuItem
                  value={`names ${idx} language grc`}
                >
                  {i18n("Greek")}
                </MenuItem>
              </StyledTextField>

            </Row>

            <Row>

              <StyledTextField
                variant="outlined"
                size="small"
                value={notes || ``}
                data-keys={`names ${idx} notes`}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                label={i18n("Notes")}
                multiline
              />

            </Row>

          </Name>
        ))}
      </ReactSortable>

      <StyledButton
        variant="contained"
        disableElevation
        size="small"
        onClick={addName}
        fullWidth
        disabled={disabled}
      >
        {i18n("Add alternative name")}
      </StyledButton>

    </Container>
  )
}

export default memo(BibleMapEditorNames)