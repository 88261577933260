const keyDownZ = async params => {

  const {
    event,
    modifierKey,
  } = params

  if(!modifierKey) return
  if([ `input`, `textarea` ].includes(((document.activeElement || {}).tagName || ``).toLowerCase())) return

  event.preventDefault()

  // this simply prevents a browser action on a Cntl-Z that is not effected on a module or the like

}

export default keyDownZ