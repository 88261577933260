import { memo } from 'react'
import styled from 'styled-components'

// import useIsLoggedIn from '../../../hooks/useIsLoggedIn'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Container = styled.div`
  margin: 20px auto 50px;
  display: flex;
  justify-content: center;
`

const InnerContainer = styled.div`
  position: relative;
  display: flex;
`

const ScheduleImg = styled.img`
  width: 540px;
  max-width: 100%;
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  transition: transform .2s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }
`

// const HoverArea = styled.a`
//   position: absolute;
//   background: transparent;
//   left: 0;
//   right: 0;

//   &:hover {
//     background: rgb(255 255 255/.1);
//   }
// `

const Schedule = ({ ...props }) => {

  // const isLoggedIn = useIsLoggedIn()

  // const autologin = isLoggedIn ? `?autologin` : ``

  return (
    <Container>
      <InnerContainer>

        <StyledNavLinkOrAWithDisable
          to="https://equip.biblearc.com/blog-post/2025-live-courses?autologin"
        >
          <ScheduleImg
            src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1728937352272-EQUIP2025Courses4xtiny.jpeg"
            className="dark-mode-exempt"
          />
        </StyledNavLinkOrAWithDisable>

        {/* <HoverArea href={`https://equip.biblearc.com/course/consolation-of-philosophy${autologin}`} style={{
          top: '24%',
          height: '12%',
        }} />
        <HoverArea href={`https://equip.biblearc.com/course/arcing-live${autologin}`} style={{
          top: '36%',
          height: '12%',
        }} />
        <HoverArea href={`https://equip.biblearc.com/course/intermediate-phrasing${autologin}`} style={{
          top: '48%',
          height: '11.7%',
        }} />
        <HoverArea href={`https://equip.biblearc.com/course/greek-ii${autologin}`} style={{
          top: '59.7%',
          height: '12.3%',
        }} />
        <HoverArea href={`https://equip.biblearc.com/course/greek-iii${autologin}`} style={{
          top: '72%',
          height: '12%',
        }} />
        <HoverArea href={`https://equip.biblearc.com/course/hebrew-cross-training-3${autologin}`} style={{
          top: '84%',
          height: '12%',
        }} /> */}

      </InnerContainer>
    </Container>
  )
}


export default memo(Schedule)