import { memo } from 'react'
import styled from 'styled-components'

import {
  MAX_NOTES_DRAWER_WIDTH,
  DRAWER_MINIMIZED_HEIGHT,
} from '../../../utils/constants'
import useDrawerHeight from '../../../hooks/useDrawerHeight'
import useGoUpdateProject from '../../../hooks/useGoUpdateProject'

import Notes from '../../modules/notes/Notes'
import NotesContainerWithFocusIndicator from '../../common/NotesContainerWithFocusIndicator'

const CropContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
`

const Container = styled(NotesContainerWithFocusIndicator)`
  pointer-events: all;
  background-color: white;
  position: absolute;
  z-index: 8;
  bottom: 0;
  left: ${({ $mobileSize }) => $mobileSize ? 8 : 15}px;
  max-width: ${MAX_NOTES_DRAWER_WIDTH}px;
  width: ${({ $modulesWidth, $mobileSize }) => $modulesWidth - ($mobileSize ? 16 : 15)}px;
  height: ${({ $notesHeight }) => $notesHeight}px;
  transition: transform .3s ease-in-out, background-color .3s ease-in-out;
  box-shadow: ${({ $mobileSize }) => $mobileSize ? `1px 8px 40px rgb(0 0 0/.3)` : `1px 15px 35px rgb(0 0 0/.3)`};
  display: flex;

  .notes-minimize-button {
    transition: transform .3s ease-in-out, box-shadow .3s ease-in-out;
  }

  ${({ $minimized, $notesHeight }) => !$minimized ? `` : `
    transform: translateY(${$notesHeight - DRAWER_MINIMIZED_HEIGHT}px);
    background-color: rgb(255 255 255/.85);
    backdrop-filter: blur(1px);

    transform: translateY(${$notesHeight}px);

    .notes-minimize-button {
      transform: translateY(${(DRAWER_MINIMIZED_HEIGHT + 10) * -1}px);
      box-shadow: 1px 8px 40px rgb(0 0 0 / 30%);
    }
`}

  & > div > div > .flipeditor {
    margin: 0 -1px;
  }

  & > div > div > .flipeditor > div > .DraftEditor-root {
    padding: 0 15px;
  }

  & > div > div > .flipeditor > div > .DraftEditor-root .options-popper-clear-cover {
    left: -15px;
    right: -15px;
  }

`

const ResizeableArea = styled.div`
  cursor: row-resize;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 0;
  right: 0;
  height: 10px;
  background-color: transparent;
`

const ProjectNotesInDrawer = ({
  project,
  modulesWidth,
  embedOverflowHeight,
  ...otherProps
}) => {

  const { notesHeightPercentage } = project
  const [ goUpdateProject ] = useGoUpdateProject({ project })

  const { drawerHeight, minimized, resizeEvents, mobileSize } = useDrawerHeight({
    heightPercentage: notesHeightPercentage,
    goUpdate: notesHeightPercentage => goUpdateProject({ notesHeightPercentage }),
    embedOverflowHeight,
  })

  return (
    <CropContainer>
      <Container
        $modulesWidth={modulesWidth}
        $mobileSize={mobileSize}
        $notesHeight={drawerHeight}
        $minimized={minimized}
      >

        <ResizeableArea {...resizeEvents} />

        <Notes
          project={project}
          inDrawer={true}
          minimizedInDrawer={minimized}
          resizeEvents={resizeEvents}
          {...otherProps}
        />

      </Container>
    </CropContainer>
  )
}

export default memo(ProjectNotesInDrawer)