const fields = `
  id
  fromLoc
  toLoc
  ordering
  info
  savedAt
  moduleId
`

export default fields