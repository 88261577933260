import { useState, useCallback } from "react"
import { i18n } from 'inline-i18n'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useNetworkState } from 'react-use'

const useError = ({
  title,
  message,
  allowClose,
}={}) => {

  const { online } = useNetworkState()
  const [ errorInfo, setErrorInfo ] = useState({})
  const [ open, setOpen ] = useState(false)

  const onClose = useCallback(() => setOpen(false), [ setOpen ])

  const setError = useCallback(
    error => {
      setErrorInfo({
        message: (error || {}).message || i18n("Unknown error"),
      })
      setOpen(true)
    },
    [ setErrorInfo ],
  )

  const errorDialog = (
    <Dialog
      open={open}
    >
      <DialogTitle>
        {title || i18n("Error")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message || errorInfo.message}
        </DialogContentText>
      </DialogContent>
      {!allowClose &&
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
          >
            {i18n("Close")}
          </Button>
        </DialogActions>
      }
    </Dialog>
  )

  return {
    online,
    errorDialog,
    setError,
  }
}

export default useError
