import { useEffect, useState } from 'react'

import useSetTimeout from './useSetTimeout'

export const getTextSelectionInfo = contextSelector => {
  let selection = document.getSelection()

  const textRange = !selection.isCollapsed ? selection.getRangeAt(0) : null

  let commonAncestorEl = textRange && textRange.commonAncestorContainer
  if(commonAncestorEl && commonAncestorEl.nodeType !== Node.ELEMENT_NODE) {
    commonAncestorEl = commonAncestorEl.parentElement
  }

  let startNode = textRange && textRange.startContainer
  let startNodeOffset = textRange && textRange.startOffset

  if(
    startNode
    && startNodeOffset === (startNode.length === undefined ? startNode.childNodes.length : startNode.length)
  ) {
    const walker = document.createTreeWalker(commonAncestorEl, NodeFilter.SHOW_ALL, node => [ Node.ELEMENT_NODE, Node.TEXT_NODE ].includes(node.nodeType))
    walker.currentNode = startNode
    startNode = walker.nextNode()
    startNodeOffset = 0
  }

  let endNode = textRange && textRange.endContainer
  let endNodeOffset = textRange && textRange.endOffset

  while(
    endNode
    && endNodeOffset === 0
  ) {
    const walker = document.createTreeWalker(commonAncestorEl, NodeFilter.SHOW_ALL, node => [ Node.ELEMENT_NODE, Node.TEXT_NODE ].includes(node.nodeType))
    walker.currentNode = endNode
    endNode = walker.previousNode()
    endNodeOffset = endNode.length === undefined ? endNode.childNodes.length : endNode.length
  }

  const contextEl = (
    !selection.isCollapsed
    && !!contextSelector
    && startNode
    && (startNode.nodeType === Node.ELEMENT_NODE ? startNode : startNode.parentElement).closest(contextSelector)
  )

  if(
    selection.isCollapsed
    || !selection.toString().trim()
    || (
      contextSelector
      && !contextEl
    )
  ) {
    return {}
  }

  return {
    selection,
    textRange,
    commonAncestorEl,
    startNode,
    startNodeOffset,
    endNode,
    endNodeOffset,
    knownToBeStillSelecting: !!(window.mouseIsDown || window.screenIsTouched),
    contextEl,
  }
}

const useTextSelectionInfo = contextSelector => {

  const [ textSelectionInfo, setTextSelectionInfo ] = useState({})
  const [ setMouseDownCheckTimeout, clearMouseDownCheckTimeout ] = useSetTimeout()

  useEffect(
    () => {

      const selectionChange = () => {

        setTextSelectionInfo(
          getTextSelectionInfo(contextSelector)
        )

        let maxChecksRemaining = 100
        const checkMouseDown = () => {
          if(!(window.mouseIsDown || window.screenIsTouched)) {
            clearMouseDownCheckTimeout()
            setTextSelectionInfo(
              getTextSelectionInfo(contextSelector)
            )
          } else if(maxChecksRemaining-- > 0) {
            setMouseDownCheckTimeout(checkMouseDown, 200)
          }
        }

        checkMouseDown()

      }

      document.addEventListener('selectionchange', selectionChange)
      return () => document.removeEventListener('selectionchange', selectionChange)
    },
    [ contextSelector ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  return textSelectionInfo
}

export default useTextSelectionInfo
