import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import FadedLoading from '../../common/FadedLoading'
import ConfirmDialog from '../../common/ConfirmDialog'
import useSimpleToggle from '../../../hooks/useSimpleToggle'

const Buttons = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Side = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const BibleMapEditorButtons = ({
  inEdit,
  closeSelected,
  discardEdits,
  goDoUpdateMapLayer,
  goDeleteMapLayer,
  requestingLock,
  lockObtained,
  updating,
  hasEdits,
  hideDelete,
  type,
  back,
}) => {

  const [ showConfirmDiscard, toggleShowConfirmDiscard ] = useSimpleToggle()
  const [ showConfirmDelete, toggleShowConfirmDelete ] = useSimpleToggle()

  const isNew = !(inEdit || {}).id

  const confirmDeleteEdits = useCallback(
    () => {
      toggleShowConfirmDelete()
      goDeleteMapLayer()
      if(type === `place`) {
        closeSelected()
      } else {
        back()
      }
  },
    [ goDeleteMapLayer, toggleShowConfirmDelete, closeSelected, type, back ],
  )

  const confirmDiscardEdits = useCallback(
    () => {
      discardEdits()
      toggleShowConfirmDiscard()
      if(isNew) {
        if(type === `place`) {
          closeSelected()
        } else {
          back()
        }
      }
    },
    [ discardEdits, toggleShowConfirmDiscard, isNew, closeSelected, type, back ],
  )

  if(!requestingLock && !lockObtained) {
    return (
      <Buttons>
        <Button
          color="secondary"
          onClick={closeSelected}
        >
          {i18n("Close")}
        </Button>
      </Buttons>
    )
  }

  if(!lockObtained) return <Buttons />

  return (
    <>

      <Buttons>

        <Side>

          {/* {!hasEdits && !updating &&
            <Button
              color="secondary"
              onClick={closeSelected}
            >
              {i18n("Close")}
            </Button>
          } */}

          {!hasEdits && !updating && !!goDeleteMapLayer && !hideDelete &&
            <Button
              color="secondary"
              onClick={toggleShowConfirmDelete}
              disabled={requestingLock || updating}
            >
              {i18n("Delete")}
            </Button>
          }

          {(hasEdits || isNew) && !updating &&
            <Button
              color="secondary"
              onClick={toggleShowConfirmDiscard}
              disabled={requestingLock || updating}
            >
              {i18n("Discard")}
            </Button>
          }

        </Side>

        <Side>

          {!!goDoUpdateMapLayer &&
            <Button
              color="secondary"
              variant="contained"
              disableElevation
              onClick={goDoUpdateMapLayer}
              disabled={requestingLock || updating || !hasEdits}
            >
              {i18n("Save")}
              {updating && <FadedLoading size={20} />}
            </Button>
          }

        </Side>


      </Buttons>

      <ConfirmDialog
        open={showConfirmDelete}
        onClose={toggleShowConfirmDelete}
        onConfirm={confirmDeleteEdits}
        explanation={i18n("Are you sure you want to delete this item?")}
        confirmButtonLabel={i18n("Delete")}
        doubleConfirm
      />

      <ConfirmDialog
        open={showConfirmDiscard}
        onClose={toggleShowConfirmDiscard}
        onConfirm={confirmDiscardEdits}
        explanation={i18n("Are you sure you want to discard your edits?")}
        confirmButtonLabel={i18n("Discard")}
      />

    </>
  )
}

export default memo(BibleMapEditorButtons)