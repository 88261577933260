import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Container = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  @media (max-width: 500px) {
    display: none;
  }
`

const Container2 = styled.div`
  background-color: ${({ theme }) => theme.palette.tertiary.dark};
  color: rgb(255 255 255/.6);
  padding: 3px 8px;
  font-size: 11px;
  text-align: center;
  border-radius: 3px;
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  color: white;
  display: inline-block;
  transition: color .15s ease-in-out;

  &:hover {
    color: rgb(255 255 255/.4);
  }
`

const ProjectNoTools = ({ ...props }) => {

  return (
    <Container>
      <Container2>

        {i18n("View-only.")}

        {` `}

        {i18nReact("{{get_tools}} to edit.", {
          get_tools: (
            <StyledNavLinkOrAWithDisable to="/subscribe">
              {i18n("Get TOOLS")}
            </StyledNavLinkOrAWithDisable>
          ),
        })}

      </Container2>
    </Container>
  )
}

export default memo(ProjectNoTools)