import { useState } from "react"

import useSetTimeout from "./useSetTimeout"
import useEffectAsync from "./useEffectAsync"

const useValueWithDelayedUpdate = (value, delay=0, defaultValue) => {

  const [ updatedValue, setUpdatedValue ] = useState(defaultValue)
  const [ setUpdateTimeout ] = useSetTimeout()

  useEffectAsync(
    () => {
      setUpdateTimeout(() => setUpdatedValue(value), delay)
    },
    [ JSON.stringify(value) ],
  )

  return updatedValue
}

export default useValueWithDelayedUpdate