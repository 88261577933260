import { memo, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import { getPrimaryName } from '../../../utils/misc'

const Container = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  cursor: pointer;
  transition: opacity .15s ease-in-out;

  &:hover {
    opacity: .7;
  }
`

const BibleMapInfoEventPerson = ({
  person,
  setSelectedJourney,
  setSelectedPerson,
}) => {

  const selectPerson = useCallback(
    () => {
      setSelectedJourney()
      setSelectedPerson(person)
    },
    [ setSelectedJourney, setSelectedPerson, person ],
  )

  return (
    <Container
      onClick={selectPerson}
    >
      {getPrimaryName(person)}
    </Container>
  )
}

export default memo(BibleMapInfoEventPerson)