import { memo, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useDefinitions from '../../hooks/useDefinitions'
import { getGrammarColorCSSRules } from '../../utils/misc'
import { GRAMMAR_COLOR_BRIGHTNESS } from './VerseNumberPopperContentInline'

import Loading from '../common/Loading'
import OriginalChartWithDefs from './OriginalChartWithDefs'
import TextContent from '../common/TextContent'
import OriginalLanguageWordPopperContent from './OriginalLanguageWordPopperContent'
import ClickWordForMoreInfo from './ClickWordForMoreInfo'

const filter = `brightness(${GRAMMAR_COLOR_BRIGHTNESS * 100}%)`

const Container = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${getGrammarColorCSSRules({ filter })}
`

const StyledLoading = styled(Loading)`
  background: none;
  position: relative;
  padding: 100px 0;
  margin-bottom: 38px;
`

const ContextText = styled.div`
  font-size: 19px;
  ${getGrammarColorCSSRules({ filter })}
`

const VerseNumberPopperContentChart = ({
  contextRef,
  goSetPopperInfo,
  compareOrigTexts,
  closeAndClearSearch,
  tagSetStatus,
  originalRefs,
  originalWordPieces,
  loading,
  apparatusItems,
  phraseAndTranslationPairs,
  showGloss,
  setShowGloss,
  versionId,
}) => {

  const [ definitionsByStrong, loadingDefinitions ] = useDefinitions(originalWordPieces)

  const selectWord = useCallback(
    id => {
      const word = originalWordPieces.find(piece => piece[`x-id`] === id)
      if(!word) return null
      const { lemma, strong, morph, text, children } = word
      goSetPopperInfo(null, {
        Component: OriginalLanguageWordPopperContent,
        props: {
          // versionId: `original`,
          contextRef,
          contextText: (
            <ContextText>
              <TextContent
                pieces={[word]}
                versionId="original"
                bookId={contextRef.bookId}
                startChapter={contextRef.chapter}
              />
            </ContextText>
          ),
          id,
          lemma,
          morph,
          strong,
          form: text || children,
          goSetPopperInfo,
          closeAndClearSearch,
        },
      })
    },
    [ goSetPopperInfo, closeAndClearSearch, contextRef, originalWordPieces ],
  )

  const ready = !loading && !loadingDefinitions

  return (
    <Container>

      {!ready && <StyledLoading size={30} />}

      {ready &&
        <>

          <OriginalChartWithDefs
            tagSetStatus={tagSetStatus}
            originalPieces={originalWordPieces}
            contextRef={originalRefs[0]}
            selectWord={selectWord}
            showGloss={showGloss || [ undefined, `automatch`, `none` ].includes(tagSetStatus)}
            definitionsByStrong={definitionsByStrong}
            phraseAndTranslationPairs={phraseAndTranslationPairs}
          />

          <ClickWordForMoreInfo
            contextRef={contextRef}
            apparatusItems={apparatusItems}
            compareOrigTexts={compareOrigTexts}
            tagSetStatus={tagSetStatus}
            showGloss={showGloss}
            setShowGloss={setShowGloss}
            versionId={versionId}
          />

        </>
      }

    </Container>
  )
}

export default memo(VerseNumberPopperContentChart)