import React, { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import ChatIcon from '@material-ui/icons/Chat'
import ShareIcon from '@material-ui/icons/Share'
import CheckIcon from '@material-ui/icons/Check'

import {
  GET_STUDY_BIBLE_ITEM_TYPES,
} from '../../utils/constants'
import { getSecondsFromTimeString, getTimeStringFromSeconds } from '../../utils/misc'
import usePassageRef from '../../hooks/usePassageRef'
import useShareOrCopy from '../../hooks/useShareOrCopy'

import Loading from '../common/Loading'
import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'
import StudyBibleItemInsightContent from './StudyBibleItemInsightContent'
import StudyBibleItemSermonContent from './StudyBibleItemSermonContent'
import StudyBibleItemImageContent from './StudyBibleItemImageContent'
import StudyBibleItemMapContent from './StudyBibleItemMapContent'
import StudyBibleItemGenealogyContent from './StudyBibleItemGenealogyContent'
import StudyBibleItemTimelineContent from './StudyBibleItemTimelineContent'
import StudyBibleItemIntroductionContent from './StudyBibleItemIntroductionContent'
import StudyBibleItemType from './StudyBibleItemType'
import StudyBibleItemTags from './StudyBibleItemTags'
import StudyBibleItemSource from './StudyBibleItemSource'

const Container = styled.div`
  width: ${({ $width }) => $width ? `${$width}px` : `auto`};
  max-height: calc(100dvh - 30px);
  border-radius: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 5px 0;
`

const HeaderInfo = styled.div`
  flex: 1;
  padding: 10px 15px;
`

const HeaderInfoLine = styled.div`
`

const HeadingLine = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 7px;
  font-size: 12px;
  margin-top: 3px;
`

const HeadingAndTags = styled.div`
`

const Title = styled.span`
  display: inline-block;
  font-weight: bold;
  font-size: 17px;
`

const PassageRefContainer = styled.span`
  display: inline-block;
  margin-left: 5px;
  font-size: 15px;
`

const Heading = styled.span`
  font-weight: 300;
  font-style: italic;
  margin-right: 2px;
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin: 1px 6px 4px 0;

  .MuiSvgIcon-root {
    height: 20px;
    width: 20px;
  }
`

const Body = styled.div`
  position: relative;
  flex: 1;
  overflow-y: auto;
`

const Source = styled.div`
  display: flex;
  padding: 15px;
  gap: 10px;
  align-items: flex-end;
  position: relative;
  justify-content: flex-end;
  user-select: none;
`

const Logo = styled.img`
  height: 20px;
  margin: -2px;
`

const ClipLength = styled.div`
  font-size: 11px;
  font-weight: 400;
`

const StudyBibleItem = ({
  studyBibleItem,
  goShowEdit,
  onClose,
  showCloseIconButton,
  versionId,
  fromThumbnail,
}) => {

  const {
    id,
    heading,
    title,
    type,
    info,
    // thumbnailImageUrl,
    // status,
    fromLoc,
    toLoc,
    // editHistory,
    // adminRating,
    // userId,
    details=[],
    tags,
    // createdAt,
    // updatedAt,
    // deletedAt,
  } = studyBibleItem || {}

  const { imageDimensions={}, sourceText, mediaStartTime, mediaEndTime } = info || {}
  const durationInSeconds = Math.max(getSecondsFromTimeString(mediaEndTime) - getSecondsFromTimeString(mediaStartTime), 0)

  const passageStr = usePassageRef({
    fromLoc,
    toLoc,
    convertToVersionId: versionId,
  })

  const { copyOrShare, justCopiedOrShared, copyOptionsComponent } = useShareOrCopy({
    shareData: {
      title,
      text: `${heading} | ${passageStr}`,
      url: `${document.location.origin}/study-bible/${id}`,
    },
    giveShareOnXOption: true,
  })

  if(!studyBibleItem) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  const ContentComponent = {
    INSIGHT: StudyBibleItemInsightContent,
    QUOTE: StudyBibleItemInsightContent,
    SERMON: StudyBibleItemSermonContent,
    LECTURE: StudyBibleItemSermonContent,
    PODCAST: StudyBibleItemSermonContent,
    COURSE: StudyBibleItemSermonContent,
    IMAGE: StudyBibleItemImageContent,
    MAP: StudyBibleItemMapContent,
    GENEALOGY: StudyBibleItemGenealogyContent,
    TIMELINE: StudyBibleItemTimelineContent,
    CREED: StudyBibleItemInsightContent,
    INTRODUCTION: StudyBibleItemIntroductionContent,
  }[type]

  if(!ContentComponent) return null

  return (
    <Container
      className="StudyBibleItem-Container"
      $width={([ `SERMON`, `LECTURE`, `PODCAST`, `COURSE`, `CHANNEL` ].includes(type) ? 400 : imageDimensions.width)}
    >

      <Header>

        <HeaderInfo>

          <HeaderInfoLine>

            <Title>
              {title || i18n("[ title ]")}
            </Title>

            {` `}

            <PassageRefContainer>
              {passageStr}
            </PassageRefContainer>

          </HeaderInfoLine>

          <HeadingLine>

            <HeadingAndTags>

              <Heading>
                {heading}
              </Heading>

              <StudyBibleItemTags
                tags={tags}
                type={type}
              />

            </HeadingAndTags>

            <StudyBibleItemType $type={type}>
              {GET_STUDY_BIBLE_ITEM_TYPES()[type].label}
            </StudyBibleItemType>

            {[ `SERMON`, `LECTURE`, `PODCAST`, `COURSE` ].includes(type) &&
              <ClipLength>
                {getTimeStringFromSeconds(durationInSeconds)}
              </ClipLength>
            }

          </HeadingLine>

        </HeaderInfo>

        <StyledIconButton
          onClick={copyOrShare}
          color={justCopiedOrShared ? `primary` : `default`}
        >
          {justCopiedOrShared ? <CheckIcon /> : <ShareIcon />}
        </StyledIconButton>

        {copyOptionsComponent}

        {!!goShowEdit &&
          <StyledIconButton
            onClick={goShowEdit}
          >
            <EditIcon />
          </StyledIconButton>
        }

        <NavLinkOrAWithDisable
          to={`/study-bible/${encodeURIComponent(id)}`}
        >
          <StyledIconButton>
            <ChatIcon />
          </StyledIconButton>
        </NavLinkOrAWithDisable>

        {!!showCloseIconButton &&
          <StyledIconButton
            onClick={onClose}
          >
            <CloseIcon />
          </StyledIconButton>
        }

      </Header>

      <Body>

        <ContentComponent
          type={type}
          info={info}
          details={details}
          fromThumbnail={fromThumbnail}
        />

        <StudyBibleItemSource
          type={type}
          info={info}
        />

        {!sourceText && [ "INSIGHT", "QUOTE", "IMAGE", "MAP", "GENEALOGY", "TIMELINE", "INTRODUCTION", "COURSE" ].includes(type) &&
          <Source>
            <Logo src="/logo.svg" />
          </Source>
        }

      </Body>

    </Container>
  )
}

export default memo(StudyBibleItem)