import { memo, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { getBibleBookAbbreviatedName, getBibleBookName, getConcentricCircleScopes, getSuggestedInflectedSearches } from '@bibletags/bibletags-ui-helper'

import { MAX_ORIG_WORDS_FOR_SEARCH_ALL_SUGGESTION } from '../../utils/constants'
import { getLXXVersionInfo } from '../../utils/misc'
import useDefinition, { getNakedStrongs } from '../../hooks/useDefinition'
import useFormattedSearchValue from '../../hooks/useFormattedSearchValue'

import MenuDivider from './MenuDivider'
import SearchSuggestionMenuItem from './SearchSuggestionMenuItem'
import SearchSuggestionSearchWord from './SearchSuggestionSearchWord'

const SearchSuggestionOriginalMenuItems = ({
  words,
  selectionStart,  // have this here removes it from otherProps
  selectionEnd,  // have this here removes it from otherProps
  versionId,  // have this here removes it from otherProps
  baseSearchStr,  // have this here removes it from otherProps
  bookId,
  onClose,
  ...otherProps
}) => {

  words = words.length > 0 ? words : [{}]

  const {
    strong,
    morph,
    form,
    lemma,
  } = words[0]

  const { nakedStrongs, definition } = useDefinition(strong)
  const { nakedStrongs: lastNakedStrongs, definition: lastDefinition={} } = useDefinition(words.at(-1).strong)

  const {
    lex=lemma,
    hits,
    // gloss,
    // syn,
    // rel,
    lxx,
    lemmas,  // eventually show with poss info
  } = definition || {}

  const concentricCircleScopes = useMemo(() => getConcentricCircleScopes(bookId), [ bookId ])

  const sameBookHits = null
  const inflectedHits = null
  const hitsPerScope = {}

  const isLxxContext = (
    /^G/.test(nakedStrongs)
    && bookId
    && bookId <= 39
  )

  const inflectedSearches = getSuggestedInflectedSearches({ morph, form, lex, nakedStrongs, lemmas, lemma })

  const formattedValue = useFormattedSearchValue({ value: `#${nakedStrongs}`, noUnderline: true })

  if(words.length === 1) {

    const lxxSearchLine = (
      <SearchSuggestionMenuItem
        searchText={`#${nakedStrongs} in:LXX`}
        hits={lxx}
        onClose={onClose}
      >
        {i18nReact("Search {{word}} in {{scope}}", {  // i18nReact needed for lex
          word: lex,
          scope: getLXXVersionInfo().abbr,
        })}
      </SearchSuggestionMenuItem>
    )

    return (
      <div {...otherProps} >

        {!isLxxContext &&
          <>
            <SearchSuggestionMenuItem
              searchText={`#${nakedStrongs}`}
              hits={hits}
              onClose={onClose}
            >
              {i18nReact("Search {{search_string}} ({{lexical_form}})", {
                search_string: (
                  <SearchSuggestionSearchWord>
                    {formattedValue}
                  </SearchSuggestionSearchWord>
                ),
                lexical_form: lex,
              })}
            </SearchSuggestionMenuItem>

            {!!bookId &&
              <>

                <SearchSuggestionMenuItem
                  searchText={`#${nakedStrongs} in:${getBibleBookAbbreviatedName(bookId)}`}
                  hits={sameBookHits}
                  onClose={onClose}
                  isSubItem
                >
                  {i18nReact("{{scope}} only", {  // i18nReact needed for lex
                    scope: getBibleBookName(bookId),
                  })}
                </SearchSuggestionMenuItem>

                {concentricCircleScopes.slice(0,-1).map(({ label, scope }) => (
                  <SearchSuggestionMenuItem
                    key={label}
                    searchText={`#${nakedStrongs} in:${scope}`}
                    hits={hitsPerScope[scope]}
                    onClose={onClose}
                    isSubItem
                  >
                    {i18nReact("{{scope}} only", {  // i18nReact needed for lex
                      scope: label,
                    })}
                  </SearchSuggestionMenuItem>
                ))}

              </>
            }

            {/^G/.test(nakedStrongs) &&
              <>
                <MenuDivider />
                {lxxSearchLine}
              </>
            }

          </>
        }

        {isLxxContext &&
          <>
            {lxxSearchLine}
            <SearchSuggestionMenuItem
              searchText={`#${nakedStrongs}`}
              hits={hits}
              onClose={onClose}
            >
              {i18nReact("Search {{word}} in {{scope}}", {  // i18nReact needed for lex
                word: lex,
                scope: i18n("New Testament"),
              })}
            </SearchSuggestionMenuItem>
          </>
        }

        {inflectedSearches.length > 0 &&
          <>
            <MenuDivider />
            {inflectedSearches.map(inflectedSearch => (
              <SearchSuggestionMenuItem
                key={inflectedSearch.label}
                searchText={inflectedSearch.searchText}
                hits={inflectedHits}
                onClose={onClose}
              >
                {inflectedSearch.label}
              </SearchSuggestionMenuItem>
            ))}
          </>
        }

      </div>
    )

  }

  const doSearchAllSuggestions = words.length <= MAX_ORIG_WORDS_FOR_SEARCH_ALL_SUGGESTION
  const searchText = words.map(({ strong }) => `#${getNakedStrongs(strong)}`).join(` `)

  const lxxSearchLine = (
    <SearchSuggestionMenuItem
      searchText={`${searchText} in:LXX`}
      onClose={onClose}
    >
      {i18n("Search {{scope}} for these words", {
        scope: getLXXVersionInfo().abbr,
      })}
    </SearchSuggestionMenuItem>
  )

  return (
    <div {...otherProps} >

      {!isLxxContext &&
        <>

          {doSearchAllSuggestions &&
            <SearchSuggestionMenuItem
              searchText={searchText}
              onClose={onClose}
            >
              {i18n("Search these words")}
            </SearchSuggestionMenuItem>
          }

          {doSearchAllSuggestions &&
            <SearchSuggestionMenuItem
              searchText={`"${searchText}"`}
              onClose={onClose}
            >
              {i18n("Search this exact phrase")}
            </SearchSuggestionMenuItem>
          }

          {words.length >= 3 &&
            <SearchSuggestionMenuItem
              searchText={`#${nakedStrongs} #${lastNakedStrongs}`}
              onClose={onClose}
            >
              {i18nReact("Search “{{first_word}}” and “{{last_word}}”", {  // i18nReact needed for lex
                first_word: lex,
                last_word: lastDefinition.lex || words.at(-1).lemma,
              })}
            </SearchSuggestionMenuItem>
          }

          {/^G/.test(nakedStrongs) && doSearchAllSuggestions && lxxSearchLine}

        </>
      }

      {isLxxContext && doSearchAllSuggestions &&
        <>

          {lxxSearchLine}

          <SearchSuggestionMenuItem
            searchText={searchText}
            onClose={onClose}
          >
            {i18n("Search {{scope}} for these words", {
              scope: i18n("New Testament"),
            })}
          </SearchSuggestionMenuItem>

        </>
      }

    </div>
  )
}

export default memo(SearchSuggestionOriginalMenuItems)