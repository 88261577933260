import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import OtherSearchResultsHeader from './OtherSearchResultsHeader'
import MapSearchResultsRow from './MapSearchResultsRow'

const Container = styled.div`
`

const MapSearchResults = ({
  count,
  maps,
  closeAndClearSearch,
}) => {

  return (
    <Container>

      <OtherSearchResultsHeader>
        {i18n("Map + Timeline")}
      </OtherSearchResultsHeader>

      {maps.map((map, idx) => (
        <MapSearchResultsRow
          key={idx}
          closeAndClearSearch={closeAndClearSearch}
          {...map}
        />
      ))}

    </Container>
  )
}

export default memo(MapSearchResults)