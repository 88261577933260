import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import OtherSearchResultsHeader from './OtherSearchResultsHeader'
import StudyBibleItemSearchResultsRow from './StudyBibleItemSearchResultsRow'

const Container = styled.div`
`

const StudyBibleItemSearchResults = ({
  count,
  studyBibleItems,
}) => {

  return (
    <Container>
    
      <OtherSearchResultsHeader>
        {i18n("Study Bible Entries")}
      </OtherSearchResultsHeader>

      {studyBibleItems.map(studyBibleItem => (
        <StudyBibleItemSearchResultsRow
          key={studyBibleItem.id}
          {...studyBibleItem}
        />
      ))}

    </Container>
  )
}

export default memo(StudyBibleItemSearchResults)