import { memo } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import { i18n } from 'inline-i18n'

import useModalAnchor from '../../../hooks/useModalAnchor'
import useFormattingKey from '../../../hooks/useFormattingKey'

import FormattingKey from './FormattingKey'
import OptionsPopover from '../../common/OptionsPopover'

const Container = styled.div`
  position: relative;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 11px;

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
`

const StyledFormattingKey = styled(FormattingKey)`
  max-width: none;
`

const FormattingKeyButton = ({
  id,
  ...otherProps
}) => {

  const { hasLabels } = useFormattingKey(id)

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  if(!hasLabels) return null

  return (
    <Container>

      <Tooltip title={i18n("Formatting Key")}>

        <StyledIconButton
          {...otherProps}
          onClick={openModal}
        >
          <VpnKeyIcon />
        </StyledIconButton>

      </Tooltip>

      <OptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
        disableRestoreFocus
      >
        <StyledFormattingKey
          id={id}
          hideExplanation
        />
      </OptionsPopover>

    </Container>
  )
}

export default memo(FormattingKeyButton)