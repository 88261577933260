import { useMemo } from 'react'

import { cloneObj, camelToDashCase } from '../utils/misc'
import useDataQuery from './useDataQuery'
import useEqualObjsMemo from './useEqualObjsMemo'

import moduleSettingQuery from '../graphql/queries/moduleSetting'

const MAX_NUMBER_OF_SETTINGS = 20  // if I change this, I must change useDuplicateModuleSettings and also consider changing batchMax

const useModuleSettings = ({
  moduleId,
  defaultSettingValues,
  skip,
 }) => {

  const memoedDefaultSettingValues = useEqualObjsMemo(defaultSettingValues)

  const defaultSettings = useMemo(
    () => {

      const defaults = {}

      for(let setting in memoedDefaultSettingValues) {
        defaults[setting] = {
          __typename: `ModuleSetting`,
          id: `${moduleId}:${camelToDashCase(setting || ``)}`,
          value: cloneObj(memoedDefaultSettingValues[setting]),
        }
      }

      return defaults
    },
    [ memoedDefaultSettingValues, moduleId ],
  )

  const settings = Object.keys(defaultSettings).sort()

  if(settings.length > MAX_NUMBER_OF_SETTINGS) throw new Error(`attempted to retrieve > ${MAX_NUMBER_OF_SETTINGS} settings`)

  const queryInfo = Array(MAX_NUMBER_OF_SETTINGS).fill().map((x, idx) => ({
    moduleSettingQuery,
    variables: {
      id: `${moduleId}:${camelToDashCase(settings[idx] || ``)}`,
      defaultValue: (defaultSettings[settings[idx]] || {}).value,
    },
    skip: skip || !settings[idx],
  }))

  let loading = false
  const getValue = (queryValue, idx) => {
    loading = loading || queryValue.loading
    return queryValue.moduleSetting || defaultSettings[settings[idx]]
  }

  let idx = -1
  const moduleSettings = {
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
    [`${settings[++idx]}Setting`]: getValue(useDataQuery(queryInfo[idx]), idx),
  }
  delete moduleSettings.undefinedSetting
  moduleSettings.moduleSettingsLoading = loading

  return moduleSettings

}

export default useModuleSettings