import { useCallback } from 'react'

import useAccountSetting from './useAccountSetting'
import useInstanceValue from './useInstanceValue'
import { cloneObj, equalObjs, removeLocalStorage, setLocalStorage } from '../utils/misc'

const useUpdateListeningHistory = ({
  channelItem,
  doAudio,
  channelId,
}) => {

  const [ listeningHistory, setListeningHistory ] = useAccountSetting(`listening-history`, [])
  const getListeningHistory = useInstanceValue(listeningHistory)
  const getDoAudio = useInstanceValue(doAudio)

  const updateListeningHistory = useCallback(
    params => {
      if(!channelItem.title) return

      let newListeningHistory = cloneObj(getListeningHistory())
      const index = newListeningHistory.findIndex(({ id }) => id === channelItem.id)
      let item = newListeningHistory[index]
      const oldOpen = (item || {}).open

      if(!item) {
        const { currentSecs=0, playing=false, doAudio=getDoAudio(), speed=1, open=true } = params
        item = {
          id: channelItem.id,
          title: channelItem.title,
          open,
          playing,
          currentSecs,
          speed,
          doAudio,
          channelId,
        }
        newListeningHistory.unshift(item)
      } else {
        if(index !== 0) {
          item = newListeningHistory.splice(index, 1)[0]
          newListeningHistory.unshift(item)
        }
        ;[ `currentSecs`, `playing`, `doAudio`, `speed`, `open` ].forEach(key => {
          if(params[key] !== undefined) { item[key] = params[key] }
        })
        item.channelId = channelId
      }

      newListeningHistory.splice(100, Infinity)

      if(!equalObjs(getListeningHistory(), newListeningHistory)) {
        item.lastViewedAt = Date.now()
        setListeningHistory(newListeningHistory)
      }

      if(oldOpen !== item.open) {
        if(item.open) {
          setLocalStorage(`message-snackbar-open`, item.open)
        } else {
          removeLocalStorage(`message-snackbar-open`)
        }
      }

    },
    [ channelItem, getListeningHistory, setListeningHistory, getDoAudio, channelId ],
  )

  return updateListeningHistory
}

export default useUpdateListeningHistory