import { gql } from '@apollo/client'

import { getUserFields } from '../fragments/user_fields'

export default gql
`
  mutation createSubscription($input: SubscriptionInput!) {
    createSubscription(input: $input) {
      ${getUserFields({ isAdmin: true })}
    }
  }
`