import { memo } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Shortcut = styled.div`
  margin: -1em -.1em -1em .5em;
  padding: 0 .3em;
  background-color: rgb(0 0 0/.7);
  border-radius: .3em;
  align-self: center;
  color: white;

  ${({ $subtle, theme }) => !$subtle ? `` : `
    font-size: .65em;
    background: none;
    color: ${theme.palette.grey[600]};
    border: 1px solid ${theme.palette.divider};
  `}
`

const TextAndShortcutCombo = ({
  text,
  shortcutCombo,
  subtle,
  ...otherProps
}) => {

  return (
    <Container {...otherProps}>
      {text}
      <Shortcut $subtle={subtle}>
        {shortcutCombo}
      </Shortcut>
    </Container>
  )
}

export default memo(TextAndShortcutCombo)