import { memo } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckIcon from '@material-ui/icons/Check'

const Container = styled.div`
  position: relative;
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  right: 7px;
  bottom: 6px;
`

const StyledCheckIcon = styled(CheckIcon)`
  height: 12px;
  position: absolute;
  right: 0;
  bottom: 5px;
`

const SaveStatusTextField = ({
  savedValue,
  ...otherProps
}) => {

  const saved = savedValue === otherProps.value

  return (
    <Container>
      <TextField
        {...otherProps}
      />
      {saved && <StyledCheckIcon />}
      {!saved && <StyledCircularProgress size={10} />}
    </Container>
  )
}

export default memo(SaveStatusTextField)