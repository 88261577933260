import { gql } from '@apollo/client'
import alertsItem_fields from '../fragments/alertsItem_fields'

export default gql
`
  mutation dismissAlert($id: ID!) {
    dismissAlert(id: $id) {
      ${alertsItem_fields}
    }
  }
`