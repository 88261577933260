import { memo, useContext, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { v4 as uuidv4 } from 'uuid'

import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'

import ConfirmDialog from '../../common/ConfirmDialog'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import MyPlanPromoSpot from '../../common/MyPlanPromoSpot'
import InfoDialog from '../../common/InfoDialog'

const MAX_NUM_MY_SNAPSHOTS = 100

const StyledIconButton = styled(IconButton)`
  padding: 5px;
  margin: -1px 0;
  align-self: center;

  .MuiSvgIcon-root {
    font-size: 22px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main}3a;
      color: rgb(0 0 0/.8);
    }
  }

  @media (max-width: 440px) {
    display: none;
  }

`

const StyledTextField = styled(TextField)`
  margin: 20px 0 15px;
`

const SnapshotButton = styled(Button)`
  text-transform: none;
  position: relative;
  display: none;

  .MuiButton-startIcon {
    margin-right: 4px;
  }

  @media (max-width: 440px) {
    display: flex;
  }
`

const Explanation = styled.div`
  margin-top: -10px;
  max-width: 400px;
  font-size: 15px;
`

const CreateSnapshotButton = ({
  myMapSnapshots,
  setMyMapSnapshots,
  eraId,
  dateStr,
  timelineDetailsWidth,
  getMapViewInfo,
  showJourneys,
  showJourneyKey,
  showDistance,
}) => {

  const { hasMyPlan } = useContext(LoggedInUserContext) || {}
  const [ dialogOpen, toggleDialogOpen ] = useSimpleToggle()

  const [ label, setLabel ] = useState(``)
  const onChange = useInstanceValuesCallback(({ target: { value } }) => setLabel(value))

  const goSaveSnapshot = useInstanceValuesCallback(
    () => {
      const {
        lowestX,
        lowestY,
        highestX,
        highestY,    
      } = getMapViewInfo()
      setMyMapSnapshots([
        ...myMapSnapshots,
        {
          id: uuidv4(),
          snapshotLabel: label.replace(/\s\s+/g, ` `).trim(),
          eraId,
          date: dateStr,
          timelineDetailsWidth,
          scaleAndPositionInfo: {
            lowestX,
            lowestY,
            highestX,
            highestY,        
          },
          options: {
            showJourneys,
            showJourneyKey,
            showDistance,
          },
        },
      ])
      toggleDialogOpen({ force: false })
      setLabel(``)
    }
  )

  const disabled = !hasMyPlan || !label.trim()
  const exceededMax = myMapSnapshots.length >= MAX_NUM_MY_SNAPSHOTS

  return (
    <>

      <Tooltip
        title={i18n("Create new snapshot")}
      >
        <StyledIconButton
          onClick={toggleDialogOpen}
        >
          <AddIcon />
        </StyledIconButton>
      </Tooltip>

      <SnapshotButton
        variant="contained"
        disableElevation
        size="small"
        color="primary"
        onClick={toggleDialogOpen}
        startIcon={<AddIcon />}
      >
        {i18n("Create new snapshot")}
      </SnapshotButton>
        
      <ConfirmDialog
        open={dialogOpen && hasMyPlan && !exceededMax}
        onCancel={toggleDialogOpen}
        onConfirm={goSaveSnapshot}
        disabled={disabled}
        title={i18n("Create a New Snapshot")}
        explanation={
          <>

            <Explanation>
              {i18n("Create a new snapshot of the current map and timeline setup so as to easily return to this view.")}
              {` `}
              {i18n("If everything is ready for the snapshot, give it a label and click Create.")}
              {` `}
              {i18n("Otherwise, return here after making adjustments.")}
            </Explanation>

            <StyledTextField
              onChange={onChange}
              variant="outlined"
              size="small"
              fullWidth
              value={label}
              label={i18n("Snapshot Label")}
              inputProps={{ maxLength: 30 }}
            />

          </>
        }
      />

      <InfoDialog
        open={dialogOpen && (!hasMyPlan || exceededMax)}
        onOkay={toggleDialogOpen}
        showCloseIconButton
        title={i18n("Create a New Snapshot")}
        explanation={
          hasMyPlan
            ? i18n("You have reached the maximum number of snapshots.")
            : (
              <MyPlanPromoSpot
                subscribeToMessage={i18n("Subscribe to create map snapshots.")}
              />
            )
        }
      />

    </>
  )
}

export default memo(CreateSnapshotButton)