import { memo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'

const Container = styled.div`
`

const HelpItemSearchResultsRow = ({
  suggestedQuery,
  action,
}) => {

  return (
    <Container>
      {suggestedQuery}
    </Container>
  )
}

export default memo(HelpItemSearchResultsRow)