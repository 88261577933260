import useShortcuts from './useShortcuts'

import keyDownSlash from '../components/common/shortcuts/keyDownSlash'
import keyDownEscape from '../components/common/shortcuts/keyDownEscape'
import keyDownZ from '../components/common/shortcuts/keyDownZ'
import keyDownA from '../components/common/shortcuts/keyDownA'

const keyDownFuncs = {
  keyDownSlash,
  keyDownEscape,
  keyDownZ,
  keyDownA,
}

const useUniversalShortcuts = params => {

  useShortcuts({
    keyDownFuncs,
    ...params,
  })

}

export default useUniversalShortcuts