import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import { getPrimaryName } from '../../../utils/misc'

import BibleMapPlaceDebuggerRect from './BibleMapPlaceDebuggerRect'

const LINE_HEIGHT = 24

const Dot = styled.div`
  position: absolute;
  z-index: 2;
  margin-left: -7px;
  margin-top: -7px;
  border-radius: 100px;
  background: ${({ $selected, $stage, theme }) => ($selected && $stage !== `PUBLISHED`) ? theme.palette.mapColors.selectedPlace : `transparent`};
  color: ${({ $selected, $stage, theme }) => ($selected && $stage === `PUBLISHED`) ? theme.palette.mapColors.selectedPlace : `inherit`};
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Label = styled.div`
  position: absolute;
  white-space: nowrap;
  line-height: ${LINE_HEIGHT}px;
  font-size: 22px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500;
  opacity: .8;
  white-space: pre;
  text-align: center;
`

export const getApproxRect = place => {
  const name = getPrimaryName(place, { newlines: true })
  const lines = name.split('\n')
  const numCharsInLongestLine = lines.reduce((acc, line) => Math.max(acc, line.length), 0)
  const numLines = lines.length
  const width = numCharsInLongestLine * 16 + 10
  const height = numLines * LINE_HEIGHT + 10
  return {
    x: parseInt(width/-2, 10),
    y: parseInt(height/-2, 10),
    width,
    height,
  }
}

const BibleMapPlaceKingdom = ({
  place,
  selectThisPlace,
  getStyle,
  missingKeyData,
  ...otherProps
}) => {

  const style = getStyle(place)

  return (
    <>
      <Dot
        onClick={selectThisPlace}
        style={style}
        {...otherProps}
      >
        <Label>
          {getPrimaryName(place, { newlines: true })}
        </Label>
      </Dot>
      <BibleMapPlaceDebuggerRect
        style={style}
        approxRect={getApproxRect(place)}
        missingKeyData={missingKeyData}
      />
    </>
  )
}

export default memo(BibleMapPlaceKingdom)