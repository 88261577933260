import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation updateHighlights($input: [HighlightInput]!, $updatedSince: Milliseconds) {
    updateHighlights(input: $input, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`