import { memo, useCallback, useContext } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
// import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import LinkIcon from '@material-ui/icons/Link'
import CheckIcon from '@material-ui/icons/Check'
import LaunchIcon from '@material-ui/icons/Launch'
// import PersonAddIcon from '@material-ui/icons/PersonAdd'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import ShareIcon from '@material-ui/icons/Share'

import useShareOrCopy from '../../../hooks/useShareOrCopy'
import usePassageRef from '../../../hooks/usePassageRef'
import { getModuleTypeLabel } from '../../../utils/misc'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import OptionsPopover from '../../common/OptionsPopover'
import MenuDivider from '../../common/MenuDivider'
import FlashDiv from '../../common/FlashDiv'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const StyledList = styled(List)`
  position: relative;
`

const Explanation = styled.div`
  padding: 10px 20px;
  color: ${({ theme }) => theme.palette.grey[500]};
  max-width: 200px;
`

const CopyMenuItem = styled(MenuItem)`
  position: relative;
`

const CopiedMenuItem = styled(MenuItem)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  left: ${({ $copied }) => $copied ? 0 : `100%`};
  transition: left .2s ease-in-out;
  right: 0;
  z-index: 1;
  font-weight: bold;
  background-color: white;

  &:hover {
    background-color: white;
  }
`

const ModuleSharingPopover = ({
  anchorEl,
  onClose,
  module,
  toggleShared,
  showSharingOn,
  toggleShowSharingOn,
}) => {

  const shareLink = `${window.location.origin}/shared/${module.id}`

  const user = useContext(LoggedInUserContext)

  const { fromLoc, toLoc, info } = module.modulePassages[0] || {}
  const { versionId } = info || {}
  const passageRef = usePassageRef({
    fromLoc,
    toLoc,
    convertToVersionId: versionId,
  })

  const { copyOrShare, goCopy, willShare, justCopiedOrShared } = useShareOrCopy({
    shareData: {
      title: (
        i18n("{{module_type}} of {{passages}}", {
          module_type: getModuleTypeLabel(module),
          passages: passageRef,
        })
      ),
      text: (
        i18n("By {{author}}", {
          author: (user || {}).name,
        })
      ),
      url: shareLink,
    },
  })

  const goTurnOffSharing = useCallback(
    () => {
      toggleShared()
      onClose()
    },
    [ onClose, toggleShared ],
  )

  return (
    <OptionsPopover
      anchorEl={anchorEl}
      onClose={onClose}
      forceBelow
      hideArrow
      horizontal="center"
    >
      <StyledList>

        <Explanation>
          {i18n("Anyone with the link can view this module.")}
        </Explanation>

        {willShare &&
          <MenuItem onClick={copyOrShare}>
            <ListItemIcon>
              <ShareIcon fontSize="small" />
            </ListItemIcon>
            {i18n("Share")}
          </MenuItem>
        }

        <CopyMenuItem
          onClick={goCopy}
        >
          <ListItemIcon>
            <LinkIcon fontSize="small" />
          </ListItemIcon>
          {i18n("Copy link")}

          <CopiedMenuItem $copied={justCopiedOrShared}>
            <ListItemIcon>
              <CheckIcon fontSize="small" />
            </ListItemIcon>
            {i18n("Copied")}
          </CopiedMenuItem>
        </CopyMenuItem>

        <NavLinkOrAWithDisable to={shareLink}>
          <MenuItem>
            <ListItemIcon>
              <LaunchIcon fontSize="small" />
            </ListItemIcon>
            {i18n("Open in new tab")}
          </MenuItem>
        </NavLinkOrAWithDisable>

        {/* <MenuItem>
          <ListItemIcon>
            <PersonAddIcon fontSize="small" />
          </ListItemIcon>
          {i18n("Share directly with individuals")}
        </MenuItem> */}

        <MenuDivider />

        <MenuItem
          onClick={goTurnOffSharing}
        >
          <ListItemIcon>
            <LinkOffIcon fontSize="small" />
          </ListItemIcon>
          {i18n("Turn off sharing")}
        </MenuItem>

        <FlashDiv
          show={showSharingOn}
          onHide={toggleShowSharingOn}
        >
          {i18n("Sharing has been turned on")}
        </FlashDiv>

      </StyledList>
    </OptionsPopover>
  )
}

export default memo(ModuleSharingPopover)