import { useMemo } from 'react'
import { getMorphInfo } from "@bibletags/bibletags-ui-helper"

const useMorphPos = ({ morph }) => {

  const info = useMemo(
    () => {

      if(!morph) return {}

      return getMorphInfo(morph)

    },
    [ morph ],
  )

  return info

}

export default useMorphPos