import { memo, useState, useCallback } from 'react'
import { useNetworkState } from 'react-use'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'
import { useMutation } from '@apollo/client'

import Header from "../../common/Header"
import AppContent from "../../common/AppContent"
import Loading from "../../common/Loading"
import LinkButton from "../../common/LinkButton"
import useMutationContext from '../../../hooks/useMutationContext'

import restoreProjectMutation from '../../../graphql/mutations/restoreProject'

const StyledAppContent = styled(AppContent)`
  padding-bottom: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Message = styled.div`
  font-size: 20px;
  margin-bottom: 30px;
`

const Buttons = styled.div`
`

const StyledButton = styled(Button)`
  margin: 0 5px;
`

const StyledLinkButton = styled(LinkButton)`
  margin: 0 5px;
`

const ProjectNotFound = ({
  id,
  deletedJustNow,
  ...otherProps
}) => {

  const { online } = useNetworkState()

  const [ lockAndShowLoading, setLockAndShowLoading ] = useState(false)

  const [ restoreProject ] = useMutation(restoreProjectMutation)
  const context = useMutationContext()

  const goRestoreProject = useCallback(
    async () => {
      setLockAndShowLoading(true)

      await restoreProject({
        variables: {
          id,
        },
        context,
      })

      window.location.reload()
    },
    [ restoreProject, id, context ],
  )

  return (
    <>

      <Header {...otherProps}>
        {` `}
      </Header>

      <StyledAppContent>

        <Message>
          {deletedJustNow ? i18n("Project deleted.") : i18n("Project not found.")}
        </Message>

        <Buttons>

          {deletedJustNow && online &&
            <StyledButton
              color="primary"
              variant="contained"
              disableElevation
              onClick={goRestoreProject}
            >
              {i18n("Restore")}
            </StyledButton>
          }

          <StyledLinkButton
            to="/projects"
            color="primary"
            variant={deletedJustNow ? "outlined" : "contained"}
            disableElevation
          >
            {i18n("Browse projects")}
          </StyledLinkButton>

          {!deletedJustNow &&
            <StyledLinkButton
              to="/projects/trash"
              color="primary"
              variant="outlined"
            >
              {i18n("Browse trash")}
            </StyledLinkButton>
          }

        </Buttons>

      </StyledAppContent>

      {lockAndShowLoading && <Loading />}

    </>
  )
}


export default memo(ProjectNotFound)