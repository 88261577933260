import { memo } from 'react'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'

const Container = styled.div`
  padding: 7px 0;
  display: block;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0 0 0 -7px;

  .MuiFormControlLabel-label {
    margin-left: 2px;
    font-size: 14px;
  }
`

const CustomSwitch = ({
  checked,
  onChange,
  onSet,
  tabIndex,
  ...otherProps
}) => {

  const goOnChange = useInstanceValuesCallback(
    event => {
      onSet && onSet(event.target.checked)
      onChange && onChange(event)
    }
  )
  
  return (
    <Container>

      <StyledFormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={goOnChange}
            tabIndex={tabIndex}
            size="small"
          />
        }
        {...otherProps}
      />

    </Container>
  )
}

export default memo(CustomSwitch)