const fields = `
  id
  container
  positionInfo
  savedAt
  moduleId
  type
  color
`

// Not included here, but available:
  // module

export default fields