import { MIN_IMAGE_WIDTH, MAX_IMAGE_WIDTH } from '../ImageOptions'

const keyDownArrowLeftRight = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    key,
    setUpdateTimeout,
    getImageWidthInChange,
    updateImageWidthInChange,
    commitImageWidth,
  } = params

  if(modifierKey) return
  event.preventDefault()

  const imageWidthInChange = getImageWidthInChange()
  const change = (key === 'ArrowLeft' ? -1 : 1) * (shiftKey ? 10 : 1)
  const newImageWidth = Math.min(Math.max(MIN_IMAGE_WIDTH, imageWidthInChange + change), MAX_IMAGE_WIDTH)

  if(newImageWidth !== imageWidthInChange) {
    document.activeElement.blur()  // this prevents the image size slider from being problematically focused
    updateImageWidthInChange(event, newImageWidth)
    setUpdateTimeout(commitImageWidth, 700)
  }

}

export default keyDownArrowLeftRight