import { memo, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { getIsEntirelyPrefixAndSuffix, getMorphPartDisplayInfo } from "@bibletags/bibletags-ui-helper"

import useMorphPos from '../../hooks/useMorphPos'
import { getHebrewPrefixDefinition, getHebrewSuffixGloss } from '../../utils/hebrewPrefixAndSuffixInfo'

const Container = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
`

const Detail = styled.span`
  color: ${({ $color }) => $color};
  filter: brightness(160%);
`

const Empty = styled.div`
  ${({ $hasHeader }) => $hasHeader ? `` : `
    margin-top: -16px;
  `}
`

const Plus = styled.span`
  display: inline-block;
  margin: 0 5px;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const MorphInfo = ({
  strong,
  morph,
  hasHeader,
}) => {

  const isEntirelyPrefixAndSuffix = getIsEntirelyPrefixAndSuffix({ strong })
  const { morphLang, morphParts, mainPartIdx } = useMorphPos({ morph })

  const contents = useMemo(
    () => (morphParts || []).map((morphPart, idx) => {

      const isPrefixOrSuffix = isEntirelyPrefixAndSuffix || idx !== mainPartIdx
      const wordIsMultiPart = morphParts.length > 1
      let { str, color } = getMorphPartDisplayInfo({ morphLang, morphPart, isPrefixOrSuffix, wordIsMultiPart })

      if(!str) return null

      if(isPrefixOrSuffix) {

        const addGlossToStr = gloss => {
          // don't add it on if it is just something in square brackets
          if(!/^\[[^[\]]*\]$/.test(gloss)) {
            str = i18n("{{title}} ({{subtitle}})", {
              title: str,
              subtitle: gloss,
            })
          }
        }

        if(idx > mainPartIdx) {

          addGlossToStr(getHebrewSuffixGloss({ morphPart }))

        } else {

          const strongParts = strong.split(/:/g)
          let { gloss=`` } = getHebrewPrefixDefinition(strongParts[idx]) || {}

          if(morphPart === `Rd`) {
            gloss += ` + ${getHebrewPrefixDefinition(`d`).gloss}`
          }

          addGlossToStr(gloss)

        }

      }

      return (
        <Detail
          key={`${idx} ${str} ${color}`}
          $color={color}
        >
          {idx > 0 && <Plus>{i18n("+", "combination character")}</Plus>}
          {str}
        </Detail>
      )

    }),
    [ morphParts, isEntirelyPrefixAndSuffix, mainPartIdx, morphLang, strong ],
  )

  if(contents.filter(Boolean).length === 0) {
    return <Empty $hasHeader={hasHeader} />
  }

  return (
    <Container>

      {contents}

    </Container>
  )
}

export default memo(MorphInfo)