import { useCallback, useState, useRef } from "react"
import styled from 'styled-components'

import { usingModifierKey } from '../utils/misc'
import useEffectAsync from "./useEffectAsync"

const BreakMark = styled.div`

  position: absolute;
  width: 2px;
  background-color: red;
  pointer-events: none;
  transform: rotate(16deg);
  box-shadow: -14px 0 22px rgb(0 0 0/.5), -7px 0 10px rgb(0 0 0/.5), -5px 0 6px rgb(0 0 0/.5), -2px 0 3px rgb(0 0 0/.5);
  ${({ $isRTL }) => !$isRTL ? `` : `
    transform: rotate(-16deg);
    box-shadow: 14px 0 22px rgb(0 0 0/.5), 7px 0 10px rgb(0 0 0/.5), 5px 0 6px rgb(0 0 0/.5), 2px 0 3px rgb(0 0 0/.5);
  `}
  box-sizing: content-box;
  border-radius: 4px;

`

const useOutlineDivider = ({
  isScrolling,
  containerRef,
  inEditingMode,
  goCreateModulePiece,
  wordLocAlreadyWithBreak,
  isRTL,
}) => {

  const [ coords, setCoords ] = useState()
  const wordLocRef = useRef()

  const breakMark = (
    (inEditingMode && coords)
      ? (
        <BreakMark
          style={coords}
          $isRTL={isRTL}
        />
      )
      : null
  )

  const clearMark = useCallback(
    () => {
      setCoords()
      wordLocRef.current = undefined
    },
    [],
  )

  const onMouseMove = useCallback(
    event => {
      const wordEl = event.target.closest('.text-content-word')
      if(
        wordEl
        && wordEl.getAttribute(`data-word-loc`) !== wordLocAlreadyWithBreak
        && window.getSelection().isCollapsed
        && !usingModifierKey(event)
      ) {
        const { left: containerLeft, top: containerTop } = containerRef.current.getBoundingClientRect()
        const { left: wordLeft, top: wordTop, height: wordHeight, width: wordWidth } = wordEl.getBoundingClientRect()
        const height = wordHeight * 2.5
        setCoords({
          top: wordTop - containerTop + wordHeight/2 - height/2,
          left: wordLeft - containerLeft - wordHeight/6 + (isRTL ? (wordWidth + wordHeight/3) : 0),
          height,
        })
        wordLocRef.current = wordEl.getAttribute(`data-word-loc`)
      } else {
        clearMark()
      }
    },
    [ containerRef, clearMark, wordLocAlreadyWithBreak, isRTL ],
  )

  const onTouchMove = useCallback(() => clearMark(), [ clearMark ])
  const onMouseLeave = useCallback(() => clearMark(), [ clearMark ])

  const onTouchStart = useCallback(
    ({ touches }) => {
      if(touches.length !== 1) {
        onTouchMove()
      } else {
        onMouseMove(touches[0])
      }
    },
    [ onTouchMove, onMouseMove ],
  )

  const onClick = useCallback(
    () => {
      if(wordLocRef.current) {
        const [ loc, wordNum ] = wordLocRef.current.split(':')
        goCreateModulePiece({
          position: `break`,
          ordering: parseInt(`${loc}${`00${wordNum}`.slice(-3)}`, 10),
        })
        clearMark()
      }
    },
    [ goCreateModulePiece, clearMark ],
  )

  useEffectAsync(
    () => {
      if(isScrolling) {
        clearMark()
      }
    },
    [ isScrolling ],
  )

  const events = (
    inEditingMode
      ? {
        onMouseMove,
        onMouseLeave,
        onClick,
        onTouchStart,
        onTouchMove,
      }
      : {}
  )

  return {
    events,
    breakMark,
  }
}

export default useOutlineDivider
