import styled from 'styled-components'

const Span = styled.span``

const SpanWithRemovedTabIndex = props => (
  <Span
    {...props}
    tabIndex={-1}
  />
)

export default SpanWithRemovedTabIndex