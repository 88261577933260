import { memo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'

const Container = styled.div`
  min-height: 40px;
`

const BibleSearchOtherSuggestedQueries = ({
  rowCountByBookId,
}) => {

  return (
    <Container>
    </Container>
  )
}

export default memo(BibleSearchOtherSuggestedQueries)