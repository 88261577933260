import { memo, useState } from 'react'
import styled from 'styled-components'

import useSetTimeout from '../../hooks/useSetTimeout'
import useEffectAsync from '../../hooks/useEffectAsync'

const Container = styled.div`
  transition: opacity ${({ $transitionDuration }) => $transitionDuration}ms ease-in-out;
  opacity: ${({ $visualState }) => $visualState === `visible` ? 1 : 0};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[600]};
  padding: 40px;
  text-align: center;
`

const FlashDiv = ({
  show,
  onHide,
  transitionDuration=300,
  displayDuration=1000,
  ...otherProps
}) => {

  const [ visualState, setVisualState ] = useState(show ? `visible` : `hidden`)
  const [ setTransitionTimeout ] = useSetTimeout()

  useEffectAsync(
    () => {
      if(show) {
        setVisualState(`faded`)
        setTransitionTimeout(
          () => {
            setVisualState(`visible`)
            setTransitionTimeout(
              () => onHide(),
              transitionDuration + displayDuration,
            )
          }
        )
      } else if(visualState !== `hidden`) {
        setVisualState(`faded`)
        setTransitionTimeout(() => setVisualState(`hidden`), transitionDuration)
      }
    },
    [ show ],
  )

  if(visualState === `hidden`) return null

  return (
    <Container
      {...otherProps}
      $visualState={visualState}
      $transitionDuration={transitionDuration}
    />
  )

}
export default memo(FlashDiv)