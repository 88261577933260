import { memo, useCallback, useState, useRef, useContext } from 'react'
import { i18n } from 'inline-i18n'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import useMutationContext from '../../hooks/useMutationContext'
import { LoggedInUserContext } from '../../context/LoggedInUser'
import { loginCallback } from '../../utils/misc'

import ConfirmDialog from './ConfirmDialog'

import createStudyBibleAssetUploadLinkMutation from '../../graphql/mutations/createStudyBibleAssetUploadLink'
import updateUserMutation from '../../graphql/mutations/updateUser'
import useEffectAsync from '../../hooks/useEffectAsync'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'

const ACCEPT = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/bmp': ['.bmp'],
  'image/gif': ['.gif'],
  'image/webp': ['.webp'],
  'image/svg+xml': ['.svg'],
}

const SliderContainer = styled.div`
`

const SliderLabel = styled.div`
`

const UploadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 25px 30px 40px;
`

const UploadButtonText = styled.div`
  font-weight: 300;
  font-size: 16px;
`

const UploadButtonOr = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
`

const AvatarEditorDialog = ({
  open,
  onClose,
}) => {

  const [ baseImage, setBaseImage ] = useState()
  const [ zoom, setZoom ] = useState(1)
  const [ saving, setSaving ] = useState(false)

  const user = useContext(LoggedInUserContext)
  const context = useMutationContext()
  const [ createStudyBibleAssetUploadLink ] = useMutation(createStudyBibleAssetUploadLinkMutation)
  const [ updateUser ] = useMutation(updateUserMutation)

  const ref = useRef()

  const handleZoomChange = useCallback((event, newValue) => setZoom(newValue), [ setZoom ])
  const onDrop = useCallback(([ image ]) => setBaseImage(image), [])

  const onConfirm = useInstanceValuesCallback(
    async () => {

      setSaving(true)
      const canvasScaled = ref.current.getImageScaledToCanvas().toDataURL()
      const result = await fetch(canvasScaled)
      const blob = await result.blob()

      // upload to server and get link

      const { data: { createStudyBibleAssetUploadLink: { uploadUrl, url } } } = await createStudyBibleAssetUploadLink({
        variables: {
          contentType: blob.type,
        },
      })

      await fetch(uploadUrl, {
        method: 'put',
        body: blob,
      })

      const { data: { updateUser: updatedUser } } = await updateUser({
        variables: {
          id: user.id,
          input: {
            image: url,
          },
        },
        context,
      })

      loginCallback(updatedUser)
      onClose()

      setSaving(false)
  
    },
  )

  useEffectAsync(
    () => {
      setZoom(1)
      setBaseImage()
    },
    [ open ],
  )

  return (
    <Dropzone
      onDrop={onDrop}
      noClick
      noKeyboard
      accept={ACCEPT}
    >
      {({ getRootProps, getInputProps, open: openFile }) => (
        <div {...getRootProps()}>

          <ConfirmDialog
            open={open}
            onCancel={onClose}
            onConfirm={onConfirm}
            title={i18n("Change Profile Image")}
            explanation={
              <>

                {!baseImage &&
                  <UploadButtonContainer>

                    <UploadButtonText>
                      {i18n("Drag and drop an image here")}
                    </UploadButtonText>

                    <UploadButtonOr>
                      {i18n("or")}
                    </UploadButtonOr>

                    <Button
                      onClick={openFile}
                      variant="contained"
                      disableElevation
                      color="primary"
                    >
                      {i18n("Choose a File")}
                    </Button>

                  </UploadButtonContainer>
                }

                {!!baseImage &&
                  <>

                    <AvatarEditor
                      className="dark-mode-exempt"
                      ref={ref}
                      image={baseImage}
                      width={250}
                      height={250}
                      border={50}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={zoom}
                      rotate={0}
                      borderRadius={125}
                    />

                    <SliderContainer>
                      <SliderLabel>
                        {i18n("Zoom")}
                      </SliderLabel>
                      <Slider
                        value={zoom}
                        min={1}
                        max={10}
                        step={.1}
                        onChange={handleZoomChange}
                      />
                    </SliderContainer>

                  </>
                }

              </>
            }
            confirmButtonLabel={i18n("Save")}
            // color="primary"
            loading={saving}
            disabled={!baseImage}
          />

          <input {...getInputProps()} />

        </div>
      )}

    </Dropzone>
  )
}

export default memo(AvatarEditorDialog)