import { memo } from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(255 255 255/${({ $bgOpacity=1 }) => $bgOpacity});
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loading = ({
  className,
  bgOpacity,
  goSetPopperInfo,  // this is here just to prevent it from causing a warning
  ...otherProps
}) => {
  return (
    <Container className={className} $bgOpacity={bgOpacity}>
      <CircularProgress {...otherProps} />
    </Container>
  )
}

export default memo(Loading)
