import { memo } from 'react'
import styled from 'styled-components'

import useFormattedSearchValue from '../../hooks/useFormattedSearchValue'

const Container = styled.div`
  position: relative;
  background: white;
  line-height: 52px;
  font-size: 16px;
  color: black;
  padding: 3px 14px;
  min-height: 58px;
  user-select: none;
  cursor: pointer;

  ${({ $selected }) => !$selected ? `` : `
    background: rgb(0 0 0/.08);
  `}

  ${({ $doNotShowHover }) => $doNotShowHover ? `` : `
    &:hover {
      background: rgb(0 0 0/.12);
    }
  `}

`

const HistoryContents = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  padding: 3px 14px;
`

const SearchSuggestion = ({
  selected,
  suggestion,
  doNotShowHover,
  onClick,
  idx,
}) => {

  const formattedValue = useFormattedSearchValue({ ...suggestion, isSuggestion: true })

  return (
    <Container
      $selected={selected}
      $doNotShowHover={doNotShowHover}
      onClick={onClick}
      data-idx={idx}
    >

      <HistoryContents>
        {formattedValue}
      </HistoryContents>

      {/* Decided to not expand width for ANY suggestions, but keeping what I previously had below just in case I change my mind. */}

      {/* {[ `search-history`, `project-search-history` ].includes(suggestion.from) &&
        <HistoryContents>
          {formattedValue}
        </HistoryContents>
      }

      {![ `search-history`, `project-search-history` ].includes(suggestion.from) && formattedValue} */}

    </Container>
  )
}

export default memo(SearchSuggestion)