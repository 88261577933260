import { useApolloClient, useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import { getLocFromRef } from '@bibletags/bibletags-versification'

import { cloneObj } from '../utils/misc'
import { defaultExpectedUserUpdate, recordExpectedResponseData } from './useGoUpdateTable'
// import { pushUndoRedoItem } from './useGoUndo'
import useMutationContext from './useMutationContext'
import useInstanceValue from './useInstanceValue'
import useInstanceValuesCallback from './useInstanceValuesCallback'
import { useContext } from 'react'
import { LoggedInUserContext } from '../context/LoggedInUser'

import updateHighlightsMutation from '../graphql/mutations/updateHighlights'
import deleteHighlightsMutation from '../graphql/mutations/deleteHighlights'
import highlightsQuery from '../graphql/queries/highlights'

export const MAX_HIGHLIGHTS_PER_CHAPTER = 50

export const getDefaultHighlight = userId => () => ({
  __typename: `Highlight`,
  note: null,
  shared: false,
  userId,
})

export const getChapterHighlightsVariables = ({ versionId, bookId, chapter, loc }) => ({
  query: `versionId:"${versionId}" ${(loc || getLocFromRef({ bookId, chapter, verse: 0 })).slice(0,-3)}`,
  limit: MAX_HIGHLIGHTS_PER_CHAPTER,
})

const useGoUpdateHighlights = ({
  onUpdate,
  onDelete,
  undoRedoStack=`undo`,
}) => {

  const [ updateHighlights, updateHighlightsResult ] = useMutation(updateHighlightsMutation)
  const [ deleteHighlights, deleteHighlightsResult ] = useMutation(deleteHighlightsMutation)

  const context = useMutationContext()
  const getContext = useInstanceValue(context)
  const client = useApolloClient()

  const user = useContext(LoggedInUserContext)

  const goUpdateHighlights = useInstanceValuesCallback(
    (doUpdateByKeyOrUpdateObjs, highlights, { savedAt }={}) => {

      if(!user.id) return

      const isNew = !highlights
      const now = savedAt || Date.now()
      highlights = highlights || Array(doUpdateByKeyOrUpdateObjs.length).fill().map(getDefaultHighlight(user.id))

      const updates = (
        doUpdateByKeyOrUpdateObjs instanceof Array
          ? (
            doUpdateByKeyOrUpdateObjs.map(({ savedAt, __typename, ...updateObj }, idx) => ({
              id: highlights[idx].id || uuidv4(),
              ...((highlights[idx] || {}).createdAt ? {} : { createdAt: now }),
              savedAt: now,
              ...updateObj,
            }))
          )
          : (
            highlights.map(highlight => {
              const updateObj = {
                id: highlight.id || uuidv4(),
                ...(highlight.createdAt ? {} : { createdAt: now }),
                savedAt: now
              }
    
              Object.keys(doUpdateByKeyOrUpdateObjs).forEach(key => {
                updateObj[key] = doUpdateByKeyOrUpdateObjs[key](highlight[key])
              })
    
              return updateObj
            })
          )
      )

      if(updates.length !== highlights.length) throw new Error(`Invalid call to goUpdateHighlights: doUpdateByKeyOrUpdateObjs and highlights are of different lengths.`)
      if(highlights.length === 0) return []

      const newHighlights = cloneObj(highlights).map((highlight, idx) => ({
        ...highlight,
        ...updates[idx],
      }))

      // if([ `undo`, `redo` ].includes(undoRedoStack)) {
      //   if(isNew) {
      //     pushUndoRedoItem({
      //       type: undoRedoStack,
      //       action: `deleteHighlights`,
      //       data: cloneObj(newHighlights),
      //     })
      //   } else {
      //     const undoUpdates = updates.map((updateObj, idx) => {
      //       const undoUpdateObj = {}
      //       Object.keys(updateObj).forEach(key => {
      //         if(![ `savedAt`, `id` ].includes(key)) {
      //           undoUpdateObj[key] = highlights[idx][key]
      //         }
      //       })
      //       return undoUpdateObj
      //     })
      //     pushUndoRedoItem({
      //       type: undoRedoStack,
      //       action: `updateHighlights`,
      //       data: cloneObj(newHighlights),
      //       updateObjs: cloneObj(undoUpdates),
      //     })
      //   }
      // }

      const variables = {
        input: cloneObj(updates),
      }

      const rows = cloneObj(newHighlights)

      const expectedResponseData = {
        ...defaultExpectedUserUpdate,
        highlight: {
          __typename: `HighlightUpdate`,
          deletedIds: [],
          rows,
        },
      }

      updateHighlights({
        variables,
        context: {
          ...getContext(),
          expectedResponse: {
            [`updateHighlights`]: expectedResponseData,
          },
        },
      })

      recordExpectedResponseData({ client, expectedResponseData: expectedResponseData })

      if(isNew) {
        const { versionId, fromLoc: loc } = newHighlights[0]
        const variables = getChapterHighlightsVariables({ versionId, loc })
        const result = client.readQuery({
          query: highlightsQuery,
          variables,
        })
        const data = cloneObj(((result || {}).highlights || {}).highlights || null) || []

        if(data) {
          data.push(
            ...(
              cloneObj(newHighlights)
                .filter(newHighlight => (
                  // Only add if it's not already in the list (which can be caused by an undo + redo)
                  !data.some(({ id }) => id === newHighlight.id)
                ))
            )
          )
          data.sort((a,b) => ((a.createdAt === b.createdAt && a.id < b.id) || a.createdAt < b.createdAt) ? -1 : 1)
          client.writeQuery({
            query: highlightsQuery,
            variables,
            data: {
              highlights: {
                count: data.length,
                highlights: data,
              },
            },
            broadcast: true,
          })
        }
      }

      onUpdate && onUpdate({
        oldData: !isNew ? cloneObj(highlights) : null,
        newData: cloneObj(newHighlights),
        isNew,
      })

      return cloneObj(newHighlights)

    },
  )

  if(updateHighlightsResult.error) {
    // Nothing to do here since it has gone into queuedMutations and will try again when relevant
    console.error(`updateHighlights.error`, updateHighlightsResult.error)
  }

  const goDeleteHighlights = useInstanceValuesCallback(
    (highlights, { savedAt }={}) => {

      const now = savedAt || Date.now()

      if(highlights.length === 0) return now

      // if([ `undo`, `redo` ].includes(undoRedoStack)) {
      //   const updateObjs = cloneObj(highlights)
      //   pushUndoRedoItem({
      //     type: undoRedoStack,
      //     action: `updateHighlights`,
      //     updateObjs,
      //   })
      // }

      const expectedResponseData = {
        ...defaultExpectedUserUpdate,
        highlight: {
          __typename: `HighlightUpdate`,
          deletedIds: highlights.map(({ id }) => id),
          rows: [],
        },
      }

      deleteHighlights({
        variables: {
          ids: highlights.map(({ id }) => id),
          savedAt: now,
        },
        context: {
          ...getContext(),
          expectedResponse: {
            [`deleteHighlights`]: expectedResponseData,
          },
        },
      })

      recordExpectedResponseData({ client, expectedResponseData: expectedResponseData })

      onDelete && onDelete({ ids: highlights.map(({ id }) => id) })

      return now

    },
  )

  if(deleteHighlightsResult.error) {
    // Nothing to do here since it has gone into queuedMutations and will try again when relevant
    console.error(`deleteHighlights.error`, deleteHighlightsResult.error)
  }

  return [
    goUpdateHighlights,
    goDeleteHighlights,
  ]
}

export default useGoUpdateHighlights