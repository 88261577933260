import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'

import useModalAnchor from '../../../hooks/useModalAnchor'
import useEffectAsync from '../../../hooks/useEffectAsync'
import SelectProjectAndModulePopover from '../shared/SelectProjectAndModulePopover'

const Container = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.palette.grey[400]};
  background-color: ${({ theme }) => theme.palette.grey[100]}80;
  border-radius: 6px;
`

const Message = styled.div`
  font-size: 20px;
  font-weight: 200;
`

const StudyModuleInNotesFindModule = ({
  includeNotFoundMessage,
  updateSettings,
  projectId,
  setPopoverComponent,
  blockKey,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  useEffectAsync(
    () => {
      setPopoverComponent({
        blockKey,
        popoverComponent: (
          <SelectProjectAndModulePopover
            key={blockKey}
            anchorEl={anchorEl}
            onClose={closeModal}
            onSelection={updateSettings}
            initialProjectId={projectId}
          />
        ),
      })
    },
    [ setPopoverComponent, projectId, anchorEl, closeModal, updateSettings ],
  )

  return (
    <Container>

      {includeNotFoundMessage &&
        <Message>
          {i18n("Module not found.")}
        </Message>
      }

      <Button
        onClick={openModal}
        variant="contained"
        disableElevation
      >
        {i18n("Choose a module")}
      </Button>

    </Container>
  )
}


export default memo(StudyModuleInNotesFindModule)