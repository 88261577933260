const keyDownSlash = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    setSearchBarOpen,
  } = params

  if(modifierKey && !shiftKey) {
    event.preventDefault()
    setSearchBarOpen(true)
  }

}

export default keyDownSlash