import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { KeyboardDatePicker } from '@material-ui/pickers'

import FadedLoading from '../../common/FadedLoading'

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  align-self: center;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
`

const ReportButton = styled(Button)`
  margin-top: 10px;
  position: relative;
`

const StylelessA = styled.a`
  text-decoration: none;
  color: inherit;
`

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  margin-top: 5px;
  text-align: center;
`

const ReportsTab = () => {

  const [ lineItemPurchaseReportInfo, setLineItemPurchaseReportInfo ] = useState({ date: null })

  const downloadLineItemPurchaseReport = useCallback(
    async () => {

      try {

        setLineItemPurchaseReportInfo({
          ...lineItemPurchaseReportInfo,
          fetching: true,
          error: null,
        })

        const lineItemPurchaseReportStartTime = Date.parse(`${lineItemPurchaseReportInfo.date.getFullYear()}-${`0${lineItemPurchaseReportInfo.date.getMonth()+1}`.slice(-2)}-01T00:00:00.000-06:00`)
        const lineItemPurchaseReportEndDate = new Date(lineItemPurchaseReportStartTime)
        lineItemPurchaseReportEndDate.setMonth(lineItemPurchaseReportEndDate.getMonth()+1)
        const lineItemPurchaseReportEndTime = lineItemPurchaseReportEndDate.getTime()
        const oneDay = 1000*60*60*24
        let data = ``
        const promises = []

        for(let time=lineItemPurchaseReportStartTime; time<lineItemPurchaseReportEndTime; time+=oneDay) {

          promises.push((async () => {  // eslint-disable-line no-loop-func

            const startTime = time
            const endTime = Math.min(time+oneDay, lineItemPurchaseReportEndTime)

            const response = await fetch(
              `${process.env.REACT_APP_BIBLEARC_DATA_URI.replace(/graphql$/, '')}reports/line-items`,
              {
                headers: {  // needed for authentication
                  'x-access-token': window.sessionSyncAuth.getAccessToken(),
                  'Content-Type': 'application/json',
                },
                method: 'post',
                body: JSON.stringify({
                  from: startTime,
                  to: endTime,
                }),
              },
            )

            const thisDaysData = await response.text()
            if(response.status !== 200) throw new Error(`Error fetching data`)
            if(!/^No data found/.test(thisDaysData)) {
              data += data ? `\n${((thisDaysData.match(/\n((?:.|\n)*)$/) || [])[1] || ``)}` : thisDaysData
            }

          })())

        }

        await Promise.all(promises)

        if(!data) throw new Error(`No data found`)

        setLineItemPurchaseReportInfo({
          ...lineItemPurchaseReportInfo,
          fetching: false,
          error: null,
          data,
        })
  
      } catch(err) {
        setLineItemPurchaseReportInfo({
          ...lineItemPurchaseReportInfo,
          fetching: false,
          error: err.message || err,
        })  
      }

    },
    [ lineItemPurchaseReportInfo ],
  )

  return (
    <OuterContainer>
      <Container>

        <KeyboardDatePicker
          views={['year', 'month']}
          label="Purchase Report Month"
          minDate={new Date(`2020-01-02`)}  // using the 2nd so as to avoid timezone issues
          maxDate={new Date()}
          value={lineItemPurchaseReportInfo.date}
          onChange={date => setLineItemPurchaseReportInfo({ date })}
          inputProps={{
            disabled: true,
          }}
          KeyboardButtonProps={{
            disabled: !!lineItemPurchaseReportInfo.fetching,
          }}
        />

        {!lineItemPurchaseReportInfo.data &&
          <ReportButton
            variant="contained"
            disableElevation
            fullWidth
            onClick={downloadLineItemPurchaseReport}
            disabled={!!(!lineItemPurchaseReportInfo.date || lineItemPurchaseReportInfo.fetching)}
          >
            Create Line Item Report
            {!!lineItemPurchaseReportInfo.fetching && <FadedLoading size={20} />}
          </ReportButton>
        }

        {!!lineItemPurchaseReportInfo.data &&
          <StylelessA
            href={`data:text/csv;charset=utf-8,${encodeURIComponent(lineItemPurchaseReportInfo.data)}`}
            download={`biblearc_purchase_line_items_${lineItemPurchaseReportInfo.date.toLocaleString("en-US", { month: "long", year: "numeric" }).replace(/ /g, '_')}.csv`}
          >
            <ReportButton
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              onClick={() => setLineItemPurchaseReportInfo({ ...lineItemPurchaseReportInfo, data: null })}
            >
              Save Line Item Report
            </ReportButton>
          </StylelessA>
        }

        <ErrorMessage>
          {lineItemPurchaseReportInfo.error}
        </ErrorMessage>

      </Container>
    </OuterContainer>
  )
}


export default memo(ReportsTab)