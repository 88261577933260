import React, { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Tooltip from '@material-ui/core/Tooltip'

const StyledButtonGroup = styled(ButtonGroup)`
  margin: 0 -5px;
`

const SwitchButton = styled(Button)`
  text-transform: none;
  padding: 2px 6px;
  font-size: 10px;
  border-color: transparent !important;
  border-left-color: rgb(255 255 255/.15) !important;
  font-weight: 500;
  align-self: flex-end;
  min-width: 0;

  &:nth-child(1) {
    border-left-color: transparent !important;
  }

  ${({ $selected, theme }) => $selected ? `` : `
    color: ${theme.palette.grey[500]};
    &:hover {
      color: rgb(255 255 255/.65);
    }
  `}
  
  ${({ $selected }) => !$selected ? `` : `
    color: white !important;
  `}
`

const ApparatusPopperContentCaseToggle = ({
  allCaps,
  toggleAllCaps,
}) => {

  return (
    <Tooltip
      title={i18n("View ancient manuscripts in uppercase letters (as found in the manuscripts themselves) or lowercase letters (easier to read).")}
    >
      <StyledButtonGroup
        size="small"
      >
        <SwitchButton
          $selected={allCaps}
          onClick={toggleAllCaps}
          disabled={allCaps}
        >
          ΑΒΓΔ
        </SwitchButton>
        <SwitchButton
          $selected={!allCaps}
          onClick={toggleAllCaps}
          disabled={!allCaps}
        >
          αβγδ
        </SwitchButton>
      </StyledButtonGroup>
    </Tooltip>
  )
}

export default memo(ApparatusPopperContentCaseToggle)