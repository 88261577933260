import { useCallback, useState } from "react"
import { i18n } from 'inline-i18n'
import copy from 'copy-to-clipboard'
import MenuList from "@material-ui/core/MenuList"
import MenuItem from "@material-ui/core/MenuItem"

import useSetTimeout from "./useSetTimeout"
import useEqualObjsMemo from "./useEqualObjsMemo"
import useModalAnchor from "./useModalAnchor"
import { isAndroid, isIOS } from "../utils/misc"

import NavLinkOrAWithDisable from "../components/common/NavLinkOrAWithDisable"
import OptionsPopover from "../components/common/OptionsPopover"

const useShareOrCopy = ({
  canShare=true,
  shareData={},
  giveShareOnXOption,
  justCopiedOrSharedMS=1500,
}) => {

  const shareDataWithTitleInText = useEqualObjsMemo({
    ...shareData,
    text: `${shareData.title ? `${shareData.title}\n` : ``}${shareData.text}`,
  })

  const willShare = !!(canShare && navigator.canShare && navigator.canShare(shareDataWithTitleInText) && (isAndroid || isIOS))

  const [ justCopiedOrShared, setJustCopied ] = useState(false)
  const [ setJustCopiedTimeout ] = useSetTimeout()
  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const goCopy = useCallback(
    async () => {
      copy(shareDataWithTitleInText.url || shareDataWithTitleInText.text, { format: `text/plain` })
      setJustCopied(true)
      setJustCopiedTimeout(() => {
        setJustCopied(true)
        setJustCopiedTimeout(() => setJustCopied(false), justCopiedOrSharedMS)
      }, 200)
      closeModal()
    },
    [ setJustCopied, setJustCopiedTimeout, shareDataWithTitleInText, justCopiedOrSharedMS, closeModal ],
  )

  const copyOrShare = useCallback(
    async event => {
      if(willShare) {
        try {
          await navigator.share(shareDataWithTitleInText)
        } catch(e) {}
        return
      }

      if(giveShareOnXOption && event) {
        openModal(event)
        return
      }

      goCopy()

    },
    [ goCopy, shareDataWithTitleInText, willShare, giveShareOnXOption, openModal ],
  )

  const copyOptionsComponent = (
    <OptionsPopover
      anchorEl={anchorEl}
      onClose={closeModal}
    >
      <MenuList>
        <NavLinkOrAWithDisable
          to={`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareDataWithTitleInText.url)}&text=${encodeURIComponent(`${shareDataWithTitleInText.text}\n\n`)}`}
        >
          <MenuItem onClick={closeModal}>
            {i18n("Share on X")}
          </MenuItem>
        </NavLinkOrAWithDisable>
        <MenuItem
          onClick={goCopy}
        >
          {i18n("Copy link")}
        </MenuItem>
      </MenuList>
    </OptionsPopover>
  )

  return {
    copyOrShare,
    goCopy,
    willShare,
    justCopiedOrShared,
    copyOptionsComponent,
  }
}

export default useShareOrCopy