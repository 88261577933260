import useShortcuts from './useShortcuts'

import keyDownSlash from '../components/pages/settings/shortcuts/keyDownSlash'

const keyDownFuncs = {
  keyDownSlash,
}

const useSettingsVersionsShortcuts = params => {

  useShortcuts({
    keyDownFuncs,
    ...params,
  })

}

export default useSettingsVersionsShortcuts