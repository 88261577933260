import { memo, useMemo, useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Tooltip from '@material-ui/core/Tooltip'

import useDataQuery from '../../../hooks/useDataQuery'
import { getNewOrdering, sortModules } from '../../../utils/misc'

import OptionsPopover from '../../common/OptionsPopover'
import ProjectsListModules from '../../pages/projects/ProjectsListModules'
import ProjectsListModule from '../../pages/projects/ProjectsListModule'

import projectsQuery from '../../../graphql/queries/projects'

const StyledOptionsPopover = styled(OptionsPopover)`
  .MuiPopover-paper {
    height: 473px;
    max-height: calc(100vh - 20px);
    display: flex;
    flex-direction: column;
  }
`

const StyledList = styled(List)`
  width: 400px;
  max-width: calc(100vw - 20px);
  overflow-y: auto;
  flex: 1;
`

const Explanation = styled.div`
  padding: 10px 20px 5px;
  font-size: 12px;
  width: 400px;
  max-width: calc(100vw - 20px);
`

const ProjectMenuItem = styled(MenuItem)`
  display: flex;
  gap: 15px;
  height: 55px;
  min-height: 55px;
`

const ProjectName = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Untitled = styled.span`
  color: ${({ theme }) => theme.palette.text.hint};
`

const StyledProjectsListModules = styled(ProjectsListModules)`
  width: 160px;
`

const StyledProjectsListModule = styled(ProjectsListModule)`
  font-size: 15px;
  padding: 6px 0;
`

const StyledTextField = styled(TextField)`
  margin: 0 15px;
  width: calc(100% - 30px);
`

const BackButton = styled(Button)`
  margin: 2px 15px 0;
  align-self: flex-start;
`

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  &.MuiTooltip-popper[x-placement="right"] .MuiTooltip-tooltip {
    margin-left: -10px;
  }
`

const SelectProjectAndModulePopover = ({
  anchorEl,
  onClose,
  initialProjectId,
  disabledModuleId,
  disabledProjectId,
  chooseProjectOnly,
  onSelection,
}) => {

  const [ projectId, setProjectId ] = useState(initialProjectId)
  const [ search, setSearch ] = useState(``)

  const {
    projects: projectsAndCount,
  } = useDataQuery({
    projectsQuery,
    variables: {
      query: search.trim(),
      limit: 7,
    },
  })

  const { projects=[] } = projectsAndCount || {}
  const project = projects.find(({ id }) => id === projectId)

  const filteredAndSortedModules = useMemo(
    () => (
      ((project || {}).moduleByProjects || [])
        .filter(({ module: { type, modulePassages } }) => (
          [ 'DISCOURSE', 'PHRASING', 'DIAGRAMMING', 'OUTLINE', 'MARKUP' ].includes(type)
          && modulePassages.length > 0
        ))
        .sort(sortModules)
        .map(({ module }) => module)
    ),
    [ project ],
  )

  const onSearchChange = useCallback(({ target }) => setSearch(target.value), [])

  return (
    <StyledOptionsPopover
      anchorEl={anchorEl}
      onClose={onClose}
      hideArrow
      horizontal="center"
    >

      {!project &&
        <>

          <Explanation>
            {!chooseProjectOnly && i18n("First select a project.")}
          </Explanation>

          <StyledTextField
            placeholder={i18n("Search projects")}
            value={search}
            onChange={onSearchChange}
            variant="outlined"
            size="small"
          />

          <StyledList>
            {projects.map(project => {
              let disabledMessage = ``
              if(project.id === disabledProjectId) {
                disabledMessage = i18n("This is the current project")
              } else if(
                disabledModuleId
                && project.moduleByProjects.some(({ module }) => module.id === disabledModuleId)
              ) {
                disabledMessage = i18n("This project already contains this module")
              }
              return (
                <StyledTooltip
                  key={project.id}
                  title={disabledMessage}
                  placement="right"
                >
                  <div>
                    <ProjectMenuItem
                      disabled={!!disabledMessage}
                      onClick={() => {
                        if(chooseProjectOnly) {
                          const maxOrdering = project.moduleByProjects.reduce((o, { ordering }) => Math.max(o, ordering), -Infinity)
                          const ordering = getNewOrdering(maxOrdering)
                          onClose()
                          requestAnimationFrame(() => {
                            onSelection({
                              project,
                              ordering,
                            })
                          })  
                        } else {
                          setProjectId(project.id)
                        }
                      }}
                    >
                      <ProjectName>
                        {project.name || <Untitled>{i18n("Untitled")}</Untitled>}
                      </ProjectName>
                      <StyledProjectsListModules
                        moduleByProjects={project.moduleByProjects}
                        disableClickableExpand
                      />
                    </ProjectMenuItem>
                  </div>
                </StyledTooltip>
              )
            })}
          </StyledList>

        </>
      }

      {!!project &&
        <>

          <Explanation>
            {project.name ? `“${project.name}”` : <Untitled>{i18n("Untitled")}</Untitled>}
          </Explanation>

          <BackButton
            onClick={() => setProjectId(null)}
            startIcon={<ArrowBackIcon />}
            size="small"
            variant="outlined"
          >
            {i18n("Change Project")}
          </BackButton>

          <StyledList>
            {filteredAndSortedModules.map(module => (
              <MenuItem
                key={module.id}
                onClick={() => {
                  onClose()
                  requestAnimationFrame(() => {
                    onSelection({ moduleId: module.id })
                  })
                }}
              >
                <StyledProjectsListModule
                  module={module}
                />
              </MenuItem>
            ))}
          </StyledList>

        </>
      }

    </StyledOptionsPopover>
  )
}

export default memo(SelectProjectAndModulePopover)