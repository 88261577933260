// Array.prototype.findLastIndex not supported in some older browsers
// https://jsitor.com/osoTOcSoig
if (!Array.prototype.findLastIndex) {
  Array.prototype.findLastIndex = function (callback, thisArg) {  // eslint-disable-line no-extend-native
    for (let i = this.length - 1; i >= 0; i--) {
      if (callback.call(thisArg, this[i], i, this)) return i;
    }
    return -1;
  };
}
// I did the following based on the above
if (!Array.prototype.findLast) {
  Array.prototype.findLast = function (callback, thisArg) {  // eslint-disable-line no-extend-native
    for (let i = this.length - 1; i >= 0; i--) {
      if (callback.call(thisArg, this[i], i, this)) return this[i];
    }
    return undefined;
  };
}

// Array.at function (Safari does not yet support it)
// https://github.com/tc39/proposal-relative-indexing-method#polyfill

if(!Array.prototype.at) {

  function at(n) {
    // ToInteger() abstract op
    n = Math.trunc(n) || 0;
    // Allow negative indexing from the end
    if (n < 0) n += this.length;
    // OOB access is guaranteed to return undefined
    if (n < 0 || n >= this.length) return undefined;
    // Otherwise, this is just normal property access
    return this[n];
  }

  const TypedArray = Reflect.getPrototypeOf(Int8Array);
  for (const C of [Array, String, TypedArray]) {
      Object.defineProperty(C.prototype, "at",
                            { value: at,
                              writable: true,
                              enumerable: false,
                              configurable: true });
  }

}
