import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import TimeAgo from 'react-timeago'

const RelativeTime = ({
  showSeconds=false,
  ...props
 }) => {

  const formatter = useCallback(
    (
      number,
      unit,
      suffix,
      // epochMilliseconds,
      // nextFormatter,
    ) => {

      const number_with_units = (
        number === 1
          ? {
            second: (
              showSeconds
                ? i18n("a second")
                : i18n("less than a minute")
            ),
            minute: i18n("a minute"),
            hour: i18n("an hour"),
            day: i18n("a day"),
            week: i18n("a week"),
            month: i18n("a month"),
            year: i18n("a year"),
          }
          : {
            second: (
              showSeconds
                ? i18n("{{number}} seconds", { number })
                : i18n("less than a minute")
            ),
            minute: i18n("{{number}} minutes", { number }),
            hour: i18n("{{number}} hours", { number }),
            day: i18n("{{number}} days", { number }),
            week: i18n("{{number}} weeks", { number }),
            month: i18n("{{number}} months", { number }),
            year: i18n("{{number}} years", { number }),
          }
      )[unit]

      if(suffix === 'ago') {
        return i18n("{{number_with_units}} ago", { number_with_units })
      } else {  // suffix === "from now"
        return i18n("{{number_with_units}} from now", { number_with_units })
      }

    },
    [ showSeconds ],
  )

  return (
    <TimeAgo
      formatter={formatter}
      {...props}
    />
  )
}

export default memo(RelativeTime)