import { useContext } from 'react'
import { useApolloClient } from '@apollo/client'

import { getHebrewPrefixDefinition } from '../utils/hebrewPrefixAndSuffixInfo'
import { TODO_defaultLanguageId } from '../utils/constants'
import { OfflineSetupStatusContext } from '../context/LocalInfo'
import useLayoutEffectAsync from './useLayoutEffectAsync'
import useEqualObjsMemo from './useEqualObjsMemo'
import useRefState from './useRefState'

import definitionQuery from '../graphql/queries/definition'

const useDefinitions = pieces => {

  const [ definitions, setDefinitions, getDefinitions ] = useRefState({})
  const [ loadingStrongs, setLoadingStrongs, getLoadingStrongs ] = useRefState({})

  const languageId = TODO_defaultLanguageId
  const client = useApolloClient()
  const offlineSetupStatus = useContext(OfflineSetupStatusContext)
  const strongs = useEqualObjsMemo([ ...new Set(pieces.map(({ strong }) => strong).filter(Boolean)) ])

  const definitionsAfterThrottle = definitions

  useLayoutEffectAsync(
    () => {

      strongs.forEach(async strong => {
        let [ nakedStrongs ] = (strong || ``).match(/[HG][0-9]{5}/) || []
        let definition
        const id = `${nakedStrongs}-${languageId}`

        if(nakedStrongs) {

          setLoadingStrongs({
            ...getLoadingStrongs(),
            [id]: true,
          })

          const { data={} } = await client.query({
            query: definitionQuery,
            variables: {
              id,
            },
            context: {
              offlineSetupStatus,
            },
          })
          definition = data.definition

        } else {

          const definition = getHebrewPrefixDefinition(((strong || ``).match(/[blkm]/) || [])[0])
          nakedStrongs = (definition || {}).nakedStrongs

        }

        if(definition && nakedStrongs) {
          setDefinitions({
            ...getDefinitions(),
            [nakedStrongs]: definition,
          })
        }

        if(nakedStrongs) {
          setLoadingStrongs({
            ...getLoadingStrongs(),
            [id]: false,
          })
        }

      })
    },
    [ strongs, setLoadingStrongs, getLoadingStrongs ],
  )

  return [
    definitionsAfterThrottle,
    Object.values(loadingStrongs).filter(Boolean).length > 0,  // i.e. loading
  ]

}

export default useDefinitions