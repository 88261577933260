import { memo, useCallback } from 'react'
import styled from 'styled-components'

import useInstanceValue from '../../../hooks/useInstanceValue'
import useNotesShortcuts from '../../../hooks/useNotesShortcuts'

import NotesEditor from './NotesEditor'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;

  ${({ $printOrDownloadInfo }) => !$printOrDownloadInfo ? `` : `
    overflow-x: visible;

    .DraftEditor-root {
      overflow: visible;
    }
  `}
`

const InnerContainer = styled.div`
  width: 1200px;
  max-width: 100vw;
  padding: 20px 20px calc(100vh - 200px) 20px;

  ${({ $printOrDownloadInfo }) => !$printOrDownloadInfo ? `` : `
    padding: 0;
    width: 100%;
    max-width: none;
  `}
`

const SharedNotes = ({
  module,
  projectId,
  goPrintOrDownload,
  printOrDownloadInfo,
  onLoad,
}) => {

  const getModule = useInstanceValue(module)

  const goPrintOrDownloadWithBaseParams = useCallback(
    params => {
      goPrintOrDownload({
        module,
        projectId,
        ...params,
      })
    },
    [ goPrintOrDownload, module, projectId ],
  )

  useNotesShortcuts({
    getModule,
    goPrintOrDownload: goPrintOrDownloadWithBaseParams,
    noneditableViewingMode: `shared-page`,
  })

  return (
    <Container $printOrDownloadInfo={printOrDownloadInfo}>
      <InnerContainer $printOrDownloadInfo={printOrDownloadInfo}>
        <NotesEditor
          module={module}
          isShared
          goPrintOrDownload={goPrintOrDownloadWithBaseParams}
          onLoad={onLoad}
          disableLegacyModules={!!printOrDownloadInfo}
        />
      </InnerContainer>
    </Container>
  )

}

export default memo(SharedNotes)