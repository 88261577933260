import { memo, useCallback } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'

import {
  MODULE_TYPE_COLOR_MAP,
} from '../../../utils/constants'
import useModalAnchor from '../../../hooks/useModalAnchor'
import useVersionInfo from '../../../hooks/useVersionInfo'

import ModuleOptionsPopover from "./ModuleOptionsPopover"
import PassageRef from '../../common/PassageRef'

const StyledButton = styled(Button)`
  position: absolute;
  top: 10px;
  left: -3px;
  height: 38px;
  text-transform: none;
  font-size: 20px;
  z-index: 6;
  max-width: calc(100% - 145px);
  color: ${({ theme }) => theme.palette.text.primary} !important;

  .MuiButton-label > span:first-child {
    overflow: hidden;
  }
`

const ModuleTitle = styled.span`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 3px;
  font-weight: 400;
`

const VersionAbbr = styled.span`
  display: inline-block;
  white-space: nowrap;
  margin-left: 8px;
  font-size: 13px;
  font-weight: 300;
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${({ theme }) => theme.palette.grey[600]};
`

const SmallPeopleAltIcon = styled(PeopleAltIcon)`
  height: 14px;
  color: ${({ theme, $moduleType }) => theme.palette[MODULE_TYPE_COLOR_MAP[$moduleType]].dark};
`

const ModuleTitleButton = ({
  module,
  goPrintOrDownload,
  goSketch,
  titleOverride,
  showVersionAbbr,
  width,
  goSetPopperInfo,  // this is here just to prevent it from causing a warning
  disabled,
  ...otherProps
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const goPrintOrDownloadWithCloseModal = useCallback(
    info => {
      closeModal()
      goPrintOrDownload(info)
    },
    [ closeModal, goPrintOrDownload ],
  )

  const goSketchWithCloseModal = useCallback(
    info => {
      closeModal()
      goSketch(info)
    },
    [ closeModal, goSketch ],
  )

  const { modulePassages } = module
  const { fromLoc, toLoc, info } = modulePassages[0]
  const { versionId } = info

  const { safeVersionAbbr } = useVersionInfo(versionId)

  return (
    <>

      <StyledButton
        onMouseDown={openModal}  // instead of onClick so that restored focus doesn't come back to this button
        tabIndex={-1}
        disabled={disabled}
      >

        <ModuleTitle>
          {titleOverride ||
            <PassageRef
              fromLoc={fromLoc}
              toLoc={toLoc}
              convertToVersionId={versionId}
              abbreviated={width < 480}
            />
          }
          {showVersionAbbr &&
            <VersionAbbr>
              {safeVersionAbbr}
            </VersionAbbr>
          }
        </ModuleTitle>

        {module.shared &&
          <SmallPeopleAltIcon $moduleType={module.type} />
        }

        {!disabled && <StyledExpandMoreIcon />}

      </StyledButton>

      {!disabled &&
        <ModuleOptionsPopover
          anchorEl={anchorEl}
          onClose={closeModal}
          module={module}
          goPrintOrDownload={goPrintOrDownloadWithCloseModal}
          goSketch={goSketchWithCloseModal}
          {...otherProps}
        />
      }

    </>
  )
}

const StyledModuleTitleButton = styled(memo(ModuleTitleButton))`

  ${({ $adjustmentType }) => !$adjustmentType ? `` : `

    .MuiPaper-root {
      background: transparent !important;
    }

    .module-options-popover-top,
    .module-options-popover-columns,
    .module-options-popover-actions,
    .phrase-options-comparison-switch,
    .module-options-popover-settings > div:first-child,
    .module-options-popover-settings > div > :not(.module-settings-${$adjustmentType}) {
      visibility: hidden;
    }

  `}

`
export default StyledModuleTitleButton