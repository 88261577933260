import { useUpdateEffect } from 'react-use'

const useUpdateEffectAsync = (func, deps) => {

  useUpdateEffect(
    () => {
      func()
    },
    deps,  // eslint-disable-line react-hooks/exhaustive-deps
  )

}

export default useUpdateEffectAsync
