import { memo } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { i18n } from 'inline-i18n'

import useModalAnchor from '../../../hooks/useModalAnchor'
import ConfirmDialog from '../../common/ConfirmDialog'

const StyledHelpOutlineIcon = styled(HelpOutlineIcon)`
  height: 18px;
`

const StyledConfirmDialog = styled(ConfirmDialog)`
  .MuiDialog-paper {
    max-width: 648px;
  }
`

const Gif = styled.img`
  width: 600px;
  max-width: 100%;
`

const HelpIconButton = ({
  gifSrc,
  courseButtonLabel,
  courseHref,
  ...otherProps
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  return (
    <>

      <IconButton
        size="small"
        onClick={openModal}
      >
        <StyledHelpOutlineIcon />
      </IconButton>

      <StyledConfirmDialog
        open={!!anchorEl}
        onClose={closeModal}
        onConfirm={closeModal}
        confirmButtonLabel={i18n("Close")}
        explanation={<Gif src={gifSrc} />}
        cancelHref={courseHref}
        cancelButtonLabel={courseButtonLabel}
        {...otherProps}
      />

    </>
  )
}

export default memo(HelpIconButton)