import { memo, useCallback, useState, useMemo, useRef, useContext } from 'react'
import { useLocation, useHistory } from "react-router-dom"
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import UndoIcon from '@material-ui/icons/Undo'
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import ContainerWithPassagePopper from '../passage/ContainerWithPassagePopper'

import useInstanceValue from '../../hooks/useInstanceValue'
import useAutoCompleteSuggestions from '../../hooks/useAutoCompleteSuggestions'
import useRecentSearchManager from '../../hooks/useRecentSearchManager'
import useFocus from '../../hooks/useFocus'
import useOpenPassage from '../../hooks/useOpenPassage'
import useSetTimeout from '../../hooks/useSetTimeout'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import { stopPropagationEvent } from '../../utils/misc'
import { IS_EMBED, MINIMUM_MEDIUM_WIDTH } from '../../utils/constants'
import { getEmbedMode } from '../../graphql/links/embedLink'
import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'

import LinkIconButton from '../common/LinkIconButton'
import SearchTextField from './SearchTextField'
import SearchSuggestions from './SearchSuggestions'
import SearchResults from './SearchResults'
import FadedLoading from '../common/FadedLoading'
import JoinTheMailingListButton from '../common/JoinTheMailingListButton'

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SearchTextFieldContainer = styled.div`
  min-width: 400px;
  max-width: 100vw;
  min-height: 59px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;

  ${({ $isEmbedSearch }) => !$isEmbedSearch ? `` : `
    min-width: 0;
    width: 100vw;
    flex: 1;
  `}

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH-1}px) {
    min-width: 0;
    width: 100vw;
  }
`

const StyledLinkIconButton = styled(LinkIconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`

const StyledJoinTheMailingListButton = styled(JoinTheMailingListButton)`
  display: none;
`

const SearchModal = ({
  setCurrentSearch,
  searchBarOpen,
  setSearchBarOpen,
  openDisplaySettings,
  openAlerts,
  projectId,
  isEmbedSearch,
  ...otherProps
}) => {

  const location = useLocation()
  const history = useHistory()
  
  const { executeSearch=false } = location.state || {}
  
  const getLocation = useInstanceValue(location)
  
  const searchText = decodeURIComponent((location.hash.match(/^#search=(.*)$/) || [])[1] || "")
  const [ lastSearch, setLastSearch ] = useState(searchText)
  const [ searchTextInComposition, setSearchTextInComposition ] = useState(searchText)
  const showSearchResults = !!searchText
  const { addToRecentSearches } = useRecentSearchManager()
  const disabled = IS_EMBED && getEmbedMode() === `frozen`
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  const [ navigatingToLogin, setNavigatingToLogin ] = useState(false)
  const [ joinMailingListConfirmStatus, setJoinMailingListConfirmStatus ] = useState(false)

  const [ setClearTimeout ] = useSetTimeout()

  const { autoCompleteSuggestions, tabAddition } = useAutoCompleteSuggestions({
    searchTextInComposition,
    projectId,
  })
  const getAutoCompleteSuggestions = useInstanceValue(autoCompleteSuggestions)

  const [ rawSelectedIdx, setRawSelectedIdx ] = useState(0)
  const selectedIdx = Math.max(Math.min(rawSelectedIdx, autoCompleteSuggestions.length - 1), 0)
  const getRawSelectedIdx = useInstanceValue(rawSelectedIdx)
  const getSelectedIdx = useInstanceValue(selectedIdx)

  const [ inputRef, setInputFocus ] = useFocus()
  const inputContainerRef = useRef()

  const { openPassage } = useOpenPassage()

  const clearCurrentSearch = useCallback(
    () => {
      setCurrentSearch("")
      if(/^#search=/.test(getLocation().hash)) {
        history.replace({ hash: `` })
      }
    },
    [ setCurrentSearch, getLocation, history ],
  )

  const onChange = useCallback(
    ({ target }) => {
      const newSearchTextInComposition = (
        target.value
          .replace(/#h([0-9]{1,5})/g, '#H$1')
          .replace(/#g([0-9]{1,5})/g, '#G$1')
          .replace(/[“”]/g, '"')
          .replace(/[…]/g, '...')
          .replace(/([^ ])\.\.\./g, '$1 ...')
          .replace(/\.\.\.([^ ])/g, '... $1')
          .replace(/^ /g, '')
      )

      const differenceInLength = newSearchTextInComposition.length - target.value.length
      const caretStart = target.selectionStart + differenceInLength
      const caretEnd = target.selectionEnd + differenceInLength

      setSearchTextInComposition(newSearchTextInComposition)

      getRawSelectedIdx() !== 0 && setRawSelectedIdx(0)
      searchText && clearCurrentSearch()

      requestAnimationFrame(() => {
        target.setSelectionRange(caretStart, caretEnd)  // prevent the cursor from jumping
        if(
          target.selectionStart === newSearchTextInComposition.length
          && inputContainerRef.current
        ) {  // cursor at very end
          inputContainerRef.current.scrollTo({
            left: inputContainerRef.current.scrollWidth,
            behavior: 'smooth',
          })
        }
      })
    },
    [ inputContainerRef, getRawSelectedIdx, searchText, clearCurrentSearch ],
  )

  const clearSearchTextInComposition = useCallback(
    () => {
      setSearchTextInComposition("")
      setRawSelectedIdx(0)
      clearCurrentSearch()
      setInputFocus()
    },
    [ clearCurrentSearch, setInputFocus ],
  )

  const closeSearchBar = useCallback(
    () => {
      if(isEmbedSearch) return
      setSearchBarOpen(false)
      setLastSearch("")
    },
    [ setSearchBarOpen, isEmbedSearch ],
  )

  const closeAndClearSearch = useCallback(
    () => {
      if(isEmbedSearch) return
      setSearchBarOpen(false)
      setClearTimeout(() => {
        setLastSearch("")
        setSearchTextInComposition("")
        setRawSelectedIdx(0)
        clearCurrentSearch()
      }, 350)
    },
    [ setSearchBarOpen, setClearTimeout, clearCurrentSearch, isEmbedSearch ],
  )

  const setSearchText = useCallback(
    searchText => {
      history.replace({
        hash: `#search=${encodeURIComponent(searchText)}`,
        state: {
          executeSearch: true,
        },
      })
    },
    [ history ],
  )

  const autoFocus = useRef(true)

  useLayoutEffectAsync(
    () => {
      if(executeSearch && searchText) {

        setSearchTextInComposition(searchText)
        setRawSelectedIdx(0)
        setCurrentSearch(searchText)
        setLastSearch(searchText)
        setSearchBarOpen && setSearchBarOpen(true)

        const location = getLocation()
        const state = location.state || {}
        delete state.executeSearch
        history.replace({
          ...location,
          state,
        })

        autoFocus.current = false
        setTimeout(() => {
          autoFocus.current = true
        })

      }
    },
    [ executeSearch, searchText ],
  )

  const goBackToLastSearch = useCallback(
    () => {
      setSearchText(lastSearch)
      setInputFocus()
    },
    [ setSearchText, lastSearch, setInputFocus ],
  )

  const doMenuSearchItemAction = useCallback(
    action => {
      switch(action) {  // eslint-disable-line default-case
        case 'join-mailing-list': {
          setJoinMailingListConfirmStatus(`show`)
          return true
        }
        case 'open-display-settings': {
          openDisplaySettings()
          return true
        }
        case 'open-alerts': {
          openAlerts()
          return true
        }
        case 'sign-in': {
          setNavigatingToLogin(true)
          window.sessionSyncAuth.logIn()
          return true
        }
        case 'manage-account': {
          setNavigatingToLogin(true)
          window.sessionSyncAuth.updateAccount()
          return true
        }
        case 'log-out': {
          window.sessionSyncAuth.logOut()
          return true
        }
      }
    },
    [ setJoinMailingListConfirmStatus, setNavigatingToLogin, openDisplaySettings, openAlerts ],
  )

  const selectSuggestionIdx = useCallback(
    idx => {
      const autoCompleteSuggestion = getAutoCompleteSuggestions()[idx]
      let { action, suggestedQuery, path, url, refs, versionId } = autoCompleteSuggestion
      if(action) {
        closeAndClearSearch()
        switch(action) {
          case 'open': {
            addToRecentSearches(autoCompleteSuggestion)
            if(
              channelIdInPWA
              && /^\/(?:message|map|settings|tag|version)(?:\/|$)/.test(path)
            ) {
              path = `/church/${channelIdInPWA}${path}`
            }
            history.push(path)
            break
          }
          case 'open-new-tab': {
            addToRecentSearches(autoCompleteSuggestion)
            window.open(url, '_blank')
            break
          }
          case 'read': {
            addToRecentSearches(autoCompleteSuggestion)
            openPassage({ refs, versionId })
            break
          }
          default: {
            doMenuSearchItemAction(action)
            break
          }
        }
      } else {
        setSearchText(
          suggestedQuery
            .replace(/  +/g, ' ')
            .replace(/^ /g, '')      
        )
      }
      setRawSelectedIdx(0)
    },
    [ getAutoCompleteSuggestions, history, openPassage, setSearchText, closeAndClearSearch, addToRecentSearches, doMenuSearchItemAction, channelIdInPWA ],
  )

  const onClick = useCallback(
    ({ currentTarget }) => {
      selectSuggestionIdx(parseInt(currentTarget.getAttribute('data-idx'), 10))
    },
    [ selectSuggestionIdx ],
  )

  const checkForSpecialChars = useCallback(
    event => {
      const suggestionsLength = getAutoCompleteSuggestions().length
      switch(event.key) {  // eslint-disable-line default-case
        case 'Enter': {
          event.preventDefault()
          event.target.blur()
          if(suggestionsLength === 0) return
          selectSuggestionIdx(getSelectedIdx())
          break
        }
        case 'ArrowDown': {
          event.preventDefault()
          setRawSelectedIdx((getSelectedIdx() + 1) % suggestionsLength)
          break
        }
        case 'ArrowUp': {
          event.preventDefault()
          setRawSelectedIdx((getSelectedIdx() - 1 + suggestionsLength) % suggestionsLength)
          break
        }
        case 'Tab': {
          event.preventDefault()
          if(suggestionsLength === 0) return
          let { from, suggestedQuery } = getAutoCompleteSuggestions()[getSelectedIdx()]
          if([ `look-up`, `translation-look-up`, `grammar` ].includes(from)) {
            suggestedQuery = suggestedQuery.replace(/[")]+$/, '')
          }
          setSearchTextInComposition(`${suggestedQuery} `)
          setRawSelectedIdx(0)
          break
        }
      }
    },
    [ selectSuggestionIdx, getSelectedIdx, getAutoCompleteSuggestions ],
  )

  let ButtonIcon = UndoIcon
  let buttonAction = goBackToLastSearch

  if(showSearchResults || !lastSearch || !searchTextInComposition) {
    ButtonIcon = CloseIcon

    if(searchTextInComposition) {
      buttonAction = clearSearchTextInComposition
    } else {
      buttonAction = closeSearchBar
    }
  }

  const button = useMemo(
    () => (
      disabled
        ? null
        : (
          <StyledLinkIconButton
            onClick={buttonAction}
            disabled={isEmbedSearch && buttonAction === closeSearchBar}
          >
            <ButtonIcon />
          </StyledLinkIconButton>
        )
    ),
    [ disabled, ButtonIcon, buttonAction, isEmbedSearch, closeSearchBar ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onClose = useCallback(
    (event, reason) => {
      if(reason === 'escapeKeyDown') {
        buttonAction()
      } else {
        closeSearchBar()
        setClearTimeout(clearSearchTextInComposition, 350)
      }
    },
    [ buttonAction, setClearTimeout, clearSearchTextInComposition, closeSearchBar ],
  )

  let value = searchTextInComposition
  if(selectedIdx > 0) {
    value = autoCompleteSuggestions[selectedIdx].suggestedQuery
  }

  return (
    <>
      <Modal
        {...otherProps}
        open={searchBarOpen}
        onClose={onClose}
        closeAfterTransition
        disableRestoreFocus={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Fade in={searchBarOpen} onClick={onClose}>
          <Container className="SearchModal-Container">

            <SearchTextFieldContainer
              className="dark-mode-box-shadow-lite"
              onClick={stopPropagationEvent}
              $isEmbedSearch={isEmbedSearch}
            >

              <SearchTextField
                {...(autoCompleteSuggestions[selectedIdx] || {})}
                value={value}
                tabAddition={selectedIdx === 0 ? tabAddition : ``}
                hasError={autoCompleteSuggestions.length === 0 && !/^[("]*$/.test(value.trim())}
                onChange={onChange}
                onKeyDown={checkForSpecialChars}
                button={button}
                inputContainerRef={inputContainerRef}
                inputRef={inputRef}
                autoFocus={autoFocus.current}
                disabled={disabled}
              />

              {!showSearchResults &&
                <SearchSuggestions
                  autoCompleteSuggestions={autoCompleteSuggestions}
                  selectedIdx={selectedIdx}
                  userHasEnteredText={!!value}
                  onClick={onClick}
                  isEmbedSearch={isEmbedSearch}
                />
              }

            </SearchTextFieldContainer>

            {showSearchResults &&
              <ContainerWithPassagePopper
                onTopOfAll
                closePopperOnChangeText={searchText}
                onClick={stopPropagationEvent}
              >
                <SearchResults
                  searchText={searchText}
                  closeAndClearSearch={closeAndClearSearch}
                  doMenuSearchItemAction={doMenuSearchItemAction}
                  isEmbedSearch={isEmbedSearch}
                  disabled={disabled}
                />
              </ContainerWithPassagePopper>
            }

          </Container>
        </Fade>
      </Modal>

      <StyledJoinTheMailingListButton
        confirmStatus={joinMailingListConfirmStatus}
        setConfirmStatus={setJoinMailingListConfirmStatus}
      />

      {navigatingToLogin && <FadedLoading />}

    </>
  )
}

export default memo(SearchModal)