const keyDownSlash = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    focusSearchInput,
  } = params

  if(!modifierKey && !shiftKey) {
    event.preventDefault()
    focusSearchInput()
  }

}

export default keyDownSlash