import { useCallback, useRef, useEffect } from 'react'
import useSetTimeout from './useSetTimeout'
import useInstanceValue from './useInstanceValue'

// Will call immediately the first time, then throttle; a change to callback or call to the flush function will immediately call any queued calls
// For saving text, call queueCall on every change, and flush on blur or unmount
const useThrottleCallback = (callback, milliseconds=2000) => {

  const waiting = useRef(false)
  const params = useRef(null)
  const [ setCallTimeout, clearCallTimeout ] = useSetTimeout()
  const getCallback = useInstanceValue(callback)

  const tryToRunCall = useCallback(
    () => {
      if(!params.current) return
      if(waiting.current) return

      getCallback()(...params.current)
      params.current = null
      waiting.current = true
      setCallTimeout(
        () => {
          waiting.current = false
          tryToRunCall()
        },
        milliseconds,
      )
    },
    [ getCallback, milliseconds, setCallTimeout ],
  )

  const queueCall = useCallback(
    (...prms) => {
      params.current = prms
      tryToRunCall()
    },
    [ tryToRunCall ],
  )

  const flush = useCallback(
    () => {
      clearCallTimeout()
      waiting.current = false
      tryToRunCall()
    },
    [ clearCallTimeout, tryToRunCall ],
  )

  useEffect(flush, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return [ queueCall, flush ]
}

export default useThrottleCallback