import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { getPiecesFromUSFM, getTextLanguageId, isRTLText } from '@bibletags/bibletags-ui-helper'
import { useMeasure } from 'react-use'
import { getRefFromLoc, getCorrespondingRefs, getLocFromRef } from '@bibletags/bibletags-versification'

import useBibleSearchResults from '../../hooks/useBibleSearchResults'
import useVersionInfos from '../../hooks/useVersionInfos'
import useVersesPieces from '../../hooks/useVersesPieces'
import useEffectAsync from '../../hooks/useEffectAsync'
import { getOrigVersionInfo } from '../../utils/misc'

import PassageRef from '../common/PassageRef'
import TextContent from '../common/TextContent'
import Loading from '../common/Loading'

const Container = styled.div`
  padding: 0 15px 10px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;

  ${({ $height }) => !$height ? `` : `
    min-height: ${$height}px;
  `}
`

const VersionAbbr = styled.div`
  color: ${({ theme }) => theme.palette.grey[900]};
  margin: 1px 0 0 6px;
  font-size: 11px;

  ${({ $selected, theme }) => $selected ? `` : `
    color: ${theme.palette.grey[400]};

    &:hover {
      cursor: pointer;
      color: ${theme.palette.grey[700]};
    }
  `}
`

const LoadingContainer = styled.div`
  flex: 1;
  position: relative;
`

const PassageToVersionAbbrsSpacer = styled.div`
  width: 3px;
`

const PassageRefContainer = styled.div`
  display: flex;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.grey[500]};
  padding-bottom: 3px;
`

const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  position: relative;
  top: 10px;
  margin-left: 10px;
`

const TextContainer = styled.div`
  direction: ${({ $isRTL }) => $isRTL ? "rtl" : "ltr"};
  padding: 3px 0;
  font-size: 16px;
`

const RowPlaceholder = styled.div`
  height: ${({ $height }) => $height}px;
`

const BibleSearchResultsTranslationRow = ({
  searchText,
  index,
  bookId,
  placeholderHeight,
  setPlaceholderHeight,
  goSetPopperInfo,
  closeAndClearSearch,
}) => {

  const { bibleSearchResult } = useBibleSearchResults({ searchText, index })
  const { originalLoc, versionResults=[] } = bibleSearchResult || {}
  const firstIndexOfVersionResults = versionResults[0] || {}

  const [ selectedIdx, setSelectedIdx ] = useState(0)

  const { versionInfos, versions } = useVersionInfos({ versionIds: versionResults.map(({ versionId }) => versionId) })
  const version = versions[selectedIdx] || {}
  const { wordDividerRegex, languageId: preadjustedLanguageId } = version
  const languageId = getTextLanguageId({ languageId: preadjustedLanguageId, bookId })
  const isRTL = isRTLText({ languageId, bookId })

  let pieces = getPiecesFromUSFM({
    usfm: firstIndexOfVersionResults.usfm || ``,
    inlineMarkersOnly: true,
    wordDividerRegex,
    splitIntoWords: true,
    searchText,
  })

  const refs = (
    (version.id && originalLoc)
       ? (
         getCorrespondingRefs({
          baseVersion: {
            ref: getRefFromLoc(originalLoc),  // Gen 1:1 here for before the result loads
            info: getOrigVersionInfo(),
          },
          lookupVersionInfo: version,
        })
      )
      : []
  )

  const skip = selectedIdx === 0
  const [ versesPieces ] = useVersesPieces({
    locs: refs.map(getLocFromRef),
    versionId: version.id,
    searchText,
    skip,
  })
  if(!skip) {
    pieces = versesPieces
  }

  const [ ref, { width, height } ] = useMeasure()
  const [ heightWithPieces, setHeightWithPieces ] = useState()

  useEffectAsync(
    () => {
      if(pieces && height) setHeightWithPieces(height)
    },
    [ pieces, height ],
  )

  const onClick = useCallback(
    ({ target }) => {
      setSelectedIdx(parseInt(target.closest('[data-idx]').getAttribute('data-idx'), 10))
    },
    [],
  )

  useEffectAsync(
    () => {
      if(index === 0 && heightWithPieces) {
        setPlaceholderHeight(heightWithPieces)
      }
    },
    [ index, setPlaceholderHeight, heightWithPieces ],
  )

  if(!bibleSearchResult) {
    return (
      <RowPlaceholder $height={placeholderHeight} />
    )
  }

  return (
    <Container
      ref={ref}
      $height={!pieces && heightWithPieces}
    >

      <PassageRefContainer
        className="options-popper-no-pointer-events"
      >

        <PassageRef
          // fromLoc={originalLoc}
          // versionId={getOrigVersionInfo()}
          refs={refs}
          versionId={version.id}
        />

        <PassageToVersionAbbrsSpacer />

        {versionInfos.map(({ safeVersionAbbr }, idx) => (
          <VersionAbbr
            key={safeVersionAbbr}
            $selected={idx === selectedIdx}
            onClick={idx === selectedIdx ? null : onClick}
            data-idx={idx}
          >
            {safeVersionAbbr}
          </VersionAbbr>
        ))}

        <Line />

      </PassageRefContainer>

      {!!pieces &&
        <TextContainer
          $isRTL={isRTL}
          data-versionid={version.id}
          className="options-popper-no-pointer-events-children"
        >
          <TextContent
            pieces={pieces}
            versionId={version.id}
            bookId={bookId}
            startChapter={refs[0].chapter}
            width={width}
            goSetPopperInfo={goSetPopperInfo}
            showExpand
            closeAndClearSearch={closeAndClearSearch}
          />
        </TextContainer>
      }

      {!pieces &&
        <LoadingContainer>
          <Loading size={20} />
        </LoadingContainer>
      }


    </Container>
  )
}

export default memo(BibleSearchResultsTranslationRow)