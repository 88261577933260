import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import Header from "../../common/Header"
// import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 20px;
`

const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  font-size: 16px;
  padding-bottom: 50vh;

  h4 {
    font-size: 18px;
  }
`

const Terms = ({ ...props }) => {

  return (
    <>

      <Header {...props} />

      <Container>
        <Content>

          <h4>
            {i18n("Your church’s beliefs must accord with this statement of faith to create and use a Church-Specific Study Bible.")}
          </h4>

          <p>
            {i18n("1.")}
            {` `}
            {i18n("There is one God who created all things, eternally existing in three equally divine Persons: the Father, the Son, and the Holy Spirit.")}
          </p>
          <p>
            {i18n("2.")}
            {` `}
            {i18n("God has most fully and clearly revealed himself and his plan to us in the sixty-six books of the Old and New Testaments, which are without error in the original writings.")}
          </p>
          <p>
            {i18n("3.")}
            {` `}
            {i18n("God created men and women distinct from one another, first-and-foremost expressed in the one-flesh union of marriage between one man and one woman.")}
          </p>
          <p>
            {i18n("4.")}
            {` `}
            {i18n("The office of pastor, elder, or bishop is reserved to qualified men.")}
          </p>
          <p>
            {i18n("5.")}
            {` `}
            {i18n("Man is sinful, alienated from God, and will be condemned to eternal death apart from the gospel.")}
          </p>
          <p>
            {i18n("6.")}
            {` `}
            {i18n("The gospel is the good news of how Jesus Christ died to pay for our sins and rose bodily on the third day to defeat death.")}
          </p>
          <p>
            {i18n("7.")}
            {` `}
            {i18n("Salvation is freely granted to all who put their faith in Christ and his gospel work on their behalf as the sole means by which they are justified before God.")}
          </p>
          <p>
            {i18n("8.")}
            {` `}
            {i18n("Salvation is in no way earned by performing good works, follow God’s law, being baptized, or taking the Lord’s Supper.")}
          </p>
          <p>
            {i18n("9.")}
            {` `}
            {i18n("God has not promised believers perpetual material prosperity in this world, but only in the world to come.")}
          </p>
          <p>
            {i18n("10.")}
            {` `}
            {i18n("Jesus will bodily return to bring his kingdom to consummation.")}
          </p>

        </Content>
      </Container>

    </>
  )
}


export default memo(Terms)