import { memo, forwardRef } from 'react'
import styled from 'styled-components'

import TitleAndAuthorForHeader from '../pages/shared/TitleAndAuthorForHeader'

const Container = styled.div`
  font-size: 6.3px;
  padding: .3em 0 .65em;
  width: ${({ $width }) => $width}px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom}px;
  transform: scale(${({ $scale }) => $scale}) translateY(${({ $translateY }) => $translateY}px);
  transform-origin: 0 0;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: .5em;
`

const StyledTitleAndAuthorForHeader = styled(TitleAndAuthorForHeader)`
  line-height: 1;
`

const LogoImg = styled.img`
  height: 1.75em;
  vertical-align: bottom;
  margin: -1.1em -.3em -1em .4em;
`

const PrintAndPdfHeader = ({
  module,
  ...otherProps
}, ref) => {


  return (
    <Container
      ref={ref}
      {...otherProps}
    >

      <RowContainer>

        <StyledTitleAndAuthorForHeader
          module={module}
          forPdf
        />

        <LogoImg src="/logo.svg" />

      </RowContainer>

    </Container>
  )
}

export default memo(forwardRef(PrintAndPdfHeader))