import { memo, useCallback, useState, useContext } from 'react'
import styled from 'styled-components'
import { i18n, getLocale } from 'inline-i18n'
import { useNetworkState } from 'react-use'
import Button from '@material-ui/core/Button'
import { useMutation } from '@apollo/client'
import { useLocation, useHistory } from "react-router-dom"

import {
  PLANS,
} from '../../../utils/constants'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { getSubscriptionPlanLanguage, getYourSubPathname } from '../../../utils/misc'
import useDataQuery from '../../../hooks/useDataQuery'
import useIsLoggedIn from '../../../hooks/useIsLoggedIn'
import useMutationContext from '../../../hooks/useMutationContext'
import useError from '../../../hooks/useError'

import Header from "../../common/Header"
import Loading from '../../common/Loading'
import FadedLoading from '../../common/FadedLoading'
import SignInButton from '../../common/SignInButton'

import actionCodeQuery from '../../../graphql/queries/actionCode'
import submitActionCodeMutation from '../../../graphql/mutations/submitActionCode'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 80px;
  background-color: white;
`

const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding: 150px 0 300px;
  align-items: center;
`

const AreYouSure = styled.div`
  font-weight: 300;
  margin-bottom: 30px;
`

const Detail = styled.div`
`

const ButtonContainer = styled.div`
  margin: 30px 40px;
`

const GroupSubName = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const GroupSubExpl = styled.div`
  font-size: 14px;
`

const Warning = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  color: ${({ theme }) => theme.palette.tertiary.main};
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 14px;
  max-width: 400px;
`

const WarningOrNoteHeading = styled.span`
  font-weight: bold;
  text-transform: uppercase;
`

const Note = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 15px;
  font-weight: 300;
  margin-top: 20px;
  font-size: 14px;
  max-width: 400px;
`

const ActionCode = ({ ...props }) => {

  const now = Date.now()

  const user = useContext(LoggedInUserContext)
  const isLoggedIn = useIsLoggedIn()
  const { online } = useNetworkState()
  const context = useMutationContext()
  const location = useLocation()
  const history = useHistory()
  const { errorDialog, setError } = useError()

  const id = new URLSearchParams(location.search).get(`id`)

  const { actionCode, loading } = useDataQuery({
    actionCodeQuery,
    variables: {
      id,
    },
    skip: !id,
  })

  const [ submitActionCode ] = useMutation(submitActionCodeMutation)

  const [ submitting, setSubmitting ] = useState(false)

  const goSubmitActionCode = useCallback(
    async () => {

      setSubmitting(true)

      try {
        await submitActionCode({
          variables: {
            id,
          },
          context,
        })
      } catch(err) {
        if(err.message === `no slots left`) {
          setError({ message: i18n("Sorry, but this group subscription does not have any slots remaining.") })
          setSubmitting(false)
          return
        }
        throw err
      }

      setSubmitting(false)
      history.replace(`${getYourSubPathname(actionCode.details.plan)}?subscription-granted`)

    },
    [ submitActionCode, id, context, history, actionCode, setError ],
  )

  let areYouSureMessage = i18n("Invalid URL")
  let buttonLabel, warning, note, needsLogin
  let details = []

  switch((actionCode || {}).action) {  // eslint-disable-line default-case
    case `ACCEPT-GROUP-SUBSCRIPTION-INVITE`: {

      const { usedAt, expiresAt } = actionCode || {}
      const { name, plan } = actionCode.details || {}
      let highestUncanceledStripePlan
      ;((user || {}).activeSubscriptions || []).forEach(({ type, plan, cancelAtPeriodEnd }) => {
        if(
          type === `STRIPE`
          && PLANS.indexOf(plan) > PLANS.indexOf(highestUncanceledStripePlan)
          && !cancelAtPeriodEnd   
        ) {
          highestUncanceledStripePlan = plan
        }
      })
      const latestRelevantCurrentPeriodEndsAt = (
        ((user || {}).activeSubscriptions || [])
          .filter(({ plan: thisPlan }) => PLANS.indexOf(thisPlan) >= PLANS.indexOf(plan))
          .map(({ currentPeriodEndsAt }) => currentPeriodEndsAt)
          .sort((a,b) => b-a)
      )[0]

      if(usedAt) {
        areYouSureMessage = i18n("This group subscription invitation has already been used.")
      } else if(expiresAt < now) {
        areYouSureMessage = i18n("This group subscription invitation is expired.")
      } else if(!isLoggedIn) {
        areYouSureMessage = i18n("You must sign in before you can accept this group subscription invitation.")
        needsLogin = true
      } else {
        areYouSureMessage = i18n("Are you sure you want to accept this group subscription invitation?")
        details = [
          <GroupSubName>
            {name}
          </GroupSubName>,
          <GroupSubExpl>
            {i18n("Joining this group grants you one full year of {{plan_name}}.", { plan_name: getSubscriptionPlanLanguage(plan) })}
          </GroupSubExpl>,
        ]
        buttonLabel = i18n("Accept Invitation")
        if(highestUncanceledStripePlan) {
          warning = i18n("By accepting this invitation, your individually paid subscription to {{plan_name}} will be canceled.", { plan_name: getSubscriptionPlanLanguage(highestUncanceledStripePlan) })
        }
        if(latestRelevantCurrentPeriodEndsAt) {
          note = i18n("Your year will begin {{date}}, once your existing subscription has expired.", { date: new Date(latestRelevantCurrentPeriodEndsAt).toLocaleDateString(getLocale()) })
        }
      }

    }
  }

  return (
    <>

      <Header {...props} />

      <Container>
        <Content>

          <AreYouSure>
            {areYouSureMessage}
          </AreYouSure>

          {details.map((detail, idx) => (
            <Detail key={idx}>
              {detail}
            </Detail>
          ))}

          {!!buttonLabel &&
            <ButtonContainer>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={goSubmitActionCode}
                disabled={!online}
              >
                {buttonLabel}
              </Button>
            </ButtonContainer>
          }

          {!!needsLogin && <SignInButton />}

          {!!warning &&
            <Warning>
              <WarningOrNoteHeading>
                {i18n("Warning")}
              </WarningOrNoteHeading>
              {` `}
              {warning}
            </Warning>
          }

          {!!note &&
            <Note>
              <WarningOrNoteHeading>
                {i18n("Note")}
              </WarningOrNoteHeading>
              {` `}
              {note}
            </Note>
          }

        </Content>
      </Container>

      {errorDialog}
      {loading && <Loading />}
      {submitting && <FadedLoading />}

    </>
  )
}


export default memo(ActionCode)