import { gql } from '@apollo/client'
import module_fields from '../fragments/module_fields'
import modulePassage_fields from '../fragments/modulePassage_fields'

export const getModule = queryName => gql`
  query ${queryName}($id: ID!) {
    ${queryName}(id: $id) {
      ${module_fields}
      modulePassages {
        ${modulePassage_fields}
      }
      moduleByProjects {
        id
        project {
          id
          name
        }
      }
    }
  }
`

export default getModule(`module`)