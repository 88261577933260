const fields = `
  id
  title
  content
  importance
  info
  createdAt
  updatedAt
  expiresAt
  deletedAt
  numUndismissed
  numDismissed
`

export default fields