import { gql } from '@apollo/client'

const accountSettings = gql`
  query accountSettings($updatedSince: Milliseconds!) {
    accountSettings(updatedSince: $updatedSince) {
      id
      value
    }
  }
`

export default accountSettings