const keyDownZ = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    noneditableViewingMode,
    goUndo,
    goRedo,
  } = params

  if(!modifierKey || noneditableViewingMode) return

  event.preventDefault()

  if(shiftKey) {
    goRedo()
  } else {
    goUndo()
  }

}

export default keyDownZ