import { memo } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import LinkIcon from '@material-ui/icons/Link'
import ShareIcon from '@material-ui/icons/Share'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CheckIcon from '@material-ui/icons/Check'
import { i18n } from 'inline-i18n'

import useShareOrCopy from '../../hooks/useShareOrCopy'

import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgb(0 0 0/.5);
  opacity: ${({ $show }) => $show ? 1 : 0};
  transition: .35s ease-in-out opacity;
  z-index: ${({ $show }) => $show ? 3 : 0};
`

const Top = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 8px;
`

const Middle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  align-items: center;
`

const TopButton = styled(Button)`
  color: white;
  padding: 2px 8px;
  
  &:hover {
    background: rgb(255 255 255/.2);
  }
`

const Title = styled.div`
  font-weight: 200;
`

const Link = styled.div`
  position: relative;
  transition: opacity .15s ease-in-out;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 40px);
`

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
`

const ViewOnSource = styled.div`
  padding: 0 0 20px;
  font-size: 13px;
  font-weight: 300;
`

const ViewOnSourceNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  color: rgb(255 255 255/.6);

  &:hover {
    color: white;
  }
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  display: block;
  align-self: center;
`

const XImg = styled.img`
  width: 38px;
  height: 38px;
  padding: 4px;
  background: black;
  border-radius: 50%;
  border: 1px solid rgb(255 255 255/.3);
  vertical-align: middle;
  transition: opacity .15s ease-in-out, border-color .15s ease-in-out;

  &:hover {
    opacity: .8;
    border-color: rgb(255 255 255/.5);
  }
`


const CopyIconButton = styled(IconButton)`
  color: white;
  background: black;
  padding: 7px;
  border: 1px solid rgb(255 255 255/.3);

  .MuiSvgIcon-root {
    font-size: 22px;
  }
`

const StyledLinkIcon = styled(LinkIcon)`
  transition: opacity .25s ease-in-out;
  opacity: ${({ $justCopiedOrShared }) => $justCopiedOrShared ? 0 : 1};
`

const StyledShareIcon = styled(ShareIcon)`
  transition: opacity .25s ease-in-out;
  opacity: ${({ $justCopiedOrShared }) => $justCopiedOrShared ? 0 : 1};

  && {
    padding: 1px;
    margin: 0 1px 0 -1px;
  }
`

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: 7px;
  bottom: 7px;
  left: 7px;
  right: 7px;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $justCopiedOrShared }) => $justCopiedOrShared ? 1 : 0};
`

const VideoPlayerCustomControlsSharing = ({
  show,
  studyBibleLink,
  sourceLink,
  toggleShowSharing,
  sharingTitle,
  sharingText,
}) => {

  const link = studyBibleLink.replace(/^\//, `${document.location.origin}/`)
  const { copyOrShare, willShare, justCopiedOrShared } = useShareOrCopy({
    shareData: {
      title: sharingTitle,
      text: sharingText,
      url: link,
    },
  })
  const { justCopiedOrShared: justCopied, goCopy } = useShareOrCopy({
    shareData: {
      url: link,
    },
  })

  const platform = {
    "https://beta.sermonaudio.com": i18n("SermonAudio"),
    "https://vimeo.com": i18n("Vimeo"),
    "https://www.youtube.com": i18n("YouTube"),
    "https://sermons.logos.com": i18n("Sermons by Logos"),
    "https://podcasts.apple.com": i18n("Apple Podcasts"),
    "https://www.desiringgod.org": i18n("Desiring God"),
  }[(sourceLink || ``).match(/^https?:\/\/[^/]+/) || ``]

  return (
    <Container $show={show}>

      <Top>

        <TopButton
          startIcon={<ArrowBackIcon />}
          onClick={toggleShowSharing}
          tabIndex={-1}
        >
          {i18n("Back")}
        </TopButton>

      </Top>

      <Middle>

        <Title>
          {i18n("Share Link")}
        </Title>

        <Link>{link}</Link>

        <SocialMedia>

          <Tooltip
            placement="top"
            title={i18n("Copy link")}
          >
            <CopyIconButton
              onClick={goCopy}
              tabIndex={-1}
            >
              <StyledLinkIcon $justCopiedOrShared={justCopied} />
              <StyledCheckIcon $justCopiedOrShared={justCopied} />
            </CopyIconButton>
          </Tooltip>

          {willShare &&
            <Tooltip
              placement="top"
              title={i18n("Share")}
            >
              <CopyIconButton
                onClick={copyOrShare}
                tabIndex={-1}
              >
                <StyledShareIcon $justCopiedOrShared={justCopiedOrShared} />
                <StyledCheckIcon $justCopiedOrShared={justCopiedOrShared} />
              </CopyIconButton>
            </Tooltip>
          }

          <Tooltip
            placement="top"
            title={i18n("Share on X")}
          >
            <StyledNavLinkOrAWithDisable
              to={`https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}&text=${encodeURIComponent(`${sharingTitle}\n${sharingText}\n\n`)}`}
              tabIndex={-1}
            >
              <XImg src="/x.svg" />
            </StyledNavLinkOrAWithDisable>
          </Tooltip>

        </SocialMedia>

      </Middle>

      <ViewOnSource>
        {!!sourceLink && !!platform &&
          <ViewOnSourceNavLinkOrAWithDisable
            tabIndex={-1}
            to={sourceLink}
          >
            {i18n("View on {{platform}}", { platform })}
          </ViewOnSourceNavLinkOrAWithDisable>
        }
      </ViewOnSource>

    </Container>
  )

}

export default memo(VideoPlayerCustomControlsSharing)