import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import CustomSlider from '../../common/CustomSlider'

const StyledCustomSlider = styled(CustomSlider)`
`

const BibleMapEditorLevelOfCertainty = ({
  levelOfCertainty,
  onChange,
  disabled,
  keys=`levelOfCertainty`,
  ...otherProps
}) => {

  const goOnChange = useCallback((event, newValue) => onChange({ keys: keys.split(` `), newValue }), [ onChange, keys ])

  return (
    <StyledCustomSlider
      {...otherProps}
      label={i18n("Level of Certainty")}
      min={1}
      max={10}
      step={1}
      valueLabelDisplay="auto"
      color="secondary"
      value={levelOfCertainty}
      disabled={disabled}
      onChange={goOnChange}
    />
  )
}

export default memo(BibleMapEditorLevelOfCertainty)