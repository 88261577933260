import { useMemo } from 'react'

import useGoUpdateHighlights from './useGoUpdateHighlights'

const useGoUpdateHighlight = ({
  highlight,
  undoRedoStack,
}={}) => {

  const [ goUpdate, goDelete ] = useGoUpdateHighlights({
    undoRedoStack,
  })

  const toReturn = useMemo(
    () => ([
      (updateObj={}, options) => (
        goUpdate(
          [ updateObj ],
          highlight && [ highlight ],
          options,
        )[0]
      ),
      ...(highlight ? [ () => goDelete([ highlight ]) ] : []),
    ]),
    [ highlight, goUpdate, goDelete ],
  )

  return toReturn
}

export default useGoUpdateHighlight