import { gql } from '@apollo/client'
import { subsetDefs, subsetMaps } from '../fragments/subset_parameters'
import { getUserFields } from '../fragments/user_fields'

const users = gql`
  query users($query: String, ${subsetDefs}) {
    users(query: $query, ${subsetMaps}) {
      users {
        ${getUserFields({ isAdmin: true })}
      }
      count
    }
  }
`

export default users