import React, { useState, useMemo } from 'react'

import useDataQuery from '../hooks/useDataQuery'
import useStickyRefState from '../hooks/useStickyRefState'
import useLayoutEffectAsync from '../hooks/useLayoutEffectAsync'
import { getLocalStorage, isIOS, setLocalStorage } from '../utils/misc'

import channelQuery from '../graphql/queries/channel'

// const DUMMY_CHANNEL_ID_FOR_TESTING = `sandyridge`  // after using this on iOS, uncomment setIOSChannelIdInPWA() below and refresh once
const DUMMY_CHANNEL_ID_FOR_TESTING = null

export const ChannelIdInPWAContext = React.createContext({
  channelIdInPWA: undefined,
  setChannelIdInPWA: () => {},
})

export const ChannelIdInPWAContextProvider = ({
  children,
}) => {

  let [ channelIdInPWA, setChannelIdInPWA ] = useState()
  const [ iOSChannelIdInPWA, setIOSChannelIdInPWA ] = useStickyRefState({ id: `ios-channel-id-in-pwa` })

  if(DUMMY_CHANNEL_ID_FOR_TESTING) {
    channelIdInPWA = DUMMY_CHANNEL_ID_FOR_TESTING
  } else if(iOSChannelIdInPWA) {
    channelIdInPWA = iOSChannelIdInPWA
    // setIOSChannelIdInPWA()  // after using DUMMY_CHANNEL_ID_FOR_TESTING on iOS, uncomment this and refresh once
  }

  window.channelIdInPWA = channelIdInPWA  // used in ConfirmResetAppDialog alone, since it is outside GlobalContext

  const { channel } = useDataQuery({
    channelQuery,
    variables: {
      id: channelIdInPWA,
    },
    sticky: true,
    skip: !channelIdInPWA,
  })

  const channelIdInPWAValueAndSetter = useMemo(
    () => ({
      channelIdInPWA,
      setChannelIdInPWA,
      channelInPWA: channel,
    }),
    [ channelIdInPWA, setChannelIdInPWA, channel ],
  )

  useLayoutEffectAsync(
    () => {
      if(isIOS && channelIdInPWA && !iOSChannelIdInPWA) {
        // Since iOS has sandboxed data for PWAs, this can be made sticky on the initial load.
        // (This is important because when it goes to authenticate and comes back, it will not necessarily have ?church_app)
        setIOSChannelIdInPWA(channelIdInPWA)
      }
    },
    [ channelIdInPWA ],
  )

  useLayoutEffectAsync(
    () => {
      const errorBondaryInfo = getLocalStorage(`errorBondaryInfo`, {})
      errorBondaryInfo.iconUrl = channel ? `${process.env.REACT_APP_ASSETS_URI}${channel.id}-favicon_192-${channel.updatedAt}.png` : `/favicon_144.png`
      setLocalStorage(`errorBondaryInfo`, errorBondaryInfo)
    },
    [ channel ],
  )

  return (
    <ChannelIdInPWAContext.Provider value={channelIdInPWAValueAndSetter}>
      {children}
    </ChannelIdInPWAContext.Provider>
  )
}