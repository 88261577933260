import React, { useState, useMemo } from 'react'

export const LastApolloResponseContext = React.createContext({
  lastApolloResponse: undefined,
  setLastApolloResponse: () => {},
})

export const LastApolloResponseContextProvider = ({
  children,
}) => {

  const [ lastApolloResponse, setLastApolloResponse ] = useState()

  const lastApolloResponseValueAndSetter = useMemo(
    () => ({
      lastApolloResponse,
      setLastApolloResponse,
    }),
    [ lastApolloResponse, setLastApolloResponse ],
  )

  return (
    <LastApolloResponseContext.Provider value={lastApolloResponseValueAndSetter}>
      {children}
    </LastApolloResponseContext.Provider>
  )
}