import { memo, useState, useCallback, useRef } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { useMutation } from '@apollo/client'

import useError from '../../hooks/useError'

import EditImageDialog from './EditImageDialog'
import FadedLoading from './FadedLoading'

import createStudyBibleAssetUploadLinkMutation from '../../graphql/mutations/createStudyBibleAssetUploadLink'
import editStudyBibleImageMutation from '../../graphql/mutations/editStudyBibleImage'

let idIdx = 0

const Container = styled.div`
`

const StyledInputAdornment = styled(InputAdornment)`
  background-color: rgb(0 0 0/.1);
  width: 30px;
  height: 30px;
  border-radius: 30px;
  flex-shrink: 0;
  margin-right: -8px;
  background-size: cover;
  background-position: center;
  background-image: url(${({ $imageUrl }) => $imageUrl});

  ${({ $includeUploadButton }) => !$includeUploadButton ? `` : `
    &:hover {
      cursor: pointer;
      opacity: .5;
    }
  `}
`

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  .MuiTooltip-tooltip {
    max-width: 616px;
    padding: 8px;
  }
`

const EnlargedImage = styled.img`
  max-height: 600px;
  max-width: min(600px, calc(100vw - 20px));
  height: auto;
  width: auto;
  vertical-align: middle;
`

const ButtonLike = styled.div`
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: #e0e0e0;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: #d5d5d5;
  }
`

const ImageUrlTextField = ({
  value=``,
  uneditedValue,
  onChange,
  onChangeUnedited,
  isImageUrl=false,
  includeUploadButton,
  editUponUpload,
  getAutoEditInfo,
  disabled,
  renderAsSimpleButton,
  label,
  minWidth=200,
  minHeight=200,
  minWidthOrHeight=200,
  maxHeight=1200,
  ...otherProps
}) => {

  const id = useRef(++idIdx).current

  const [ editImageDialogUrl, setEditImageDialogUrl ] = useState(``)
  const [ editImageDialogOpen, setEditImageDialogOpen ] = useState(false)
  const [ enlargedImageStyle, setEnlargedImageStyle ] = useState({})
  const [ loading, setLoading ] = useState(false)

  const [ createStudyBibleAssetUploadLink ] = useMutation(createStudyBibleAssetUploadLinkMutation)
  const [ editStudyBibleImage ] = useMutation(editStudyBibleImageMutation)

  const { errorDialog, setError } = useError()

  const onLoadEnlargedImage = useCallback(
    ({ target }) => {
      setEnlargedImageStyle({
        width: `${target.naturalWidth / 2}px`,
        height: `${target.naturalHeight / 2}px`,
      })
    },
    [],
  )

  const onClick = useCallback(
    ({ target }) => {
      const { validity, files: [image] } = target

      document.activeElement.blur()
      if(!image || !validity.valid) return

      // TODO: show loading

      const cancelUpload = message => {
        // TODO: show error; end loading
        setError({ message })
        target.value = null
      }
      const img = new Image()
      img.src = window.URL.createObjectURL(image)
      img.onload = async () => {
        const width = img.naturalWidth
        const height = img.naturalHeight

        window.URL.revokeObjectURL(img.src)

        if(image.size > 8000000) {  // 8mb
          cancelUpload("The chosen image is too large. (It must be less than 8 MB in size.)")

        } else if(width < minWidth || height < minHeight || Math.max(width, height) < minWidthOrHeight) {
          cancelUpload("The chosen image is not big enough.")

        } else {

          setLoading(true)

          let { data: { createStudyBibleAssetUploadLink: { uploadUrl, url } } } = await createStudyBibleAssetUploadLink({
            variables: {
              contentType: image.type,
            },
          })

          onChangeUnedited && onChangeUnedited({ target: { value: url } })

          await fetch(uploadUrl, {
            method: 'put',
            body: image,
          })

          if(editUponUpload) {
            setEditImageDialogUrl(url)
            setEditImageDialogOpen(true)
          } else if(getAutoEditInfo) {
            const { data: { editStudyBibleImage: newUrl } } = await editStudyBibleImage({
              variables: {
                url,
                editInfo: getAutoEditInfo({ width, height }),
              },
            })
            onChange({ target: { value: newUrl } })
          } else {
            onChange({ target: { value: url } })
          }

          setLoading(false)

        }
      }
      img.onerror = () => cancelUpload("Invalid image file.")
    },
    [ createStudyBibleAssetUploadLink, editUponUpload, onChange, editStudyBibleImage, minWidth, minHeight, minWidthOrHeight, onChangeUnedited, getAutoEditInfo, setError ],
  )

  const reEditOnClick = useCallback(
    () => {
      setEditImageDialogUrl(uneditedValue)
      setEditImageDialogOpen(true)
    },
    [ uneditedValue ],
  )

  const onCloseEditImageDialog = useCallback(() => setEditImageDialogOpen(false), [])
  const updateUrl = useCallback(value => onChange({ target: { value } }), [ onChange ])

  if(!isImageUrl || disabled) {
    return (
      <TextField
        {...otherProps}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    )
  }

  const onClickWillReEdit = !!value && !!onChangeUnedited && !!uneditedValue

  if(renderAsSimpleButton) {
    return (
      <Container>

        <label htmlFor={`ImageUrlTextField-upload-${id}`}>
          <ButtonLike
            {...otherProps}
            onClick={onClickWillReEdit ? reEditOnClick : null}
          >
            {label}
          </ButtonLike>
        </label>

        {!onClickWillReEdit &&
          <input
            key={editImageDialogOpen ? `open` : `closed`}
            accept=".png, .jpg, .jpeg, .bmp, .gif"
            style={{ display: 'none' }}
            id={`ImageUrlTextField-upload-${id}`}
            type="file"
            onChange={onClick}
          />
        }

        {errorDialog}

        {loading && <FadedLoading size={15} />}

      </Container>

    )
  }

  return (
    <Container>
      <TextField
        {...otherProps}
        value={value}
        label={label}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <StyledTooltip
              title={
                value
                  ? (
                    <EnlargedImage
                      src={value}
                      onLoad={onLoadEnlargedImage}
                      style={enlargedImageStyle}
                    />
                  )
                  : (
                    includeUploadButton
                      ? i18n("Click to upload")
                      : ``
                  )
              }
            >
              <label htmlFor={`ImageUrlTextField-upload-${id}`}>
                <StyledInputAdornment
                  position="end"
                  $imageUrl={value}
                  children={[]}
                  $includeUploadButton={includeUploadButton}
                  onClick={onClickWillReEdit ? reEditOnClick : null}
                />
              </label>
            </StyledTooltip>
          )
        }}
      />

      {includeUploadButton && !onClickWillReEdit &&
        <input
          key={editImageDialogOpen ? `open` : `closed`}
          accept=".png, .jpg, .jpeg, .bmp, .gif"
          style={{ display: 'none' }}
          id={`ImageUrlTextField-upload-${id}`}
          type="file"
          onChange={onClick}
        />
      }

      <EditImageDialog
        key={editImageDialogUrl}
        open={editImageDialogOpen}
        onClose={onCloseEditImageDialog}
        url={editImageDialogUrl}
        updateUrl={updateUrl}
        minWidth={minWidth}
        minHeight={minHeight}
        maxHeight={maxHeight}
      />

      {errorDialog}

      {loading && <FadedLoading />}

    </Container>
  )
}

export default memo(ImageUrlTextField)