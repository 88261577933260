import { gql } from '@apollo/client'

const studyBibleItemTags = gql`
  query studyBibleItemTags {
    studyBibleItemTags {
      tag
    }
  }
`

export default studyBibleItemTags