import { memo } from 'react'

import ArrowHighlight from './ArrowHighlight'

const ArrowHighlightDouble = ({
  startType,
  endType,
  ...otherProps
}) => (
  <ArrowHighlight
    {...otherProps}
    startType="point"
    endType="point"
  />
)


export default memo(ArrowHighlightDouble)