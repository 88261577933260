import { gql } from '@apollo/client'

import channelItem_fields from '../fragments/channelItem_fields'

export default gql
`
  mutation removeReactionFromChannelItem($channelItemId: ID!, $type: String!) {
    removeReactionFromChannelItem(channelItemId: $channelItemId, type: $type) {
      ${channelItem_fields}
    }
  }
`