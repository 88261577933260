import { formattingTypes, formattingColors } from "./formatting"

const fullPaletteFormattingKey = {
  id: "system:all",
  type: "HIGHLIGHTS",
  info: {
    styles: formattingTypes.map(markupType => ({ markupType })),
    colors: formattingColors.map(color => ({ color })),
  },
  public: false,
}

export default fullPaletteFormattingKey