import { memo, useCallback, useState, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation, useHistory } from "react-router-dom"
import { i18n } from 'inline-i18n'
import Divider from '@material-ui/core/Divider'
import { usePrevious } from 'react-use'
import { useMutation, useApolloClient } from '@apollo/client'

import { getLocalStorage } from '../../../utils/misc'
import useDataQuery from '../../../hooks/useDataQuery'
import useInitialValueUpdater from '../../../hooks/useInitialValueUpdater'
import useDataUpdated from '../../../hooks/useDataUpdated'
import useMutationContext from '../../../hooks/useMutationContext'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'

import ProjectsListHeader from "./ProjectsListHeader"
import ProjectStar from "../../common/ProjectStar"
import ProjectsListModules from "./ProjectsListModules"
import ProjectsListDate from './ProjectsListDate'
import ProjectsListMenu from "./ProjectsListMenu"
import ProjectsListTrashMenu from "./ProjectsListTrashMenu"
import ProjectsListMore from "./ProjectsListMore"
import ProjectsListNone from "./ProjectsListNone"
import Loading from "../../common/Loading"
import FadedLoading from "../../common/FadedLoading"
import CustomSnackbar from '../../common/CustomSnackbar'

import projectsQuery from '../../../graphql/queries/projects'
import restoreProjectMutation from '../../../graphql/mutations/restoreProject'
import permanentlyDeleteProjectMutation from '../../../graphql/mutations/permanentlyDeleteProject'

const Container = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Contents = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-anchor: none;
  padding: 0 0 calc(100vh - 300px);
`

const Lines = styled.div`
`

const Line = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
  
  &:hover {
    box-shadow: 0 0 20px ${({ theme }) => theme.palette.grey[300]};
  }
`

const baseNameStyles = `
  flex: 1;
  font-size: 18px;
  padding: 0 10px;
  text-decoration: none;
  line-height: 62px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Name = styled(NavLink)`
  ${baseNameStyles}
  color: ${({ theme }) => theme.palette.text.primary};

  &:hover > span {
    color: inherit;
  }

  @media (max-width: 500px) {
    margin-left: -15px;
  }
`

const NonclickableName = styled.div`
  ${baseNameStyles}
  color: ${({ theme }) => theme.palette.text.primary};

  @media (max-width: 500px) {
    margin-left: -10px;
  }
`

const Untitled = styled.span`
  color: ${({ theme }) => theme.palette.text.hint};
`

const HashTags = styled.div`
  width: 150px;
  display: none;
`

const PAGE_SIZE = 25

const ProjectsList = ({
  expandLeftPanel,
  expandRightPanel,
}) => {

  const client = useApolloClient()
  const context = useMutationContext()
  const [ restoreProject ] = useMutation(restoreProjectMutation)
  const [ permanentlyDeleteProject ] = useMutation(permanentlyDeleteProjectMutation)
  
  const [ deletedProjectIds, setDeletedProjectIds ] = useState([])
  const [ snackbarInfo, setSnackbarInfo ] = useState({ open: false })
  const [ lockAndShowLoading, setLockAndShowLoading ] = useState(false)
  const contentsRef = useRef()

  const history = useHistory()
  const location = useLocation()
  const slug = location.pathname.split('/')[2] || 'projects'
  const searchParams = new URLSearchParams(location.search)
  const search = (searchParams.get(`search`) || ``).trim()

  const [ projectsUpdated, resetProjectsUpdated ] = useDataUpdated({
    markUpdated: ({ project }) => project !== null,
  })

  const queryAddOns = []

  for(const [ key, value ] of searchParams) {
    if([ `moduleType` ].includes(key)) {
      queryAddOns.push(`${key}:"${value}"`)
    }
  }

  if(slug === `starred`) {
    queryAddOns.push(`starred:true`)
  } else if(slug === `trash`) {
    queryAddOns.push(`deleted:true`)
  }

  const {
    projects: projectsAndCount,
    networkStatus,
    fetchMore,
    offline,
    refetch,
  } = useDataQuery({
    projectsQuery,
    variables: {
      query: [ search, ...queryAddOns ].filter(Boolean).join(` `),
      limit: PAGE_SIZE,
    },
    fetchPolicyNetworkNoRerun: true,
    refetchOnFocus: projectsUpdated,
    notifyOnNetworkStatusChange: true,
  })

  const { projects, count } = useMemo(
    () => {
      let { projects=[], count=0 } = projectsAndCount || {}
      projects = (networkStatus < 3 ? [] : projects).filter(({ id }) => !deletedProjectIds.includes(id))
      count -= deletedProjectIds.length
      return { projects, count }
    },
    [ projectsAndCount, deletedProjectIds, networkStatus ],
  )

  // const [ projectsListSort, setProjectsListSort ] = useState(() => getLocalStorage(`initialValue:projectsListSort`, `openedOrModifiedAt`))
  const [ projectsListSort ] = useState(() => getLocalStorage(`initialValue:projectsListSort`, `openedOrModifiedAt`))

  useInitialValueUpdater({
    projectsListSort,
  })

  const prevProjectsAndCount = usePrevious(projectsAndCount)
  useLayoutEffectAsync(
    () => {
      if(!prevProjectsAndCount) return

      const isDeletedProjectsResponseFromTheServer = (
        (projectsAndCount || {}).count === (prevProjectsAndCount || {}).count
        && deletedProjectIds.length > 0
      )

      if(!isDeletedProjectsResponseFromTheServer) {
        setDeletedProjectIds([])
        resetProjectsUpdated()
      }
    },
    [ projectsAndCount ],
  )

  const goFetchMore = useCallback(
    () => {
      fetchMore({
        variables: (
          projectsUpdated
            ? {
                // if projects have changed, then fetchMore from 0 offset
                limit: projects.length + PAGE_SIZE,
              }
            : {
                offset: projects.length,
            }
        ),
        fetchPolicy: 'network-only',
      })
    },
    [ fetchMore, projects, projectsUpdated ],
  )

  const goRestoreProjects = useCallback(
    async ({ projectIds, limit }) => {

      setLockAndShowLoading(true)

      await Promise.all(projectIds.map(id => (
        restoreProject({
          variables: {
            id,
          },
          context,
        })
      )))

      await client.refetchQueries({
        include: [ 'project' ],
        onQueryUpdated: ({ options: { variables }}) => projectIds.includes(variables.id),
      })

      await fetchMore({
        variables: {
          offset: 0,
          limit: limit || (projects.length - projectIds.length),
        },
        fetchPolicy: 'network-only',
      })

      setLockAndShowLoading(false)

      if(slug === `trash`) {
        const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
        const snackbarInfo = {
          open: true,
          message: projectIds.length > 1 ? i18n("Projects restored") : i18n("Project restored"),
          buttonLabel: i18n("Open it"),
          hideButton: projectIds.length > 1,
          onClose: closeSnackbar,
          onClick: () => {
            closeSnackbar()
            history.push(`/project/${projectIds[0]}`)
          },
        }
        setSnackbarInfo(snackbarInfo)
      }

    },
    [ setLockAndShowLoading, restoreProject, context, fetchMore, projects, slug, setSnackbarInfo, history, client ],
  )

  const onDuplicateDone = useCallback(
    async () => {
      await refetch()
      contentsRef.current.scrollTo(0,0)
    },
    [ refetch ],
  )

  const onDelete = useCallback(
    ({ ids: projectIds }) => {

      setDeletedProjectIds(projectIds)

      const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
      const snackbarInfo = {
        open: true,
        message: projectIds.length > 1 ? i18n("Projects deleted") : i18n("Project deleted"),
        onClose: closeSnackbar,
        onClick: () => {
          closeSnackbar()
          goRestoreProjects({
            projectIds,
            limit: projects.length,
          })
        },
      }
      setSnackbarInfo(snackbarInfo)

      // fetchMore({
      //   variables: {
      //     offset: 0,
      //     limit: projects.length - projectIds.length,
      //   },
      //   fetchPolicy: 'network-only',
      // })

    },
    [ projects, setDeletedProjectIds, setSnackbarInfo, goRestoreProjects ],
  )

  const goPermanentlyDeleteProjects = useCallback(
    async ({ projectIds }) => {

      setLockAndShowLoading(true)

      await Promise.all(projectIds.map(id => (
        permanentlyDeleteProject({
          variables: {
            id,
          },
          context,
        })
      )))

      await fetchMore({
        variables: {
          offset: 0,
          limit: projects.length - projectIds.length,
        },
        fetchPolicy: 'network-only',
      })

      setLockAndShowLoading(false)

    },
    [ setLockAndShowLoading, permanentlyDeleteProject, context, fetchMore, projects ],
  )

  return (
    <Container>

      <ProjectsListHeader
        projectsListSort={projectsListSort}
        expandLeftPanel={expandLeftPanel}
        expandRightPanel={expandRightPanel}
      />

      <Divider />

      <Contents ref={contentsRef}>

        {networkStatus < 3 && <Loading />}

        {networkStatus >= 3 && 
          <>

            <Lines>

              {projects.map(project => (
                <Line key={project.id}>

                  <ProjectStar
                    project={project}
                    disabled={slug === `trash`}
                  />

                  {slug !== `trash` &&
                    <Name exact to={`/project/${project.id}`}>
                      {project.name || <Untitled>{i18n("Untitled")}</Untitled>}
                    </Name>
                  }

                  {slug === `trash` &&
                    <NonclickableName>
                      {project.name || <Untitled>{i18n("Untitled")}</Untitled>}
                    </NonclickableName>
                  }

                  <ProjectsListModules
                    moduleByProjects={project.moduleByProjects}
                  />

                  <HashTags>
                  </HashTags>

                  <ProjectsListDate
                    sort={projectsListSort}
                    project={project}
                  />

                  {slug !== `trash` &&
                    <ProjectsListMenu
                      project={project}
                      onDuplicateDone={onDuplicateDone}
                      onDelete={onDelete}
                    />
                  }

                  {slug === `trash` &&
                    <ProjectsListTrashMenu
                      project={project}
                      goRestoreProjects={goRestoreProjects}
                      goPermanentlyDeleteProjects={goPermanentlyDeleteProjects}
                    />
                  }

                </Line>
              ))}

            </Lines>

            <ProjectsListMore
              showingCount={projects.length}
              totalCount={count}
              goFetchMore={goFetchMore}
              loading={networkStatus < 7}
            />

            {count === 0 && <ProjectsListNone />}

          </>
        }

        {!!offline &&
          <ProjectsListNone
            message={i18n("Not available offline.")}
          />
        }

      </Contents>

      <CustomSnackbar {...snackbarInfo} />
      {lockAndShowLoading && <FadedLoading />}

    </Container>
  )
}

export default memo(ProjectsList)