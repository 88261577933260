import { useMutation } from '@apollo/client'

import useGoUpdateModuleSubtables from './useGoUpdateModuleSubtables'

import modulePieceQuery from '../graphql/queries/modulePiece'
import modulePiecesQuery from '../graphql/queries/modulePieces'
import updateModulePiecesMutation from '../graphql/mutations/updateModulePieces'
import deleteModulePiecesMutation from '../graphql/mutations/deleteModulePieces'

export const getDefaultModulePiece = () => ({
  __typename: `ModulePiece`,
  content: null,
  info: null,
  position: null,
})

const useGoUpdateModulePieces = ({
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  // NOTE: This hook does NOT automatically write modulePieces queries to the apollo cache.
  // (Exception: It does write the modulePieces query with only moduleId specified.)
  // Other needed queries should be done manually for all creates.
  // modulePieces(moduleId: ID!, position: String, orderingFrom: BigInt, orderingTo: BigInt, offset: Int): [ModulePiece]

  const [ updateModulePieces, updateModulePiecesResult ] = useMutation(updateModulePiecesMutation)
  const [ deleteModulePieces, deleteModulePiecesResult ] = useMutation(deleteModulePiecesMutation)

  const updateAndDeleteFuncs = useGoUpdateModuleSubtables({
    updateFunc: updateModulePieces,
    updateResult: updateModulePiecesResult,
    deleteFunc: deleteModulePieces,
    deleteResult: deleteModulePiecesResult,
    singleItemByIdQuery: modulePieceQuery,
    pluralQuery: modulePiecesQuery,
    // onUpdate,
    // onDelete,
    moduleId,
    projectId,
    undoRedoStack,
    getDefault: getDefaultModulePiece,
  })

  return updateAndDeleteFuncs
}

export default useGoUpdateModulePieces