import React from "react"
import styled from 'styled-components'

import RawCode from './RawCode'
import AtomicCursor from './AtomicCursor'

import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'

import {
  BORDER_COLOR,
  DEFAULT_ACTIVE_COLOR,
} from './utils/constants'

const Container = styled.div`

  width: auto;
  display: flex;
  flex-direction: row;

  /*

  This comment contains what was present prior to it messing up video embeds. Given that
  the comments within this code says it is unclear why I have column-count, I am
  getting rid of it. Hopefully this will not have ill effects!!

  ${({ $componentType }) => $componentType === `mapWithPins` ? `` : `
    /* 
      I do not know why column-count is needed. However, there is probably a reason
      I put it in. But, it messes up the map and so in that case it is excluded.
    * /
    column-count: 1;
  `}

  */

  ${({ $editingSettings }) => !$editingSettings ? `` : `
    border-left: 5px solid ${DEFAULT_ACTIVE_COLOR};
    margin-left: -15px;
    padding-left: 10px;
  `}
`

const AtomicComponentContainer = styled.div`
  flex: 1;
  min-width: 0;
`

const SettingsIconContainer = styled.div`
  position: relative;
  width: 48px;
  margin-left: -48px;
  overflow: hidden;
  z-index: 40;
`

const StyledIcon = styled(SettingsIcon)`
  ${({ $editingSettings }) => !$editingSettings ? `` : `
    color: ${DEFAULT_ACTIVE_COLOR};
  `}
`

const MissingComponent = styled.div`
  border: 1px solid ${BORDER_COLOR};
  color: ${BORDER_COLOR};
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
  ::before {
    content: "?";
  }
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;

  :hover {
    background-color: rgb(240 240 240);
  }
`

class AtomicBlock extends React.PureComponent {

  handleMouseDown = event => {
    event.preventDefault()
  }

  toggleSettings = () => {
    const { block, blockProps } = this.props
    const { componentDefinition, toggleSettings } = blockProps

    const { dataStructure=[], lang } = componentDefinition

    toggleSettings({
      block,
      dataStructure,
      lang,
    })
  }

  render() {
    const { block, blockProps } = this.props
    const { componentDefinition, componentData, settingsData, editorMode,
            editingSettings, updateSettings, readOnly, setParentReadOnly, deleteThisBlock,
            customComponentDataByComponentType, customComponentDataByBlockKey,
            preppedCustomComponentTypes, toolbarSize, hideAllSettingsIcons } = blockProps

    const { component, defaultProps, getHTML, dataStructure=[], type,
            hideSettingsIcon=hideAllSettingsIcons } = componentDefinition

    const AtomicComponent = component
      || (
        getHTML
          ? RawCode
          : MissingComponent
      )

    const codeAddition = (
      getHTML
        ? {
          code: getHTML({
            ...settingsData,
            key: block.getKey(),
          })
        }
        : {}
    )

    const defaultPropsAddition =
      typeof defaultProps === 'function'
        ? defaultProps({
          blockKey: block.getKey(),
        })
        : (defaultProps || {})

    if(componentData.hide) return null

    return (
      <Container
        $editingSettings={editingSettings}
        className={defaultPropsAddition.className
          ? `${defaultPropsAddition.className}-container`
          : ``
        }
        $componentType={type}
      >
        <AtomicComponentContainer contentEditable={false}>
          <AtomicComponent
            flipEditorMode={editorMode}
            flipEditorBlock={block}
            flipEditorUpdateSettings={updateSettings}
            flipEditorSetReadOnly={setParentReadOnly}
            flipEditorReadOnly={!readOnly}  /* Used in InnerFlipEditor; Only one - parent or child Flip Editor - is ediable at a time */
            flipEditorPreppedCustomComponentTypes={preppedCustomComponentTypes}
            flipEditorToolbarSize={toolbarSize}
            flipEditorHideAllSettingsIcons={hideAllSettingsIcons}
            flipEditorCustomComponentDataByComponentType={customComponentDataByComponentType}
            flipEditorCustomComponentDataByBlockKey={customComponentDataByBlockKey}
            flipEditorDeleteThisBlock={deleteThisBlock}
            {...codeAddition}
            {...defaultPropsAddition}
            {...componentData}
            {...settingsData}
          />
        </AtomicComponentContainer>
        {editorMode === 'edit' && !hideSettingsIcon &&
          <SettingsIconContainer contentEditable={false}>
            <StyledIconButton
              onMouseDown={this.handleMouseDown}
              onClick={this.toggleSettings}
              className="flipeditor-settings-button"
            >
              <StyledIcon
                $editingSettings={editingSettings}
              />
            </StyledIconButton>
          </SettingsIconContainer>
        }
        {!readOnly && <AtomicCursor />}
      </Container>
    )
  }
}

export default AtomicBlock
