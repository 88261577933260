import React from "react"

const getTableInfoFromData = tableData => {
  const columnNames = []

  const data = tableData
    .split(/\n\s*\n/)
    .map(rowString => {

      const row = {}
      let inListColumnName, inListIndex, inListIsOrdered

      const completeList = () => {
        if(inListColumnName) {
          if(row[inListColumnName]) {
            // list is done
            row[inListColumnName] = inListIsOrdered ? <ol>{row[inListColumnName]}</ol> : <ul>{row[inListColumnName]}</ul>
            inListColumnName = null
          } else {
            // it was not a list after all
            row[inListColumnName] = ''
          }
        }
      }

      rowString.split(/\n/).forEach(detail => {
        if(inListColumnName) {
          if(inListIsOrdered !== true && /^- /.test(detail)) {
            // add on the list item
            row[inListColumnName].push(<li key={inListIndex++}>{detail.replace(/^- /, '')}</li>)
            inListIsOrdered = false
            return
          } else if(inListIsOrdered !== false && detail.indexOf(`${inListIndex}. `) === 0) {
            // add on the list item
            row[inListColumnName].push(<li key={inListIndex++}>{detail.substr(`${inListIndex}`.length + 2)}</li>)
            inListIsOrdered = true
            return
          }
        }

        completeList()

        const [ columnName, columnDetail ] = detail.split(/:(.*)/).map(a => a.trim())

        if(!columnName || columnDetail === undefined) return

        if(!columnNames.includes(columnName)) {
          columnNames.push(columnName)
        }

        const adjColumnName = columnName.replace(/\./g, '_PERIOD_')
        
        if(columnDetail.trim() === '') {
          // assume a list is being started
          inListColumnName = adjColumnName
          inListIndex = 1
          inListIsOrdered = null
          row[adjColumnName] = []
        } else {
          row[adjColumnName] = columnDetail
        }
      })

      completeList()

      return row

    })
    .filter(row => Object.keys(row).length)

  return {
    columnNames,
    data,
  }
}

export default getTableInfoFromData