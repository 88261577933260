import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import useDataQuery from '../../hooks/useDataQuery'
import useIsLoggedIn from '../../hooks/useIsLoggedIn'

import OptionsPopover from './OptionsPopover'
import Loading from './Loading'
import AlertsItem from './AlertsItem'

import alertsItemsQuery from '../../graphql/queries/alertsItems'

const FETCH_MORE_LIMIT = 10

const Container = styled.div`
  padding: 15px;
  width: 300px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Spacer = styled.div`
  flex: 1;
`

const None = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  text-align: center;
  font-weight: 300;
  margin-bottom: 12px;
`

const Heading = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-bottom: 12px;
`

const Message = styled.div`
  margin: 5px 0 0;
  font-size: 13px;
  font-weight: 200;
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: 5px;
  align-self: center;
`

const AlertsPopover = ({
  onClose,
  inMenu,
  ...otherProps
}) => {

  const isLoggedIn = useIsLoggedIn()

  const { alertsItems: alertsItemsAndCount, fetchMore } = useDataQuery({
    alertsItemsQuery,
    pollInterval: 1000*60,
    skip: !isLoggedIn,
    dataOnError: null,
  })
  const { alertsItems, count=0 } = alertsItemsAndCount || {}
  const numShowing = (alertsItems || []).length

  const goFetchMore = useCallback(
    () => {
      fetchMore({
        variables: {
          limit: FETCH_MORE_LIMIT,
          offset: numShowing,
        },
        fetchPolicy: 'network-only',
      })
    },
    [ fetchMore, numShowing ],
  )

  return (
    <OptionsPopover
      hideArrow
      onClose={onClose}
      $inMenu={inMenu}
      horizontal={inMenu && `center`}
      {...otherProps}
    >
      <Container>

        <Heading>
          {i18n("Alerts")}
        </Heading>

        {(alertsItems || []).map(({ __typename, ...alertsItem }) => (
          <AlertsItem
            key={alertsItem.id}
            onClosePopover={onClose}
            {...alertsItem}
          />
        ))}

        <Spacer />

        {!!alertsItems && numShowing === 0 && 
          <>
            <None>
              {i18n("You do not have any alerts.")}
            </None>
            <Spacer />
          </>
        }

        {!!alertsItems && numShowing > 0 &&
          <Message>
            {i18n("Showing {{number}} of {{total}}", {
              number: numShowing,
              total: count,
            })}
          </Message>
        }

        {numShowing < count &&
          <StyledButton
            onClick={goFetchMore}
            variant="contained"
            disableElevation
            size="small"
          >
            {i18n("Load more")}
          </StyledButton>
        }

        {!alertsItems && <Loading size={20} />}

      </Container>
    </OptionsPopover>
  )
}

export default memo(AlertsPopover)