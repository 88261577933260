import { memo } from 'react'
import styled from 'styled-components'

import { IS_EMBED } from '../../utils/constants'

const AppContent = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  background: ${IS_EMBED ? `transparent` : `white`};
  position: relative;
`

export default memo(AppContent)