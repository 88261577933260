import { selectTextOfEl } from '../../../../utils/misc'

const keyDownA = async params => {

  const {
    event,
    modifierKey,
    containerRef,
  } = params

  if(!modifierKey) return

  event.preventDefault()

  const textContentContainerEl = containerRef.current && containerRef.current.querySelector(`.MarkupContent-StyledTextContentMarkupContainer`)

  if(textContentContainerEl) {
    selectTextOfEl(textContentContainerEl)
  }

}

export default keyDownA