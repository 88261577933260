import { memo, useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import styled from 'styled-components'
import { i18n, getLocale } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { useMutation } from '@apollo/client'
import copy from 'copy-to-clipboard'
import Autocomplete from '@material-ui/lab/Autocomplete'

import useDataQuery from '../../../hooks/useDataQuery'
import useMutationContext from '../../../hooks/useMutationContext'
import useThrottleCallback from '../../../hooks/useThrottleCallback'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useSetTimeout from '../../../hooks/useSetTimeout'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import { getAdminLevelText, isValidEmail } from '../../../utils/misc'

import FadedLoading from "../../common/FadedLoading"
import ConfirmDialog from "../../common/ConfirmDialog"
import Avatar from "../../common/Avatar"
import SubscriptionInfo from '../../common/SubscriptionInfo'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import SaveStatusTextField from '../../common/SaveStatusTextField'
import CreateSubscriptionButton from './CreateSubscriptionButton'

import usersQuery from '../../../graphql/queries/users'
import createLoginLinkMutation from '../../../graphql/mutations/createLoginLink'
import createUserMutation from '../../../graphql/mutations/createUser'
import updateUserMutation from '../../../graphql/mutations/updateUser'
import deleteUserMutation from '../../../graphql/mutations/deleteUser'
import UserTabConfirmMergeDialog from './UserTabConfirmMergeDialog'

const Container = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledTextField = styled(TextField)`
  width: 300px;
  margin-bottom: 10px;
`

const SubscriptionInfoContainer = styled.div`
  width: 300px;
  max-width: calc(100vw - 40px);
  margin: 20px 0;
`

const DeleteContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  margin: 30px -20px;
  padding: 35px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CreateNewContainer = styled.div`
  margin-top: 60px;
  position: relative;
`

const NameAndAvatar = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`

const Name = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-left: 10px;
`

const Id = styled.span`
  display: inline-block;
  margin: 0 10px;
  font-weight: 200;
  font-size: 20px;
`

const Email = styled.div`
  font-size: 18px;
`

const Admin = styled.div`
  margin: 5px 0;
  font-weight: bold;
  text-transform: uppercase;
`

const Blocked = styled.div`
  margin: 5px 0;
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
`

const Language = styled.div`
  margin-top: 5px;
  font-size: 13px;
`

const UserSince = styled.div`
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 10px;
`

const InternalNotes = styled.div`
  width: 300px;
  max-width: calc(100vw - 40px);
  padding: 15px;
  position: relative;
`

const InternalNotesTextField = styled(SaveStatusTextField)`
  position: relative;
  z-index: 1;

  .MuiInputBase-root textarea {
    font-size: 13px;
  }
`

const StripeCustomerId = styled.div`
  margin-bottom: 15px;
`

const DeleteButton = styled(Button)`
  margin-top: 15px;
  background-color: ${({ theme }) => theme.palette.grey[600]};
  color: white;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[800]};
  }
`

const EditIconButton = styled(IconButton)`
  padding: 8px;
  margin: -10px -34px -10px 2px;

  .MuiSvgIcon-root {
    font-size: 16px;
  }
`

const EditEmailTextField = styled(TextField)`
  min-width: min(300px, calc(100vw - 40px));
  margin-bottom: 10px;
`

const getAll = option => option

const UserTab = () => {

  const context = useMutationContext()
  const client = useApolloClient()

  const [ open, setOpen ] = useState(false)
  const [ searchText, setSearchText ] = useState(``)
  const [ selectedUser, setSelectedUser ] = useState(``)
  const [ internalNotes, setInternalNotes ] = useState(``)
  const [ gettingLoginLink, setGettingLoginLink ] = useState(false)
  const [ loginLink, setLoginLink ] = useState()
  const [ showConfirmDeleteUser, setShowConfirmDeleteUser ] = useState(false)
  const [ showConfirmMergeUser, setShowConfirmMergeUser ] = useState(false)
  const [ doingAction, setDoingAction ] = useState(false)
  const [ setResetCopyTimeout ] = useSetTimeout()
  const [ showEditEmail, toggleShowEditEmail ] = useSimpleToggle()
  const [ emailInEdit, setEmailInEdit ] = useState(``)
  const emailInEditOnChange = useInstanceValuesCallback(({ target }) => setEmailInEdit(target.value))

  const getSearchText = useInstanceValue(searchText)

  const { id, email } = selectedUser || {}

  const onChange = useCallback(
    ({ target }) => {
      setSelectedUser()
      setSearchText(target.value)
    },
    [],
  )

  const onOpen = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => setOpen(false), [])

  const { users: { users=[] }={}, loading } = useDataQuery({
    usersQuery,
    skip: !searchText.trim(),
    variables: {
      query: searchText.toLowerCase().trim(),
    },
    fetchPolicy: 'network-only',
  })

  const [ createLoginLink ] = useMutation(createLoginLinkMutation)
  const [ createUser ] = useMutation(createUserMutation)
  const [ updateUser ] = useMutation(updateUserMutation)
  const [ deleteUser ] = useMutation(deleteUserMutation)

  useEffectAsync(
    () => {
      setInternalNotes((selectedUser || {}).internalNotes || ``)
    },
    [ (selectedUser || {}).id ]
  )

  const getOptionLabel = useCallback(user => `${user.email}${user.name ? ` (${user.name})` : ``} [${user.id}]`, [])
  const getOptionSelected = useCallback((option, value) => getOptionLabel(option) === getOptionLabel(value), [ getOptionLabel ])

  const goCreateUser = useCallback(
    async () => {

      setDoingAction(true)

      const { data: { createUser: newUser } } = await createUser({
        variables: {
          email: getSearchText(),
        },
        context,
      })

      setGettingLoginLink(false)
      setLoginLink()
      setSelectedUser(newUser)
      setSearchText(``)
      setDoingAction(false)

    },
    [ createUser, context, getSearchText ],
  )

  const [ goUpdateUser, flushUpdateUser ] = useThrottleCallback(update => update())
  const onInternalNotesChange = useCallback(
    ({ target }) => {
      const internalNotes = target.value
      setInternalNotes(internalNotes)
      goUpdateUser(async () => {
        const { data: { updateUser: updatedUser } } = await updateUser({
          variables: {
            id,
            input: {
              internalNotes,
            },
          },
          context,
        })
        setSelectedUser(updatedUser)
      })
    },
    [ goUpdateUser, updateUser, id, setSelectedUser, context ],
  )

  const onSelect = useCallback(
    (event, user) => {
      setGettingLoginLink(false)
      setLoginLink()
      setSelectedUser(user)
      setSearchText(``)
    },
    [],
  )

  const goCreateLoginLink = useCallback(
    async () => {

      setGettingLoginLink(true)

      const { data: { createLoginLink: loginLink } }  = await createLoginLink({
        variables: {
          input: {
            email,
          },
        },
        context,
      })

      setLoginLink(loginLink)
      setGettingLoginLink(false)

    },
    [ createLoginLink, context, email, setLoginLink ],
  )

  const goDeleteUser = useCallback(
    async () => {

      setDoingAction(true)

      await deleteUser({
        variables: {
          id,
        },
        context,
      })

      setShowConfirmDeleteUser(false)
      setGettingLoginLink(false)
      setLoginLink()
      setSelectedUser()
      setDoingAction(false)

    },
    [ deleteUser, context, id ],
  )

  const goSaveEmail = useInstanceValuesCallback(
    async () => {

      setDoingAction(true)

      const { data: { users: { count } } } = await client.query({
        query: usersQuery,
        variables: {
          query: emailInEdit.trim().toLowerCase(),
        },
        context,
      })

      if(count > 0) {
        alert("This email is already in use.")
        setDoingAction(false)
        return
      }

      const { data: { updateUser: updatedUser } } = await updateUser({
        variables: {
          id,
          input: {
            email: emailInEdit.trim(),
          },
        },
        context,
      })

      setSelectedUser(updatedUser)
      setDoingAction(false)
      toggleShowEditEmail({ force: false })

    },
  )

  useEffectAsync(
    () => {
      if(showEditEmail && selectedUser) {
        setEmailInEdit(selectedUser.email)
      }
    },
    [ selectedUser, showEditEmail ],
  )

  const adminLevelText = getAdminLevelText((selectedUser || {}).adminLevel)
  const hasUncanceledStripeSubscription = ((selectedUser || {}).activeSubscriptions || []).some(({ type, cancelAtPeriodEnd }) => (type === 'STRIPE' && !cancelAtPeriodEnd))
  const hasActiveStripeSubscription = ((selectedUser || {}).activeSubscriptions || []).some(({ type }) => type === 'STRIPE')

  return (
    <>

      <Container>

        <Autocomplete
          filterOptions={getAll}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          onChange={onSelect}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          noOptionsText={i18n("None found")}
          options={users}
          loading={loading}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label={i18n("Search users by email, name or id", "", "admin")}
              variant="outlined"
              value={searchText}
              onChange={onChange}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />

        {!loading && users.length === 0 && !selectedUser && isValidEmail(searchText) &&
          <CreateNewContainer>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={goCreateUser}
            >
              {i18n("Create new user with this email")}
            </Button>
            {doingAction && <FadedLoading size={20} />}
          </CreateNewContainer>
        }

        {!!selectedUser &&
          <>

            <NameAndAvatar>

              <Avatar user={selectedUser} />

              <Name>
                {selectedUser.name || i18n("Unknown name")}
                <Id>
                  ID: {selectedUser.id}
                </Id>
              </Name>

            </NameAndAvatar>

            <Email>
              {selectedUser.email || i18n("Unknown email")}
              <EditIconButton
                onClick={toggleShowEditEmail}
              >
                <EditIcon />
              </EditIconButton>
            </Email>

            <ConfirmDialog
              open={showEditEmail}
              onCancel={toggleShowEditEmail}
              onConfirm={goSaveEmail}
              title={i18n("Edit {{user_name}}’s Email", { user_name: selectedUser.name || selectedUser.id })}
              disabled={emailInEdit.trim() === selectedUser.email || !isValidEmail(emailInEdit.trim())}
              explanation={
                <EditEmailTextField
                  label={i18n("Email")}
                  fullWidth
                  value={emailInEdit}
                  onChange={emailInEditOnChange}
                  variant="outlined"
                />
              }
              loading={doingAction}
            />

            {!!adminLevelText &&
              <Admin>
                {adminLevelText}
              </Admin>
            }

            {!!selectedUser.blockedFromSharing &&
              <Blocked>
                {i18n("Blocked from sharing")}
              </Blocked>
            }

            <Language>
              {i18n("Site language: {{language}}", {
                language: selectedUser.languageId,
              })}
            </Language>

            <UserSince>
              {i18n("User since {{date}}", {
                date: new Date(selectedUser.createdAt).toLocaleDateString(getLocale()),
              })}
            </UserSince>

            <InternalNotes>
              <InternalNotesTextField
                label={i18n("Internal notes")}
                multiline
                fullWidth
                value={internalNotes}
                savedValue={(selectedUser || {}).internalNotes || ``}
                onChange={onInternalNotesChange}
                onBlur={flushUpdateUser}
                variant="outlined"
                size="small"
              />
            </InternalNotes>

            {selectedUser.stripeCustomerId &&
              <StripeCustomerId>
                {i18nReact("Stripe customer id: {{id}}", "", "admin", {
                  id: (
                    <NavLinkOrAWithDisable
                      to={`https://dashboard.stripe.com/customers/${selectedUser.stripeCustomerId}`}
                    >
                      {selectedUser.stripeCustomerId}
                    </NavLinkOrAWithDisable>
                  )
                })}
              </StripeCustomerId>
            }

            {!loginLink &&
              <div>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={goCreateLoginLink}
                >
                  {i18n("Get login link", "", "admin")}
                  {gettingLoginLink && <FadedLoading size={20} />}
                </Button>
              </div>
            }

            {!!loginLink &&
              <div>
                <Button
                  variant="contained"
                  disableElevation
                  disabled={loginLink === 'copied'}
                  color="primary"
                  onClick={() => {
                    copy(loginLink, { format: `text/plain` })
                    setLoginLink('copied')
                    setResetCopyTimeout(() => {
                      setLoginLink()
                      setGettingLoginLink(false)
                    }, 3000)
                  }}
                >
                  {loginLink === 'copied' ? i18n("Copied", "", "admin") : i18n("Copy Login Link", "", "admin")}
                </Button>
              </div>
            }

            <SubscriptionInfoContainer>
              <SubscriptionInfo
                key={selectedUser.id}
                user={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            </SubscriptionInfoContainer>

            <ConfirmDialog
              open={showConfirmDeleteUser}
              onCancel={() => setShowConfirmDeleteUser(false)}
              onConfirm={goDeleteUser}
              title={i18n("Delete Forever?")}
              explanation={i18n("“{{name}}” will be permanently deleted. A copy of this user’s data will be sent to him/her, but we will NOT be able to restore the account. Are you sure?", {
                name: selectedUser.name || selectedUser.email,
              })}
              confirmButtonLabel={i18n("Delete Forever")}
              doubleConfirm={true}
              loading={doingAction}
            />

            <UserTabConfirmMergeDialog
              open={showConfirmMergeUser}
              onCancel={() => setShowConfirmMergeUser(false)}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />

            <CreateSubscriptionButton
              userId={selectedUser.id}
              setSelectedUser={setSelectedUser}
              hasUncanceledStripeSubscription={hasUncanceledStripeSubscription}
            />

            <DeleteContainer>

              <Button
                variant="outlined"
                disableElevation
                onClick={() => {
                  if(hasActiveStripeSubscription) {
                    alert(`This user has an ACTIVE Stripe subscription and so presently cannot be merged into another account.`)
                  } else {
                    setShowConfirmMergeUser(true)
                  }
                }}
                size="small"
              >
                {i18n("Merge Data Over To Another User", "", "admin")}
              </Button>

              <DeleteButton
                variant="contained"
                disableElevation
                onClick={() => setShowConfirmDeleteUser(true)}
                size="small"
              >
                {i18n("Permanently Delete User", "", "admin")}
              </DeleteButton>

            </DeleteContainer>

          </>
        }

      </Container>

    </>
  )
}


export default memo(UserTab)