import { memo, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useVersionInfos from '../../../hooks/useVersionInfos'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import PassageRef from '../../common/PassageRef'
import PassageRefPopperContent from '../../passage/PassageRefPopperContent'

const Container = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  cursor: pointer;
  transition: opacity .15s ease-in-out;

  &:hover {
    opacity: .7;
  }
`

const BibleMapInfoEventReference = ({
  passage,
  goSetPopperInfo,
}) => {

  const { defaultVersionId } = useVersionInfos()

  const onClick = useCallback(
    event => {

      event.preventDefault()

      const refSet = []
      const addRefSet = loc => refSet.push(getRefFromLoc(loc))
      addRefSet(passage.fromLoc)
      if(passage.toLoc && passage.toLoc !== passage.fromLoc) addRefSet(passage.toLoc)

      goSetPopperInfo(event, {
        Component: PassageRefPopperContent,
        props: {
          versionId: defaultVersionId,
          refSets: [ refSet ],
          goSetPopperInfo,
        },
      })

    },
    [ passage, goSetPopperInfo, defaultVersionId ],
  )

  return (
    <Container
      onClick={onClick}
    >
      <PassageRef
        abbreviated
        {...passage}
      />
    </Container>
  )
}

export default memo(BibleMapInfoEventReference)