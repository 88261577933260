import styled from 'styled-components'

export const theme = {
  ltr: 'lexical-ltr',
  rtl: 'lexical-rtl',
  paragraph: 'lexical-p',
  quote: 'lexical-blockquote',
  heading: {
    h1: 'lexical-h1',
    h2: 'lexical-h2',
    h3: 'lexical-h3',
    h4: 'lexical-h4',
    h5: 'lexical-h5',
    h6: 'lexical-h6',
  },
  list: {
    nested: {
      listitem: 'lexical-li-nested',
    },
    ol: 'lexical-ol',
    ul: 'lexical-ul',
    listitem: 'lexical-li',
    listitemChecked: 'lexical-li-checked',
    listitemUnchecked: 'lexical-li-unchecked',
  },
  hashtag: 'lexical-hashtag',
  image: 'lexical-img',
  link: 'lexical-a',
  text: {
    bold: 'lexical-b',
    code: 'lexical-code',
    italic: 'lexical-i',
    strikethrough: 'lexical-s',
    // subscript: 'lexical-subscript',
    superscript: 'lexical-superscript',
    underline: 'lexical-u',
    underlineStrikethrough: 'lexical-u-s',
  },
  code: 'lexical-code-block',
}

const EditorContainer = styled.div`

  [contenteditable="true"]:focus-visible {
    outline: none;
  }

  line-height: 1.4;
  --lexical-indent-base-value: 15px;

  .lexical-p {
    margin: .8em 0;
  }

  .lexical-b {
    font-weight: bold;
  }

  .lexical-code {

  }

  .lexical-i {
    font-style: italic;
  }

  .lexical-s {
    text-decoration: line-through;
  }

  .lexical-subscript {

  }

  .lexical-superscript {

  }

  .lexical-u {
    text-decoration: underline;
  }

  .lexical-u-s {
    text-decoration: underline line-through;
  }

  .lexical-ol .lexical-li:before {
    left: auto;
    transform: translateX(-36px);
  }

  .lexical-ol {
    padding: 0;
    margin: .8em 0;
    list-style-position: outside;
  }

  .lexical-ol .lexical-ol {
    margin: 0;
    list-style-type: lower-alpha;
  }

  .lexical-ol .lexical-ol .lexical-ol {
    list-style-type: lower-roman;
  }

  .lexical-ol .lexical-ol .lexical-ol .lexical-ol {
    list-style-type: decimal;
  }

  .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol {
    list-style-type: lower-alpha;
  }

  .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol {
    list-style-type: lower-roman;
  }

  .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol {
    list-style-type: decimal;
  }

  .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol {
    list-style-type: lower-alpha;
  }

  .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol .lexical-ol {
    list-style-type: lower-roman;
  }

  .lexical-ul {
    padding: 0;
    margin: .8em 0;
    list-style-position: outside;
    list-style-type: disc;
  }

  .lexical-ul .lexical-ul {
    margin: 0;
    list-style-type: circle;
  }

  .lexical-ul .lexical-ul .lexical-ul {
    list-style-type: square;
  }

  .lexical-ul .lexical-ul .lexical-ul .lexical-ul {
    list-style-type: disc;
  }

  .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul {
    margin: 0;
    list-style-type: circle;
  }

  .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul {
    list-style-type: square;
  }

  .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul {
    list-style-type: disc;
  }

  .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul {
    margin: 0;
    list-style-type: circle;
  }

  .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul .lexical-ul {
    list-style-type: square;
  }

  .lexical-li {
    margin-left: 15px;
  }

  .lexical-li-nested {
    list-style-type: none;
  }

  .lexical-li-nested::before, .lexical-li-nested::after {
    display: none
  }

  .lexical-blockquote:before {
    top: auto;
    left: 50%;
    transform: translate(-50%, calc(-1.5rem - 50% + 3px));
  }

  .lexical-h1 {
    text-align: left;
    font-size: 26px;
    font-weight: 600;
    border-bottom: 1px solid #eae5cb;
    margin: .85em 0 .6em;
  }

  .lexical-h2 {
    font-weight: 400;
    font-size: 22px;
    margin: .85em 0 .6em;
  }

  .lexical-h3 {
    font-weight: bold;
    font-size: 19px;
    margin: .85em 0 .6em;
  }

  .lexical-h4 {
    font-weight: bold;
    font-size: 19px;
    margin: .85em 0 .6em;
    text-align: center;
  }

  .lexical-h5 {
    font-weight: 500;
    font-size: 17px;
    margin: .65em 0 .4em;
    text-align: center;
  }

  .lexical-h6 {
    font-weight: 300;
    font-size: 12px;
    margin: 0;
    text-align: center;
  }

`

export default EditorContainer
