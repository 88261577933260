const fields = `
  id
  name
  type
  info
  public
  createdAt
  savedAt
`

export default fields