import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { useMutation } from '@apollo/client'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { KeyboardDatePicker } from '@material-ui/pickers'

import {
  PLANS,
} from '../../../utils/constants'
import { getSubscriptionTypeLanguage, getSubscriptionPlanLanguage } from '../../../utils/misc'
import useMutationContext from '../../../hooks/useMutationContext'

import ConfirmDialog from "../../common/ConfirmDialog"

import createSubscriptionMutation from '../../../graphql/mutations/createSubscription'

const Note = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  margin-bottom: 20px;
  width: 350px;
`

const StyledButton = styled(Button)`
  
`

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  margin-bottom: 20px;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
`

const types = [
  "SCHOLARSHIP",
  "HONORARY",
  "GIVE-AWAY",
]

const CreateSubscriptionButton = ({
  userId,
  setSelectedUser,
  hasUncanceledStripeSubscription,
  ...otherProps
}) => {

  const context = useMutationContext()

  const [ showDialog, setShowDialog ] = useState(false)
  const [ type, setType ] = useState(`SCHOLARSHIP`)
  const [ plan, setPlan ] = useState(`EQUIP`)
  const [ currentPeriodEndsAt, setCurrentPeriodEndsAt ] = useState(Date.now() + (1000 * 60 * 60 * 24 * 61))
  const [ creatingSubscription, setCreatingSubscription ] = useState(false)

  const handleTypeChange = useCallback(({ target }) => setType(target.value), [])
  const handlePlanChange = useCallback(({ target }) => setPlan(target.value), [])
  const handleCurrentPeriodEndsAtChange = useCallback(newDate => setCurrentPeriodEndsAt(newDate.getTime()), [])

  const [ createSubscription ] = useMutation(createSubscriptionMutation)

  const goCreateSubscription = useCallback(
    async () => {

      setCreatingSubscription(true)

      const { data: { createSubscription: updatedUser } } = await createSubscription({
        variables: {
          input: {
            userId,
            type,
            plan,
            currentPeriodEndsAt,
          },
        },
        context,
      })

      setSelectedUser(updatedUser)
      setShowDialog(false)
      setCreatingSubscription(false)

    },
    [ setSelectedUser, createSubscription, context, userId, type, plan, currentPeriodEndsAt ],
  )

  return (
    <>

      <StyledButton
        variant="contained"
        size="small"
        disableElevation
        onClick={() => setShowDialog(true)}
      >
        {i18n("Grant a new subscription")}
      </StyledButton>

      <ConfirmDialog
        {...otherProps}
        open={showDialog}
        onCancel={() => setShowDialog(false)}
        onConfirm={goCreateSubscription}
        title={i18n("Grant Subscription")}
        explanation={
          <Form>

            {hasUncanceledStripeSubscription &&
              <Note>
                {i18n("Note: This does not automatically cancel their Paid Subscription. You will need to do that separately.")}
              </Note>
            }

            <StyledTextField
              select
              label={i18n("Type")}
              value={type}
              onChange={handleTypeChange}
            >
              {types.map(type => (
                <MenuItem
                  key={type}
                  value={type}
                >
                  {getSubscriptionTypeLanguage(type)}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              select
              label={i18n("Plan")}
              value={plan}
              onChange={handlePlanChange}
            >
              {PLANS.map(plan => (
                <MenuItem
                  key={plan}
                  value={plan}
                >
                  {getSubscriptionPlanLanguage(plan)}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledKeyboardDatePicker
              label={i18n("Expiration date")}
              minDate={new Date()}
              value={new Date(currentPeriodEndsAt)}
              onChange={handleCurrentPeriodEndsAtChange}
              format="MM/dd/yyyy"
            />

          </Form>
        }
        confirmButtonLabel={i18n("Grant")}
        disabled={isNaN(currentPeriodEndsAt)}
        loading={creatingSubscription}
      />

    </>
  )
}

export default memo(CreateSubscriptionButton)