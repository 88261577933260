import { useMemo } from 'react'
import { useLocation } from "react-router-dom"
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import StarIcon from '@material-ui/icons/Star'
import ShareIcon from '@material-ui/icons/Share'
import DeleteIcon from '@material-ui/icons/Delete'

const styleIcon = () => `
  height: 26px;
  width: auto;
  margin: 0 10px 0 1px;
`

const StyledLibraryBooksIcon = styled(LibraryBooksIcon)(styleIcon)
const StyledStarIcon = styled(StarIcon)(styleIcon)
const StyledShareIcon = styled(ShareIcon)(styleIcon)
const StyledDeleteIcon = styled(DeleteIcon)(styleIcon)

const useProjectsTitleAndIcon = () => {

  const location = useLocation()
  const slug = location.pathname.split('/')[2] || 'projects'

  const titleAndIcon = useMemo(
    () => ({
      projects: {
        title: i18n("All Projects"),
        icon: <StyledLibraryBooksIcon />,
      },
      starred: {
        title: i18n("Starred"),
        icon: <StyledStarIcon />,
      },
      "shared-with-me": {
        title: i18n("Shared With Me"),
        icon: <StyledShareIcon />,
      },
      trash: {
        title: i18n("Trash"),
        icon: <StyledDeleteIcon />,
      },
    }[slug]),
    [ slug ],
  )

  return titleAndIcon
}

export default useProjectsTitleAndIcon
