import React from "react"
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

const buttonColorMap = {
  normal: 'basic',
  emphasis: 'primary',
  scary: 'secondary',
}

const Container = styled.div`
  text-align: ${({ $alignment }) => ($alignment || 'center')};
  margin: 0 -5px;
`

const ButtonLink = styled.a`
  text-decoration: none;
`

const StyledButton = styled(Button)`
  margin: 5px;
`

const ButtonLinkSet = ({
  buttons,
  alignment,
}) => {

  return (
    <Container
      className="flipeditor-buttonLinkSet"
      $alignment={alignment}
    >
      {(buttons || []).map(({ label, href, contained, color='normal', openInNewTab }, idx) => (
        <ButtonLink
          key={idx}
          href={href || '#'}
          target={openInNewTab ? "_blank" : ``}
        >
          <StyledButton
            color={buttonColorMap[color]}
            variant={contained ? "contained" : null}
            className={[
              `flipeditor-button-${color}`,
              (contained && `flipeditor-button-contained`),
            ].filter(Boolean)}
          >
            {label || 'Button'}
          </StyledButton>
        </ButtonLink>
      ))}
    </Container>
  )

}

export default ButtonLinkSet