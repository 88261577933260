import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

// import useSimpleToggle from '../../hooks/useSimpleToggle'
import useOnChangeImage from '../../hooks/useOnChangeImage'
import useEffectAsync from '../../hooks/useEffectAsync'

import StudyBiblePageEditorDialog from './StudyBiblePageEditorDialog'
import StudyBibleItemEditorSourceSection from './StudyBibleItemEditorSourceSection'

import editStudyBibleImageMutation from '../../graphql/mutations/editStudyBibleImage'

const StyledButton = styled(Button)`
  margin: 0 15px;
  width: calc(100% - 30px);
`

const StudyBibleItemEditorInsightInfo = ({
  StyledTextField,
  commonTextFieldProps,
  StyledDivider,
  type,
  info,
  details,
  onChangeInfo,
  onChangeDetails,
  allDisabled,
  allTemporarilyDisabled,
  toggleAllTemporarilyDisabled,
  updatePreview,
}) => {

  const { uneditedImageUrl, imageUrl, imageDimensions } = info || {}

  // const [ pageEditorOpen, togglePageEditorOpen ] = useSimpleToggle()

  const [ editStudyBibleImage ] = useMutation(editStudyBibleImageMutation)

  const onChangeUneditedImageUrl = useCallback(onChangeInfo(`uneditedImageUrl`), [ onChangeInfo ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeImage = useOnChangeImage({ onChangeInfo, editStudyBibleImage })
  const onChangeImageUrl = useCallback(onChangeImage(`image`), [ onChangeImage ])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffectAsync(updatePreview, [ JSON.stringify(imageDimensions) ])

  return (
    <>

      <StyledTextField
        {...commonTextFieldProps}
        label={i18n("Base Image")}
        value={imageUrl}
        uneditedValue={uneditedImageUrl}
        onChange={onChangeImageUrl}
        onChangeUnedited={onChangeUneditedImageUrl}
        isImageUrl
        includeUploadButton
        editUponUpload
        minWidth={450}
        minHeight={150}
        maxHeight={800}
      />

      <StyledButton
        size="small"
        variant="contained"
        fullWidth
        disableElevation
        disabled={!imageUrl || allDisabled}
        onClick={toggleAllTemporarilyDisabled}
      >
        {i18n("Add / Edit Pages")}
      </StyledButton>

      <StudyBiblePageEditorDialog
        open={allTemporarilyDisabled}
        onClose={toggleAllTemporarilyDisabled}
        type={type}
        details={details}
        imageUrl={imageUrl}
        onChangeDetails={onChangeDetails}
      />

      <StyledDivider />

      <StudyBibleItemEditorSourceSection
        StyledTextField={StyledTextField}
        commonTextFieldProps={commonTextFieldProps}
        info={info}
        onChangeInfo={onChangeInfo}
        imageUrl={imageUrl}
        updatePreview={updatePreview}
      />

    </>
  )
}

export default memo(StudyBibleItemEditorInsightInfo)