import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router-dom'
import useBibleSearchResults from '../../hooks/useBibleSearchResults'

const Hits = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: 13px;
  margin-left: 4px;
  font-weight: bold;
  min-width: 35px;
  margin-right: -10px;
`

const StyledMenuItem = styled(MenuItem)`
  display: block;

  ${({ $isSubItem }) => !$isSubItem ? `` : `
    font-weight: 200;
  `}
`

const SearchSuggestionMenuItem = ({
  searchText,
  onClose,
  hits,
  isSubItem,
  children,
  ...otherProps
}) => {

  const { bibleSearchResults={} } = useBibleSearchResults({
    searchText,
    skip: Number.isInteger(hits),
  }) || {}

  hits = useMemo(
    () => {
      const { hitsByBookId, rowCountByBookId } = bibleSearchResults
      if(hitsByBookId || rowCountByBookId) {
        return (hitsByBookId || rowCountByBookId).reduce((total, count) => total + count, 0)
      }
      return hits
    },
    [ bibleSearchResults, hits ],
  )

  const history = useHistory()

  const goSearch = useCallback(
    () => {

      window.getSelection().empty()
      onClose && onClose()

      history.replace({
        hash: `#search=${encodeURIComponent(searchText)}`,
        state: {
          executeSearch: true,
        },
      })

    },
    [ history, onClose, searchText ],
  )

  return (
    <StyledMenuItem
      onClick={goSearch}
      // disabled={hits === 0}
      $isSubItem={isSubItem}
      // tabIndex={-1}
      {...otherProps}
    >
      {children}
      <Hits>
        {Number.isInteger(hits) && i18n("{{hits}}x", { hits })}
      </Hits>
    </StyledMenuItem>
  )
}

export default memo(SearchSuggestionMenuItem)