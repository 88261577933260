import React, { memo, useRef } from 'react'
import { getLocFromRef } from '@bibletags/bibletags-versification'
import { getPassageStr } from '@bibletags/bibletags-ui-helper'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useVersionInfo from '../../hooks/useVersionInfo'
import useVersionInfos from '../../hooks/useVersionInfos'
import usePassageRefPopperInfo from '../../hooks/usePassageRefPopperInfo'
import useOnPassageLoadScrollCallback from '../../hooks/useOnPassageLoadScrollCallback'

import PassagePopperContainer from './PassagePopperContainer'
import VerseNumberPopperContentVersion from './VerseNumberPopperContentVersion'
import PassagePopperGoRead from './PassagePopperGoRead'
import PassageContent from './PassageContent'

const Heading = styled.div`
  margin: -2px 0 7px;
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 14px;

  &:not(:first-child) {
    margin-top: 15px;
  }
`

const ContextLabel = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.palette.grey[600]};
  margin-right: 3px;
`

const Version = styled.span`
  display: inline-block;
  font-size: 12px;
  margin-left: 3px;
`

const UnknownVersion = styled.span`
  font-weight: 200;
`

const StyledPassagePopperGoRead = styled(PassagePopperGoRead)`
  position: absolute;
  top: 7px;
  right: 0;
`

const PassageRefPopperContent = ({
  versionId,
  refSets,
  contextRefs,
  isChapterContext,
  goSetPopperInfo,
  hasHeader,
  closePopper,
  closeAndClearSearch,
}) => {

  const { defaultVersionId } = useVersionInfos()
  versionId = versionId || defaultVersionId

  const { safeVersionAbbr, version } = useVersionInfo(versionId)

  const ref = useRef()

  const { locsToHighlight, specialMarkup } = usePassageRefPopperInfo({ contextRefs, version })

  const onLoad = useOnPassageLoadScrollCallback({
    ref,
    selector: `.TextContent-highlighted-loc`,
  })

  if(!safeVersionAbbr) {
    return (
      <PassagePopperContainer>
        <UnknownVersion>
          {i18n("Unknown Bible version")}
        </UnknownVersion>
      </PassagePopperContainer>
    )
  }

  return (
    <>

      <PassagePopperContainer
        ref={ref}
        $hasHeader={hasHeader}
      >

        {refSets.map((refSet, idx) => (
          <React.Fragment key={idx}>

            {!(!!isChapterContext && hasHeader) &&
              <Heading>

                {!!isChapterContext &&
                  <ContextLabel>
                    {i18n("Context:")}
                  </ContextLabel>
                }

                {getPassageStr({ refs: refSet })}

                {` `}

                <Version>
                  {safeVersionAbbr}
                </Version>

              </Heading>
            }

            {!!isChapterContext && refSet.map(({ bookId, chapter }, idx) => (
              <PassageContent
                key={idx}
                bookId={bookId}
                chapter={chapter}
                versionId={versionId}
                goSetPopperInfo={goSetPopperInfo}
                doPopperTextSizeAndLineSpacing
                locsToHighlight={locsToHighlight}
                showChapterNumbers={refSet.length > 1}
                onLoad={idx === 0 ? onLoad : null}
                closeAndClearSearch={closeAndClearSearch}
                noHighlights
              />
            ))}

            {!isChapterContext &&
              <VerseNumberPopperContentVersion
                versionId={versionId}
                fromLoc={getLocFromRef({ verse: 0, ...refSet[0] })}
                toLoc={getLocFromRef({ verse: 999, ...refSet.at(-1) })}
                hideHeader
                goSetPopperInfo={goSetPopperInfo}
                closeAndClearSearch={closeAndClearSearch}
              />
            }

          </React.Fragment>
        ))}

      </PassagePopperContainer>

      {!!isChapterContext && !hasHeader &&
        <StyledPassagePopperGoRead
          refs={refSets[0]}
          versionId={versionId}
          closePopper={closePopper}
          closeAndClearSearch={closeAndClearSearch}
          specialMarkup={specialMarkup}
        />
      }

    </>
  )
}

export default memo(PassageRefPopperContent)