import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import useDataQuery from '../../hooks/useDataQuery'

import projectsQuery from '../../graphql/queries/projects'

const Container = styled.div`
  position: relative;
`

const Heading = styled.div`
  position: absolute;
  top: 0;
  right: 18px;
  bottom: 0;
  writing-mode: vertical-rl;
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before, &::after {
    content: "";
    background-color: ${({ theme }) => theme.palette.grey[300]};
    flex: 1;
    width: 1px;
    margin: 4px 0;
  }
`

const StyledListItemText = styled(ListItemText)`
  margin-right: 28px;
`

const DummyName = styled.div`
  width: ${({ $width }) => $width}px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  border-radius: 9px;
  height: 16px;
  margin: 4px 0;
`

const ItemName = styled.span`
  font-weight: 300;
`

const Untitled = styled.span`
  font-weight: 100;
`

const getDummyProject = () => ({
  name: <DummyName $width={parseInt(Math.random() * 100) + 30} />,
})

const RecentProjects = ({
  toggleDrawerOpen,
  MenuItemNavLink,
}) => {

  const { projects: projectsAndCount } = useDataQuery({
    projectsQuery,
    variables: {
      limit: 3,
    },
    fetchPolicy: `cache-and-network`,
  })

  const projects = useMemo(
    () => (
      projectsAndCount
        ? projectsAndCount.projects.slice(0,3)
        : Array(3).fill().map(getDummyProject)
    ),
    [ projectsAndCount ],
  )

  return (
    <Container>

      {projects.length >= 3 &&
        <Heading>
          {i18n("Recent Projects")}
        </Heading>
      }

      {projects.map(({ id, name }, idx) => (
        <MenuItemNavLink
          key={idx}
          exact
          to={`/project/${id}`}
          disabled={!id}
        >
          <ListItem
            button
            onClick={toggleDrawerOpen}
            disabled={!id}
          >
            <StyledListItemText
              primary={
                <ItemName>
                  {name || <Untitled>{i18n("Untitled")}</Untitled>}
                </ItemName>
              }
            />
          </ListItem>
        </MenuItemNavLink>
      ))}

    </Container>
  )
}

export default memo(RecentProjects)