import { useMemo, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'
import useGoUpdateModuleByProject from './useGoUpdateModuleByProject'
import useAccountSetting from './useAccountSetting'
import useInstanceValue from './useInstanceValue'
import { LoggedInUserContext } from '../context/LoggedInUser'
import { IS_EMBED } from '../utils/constants'
import { camelToDashCase, cloneObj } from '../utils/misc'
import { defaultSettingValues as OUTLINE } from './useOutlineSettings'
import { defaultSettingValues as MARKUP } from './useMarkupSettings'
// import { defaultSettingValues as DISCOURSE } from './useDiscourseSettings'
// import { defaultSettingValues as DIAGRAMMING } from './useDiagrammingSettings'
// import { defaultSettingValues as PHRASING } from './usePhrasingSettings'
import { defaultSettingValues as LEGACY } from './useLegacySettings'

import moduleQuery from '../graphql/queries/module'
import updateModuleMutation from '../graphql/mutations/updateModule'
import deleteModuleMutation from '../graphql/mutations/deleteModule'
import setModuleSettingMutation from '../graphql/mutations/setModuleSetting'

const defaultSettingValuesByModuleType = {
  OUTLINE,
  MARKUP,
  DISCOURSE: LEGACY,
  PHRASING: LEGACY,
  // DIAGRAMMING,
}

export const getDefaultModule = userId => ({
  __typename: `Module`,
  label: null,
  inEditingMode: true,
  info: null,
  shared: false,
  public: false,
  userId,
  deletedAt: null,
})

const useGoUpdateModule = ({
  module,
  projectId,
}) => {

  const user = useContext(LoggedInUserContext)
  const client = useApolloClient()

  const [ updateModule, updateModuleResult ] = useMutation(updateModuleMutation)
  const [ deleteModule, deleteModuleResult ] = useMutation(deleteModuleMutation)

  const goUpdate = useGoUpdateTable({
    currentData: module || getDefaultModule(IS_EMBED ? `embed` : (user || {}).id),
    updateFunc: updateModule,
    updateResult: updateModuleResult,
    deleteFunc: deleteModule,
    deleteResult: deleteModuleResult,
    projectId,
  })

  const [ goCreateModuleByProject ] = useGoUpdateModuleByProject({ projectId })

  const [ defaultModuleSettings ] = useAccountSetting(`def-module-settings`, {})
  const getDefaultModuleSettings = useInstanceValue(defaultModuleSettings)
  const [ setModuleSetting, setModuleSettingResult ] = useMutation(setModuleSettingMutation)
  const [ goCreateModuleSetting ] = useGoUpdateTable({
    currentData: { __typename: `ModuleSetting` },
    updateFunc: setModuleSetting,
    updateResult: setModuleSettingResult,
    mutationPrefix: `set`,
    projectId,
    undoRedoStack: `none`,
  })

  const toReturn = useMemo(
    () => {
      if(!module) {
        return [
          (initialModuleData, initialModuleByProjectData, { initialLegacySettings, skipCreateModuleSettings }={}) => {
            // Create a new module

            const now = Date.now()

            const newData = goUpdate[0]({
              ...initialModuleData,
              createdAt: now,
              modifiedAt: now,
              openedOrModifiedAt: now,
            })

            client.writeQuery({
              query: moduleQuery,
              data: {
                module: {
                  modulePassages: [],
                  moduleByProjects: [],
                  ...newData,
                },
              },
              variables: {
                id: newData.id,
              },
            })

            const moduleId = newData.id

            goCreateModuleByProject({
              ...initialModuleByProjectData,
              moduleId,
            })

            if(!skipCreateModuleSettings) {

              // create moduleSettings
              const defaultSettingValues = defaultSettingValuesByModuleType[newData.type] || {}  // system defaults
              const defaultModuleSettings = getDefaultModuleSettings()  // user's default settings
              if(defaultSettingValues.legacy) {

                const systemDefaults = (defaultModuleSettings.legacy || {})[newData.type]
                if(systemDefaults || initialLegacySettings) {
                  goCreateModuleSetting(
                    {
                      value: {
                        settings: cloneObj({
                          ...(systemDefaults || {}),
                          ...(initialLegacySettings || {}),
                        }),
                      },
                    },
                    {
                      __typename: `ModuleSetting`,
                      id: `${moduleId}:legacy`,
                    },
                    moduleId,
                  )
                }

              } else {
                for(let setting in defaultSettingValues) {
                  if(defaultModuleSettings[setting] !== undefined) {
                    goCreateModuleSetting(
                      {
                        value: cloneObj(defaultModuleSettings[setting]),
                      },
                      {
                        __typename: `ModuleSetting`,
                        id: `${moduleId}:${camelToDashCase(setting || ``)}`,
                      },
                      moduleId,
                    )
                  }
                }
              }

            }

            return newData
          },
        ]
      }

      return goUpdate
    },
    [ module, goUpdate, goCreateModuleByProject, client, getDefaultModuleSettings, goCreateModuleSetting ],
  )

  return toReturn
}

export default useGoUpdateModule