import { useMemo } from "react"

export const getHasEqualPassageRanges = modulePassages =>  {
  const { fromLoc, toLoc } = modulePassages[0] || {}
  const fromLocToLoc = `${fromLoc}-${toLoc}`
  return modulePassages.slice(1).every(({ fromLoc, toLoc }) => `${fromLoc}-${toLoc}` === fromLocToLoc)
}

const useHasEqualPassageRanges = ({ modulePassages }) => {

  const hasEqualPassageRanges = useMemo(
    () => getHasEqualPassageRanges(modulePassages),
    [ modulePassages ],
  )

  return hasEqualPassageRanges

}

export default useHasEqualPassageRanges
