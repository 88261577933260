import { gql } from '@apollo/client'
import project_fields_complete from '../fragments/project_fields_complete'

const project = gql`
  query project($id: ID!) {
    project(id: $id) {
      ${project_fields_complete}
    }
  }
`

export default project