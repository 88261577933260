import { gql } from '@apollo/client'
import highlight_fields from '../fragments/highlight_fields'
import { subsetDefs, subsetMaps } from '../fragments/subset_parameters'

const highlights = gql`
  query highlights($query: String, ${subsetDefs}) {
    highlights(query: $query, ${subsetMaps}) {
      highlights {
        ${highlight_fields}
      }
      count
    }
  }
`

export default highlights