import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'
// import { useMeasure } from 'react-use'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom'

import useGoUpdateModuleByProject from '../../../hooks/useGoUpdateModuleByProject'
import useLegacySettings from '../../../hooks/useLegacySettings'
import useDataQuery from '../../../hooks/useDataQuery'

import ModuleContainer from '../shared/ModuleContainer'

import allVersionsQuery from '../../../graphql/queries/allVersions'

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px 0 calc(100vh - 200px);
  font-size: 15px;
`

const Introducing = styled.div`
  font-weight: 300;
  font-size: 24px;
`

const ComingSoon = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #d68945;
`

const ScholarSearch = styled.div`
  margin-top: 30px;
`

const NewSearchStringContainer = styled.div`
  margin-top: 10px;
`

const NewSearchString = styled.div`
  border: 1px dashed rgb(0 0 0/.2);
  background-color: rgb(0 0 0/.03);
  padding: 10px 12px;
  display: inline-block;
  font-weight: 500;
`

const SearchButton = styled(IconButton)`
  margin: -20px 5px -20px 10px;
  height: 34px;
  width: 34px;
`

const StyledSearchIcon = styled(SearchIcon)`
  height: 18px;
`

const WithCategorization = styled.div`
  margin-top: 10px;
  opacity: .5;
`

const LeaveInPlace = styled.div`
  margin-top: 40px;
  font-style: italic;
`

const LinkLike = styled.span`
  color: #2e566c;
  text-decoration: underline;

  &:hover {
    opacity: .5;
    cursor: pointer;
  }
`

const WordStudy = ({
  module,
  projectId,
  moduleByProject,
  onDeleteModuleByProject,
  ...otherProps
}) => {

  const history = useHistory()
  // const [ ref, { width: moduleWidth } ] = useMeasure()
  const [ x, goDeleteModuleByProject ] = useGoUpdateModuleByProject({ moduleByProject })  // eslint-disable-line no-unused-vars

  const { legacySetting, moduleSettingsLoading } = useLegacySettings({ moduleId: module.id })
  const { versions: { versions }={} } = useDataQuery({ versionsQuery: allVersionsQuery })

  const newSearchString = useMemo(
    () => {
      if(moduleSettingsLoading || !versions) return "Loading..."
      console.log('legacySetting', legacySetting)

      let versionId = legacySetting.value.texts[0]
      if([ 'wlc', 'na27', 'na28', 'sbl' ].includes(versionId)) {
        versionId = 'original'
      }

      let newSearchStr = legacySetting.value.searchtext

      if(versionId === 'original') {
        newSearchStr = (
          newSearchStr
            .replace(/ _ /g, ' * ')
            .replace(/ OR /g, '~/~')
            .replace(/\[/g, '(')
            .replace(/\]/g, ')')
            .replace(/ AND /g, '~')
            .replace(/([^ ()~]+(?: [^ ()~]+)+)/g, '"$1"')
            .replace(/~/g, ' ')
            .replace(/<[^>]+>/g, match => {

              let parsing = ``

              const prefixes = (match.slice(1, -1).split('—').at(-2) || '').split('|').filter(Boolean)
              const suffix = match.slice(1, -1).split('+')[1] || ''
              const posPlus = match.slice(1, -1).split('—').at(-1).split('+')[0]
              prefixes.forEach(prefix => {
                const translatedPrefix = {
                  m: `m`,
                  d: `h!`,
                  b: `b`,
                  l: `l`,
                  i: `h?`,
                  k: `k`,
                  s: `sh`,
                  c: `v`,
                }[prefix]
                if(translatedPrefix) {
                  parsing += `#${translatedPrefix}`
                }
              })

              const [ posesStr, detailStr='' ] = posPlus.split(':')
              const details = detailStr.split(',').filter(Boolean)
              const poses = posesStr.split('/').filter(Boolean)

              poses.forEach((pos, idx) => {

                const translatedPos = {
                  "N-": `noun`,
                  "V-": `verb`,
                  "A-": `adjective`,
                  "D-": `adverb`,
                  N: `noun`,
                  V: `verb`,
                  A: `adjective`,
                  D: `adverb`,
                  "P-": `preposition`,
                  P: `pronoun`,
                  R: `preposition`,
                  RA: `article`,
                  RP: `pronoun#personal`,
                  RR: `pronoun#relative`,
                  RD: `pronoun#demonstrative`,
                  RI: `pronoun#interrogative`,
                  "C-": `conjunction`,
                  C: `conjunction`,
                  "I-": `interjection`,
                  "X-": `particle`,
                  T: `particle`,
                }[pos]

                if(translatedPos) {
                  parsing += `${idx === 0 ? `#` : `/`}${translatedPos}`
                }

              })

              details.forEach(detail => {

                const translatedDetail = {
                  "1P": "present",
                  "1A": "aorist",
                  "1X": "perfect",
                  "1I": "imperfect",
                  "1F": "future",
                  "1Y": "pluperfect",
                  "2A": "active",
                  "2M": "middle",
                  "2P": "passive",
                  "3I": "indicative",
                  "3P": "participle",
                  "3N": "infinitive",
                  "3S": "subjunctive",
                  "3D": "imperative",
                  "3O": "optative",
                  "41": "1st",
                  "42": "2nd",
                  "43": "3rd",
                  "5M": "masculine",
                  "5F": "feminine",
                  "5N": "neuter",
                  "6S": "singular",
                  "6P": "plural",
                  "7N": "nominative",
                  "7D": "dative",
                  "7G": "genitive",
                  "7A": "accusative",
                  "7V": "vocative",
                  "8C": "comparative",
                  "8S": "superlative",
                }[detail]

                if(translatedDetail) {
                  parsing += `#${translatedDetail}`
                }

              })

              if(suffix === '3A') {
                parsing += `#h!`
              } else if(suffix === '3D') {
                parsing += `#h->/h^/n^`
              } else if(suffix) {
                parsing += `#suffix:${suffix.replace(/[0-2,]/g, '').toLowerCase()}`
              }

              return parsing
            })
            .replace(/(^| |\(|")([^#()/."*0-9])/g, '$1#lemma:$2')
            .replace(/\+/g, '')

            // <V-:1P,2M,3P,5M,6S,7D>
            // <c|k|s|i—V/D+02,1F,2P>
        )

      } else {
        newSearchStr = (
          newSearchStr
            .replace(/ /g, ' / ')
          )
      }

      if(versions.some(({ id }) => id === versionId)) {
        newSearchStr += ` in:${versionId.toUpperCase()}`
      }

      return newSearchStr
    },
    [ moduleSettingsLoading, versions, legacySetting ],
  )
  // On the Legacy Biblearc app, you had a “Scholar Search” tab searching the {{portion}} of the {{version}} by {{unit}} for the following: {{new_search_string}}.


  const goSearch = useCallback(
    () => {
      history.replace({
        hash: `#search=${encodeURIComponent(newSearchString)}`,
        state: {
          executeSearch: true,
        },
      })

    },
    [ history, newSearchString ],
  )

  const deleteModuleByProject = useCallback(
    () => {
      goDeleteModuleByProject()
      onDeleteModuleByProject({ moduleByProject })
    },
    [ goDeleteModuleByProject, onDeleteModuleByProject, moduleByProject ],
  )

  return (
    <ModuleContainer
      // ref={ref}
      moduleId={module.id}
      {...otherProps}
    >

      <Container>

        <ComingSoon>
          Coming soon...
        </ComingSoon>

        <Introducing>
          The new <b>Word Study</b> module
        </Introducing>


        <ScholarSearch>
          On the Legacy Biblearc app, you had a “Scholar Search” tab whose search translates to the following:
        </ScholarSearch>

        <NewSearchStringContainer>
          <NewSearchString>

            {newSearchString}

          </NewSearchString>

          <SearchButton
            onClick={goSearch}
          >
            <StyledSearchIcon />
          </SearchButton>
        </NewSearchStringContainer>

        {Object.keys(legacySetting.value.labelsandnotes || {}).length > 0 && 
          <WithCategorization>
            (This tab also had the search results categorized.)
          </WithCategorization>
        }

        <LeaveInPlace>
          This module will be converted into a Word Study module when that functionality is available.
          Otherwise, you may <LinkLike onClick={deleteModuleByProject}>delete it</LinkLike>.
        </LeaveInPlace>

      </Container>

    </ModuleContainer>
  )
}

export default memo(WordStudy)