import { memo } from 'react'
import styled from 'styled-components'

import { getEmbedSettings } from '../../../graphql/links/embedLink'

import Notes from '../../modules/notes/Notes'
import NotesContainerWithFocusIndicator from '../../common/NotesContainerWithFocusIndicator'

const Container = styled(NotesContainerWithFocusIndicator)`
  position: relative;
  height: 100%;
  display: flex;
  margin-top: -1px;

  ${() => getEmbedSettings().embedType !== `richtextbox` ? `` : `

    & > div > div > .flipeditor {
      margin: 0 -1px;
    }

    & > div > div > .flipeditor > div > .DraftEditor-root {
      padding-left: 15px;
      padding-right: 15px;
    }

    & > div > div > .flipeditor > div > .DraftEditor-root .options-popper-clear-cover {
      left: -15px;
      right: -15px;
    }

    & .public-DraftEditor-content {
      padding-bottom: 0;
    }

  `}

`

const EmbedRichTextBox = ({
  ...otherProps
}) => {

  return (
    <Container>

      <Notes
        {...otherProps}
      />

    </Container>
  )
}

export default memo(EmbedRichTextBox)