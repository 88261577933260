import { gql } from '@apollo/client'

const mapLayer = gql`
  query mapLayer($layer: String!, $stage: String!, $createdSince: Milliseconds!) {
    mapLayer(layer: $layer, stage: $stage, createdSince: $createdSince) {
      data
      createdAt
    }
  }
`

export default mapLayer