import { memo } from 'react'
import styled from 'styled-components'

import useEffectAsync from '../../../hooks/useEffectAsync'

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  text-align: center;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.palette.grey[400]};
  background-color: ${({ theme }) => theme.palette.grey[100]}50;
  border-radius: 6px;
`

const Message = styled.div`
`

const However = styled.div`
  font-weight: 200;
`

const LegacyStudyModuleUponPrintInNotes = ({
  onLoad,
}) => {

  useEffectAsync(() => { onLoad && onLoad() }, [])

  return (
    <Container>

      <Message>
        Sorry! Legacy modules within Notes are not able to be printed or downloaded.
      </Message>

      <However>
        However, you may still open the original module and print from there.
      </However>

    </Container>
  )
}


export default memo(LegacyStudyModuleUponPrintInNotes)