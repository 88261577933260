import { memo, useContext } from 'react'
import styled from 'styled-components'
import { useLocation } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid'
import { i18n } from 'inline-i18n'

// import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { ChannelIdInPWAContext } from '../../../context/ChannelIdInPWA'

import useStickyRefState from '../../../hooks/useStickyRefState'
import useDataQuery from '../../../hooks/useDataQuery'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useAccountSetting from '../../../hooks/useAccountSetting'
import { equalObjs, cloneObj } from '../../../utils/misc'

import ChannelHeader from "./ChannelHeader"
import AppContent from "../../common/AppContent"
import PassageArea from '../../passage/PassageArea'
import ChannelNotFound from './ChannelNotFound'
import ChannelInstallSnackbar from './ChannelInstallSnackbar'

import channelQuery from '../../../graphql/queries/channel'

const CSSB_TRIAL_PERIOD = 14  // needs to match value in biblearc-data repo

export const getChannelVars = ({
  channel,
  loggedInUser,  // only needed if iAmAnAdmin is utilized
}) => {
  
  const {
    createdAt,
    purchasedAt,
    channelAdmins,
  } = channel || {}

  let numDaysLeft = Math.floor(CSSB_TRIAL_PERIOD - ((Date.now() - createdAt) / (1000*60*60*24)))
  const iAmAnAdmin = (
    !!loggedInUser
    && (
      (channelAdmins || []).some(({ user }) => (user || {}).id === loggedInUser.id)
      || [ 'ADMIN', 'EDITOR', 'MARKETING' ].includes(loggedInUser.adminLevel)
    )
  )
  const trialExpired = !purchasedAt && numDaysLeft < 0
  numDaysLeft = Math.max(numDaysLeft, 0)

  return {
    numDaysLeft,
    iAmAnAdmin,
    trialExpired,
  }
}

const StyledAppContent = styled(AppContent)`
  padding: 100px 20% 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
`

const CHANNEL_DEFAULT_PASSAGE_BOOKMARKS = [{
  id: uuidv4(),
  width: 1,
  ref: {
    bookId: 1,
    chapter: 1,
  },
}]

const PassageContainer = styled.div`
  flex: 1;
`

const Channel = ({ ...props }) => {

  const location = useLocation()
  const id = location.pathname.split('/').pop()

  const { channel, loading } = useDataQuery({
    channelQuery,
    variables: {
      id,
    },
    sticky: true,
  })

  const { trialExpired } = getChannelVars({ channel })

  const [ churchBibles, setChurchBibles, x, loadingChurchBibles ] = useAccountSetting(`church-bibles`, [])  // eslint-disable-line no-unused-vars
  const churchBible = churchBibles.find(churchBible => churchBible.id === id) || {}
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  const [ passageBookmarks, setPassageBookmarks, getPassageBookmarks ] = useStickyRefState({
    id: `channel:${id}`,
    defaultValue: CHANNEL_DEFAULT_PASSAGE_BOOKMARKS,
  })

  // const user = useContext(LoggedInUserContext)
  // const { hasToolsPlan } = user || {}

  useEffectAsync(
    async () => {
      if(!loadingChurchBibles && (channel || {}).id && channelIdInPWA === null) {
        let newChurchBibles = cloneObj(churchBibles)
        const churchBibleIdx = newChurchBibles.findIndex(churchBible => churchBible.id === channel.id)
        if(churchBibleIdx !== -1) {
          if(!equalObjs(newChurchBibles[churchBibleIdx], channel)) {
            newChurchBibles[churchBibleIdx] = cloneObj(channel)
            await setChurchBibles(newChurchBibles)
          }
        } else {
          newChurchBibles = newChurchBibles.slice(-10)
          newChurchBibles.push(cloneObj(channel))
          await setChurchBibles(newChurchBibles)
        }
      }
    },
    [ channel, channelIdInPWA, loadingChurchBibles ],
  )

  if(!channel && !loading && !channelIdInPWA) {
    return (
      <ChannelNotFound
        {...props}
      />
    )
  }

  return (
    <>

      <ChannelHeader
        {...props}
        channel={channel || churchBible}
        loading={loading}
        passageBookmark={passageBookmarks[0]}
        setPassageBookmarks={setPassageBookmarks}
        getPassageBookmarks={getPassageBookmarks}
      />

      {trialExpired &&
        <StyledAppContent>
          {i18n("This Study Bible's trial period has ended. It is disabled until the church admin completes the setup.")}
        </StyledAppContent>
      }

      {!trialExpired &&
        <AppContent key={id}>

          <PassageContainer>
            <PassageArea
              passageBookmarks={passageBookmarks}
              setPassageBookmarks={setPassageBookmarks}
              getPassageBookmarks={getPassageBookmarks}
              allowMultipleColumns={false}
              channelIdOrUrl={id}
            />
          </PassageContainer> 

          <ChannelInstallSnackbar
            channel={channel}
          />

        </AppContent>
      }

    </>
  )
}


export default memo(Channel)