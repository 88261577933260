import { useCallback } from 'react'

import useStickyRefState from './useStickyRefState'
import { cloneObj, equalObjs } from '../utils/misc'

import BibleMapEditorButtons from '../components/pages/map/BibleMapEditorButtons'

const useMapEditorItem = ({
  mapLayerId,
  type,
  inEdit,
  lockObtained,
  doUpdateMapLayer,
  placeId,
  backToPlace,
  backToEvent,
  ...otherProps
}) => {

  const [ edited, setEdited, getEdited, clear ] = useStickyRefState({ id: `${mapLayerId} ${type}:${(inEdit || {}).id || `new`}`, defaultValue: inEdit })

  const onChange = useCallback(
    ({ target, keys, newValue }) => {
      if(!keys && target instanceof Element) {
        keys = target.closest(`.MuiTextField-root`).getAttribute(`data-keys`).split(` `)
      } else if(!keys) {
        keys = target.value.split(` `)
        newValue = keys.pop()
      }
      const newEdited = cloneObj(getEdited())
      let obj = newEdited
      keys.slice(0,-1).forEach(key => {
        obj = obj[key]
      })
      obj[keys.pop()] = newValue !== undefined ? newValue : target.value
      setEdited(newEdited)
    },
    [ getEdited, setEdited ],
  )

  const discardEdits = useCallback(
    () => {
      if((inEdit || {}).id) {
        setEdited(inEdit)
      } else {
        clear()
      }
    },
    [ setEdited, inEdit, clear ],
  )

  const goDoUpdateMapLayer = useCallback(
    () => {
      doUpdateMapLayer({ [type]: getEdited(), placeId })
      clear()
    },
    [ doUpdateMapLayer, type, getEdited, clear, placeId ],
  )

  const goDeleteMapLayer = useCallback(
    () => {
      doUpdateMapLayer({ [type]: { ...inEdit, delete: true }, placeId })
      clear()
    },
    [ doUpdateMapLayer, type, inEdit, clear, placeId ],
  )

  return {
    onChange,
    edited,
    buttons: (
      <BibleMapEditorButtons
        inEdit={inEdit}
        lockObtained={lockObtained}
        goDoUpdateMapLayer={lockObtained && goDoUpdateMapLayer}
        goDeleteMapLayer={lockObtained && !!(inEdit || {}).id && goDeleteMapLayer}
        discardEdits={discardEdits}
        hasEdits={!equalObjs(inEdit, edited)}
        type={type}
        back={type === `event` ? backToPlace : backToEvent}
        {...otherProps}
      />
    ),
  }
}

export default useMapEditorItem