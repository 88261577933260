import { memo, useState, useCallback, useContext } from 'react'
import styled from 'styled-components'
import Backdrop from '@material-ui/core/Backdrop'
// import { i18n } from 'inline-i18n'

import useFocus from '../../../hooks/useFocus'
import useProjectsShortcuts from '../../../hooks/useProjectsShortcuts'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import Header from "../../common/Header"
import AppContent from "../../common/AppContent"
import ProjectsLeftPanel from "./ProjectsLeftPanel"
import ProjectsList from "./ProjectsList"
import ProjectsRightPanel from "./ProjectsRightPanel"
import ProjectsNoTools from "./ProjectsNoTools"

const LogoImg = styled.img`
  height: 38px;
  vertical-align: top;
  margin: 5px -6px 0;
  user-select: none;

  @media (max-width: 319px) {
    display: none;
  }
`

const StyledBackdrop = styled(Backdrop)`
  z-index: 2;
  top: 59px;
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`

const Projects = ({ ...props }) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan=true } = user || {}

  const [ searchInputRef, focusSearchInput ] = useFocus()

  const [ leftPanelExpanded, setLeftPanelExpanded ] = useState(false)
  const expandLeftPanel = useCallback(() => setLeftPanelExpanded(true), [])
  const closeLeftPanel = useCallback(() => setLeftPanelExpanded(false), [])

  const [ rightPanelExpanded, setRightPanelExpanded ] = useState(false)
  const expandRightPanel = useCallback(() => setRightPanelExpanded(true), [])
  const closeRightPanel = useCallback(() => setRightPanelExpanded(false), [])

  const closeBothPanels = useCallback(
    () => {
      setLeftPanelExpanded(false)
      setRightPanelExpanded(false)
    },
    [],
  )

  useProjectsShortcuts({
    focusSearchInput,
  })

  return (
    <>

      <Header {...props} >
        <LogoImg src="/biblearc_tools_1.svg" />
      </Header>

      {!hasToolsPlan && <ProjectsNoTools />}

      <AppContent>

        <StyledBackdrop
          open={leftPanelExpanded || rightPanelExpanded}
          onClick={closeBothPanels}
        />

        <ProjectsLeftPanel
          expanded={leftPanelExpanded}
          close={closeLeftPanel}
        />

        <ProjectsList
          expandLeftPanel={expandLeftPanel}
          expandRightPanel={expandRightPanel}
        />

        <ProjectsRightPanel
          searchInputRef={searchInputRef}
          expanded={rightPanelExpanded}
          close={closeRightPanel}
        />

      </AppContent>

    </>
  )
}

export default memo(Projects)