import { memo } from 'react'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'

import useMapEditorItem from '../../../hooks/useMapEditorItem'
import { getPrimaryDate, getPrimaryName } from '../../../utils/misc'
import { colorPattern } from './mapDataIsValid'

import FadedLoading from '../../common/FadedLoading'
import BibleMapEditorNames from './BibleMapEditorNames'
import BibleMapEditorBody from './BibleMapEditorBody'
import BibleMapEditorLevelOfImportance from './BibleMapEditorLevelOfImportance'

const EventsHeading = styled.div`
  font-size: 16px;
`

const EventRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const EventRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: 200;
  transition: transform .15s ease-in-out;
  line-height: 1.2;

  &:hover {
    transform: translateX(10px);
    cursor: pointer;
  }
`

const StyledEditIcon = styled(EditIcon)`
  font-size: 14px;
  margin: -1px 0 0 9px;
`

const EventRowContent = styled.div`
  flex: 1;
`

const EventRowName = styled.span`
  font-weight: 300;
`

const EventRowPlace = styled.span`
  display: inline-block;
  font-weight: 500;
`

const EventRowDate = styled.span`
  display: inline-block;
  font-weight: 300;
  font-size: 11px;
`

const None = styled.div`
  font-style: italic;
  margin: -10px 0 0;
  font-weight: 300;
`

const BibleMapEditorJourney = ({
  mapLayerId,
  journeyInEdit,
  requestingLock,
  lockObtained,
  updating,
  doUpdateMapLayer,
  places,
  eventsByJourneyId,
  setSelectedPlace,
  setEventInEdit,
  mapLayerIds,
  ...otherProps
}) => {

  const eventsInJourney = eventsByJourneyId[journeyInEdit.id] || []

  const { onChange, edited, buttons } = useMapEditorItem({
    mapLayerId,
    type: `journey`,
    inEdit: journeyInEdit,
    requestingLock,
    lockObtained,
    updating,
    doUpdateMapLayer,
    hideDelete: eventsInJourney.length > 0,
    ...otherProps,
  })

  const { names, levelOfImportance, about, color } = edited

  const commonProps = {
    onChange: onChange,
    disabled: !lockObtained || updating,
    variant: "outlined",
    size: "small",
    fullWidth: true,
  }

  return (
    <>

      <BibleMapEditorBody>

        <BibleMapEditorNames
          names={names}
          mapLayerIds={mapLayerIds}
          {...commonProps}
        />

        <TextField
          value={about || ``}
          data-keys={`about`}
          label={i18n("About")}
          multiline
          {...commonProps}
        />

        <TextField
          value={color || ``}
          data-keys={`color`}
          label={i18n("Color")}
          placeholder="e.g. #FF0000"
          error={!new RegExp(colorPattern).test(color || ``)}
          {...commonProps}
        />

        <BibleMapEditorLevelOfImportance
          levelOfImportance={levelOfImportance || 1}
          {...commonProps}
        />

        <EventsHeading>
          {i18n("Events")}
        </EventsHeading>

        {eventsInJourney.length === 0 &&
          <None>
            {i18n("None")}
          </None>
        }

        <EventRows>
          {eventsInJourney.map(event => (
            <EventRow
              key={event.id}
              onClick={() => {
                setSelectedPlace(event.place)
                setTimeout(() => {
                  setEventInEdit(event)
                })
              }}
            >
              <EventRowContent>
                <EventRowName>
                  {getPrimaryName(event)}
                </EventRowName>
                {` | `}
                <EventRowPlace>
                  {getPrimaryName(event.place)}
                </EventRowPlace>
                {` | `}
                <EventRowDate>
                  {getPrimaryDate(event)}
                </EventRowDate>
              </EventRowContent>
              <StyledEditIcon />
            </EventRow>
          ))}
        </EventRows>

        {(requestingLock || updating) && <FadedLoading />}

      </BibleMapEditorBody>

      {buttons}

    </>
  )
}

export default memo(BibleMapEditorJourney)