import { memo, useCallback, useState, useContext } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'

import { IS_EMBED } from '../../../utils/constants'
import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { getEmbedMode } from '../../../graphql/links/embedLink'

import OptionsPopover from '../../common/OptionsPopover'
import ModuleActions from '../shared/ModuleActions'
import ModuleOptionsSharing from '../shared/ModuleOptionsSharing'

const Container = styled.div`
  width: 220px;
`

const SharingContainer = styled.div`
  margin: 5px 15px 15px;
`

const StyledTextField = styled(TextField)`
  margin-bottom: 10px;

  input {
    font-size: 14px;
    font-weight: 500;
    padding: 16px 12px;
  }
`

const ModuleOptionsHeading = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  padding: 10px 15px 0;
`

const NotesPopover = ({
  moduleByProject,
  onDeleteModuleByProject,
  goPrintOrDownload,
  onClose,
  ...otherProps
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}
  const editableAccountStatus = hasToolsPlan || (IS_EMBED && getEmbedMode() !== `frozen`)

  const { module } = moduleByProject

  const [ goUpdateModule ] = useGoUpdateModule({ module })

  const { label } = module
  const [ labelInEdit, setLabelInEdit ] = useState(label)
  const getLabel = useInstanceValue(label)
  const getLabelInEdit = useInstanceValue(labelInEdit)

  const onChangeLabel = useCallback(({ target }) => setLabelInEdit(target.value), [])

  const onBlurLabel = useCallback(
    () => {
      const label = getLabelInEdit().trim() || i18n("Notes", "", "notes")
      if(getLabel() !== label) {
        goUpdateModule({
          label,
        })
      }
    },
    [ getLabelInEdit, getLabel, goUpdateModule ],
  )

  const onKeyPressLabel = useCallback(
    ({ key }) => {
      if(key === 'Enter') {
        onBlurLabel()
        onClose()
      }
    },
    [ onBlurLabel, onClose ],
  )

  useEffectAsync(
    () => {
      setLabelInEdit(label === i18n("Notes", "", "notes") ? '' : label)
    },
    [ label ]
  )

  return (
    <OptionsPopover
      {...otherProps}
      onClose={onClose}
      hideArrow
      forceBelow
      vertical="top"
    >
      <Container>

        <StyledTextField
          variant="outlined"
          value={labelInEdit}
          onChange={onChangeLabel}
          onBlur={onBlurLabel}
          onKeyPress={onKeyPressLabel}
          placeholder={i18n("Notes", "", "notes")}
          fullWidth
          autoFocus
          disabled={!editableAccountStatus}
        />

        <SharingContainer>
          <ModuleOptionsSharing
            module={module}
          />
        </SharingContainer>

        <Divider />

        <ModuleOptionsHeading>
          {i18n("Actions")}
        </ModuleOptionsHeading>

        <ModuleActions
          module={module}
          moduleByProject={moduleByProject}
          onDeleteModuleByProject={onDeleteModuleByProject}
          goPrintOrDownload={goPrintOrDownload}
          // goUndo={goUndo}
          onClose={onClose}
        />

      </Container>
    </OptionsPopover>
  )
}

export default memo(NotesPopover)