const keyDownArrowLeftRight = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    key,
    filteredFormattingKeyInfos,
    formattingKeyInfoId,
    setCurrentLensId,
  } = params

  if(modifierKey) return
  if(shiftKey) return
  event.preventDefault()

  const pageKeyIndex = Math.max(filteredFormattingKeyInfos.findIndex(({ id }) => id === formattingKeyInfoId), 0) + (key === 'ArrowLeft' ? -1 : 1)
  const newFormattingKeyInfo = filteredFormattingKeyInfos[pageKeyIndex]

  if(newFormattingKeyInfo) {
    setCurrentLensId(newFormattingKeyInfo.id)
  }

}

export default keyDownArrowLeftRight