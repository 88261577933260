import { memo } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import AddIcon from '@material-ui/icons/Add'

import ModuleContainer from '../../modules/shared/ModuleContainer'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import { IS_EMBED } from '../../../utils/constants'
import { getEmbedMode, getEmbedSettings } from '../../../graphql/links/embedLink'

const Message = styled.div`
  padding: 15px 5px;
  font-size: 17px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const MoreHelp = styled.div`
  margin: -5px 5px 0;
  font-size: 14px;
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  color: ${({ theme }) => theme.palette.grey[500]};
`

const StyledAddIcon = styled(AddIcon)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  vertical-align: bottom;
  padding: 6px;
  height: 26px;
  width: 26px;
  color: black;
  margin: 0 2px;
`

const ProjectNoModulesPlaceholder = ({
  width,
  padding,
  children,
  noneditableViewingMode,
  moduleId,
}) => {

  const hideMessage = (
    IS_EMBED
    && (
      getEmbedMode() === `frozen`
      || (
        getEmbedMode() === `edit`
        && !getEmbedSettings().allowStudentsToAddModules
      )
    )
  )

  return (
    <ModuleContainer
      width={width}
      padding={padding}
      children={children}
      noneditableViewingMode={noneditableViewingMode}
      moduleId={moduleId}
    >

      {!hideMessage &&
        <Message>
          {i18nReact("Click the {{add_button}} button to add a study module.", {
            add_button: <StyledAddIcon />,
          })}
        </Message>
      }

      {!IS_EMBED &&
        <MoreHelp>
          <StyledNavLinkOrAWithDisable to="/your-tools-subscription" exact>
            {i18n("More help getting started")}
          </StyledNavLinkOrAWithDisable>
        </MoreHelp>
      }

    </ModuleContainer>
  )
}

export default memo(ProjectNoModulesPlaceholder)