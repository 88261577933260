const keyDownD = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    goPrintOrDownload,
  } = params

  if(modifierKey && !shiftKey) {
    event.preventDefault()
    goPrintOrDownload({ action: `download` })
  }

}

export default keyDownD