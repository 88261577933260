import { useState, useContext, useRef } from 'react'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { useApolloClient } from '@apollo/client'
import { getPiecesFromUSFM } from '@bibletags/bibletags-ui-helper'
import { usePrevious } from 'react-use'

import useLayoutEffectAsync from './useLayoutEffectAsync'
import useInstanceValue from './useInstanceValue'
import useVersionInfo from './useVersionInfo'
import useEqualObjsMemo from './useEqualObjsMemo'
import useAnalytics from './useAnalytics'
import { OfflineVersionsContext } from '../context/LocalInfo'
import { OfflineSetupStatusContext } from '../context/LocalInfo'
import { getVersionIdForBibleTags } from '../utils/misc'

import chapterQuery from '../graphql/queries/chapter'

const getChildWithText = (subsetOfPieces, which=`first`) => {
  const indexWithTextOrChildren = subsetOfPieces[which === `first` ? `findIndex` : `findLastIndex`](({ text, children }) => text || children)
  if(indexWithTextOrChildren === -1) {
    return null
  } else if(subsetOfPieces[indexWithTextOrChildren].children) {
    return (
      getChildWithText(subsetOfPieces[indexWithTextOrChildren].children, which)
      || getChildWithText(subsetOfPieces.slice(indexWithTextOrChildren + (which === `first` ? 1 : 0)), which)
    )
  } else {
    return {
      child: subsetOfPieces[indexWithTextOrChildren],
      parent: subsetOfPieces,
      index: indexWithTextOrChildren,
    }
  }
}

const getFirstChildWithText = subsetOfPieces => getChildWithText(subsetOfPieces, `first`)
const getLastChildWithText = subsetOfPieces => getChildWithText(subsetOfPieces, `last`)

const useVersesPieces = ({
  locs: locsProp,
  fromLoc,
  toLoc,
  versionId,
  pastedInVerses,
  searchText,
  skip,
  onInvalidVersionId,
  forceIncludeTagsRelatedToEntireVerse,
  inlineMarkersOnly=true,
  hideHeadings,
}) => {

  if(fromLoc && !toLoc) {
    toLoc = fromLoc
  }

  const client = useApolloClient()

  const offlineVersions = useContext(OfflineVersionsContext)
  const offlineSetupStatus = useContext(OfflineSetupStatusContext)

  const [ loading, setLoading ] = useState(true)
  const getLoading = useInstanceValue(loading)

  const { version } = useVersionInfo(versionId)
  const locs = useEqualObjsMemo(locsProp)
  const getVersion = useInstanceValue(version)
  const getLocs = useInstanceValue(locs)
  const getFromLoc = useInstanceValue(fromLoc)
  const getToLoc = useInstanceValue(toLoc)
  const getInlineMarkersOnly = useInstanceValue(inlineMarkersOnly)
  const getHideHeadings = useInstanceValue(hideHeadings)

  const { bookId } = getRefFromLoc((locs ? locs[0] : fromLoc) || ``)  // if there is a skip, there may not be either locs or fromLoc
  const bibleTagsVersionId = getVersionIdForBibleTags({ versionId, bookId })

  const bookIdAndChapterCombos = useEqualObjsMemo(
    () => {
      if(skip || pastedInVerses) {
        return []
      } else if(locs) {
        const refs = locs.map(loc => getRefFromLoc(loc))
        return [ ...new Set(refs.map(({ bookId, chapter }) => JSON.stringify({ bookId, chapter }))) ].map(JSON.parse)
      } else {
        const refs = []
        const fromRef = getRefFromLoc(fromLoc)
        const toRef = getRefFromLoc(toLoc)
        if(fromRef.bookId !== toRef.bookId) throw new Error(`Unexpected misaligned bookId in fromLoc and toLoc: ${fromLoc} ${toLoc}`)
        if(fromRef.chapter > toRef.chapter) throw new Error(`Unexpected chapter ordering in fromLoc and toLoc: ${fromLoc} ${toLoc}`)
        for(let chapter=fromRef.chapter; chapter<=toRef.chapter; chapter++) {
          refs.push({ bookId: fromRef.bookId, chapter })
        }
        return refs
      }
    },
    [ locs, fromLoc, toLoc, skip, pastedInVerses ],
  )

  const getBookIdAndChapterCombos = useInstanceValue(bookIdAndChapterCombos)

  const getParamsForQueries = () => (
    bibleTagsVersionId
      ? (
        bookIdAndChapterCombos.map(({ bookId, chapter }) => ({
          query: chapterQuery,
          variables: {
            bookId,
            chapter,
            versionId: bibleTagsVersionId,
          },
          context: {
            offlineVersions,
            offlineSetupStatus,
          },
        }))
      )
      : []
  )

  const calculateVersePieces = usfmSets => {

    const locs = getLocs()
    const fromLoc = getFromLoc()
    const toLoc = getToLoc()
    const { wordDividerRegex } = version || {}

    const wholeVerseLocs = (locs || []).map(loc => loc.split(':')[0])
    const shouldGetLoc = loc => (
      locs
        ? wholeVerseLocs.includes(loc)
        : (
          loc >= fromLoc.split(':')[0]
          && loc <= toLoc.split(':')[0]
        )
    )

    if(!getInlineMarkersOnly()) {
      const allPieces = usfmSets
        .map((chapter, idx) => {
          let usfm = (
            chapter
              .filter(({ id }) => shouldGetLoc(id.split('-')[0]))
              .map(({ usfm }) => usfm)
              .join(`\n`)
          )
          if(!/\\c /.test(usfm)) {
            const backupChapter = getRefFromLoc((locs || [])[0] || fromLoc).chapter || 1
            const { chapter=backupChapter } = (getParamsForQueries()[idx] || {}).variables || {}
            usfm = usfm.replace(/\\v /, `\\c ${chapter}\n\\v `)
          }
          if(!/^\\(?:m[tsr]?[e]?[1-9]?|s[rpd]?[1-9]?|r[q]?|d|p[ormchi]?[ocr]?[1-9]?|mi?|cls|nb|b|q[rcsamd]?[c]?[1-9]?|l[hif][mt]?[l]?[1-9]?|t[rhc][r]?[1-9]?)(?: |\n)/.test(usfm)) {
            usfm = `\\p\n` + usfm
          }
          if(getHideHeadings()) {
            usfm = usfm.replace(/\\(?:m[tsr]?[e]?[1-9]?|s[rpd]?[1-9]?) .*\n/g, `\n`)
            usfm = usfm.replace(/\\rq .*?\\rq\*\n?/g, ``)
          }
          return getPiecesFromUSFM({
            usfm,
            wordDividerRegex,
            splitIntoWords: true,
            searchText,
          })
        })
        .flat()

      return allPieces
    }

    // get the right verses
    const allPieces = usfmSets
      .map(chapter => (
        chapter.filter(({ id }) => shouldGetLoc(id.split('-')[0])).map(({ id, usfm }) => {

          // Note: id === `${loc}-${versionId}`

          const loc = id.split('-')[0]

          // get the pieces
          let pieces = getPiecesFromUSFM({
            usfm,
            inlineMarkersOnly: true,
            wordDividerRegex,
            splitIntoWords: true,
            searchText,
          })

          const [ bareFromLoc, fromLocWordRange=`1` ] = (fromLoc || ``).split(':')
          const isFromLoc = loc === bareFromLoc
          const fromLocStartWordNumber = parseInt(fromLocWordRange.split(`-`)[0], 10)
          const [ bareToLoc, toLocWordRange=`999` ] = (toLoc || ``).split(':')
          const isToLoc = loc === bareToLoc
          const toLocEndWordNumber = parseInt(toLocWordRange.split(`-`).at(-1) || `999`, 10)

          if(
            (
              locs
              && !locs.includes(loc)
            )
            || (
              isFromLoc
              && fromLocStartWordNumber > 1
            )
            || (
              isToLoc
              && toLocEndWordNumber < 999
            )
          ) {
            // the full verse is not included; get the right words

            const wordNumbersToKeep = (locs || [])
              .filter(l => l.split(':')[0] === loc)
              .map(loc => (
                loc.split(':')[1].split(',').map(verseRange => {
                  const [ startWordNumber, endWordNumber ] = verseRange.split('-').map(n => parseInt(n, 10))
                  if(!endWordNumber) {
                    return [ startWordNumber, null ]
                  }
                  return Array(endWordNumber - startWordNumber + 1).fill().map((x, idx) => startWordNumber + idx)
                })
              ))
              .flat(2)

            let keepCurrent = (
              locs
                ? wordNumbersToKeep[0] === 1
                : (
                  !isFromLoc
                  || fromLocStartWordNumber === 1
                )
            )
            let lastWordNumberInVerse = 0
            const updateKeepCurrent = wordNumberInVerse => {
              if(locs) {
                keepCurrent = (
                  wordNumbersToKeep.includes(wordNumberInVerse)
                  || (
                    !wordNumbersToKeep.at(-1)  // open-ended range at end
                    && wordNumberInVerse >= wordNumbersToKeep.at(-2)
                  )
                )
              } else {
                keepCurrent = !(
                  (
                    isFromLoc
                    && wordNumberInVerse < fromLocStartWordNumber
                  )
                  || (
                    isToLoc
                    && wordNumberInVerse > toLocEndWordNumber
                  )
                )
              }
            }

            const spliceOutPieces = (somePieces, partOfParentPiece={}, remainingPiecesInParentStack=[]) => {

              const indexesToKeepOrReplace = somePieces.map((piece, idx) => {

                const newRemainingPiecesInParentStack = [ somePieces.slice(idx + 1), ...remainingPiecesInParentStack ]
                const { type, wordNumberInVerse, text='', content, children, tag } = { ...partOfParentPiece, ...piece }

                if(children) {

                  spliceOutPieces(children, { type, wordNumberInVerse }, newRemainingPiecesInParentStack)
                  return children.length > 0 ? false : []

                } else {

                  let nextChild
                  newRemainingPiecesInParentStack.some(pieceAryToCheck => {
                    nextChild = (getFirstChildWithText(pieceAryToCheck) || {}).child
                    return !!nextChild
                  })
                  const nextChildHasASpace = (nextChild || {}).type !== 'word' && ((nextChild || {}).text || "").includes(' ')

                  if(type === 'word') {
                    updateKeepCurrent(wordNumberInVerse)
                    lastWordNumberInVerse = wordNumberInVerse
                  } else if(forceIncludeTagsRelatedToEntireVerse && [ `zApparatusJson`, `vp` ].includes(tag)) {
                    return false
                  } else if(forceIncludeTagsRelatedToEntireVerse && [ `v` ].includes(tag)) {
                    if(somePieces[idx+1].tag === `vp`) return false
                    if(keepCurrent) return false
                    return [
                      { ...piece },
                      { tag: `vp`, content: `${content || `?`}b` },
                    ]
                  } else if(/( |—|־)$/.test(text)) {  // e.g. ...the light was good. |And God separated...
                    const oldKeepCurrent = keepCurrent
                    updateKeepCurrent(lastWordNumberInVerse + 1)
                    return oldKeepCurrent ? false : []
                  } else if(/( |—|־)/.test(text)) {  // e.g. And he said, |“...
                    const [ text1, text2 ] = text.split(/(.*?(?: |—))(.*)/, 3).slice(1)
                    const twoChildrenToReturn = keepCurrent ? [{ ...piece, text: text1 }] : []
                    updateKeepCurrent(lastWordNumberInVerse + 1)
                    if(keepCurrent) {
                      twoChildrenToReturn.push({ ...piece, text: text2 })
                    }
                    return twoChildrenToReturn
                  } else if(!nextChildHasASpace) {  // when nextChildHasASpace, this piece should stay with the former group
                    updateKeepCurrent(lastWordNumberInVerse + 1)
                  }

                  return keepCurrent ? false : []

                }

              })

              for(let idx=somePieces.length-1; idx >= 0; idx--) {
                if(indexesToKeepOrReplace[idx]) {
                  somePieces.splice(idx, 1, ...indexesToKeepOrReplace[idx])
                }
              }

            }

            spliceOutPieces(pieces)

          }

          // get rid of possible start and/or final space
          ;[
            {
              childInfo: getLastChildWithText(pieces),
              which: `last`,
            },
            {
              childInfo: getFirstChildWithText(pieces),
              which: `first`,
            },
          ].forEach(({ childInfo, which }) => {
            const { child={}, parent, index } = childInfo || {}
            if(child.type !== 'word' && child.text) {
              if(child.text === ' ') {
                parent.splice(index, 1)
              } else {
                child.text = child.text.replace(which === `first` ? /^ / : / $/, ``)
              }
            }
          })

          return pieces

        })
      ))
      .flat(2)

    return allPieces

  }

  const [ versePieces, setVersesPieces ] = useState(() => {

    const usfmSets = getParamsForQueries().map(params => (
      (client.readQuery(params) || {}).chapter
    ))

    if(!usfmSets.every(Boolean)) return null

    return calculateVersePieces(usfmSets)
  })

  const isUpdate = useRef(false)
  useLayoutEffectAsync(
    async () => {

      let forceSetLoadingFalse = false

      if(pastedInVerses) {

        const usfmSets = [[]]
        const { chapter: initialChapter } = getRefFromLoc(Object.keys(pastedInVerses)[0] || ``) || {}
        for(let pastedLoc in pastedInVerses) {
          const { chapter } = getRefFromLoc(pastedLoc) 
          let chapterTag = ``
          if(chapter !== initialChapter) {
            chapterTag = `\\c ${chapter}\n`
          }
          usfmSets[0].push({
            id: `${pastedLoc}-${versionId}`,
            usfm: `${chapterTag}\\v ${getRefFromLoc(pastedLoc).verse} ${pastedInVerses[pastedLoc]}`,
          })
        }

        setVersesPieces(calculateVersePieces(usfmSets))

      } else if(!isUpdate.current) {
        isUpdate.current = true  // for next time

        if(!versePieces) {

          setLoading(true)
          forceSetLoadingFalse = true

          const usfmSets = await Promise.all(
            getParamsForQueries().map(async params => {
              try {
                return (await client.query(params)).data.chapter
              } catch(err) {
                if(err.message === `invalid versionId`) {
                  onInvalidVersionId && onInvalidVersionId({ versionId: params.variables.versionId })
                  return []
                } else {
                  throw err
                }
              }
            })
          )

          if(getBookIdAndChapterCombos() === bookIdAndChapterCombos) {  // make sure bookIdAndChapterCombos hasn't changed
            setVersesPieces(calculateVersePieces(usfmSets))
          }

        }

      } else {

        const paramsForQueries = getParamsForQueries()

        // first see if we can do this synchonously to determine whether we need to setVersesPieces(null)
        let usfmSets = paramsForQueries.map(params => (client.readQuery(params) || {}).chapter)

        if(!usfmSets.every(Boolean)) {

          setVersesPieces(null)
          setLoading(true)
          forceSetLoadingFalse = true

          usfmSets = await Promise.all(
            paramsForQueries.map(async params => {
              try {
                return (await client.query(params)).data.chapter
              } catch(err) {
                if(err.message === `invalid versionId`) {
                  onInvalidVersionId && onInvalidVersionId({ versionId: params.variables.versionId })
                  return []
                } else {
                  throw err
                }
              }
            })
          )

        }

        if(
          getLocs() === locs
          && getFromLoc() === fromLoc
          && getToLoc() === toLoc
          && getVersion() === version
        ) {  // make sure props haven't changed
          setVersesPieces(calculateVersePieces(usfmSets))
        }

      }

      if(forceSetLoadingFalse || getLoading()) setLoading(false)

    },
    [ locs, fromLoc, toLoc, version, pastedInVerses, versionId, inlineMarkersOnly, hideHeadings ],
  )

  const numVerses = (versePieces || []).filter(({ tag }) => [ `v`, `d` ].includes(tag)).length
  useAnalytics({
    eventReady: numVerses > 0 && !!versionId,
    deps: [ versePieces ],
    eventObj: {
      action: `Study:${versionId}`,
      value: numVerses,
    },
  })

  const memoedProps = useEqualObjsMemo({ locs, fromLoc, toLoc, version, pastedInVerses, versionId, inlineMarkersOnly, hideHeadings })
  const previousMemoedProps = usePrevious(memoedProps)
  const effectiveLoading = loading || memoedProps !== previousMemoedProps

  return [ versePieces, effectiveLoading ]

}

export default useVersesPieces