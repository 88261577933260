import { gql } from '@apollo/client'

import notificationsDevice_fields from '../fragments/notificationsDevice_fields'

const notificationsDevice = gql`
  query notificationsDevice($id: ID!) {
    notificationsDevice(id: $id) {
      ${notificationsDevice_fields}
    }
  }
`

export default notificationsDevice