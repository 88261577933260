import { memo } from 'react'
import styledResizableDiv from '../../../utils/styledResizableDiv'

const Container = styledResizableDiv`
  display: flex;
`

const ModuleAndThumbnail = ({
  width,
  children,
}) => (
  <Container $width={width}>
    {children}
  </Container>
)

export default memo(ModuleAndThumbnail)