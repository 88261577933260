import React from "react"
import styled from 'styled-components'

import InnerFlipEditor from '../InnerFlipEditor'

const StyledInnerFlipEditor = styled(InnerFlipEditor)`
  ${({ $framing }) => `

    ${![ 'containedSubtle', 'containedEmphasized' ].includes($framing) ? `` : `
      padding: 10px 30px;
      background: rgba(0,0,0,.07);
    `}

    ${![ 'fullWidthSubtle', 'fullWidthEmphasized' ].includes($framing) ? `` : `
      padding: 20px calc(50vw - 50% - var(--flipeditor-scrollbar-width, 0px)/2);
      margin: 0 calc((50vw - 50% - var(--flipeditor-scrollbar-width, 0px)/2) * -1);
      background: rgba(0,0,0,.07);
    `}

    ${![ 'containedEmphasized', 'fullWidthEmphasized' ].includes($framing) ? `` : `
      background: rgba(0,0,0,.15);
    `}

  `}
`

const FramedSection = ({
  className,
  framing,
  ...innerFlipEditorProps
}) => {

  const classNames = [
    className,
    `${className}-${framing}`,
  ]

  return (
    <StyledInnerFlipEditor
      className={classNames.join(' ')}
      $framing={framing}
      {...innerFlipEditorProps}
    />
  )

}

export default FramedSection