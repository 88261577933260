import { memo, useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Backdrop from '@material-ui/core/Backdrop'

import Header from "../../common/Header"
import AppContent from "../../common/AppContent"
import SettingsLeftPanel from "./SettingsLeftPanel"
import SettingsVersions from "./SettingsVersions"
import SettingsChannels from './SettingsChannels'
import SettingsUI from './SettingsUI'
import SettingsNotifications from './SettingsNotifications'

const ChooseASetting = styled.div`
  flex: 1;
  text-align: center;
  padding-top: 100px;
  font-size: 17px;
  font-weight: 300;
  text-align: center;
`

const StyledBackdrop = styled(Backdrop)`
  z-index: 2;
  top: 0;
  display: none;
  position: absolute;
  background-color: rgb(255 255 255/0.5);

  @media (max-width: 1200px) {
    display: block;
  }
`

const Settings = ({ ...props }) => {

  const { pathname } = useLocation()

  const SpecificSettingComponent = {
    versions: SettingsVersions,
    channels: SettingsChannels,
    ui: SettingsUI,
    notifications: SettingsNotifications,
  }[pathname.split('/').at(-1)]

  const [ leftPanelExpanded, setLeftPanelExpanded ] = useState(false)
  const expandLeftPanel = useCallback(() => setLeftPanelExpanded(true), [])
  const closeLeftPanel = useCallback(() => setLeftPanelExpanded(false), [])

  const [ rightPanelExpanded, setRightPanelExpanded ] = useState(false)
  const expandRightPanel = useCallback(() => setRightPanelExpanded(true), [])
  const closeRightPanel = useCallback(() => setRightPanelExpanded(false), [])

  const closeBothPanels = useCallback(
    () => {
      setLeftPanelExpanded(false)
      setRightPanelExpanded(false)
    },
    [],
  )

  const effectiveLeftPanelExpanded = leftPanelExpanded || !SpecificSettingComponent

  return (
    <>

      <Header {...props} />

      <AppContent>

        <StyledBackdrop
          open={effectiveLeftPanelExpanded || rightPanelExpanded}
          onClick={closeBothPanels}
        />

        <SettingsLeftPanel
          expanded={effectiveLeftPanelExpanded}
          close={closeLeftPanel}
          toggleDrawerOpen={props.toggleDrawerOpen}
          openDisplaySettings={props.openDisplaySettings}
        />

        {!!SpecificSettingComponent &&
          <SpecificSettingComponent
            expandLeftPanel={expandLeftPanel}
            expandRightPanel={expandRightPanel}
            rightPanelExpanded={rightPanelExpanded}
            closeRightPanel={closeRightPanel}
          />
        }

        {!SpecificSettingComponent &&
          <ChooseASetting>
            {i18n("Choose a setting from the left")}
          </ChooseASetting>
        }

      </AppContent>

    </>
  )
}

export default memo(Settings)