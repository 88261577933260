import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'

import { TransitionUp } from './CustomSnackbar'

const StyledSnackbar = styled(Snackbar)`

  .MuiSnackbarContent-root {
    min-width: 0;
    width: 100%;
    padding: 12px 15px 15px;
    justify-content: center;
  }

  .MuiSnackbarContent-action {
    padding: 0;
    margin: 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const BackButton = styled(Button)`
  &:hover {
    background: rgb(255 255 255/.1);
  }
`

const DirectLoadSnackbar = ({
  onBackToPrevious,
  onBookmarkThisSpot,
  ...otherProps
}) => {

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      TransitionComponent={TransitionUp}
      action={
        <Container>
          <BackButton
            color="primary"
            size="small"
            disableElevation
            onClick={onBackToPrevious}
          >
            {i18n("Restore Previous Reading Spot")}
          </BackButton>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            onClick={onBookmarkThisSpot}
          >
            {i18n("Make This My New Reading Spot")}
          </Button>
        </Container>
      }
      {...otherProps}
    />
  )
}

export default memo(DirectLoadSnackbar)