import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation updateModulePassages($moduleId: ID!, $projectId: ID, $input: [ModulePassageInput]!, $updatedSince: Milliseconds) {
    updateModulePassages(moduleId: $moduleId, projectId: $projectId, input: $input, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`