import { memo } from 'react'

import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
  position: relative;
  padding: 0 15px 15px;
  overflow: scroll;
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 12px;
`

const BibleMapInfoBody = ({
  children,
  ...otherProps
}) => (
  <Container {...otherProps}>
    <Items>
      {children}
    </Items>
  </Container>
)

export default memo(BibleMapInfoBody)