import { memo, useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import queryString from 'query-string'
import { useHistory } from "react-router-dom"

import useStickyRefState from '../../../hooks/useStickyRefState'
import useVersionInfos from '../../../hooks/useVersionInfos'
import useTagSet from '../../../hooks/useTagSet'
import useInterlinearInfo from '../../../hooks/useInterlinearInfo'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import useRefState from '../../../hooks/useRefState'
import usePrevNextTaggingLocs from '../../../hooks/usePrevNextTaggingLocs'
import useGoSubmitTagSet from '../../../hooks/useGoSubmitTagSet'
import useDataQuery from '../../../hooks/useDataQuery'
import useIsLoggedIn from '../../../hooks/useIsLoggedIn'
import { cloneObj, removeUnassociatedTags, sortTagSetTags } from '../../../utils/misc'

import TaggerHeader from "./TaggerHeader"
import AppContent from "../../common/AppContent"
import TaggerContent from './TaggerContent'
import TaggerInstructions from './TaggerInstructions'
import ContainerWithPassagePopper from '../../passage/ContainerWithPassagePopper'

import myTagSetSubmissionsQuery from '../../../graphql/queries/myTagSetSubmissions'

const DEFAULT_UNDO_INFO = { stack: [], idx: -1 }

const Container = styled(AppContent)`
  overflow: auto;
  padding: 10px 20px 20px;
  justify-content: center;
  position: relative;
`

const StyledContainerWithPassagePopper = styled(ContainerWithPassagePopper)`
  max-width: 470px;
  padding-bottom: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit !important;
`

const Tagger = ({ ...props }) => {

  const { defaultVersionId } = useVersionInfos()
  const history = useHistory()

  const [ versionId, setVersionId ] = useStickyRefState({
    id: `Tagger:versionId`,
    defaultValue: defaultVersionId,
  })

  const [ loc, setLoc ] = useStickyRefState({ id: `Tagger:loc` })
  const [ selectedOriginalWordIdAndPartNumbers, setSelectedOriginalWordIdAndPartNumbers ] = useState([])
  const [ viewUntaggedOnly, setViewUntaggedOnly ] = useStickyRefState({ id: `Tagger:viewUntaggedOnly`, defaultValue: true })
  let [ undoInfo, setUndoInfo, getUndoInfo ] = useRefState(DEFAULT_UNDO_INFO)
  const [ rulesOpenedAtLeastOnce, setRulesOpenedAtLeastOnce ] = useStickyRefState({ id: `Tagger:rulesOpenedAtLeastOnce`, defaultValue: false })
  const [ instructionsRead, setInstructionsRead ] = useStickyRefState({ id: `Tagger:instructionsRead`, defaultValue: false })
  const isLoggedIn = useIsLoggedIn()

  const translationRef = useEqualObjsMemo(getRefFromLoc(loc || ``))
  const { bookId, chapter } = translationRef || {}

  const {
    tags: currentlyAcceptedTags,
    tagSet,
    tagSetStatus,
    translationWords,
    originalRefs,
    originalPieces,
    originalWordPieces,
    wordsHash,
    loading,
  } = useTagSet({
    passageRef: translationRef,
    versionId,
    tagSetFetchPolicyNetworkNoRerun: true,
  })

  if(loading) {
    undoInfo = DEFAULT_UNDO_INFO  // this prevents undo from being available while loading
  }

  const { myTagSetSubmissions, loading: loadingMyTagSetSubmissions } = useDataQuery({
    myTagSetSubmissionsQuery,
    variables: {
      bookId,
      chapter,
      versionId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !loc,
  })

  const myTagSet = useMemo(
    () => {
      return (myTagSetSubmissions || []).find(({ id }) => id === `${loc}-${versionId}-${wordsHash}`)
    },
    [ myTagSetSubmissions, loc, versionId, wordsHash ],
  )

  const clearSelection = useCallback(
    event => {
      if(event && event.target && event.target.closest(`.options-popper, .options-popper-clear-cover`)) return
      setSelectedOriginalWordIdAndPartNumbers([])
    },
    [ setSelectedOriginalWordIdAndPartNumbers ],
  )

  const fillOutAndSortTags = useCallback(
    tags => {

      tags = cloneObj(tags)

      if(!loading) {

        // put in all the remaining original words
        ;(originalWordPieces || [])
          .map(piece => (
            bookId < 40
              ? (piece.children || [null]).map((x, idx) => `${piece[`x-id`]}|${idx + 1}`)
              : piece[`x-id`]
          ))
          .flat()
          .filter(wordIdAndPartNumber => !tags.some(({ o }) => o.includes(wordIdAndPartNumber)))
          .forEach(wordIdAndPartNumber => {
            tags.push({
              o: [ wordIdAndPartNumber ],
              t: [],
            })
          })

        // put in all the remaining translation words
        ;(translationWords || [])
          .filter(({ wordNumberInVerse }) => !tags.some(({ t }) => t.includes(wordNumberInVerse)))
          .forEach(({ wordNumberInVerse }) => {
            tags.push({
              o: [],
              t: [ wordNumberInVerse ],
            })
          })

      }

      return sortTagSetTags(tags)

    },
    [ originalWordPieces, translationWords, bookId, loading ],
  )

  const [ tags, setTagsSticky, getTags ] = useStickyRefState({
    id: `Tagger:tags:${versionId}:${loc}`,
    defaultValue: fillOutAndSortTags(myTagSet ? myTagSet.tags : currentlyAcceptedTags),
  })

  const {
    infoByWordIdAndPartNumber,
  } = useInterlinearInfo({
    versionId,
    tags,
    translationWords,
    originalWordPieces,  
    colorWordPartCombos: true,
    lightOrDark: `light`,
  })

  const goSubmitTagSet = useGoSubmitTagSet({
    tagSet,
    translationWords,
    getTags,
    setTagsSticky,  // this needed in order to trigger the sticky ref state to clear
  })

  const { previousLoc, nextLoc } = usePrevNextTaggingLocs({
    currentLoc: loc,
    versionId,
    viewUntaggedOnly,
  })

  const onClickPrevious = useMemo(
    () => {
      if(previousLoc) {
        return () => setLoc(previousLoc)
      }
    },
    [ previousLoc, setLoc ],
  )

  const onClickNext = useMemo(
    () => {
      if(nextLoc) {
        return () => setLoc(nextLoc)
      }
    },
    [ nextLoc, setLoc ],
  )

  const setTags = useCallback(
    (tags, { clearUndo, isUndo, isRedo }={}) => {

      tags = fillOutAndSortTags(tags)

      if(clearUndo) {
        setUndoInfo({ stack: [ cloneObj(tags) ], idx: -1 })
      } else {
        if(isUndo) {
          setSelectedOriginalWordIdAndPartNumbers([])
          setUndoInfo({
            ...cloneObj(getUndoInfo()),
            idx: getUndoInfo().idx - 1,
          })
        } else if(isRedo) {
          setSelectedOriginalWordIdAndPartNumbers([])
          setUndoInfo({
            ...cloneObj(getUndoInfo()),
            idx: getUndoInfo().idx + 1,
          })
        } else {
          const stack = [
            ...cloneObj(getUndoInfo().stack.slice(0, getUndoInfo().idx + 2)),
            cloneObj(tags),
          ]
          setUndoInfo({
            stack,
            idx: stack.length - 2,
          })
        }
      }

      setTagsSticky(tags)

    },
    [ getUndoInfo, setUndoInfo, setTagsSticky, setSelectedOriginalWordIdAndPartNumbers, fillOutAndSortTags ],
  )

  useLayoutEffectAsync(
    () => {
      if(!loading) {
        setTags(tags, { clearUndo: true })
      }
    },
    [ loc, versionId, loading ],
  )

  useLayoutEffectAsync(
    () => {
      if(
        !loading
        && (currentlyAcceptedTags || []).length > 0
        && removeUnassociatedTags(tags).length === 0
      ) {
        setTags(currentlyAcceptedTags, { clearUndo: true })
      }
    },
    [ currentlyAcceptedTags, loading ],
  )

  useLayoutEffectAsync(
    () => {
      const { versionId, loc } = queryString.parse(window.location.search)
      if(versionId) setVersionId(versionId)
      if(loc) setLoc(loc)
      history.replace({ search: null })
    },
    [],
  )

  return (
    <>

      <TaggerHeader
        {...props}
        versionId={versionId}
        setVersionId={setVersionId}
        loc={loc}
        setLoc={setLoc}
        tags={tags}
        setTags={setTags}
        undoInfo={undoInfo}
        myTagSet={myTagSet}
        currentlyAcceptedTags={currentlyAcceptedTags}
        tagSetStatus={tagSetStatus}
        onClickPrevious={onClickPrevious}
        onClickNext={onClickNext}
        viewUntaggedOnly={viewUntaggedOnly}
        setViewUntaggedOnly={setViewUntaggedOnly}
        setRulesOpenedAtLeastOnce={setRulesOpenedAtLeastOnce}
        ready={instructionsRead && isLoggedIn}
      />

      <Container onClick={clearSelection} >
        <StyledContainerWithPassagePopper
          versionId={versionId}
          bookId={bookId}
          topOffset={48}
        >

          {!(instructionsRead && loc && isLoggedIn) &&
            <TaggerInstructions
              instructionsRead={instructionsRead}
              setInstructionsRead={setInstructionsRead}
              rulesOpenedAtLeastOnce={rulesOpenedAtLeastOnce}
              isLoggedIn={isLoggedIn}
            />
          }

          {!!(instructionsRead && loc && isLoggedIn) &&
            <TaggerContent
              key={`${loc}-${versionId}`}
              versionId={versionId}
              loc={loc}
              originalRefs={originalRefs}
              originalPieces={originalPieces}
              infoByWordIdAndPartNumber={infoByWordIdAndPartNumber}
              tags={tags}
              setTags={setTags}
              loading={loading}
              loadingMyTagSetSubmissions={loadingMyTagSetSubmissions}
              myTagSet={myTagSet}
              currentlyAcceptedTags={currentlyAcceptedTags}
              selectedOriginalWordIdAndPartNumbers={selectedOriginalWordIdAndPartNumbers}
              setSelectedOriginalWordIdAndPartNumbers={setSelectedOriginalWordIdAndPartNumbers}
              clearSelection={clearSelection}
              tagSetStatus={tagSetStatus}
              onClickPrevious={onClickPrevious}
              onClickNext={onClickNext}
              goSubmitTagSet={goSubmitTagSet}
            />
          }

        </StyledContainerWithPassagePopper>
      </Container>

    </>
  )
}


export default memo(Tagger)