import { memo, useState, useCallback } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { useMeasure } from 'react-use'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { getTextLanguageId, isRTLText } from "@bibletags/bibletags-ui-helper"

import { getPassageTextSize, getPassageLineSpacing, cloneObj, equalObjs } from '../../../utils/misc'
import useGoUndo from '../../../hooks/useGoUndo'
import useMarkupSettings from '../../../hooks/useMarkupSettings'
import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import useFormattingKeys from '../../../hooks/useFormattingKeys'
import useVersionInfo from '../../../hooks/useVersionInfo'
import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import useMirrorRefs from '../../../hooks/useMirrorRefs'
import useDotNotes from '../../../hooks/useDotNotes'
import { KJV_VERSION } from '../../../utils/constants'

import ModuleContainer from '../shared/ModuleContainer'
import NewModuleHeadingAndPassageChooser from '../shared/NewModuleHeadingAndPassageChooser'
// import NewToMarkup from './NewToMarkup'
import ModuleHeaderBackground from '../shared/ModuleHeaderBackground'
import MarkupOptionsChangePassage from './MarkupOptionsChangePassage'
import MarkupSettings from './MarkupSettings'
import ModuleTitleButton from '../shared/ModuleTitleButton'
import EditingButton from '../shared/EditingButton'
import MarkupMainPanel from './MarkupMainPanel'
import MarkupKey from './MarkupKey'

const noop = ()=>{}

const ContentContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 0;

  ${({ $noneditableViewingMode }) => ![ `shared-page`, `sketch`].includes($noneditableViewingMode) ? `` : `
    padding: 0 15px;

    @media (max-width:600px) {
      padding: 0 10px;
    }
  `}

  ${({ $noneditableViewingMode }) => $noneditableViewingMode !== `within-notes` ? `` : `
    justify-content: flex-start;
  `}
`

const Markup = ({
  module,
  projectId,
  moduleByProject,
  onDeleteModuleByProject,
  noneditableViewingMode,
  printOrDownloadInfo,
  goPrintOrDownload,
  // goPrintEncompassingNotesTab,
  goSketch,
  onLoad,
  setHeight,
  embedFullScreen,
  ...otherProps
}) => {

  const { id, modulePassages } = module
  const { fromLoc, info={} } = modulePassages[0] || {}
  const { bookId } = getRefFromLoc(fromLoc || ``)
  const { versionId } = info

  let [ measureRef, { width: moduleWidth } ] = useMeasure()
  moduleWidth = moduleWidth || otherProps.width || 0
  const ref = useMirrorRefs(measureRef)
  const minimizedKey = moduleWidth < 600

  const inEditingMode = module.inEditingMode && !noneditableViewingMode

  const { version=KJV_VERSION } = useVersionInfo(versionId)

  const goPrintOrDownloadWithBaseParams = useCallback(
    params => {
      goPrintOrDownload({
        module,
        projectId,
        ...params,
      })
    },
    [ goPrintOrDownload, module, projectId ],
  )

  useEffectAsync(
    () => {
      // TODO: not sure what this is for
      if(onLoad) {
        onLoad()
      }
    },
    [],
  )

  const { formattingKeysById } = useFormattingKeys()
  const markupSettings = useMarkupSettings({ moduleId: id })
  const {
    textSizesSetting,
    lineSpacingsSetting,
    formattingKeyInfosSetting,
    currentFrmtgKeyIdSetting,
    lensesSetting,
    moduleSettingsLoading,
    showContextSetting,
  } = markupSettings

  const filteredFormattingKeyInfos = useEqualObjsMemo(formattingKeyInfosSetting.value.filter(({ id }) => formattingKeysById[id]))

  const [ goSetFormattingKeyInfos ] = useGoSetModuleSetting({
    moduleSetting: formattingKeyInfosSetting,
    projectId,
  })

  const [ goSetCurrentFormattingKeyId ] = useGoSetModuleSetting({
    moduleSetting: currentFrmtgKeyIdSetting,
    projectId,
  })

  const [ goSetShowContext ] = useGoSetModuleSetting({
    moduleSetting: showContextSetting,
    projectId,
  })

  const [ displayedTextSizes, setDisplayedTextSizes ] = useState(textSizesSetting.value)
  const [ displayedLineSpacings, setDisplayedLineSpacings ] = useState(lineSpacingsSetting.value)
  const [ selectedColor, setSelectedColor ] = useState(`ALL`)
  const [ currentLensId, setCurrentLensId ] = useState()

  const formattingKeyInfo = filteredFormattingKeyInfos.find(({ id }) => id === currentLensId) || filteredFormattingKeyInfos[0]
  const formattingKeyInfoId = (formattingKeyInfo || {}).id

  const filter = useCallback(
    moduleDot => {
      const { positionInfo={}, color } = moduleDot
      return (
        (
          lensesSetting.value === `OFF`
          || positionInfo.formattingKeyId === formattingKeyInfoId
        )
        && (
          [ color, `ALL`, undefined, null ].includes(selectedColor)
          || color === `GREY`
        )
      )
    },
    [ lensesSetting.value, formattingKeyInfoId, selectedColor ],
  )

  const {
    dotNotesButton,
    dotNotePlacementCover,
    moduleDots,
    setPlacingInfo,
  } = useDotNotes({
    projectId,
    moduleId: id,
    inEditingMode,
    filter,
    formattingKeyInfoId,
  })

  const prepAction = useCallback(
    async ({ items=[] }) => {
      for(let item of items) {
        if(/ModuleMarkup$/.test(item.action)) {
          const data = {
            ...(item.data || {}),
            ...(item.initData || {}),
            ...(item.updateObj || {}),
          }
          const { color, container } = data
          if(container !== `formattingKeyId:${formattingKeyInfoId}`) {
            const formattingKeyInfo = filteredFormattingKeyInfos.find(({ id }) => `formattingKeyId:${id}` === container)
            if(formattingKeyInfo) {
              setCurrentLensId(formattingKeyInfo.id)
              setSelectedColor(`ALL`)
              break
            }
          } else if(![ color, `ALL` ].includes(selectedColor)) {
            setSelectedColor(`ALL`)
            break
          }
        } else if(/ModuleSetting$/.test(item.action)) {
          if(
            /ing-context$/.test(item.data.id)
            && !showContextSetting.value
          ) {
            goSetShowContext({ value: true })
            await new Promise(resolve => setTimeout(resolve, 50))
            break
          } else if(/:formatting-key-infos$/.test(item.data.id)) {
            const lensUpdatesById = {}
            item.updateObj.value.forEach(lens => {
              lensUpdatesById[lens.id] = lens
            })
            if(item.data.value.some(lens => {
              if(
                !equalObjs(lens, lensUpdatesById[lens.id])
                && lens.id !== formattingKeyInfoId
              ) {
                setCurrentLensId(lens.id)
                setSelectedColor(`ALL`)
                return true
              }
              return false
            })) break
          }
        }
      }
    },
    [ filteredFormattingKeyInfos, formattingKeyInfoId, selectedColor, showContextSetting.value, setCurrentLensId, goSetShowContext ],
  )

  const { goUndo, goRedo, undoComponent } = useGoUndo({
    moduleId: id,
    projectId,
    moduleContainerRef: ref,
    prepAction,
  })

  const updateFormattingKeyInfo = useCallback(
    newFormattingKeyInfo => {
      const newFormattingKeyInfos = cloneObj(formattingKeyInfosSetting.value)
      const index = newFormattingKeyInfos.findIndex(({ id }) => id === formattingKeyInfoId)
      if(index !== -1) {
        newFormattingKeyInfos[index] = newFormattingKeyInfo
      }
      goSetFormattingKeyInfos({ value: newFormattingKeyInfos })
    },
    [ formattingKeyInfosSetting.value, goSetFormattingKeyInfos, formattingKeyInfoId ],
  )

  const onClick = useCallback(
    ({ target, currentTarget }) => {
      if(target && target.closest && target.closest(`input[type="text"], textarea, [contenteditable]`)) return
      try {
        currentTarget.querySelector(`.MarkupMainPanel-Container`).focus()
      } catch(e) {}
    },
    [],
  )

  useLayoutEffectAsync(
    () => {
      // set initial lens being viewed, but then allow different tabs to be on different lenses
      if(!moduleSettingsLoading) {
        setCurrentLensId(
          currentFrmtgKeyIdSetting.value
          || (filteredFormattingKeyInfos[0] || {}).id
        )
      }
    },
    [ moduleSettingsLoading ],
  )

  useEffectAsync(
    () => {
      if(
        currentLensId
        && currentLensId !== currentFrmtgKeyIdSetting.value
        && !noneditableViewingMode
      ) {
        goSetCurrentFormattingKeyId({ value: currentLensId })
      }
    },
    [ currentLensId ],
  )

  useLayoutEffectAsync(
    () => {
      setDisplayedTextSizes(textSizesSetting.value)
    },
    [ textSizesSetting, setDisplayedTextSizes ],
  )

  useLayoutEffectAsync(
    () => {
      setDisplayedLineSpacings(lineSpacingsSetting.value)
    },
    [ lineSpacingsSetting, setDisplayedLineSpacings ],
  )

  useLayoutEffectAsync(
    () => {
      setSelectedColor(`ALL`)
    },
    [ currentLensId ],
  )

  const adjustmentType = (
    (displayedTextSizes !== textSizesSetting.value && `textsizes`)
    || (displayedLineSpacings !== lineSpacingsSetting.value && `linespacings`)
  )
  const languageId = getTextLanguageId({ ...version, bookId })
  const textFontSize = getPassageTextSize({ textSizes: displayedTextSizes, languageId })
  const lineSpacing = getPassageLineSpacing({ lineSpacingSizes: displayedLineSpacings, languageId })
  const isRTL = isRTLText({ languageId, bookId })

  return (
    <ModuleContainer 
      ref={ref}
      noneditableViewingMode={noneditableViewingMode}
      className="Markup-ModuleContainer"
      onClick={onClick}
      moduleId={module.id}
      {...otherProps}
    >

      {!noneditableViewingMode && modulePassages.length === 0 &&
        <NewModuleHeadingAndPassageChooser
          heading={i18n("New Markup", "", "markup")}
          module={module}
          moduleByProject={moduleByProject}
          setUpPiecesAfterPassageChange={noop}
          moduleWidth={moduleWidth}
          // newOrLearning={<NewToMarkup />}
          maxChapterSpan={3}
        />
      }

      {modulePassages.length > 0 &&
        <ContentContainer
          $noneditableViewingMode={noneditableViewingMode}
        >

          {!noneditableViewingMode && !printOrDownloadInfo &&
            <>

              <ModuleHeaderBackground />

              <ModuleTitleButton
                module={module}
                moduleByProject={moduleByProject}
                showVersionAbbr
                onDeleteModuleByProject={onDeleteModuleByProject}
                goPrintOrDownload={goPrintOrDownloadWithBaseParams}
                goSketch={goSketch}
                goUndo={goUndo}
                width={moduleWidth}
                hidePrintAndDownload
                // absolutePositionedSection={
                // }
                topSection={
                  <MarkupOptionsChangePassage
                    module={module}
                    projectId={projectId}
                  />
                }
                settingsSection={
                  <MarkupSettings
                    projectId={projectId}
                    versionId={versionId}
                    languageId={languageId}
                    displayedTextSizes={displayedTextSizes}
                    displayedLineSpacings={displayedLineSpacings}
                    setDisplayedTextSizes={setDisplayedTextSizes}
                    setDisplayedLineSpacings={setDisplayedLineSpacings}
                    {...markupSettings}
                  />
                }
                $adjustmentType={adjustmentType}
              />

              {dotNotesButton}

              <EditingButton
                module={module}
              />

            </>
          }

          {noneditableViewingMode === `embed` &&
            <ModuleTitleButton
              module={module}
              width={moduleWidth}
              disabled
              showVersionAbbr
            />
          }

          <MarkupMainPanel
            module={module}
            projectId={projectId}
            inEditingMode={inEditingMode}
            noneditableViewingMode={noneditableViewingMode}
            isRTL={isRTL}
            textFontSize={textFontSize}
            lineSpacing={lineSpacing}
            goUndo={goUndo}
            goRedo={goRedo}
            goPrintOrDownload={goPrintOrDownloadWithBaseParams}
            embedFullScreen={embedFullScreen}
            moduleSettingsLoading={moduleSettingsLoading}
            formattingKeyInfo={formattingKeyInfo}
            updateFormattingKeyInfo={updateFormattingKeyInfo}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            filteredFormattingKeyInfos={filteredFormattingKeyInfos}
            formattingKeyInfoId={formattingKeyInfoId}
            setHeight={setHeight}
            moduleDots={moduleDots}
            setPlacingInfo={setPlacingInfo}
            minimizedKey={minimizedKey}
            dotNotePlacementCover={dotNotePlacementCover}
            moduleWidth={moduleWidth}
            setCurrentLensId={setCurrentLensId}
            {...markupSettings}
          />

          {lensesSetting.value === `ON` &&
            <MarkupKey
              formattingKeyInfosSetting={formattingKeyInfosSetting}
              filteredFormattingKeyInfos={filteredFormattingKeyInfos}
              formattingKeyInfoId={formattingKeyInfoId}
              inEditingMode={inEditingMode}
              noneditableViewingMode={noneditableViewingMode}
              minimized={minimizedKey}
              printOrDownloadInfo={printOrDownloadInfo}
              projectId={projectId}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              loading={moduleSettingsLoading || !currentLensId}
              moduleDots={moduleDots}
              setPlacingInfo={setPlacingInfo}
              dotNotePlacementCover={dotNotePlacementCover}
              setCurrentLensId={setCurrentLensId}
            />
          }

        </ContentContainer>
      }

      {undoComponent}

    </ModuleContainer>
  )
}

export default memo(Markup)


// const formattingKeys = [
//   {
//     id: "lkjsdf-sdfsdfsdf-sdfd",
//     name: "Commands+",
//     type: "MARKUP",
//     info: {
//       styles: [
//         {
//           markupType: "BASE-HIGHLIGHT",
//           label: "Commands",
//         },
//       ],
//       colors: [
//         {
//           color: "RED",
//           type: "Main Topic",
//           example: "Love one another",
//           value: "?",
//         },
//       ],
//       colorDefault: {
//         type: "Secondary Topic",
//       },
//       sourceId: "fkdjfjkdf-df-df-dfsfsdfsdf-d",
//     },
//     public: false,
//   }
// ]

// const moduleSettings = {
//   formattingKeyInfos: [
//     {
//       id: "sdf",  // if a custom formattingKey is deleted, this data remains since those can be undeleted
//       colors: [
//         {
//           color: "RED",
//           value: "Main Topic",
//         },
//       ],
//     },
//   ],
//   currentFrmtgKeyId,  // the page they are on
// }