import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import OtherSearchResultsHeader from './OtherSearchResultsHeader'
import AppItemSearchResultsRow from './AppItemSearchResultsRow'

const Container = styled.div`
`

const AppItemSearchResults = ({
  suggestions,
  doMenuSearchItemAction,
}) => {

  return (
    <Container>
    
      <OtherSearchResultsHeader>
        {i18n("In the App")}
      </OtherSearchResultsHeader>

      {suggestions.map(suggestion => (
        <AppItemSearchResultsRow
          key={suggestion.suggestedQuery}
          doMenuSearchItemAction={doMenuSearchItemAction}
          {...suggestion}
        />
      ))}

    </Container>
  )
}

export default memo(AppItemSearchResults)