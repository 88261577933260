import { gql } from '@apollo/client'

import channelItem_fields from '../fragments/channelItem_fields'

const channelItem = gql`
  query channelItem($id: ID!) {
    channelItem(id: $id) {
      ${channelItem_fields}
    }
  }
`

export default channelItem