import { selectTextOfEl } from '../../../../utils/misc'

const keyDownA = async params => {

  const {
    event,
    modifierKey,
  } = params

  if(!modifierKey) return

  event.preventDefault()

  const selection = document.getSelection()

  const textRange = selection.getRangeAt(0)
  let commonAncestorEl = textRange && textRange.commonAncestorContainer
  if(commonAncestorEl && commonAncestorEl.nodeType !== Node.ELEMENT_NODE) {
    commonAncestorEl = commonAncestorEl.parentElement
  }
  const outlineSelectAllContainer = commonAncestorEl && commonAncestorEl.closest(`.outline-select-all-container`)

  if(!outlineSelectAllContainer) return

  selectTextOfEl(outlineSelectAllContainer)

}

export default keyDownA