import { memo, useContext, useState, useCallback, useMemo } from 'react'
import { useLocation } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import { useMutation } from '@apollo/client'

import { LoggedInUserContext } from '../../context/LoggedInUser'
import useMutationContext from '../../hooks/useMutationContext'

import InfoDialog from '../common/InfoDialog'
import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'
import StudyBibleFollowAddCSSB from './StudyBibleFollowAddCSSB'
import StudyBibleFollowAddSermonAudio from './StudyBibleFollowAddSermonAudio'
import StudyBibleFollowAddLogos from './StudyBibleFollowAddLogos'
import StudyBibleFollowAddPopular from './StudyBibleFollowAddPopular'
import MyPlanPromoSpot from '../common/MyPlanPromoSpot'

import createStudyBibleFollowMutation from '../../graphql/mutations/createStudyBibleFollow'

const MAX_CHANNELS = 15

const Null = () => null

const StyledInfoDialog = styled(InfoDialog)`

  @media (max-width: 400px) {
    .MuiDialog-paper {
      margin: 0;
      height: 100dvh;
      max-height: none;
    }
  }

  .MuiDialogContent-root {
    padding-bottom: 25px;
  }

`

const Container = styled.div`
`

const Explanation = styled.div`
  margin: -8px 0 5px;
  padding: 7px 12px;
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  background: ${({ theme }) => theme.palette.secondary.main}33;
  border-radius: 4px;
`

const ReorderEtc = styled.div`
  margin-top: 10px;
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  display: inline-block;
`

const StyledStepper = styled(Stepper)`
  padding: 24px 0;

  .MuiStepLabel-iconContainer .MuiStepIcon-root {
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  .MuiStepLabel-labelContainer {
    min-width: 0;
  }

  .MuiStepLabel-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    cursor: default;
  }

  .MuiStepLabel-completed {
    transition: padding .15s ease-in-out;

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        padding-left: 5px;
      }
    }
  }
`

const StepContentInstructions = styled.div`
  margin: 0 0 10px;
`

const ChannelTypeButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  .MuiButton-root {
    max-width: 100%;
  }

  .MuiButton-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-start;
    display: block;
  }
`

const SelectedChannelType = styled.span`
  font-weight: 300;
`

const SelectedChannel = styled.span`
  font-weight: bold;
`

const ConfirmButtonContainer = styled.div`
`

const ChannelMax = styled.div`
  text-align: center;
  margin: 80px 0;
  font-weight: 300;
`

const StudyBibleFollowAddDialog = ({
  onClose,
  ...otherProps
}) => {

  const { studyBibleFollows=[], hasMyPlan } = useContext(LoggedInUserContext) || {}
  const location = useLocation()

  const [ activeStep, setActiveStep ] = useState(0)
  const [ channelTypeId, setChannelTypeId ] = useState()
  const [ channelInfo, setChannelInfo ] = useState()
  const [ saving, setSaving ] = useState(false)
  const [ justFollowedSomething, setJustFollowedSomething ] = useState(false)

  const [ createStudyBibleFollow ] = useMutation(createStudyBibleFollowMutation)
  const context = useMutationContext()

  const hasChannelMax = studyBibleFollows.length >= MAX_CHANNELS

  const channelTypes = useMemo(
    () => ([
      {
        id: `CHANNEL`,
        label: i18n("Church-Specific Study Bible"),
        Component: StudyBibleFollowAddCSSB,
      },
      {
        id: `RECOMMENDED`,
        label: i18n("Popular Ministry"),
        Component: StudyBibleFollowAddPopular,
      },
      {
        id: `SERMON-AUDIO`,
        label: i18n("SermonAudio"),
        Component: StudyBibleFollowAddSermonAudio,
      },
      {
        id: `SERMONS-BY-LOGOS`,
        label: i18n("Sermons by Logos"),
        Component: StudyBibleFollowAddLogos,
      },
    ]),
    [],
  )

  const setChannel = useCallback(
    info => {
      setChannelInfo(info)
      setActiveStep(2)
    },
    [ setChannelInfo, setActiveStep],
  )

  const onConfirm = useCallback(
    async () => {

      setSaving(true)

      const input = (
        channelTypeId === `CHANNEL`
          ? { channelId: channelInfo.id }
          : { channelUrl: channelInfo.channelUrl }
      )

      await createStudyBibleFollow({
        variables: {
          input,
        },
        context,
      })

      setSaving(false)
      setJustFollowedSomething(true)

    },
    [ createStudyBibleFollow, context, channelInfo, channelTypeId ],
  )

  const channelType = channelTypes.find(({ id }) => id === channelTypeId)
  const ChannelTypeComponent = (channelType || {}).Component || Null
  const channelName = channelInfo && (channelInfo.name || channelInfo.displayName || channelInfo.label)

  const alreadyFollowing = !!channelInfo && !!studyBibleFollows.find(({ channelId, channelUrl }) => (
    channelId === channelInfo.id
    || channelUrl === channelInfo.channelUrl
  ))

  return (
    <StyledInfoDialog
      {...otherProps}
      $hasMyPlan={hasMyPlan}
      title={i18n("Add Channels")}
      onOkay={onClose}
      showCloseIconButton
      explanation={
        <Container>

          {hasMyPlan &&
            <>

              <Explanation>
                {i18n("View sermons from your church and popular ministries right along side your Bible.")}
              </Explanation>

              {!hasChannelMax &&
                <StyledStepper
                  activeStep={activeStep}
                  orientation="vertical"
                >

                  <Step>
                    <StepLabel
                      onClick={activeStep <= 0 ? null : () => {
                        setChannelTypeId()
                        setChannelInfo()
                        setActiveStep(0)
                        setJustFollowedSomething(false)
                      }}
                    >
                      {channelType
                        ? i18nReact("Select Channel Type: {{type}}", {
                          type: (
                            <SelectedChannelType>
                              {channelType.label}
                            </SelectedChannelType>
                          )
                        })
                        : i18n("Select Channel Type")
                      }
                    </StepLabel>
                    <StepContent>
                      <StepContentInstructions>
                        {i18n("Select the type of channel you want to follow.")}
                      </StepContentInstructions>
                      <ChannelTypeButtons>
                        {channelTypes.map(({ id, label }) => (
                          <Button
                            key={id}
                            variant="contained"
                            disableElevation
                            onClick={() => {
                              setChannelTypeId(id)
                              setActiveStep(1)
                            }}
                            size="small"
                          >
                            {label}
                          </Button>
                        ))}
                      </ChannelTypeButtons>
                    </StepContent>
                  </Step>

                  <Step>
                    <StepLabel
                      onClick={activeStep <= 1 ? null : () => {
                        setChannelInfo()
                        setActiveStep(1)
                        setJustFollowedSomething(false)
                      }}
                    >
                      {channelInfo
                        ? i18nReact("Choose a Channel: {{name}}", {
                          name: (
                            <SelectedChannelType>
                              {channelName}
                            </SelectedChannelType>
                          )
                        })
                        : i18n("Choose a Channel")
                      }
                    </StepLabel>
                    <StepContent>
                      <ChannelTypeComponent
                        setChannel={setChannel}
                        studyBibleFollows={studyBibleFollows}
                      />
                    </StepContent>
                  </Step>

                  <Step>
                    <StepLabel>
                      {i18n("Confirm")}
                    </StepLabel>
                    <StepContent>
                      {alreadyFollowing && !justFollowedSomething &&
                        <StepContentInstructions>
                          {i18nReact("You are already following {{channel}}.", {
                            channel: (
                              <SelectedChannel>
                                {channelName}
                              </SelectedChannel>
                            )
                          })}
                        </StepContentInstructions>
                      }
                      {alreadyFollowing && justFollowedSomething &&
                        <>
                          <StepContentInstructions>
                            {i18nReact("You are now following {{channel}}.", {
                              channel: (
                                <SelectedChannel>
                                  {channelName}
                                </SelectedChannel>
                              )
                            })}
                          </StepContentInstructions>
                          <ConfirmButtonContainer>
                            <Button
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                setChannelTypeId()
                                setChannelInfo()
                                setActiveStep(0)
                                setJustFollowedSomething(false)
                              }}
                            >
                              {i18n("Add another channel")}
                            </Button>
                          </ConfirmButtonContainer>
                        </>
                      }
                      {!alreadyFollowing &&
                        <>
                          <StepContentInstructions>
                            {i18nReact("Do you want to follow {{channel}}?", {
                              channel: (
                                <SelectedChannel>
                                  {channelName}
                                </SelectedChannel>
                              )
                            })}
                          </StepContentInstructions>
                          <ConfirmButtonContainer>
                            <Button
                              variant="contained"
                              disableElevation
                              color="secondary"
                              onClick={onConfirm}
                            >
                              {i18n("Follow")}
                            </Button>
                          </ConfirmButtonContainer>
                        </>
                      }
                    </StepContent>
                  </Step>

                </StyledStepper>
              }

              {hasChannelMax &&
                <ChannelMax>
                  {i18n("You have reached the maximum number of non-Biblearc channels you can follow.")}
                </ChannelMax>
              }

              {location.pathname !== "/settings/channels" &&
                <ReorderEtc>
                  {i18nReact("Note: Reorder channel priority and unfollow by going to {{settings_channels}}.", {
                    settings_channels: (
                      <StyledNavLinkOrAWithDisable
                        to="/settings/channels"
                        onClick={onClose}
                      >
                        {i18n("Settings > Channels")}
                      </StyledNavLinkOrAWithDisable>
                    ),
                  })}
                </ReorderEtc>
              }

            </>
          }

          <MyPlanPromoSpot
            subscribeToMessage={i18n("Subscribe to add ministry channels.")}
          />

        </Container>
      }
      loading={saving}
    />
  )
}

export default memo(StudyBibleFollowAddDialog)