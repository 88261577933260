import { gql } from '@apollo/client'

import studyBibleItem_fields from '../fragments/studyBibleItem_fields'

export default gql
`
  mutation createStudyBibleItem($input: StudyBibleItemInput!) {
    createStudyBibleItem(input: $input) {
      ${studyBibleItem_fields}
    }
  }
`