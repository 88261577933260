import { memo } from 'react'

import ArrowHighlight from './ArrowHighlight'

const ArrowHighlightRight = ({
  startType,
  endType,
  ...otherProps
}) => (
  <ArrowHighlight
    {...otherProps}
    startType="tail"
    endType="point"
  />
)


export default memo(ArrowHighlightRight)