import { memo } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'

import FadedLoading from './FadedLoading'
import useLogInCallback from '../../hooks/useLogInCallback'

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 7px 0;
`

const StyledButton = styled(Button)`
  overflow: hidden;
`

const SignInButton = ({
  label=i18n("Sign in"),
  ...otherProps
}) => {

  const { logIn, navigatingToLogin } = useLogInCallback()

  return (
    <Container>
      <StyledButton
        variant="contained"
        color="primary"
        disableElevation
        onClick={logIn}
        {...otherProps}
      >
        {label}
        {navigatingToLogin && <FadedLoading size={20} />}
      </StyledButton>
    </Container>
  )

}

export default memo(SignInButton)