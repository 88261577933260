import { memo, useMemo, useCallback } from 'react'
import { BarChart, Bar, XAxis, Legend, Tooltip, LabelList } from 'recharts'
import styled from 'styled-components'

import { cloneObj } from '../../../utils/misc'

const StyledBarChart = styled(BarChart)`
  svg {
    overflow: visible;
  }

  g:first-of-type .recharts-label {
    fill: white;
  }

  .recharts-cartesian-axis-tick-value {
    font-size: ${({ $fontSize }) => $fontSize}px;
  }
`

const StatsBarChart = ({
  data,
  xAxisLabel,
  width=800,
  totalKey,
  color,
}) => {

  data = data || []

  const { dataKeys, adjustedData } = useMemo(
    () => {
      const dataKeys = Object.keys(data[0] || {}).filter(dataKey => ![ xAxisLabel ].includes(dataKey))
      const adjustedData = cloneObj(data)

      if(totalKey) {
        dataKeys.sort((a,b) => a === totalKey ? 1 : -1)
        adjustedData.forEach(obj => {
          obj[totalKey] -= dataKeys.reduce((total, dataKey) => total + (dataKey === totalKey ? 0 : obj[dataKey]), 0)
        })
      }

      return {
        dataKeys,
        adjustedData,
      }
    },
    [ data, totalKey, xAxisLabel ],
  )

  const totalTooltipFormatting = useCallback(
    (value, name, props) => {

      if(totalKey === name) {
        value += dataKeys.reduce((total, dataKey) => total + (dataKey === totalKey ? 0 : props.payload[dataKey]), 0)
      }

      return [
        value,
        name,
      ]
    },
    [ dataKeys, totalKey ],
  )

  return (
    <StyledBarChart
      width={width}
      height={300}
      data={adjustedData}
      $fontSize={adjustedData.length > 10 ? 10 : null}
    >
      <Tooltip
        formatter={totalTooltipFormatting}
        itemStyle={{
          whiteSpace: `pre`,
          lineHeight: 1.2,
        }}
      />

      {dataKeys.map((dataKey, idx) => (
        <Bar
          key={idx}
          stackId="a"
          dataKey={dataKey}
          fill={(idx === dataKeys.length - 1 && color) || `black`}
        >
          {dataKeys.length === 1 &&
            <LabelList
              dataKey={dataKey}
              position="center"
            />
          }
        </Bar>
      ))}
      <XAxis
        dataKey={xAxisLabel}
        interval={0}
      />
      {dataKeys.length > 1 && <Legend />}
    </StyledBarChart>
  )
}

export default memo(StatsBarChart)