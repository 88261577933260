import { memo } from 'react'
import styled from 'styled-components'

const SearchResultsError = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-weight: 200;
  text-align: center;
`

export default memo(SearchResultsError)