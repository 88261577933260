import React, { memo, useCallback } from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router-dom"

import {
  GET_STUDY_BIBLE_ITEM_TYPES,
} from '../../utils/constants'

const Tag = styled.span`
  display: inline-block;
  font-weight: normal;
  margin-left: 2px;
  color: ${({ $type }) => GET_STUDY_BIBLE_ITEM_TYPES()[$type].color};
  transition: opacity .15s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: .5;
  }
`

const StudyBibleItemTags = ({
  tags,
  type,
}) => {

  const history = useHistory()

  const setSearchText = useCallback(
    searchText => {
      history.replace({
        hash: `#search=${encodeURIComponent(searchText)}`,
        state: {
          executeSearch: true,
        },
      })
    },
    [ history ],
  )

  return (
    (tags || []).filter(({ tag }) => !/^INTERNAL:/.test(tag)).map(({ tag }, idx) => (
      <React.Fragment key={idx}>
        {` `}
        <Tag
          $type={type}
          onClick={() => setSearchText(`#${tag}`)}
        >
          {`#${tag}`}
        </Tag>
      </React.Fragment>
    ))
  )

}

export default memo(StudyBibleItemTags)