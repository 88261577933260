import { memo, useCallback } from 'react'
import { i18n, getLocale } from 'inline-i18n'
// import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useMutation } from '@apollo/client'

import useSetChannelItemInfo from '../../hooks/useSetChannelItemInfo'
import useVersionInfos from '../../hooks/useVersionInfos'
import usePassageRefs from '../../hooks/usePassageRefs'
import { cloneObj, getTimeStringFromSeconds, preventDefaultEvent } from '../../utils/misc'
import useUpdateListeningHistory from '../../hooks/useUpdateListeningHistory'
import useAccountSetting from '../../hooks/useAccountSetting'

import addReactionToChannelItemMutation from '../../graphql/mutations/addReactionToChannelItem'
import removeReactionFromChannelItemMutation from '../../graphql/mutations/removeReactionFromChannelItem'
import { removeBookmarkFromReactions } from '../study-bible/ChannelItem'

const Main = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
`

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`

const Container = styled.div`
  padding: 10px 16px;
  justify-content: stretch;
  flex: 1;
  min-width: 240px;
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`

const ActionIconButton = styled(IconButton)`
  padding: 10px;
  margin: -5px -5px -5px 0;
  align-self: center;
  
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const Image = styled.img`
  width: 70px;
`

const PassageRefContainer = styled.div`
  font-size: 11px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 2px 0 5px;
`

const SpeakerImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  object-fit: cover;
  border-radius: 50%;
`

const Speaker = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 11px;
`

const SpeakerName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 1;
`

const Bookmarks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Bookmark = styled.div`
  display: flex;
  align-items: center;
`

const BookmarkLeft = styled.div`
  width: 65px;
  margin-right: -10px;
  display: flex;
  flex-direction: column;
`

const StyledBookmarkIcon = styled(BookmarkIcon)`
  font-size: 20px;
  margin: -1px 0 0 -3px;
  color: ${({ theme }) => theme.palette.primary.main};
`

const BookmarkTimestamp = styled.div`
  font-size: 12px;
  font-weight: bold;
`

const BookmarkDate = styled.div`
  font-size: 11px;
  font-weight: 300;
`

const BookmarkLine1 = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -3px;
`

const MainMenuBookmark = ({
  channelItem,
  channelIdInPWA,
  toggleDrawerOpen,
  setSnackbarInfo,
  deleteMode,
}) => {

  const {
    id,
    // url,
    title,
    thumbnailImageUrl,
    info,
    reactions,  
  } = channelItem || {}

  const { locSets=[], speakerName, speakerImageUrl, audioDuration, videoDuration } = info || {}
  const { details: { myCreatedAts={} }={} } = reactions || {}
  const [ defaultDoAudio ] = useAccountSetting(`def-channel-do-audio`, false)  // i.e. audio or video (when both available)
  const [ addReactionToChannelItem ] = useMutation(addReactionToChannelItemMutation)
  const [ removeReactionFromChannelItem ] = useMutation(removeReactionFromChannelItemMutation)

  const doAudio = (
    (
      defaultDoAudio
      && !!audioDuration
    )
    || !videoDuration
  )

  const setChannelItemInfo = useSetChannelItemInfo()
  const { defaultVersionId } = useVersionInfos()
  const updateListeningHistory = useUpdateListeningHistory({
    channelItem,
    doAudio,  // only relevant if no longer in listening history
    channelId: channelIdInPWA,
  })

  const passageRefs = usePassageRefs({
    locSets,
    convertToVersionId: defaultVersionId,
    abbreviated: locSets.length > 1,
  })

  const onClickListen = useCallback(
    event => {
      preventDefaultEvent(event)

      const bookmarkWithMs = event.target.closest(`[data-bookmark-with-ms]`).getAttribute(`data-bookmark-with-ms`)
      const currentSecs = parseInt(bookmarkWithMs.split(`:`)[1], 0) / 1000

      const studyBibleFollow = {
        id: `none`,
        channelId: channelIdInPWA,
      }

      updateListeningHistory({ currentSecs })

      setChannelItemInfo({
        studyBibleItem: {
          id,
          title,
          ...(channelIdInPWA ? { studyBibleFollow } : {}),
          type: `CHANNEL`
        },
        versionId: defaultVersionId,
        anchorEl: true,
      })

      toggleDrawerOpen(false)

    },
    [ channelIdInPWA, defaultVersionId, id, setChannelItemInfo, title, toggleDrawerOpen, updateListeningHistory ],
  )

  const onClickDelete = useCallback(
    event => {
      preventDefaultEvent(event)

      const type = event.target.closest(`[data-bookmark-with-ms]`).getAttribute(`data-bookmark-with-ms`)
      const reactions = removeBookmarkFromReactions({ type, updatedChannelItem: channelItem })
      const channelItemClone = cloneObj(channelItem)

      removeReactionFromChannelItem({
        variables: {
          channelItemId: channelItem.id,
          type,
        },
        optimisticResponse: {
          removeReactionFromChannelItem: {
            ...channelItem,
            reactions,
          },
        },
      })

      const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
      const snackbarInfo = {
        open: true,
        message: i18n("Bookmark deleted."),
        buttonLabel: i18n("Restore"),
        onClick: () => {
          addReactionToChannelItem({
            variables: {
              channelItemId: channelItem.id,
              input: {
                type,
              },
            },
            optimisticResponse: {
              addReactionToChannelItem: channelItemClone,
            },
          })
          closeSnackbar()
        },
        onClose: closeSnackbar,
      }
      setSnackbarInfo(snackbarInfo)                  

    },
    [ removeReactionFromChannelItem, channelItem, addReactionToChannelItem, setSnackbarInfo ],
  )

  return (
    <Container>

      <Image
        src={thumbnailImageUrl}
        className="dark-mode-exempt"
      />

      <Main>

        <Title>
          {title}
        </Title>

        <PassageRefContainer>
          {passageRefs}
        </PassageRefContainer>

        {!!speakerName &&
          <Speaker>
            <SpeakerImage
              src={speakerImageUrl}
              className="dark-mode-exempt"
            />
            <SpeakerName>
              {speakerName}
            </SpeakerName>
          </Speaker>
        }

      </Main>

      <Bookmarks>
        {Object.keys(myCreatedAts).map(bookmarkWithMs => (
          <Bookmark>
            <BookmarkLeft>
              <BookmarkLine1>
                <StyledBookmarkIcon />
                <BookmarkTimestamp>
                  {getTimeStringFromSeconds(parseInt(parseInt(bookmarkWithMs.split(`:`)[1], 0) / 1000, 0))}
                </BookmarkTimestamp>
              </BookmarkLine1>
              <BookmarkDate>
                {new Date(myCreatedAts[bookmarkWithMs]).toLocaleDateString(getLocale())}
              </BookmarkDate>
            </BookmarkLeft>
            <ActionIconButton
              data-bookmark-with-ms={bookmarkWithMs}
              onClick={deleteMode ? onClickDelete : onClickListen}
            >
              {deleteMode ? <DeleteIcon /> : <ArrowForwardIcon />}
            </ActionIconButton>
          </Bookmark>
        ))}
      </Bookmarks>

    </Container>
  )
}

export default memo(MainMenuBookmark)