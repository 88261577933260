import { useCallback } from "react"

const useOnChangeImage = ({ onChangeInfo, editStudyBibleImage }) => {

  const onChangeImage = useCallback(
    (type, maxW, maxH) => event => {
      maxH = maxH || maxW

      onChangeInfo(`${type}Url`)(event)

      const { target: { value: url } } = event
      const img = new Image()
      img.onload = async () => {
        const { naturalWidth, naturalHeight } = img
        const neededScale = Math.min(maxW / naturalWidth, maxH / naturalHeight, 1)
        if(neededScale < 1) {
          const { data: { editStudyBibleImage: newUrl } } = await editStudyBibleImage({
            variables: {
              url: url.replace(/^\/\//, `https://`),
              editInfo: {
                resize: [
                  naturalWidth * neededScale,
                  naturalHeight * neededScale,
                ],
              },
            },
          })
          onChangeImage(type, maxW, maxH)({ target: { value: newUrl } })
          return
        }
        const value = {
          width: naturalWidth / 2,
          height: naturalHeight / 2,
        }
        onChangeInfo(`${type}Dimensions`)({ target: { value } })
      }
      img.src = url
    },
    [ onChangeInfo, editStudyBibleImage ],
  )

  return onChangeImage
}

export default useOnChangeImage
