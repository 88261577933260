import { useCallback } from 'react'

import useShortcuts from './useShortcuts'
import keyDownEscape from '../components/modules/outline/shortcuts/keyDownEscape'
import keyDownP from '../components/modules/outline/shortcuts/keyDownP'
import keyDownD from '../components/modules/outline/shortcuts/keyDownD'
import keyDownSpace from '../components/modules/outline/shortcuts/keyDownSpace'
import keyDownA from '../components/modules/outline/shortcuts/keyDownA'
import keyDownZ from '../components/modules/outline/shortcuts/keyDownZ'

const keyDownFuncs = {
  keyDownEscape,
  keyDownP,
  keyDownD,
  keyDownSpace,
  keyDownA,
  keyDownZ,
}

const useOutlineShortcuts = params => {

  const {
    getModule,
  } = params

  const inCorrectContext = useCallback(
    event => {

      // check that we are focused on this module
      if(!document.activeElement) return false
      if(document.activeElement.getAttribute('data-module-id') !== getModule().id) return false

      // if(cancelClickOrMoveRef.current) {
      //   event.preventDefault()
      //   if(event.key === 'Escape') {
      //     cancelClickOrMoveRef.current()
      //   }
      //   return false
      // }

      return true

    },
    [ getModule ],
  )

  useShortcuts({
    keyDownFuncs,
    inCorrectContext,
    ...params,
  })

}

export default useOutlineShortcuts