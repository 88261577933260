import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'
import { useLocation } from "react-router-dom"
import { useUpdate } from 'react-use'

import { embedSettingOnClick, getEmbedId, getEmbedMode, setEmbedHeight, setUpdateEmbedOverflowHeight,
         setUpdateEmbedData, setUpdateEmbedSettings, getEmbedProjectId } from '../../../graphql/links/embedLink'
import { equalObjs } from '../../../utils/misc'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'

import EmbedModules from './EmbedModules'
import EmbedSearch from './EmbedSearch'

const TypeChooser = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.grey[100]}80;
  border-radius: 6px;
`

const LogoImg = styled.img`
  height: 38px;
  vertical-align: top;
  margin: 5px -6px 0;
`

const TypeChooserMessage = styled.div`
  margin: 5px 0 20px;
  font-size: 16px;
`

const TypeChooserButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

const Embed = ({ ...props }) => {

  const id = getEmbedId()

  const [ settings, setSettings ] = useState({})
  useLayoutEffectAsync(() => setUpdateEmbedSettings(setSettings), [])
  const { embedType } = settings

  const [ searchInfo, setSearchInfo ] = useState()
  const getSearchInfo = useInstanceValue(searchInfo)

  const [ ready, setReady ] = useState(false)
  const getReady = useInstanceValue(ready)

  const update = useUpdate()

  const [ embedOverflowHeight, setEmbedOverflowHeight ] = useState(0)
  useLayoutEffectAsync(() => setUpdateEmbedOverflowHeight(setEmbedOverflowHeight), [])
  const getEmbedOverflowHeight = useInstanceValue(embedOverflowHeight)

  const embedSetHeight = useCallback(
    height => {
      if(!height) return  // caused by browser non-render of scroll until iframe is in view; effect of setting to zero is to make iframe not visible, and so never rendered
      if([ `passages`, `richtextbox` ].includes(embedType)) {
        height -= getEmbedOverflowHeight()
      }
      setEmbedHeight(height)
    },
    [ embedType, getEmbedOverflowHeight ],
  )

  useLayoutEffectAsync(
    () => {
      setUpdateEmbedData(({ searchInfo }) => {
        if(!equalObjs(getSearchInfo(), searchInfo)) {
          setSearchInfo(searchInfo)
        }
        if(!getReady()) setReady(true)
      })
    },
    [],
  )

  const location = useLocation()
  useEffectAsync(
    () => {
      if(getEmbedId() !== location.pathname.split('/')[3]) {
        // shouldn't ever get here
        throw new Error(`Cannot navigate a Biblearc task.`)
      }
    },
    [ location ],
  )
  useEffectAsync(
    () => {
      if(![ `prep`, `frozen`, `edit` ].includes(getEmbedMode())) {
        throw new Error(`Invalid Biblearc task component setup.`)
      }
    },
    [ location ],
  )

  if(!ready) return null

  switch(embedType) {

    case `passages`:
    case `modules`:
    case `richtextbox`: {
      return (
        <EmbedModules
          key={id}
          {...props}
          embedSetHeight={embedSetHeight}
          embedId={id}
          embedProjectId={getEmbedProjectId()}
          embedSettings={settings}
          embedOverflowHeight={embedOverflowHeight}
          update={update}
        />
      )
    }

    case `search`: {
      return (
        <EmbedSearch
          {...props}
          embedSettings={settings}
          searchInfo={searchInfo}
        />
      )
    }

    default: {

      if(getEmbedMode() !== `prep`) {
        return (
          <TypeChooser>
            <LogoImg src="/logo.svg" />
            <TypeChooserMessage>
              {i18n("Missing configuration")}
            </TypeChooserMessage>
          </TypeChooser>
        )
      }

      return (
        <TypeChooser>
          <LogoImg src="/logo.svg" />
          <TypeChooserMessage>
            {i18n("Choose a component type")}
          </TypeChooserMessage>
          <TypeChooserButtons>
            <Button
              color="default"
              variant="contained"
              data-key="embedType"
              data-value="passages"
              onClick={embedSettingOnClick}
              disableElevation
            >
              {i18n("Passage(s)")}
            </Button>
            <Button
              color="default"
              variant="contained"
              data-key="embedType"
              data-value="modules"
              onClick={embedSettingOnClick}
              disableElevation
            >
              {i18n("Modules+")}
            </Button>
            <Button
              color="default"
              variant="contained"
              data-key="embedType"
              data-value="richtextbox"
              onClick={embedSettingOnClick}
              disableElevation
            >
              {i18n("Rich Textbox")}
            </Button>
            <Button
              color="default"
              variant="contained"
              data-key="embedType"
              data-value="search"
              onClick={embedSettingOnClick}
              disableElevation
            >
              {i18n("Search")}
            </Button>
          </TypeChooserButtons>
        </TypeChooser>
      )
    }

  }

}

export default memo(Embed)