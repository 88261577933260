const keyDownEscape = params => {

  const {
    event,
  } = params

  event.preventDefault()

  // TODO

}

export default keyDownEscape