import { useMemo } from 'react'

import useGoUpdateModuleDots from './useGoUpdateModuleDots'

const useGoUpdateModuleDot = ({
  moduleDot,
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  moduleId = moduleId || moduleDot.moduleId

  const [ goUpdate, goDelete ] = useGoUpdateModuleDots({
    projectId,
    moduleId,
    undoRedoStack,
  })

  const toReturn = useMemo(
    () => ([
      (updateObj={}, options) => (
        goUpdate(
          [ updateObj ],
          moduleDot && [ moduleDot ],
          options,
        )[0]
      ),
      ...(moduleDot ? [ () => goDelete([ moduleDot ]) ] : []),
    ]),
    [ moduleDot, goUpdate, goDelete ],
  )

  return toReturn
}

export default useGoUpdateModuleDot