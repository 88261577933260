import { memo } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
// import { i18n } from 'inline-i18n'

import { formattingColors } from '../../utils/formatting'
import useModalAnchor from '../../hooks/useModalAnchor'

import OptionsPopover from './OptionsPopover'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  padding: 5px;
`

const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  margin: 5px;
  background-color: ${({ theme, $color }) => (theme.palette.markupColors[$color] || {}).arrow};
  transition: transform .15s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 12px;
`


const ColorDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: ${({ theme, $color }) => (theme.palette.markupColors[$color] || {}).arrow || `none`};
`

const ColorChooser = ({
  skipColors=[],
  onChange,
  keys,
  color,
  disabled,
  className,
  children,
  disableAutoFocus,
  forceBelow,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  return (
    <>

      {children &&
        <div onClick={openModal}>
          {children}
        </div>
      }

      {!children &&
        <StyledIconButton
          className={className}
          onClick={openModal}
          disabled={disabled}
          tabIndex={-1}
        >
          <ColorDot
            $color={color}
            className={[ `PURPLE` ].includes(color) ? "" : "dark-mode-exempt"}
          />
        </StyledIconButton>
      }

      <OptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
        disableAutoFocus={disableAutoFocus}
        disableEnforceFocus={disableAutoFocus}
        forceBelow={forceBelow}
      >
        <Container>
          {formattingColors.filter(color => !skipColors.includes(color)).map((color, idx) => (
            <Dot
              key={color}
              onClick={() => {
                onChange({ keys, newValue: color })
                closeModal()
              }}
              $color={color}
              className={[ `PURPLE` ].includes(color) ? "" : "dark-mode-exempt"}
            >
            </Dot>
          ))}
        </Container>
      </OptionsPopover>

    </>
  )
}


export default memo(ColorChooser)