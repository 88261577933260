import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import OtherSearchResultsHeader from './OtherSearchResultsHeader'
import HelpItemSearchResultsRow from './HelpItemSearchResultsRow'

const Container = styled.div`
`

const HelpItemSearchResults = ({
  suggestions,
}) => {

  return (
    <Container>
    
      <OtherSearchResultsHeader>
        {i18n("Help Topics")}
      </OtherSearchResultsHeader>

      {suggestions.map(suggestion => (
        <HelpItemSearchResultsRow
          {...suggestion}
        />
      ))}

    </Container>
  )
}

export default memo(HelpItemSearchResults)