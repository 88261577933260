import { memo, useCallback } from 'react'
import styled from 'styled-components'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { i18n } from 'inline-i18n'
import { getLanguageInfo } from '@bibletags/bibletags-ui-helper'

import useVersionInfos from '../../../hooks/useVersionInfos'

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
  padding: 15px;
`

const StyledIconButton = styled(IconButton)`
`

const BasicVersionInfo = styled.div`
  flex: 1;
  padding: 0 5px;
`

const VersionAbbr = styled.div`
  font-weight: 700;
  font-size: 16px;
`

const VersionLanguage = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  margin-left: 12px;
  color: ${({ theme }) => theme.palette.grey[600]};
`

const VersionName = styled.div`
  font-size: 15px;
`

const AddVersionsListLine = ({
  index,
  searchTextWithNotIds,
}) => {

  const { version, selectedVersionInfos, updateSelectedVersionIds } = useVersionInfos({ searchText: searchTextWithNotIds, index })
  const { id, abbr, name, languageId } = version || {}

  const addVersion = useCallback(
    () => {
      updateSelectedVersionIds([
        ...selectedVersionInfos.map(({ id }) => id),
        id,
      ])
    },
    [ id, selectedVersionInfos, updateSelectedVersionIds ],
  )

  return (
    <Container>

      <BasicVersionInfo>

        <VersionAbbr>

          {abbr}

          <VersionLanguage>
            {getLanguageInfo(languageId).nativeName}
          </VersionLanguage>

        </VersionAbbr>

        <VersionName>
          {name}
        </VersionName>

      </BasicVersionInfo>

      <Tooltip
        title={i18n("Add to My Bible Versions")}
      >
        <StyledIconButton
          onClick={addVersion}
          variant="outlined"
        >
          <AddIcon />
        </StyledIconButton>
      </Tooltip>

    </Container>
  )
}

export default memo(AddVersionsListLine)