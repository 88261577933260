import { useEffect, useCallback } from "react"

import { usingModifierKey } from '../utils/misc'
import useRefState from "./useRefState"

const useModifierKeyDown = ({
  onKeyDown,
  onKeyUp,
}={}) => {

  const [ down, setDown, getDown ] = useRefState(false)

  const setDownIfChanged = useCallback(
    newDown => {
      if(newDown !== getDown()) {
        setDown(newDown)
      }
    },
    [ setDown, getDown ],
  )

  useEffect(
    () => {

      const keyDown = async event => {
        onKeyDown && onKeyDown(event)
        setDownIfChanged(usingModifierKey(event))
      }

      window.addEventListener('keydown', keyDown)
      return () => window.removeEventListener('keydown', keyDown)
    },
    [ onKeyDown, setDownIfChanged ],
  )

  useEffect(
    () => {

      const keyUp = async event => {
        onKeyUp && onKeyUp(event)
        setDownIfChanged(usingModifierKey(event))
      }

      window.addEventListener('keyup', keyUp)
      return () => window.removeEventListener('keyup', keyUp)
    },
    [ onKeyUp, setDownIfChanged ],
  )

  return [ down, getDown ]
}

export default useModifierKeyDown
