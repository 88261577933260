import { memo, useContext, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'

import useEffectAsync from '../../hooks/useEffectAsync'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useStickyRefState from '../../hooks/useStickyRefState'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'
import { TransitionUp } from './CustomSnackbar'
import { NotificationsContext } from '../../context/Notifications'
import { isPWA } from '../../utils/misc'

import InfoDialog from './InfoDialog'
import NotificationsSubscribeButton from './NotificationsSubscribeButton'
import FadedLoading from './FadedLoading'

const StyledInfoDialog = styled(InfoDialog)`
  z-index: 8500 !important;
`

const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-message {
    flex: 1;
  }

  .MuiSnackbarContent-root {
    padding: 6px 14px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`

const TextContent = styled.div`
  max-width: 300px;
  padding-right: 25px;
  font-size: 17px;
  line-height: 1.3;

  @media (max-width: 600px) {
    max-width: none;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  white-space: nowrap;
`

const NotificationsSnackbar = () => {

  const [ attemptedToTurnOn, setAttemptedToTurnOn ] = useState(false)
  const [ dialogOpen, toggleDialogOpen ] = useSimpleToggle(false)
  const { notificationsStatus, getNotificationsStatus, updateNotificationsStatus, updatingNotificationsStatus } = useContext(NotificationsContext)
  const [ hasRecommendedNotifications, setHasRecommendedNotifications ] = useStickyRefState({ id: `NotificationsSnackbar:hasRecommendedNotifications`, value: false })

  const closeSnackbar = useInstanceValuesCallback(
    () => {
      setAttemptedToTurnOn(false)
      setHasRecommendedNotifications(true)
      if([ `NONE` ].includes(getNotificationsStatus())) {
        toggleDialogOpen({ force: true })
      }
    }
  )

  const onSubscribe = useInstanceValuesCallback(
    async () => {
      setAttemptedToTurnOn(true)
      setHasRecommendedNotifications(true)
      await updateNotificationsStatus(`ALL`)
      if([ `NONE` ].includes(getNotificationsStatus())) {
        toggleDialogOpen({ force: true })
      }
    }
  )

  useEffectAsync(
    () => {
      if(![ `NONE` ].includes(getNotificationsStatus())) {
        setHasRecommendedNotifications(true)
      }
    },
    [ notificationsStatus ],
  )

  if(!isPWA) return null

  return (
    <>

      <StyledSnackbar
        open={!hasRecommendedNotifications}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        message={
          <>

            <Content>

              <TextContent>
                {i18n("Hear about new content and engage in discussions.")}
              </TextContent>

              <ButtonContainer>
                <NotificationsSubscribeButton
                  onClick={onSubscribe}
                />
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={closeSnackbar}
                >
                  {i18n("No Thanks")}
                </Button>
              </ButtonContainer>

            </Content>

            {updatingNotificationsStatus && <FadedLoading />}

          </>
        }
        TransitionComponent={TransitionUp}
      />

      <StyledInfoDialog
        open={dialogOpen}
        onOkay={toggleDialogOpen}
        title={
          attemptedToTurnOn
            ? i18n("Failed to Turn On Notifications")
            : i18n("In Case You Change Your Mind...")
        }
        explanation={i18n("Turn on notifications by opening the main menu and selecting Settings > Notifications.")}
      />

    </>
  )
}

export default memo(NotificationsSnackbar)