import { memo, useCallback } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'
import { useMeasure } from 'react-use'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { isRTLText } from '@bibletags/bibletags-ui-helper'

import useVersionInfo from '../../hooks/useVersionInfo'
import useOpenPassage from '../../hooks/useOpenPassage'

import PassageRef from '../common/PassageRef'
import TextContent from '../common/TextContent'

const Container = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin: 0;
  }
`

const PassageRefAndVersionAbbrContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
`

const TextContentContainer = styled.div`
  direction: ${({ $isRTL }) => $isRTL ? "rtl" : "ltr"};
  align-self: flex-start;
  font-size: ${({ $fontSize }) => $fontSize}px;
`

const PassageRefContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`

const VersionAbbrs = styled.div`
  margin: 1px 15px 0 8px;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
`

const StyledButton = styled(Button)`
  margin: -3px 0;
  padding: 2px 12px;
  min-width: 0;
  font-size: 12px;
`

const PassageSearchResultsRow = ({
  versionId,
  fromLoc,
  toLoc,
  closeAndClearSearch,
  ...otherProps
}) => {

  const { safeVersionAbbr, version: { languageId }={} } = useVersionInfo(versionId)
  const { openPassage } = useOpenPassage()

  const [ ref, { width } ] = useMeasure()

  const { bookId } = getRefFromLoc(fromLoc)

  const goRead = useCallback(
    () => {
      const refs = [ getRefFromLoc(fromLoc) ]
      if(toLoc && toLoc !== fromLoc) {
        refs.push(getRefFromLoc(toLoc))
      }
      closeAndClearSearch()
      openPassage({
        refs,
        versionId,
      })
    },
    [ fromLoc, toLoc, versionId, openPassage, closeAndClearSearch ],
  )

  const fontSize = [ `heb`, `grc`, `heb+grc` ].includes(languageId) ? 18 : 16

  return (
    <Container ref={ref}>

      <PassageRefAndVersionAbbrContainer
        className="options-popper-no-pointer-events"
      >

        <PassageRefContainer>
          <PassageRef
            fromLoc={fromLoc}
            toLoc={toLoc}
            versionId={versionId}
          />
        </PassageRefContainer>

        <VersionAbbrs>
          {safeVersionAbbr}
        </VersionAbbrs>

        <StyledButton
          variant="contained"
          disableElevation
          onClick={goRead}
        >
          {i18n("Read")}
        </StyledButton>

      </PassageRefAndVersionAbbrContainer>

      <TextContentContainer
        $isRTL={isRTLText({ languageId, bookId })}
        $fontSize={fontSize}
        className="options-popper-no-pointer-events-children"
        data-versionid={versionId}
      >
        <TextContent
          versionId={versionId}
          width={width}
          showExpand
          closeAndClearSearch={closeAndClearSearch}
          {...otherProps}
        />
      </TextContentContainer>

      {fromLoc.length === 5 &&
        <>
          {` `}
          {i18n("...", "ellipsis")}
        </>
      }

    </Container>
  )
}

export default memo(PassageSearchResultsRow)