import { db } from '../../utils/database'

const user = async args => {

  if(args.id) throw new Error(`Unexpected user query with id`)

  const result = await db.users.toArray()
  
  return result[0]

}

export default user