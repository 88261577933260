import { gql } from '@apollo/client'

import { getUserFields } from '../fragments/user_fields'

export default gql
`
  mutation cancelSubscription($id: ID!) {
    cancelSubscription(id: $id) {
      ${getUserFields({ isAdmin: true })}
    }
  }
`