import { memo } from 'react'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import { NavLink } from "react-router-dom"

const StylelessA = styled.a`
  text-decoration: none;
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`

const LinkButton = ({
  href,
  to,
  navInSameTab,
  disabled,
  ...otherProps
}) => {

  if(disabled) {
    return (
      <Button
        {...otherProps}
        disabled={disabled}
      />
    )
  }

  if(to) {
    return (
      <StyledNavLink
        exact
        to={to}
        tabIndex={-1}
      >
        <Button
          {...otherProps}
        />
      </StyledNavLink>
    )
  }

  return (
    <StylelessA
      href={href}
      {...(navInSameTab ? {} : {
        target: "_blank",
        rel: "noreferrer",
      })}
      tabIndex={-1}
    >
      <Button
        {...otherProps}
      />
    </StylelessA>
  )
}

export default memo(LinkButton)