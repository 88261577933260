import { memo, useCallback, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory, useLocation } from "react-router-dom"

import useFocus from '../../../hooks/useFocus'
import useSettingsVersionsShortcuts from '../../../hooks/useSettingsVersionsShortcuts'
import AddVersionsList from './AddVersionsList'
import { useNetworkState } from 'react-use'

const Container = styled.div`
  width: 300px;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-direction: column;
  user-select: none;
  position: relative;

  @media (max-width: 900px) {
    background-color: white;
    position: absolute;
    z-index: 3;
    top: 59px;
    bottom: 0;
    transition: right .2s ease-in-out;
    right: ${({ $expanded }) => $expanded ? 0 : `-300px`};
  }
`

const TextFieldContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 1;

  .MuiFormLabel-root {
    background-color: white;
    border-radius: 2px;
  }
`

const StyledTextField = styled(TextField)`
  width: 100%;

  .MuiInputBase-root {
    background-color: white;
  }
`

const DoneButton = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 30px 20px;
  display: none;
  
  @media (max-width: 900px) {
    display: block;
  }
`

const SettingsVersionsRightPanel = ({
  expanded=false,
  close,
}) => {

  const [ searchInputRef, focusSearchInput ] = useFocus()

  useSettingsVersionsShortcuts({
    focusSearchInput,
  })

  const history = useHistory()
  const location = useLocation()
  const { online } = useNetworkState()

  const searchText = new URLSearchParams(location.search).get(`search`) || ``

  const inputProps = useMemo(
    () => ({
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }),
    [],
  )

  const onChange = useCallback(
    ({ target }) => {
      const searchParams = new URLSearchParams(window.location.search)

      if(target.value) {
        searchParams.set(`search`, target.value)
      } else {
        searchParams.delete(`search`)
      }

      history.replace({
        search: searchParams.toString(),
      })
    },
    [ history ],
  )

  return (
    <Container $expanded={expanded}>

      <TextFieldContainer>
        <StyledTextField
          label={i18n("Search versions to add")}
          placeholder={i18n("Name or language")}
          variant="outlined"
          InputProps={inputProps}
          value={searchText}
          onChange={onChange}
          inputRef={searchInputRef}
          disabled={!online}
        />
      </TextFieldContainer>

      {online &&
        <AddVersionsList
          searchText={searchText}
        />
      }

      <DoneButton
        variant="contained"
        color="primary"
        onClick={close}
      >
        {i18n("Done")}
      </DoneButton>

    </Container>
  )
}

export default memo(SettingsVersionsRightPanel)