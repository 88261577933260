import { memo, useCallback, useRef } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { getBibleBookName } from "@bibletags/bibletags-ui-helper"

import useEffectAsync from '../../hooks/useEffectAsync'

const Container = styled.div`
  padding: 7px 14px;

  ${({ $isSelected }) => !$isSelected ? `` : `
    background: black;
    color: white;
  `}

  ${({ $isSelected, theme }) => $isSelected ? `` : `
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background: ${theme.palette.grey[100]};
      }
    }
  `}
`

const BookChooserBook = ({
  bookId,
  isSelected,
  setBookId,
}) => {

  const ref = useRef()

  useEffectAsync(
    () => {
      if(isSelected) {
        ref.current.scrollIntoView({ block: 'center' })
      }
    },
    [],
  )

  const onClick = useCallback(() => setBookId(bookId), [ setBookId, bookId ])

  return (
    <Container
      $isSelected={isSelected}
      onClick={onClick}
      ref={ref}
    >
      {getBibleBookName(bookId)}
    </Container>
  )
}

export default memo(BookChooserBook)