import { memo } from 'react'
import styled from 'styled-components'

import Header from "../../common/Header"
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 20px;
`

const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  font-size: 16px;
  padding-bottom: 50vh;

  h1 {
    font-size: 18px;
  }
`

const Privacy = ({ ...props }) => {

  return (
    <>

      <Header {...props} />

      <Container>
        <Content>

          <h1>
            INTRODUCTION
          </h1>
          <p>
            We know you care about how your information is used and shared. We hope the following statements will help you understand our policies and how we use the information you provide to us. We will collect, use and protect the information you provide to us on our site. We will not use or share your information with anyone except as described in this Privacy Policy.
          </p>

          <h1>
            WHAT WE COLLECT AND HOW WE USE IT
          </h1>
          <ol>
            <li><b>Cookies.</b> When you visit this site, we may send one or more cookies to your computer, so that we may uniquely identify your browser (a cookie is simply a tiny text file containing a string of characters). Accepting our cookies allows you to keep your user preferences from session to session. Although most browsers accept cookies by default, you can set your browser to refuse all cookies, or to tell you when you're being sent a cookie. Some features and services may not function properly if you have cookies disabled.</li>
            <li><b>Local browser storage.</b> When you use our services, we store information about your usage within your browser’s local storage in order to enable quick load times and offline access. Disabling or clearing local storage in your browser may cause our services not to function properly.</li>
            <li><b>Log information.</b> When you use our services, our servers automatically record information that your browser sends when you visit one of our websites. These server logs may include information such as your web request, Internet Protocol address, browser type, browser language, the date and time of your request, and one or more cookies that may uniquely identify your browser. In addition, we may use 3rd party analytics services to record anonymous usage statistics.</li>
            <li><b>Other sites.</b> This Privacy Policy applies only to this website. We cannot regulate other sites linked to from within our various websites and services. These other sites may place their own cookies or other files on your computer, collect data or solicit personal information from you. You should familiarize yourself with their unique Privacy Policies, should you choose to visit their sites.</li>
          </ol>

          <h1>
            HOW DO WE USE YOUR INFORMATION?
          </h1>
          <p>
            When you sign up for a particular service that requires registration, we ask you to provide personal information. You may decline to submit personal information to any of our services, in which case we may not be able to provide those services to you. Your email address is only used for communications related to account notifications and newsletters you’ve opted-in to receive. We never sell, share, or spam.
          </p>

          <h1>
            HOW DO WE SHARE YOUR INFORMATION?
          </h1>
          <p>
            Your registered name and profile image will be publically accessible to enable our sharing functionality.
          </p>
          <p>
            We do not share your personal information with other institutions. However, we may share your personal information if we have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to (a) satisfy any applicable law, regulation, legal process or enforceable governmental request, (b) enforce applicable Terms Of Service, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address fraud, security or technical issues, or (d) protect against imminent harm to the rights, property or safety of our organization, its users or the public as required or permitted by law.
          </p>

          <h1>
            INFORMATION SECURITY
          </h1>
          <p>
            We take commercially reasonable steps to implement appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. We restrict access to personal information to our employees, contractors, and agents who need to know that information to operate, develop or improve our services. These individuals are bound by confidentiality obligations and may be subject to discipline, including termination and criminal prosecution, if they fail to meet these obligations. We are not responsible, however, if unauthorized access to information occurs.
          </p>

          <h1>
            DATA INTEGRITY
          </h1>
          <p>
            We review our data collection, storage, and processing practices to ensure that we only collect, store and process the personal information needed to provide or improve our services. We take reasonable steps to ensure that the personal information we process is accurate, complete, and current, but we depend on our users to update or correct their personal information whenever necessary.
          </p>

          <h1>
            ENFORCEMENT
          </h1>
          <p>
            We periodically review our compliance with this Privacy Policy. When we receive formal written complaints regarding privacy issues, it is our policy to contact the complaining user regarding his or her concerns. We will cooperate with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data that cannot be resolved between us and an individual.
          </p>

          <h1>
            QUESTIONS
          </h1>
          <p>
            Any questions about our privacy policy can be directed to our <NavLinkOrAWithDisable to={`https://equip.biblearc.com/contact`}>contact form</NavLinkOrAWithDisable>.
          </p>

        </Content>
      </Container>

    </>
  )
}


export default memo(Privacy)