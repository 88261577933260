import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation restoreModule($id: ID!, $projectId: ID!, $ordering: Float, $updatedSince: Milliseconds!) {
    restoreModule(id: $id, projectId: $projectId, ordering: $ordering, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`