import { useCallback, useMemo, useState } from "react"

import useDataQuery from "./useDataQuery"

import DotNotePlacementCover from "../components/dot-notes/DotNotePlacementCover"
import DotNotesButton from "../components/modules/shared/DotNotesButton"

import moduleDotsQuery from '../graphql/queries/moduleDots'
import useEffectAsync from "./useEffectAsync"

const getFirstNonCoverNonDotEl = (x,y) => document.elementsFromPoint(x,y).find(el => !el.closest(`.DotNotePlacementCover-Cover, .Dot-StyledDiv, .StickyNote-Container`))

export const getMovingPositionInfo = ({ clientX, clientY }, baseValue, adjustments={}) => {
  clientY += adjustments.y || 0
  clientX += adjustments.x || 0

  const targetEl = getFirstNonCoverNonDotEl(clientX, clientY)
  const positionEl = targetEl && targetEl.closest(`[data-dot-container]`)
  
  let positionInfo = { ...baseValue }

  if(positionEl) {
    const dotContainer = positionEl.getAttribute(`data-dot-container`)
    const { top, left, height, width } = positionEl.getBoundingClientRect()

    positionInfo = {
      ...positionInfo,
      selector: `[data-dot-container="${dotContainer}"]`,
      fractionTop: (clientY - top) / height,
      fractionLeft: (clientX - left) / width,
    }
  }

  return positionInfo

}

export const getFinalPositionInfo = ({ clientX, clientY }, baseValue, adjustments={}) => {
  clientY += adjustments.y || 0
  clientX += adjustments.x || 0

  let selector, positionEl

  const findSelector = (x, y, positionElSelector) => {

    const targetEl = getFirstNonCoverNonDotEl(x, y)
    positionEl = targetEl && targetEl.closest(positionElSelector || `[data-dot-container]:not([data-dot-container="content"]):not([data-dot-container="space-below-key"]), [data-word-loc]`)
    if(!positionEl) return

    const wordLoc = positionEl.getAttribute(`data-word-loc`)
    const dotContainer = positionEl.getAttribute(`data-dot-container`)
    selector = (
      (wordLoc && `[data-word-loc="${wordLoc}"]`)
      || (dotContainer && `[data-dot-container="${dotContainer}"]`)
    )

    return true
  }

  findSelector(clientX, clientY)
  let distanceToTry = 10
  while(!selector && distanceToTry < 150) {
    if(findSelector(clientX - distanceToTry, clientY)) break
    if(findSelector(clientX + distanceToTry, clientY)) break
    if(findSelector(clientX, clientY + distanceToTry)) break
    if(findSelector(clientX, clientY - distanceToTry)) break
    distanceToTry += 10
  }
  if(!selector) findSelector(clientX, clientY, `[data-dot-container]`)

  if(!selector) return  // just in case

  const { top, left, height, width } = positionEl.getBoundingClientRect()

  return {
    ...baseValue,
    selector,
    fractionTop: (clientY - top) / height,
    fractionLeft: (clientX - left) / width,
  }

}

const useDotNotes = ({
  projectId,
  moduleId,
  modulePieceId,  // modulePieceId can be null or undefined; null requires that modulePieceId === null, whereas undefined gets all results for the module
  filter,
  inEditingMode,
  bannerHeight,
  formattingKeyInfoId,
}) => {

  const [ placingInfo, setPlacingInfo ] = useState()

  const { moduleDots=[], loading } = useDataQuery({
    moduleDotsQuery,
    variables: {
      moduleId,
      ...(modulePieceId !== undefined ? { modulePieceId } : {}),
    },
  })

  const alteredModuleDots = useMemo(
    () => {
      const alteredModuleDots = filter ? moduleDots.filter(filter) : [ ...moduleDots ]

      if((placingInfo || {}).id) {
        const placingIndex = alteredModuleDots.findIndex(({ id }) => id === placingInfo.id)
        if(placingIndex !== -1) {  
          alteredModuleDots[placingIndex] = {
            ...alteredModuleDots[placingIndex],
            ...placingInfo,
            unalteredModuleDot: alteredModuleDots[placingIndex].unalteredModuleDot || alteredModuleDots[placingIndex],
          }
        }
      } else if((placingInfo || {}).positionInfo) {  // new dot
        alteredModuleDots.push(placingInfo)
      }

      return alteredModuleDots

    },
    [ moduleDots, placingInfo, filter ],
  )

  const toggleNewDotNote = useCallback(() => setPlacingInfo(placingInfo ? null : {}), [ placingInfo ])
  const onClose = useCallback(() => setPlacingInfo(), [])

  useEffectAsync(
    () => {
      if(!inEditingMode && placingInfo) {
        onClose()
      }
    },
    [ inEditingMode ],
  )

  return {
    dotNotesButton: (
      <>

        {inEditingMode &&
          <DotNotesButton
            onClick={toggleNewDotNote}
            addingNew={!!placingInfo && !placingInfo.id}
            onClose={onClose}
          />
        }

      </>
    ),
    dotNotePlacementCover: (
      (!!placingInfo && !placingInfo.id)
        ? (
          <DotNotePlacementCover
            projectId={projectId}
            moduleId={moduleId}
            onClose={onClose}
            setPlacingInfo={setPlacingInfo}
            formattingKeyId={formattingKeyInfoId}
          />
        )
        : null
    ),
    moduleDots: alteredModuleDots,
    setPlacingInfo: setPlacingInfo,
    moduleDotsLoading: loading,
  }

}

export default useDotNotes

/*

  positionInfo by module type

  MARKUP
  
  {
    selector: (
      `[data-word-loc="01001001:2"]`
      || `[data-dot-container="proceeding-context"]`
      || `[data-dot-container="content"]`
      || `[data-dot-container="space-below-key"]`
    )
    fractionTop: .82,
    fractionLeft: .30,
  }

*/