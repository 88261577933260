import { gql } from '@apollo/client'
import { subsetDefs, subsetMaps } from '../fragments/subset_parameters'
import alertsItem_fields from '../fragments/alertsItem_fields'

const alertsItems = gql`
  query alertsItems($query: String, ${subsetDefs}) {
    alertsItems(query: $query, ${subsetMaps}) {
      alertsItems {
        ${alertsItem_fields}
      }
      count
    }
  }
`

export default alertsItems