import { memo, useCallback, useRef } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { useUpdateEffect } from 'react-use'

import { isTouchDevice } from '../../utils/misc'
import useSimpleToggle from '../../hooks/useSimpleToggle'

const Container = styled.div`
  position: relative;
`

const Cover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: text;
`

const StyledTextField = styled(TextField)`
  margin-top: 10px;

  .MuiInputLabel-root {
    background: white;
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  textarea {
    padding: 18.5px 14px;
    font-size: 13px;
  }
`

const StudyBiblePageEditorPage = ({
  type,
  detail,
  updateDetail,
}) => {

  const ref = useRef()
  const [ disabled, toggleDisabled ] = useSimpleToggle(isTouchDevice())

  // const { text=``, ordering=0 } = detail
  const { text=`` } = detail

  const onChange = useCallback(
    ({ target }) => {
      updateDetail({
        text: target.value,
      })
    },
    [ updateDetail ],
  )

  const onBlurTextField = useCallback(
    () => {
      if(isTouchDevice()) {
        toggleDisabled({ force: true })
      }
    },
    [ toggleDisabled ],
  )

  useUpdateEffect(
    () => {
      if(!disabled) ref.current.focus()
    },
    [ disabled ],
  )

  return (
    <Container>
      <StyledTextField
        inputRef={ref}
        fullWidth
        label={type === `INSIGHT` ? i18n("Insight") : i18n("Quote")}
        variant="outlined"
        value={text}
        onChange={onChange}
        onBlur={onBlurTextField}
        multiline
        minRows={6}
        maxRows={6}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {disabled && <Cover onClick={toggleDisabled} />}
    </Container>
  )
}

export default memo(StudyBiblePageEditorPage)