import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

const Container = styled.div`
`

const PassageBookmarks = () => {

  return (
    <Container>

    </Container>
  )
}

export default memo(PassageBookmarks)

  // PassageBookmarks
    // PassageBookmark
    // PassageBookmarkHistoryButton
      // PassageBookmarkHistoryItems
