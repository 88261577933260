import { memo } from 'react'
// import { i18n } from 'inline-i18n'
// import Button from '@material-ui/core/Button'
// import styled from 'styled-components'

// const StyledButton = styled(Button)`
//   margin: 0 15px;
//   width: calc(100% - 30px);
// `

const StudyBibleItemEditorGenealogyInfo = ({
  StyledTextField,
  commonTextFieldProps,
  StyledDivider,
  info,
  onChangeInfo,
}) => {

  return (
    <>

    </>
  )
}

export default memo(StudyBibleItemEditorGenealogyInfo)