import { gql } from '@apollo/client'
import { subsetDefs, subsetMaps } from '../fragments/subset_parameters'
import project_fields_complete from '../fragments/project_fields_complete'

const projects = gql`
  query projects($query: String, ${subsetDefs}) {
    projects(query: $query, ${subsetMaps}) {
      projects {
        ${project_fields_complete}
      }
      count
    }
  }
`

export default projects