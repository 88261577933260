const countries = [
  { code: 'AD', label: 'Andorra', qualifies: true },
  { code: 'AE', label: 'United Arab Emirates', qualifies: false },
  { code: 'AF', label: 'Afghanistan', qualifies: true },
  { code: 'AG', label: 'Antigua and Barbuda', qualifies: true },
  { code: 'AI', label: 'Anguilla', qualifies: true },
  { code: 'AL', label: 'Albania', qualifies: true },
  { code: 'AM', label: 'Armenia', qualifies: true },
  { code: 'AO', label: 'Angola', qualifies: true },
  { code: 'AQ', label: 'Antarctica', qualifies: true },
  { code: 'AR', label: 'Argentina', qualifies: true },
  { code: 'AS', label: 'American Samoa', qualifies: true },
  { code: 'AT', label: 'Austria', qualifies: false },
  { code: 'AU', label: 'Australia', qualifies: false },
  { code: 'AW', label: 'Aruba', qualifies: true },
  { code: 'AX', label: 'Alland Islands', qualifies: true },
  { code: 'AZ', label: 'Azerbaijan', qualifies: false },
  { code: 'BA', label: 'Bosnia and Herzegovina', qualifies: false },
  { code: 'BB', label: 'Barbados', qualifies: true },
  { code: 'BD', label: 'Bangladesh', qualifies: true },
  { code: 'BE', label: 'Belgium', qualifies: false },
  { code: 'BF', label: 'Burkina Faso', qualifies: true },
  { code: 'BG', label: 'Bulgaria', qualifies: true },
  { code: 'BH', label: 'Bahrain', qualifies: true },
  { code: 'BI', label: 'Burundi', qualifies: true },
  { code: 'BJ', label: 'Benin', qualifies: true },
  { code: 'BL', label: 'Saint Barthelemy', qualifies: true },
  { code: 'BM', label: 'Bermuda', qualifies: true },
  { code: 'BN', label: 'Brunei Darussalam', qualifies: true },
  { code: 'BO', label: 'Bolivia', qualifies: true },
  { code: 'BR', label: 'Brazil', qualifies: true },
  { code: 'BS', label: 'Bahamas', qualifies: true },
  { code: 'BT', label: 'Bhutan', qualifies: true },
  { code: 'BV', label: 'Bouvet Island', qualifies: true },
  { code: 'BW', label: 'Botswana', qualifies: true },
  { code: 'BY', label: 'Belarus', qualifies: false },
  { code: 'BZ', label: 'Belize', qualifies: true },
  { code: 'CA', label: 'Canada', qualifies: false },
  { code: 'CC', label: 'Cocos (Keeling) Islands', qualifies: true },
  { code: 'CD', label: 'Congo, Democratic Republic of the', qualifies: true },
  { code: 'CF', label: 'Central African Republic', qualifies: true },
  { code: 'CG', label: 'Congo, Republic of the', qualifies: true },
  { code: 'CH', label: 'Switzerland', qualifies: false },
  { code: 'CI', label: "Cote d'Ivoire", qualifies: true },
  { code: 'CK', label: 'Cook Islands', qualifies: true },
  { code: 'CL', label: 'Chile', qualifies: false },
  { code: 'CM', label: 'Cameroon', qualifies: true },
  { code: 'CN', label: 'China', qualifies: true },
  { code: 'CO', label: 'Colombia', qualifies: true },
  { code: 'CR', label: 'Costa Rica', qualifies: true },
  { code: 'CU', label: 'Cuba', qualifies: true },
  { code: 'CV', label: 'Cape Verde', qualifies: true },
  { code: 'CW', label: 'Curacao', qualifies: true },
  { code: 'CX', label: 'Christmas Island', qualifies: true },
  { code: 'CY', label: 'Cyprus', qualifies: false },
  { code: 'CZ', label: 'Czech Republic', qualifies: false },
  { code: 'DE', label: 'Germany', qualifies: false },
  { code: 'DJ', label: 'Djibouti', qualifies: true },
  { code: 'DK', label: 'Denmark', qualifies: false },
  { code: 'DM', label: 'Dominica', qualifies: true },
  { code: 'DO', label: 'Dominican Republic', qualifies: true },
  { code: 'DZ', label: 'Algeria', qualifies: true },
  { code: 'EC', label: 'Ecuador', qualifies: true },
  { code: 'EE', label: 'Estonia', qualifies: false },
  { code: 'EG', label: 'Egypt', qualifies: true },
  { code: 'EH', label: 'Western Sahara', qualifies: true },
  { code: 'ER', label: 'Eritrea', qualifies: true },
  { code: 'ES', label: 'Spain', qualifies: false },
  { code: 'ET', label: 'Ethiopia', qualifies: true },
  { code: 'FI', label: 'Finland', qualifies: false },
  { code: 'FJ', label: 'Fiji', qualifies: true },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', qualifies: true },
  { code: 'FM', label: 'Micronesia, Federated States of', qualifies: true },
  { code: 'FO', label: 'Faroe Islands', qualifies: true },
  { code: 'FR', label: 'France', qualifies: false },
  { code: 'GA', label: 'Gabon', qualifies: true },
  { code: 'GB', label: 'United Kingdom', qualifies: false },
  { code: 'GD', label: 'Grenada', qualifies: true },
  { code: 'GE', label: 'Georgia', qualifies: true },
  { code: 'GF', label: 'French Guiana', qualifies: true },
  { code: 'GG', label: 'Guernsey', qualifies: true },
  { code: 'GH', label: 'Ghana', qualifies: true },
  { code: 'GI', label: 'Gibraltar', qualifies: true },
  { code: 'GL', label: 'Greenland', qualifies: true },
  { code: 'GM', label: 'Gambia', qualifies: true },
  { code: 'GN', label: 'Guinea', qualifies: true },
  { code: 'GP', label: 'Guadeloupe', qualifies: true },
  { code: 'GQ', label: 'Equatorial Guinea', qualifies: true },
  { code: 'GR', label: 'Greece', qualifies: false },
  { code: 'GS', label: 'South Georgia and the South Sandwich Islands', qualifies: true },
  { code: 'GT', label: 'Guatemala', qualifies: true },
  { code: 'GU', label: 'Guam', qualifies: true },
  { code: 'GW', label: 'Guinea-Bissau', qualifies: true },
  { code: 'GY', label: 'Guyana', qualifies: true },
  { code: 'HK', label: 'Hong Kong', qualifies: true },
  { code: 'HM', label: 'Heard Island and McDonald Islands', qualifies: true },
  { code: 'HN', label: 'Honduras', qualifies: true },
  { code: 'HR', label: 'Croatia', qualifies: false },
  { code: 'HT', label: 'Haiti', qualifies: true },
  { code: 'HU', label: 'Hungary', qualifies: false },
  { code: 'ID', label: 'Indonesia', qualifies: true },
  { code: 'IE', label: 'Ireland', qualifies: false },
  { code: 'IL', label: 'Israel', qualifies: false },
  { code: 'IM', label: 'Isle of Man', qualifies: true },
  { code: 'IN', label: 'India', qualifies: true },
  { code: 'IO', label: 'British Indian Ocean Territory', qualifies: true },
  { code: 'IQ', label: 'Iraq', qualifies: true },
  { code: 'IR', label: 'Iran, Islamic Republic of', qualifies: true },
  { code: 'IS', label: 'Iceland', qualifies: false },
  { code: 'IT', label: 'Italy', qualifies: false },
  { code: 'JE', label: 'Jersey', qualifies: true },
  { code: 'JM', label: 'Jamaica', qualifies: true },
  { code: 'JO', label: 'Jordan', qualifies: true },
  { code: 'JP', label: 'Japan', qualifies: false },
  { code: 'KE', label: 'Kenya', qualifies: true },
  { code: 'KG', label: 'Kyrgyzstan', qualifies: true },
  { code: 'KH', label: 'Cambodia', qualifies: true },
  { code: 'KI', label: 'Kiribati', qualifies: true },
  { code: 'KM', label: 'Comoros', qualifies: true },
  { code: 'KN', label: 'Saint Kitts and Nevis', qualifies: true },
  { code: 'KP', label: "Korea, Democratic People's Republic of", qualifies: true },
  { code: 'KR', label: 'Korea, Republic of', qualifies: false },
  { code: 'KW', label: 'Kuwait', qualifies: true },
  { code: 'KY', label: 'Cayman Islands', qualifies: true },
  { code: 'KZ', label: 'Kazakhstan', qualifies: false },
  { code: 'LA', label: "Lao People's Democratic Republic", qualifies: true },
  { code: 'LB', label: 'Lebanon', qualifies: false },
  { code: 'LC', label: 'Saint Lucia', qualifies: true },
  { code: 'LI', label: 'Liechtenstein', qualifies: true },
  { code: 'LK', label: 'Sri Lanka', qualifies: true },
  { code: 'LR', label: 'Liberia', qualifies: true },
  { code: 'LS', label: 'Lesotho', qualifies: true },
  { code: 'LT', label: 'Lithuania', qualifies: false },
  { code: 'LU', label: 'Luxembourg', qualifies: false },
  { code: 'LV', label: 'Latvia', qualifies: false },
  { code: 'LY', label: 'Libya', qualifies: true },
  { code: 'MA', label: 'Morocco', qualifies: true },
  { code: 'MC', label: 'Monaco', qualifies: true },
  { code: 'MD', label: 'Moldova, Republic of', qualifies: true },
  { code: 'ME', label: 'Montenegro', qualifies: true },
  { code: 'MF', label: 'Saint Martin (French part)', qualifies: true },
  { code: 'MG', label: 'Madagascar', qualifies: true },
  { code: 'MH', label: 'Marshall Islands', qualifies: true },
  { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', qualifies: true },
  { code: 'ML', label: 'Mali', qualifies: true },
  { code: 'MM', label: 'Myanmar', qualifies: true },
  { code: 'MN', label: 'Mongolia', qualifies: true },
  { code: 'MO', label: 'Macao', qualifies: true },
  { code: 'MP', label: 'Northern Mariana Islands', qualifies: true },
  { code: 'MQ', label: 'Martinique', qualifies: true },
  { code: 'MR', label: 'Mauritania', qualifies: true },
  { code: 'MS', label: 'Montserrat', qualifies: true },
  { code: 'MT', label: 'Malta', qualifies: false },
  { code: 'MU', label: 'Mauritius', qualifies: true },
  { code: 'MV', label: 'Maldives', qualifies: false },
  { code: 'MW', label: 'Malawi', qualifies: true },
  { code: 'MX', label: 'Mexico', qualifies: true },
  { code: 'MY', label: 'Malaysia', qualifies: false },
  { code: 'MZ', label: 'Mozambique', qualifies: true },
  { code: 'NA', label: 'Namibia', qualifies: true },
  { code: 'NC', label: 'New Caledonia', qualifies: true },
  { code: 'NE', label: 'Niger', qualifies: true },
  { code: 'NF', label: 'Norfolk Island', qualifies: true },
  { code: 'NG', label: 'Nigeria', qualifies: true },
  { code: 'NI', label: 'Nicaragua', qualifies: true },
  { code: 'NL', label: 'Netherlands', qualifies: false },
  { code: 'NO', label: 'Norway', qualifies: false },
  { code: 'NP', label: 'Nepal', qualifies: true },
  { code: 'NR', label: 'Nauru', qualifies: true },
  { code: 'NU', label: 'Niue', qualifies: true },
  { code: 'NZ', label: 'New Zealand', qualifies: true },
  { code: 'OM', label: 'Oman', qualifies: true },
  { code: 'PA', label: 'Panama', qualifies: true },
  { code: 'PE', label: 'Peru', qualifies: true },
  { code: 'PF', label: 'French Polynesia', qualifies: true },
  { code: 'PG', label: 'Papua New Guinea', qualifies: true },
  { code: 'PH', label: 'Philippines', qualifies: true },
  { code: 'PK', label: 'Pakistan', qualifies: true },
  { code: 'PL', label: 'Poland', qualifies: false },
  { code: 'PM', label: 'Saint Pierre and Miquelon', qualifies: true },
  { code: 'PN', label: 'Pitcairn', qualifies: true },
  { code: 'PR', label: 'Puerto Rico', qualifies: true },
  { code: 'PS', label: 'Palestine, State of', qualifies: true },
  { code: 'PT', label: 'Portugal', qualifies: false },
  { code: 'PW', label: 'Palau', qualifies: true },
  { code: 'PY', label: 'Paraguay', qualifies: true },
  { code: 'QA', label: 'Qatar', qualifies: true },
  { code: 'RE', label: 'Reunion', qualifies: true },
  { code: 'RO', label: 'Romania', qualifies: true },
  { code: 'RS', label: 'Serbia', qualifies: true },
  { code: 'RU', label: 'Russian Federation', qualifies: false },
  { code: 'RW', label: 'Rwanda', qualifies: true },
  { code: 'SA', label: 'Saudi Arabia', qualifies: true },
  { code: 'SB', label: 'Solomon Islands', qualifies: true },
  { code: 'SC', label: 'Seychelles', qualifies: false },
  { code: 'SD', label: 'Sudan', qualifies: true },
  { code: 'SE', label: 'Sweden', qualifies: false },
  { code: 'SG', label: 'Singapore', qualifies: true },
  { code: 'SH', label: 'Saint Helena', qualifies: true },
  { code: 'SI', label: 'Slovenia', qualifies: false },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', qualifies: true },
  { code: 'SK', label: 'Slovakia', qualifies: false },
  { code: 'SL', label: 'Sierra Leone', qualifies: true },
  { code: 'SM', label: 'San Marino', qualifies: true },
  { code: 'SN', label: 'Senegal', qualifies: true },
  { code: 'SO', label: 'Somalia', qualifies: true },
  { code: 'SR', label: 'Suriname', qualifies: true },
  { code: 'SS', label: 'South Sudan', qualifies: true },
  { code: 'ST', label: 'Sao Tome and Principe', qualifies: true },
  { code: 'SV', label: 'El Salvador', qualifies: true },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', qualifies: true },
  { code: 'SY', label: 'Syrian Arab Republic', qualifies: true },
  { code: 'SZ', label: 'Swaziland', qualifies: true },
  { code: 'TC', label: 'Turks and Caicos Islands', qualifies: true },
  { code: 'TD', label: 'Chad', qualifies: true },
  { code: 'TF', label: 'French Southern Territories', qualifies: true },
  { code: 'TG', label: 'Togo', qualifies: true },
  { code: 'TH', label: 'Thailand', qualifies: true },
  { code: 'TJ', label: 'Tajikistan', qualifies: true },
  { code: 'TK', label: 'Tokelau', qualifies: true },
  { code: 'TL', label: 'Timor-Leste', qualifies: true },
  { code: 'TM', label: 'Turkmenistan', qualifies: true },
  { code: 'TN', label: 'Tunisia', qualifies: true },
  { code: 'TO', label: 'Tonga', qualifies: true },
  { code: 'TR', label: 'Turkey', qualifies: true },
  { code: 'TT', label: 'Trinidad and Tobago', qualifies: true },
  { code: 'TV', label: 'Tuvalu', qualifies: true },
  { code: 'TW', label: 'Taiwan', qualifies: true },
  { code: 'TZ', label: 'United Republic of Tanzania', qualifies: true },
  { code: 'UA', label: 'Ukraine', qualifies: false },
  { code: 'UG', label: 'Uganda', qualifies: true },
  { code: 'US', label: 'United States', qualifies: false },
  { code: 'UY', label: 'Uruguay', qualifies: false },
  { code: 'UZ', label: 'Uzbekistan', qualifies: true },
  { code: 'VA', label: 'Holy See (Vatican City State)', qualifies: true },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', qualifies: true },
  { code: 'VE', label: 'Venezuela', qualifies: true },
  { code: 'VG', label: 'British Virgin Islands', qualifies: true },
  { code: 'VI', label: 'US Virgin Islands', qualifies: true },
  { code: 'VN', label: 'Vietnam', qualifies: true },
  { code: 'VU', label: 'Vanuatu', qualifies: true },
  { code: 'WF', label: 'Wallis and Futuna', qualifies: true },
  { code: 'WS', label: 'Samoa', qualifies: true },
  { code: 'XK', label: 'Kosovo', qualifies: true },
  { code: 'YE', label: 'Yemen', qualifies: true },
  { code: 'YT', label: 'Mayotte', qualifies: true },
  { code: 'ZA', label: 'South Africa', qualifies: true },
  { code: 'ZM', label: 'Zambia', qualifies: true },
  { code: 'ZW', label: 'Zimbabwe', qualifies: true },
]

export default countries