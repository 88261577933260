import React from "react"
import { setLocale, getLocale, isRTL } from 'inline-i18n'
import styled from 'styled-components'
import { Editor, EditorState, ContentState, RichUtils, AtomicBlockUtils, SelectionState,
  convertToRaw, convertFromRaw, convertFromHTML, CompositeDecorator, Modifier, DefaultDraftBlockRenderMap } from 'draft-js'
import Immutable from 'immutable'
import { create } from 'jss'
import { StylesProvider, jssPreset, createGenerateClassName, ThemeProvider,
         createMuiTheme, ServerStyleSheets as MaterialUiServerStyleSheets } from '@material-ui/core/styles'

import getOutOfTheBoxCustomComponentTypes from './utils/getOutOfTheBoxCustomComponentTypes'
import findEntities from './utils/findEntities'
import { adjustListIndentation } from './utils/adjustments'
import handleAtomicKeyDown from './utils/handleAtomicKeyDown'
import toolbarSections from './utils/toolbarSections'
import {
  LINK_FLOATER_WIDTH,
  getToolbarHeightWithBorder,
} from './utils/constants'

import ATag from './ATag'
import AtomicBlock from './AtomicBlock'
import Toolbar from "./Toolbar"
import LinkFloater from "./LinkFloater"
import FlipEditorContainer from "./FlipEditorContainer"

const defaultGenerateClassName = createGenerateClassName({ seed: `flip-editor` })
const defaultJss = create(jssPreset())
const defaultTheme = createMuiTheme({})

const isAndroid = typeof navigator !== `undefined` && /Android/i.test(navigator.userAgent || ``)

const ToolbarContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  position: relative;
  z-index: 5;
  height: ${getToolbarHeightWithBorder};
  ${({ $readOnly }) => !$readOnly ? `` : `
    ::before {
      content: " ";
      position: absolute;
      z-index: 50;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255,255,255,.0001);
    }
    opacity: .4;
  `}
`

const EditorContainer = styled.div`

  position: relative;

  ${({ $readOnly, $noPaddingBottom, $fillHeight }) => $readOnly ? `` : `
    ${!$fillHeight ? `` : `
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
    `}

    .public-DraftEditor-content {
      min-height: 100px;
      padding-bottom: ${$noPaddingBottom ? `0` : `40vh`};
    }
  `}

  ${({ $contentSass }) => (typeof $contentSass === 'string' ? $contentSass : `

    blockquote {
      border-width: 1px 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, .2);
      padding: 1.5em 0;
      margin: 1.5em 0;
      position: relative;
      color: rgba(0, 0, 0, .55);
    }

    blockquote:before {
      content: '\\201C';
      position: absolute;
      top: 0em;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      width: 3rem;
      height: 2rem;
      font-size: 3.4em;
      line-height: 1.05em;
      font-family: Georgia;
      font-weight: bold;
      color: rgba(0, 0, 0, .8);
      text-align: center;
    }

    aside {
      color: rgba(0, 0, 0, .5);
      font-size: 12px;
    }

  `)}

  .public-DraftEditor-content[contenteditable="false"] {
    .flipeditor-video-fixed {
      .flipeditor-video-container2 {
        position: relative !important;
      }
      .flipeditor-video-container3 {
        position: fixed !important;
        bottom: 0;
        right: 40px;
        z-index: 99;
        max-width: 650px !important;
      }
    }
  }

  @media (max-width: 780px) {
    .public-DraftEditor-content[contenteditable="false"] .flipeditor-video-fixed {
      .flipeditor-video-container3 {
        max-width: 520px !important;
      }
    }
  }

  @media (max-height: 650px), (max-width: 600px) {
    .public-DraftEditor-content[contenteditable="false"] .flipeditor-video-fixed {
      .flipeditor-video-container2 {
        position: absolute !important;
      }
      .flipeditor-video-container3 {
        position: relative !important;
        bottom: 0;
        right: 0;
        z-index: unset;
        max-width: 100% !important;
      }
    }
  }

  @media (max-width: 600px) and (orientation: portrait) and (max-aspect-ratio: 10/13) {
    .public-DraftEditor-content[contenteditable="false"] .flipeditor-video-fixed {
      .flipeditor-video-container2 {
        position: relative !important;
      }
      .flipeditor-video-container3 {
        position: fixed !important;
        right: 0;
        left: 0;
        width: 100% !important;
        bottom: 0;
        z-index: 99;
        max-width: 100% !important;
      }
    }
  }

`

const ClearBoth = styled.div`
  clear: both;
`

export const getCustomComponentInfoFromContent = rawContent => {
  try {
    const components = []
    const rawContentObj = typeof rawContent === 'object' ? rawContent : JSON.parse(rawContent)
    const content = convertFromRaw(rawContentObj)

    content.getBlockMap().forEach(block => {
      if(block.type === 'atomic') {
        const entity = content.getEntity(
          block.getEntityAt(0)
        )

        if(entity) {
          components.push({
            blockKey: block.key,
            type: entity.getType(),
            data: entity.getData(),
            mutibility: entity.getMutability(),
          })
        }
      }
    })

    return components

  } catch(err) {
    console.log('FlipEditor.getCustomComponentInfoFromContent error', err)
    return []
  }
}

class FlipEditor extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      editorState: this.getInitialEditorState(),
      settingsInfo: null,
      settingsHeight: 0,
      toolbarFixedStyle: null,
      readOnly: false,
      ...this.getPreppedCustomComponentTypeVars(props),
    }

    props.locale && setLocale({ locale: props.locale })

    this.setUpBlockRenderMap(props)
  }

  componentDidMount() {
    const { mode, scrollToFixedContainer, focusOnLoad } = this.props

    // Detect window scrollbar
    if(typeof window !== 'undefined') {
      this.resizeObserver = new ResizeObserver(() => {
        const currentScrollbarWidth = document.documentElement.style.getPropertyValue(`--flipeditor-scrollbar-width`)
        const newScrollbarWidth = `${window.innerWidth - document.documentElement.clientWidth}px`
        if(newScrollbarWidth !== currentScrollbarWidth) {
          document.documentElement.style.setProperty(`--flipeditor-scrollbar-width`, newScrollbarWidth)
        }
      })
      this.resizeObserver.observe(document.body)
    }

    if(mode === 'display') return

    this.setState({
      editorState: this.getInitialEditorState(),
    })

    ;(scrollToFixedContainer || window).addEventListener('scroll', this.handleContainerScroll)
    this.handleContainerScroll()

    if(focusOnLoad && mode === 'edit') {
      requestAnimationFrame(() =>  {
        if(this.editorRef) {
          this.editorRef.focus()
        }  
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { scrollToFixedContainer, customComponentTypes, toolbarSize, hideAllSettingsIcons, locale } = this.props

    if(prevProps.scrollToFixedContainer !== scrollToFixedContainer) {
      ;(prevProps.scrollToFixedContainer || window).removeEventListener('scroll', this.handleContainerScroll)
      ;(scrollToFixedContainer || window).addEventListener('scroll', this.handleContainerScroll)
      this.handleContainerScroll()
    }

    if(
      prevProps.customComponentTypes !== customComponentTypes
      || prevProps.toolbarSize !== toolbarSize
      || prevProps.hideAllSettingsIcons !== hideAllSettingsIcons
      || prevProps.locale !== locale
    ) {
      locale && setLocale({ locale })

      this.setState(this.getPreppedCustomComponentTypeVars())
    }
  }

  componentWillUnmount() {
    const { scrollToFixedContainer } = this.props

    ;(scrollToFixedContainer || window).removeEventListener('scroll', this.handleContainerScroll)

    this.updateContentThrottle && this.updateContentThrottle()

    this.resizeObserver && this.resizeObserver.disconnect()
  }

  setUpBlockRenderMap = props => {

    // TODO: base this on props
    // FUTURE: consider using https://github.com/thibaudcolas/draftjs-filters

    const blockRenderExtrasMap = Immutable.Map({
      aside: {
        element: 'aside',
      },
    })

    this.blockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderExtrasMap)

    const keysToRemove = [
      'code-block',
      'atomic',
    ]

    keysToRemove.forEach(key => this.blockRenderMap = this.blockRenderMap.delete(key))
  }

  getPreppedCustomComponentTypeVars = props => {
    let { customComponentTypes={} } = this.props

    const locale = getLocale()

    const outOfTheBoxCustomComponentTypes = getOutOfTheBoxCustomComponentTypes({ locale })

    const mapInOutOfTheBox = (customComponentTypesSection=[]) => (
      customComponentTypesSection.map(customComponentType => (
        (
          customComponentType.type
          && customComponentType.override !== false
          && outOfTheBoxCustomComponentTypes[customComponentType.type]
          && {
            ...customComponentType,
            ...outOfTheBoxCustomComponentTypes[customComponentType.type],
          }
        ) || customComponentType
      ))
    )

    const preppedCustomComponentTypes = {
      specialBlockSectionAdditions: mapInOutOfTheBox(customComponentTypes.specialBlockSectionAdditions),
      insertSection: mapInOutOfTheBox(customComponentTypes.insertSection),
    }

    const atomicComponentTypes = [
      ...preppedCustomComponentTypes.insertSection.map(({ type }) => type),
      ...preppedCustomComponentTypes.specialBlockSectionAdditions.map(({ type }) => type),
    ]

    const nonAtomicComponentTypes = toolbarSections.map(({ buttons }) => buttons.map(({ type }) => type)).flat(2)

    const entityComponentTypes = toolbarSections.map(({ buttons }) => buttons.filter(({ isEntity }) => isEntity).map(({ type }) => type)).flat(2)

    return {
      preppedCustomComponentTypes,
      atomicComponentTypes,
      nonAtomicComponentTypes,
      entityComponentTypes,
    }
  }

  handleContainerScroll = () => {
    const { scrollToFixedTop, toolbarFixedStyle } = this.props

    if(scrollToFixedTop == null) return

    const toolbarContainerRect = this.toolbarContainerRef.getBoundingClientRect()

    if(toolbarContainerRect.top < scrollToFixedTop) {

      const newToolbarFixedStyle = {
        position: 'fixed',
        top: scrollToFixedTop,
        width: toolbarContainerRect.width,
      }

      if(JSON.stringify(newToolbarFixedStyle) !== JSON.stringify(toolbarFixedStyle)) {
        this.setState({
          toolbarFixedStyle: newToolbarFixedStyle,
        })
      }
      
    } else {

      if(toolbarFixedStyle !== null) {
        this.setState({
          toolbarFixedStyle: null,
        })
      }

    }
  }

  toggleSettings = newSettingsInfo => {
    const { hideAllSettingsIcons } = this.props
    const { settingsInfo } = this.state

    if(settingsInfo && settingsInfo.block === newSettingsInfo.block) {
      this.closeSettings()

    } else if(!hideAllSettingsIcons) {
      const open = () => this.openSettings(newSettingsInfo)

      if(settingsInfo) {
        this.editorRef.focus()
        this.setState({}, open)
      } else {
        open()
      }
    }
  }

  adjustScrollY = adjAmount => {
    const { fillHeight, scrollToFixedContainer } = this.props

    const scrollContainer = (
      fillHeight
        ? this.editorContainerDiv
        : (
          scrollToFixedContainer
          || window
        )
    )

    scrollContainer.scrollTo({
      top: (scrollContainer.scrollY || scrollContainer.scrollTop || 0) + adjAmount,
    })
  }

  openSettings = settingsInfo => {
    const { settingsHeight } = this.state

    this.editorRef.focus()
    this.setState(
      { settingsInfo },
      () => {
        this.adjustScrollY(settingsHeight)
        this.editorRef.blur()
      },
    )

  }
  
  closeSettings = () => {
    const { settingsInfo, settingsHeight: settingsHeightBefore } = this.state
    
    if(settingsInfo) {
      this.setState(
        {
          settingsInfo: null,
          settingsHeight: 0,
        },
        () => {
          this.adjustScrollY(settingsHeightBefore * -1)
          this.editorRef.focus()
        },
      )
    }
  }
  
  indicateSettingsHeight = settingsHeight => {
    const { settingsInfo, settingsHeight: settingsHeightBefore } = this.state

    this.setState(
      { settingsHeight },
      () => {
        if(settingsInfo) {
          this.adjustScrollY(settingsHeight - settingsHeightBefore)
        }
      },
    )
  }

  updateComponentSettings = ({ newData, info }) => {
    const { editorState, settingsInfo } = this.state
    info = info || settingsInfo

    const content = editorState.getCurrentContent()
    const entityKey = info.block.getEntityAt(0)
    const entity = content.getEntity(entityKey)

    content.replaceEntityData(
      entityKey,
      {
        ...(entity.getData() || {}),
        ...newData,
      }
    )

    const selection = SelectionState.createEmpty(info.block.getKey()).set('focusOffset', 0)
  
    const newContent = Modifier.applyEntity(
      content,
      selection,
      entityKey
    )

    this.onChange(
      EditorState.set(
        editorState,
        {
          currentContent: newContent,
        }
      )
    )
  }

  getInitialEditorState = () => {
    const { initialContent, getDecorators } = this.props

    const decorator = new CompositeDecorator(
      getDecorators
        ? (
          getDecorators({
            findLinkEntities: findEntities('LINK'),
            defaultLinkComponent: ATag,
          })
        )
        : [
          {
            strategy: findEntities('LINK'),
            component: ATag,
          },
        ]
    )

    if(!initialContent) {
      return EditorState.createEmpty(decorator)
    }

    let content
    
    try {
      content = convertFromRaw(JSON.parse(initialContent))

    } catch(e) {

      try {
        const contentObj = convertFromHTML(initialContent)

        content = ContentState.createFromBlockArray(
          contentObj.contentBlocks,
          contentObj.entityMap
        )

      } catch(e) {
        return EditorState.createEmpty(decorator)
      }

    }

    return EditorState.createWithContent(content, decorator)
  }

  getEditable = () => (!this.state.readOnly && this.props.mode !== 'display')

  handleKeyCommand = (command, editorState) => {

    if(!this.getEditable()) return 'not-handled'

    // FUTURE: consider using https://github.com/thibaudcolas/draftjs-filters

    const newState =
      ['bold', 'italic'].includes(command)
        ? RichUtils.handleKeyCommand(editorState, command)
        : null


    if(newState) {
      this.onChange(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  handleAtomic = event => {
    const { editorState } = this.state

    const content = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const focusKey = selection.getFocusKey()
    const focusBlock = content.getBlockForKey(focusKey)
    const focusType = focusBlock.getType()

    if(focusType === 'atomic') {
      handleAtomicKeyDown({
        event,
        editorState,
        onChange: this.onChange,
        toggleSettings: this.toggleSettings,
        getComponentDefinition: this.getComponentDefinition,
        deleteAtomicBlock: this.deleteAtomicBlock,
      })
      return true
    }
  }

  handleReturn = event => {
    const { editorState } = this.state

    if(!this.getEditable()) return 'not-handled'
    if(this.handleAtomic(event)) return 'handled'

    // const blockType = RichUtils.getCurrentBlockType(editorState)
    // if(blockType === '??') {
    //   return 'not_handled'
    // }

    const newState =
      event.shiftKey
        ? RichUtils.insertSoftNewline(editorState)
        : null


    if(newState) {
      this.onChange(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  handleKeyDown = event => {
    const { editorState } = this.state
    const { keyCode } = event

    if(!this.getEditable()) return
    if(this.handleAtomic(event)) return

    switch(keyCode) {

      // Logic is right here, but the preventDefault/stopPropagation doesn't do anything

      // case 8: {  // backspace

      //   const content = editorState.getCurrentContent()
      //   const selection = editorState.getSelection()
      //   const focusKey = selection.getFocusKey()
      //   const focusOffset = selection.getFocusOffset()
      //   const focusBlock = content.getBlockForKey(focusKey)
      //   const focusBlockType = focusBlock.getType()
      //   const blockMap = content.getBlockMap()

      //   const previousBlock = blockMap
      //     .toSeq()
      //     .takeUntil(v => v === focusBlock)
      //     .last()
      //   const previousBlockType = previousBlock.getType()
    
      //   if(
      //     selection.isCollapsed()
      //     && focusBlockType !== 'atomic'  // redundant
      //     && focusOffset === 0
      //     && previousBlockType === 'atomic'
      //   ) {
      //     // We don't want it to clear the line nor delete the previous atomic block
      //     event.preventDefault()
      //     event.stopPropagation()
      //   }

      //   break
      // }

      case 9: {  // tab
        event.preventDefault()

        const newEditorState = RichUtils.onTab(
          event,
          editorState,
          4, // maxDepth
        )

        // Old code below, though I might need it to fine-tune later

        // const content = editorState.getCurrentContent()
        // const selection = editorState.getSelection()
        // const focusKey = selection.getFocusKey()
        // const anchorKey = selection.getAnchorKey()
  
        // let newEditorState = editorState
  
        // const textIsSelected = anchorKey !== focusKey
  
        // const block = content.getBlockForKey(anchorKey)
        // const type = block.getType()
        // const isListBlock = ['unordered-list-item', 'ordered-list-item'].includes(type)
  
        // if(!textIsSelected && isListBlock) {
        //   newEditorState = adjustListIndentation({ EditorState, editorState, event })
        // }
  
        if(newEditorState !== editorState) {
          this.onChange(newEditorState)
        }
  
        break
      }
    }
  }

  forceRender = () => {
    const { editorState } = this.state

    const newEditorState = EditorState.forceSelection(editorState, editorState.getSelection())

    this.setState({
      editorState: newEditorState,
    })
  }

  getComponentDefinition = type => {
    const { preppedCustomComponentTypes={} } = this.state

    let componentDefinition = {}
  
    Object.values(preppedCustomComponentTypes).flat().some(componentTypeDef => {
      if(componentTypeDef.type === type) {
        componentDefinition = componentTypeDef
        return true
      }
    })

    return componentDefinition
  }

  setReadOnly = readOnly => {
    this.setState({ readOnly }, () => {
      if(!readOnly) {
        this.forceRender()
      }
    })
  }

  blockRenderer = block => {
    const { customComponentDataByBlockKey={},
            customComponentDataByComponentType={},
            mode, toolbarSize, hideAllSettingsIcons } = this.props
    const { editorState, settingsInfo, preppedCustomComponentTypes={} } = this.state

    if(block.getType() === 'atomic') {

      const content = editorState.getCurrentContent()
      const entity = content.getEntity(
        block.getEntityAt(0)
      )
      const entityType = entity.getType()
      const settingsData = entity.getData()
  
      const componentDefinition = this.getComponentDefinition(entityType)
      const { dataStructure=[], lang } = componentDefinition
      const componentData = {
        ...(customComponentDataByComponentType[entityType] || {}),
        ...(customComponentDataByBlockKey[block.getKey()] || {}),
      }

      const editable = this.getEditable()

      return {
        component: AtomicBlock,
        editable,
        props: {
          componentDefinition,
          componentData,
          settingsData,
          editorMode: mode,
          readOnly: !editable,
          setParentReadOnly: this.setReadOnly,
          editingSettings: (settingsInfo || {}).block === block,
          toggleSettings: this.toggleSettings,
          updateSettings: newData => {
            this.updateComponentSettings({
              newData,
              info: {
                block,
                dataStructure,
                lang,
              },
            })
          },
          deleteThisBlock: () => this.deleteAtomicBlock(block),
          preppedCustomComponentTypes,
          toolbarSize,
          hideAllSettingsIcons,
          customComponentDataByComponentType,
          customComponentDataByBlockKey,
        },
      }
    }
  }

  blockStyler = block => {
    switch(block.getType()) {
      case 'unstyled': {
        return 'custom-DraftEditor-pLikeDiv'
      }
    }
  }
  
  onChange = (newEditorState, forceUpdateContentCall) => {
    const { updateContent, throttleUpdateContent, isInnerFlipEditor } = this.props
    const { editorState } = this.state

    const content = editorState.getCurrentContent()
    const newContent = newEditorState.getCurrentContent()

    if(updateContent && (content !== newContent || forceUpdateContentCall)) {

      this.updateContentThrottle = () => {
        clearTimeout(this.updateContentThrottleTimeout)
        delete this.updateContentThrottle
        delete this.updateContentThrottleTimeout
        updateContent(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())))
      }

      if(!isInnerFlipEditor && !!throttleUpdateContent) {

        const throttleTimeout = throttleUpdateContent[0] || 500  // half second default
        const maxThrottleWaitTime = throttleUpdateContent[1] || 1000 * 3  // 3 second default
        let msToWait

        if(this.updateContentThrottleTimeout) {
          clearTimeout(this.updateContentThrottleTimeout)
          msToWait = Math.min(
            throttleTimeout, 
            maxThrottleWaitTime - (Date.now() - this.updateContentThrottleInitialTimeoutTime),
          )
        } else {
          this.updateContentThrottleInitialTimeoutTime = Date.now()
          msToWait = throttleTimeout
        }
        this.updateContentThrottleTimeout = setTimeout(this.updateContentThrottle, msToWait)

      } else {

        this.updateContentThrottle()

      }
    }

    this.updateEntitiesSelectionInfo(newEditorState)
    this.setLinkFloaterCoords()

    this.setState({
      editorState: newEditorState,
    })
  }

  onBlur = () => {
    this.blurred = true
  }

  onFocus = () => {
    this.blurred = false
    this.closeSettings()
  }

  toggleType = ({ sectionType, type, isEntity }) => {
    const { editorState } = this.state

    if(isEntity) {

      if(this.currentEntityInfo) {
        if(this.currentEntityInfo.type === type) {
          this.removeEntity()
        }
        return
      }

      const content = editorState.getCurrentContent()
      const selection = editorState.getSelection()

      var startKey = selection.getStartKey()
      var block = content.getBlockForKey(startKey)
      var selectedText = block.getText().slice(selection.getStartOffset(), selection.getEndOffset())

      // TODO: Allow for someone to click link PRIOR to typing the label
      // if(selection.isCollapsed()) {
      //   this.extendEntity = 
      //   return
      // }

      // TODO: Make this function work for other entities, not just links
      if(type !== 'LINK') return

      const url =
        /^(?:https?:\/\/|ftp?:\/\/|mailto: ?)[^\s]+$/i.test(selectedText)
          ? selectedText
          : (
            // /^[-\p{Letter}0-9]+\.[-\p{Letter}0-9\.]+(?:\/[^\s]+)?$/ugi.test(selectedText)
            // Add @babel/plugin-proposal-unicode-property-regex to be able to use the line above
            /^[-\w]+\.[-\w\.]+(?:\/[^\s]+)?$/.test(selectedText)
              ? `https://${selectedText}`
              : ``
          )
      const openInNewTab = true
      const newContent = content.createEntity(
        type,
        'MUTABLE',
        {
          url,
          openInNewTab,
        }
      )
      const entityKey = newContent.getLastCreatedEntityKey()
      const newEditorState = EditorState.set(editorState, { currentContent: newContent })

      if(newEditorState !== editorState) {
        this.onChange(
          RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
          )
        )
      } else {
        // this.currentEntityInfo = type
      }

      return
    }

    this.onChange(
      RichUtils[['block', 'specialBlock'].includes(sectionType) ? 'toggleBlockType' : 'toggleInlineStyle'](
        editorState,
        type
      )
    )
  }

  insertType = ({ type }) => {
    const { editorState } = this.state

    const content = editorState.getCurrentContent()
    const componentDefinition = this.getComponentDefinition(type)
    const { dataStructure=[], defaultData={}, openSettingsOnCreate, lang } = componentDefinition
    const newContent = content.createEntity(
      type,
      'IMMUTABLE',
      defaultData,
    )
    const entityKey = newContent.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, { currentContent: newContent })

    this.onChange(
      AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        ' '
      )
    )

    if(openSettingsOnCreate) {

      this.setState({}, () => {
        
        const content = this.state.editorState.getCurrentContent()
        const blockKeyBefore = newEditorState.getSelection().getStartKey()
        const block = content.getBlockAfter(blockKeyBefore)

        this.openSettings({
          block,
          dataStructure,
          lang,
        })
      })

    }
  }

  deleteAtomicBlock = deleteBlock => {
    const { editorState } = this.state

    const content = editorState.getCurrentContent()
    const selectionBefore = editorState.getSelection()
    let selectionAfter = selectionBefore
    const selectionBlockKey = selectionBefore.getStartKey()
    const deleteBlockKey = deleteBlock.getKey()
    const oldBlockMap = content.getBlockMap()

    if(selectionBlockKey === deleteBlockKey) {
      const newSelectionBlock = (
        (
          oldBlockMap
            .toSeq()
            .takeUntil(v => v === deleteBlock)
            .last()
        )
        || (
          oldBlockMap
            .toSeq()
            .skipUntil(v => v === deleteBlock)
            .skip(1)
            .first()
        )
      )
      const newKey = newSelectionBlock.getKey()
      const newOffset = newSelectionBlock.getLength()

      selectionAfter = selectionBefore.merge({
        anchorKey: newKey,
        anchorOffset: newOffset,
        focusKey: newKey,
        focusOffset: newOffset,
        isBackward: false,
      })
    }

    const withoutAtomicBlock = content.merge({
      blockMap: oldBlockMap.delete(deleteBlockKey),
      selectionBefore,
      selectionAfter,
    })
    
    this.onChange(
      EditorState.push(
        editorState,
        withoutAtomicBlock,
        'remove-range',
      )
    )
  }

  deleteSettingsBlock = () => {
    const { settingsInfo } = this.state

    if(!settingsInfo) return

    this.deleteAtomicBlock(settingsInfo.block)
    this.closeSettings()
  }

  updateLink = updateObject => {
    const { editorState } = this.state

    if(
      !this.currentEntityInfo
      || this.currentEntityInfo.type !== 'LINK'
    ) return

    const content = editorState.getCurrentContent()

    const newContent = content.mergeEntityData(
      this.currentEntityInfo.key,
      updateObject
    )

    const newEditorState = EditorState.set(editorState, { currentContent: newContent })

    if(newEditorState !== editorState) {
      this.onChange(newEditorState, true)
    }
  }

  removeEntity = event => {
    const { editorState } = this.state

    event && event.preventDefault()

    const content = editorState.getCurrentContent()
    const selection = editorState.getSelection()

    const blockKey = selection.getStartKey()
    const block = content.getBlockForKey(blockKey)

    block.findEntityRanges(
      character => (
        this.currentEntityInfo
        && this.currentEntityInfo.key === character.getEntity()
      ),
      (startOffset, endOffset) => {

        const selectedIsBackward = selection.getIsBackward()
        const anchorOffset = selectedIsBackward ? endOffset : startOffset
        const focusOffset = selectedIsBackward ? startOffset : endOffset

        const updatedSelection = selection.merge({
          anchorOffset,
          focusOffset,
        })

        this.onChange(
          EditorState.forceSelection(
            EditorState.push(
              editorState,
              Modifier.applyEntity(content, updatedSelection, null),
              'apply-entity'
            ),
            selection
          )
        )
    
      }
    )

  }

  disabledEntities = []

  updateEntitiesSelectionInfo = editorState => {
    const { atomicComponentTypes, nonAtomicComponentTypes, entityComponentTypes } = this.state

    const selection = editorState.getSelection()
    const content = editorState.getCurrentContent()

    const startBlockKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const endBlockKey = selection.getEndKey()
    const endOffset = selection.getEndOffset()
    const finalOffset = Math.max(startOffset, endOffset - 1)
    const block = content.getBlockForKey(startBlockKey)

    const disabledEntitiesObj = {}
    const firstEntityKey = block.getEntityAt(startOffset)
    let previousEntityKey = null

    for(let offset = startOffset; offset <= finalOffset; offset++) {
      const entityKey = block.getEntityAt(offset)

      if(!entityKey) continue

      if(offset !== startOffset && entityKey !== previousEntityKey) {
        const entity = content.getEntity(entityKey)
        disabledEntitiesObj[entity.getType()] = true
      }

      previousEntityKey = entityKey
    }

    const lastEntityKey = block.getEntityAt(finalOffset)
    const lastEntity = lastEntityKey && content.getEntity(lastEntityKey)
    const lastEntityType = lastEntity && lastEntity.getType()

    // This did not work because it wasn't yet rendered; replaced with onBlur
    // const windowSelection = window.getSelection()
    // const noSelectionOrCursor = (
    //   windowSelection.rangeCount === 0
    //   || !!windowSelection.anchorNode.parentElement.closest('#flipeditor-link')
    // )

    if(previousEntityKey !== lastEntityKey) {
      const entity = content.getEntity(previousEntityKey || lastEntityKey)
      disabledEntitiesObj[entity.getType()] = true
    }

    if(selection.isCollapsed()) {
      // if selection selection is collapsed, then disallow entities (e.g. LINK)
      entityComponentTypes.forEach(type => {
        if(type !== lastEntityType) {
          disabledEntitiesObj[type] = true
        }
      })
    } else {
      // if there is a selection, disallow all atomic
      atomicComponentTypes.forEach(type => {
        disabledEntitiesObj[type] = true
      })
    }
    if(startBlockKey !== endBlockKey) {
      // if selection includes multiple blocks, then disallow entities (e.g. LINK)
      entityComponentTypes.forEach(type => {
        disabledEntitiesObj[type] = true
      })
    }
    // if(noSelectionOrCursor) {
    //   // if no selection or cursor, then disallow all
    //   atomicComponentTypes.forEach(type => {
    //     disabledEntitiesObj[type] = true
    //   })
    //   nonAtomicComponentTypes.forEach(type => {
    //     disabledEntitiesObj[type] = true
    //   })
    // }

    const allSelectedBlocks = [ block ]
    while(allSelectedBlocks.at(-1).getKey() !== endBlockKey) {
      allSelectedBlocks.push(content.getBlockAfter(allSelectedBlocks.at(-1).getKey()))
      if(allSelectedBlocks.length > 1000) break  // just in case to avoid infinite loop
    }

    const numAtomicBlocks = allSelectedBlocks.filter(block => block.getType() === `atomic`).length

    if(numAtomicBlocks > 0) {
      // if selection includes an atomic block, then disallow all but bold and italic
      nonAtomicComponentTypes.forEach(type => {
        if(![ `BOLD`, `ITALIC` ].includes(type)) {
          disabledEntitiesObj[type] = true
        }
      })
    }
    if(numAtomicBlocks === allSelectedBlocks.length) {
      // if selection is only atomic blocks, then disallow all
      atomicComponentTypes.forEach(type => {
        disabledEntitiesObj[type] = true
      })
      nonAtomicComponentTypes.forEach(type => {
        disabledEntitiesObj[type] = true
      })
    }

    const disabledEntities = Object.keys(disabledEntitiesObj)
    disabledEntities.sort()

    if(JSON.stringify(disabledEntities) !== JSON.stringify(this.disabledEntities)) {
      this.disabledEntities = disabledEntities || []
    }

    if(!lastEntityKey || firstEntityKey !== lastEntityKey) {
      this.currentEntityInfo = null
    } else if((this.currentEntityInfo || {}).key === lastEntityKey) {
      this.currentEntityInfo.data = lastEntity.getData()
    } else {
      this.currentEntityInfo = {
        key: lastEntityKey,
        type: lastEntityType,
        data: lastEntity.getData(),
        mutibility: lastEntity.getMutability(),
      }
    }

  }

  setEditorContainerRef = div => this.editorContainerDiv = div

  setLinkFloaterCoords = () => {
    if(!this.editorContainerDiv) return

    try {
      const cursorRect = window.getSelection().getRangeAt(0).getBoundingClientRect()
      const containerRect = this.editorContainerDiv.getBoundingClientRect()

      if(!cursorRect.height) return

      this.linkFloaterCoords = {
        left: (
          Math.max(
            0,
            Math.min(
              containerRect.width - LINK_FLOATER_WIDTH,
              cursorRect.left - containerRect.left - LINK_FLOATER_WIDTH/2
            )
          )
        ),
        top: cursorRect.top - containerRect.top + cursorRect.height + this.editorContainerDiv.scrollTop,
      }

    } catch(e) {}

  }

  setToolbarContainerRef = div => this.toolbarContainerRef = div

  setEditorRef = ref => this.editorRef = ref

  render() {
    const { id, mode, appearReadOnly, showDisabledToolbar, noPaddingBottom, generateClassName, addServerStyleSheets,
            fillHeight, wrapperStyle, contentSass, muiJss, muiTheme, isInnerFlipEditor, editorProps={},
            openAssetListDialog, openUploadAssetDialog, toolbarSize, hideAllSettingsIcons } = this.props
    const { editorState, settingsInfo, settingsHeight, toolbarFixedStyle,
            readOnly, preppedCustomComponentTypes } = this.state

    if(!editorState) return null

    const selection = editorState.getSelection()
    const content = editorState.getCurrentContent()
    const currentBlockType = content
      .getBlockForKey(selection.getStartKey())
      .getType()
    const currentStyle = editorState.getCurrentInlineStyle()

    const showLinkFloater = (
      this.currentEntityInfo
      && this.currentEntityInfo.type === 'LINK'
    )

    let currentEntityType = this.currentEntityInfo && (this.currentEntityInfo.type || this.currentEntityInfo)
    if(this.disabledEntities.includes(currentEntityType)) {
      currentEntityType = false
    }

    const locale = getLocale()

    let settingsData, settingsTypeLabel
    if(settingsInfo) {
      const entityKey = settingsInfo.block.getEntityAt(0)
      const entity = content.getEntity(entityKey)
      settingsData = entity.getData()

      settingsTypeLabel = settingsInfo.lang
        ? (settingsInfo.lang[locale] || settingsInfo.lang.en || '?')
        : '?'
    }

    const editorBehaviorProps = (
      isAndroid
        ? {
          autoCapitalize: "off",
          autoComplete: "off",
          autoCorrect: "off",
          spellCheck: false,
          ...editorProps,
        }
        : {
          spellCheck: true,
          ...editorProps,
        }
    )


    let jsx = (
      <FlipEditorContainer
        className={`flipeditor`}
        $fillHeight={fillHeight}
        style={wrapperStyle}
      >
        {(mode === 'edit' || showDisabledToolbar) &&
          <ToolbarContainer
            ref={this.setToolbarContainerRef}
            $readOnly={readOnly || appearReadOnly || showDisabledToolbar}
            $toolbarSize={toolbarSize}
            className="flipeditor-toolbar-container"
          >
            <Toolbar
              currentBlockType={currentBlockType}
              currentStyle={currentStyle}
              disabledEntities={this.disabledEntities}
              blurred={this.blurred}
              currentEntityType={currentEntityType}
              toggleType={this.toggleType}
              insertType={this.insertType}
              deleteSettingsBlock={this.deleteSettingsBlock}
              customComponentTypes={preppedCustomComponentTypes}
              settingsOpen={!!settingsInfo}
              settingsComponentTypeLabel={settingsTypeLabel}
              closeSettings={this.closeSettings}
              indicateSettingsHeight={this.indicateSettingsHeight}
              updateComponentSettings={this.updateComponentSettings}
              settingsComponentDataStructure={settingsInfo ? settingsInfo.dataStructure : null}
              settingsComponentData={settingsData}
              style={toolbarFixedStyle}
              openAssetListDialog={openAssetListDialog}
              openUploadAssetDialog={openUploadAssetDialog}
              toolbarSize={toolbarSize}
              hideAllSettingsIcons={hideAllSettingsIcons}
            />
          </ToolbarContainer>
        }
        <EditorContainer
          className="flipeditor-editor-container"
          onKeyDown={this.handleKeyDown}
          ref={this.setEditorContainerRef}
          $readOnly={mode === 'display'}
          $noPaddingBottom={noPaddingBottom}
          $fillHeight={fillHeight}
          $contentSass={contentSass}
          style={settingsHeight ? { paddingTop: settingsHeight } : null}
        >
          <Editor
            {...editorBehaviorProps}
            editorKey={id}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            handleReturn={this.handleReturn}
            blockStyleFn={this.blockStyler}
            blockRendererFn={this.blockRenderer}
            blockRenderMap={this.blockRenderMap}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            readOnly={!this.getEditable()}
            // stripPastedStyles={true}
            textDirectionality={isRTL(locale) ? 'RTL' : 'LTR'}
            ref={this.setEditorRef}
          />
          {showLinkFloater &&
            <LinkFloater
              style={{
                left: (this.linkFloaterCoords || {}).left,
                top: (this.linkFloaterCoords || {}).top,
              }}
              url={(this.currentEntityInfo.data || {}).url}
              openInNewTab={(this.currentEntityInfo.data || {}).openInNewTab || false}
              onChange={this.updateLink}
              onRemove={this.removeEntity}
              openAssetListDialog={openAssetListDialog}
              openUploadAssetDialog={openUploadAssetDialog}
            />
          }
          <ClearBoth />
        </EditorContainer>
      </FlipEditorContainer>
    )

    if(!isInnerFlipEditor) {
      jsx = (
        <StylesProvider
          jss={muiJss || defaultJss}
          generateClassName={generateClassName || defaultGenerateClassName}
        >
          <ThemeProvider theme={muiTheme || defaultTheme}>
            {jsx}
          </ThemeProvider>
        </StylesProvider>
      )
    }

    // support for ssr
    if(addServerStyleSheets && typeof window === 'undefined' && !isInnerFlipEditor) {
      const materialUiSheets = new MaterialUiServerStyleSheets({ serverGenerateClassName: generateClassName || defaultGenerateClassName })
      const result = materialUiSheets.collect(jsx)
      addServerStyleSheets(materialUiSheets)
      return result
    }

    return jsx
  }

}

export default FlipEditor