import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import useVersionInfos from '../../../hooks/useVersionInfos'
import usePassageNextPreviousInfo from '../../../hooks/usePassageNextPreviousInfo'
import useUpdatePassageBookmark from '../../../hooks/useUpdatePassageBookmark'

import PassageRefButton from '../../passage/PassageRefButton'

const Container = styled.div`
  display: flex;
`

const StyledIconButton = styled(IconButton)`
  padding: 6px;
  margin: 6px 4px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: white;

  &:hover {
    background: white;
  }

  &.Mui-disabled {
    background: white;
  }

`

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  height: 18px;
`

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  height: 18px;
`

const HomeMiniPassageRefNavigator = ({
  passageBookmark,
  setPassageBookmarks,
  getPassageBookmarks,
}) => {

  const { defaultVersionId, unhiddenSelectedVersionInfos } = useVersionInfos()

  const { ref, versionId=defaultVersionId, parallelVersionId } = passageBookmark || {}
  const { bookId=1, chapter=1 } = ref || {}

  const { updatePassageBookmark } = useUpdatePassageBookmark({
    getPassageBookmarks,
    setPassageBookmarks,
    unhiddenSelectedVersionInfos,
    defaultVersionId,  
  })

  const { previousProps, nextProps } = usePassageNextPreviousInfo({
    updatePassageBookmark,
    bookId,
    chapter,
    versionId,
    columnIdx: 0,  
  })

  return (
    <Container>

      <StyledIconButton {...previousProps} >
        <StyledArrowBackIcon />
      </StyledIconButton>

      <PassageRefButton
        bookId={bookId}
        chapter={chapter}
        versionId={versionId}
        parallelVersionId={parallelVersionId}
        updatePassageBookmark={updatePassageBookmark}
        abbreviated
        mini
      />

      <StyledIconButton {...nextProps} >
        <StyledArrowForwardIcon />
      </StyledIconButton>

    </Container>
  )
}

export default memo(HomeMiniPassageRefNavigator)