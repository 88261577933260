import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Tooltip from '@material-ui/core/Tooltip'

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const Switch = styled.div`
  position: absolute;
  top: ${({ $hasHeader }) => $hasHeader ? `46px` : `16px`};
  z-index: 1;
  display: flex;
  border: 1px solid #707070;
  background: #4c4c4c;
  border-radius: 100px;
  padding: 3px;
`

const Marker = styled.div`
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 3px;
  width: 80px;
  transition: transform .15s ease-in-out;
  transform: translateX(${({ $view }) => (
    ($view === `chart` && 80)
    || ($view === `inline` && 160)
    || 0
  )}px);
  background: #707070;
  border-radius: 100px;
`

const StyledButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 30px;
  padding: 5px;
  line-height: .9;
  white-space: nowrap;
  transition: background-color .25s ease-in-out;
  border-radius: 100px;
  cursor: default;
  user-select: none;

  ${({ $selected, onClick }) => ($selected || onClick) ? `` : `
    opacity: .3;
  `}

  ${({ $selected, onClick }) => ($selected || !onClick) ? `` : `
    &:hover {
      background-color: #555555;
      cursor: pointer;
    }
  `}
`

const Line1 = styled.div`
  text-transform: uppercase;
  font-size: 10px;
`

const Line2 = styled.div`
  font-weight: bold;
  font-size: 14px;
`

const VerseNumberPopperSwitch = ({
  hasHeader,
  view,
  setView,
  bookId,
  ...otherProps
}) => {

  const goSetView = useCallback(({ target }) => setView(target.closest(`[data-view]`).getAttribute(`data-view`)), [ setView ])

  return (
    <Container
      {...otherProps}
    >
      <Switch $hasHeader={hasHeader}>

        <Marker $view={view} />

        <StyledButton
          onClick={setView && goSetView}
          data-view="versions"
          $selected={view === `versions`}
        >
          <Line1>
            {i18n("Bible", "Half of phrase “Bible Versions”")}
          </Line1>
          <Line2>
            {i18n("Versions", "Half of phrase “Bible Versions”")}
          </Line2>
        </StyledButton>

        <Tooltip
          title={
            setView
              ? ``
              : i18n("Original language tools have been disabled")
          }
        >
          <StyledButton
            onClick={setView && goSetView}
            data-view="chart"
            $selected={view === `chart`}
          >
            <Line1>
              {bookId >= 40 ? i18n("Greek") : i18n("Hebrew")}
            </Line1>
            <Line2>
              {i18n("Chart")}
            </Line2>
          </StyledButton>
        </Tooltip>

        <Tooltip
          title={
            setView
              ? ``
              : i18n("Original language tools have been disabled")
          }
        >
          <StyledButton
            onClick={setView && goSetView}
            data-view="inline"
            $selected={view === `inline`}
          >
            <Line1>
              {bookId >= 40 ? i18n("Greek") : i18n("Hebrew")}
            </Line1>
            <Line2>
              {i18n("Inline")}
            </Line2>
          </StyledButton>
        </Tooltip>

      </Switch>
    </Container>
  )

}

export default memo(VerseNumberPopperSwitch)