import { memo } from 'react'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import { i18n } from 'inline-i18n'

import { HAS_FULLSCREEN_OPTION } from '../../../utils/misc'

import Header from "../../common/Header"
import LinkIconButton from '../../common/LinkIconButton'

const Container = styled.div`
  flex: 1;
  overflow: hidden;
`

const EquipLogoImg = styled.img`
  height: 24px;

  ${({ $noInstructions }) => !$noInstructions ? `` : `
    height: 40px;
    margin: 4px 0 -10px;
  `}
`

const Instructions = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 1;
  margin: -5px 30px 0 7px;
`

const InstructionsInTooltip = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  padding: 10px 5px;
  font-weight: 400;
`

const EmbedHeader = ({
  instructions,
  toggleEmbedFullScreen,
}) => {

  return (
    <>

      <Header
        toggleEmbedFullScreen={toggleEmbedFullScreen}
        accountMenuButtonReplacement={
          <Tooltip
            title={HAS_FULLSCREEN_OPTION ? i18n("Close Full Screen") : i18n("Close Full Study Mode")}
          >
            <span>
              <LinkIconButton
                onClick={toggleEmbedFullScreen}
              >
                <CloseIcon />
              </LinkIconButton>
            </span>
          </Tooltip>
        }
      >

        <Container>

          <EquipLogoImg
            src="/biblearc_equip_1.svg"
            $noInstructions={!instructions.trim()}
          />

          <Tooltip
            title={
              <InstructionsInTooltip>
                {instructions}
              </InstructionsInTooltip>
            }
          >
            <Instructions>
              {instructions}
            </Instructions>
          </Tooltip>

        </Container>

      </Header>

    </>
  )
}

export default memo(EmbedHeader)