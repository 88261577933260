import { useMemo } from "react"
import { getNumberOfChapters } from "@bibletags/bibletags-versification"

import useVersionInfo from './useVersionInfo'
import { KJV_VERSION } from "../utils/constants"

const useNumChapters = ({
  versionId,
  bookId,
 }) => {

  const { version=KJV_VERSION } = useVersionInfo(versionId)

  const numChapters = useMemo(
    () => (
      bookId
        ? (getNumberOfChapters({ versionInfo: version, bookId }) || 0)
        : 0
    ),
    [ version, bookId ]
  )

  return numChapters
}

export default useNumChapters
