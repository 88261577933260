import { memo, useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'

import { equalTags, removeUnassociatedTags } from '../../../utils/misc'

import ConfirmDialog from '../../common/ConfirmDialog'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useRefState from '../../../hooks/useRefState'
import useSetTimeout from '../../../hooks/useSetTimeout'

const Container = styled.div`
  line-height: 1.2;
  margin: -18px 0 0;
`

const UntaggedWarning = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.tertiary.light}77;
  padding: 10px;
  margin: 10px 0 15px;
`

const UntaggedWarningHeading = styled.div`
  font-weight: bold;
  margin-bottom: 7px;
`

const UntaggedWarningExplanation = styled.div`
  margin-bottom: 7px;
  opacity: .8;
`

const UntaggedWarningInstructions = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  font-style: italic;
`

const IndicateCapitalization = styled.div`
  margin: 10px 0 10px;
`

const IndicateCapitalizationInstructions = styled.div`
  font-size: 15px;
`

const IndicateCapitalizationExamples = styled.div`
  font-weight: 300;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  padding-left: 10px;
  margin: 15px 0;
  font-size: 13px;
`

const CapitalizationRow = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin: 7px 0;
`

const CapitalizationWord = styled.div`
  color: ${({ $selected }) => $selected ? 'white' : 'black'};
  background: ${({ theme, $selected }) => $selected ? theme.palette.primary.main : theme.palette.grey[200]};
  padding: 5px 10px;
  border-radius: 3px;
  cursor: default;

  ${({ $selected, theme }) => $selected ? `` : `
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background: ${theme.palette.grey[300]};
      }
    }
  `}
`

const CapitalizationContext = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.grey[500]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`

const CapitalizationContextWord = styled.span`
  color: ${({ theme }) => theme.palette.grey[800]};
`

const AreYouSure = styled.div`
  margin: -10px 0 15px;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.grey[600]};
`

const TaggerSubmitButton = ({
  hasMyTagSet,
  tags,
  loadingMyTagSetSubmissions,
  myTagSet,
  goSubmitTagSet,
  translationPieces,
  onClickNext,
}) => {

  const matchesMyTagSet = myTagSet && equalTags(tags, myTagSet.tags)

  const [ showDialog, toggleShowDialog ] = useSimpleToggle(false)
  const [ properlyCaptitalizedTranslationWordByWordNumberInVerse, setProperlyCaptitalizedTranslationWordByWordNumberInVerse, getProperlyCaptitalizedTranslationWordByWordNumberInVerse ] = useRefState({})
  const allCapsWordsByWordNumberInVerse = useRef({})
  const [ submitted, setSubmitted ] = useState(false)
  const [ setResetTimeout ] = useSetTimeout()

  const translationWordsNeedingCapitalizationChoice = useMemo(
    () => {

      const wordsNeedingChoice = []
      let lastCharWasMajorPunctuation = true

      const examinePieces = pieces => {
        pieces.forEach(({ text, children, type, tag, wordNumberInVerse }) => {
          if(text) {
            wordsNeedingChoice.forEach(wordNeedingChoice => {
              if(wordNeedingChoice.followingContext.length < 20) {
                wordNeedingChoice.followingContext += text
              }
            })
          }
          if(type === 'word') {
            if([ `nd`, `sc` ].includes(tag)) {
              allCapsWordsByWordNumberInVerse.current[wordNumberInVerse] = text.toUpperCase()
            }
            if(
              text
              && lastCharWasMajorPunctuation
              && text.slice(0,1) !== text.slice(0,1).toLowerCase()  // first char is capitalized
              && text.slice(1) === text.slice(1).toLowerCase()  // remaining chars are not capitalized
              && ![ `nd`, `sc` ].includes(tag)
            ) {
              wordsNeedingChoice.push({ wordNumberInVerse, word: text, followingContext: '' })
            }
            lastCharWasMajorPunctuation = false
          } else if(text) {
            if(text.trim()) {
              lastCharWasMajorPunctuation = /[.;:,¿?!¡"“’(]$/.test(text.trim())
            }
          } else if(children) {
            examinePieces(children)
          }
        })
      }

      examinePieces(translationPieces)

      return wordsNeedingChoice

    },
    [ translationPieces ]
  )

  const totalOriginalWords = tags.map(({ o }) => o.length).reduce((acc, numOs) => acc + numOs, 0)
  const numUntaggedOriginalWords = tags.filter(({ t }) => t.length === 0).length
  const totalTranslationWords = tags.map(({ t }) => t.length).reduce((acc, numTs) => acc + numTs, 0)
  const numUntaggedTranslationWords = tags.filter(({ o }) => o.length === 0).length
  const severalUntaggedWords = (
    numUntaggedOriginalWords / totalOriginalWords > .15
    || numUntaggedTranslationWords / totalTranslationWords > .25
  )

  const buttonLabel = (
    (loadingMyTagSetSubmissions && i18n("Loading..."))
    || (matchesMyTagSet && i18n("Viewing your previous tag submission"))
    || (hasMyTagSet && i18n("Resubmit tags"))
    || i18n("Submit tags")
  )

  const disabled = (
    loadingMyTagSetSubmissions
    || matchesMyTagSet
    || removeUnassociatedTags(tags).length === 0
  )

  const onConfirm = useCallback(
    () => {
      const properlyCaptitalizedTranslationWordByWordNumberInVerse = {
        ...allCapsWordsByWordNumberInVerse.current,
        ...getProperlyCaptitalizedTranslationWordByWordNumberInVerse()
      }
      goSubmitTagSet({ properlyCaptitalizedTranslationWordByWordNumberInVerse })
      setSubmitted(true)
    },
    [ goSubmitTagSet, getProperlyCaptitalizedTranslationWordByWordNumberInVerse ],
  )

  const dontTagAnother = useCallback(
    () => {
      toggleShowDialog({ force: false })
      setResetTimeout(() => setSubmitted(false), 500)
    },
    [ toggleShowDialog, setResetTimeout ],
  )

  return (
    <>

      <Button
        color="primary"
        variant="contained"
        disableElevation
        fullWidth
        onClick={toggleShowDialog}
        disabled={disabled}
      >
        {buttonLabel}
      </Button>

      <ConfirmDialog
        open={showDialog}
        title={i18n("Confirm Tag Submission")}
        cancelButtonLabel={submitted ? i18n("Close"): i18n("Cancel")}
        onCancel={submitted ? dontTagAnother : toggleShowDialog}
        confirmButtonLabel={submitted ? i18n("Tag another") : buttonLabel}
        onConfirm={submitted ? onClickNext : onConfirm}
        explanation={
          <>

            {submitted &&
              <AreYouSure>
                {i18n("Your tag set has been submitted.")}
              </AreYouSure>
            }

            {!submitted &&
              <Container>

                {severalUntaggedWords &&
                  <UntaggedWarning>
                    <UntaggedWarningHeading>
                      {i18n("Warning: {{num}} words are still untagged.", { num: numUntaggedOriginalWords + numUntaggedTranslationWords })}
                    </UntaggedWarningHeading>
                    <UntaggedWarningExplanation>
                      {i18n("Only leave words untagged when an original word is not translated, or a translation word is supplied without an original word counterpart.")}
                    </UntaggedWarningExplanation>
                    <UntaggedWarningInstructions>
                      {i18n("If words have been left untagged simply because you do not know the propper tagging, please do NOT submit this tag set.")}
                    </UntaggedWarningInstructions>
                  </UntaggedWarning>
                }

                {translationWordsNeedingCapitalizationChoice.length > 0 &&
                  <IndicateCapitalization>

                    <IndicateCapitalizationInstructions>
                      {i18n("Indicate how each of word would appear in a Bible dictionary—capitalized or not.")}
                    </IndicateCapitalizationInstructions>

                    <IndicateCapitalizationExamples>
                      {i18n("E.g. “I” and “Paul” and even “Father” (when it refers to God) should be capitalized whereas “then” and “so” should not.")}
                    </IndicateCapitalizationExamples>

                    {translationWordsNeedingCapitalizationChoice.map(({ wordNumberInVerse, word, followingContext }) => {
                      const setProperlyCapitalizedWord = properlyCapitalizedWord => {
                        setProperlyCaptitalizedTranslationWordByWordNumberInVerse({
                          ...properlyCaptitalizedTranslationWordByWordNumberInVerse,
                          [ wordNumberInVerse ]: properlyCapitalizedWord,
                        })
                      }
                      return (
                        <CapitalizationRow key={wordNumberInVerse}>
                          <CapitalizationWord
                            onClick={() => setProperlyCapitalizedWord(word)}
                            $selected={properlyCaptitalizedTranslationWordByWordNumberInVerse[wordNumberInVerse] === word}
                          >
                            {word}
                          </CapitalizationWord>
                          <CapitalizationWord
                            onClick={() => setProperlyCapitalizedWord(word.toLowerCase())}
                            $selected={properlyCaptitalizedTranslationWordByWordNumberInVerse[wordNumberInVerse] === word.toLowerCase()}
                          >
                            {word.toLowerCase()}
                          </CapitalizationWord>
                          <CapitalizationContext>
                            <CapitalizationContextWord>
                              {word}
                            </CapitalizationContextWord>
                            {followingContext}
                            {` `}
                            {i18n("...", "ellipsis")}
                          </CapitalizationContext>
                        </CapitalizationRow>
                      )
                    })}

                  </IndicateCapitalization>
                }

                {!severalUntaggedWords && translationWordsNeedingCapitalizationChoice.length === 0 &&
                  <AreYouSure>
                    {i18n("Are you sure you want to submit this tag set?")}
                  </AreYouSure>
                }

              </Container>
            }

          </>
        }
        disabled={translationWordsNeedingCapitalizationChoice.some(({ wordNumberInVerse }) => !properlyCaptitalizedTranslationWordByWordNumberInVerse[wordNumberInVerse])}
      />

    </>
  )
}

export default memo(TaggerSubmitButton)