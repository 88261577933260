import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'

import { preventDefaultEvent } from '../../utils/misc'

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin: 4px 2px 4px -12px;
`

const StyledCloseIcon = styled(CloseIcon)`
  height: 20px;
`

const PassageClose = ({
  closeColumn,
}) => {

  return (
    <Tooltip
      title={i18n("Remove column")}
    >
      <StyledIconButton
        onClick={closeColumn}
        onMouseDown={preventDefaultEvent}
        tabIndex={-1}
      >
        <StyledCloseIcon />
      </StyledIconButton>
    </Tooltip>
  )
}

export default memo(PassageClose)