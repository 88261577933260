import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import List from '@material-ui/core/List'

import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useNoDoubleClickCallback from '../../../hooks/useNoDoubleClickCallback'

// import CustomSlider from '../../common/CustomSlider'
// import CustomSwitch from '../../common/CustomSwitch'
import CustomCheckbox from '../../common/CustomCheckbox'
// import SubtleDivider from '../../common/SubtleDivider'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 15px 2px;
`

// const StyledSubtleDivider = styled(SubtleDivider)`
//   margin: 0 -15px;
// `

const LegacyPhrasingSettings = ({
  projectId,
  legacySetting,
}) => {

  // const getDisplayedTextSizes = useInstanceValue(displayedTextSizes)

  const getLegacySetting = useInstanceValue(legacySetting)
  const [ goSetLegacySetting ] = useGoSetModuleSetting({
    moduleSetting: legacySetting,
    projectId,
  })

  // const updateBaseTextSize = useCallback(
  //   (event, value) => {
  //     setDisplayedTextSizes({
  //       ...getDisplayedTextSizes(),
  //       base: value / 100,
  //     })
  //   },
  //   [ getDisplayedTextSizes, setDisplayedTextSizes ],
  // )

  // const commitTextSizesChange = useCallback(
  //   () => {
  //     goSetTextSizes({
  //       value: getDisplayedTextSizes(),
  //     })
  //   },
  //   [ getDisplayedTextSizes, goSetTextSizes ],
  // )

  const toggleTwoColView = useNoDoubleClickCallback(({ target }) => {
    const currentLegacySettingValue = getLegacySetting().value
    goSetLegacySetting({
      value: {
        ...currentLegacySettingValue,
        settings: {
          ...(currentLegacySettingValue.settings || {}),
          numColsShown: target.checked ? 2 : 1,
        },
      },
    })
  })

  const { numColsShown } = legacySetting.value.settings || {}

  return (
    <Container>

      {/* <CustomSlider
        label={i18n("Text Size", "", "phrasing")}
        min={200}
        max={400}
        color="secondary"
        value={parseInt(displayedTextSizes.base * 100)}
        onChange={updateBaseTextSize}
        onChangeCommitted={commitTextSizesChange}
        className="module-settings-textsizes"
      /> */}

      <List>

        <CustomCheckbox
          checked={numColsShown === 2}
          onChange={toggleTwoColView}
          label={i18n("Two column view", "", "phrasing")}
        />

      </List>

    </Container>
  )
}

export default memo(LegacyPhrasingSettings)