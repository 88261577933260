import { memo } from 'react'
import styled from 'styled-components'
import { useLocation } from "react-router-dom"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import useProjectsTitleAndIcon from '../../../hooks/useProjectsTitleAndIcon'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  user-select: none;
  justify-content: space-between;
`

const Title = styled.div`
  font-size: 18px;
`

const StyledButton = styled(Button)`
  height: 38px;
  text-transform: none;
  font-size: 18px;
  font-weight: normal;
  margin: -6px -8px;
  padding-right: 3px;
  
  @media (min-width: 801px) {
    &:hover {
      background: none;
      cursor: default;
    }
    .MuiTouchRipple-root {
      display: none;
    }
  }
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin: 0 3px;
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`

const StyledIconButton = styled(IconButton)`
  margin: -20px -8px -20px 5px;
  display: none;
  color: black;

  .MuiSvgIcon-root {
    height: 20px;
  }

  @media (max-width: 900px) {
    display: block;
  }
`

const ProjectsListHeader = ({
  expandLeftPanel,
  expandRightPanel,
}) => {

  const location = useLocation()
  const search = (new URLSearchParams(location.search).get(`search`) || ``).trim()

  const { title, icon } = useProjectsTitleAndIcon()

  return (
    <Container>

      <StyledButton
        onClick={expandLeftPanel}
      >

        {icon}

        <Title>
          {title}
        </Title>

        <StyledExpandMoreIcon />

      </StyledButton>

      <StyledIconButton
        onClick={expandRightPanel}
      >
        <SearchIcon
          color={!!search ? "primary" : "inherit"}
        />
      </StyledIconButton>

    </Container>
  )
}

export default memo(ProjectsListHeader)