import folder_fields from './folder_fields'
import project_fields from './project_fields'
import tag_fields from './tag_fields'
import moduleByProject_fields from './moduleByProject_fields'
import module_fields from './module_fields'
import modulePassage_fields from './modulePassage_fields'
import moduleSetting_fields from './moduleSetting_fields'
import modulePiece_fields from './modulePiece_fields'
import moduleDot_fields from './moduleDot_fields'
import moduleMarkup_fields from './moduleMarkup_fields'
import formattingKey_fields from './formattingKey_fields'
import highlight_fields from './highlight_fields'

const fields = `
  moreToGet
  newUpdatedSince
  folder {
    rows { ${folder_fields} }
    deletedIds
  }
  project {
    rows { ${project_fields} }
    deletedIds
  }
  tag {
    rows { ${tag_fields} }
    deletedIds
  }
  moduleByProject {
    rows { ${moduleByProject_fields} }
    deletedIds
  }
  module {
    rows {
      ${module_fields}
      ${/*
        - Will evict() get rid of a deleted passage or moduleByProject? If so, great! This can be deleted. If not, I need to fix it either with this sort of code, deletedAt, or modifying the cache
        modulePassages {
          id
        }
        moduleByProjects {
          id
        }
      */ ""}
    }
    deletedIds
  }
  modulePassage {
    rows { ${modulePassage_fields} }
    deletedIds
  }
  moduleSetting {
    rows { ${moduleSetting_fields} }
  }
  modulePiece {
    rows { ${modulePiece_fields} }
    deletedIds
  }
  moduleDot {
    rows { ${moduleDot_fields} }
    deletedIds
  }
  moduleMarkup {
    rows { ${moduleMarkup_fields} }
    deletedIds
  }
  formattingKey {
    rows { ${formattingKey_fields} }
    deletedIds
  }
  highlight {
    rows { ${highlight_fields} }
    deletedIds
  }
`

export default fields