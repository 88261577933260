import { useMemo, useState } from 'react'
import { useWindowSize } from 'react-use'  // Note: do NOT use useWindowSize elsewhere; use useAppSize instead

import {
  MINIMUM_STUDY_BIBLE_ITEM_POPOVER_WIDTH,
  MINIMUM_MEDIUM_WIDTH,
  MINIMUM_MEDIUM_LARGE_WIDTH,
  MINIMUM_LARGE_WIDTH,
} from '../utils/constants'
import useInstanceValue from './useInstanceValue'
import useSetTimeout from './useSetTimeout'
import useLayoutEffectAsync from './useLayoutEffectAsync'

const useAppSize = () => {

  // The double checking below is due to iOS having strange behavior when waking up
  const windowSize = useWindowSize()
  const [ doubleCheckedWindowSize, setDoubleCheckedWindowSize ] = useState(windowSize)
  const getDoubleCheckedWindowSize = useInstanceValue(doubleCheckedWindowSize)
  const [ setDoubleCheckTimeout ] = useSetTimeout()
  const [ setDoubleCheckBackupTimeout ] = useSetTimeout()

  useLayoutEffectAsync(
    () => {
      const updateIfChanged = ({ isFirstCheck }={}) => {

        // On my iPad, the window.innerWidth reports to be half the size of what it really
        // is and then does not call an window.onresize event when it is fixed a moment later.
        // Thus, I do the below. 

        const { width, height } = getDoubleCheckedWindowSize()
        if(
          (
            window.innerWidth !== width
            || window.innerHeight !== height
          )
          && (
            !isFirstCheck

            // user just flipped the orientation or is resizing the browser window via the corner
            || Math.abs((window.innerWidth + window.innerHeight) - (width + height)) < Math.min(width + height) / 2
            || window.innerWidth + window.innerHeight > width + height

            // user resizing the browser window via the side
            || window.innerWidth === width

            // user resizing the browser window via the bottom
            || window.innerHeight === height
          )
        ) {
          setDoubleCheckedWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          })
        }
      }
      updateIfChanged({ isFirstCheck: true })
      setDoubleCheckTimeout(updateIfChanged,100)
      setDoubleCheckBackupTimeout(updateIfChanged,1000)  // just in case the 100ms in the line above is not enough
    },
    [ JSON.stringify(windowSize) ],
  )

  const { width, height } = doubleCheckedWindowSize
  const miniHeaderSize = Math.min(width, height) < 600
  const studyBibleItemPopoverSize = width >= MINIMUM_STUDY_BIBLE_ITEM_POPOVER_WIDTH
  const mobileSize = width < MINIMUM_MEDIUM_WIDTH
  const mediumSize = !mobileSize && width < MINIMUM_MEDIUM_LARGE_WIDTH
  const mediumLargeSize = !mobileSize && !mediumSize && width < MINIMUM_LARGE_WIDTH
  const largeSize = !mobileSize && !mediumSize && !mediumLargeSize

  const appSizes = useMemo(
    () => ({
      width,
      height,
      miniHeaderSize,
      studyBibleItemPopoverSize,
      mobileSize,
      mediumSize,
      mediumLargeSize,
      largeSize,
    }),
    [ width, height, miniHeaderSize, studyBibleItemPopoverSize, mobileSize, mediumSize, mediumLargeSize, largeSize ],
  )

  const getAppSizes = useInstanceValue(appSizes)

  return {
    width,
    height,
    miniHeaderSize,
    studyBibleItemPopoverSize,
    mobileSize,
    mediumSize,
    mediumLargeSize,
    largeSize,
    getAppSizes,
  }
}

export default useAppSize
