import { useCallback, useState, useMemo, useRef } from 'react'

import useEffectAsync from './useEffectAsync'

import {
  DRAWER_MINIMIZED_HEIGHT,
  HEADER_HEIGHT,
} from '../utils/constants'
import useAppSize from './useAppSize'
import useInstanceValue from './useInstanceValue'

const useDrawerHeight = ({
  heightPercentage,
  goUpdate,
  embedOverflowHeight,
}) => {

  let { height, mobileSize } = useAppSize()
  height -= embedOverflowHeight || 0

  const calculatedDrawerHeight = (height - HEADER_HEIGHT) * Math.abs(heightPercentage/100)
  const [ drawerHeight, setDrawerHeight ] = useState(calculatedDrawerHeight)

  const getDrawerHeight = useInstanceValue(drawerHeight)
  const getGoUpdate = useInstanceValue(goUpdate)

  const offset = useRef(0)

  const getNewHeight = useCallback(
    clientY => (
      Math.min(
        height - HEADER_HEIGHT - 15,
        Math.max(
          200,
          height - clientY + offset.current,
        )
      )
    ),
    [ height ],
  )

  const startResize = useCallback(
    ({ clientY }) => {
      offset.current = Math.max(clientY - (height - getDrawerHeight()), 0)
      document.querySelectorAll('iframe').forEach(iframeEl => { iframeEl.style.pointerEvents = 'none' })
    },
    [ height, getDrawerHeight ],
  )

  const resize = useCallback(
    ({ clientY }) => {
      if(clientY > 0) {
        setDrawerHeight(getNewHeight(clientY))
      }
    },
    [ getNewHeight ],
  )

  const endResize = useCallback(
    () => {
      document.querySelectorAll('iframe').forEach(iframeEl => { iframeEl.style.pointerEvents = 'inherit' })
      getGoUpdate()((getDrawerHeight() / (height - HEADER_HEIGHT)) * 100)
    },
    [ height, getGoUpdate, getDrawerHeight ],
  )

  const onTouchStart = useCallback(
    ({ touches }) => {

      startResize(touches[0])

      const onTouchEnd = () => {
        document.body.removeEventListener('touchmove', onTouchMove)
        document.body.removeEventListener('touchend', onTouchEnd)
        document.body.removeEventListener('touchcancel', onTouchEnd)
        endResize()
      }

      const onTouchMove = ({ touches }) => {
        if(touches.length !== 1) {
          onTouchEnd()
        } else {
          resize(touches[0])
        }
      }

      document.body.addEventListener('touchmove', onTouchMove)
      document.body.addEventListener('touchend', onTouchEnd)
      document.body.addEventListener('touchcancel', onTouchEnd)

    },
    [ resize, endResize, startResize ],
  )

  const onMouseDown = useCallback(
    event => {

      startResize(event)
      event.preventDefault()

      const onMouseUp = () => {
        document.body.removeEventListener('mousemove', resize)
        document.body.removeEventListener('mouseup', onMouseUp)
        document.body.removeEventListener('mouseleave', onMouseUp)
        endResize()
      }

      document.body.addEventListener('mousemove', resize)
      document.body.addEventListener('mouseup', onMouseUp)
      document.body.addEventListener('mouseleave', onMouseUp)

    },
    [ resize, endResize, startResize ],
  )

  const resizeEvents = useMemo(
    () => ({
      onMouseDown,
      onTouchStart,
    }),
    [ onMouseDown, onTouchStart ],
  )

  useEffectAsync(
    () => {
      setDrawerHeight(calculatedDrawerHeight)
    },
    [ calculatedDrawerHeight ],
  )

  const minimized = heightPercentage < 0
  const effectiveDrawerHeight = minimized ? DRAWER_MINIMIZED_HEIGHT : drawerHeight

  return {
    drawerHeight,
    minimized,
    effectiveDrawerHeight,
    resizeEvents,
    mobileSize,
  }
}

export default useDrawerHeight
