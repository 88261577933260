import { ApolloLink, Observable } from '@apollo/client'
import { asyncMap } from "@apollo/client/utilities"

import getQuerySettings from './getQuerySettings'
import getMutationSettings from './getMutationSettings'
import { IS_EMBED } from '../../utils/constants'
import { cloneObj } from '../../utils/misc'

const outstandingExpectedResponses = []

const getAddContextLink = getClient => new ApolloLink((operation, forward) => {

  const { variables, query: { definitions } } = operation
  if(definitions.length !== 1) {
    throw new Error(`Unexpected group of queries. Redo this function. (query.definitions.length === ${definitions.length})`)
  }
  const operationName = definitions[0].name.value
  const operationType = definitions[0].operation

  const previousContext = operation.getContext()
  const { offlineVersions, offlineSetupStatus, isBackupServerQuery, forceSaveServerResultToDexie, presetQueuedMutationId } = previousContext

  let newContext = {
    ...previousContext,
    client: getClient(),
    operationName,
    operationType,
    headers: {  // needed for authentication
      ...previousContext.headers,
      'x-access-token': !IS_EMBED && window.sessionSyncAuth.getAccessToken(),  // do not include if embed to be sure we do not save something
    },
  }

  if(operationType === 'query') {

    newContext = {
      ...newContext,
      ...getQuerySettings({
        operationName,
        variables,
        offlineVersions,
        offlineSetupStatus,
        isBackupServerQuery,
        forceSaveServerResultToDexie,
      }),
    }
 
  } else {  // operationType === 'mutation'

    newContext = {
      ...newContext,
      ...getMutationSettings({
        operationName,
        offlineSetupStatus,
        presetQueuedMutationId,
      }),
    }
  }

  if(newContext.failDueToNoConnection) {
    return new Observable(observer => {
      observer.error(new Error('No connection'))
    })
  }

  if(newContext.expectedResponse) {
    newContext.outstandingExpectedResponsesWhenExecuted = cloneObj(outstandingExpectedResponses)
    outstandingExpectedResponses.unshift(newContext.expectedResponse[operationName])
  }

  operation.setContext(newContext)

  return asyncMap(forward(operation), async response => {

    if(newContext.expectedResponse) {
      outstandingExpectedResponses.splice(
        outstandingExpectedResponses.findIndex(expectedResponse => expectedResponse === newContext.expectedResponse[operationName]),
        1,
      )
    }

    return response
  })

})

export default getAddContextLink