import { useMemo } from "react"

import {
  MINIMUM_MODULE_COLUMN_WIDTH,
  HIDDEN_COLUMN_WIDTH,
  THUMBNAILS_WIDTH,
} from '../utils/constants'
import useAppSize from './useAppSize'
import useEqualObjsMemo from './useEqualObjsMemo'

const useProjectSizes = ({
  inDoubleStudyMode,
  notesInDrawer,
  notesWidthPercentage,
  firstColumnWidthPercentage,
  passageBookmarks,
  moduleByProjects,
}) => {

  const { width, mobileSize, mediumLargeSize, largeSize } = useAppSize()

  const numNonNotesModules = useEqualObjsMemo(moduleByProjects.filter(({ module }) => module.type !== `NOTES`).length)

  const showInDoubleStudyMode = inDoubleStudyMode && (mediumLargeSize || largeSize) && numNonNotesModules > 0
  const showNotesInDrawer = notesInDrawer || !largeSize

  let passagesWidth = 0
  if(!mobileSize) {

    let maxPassagesWidth = width - MINIMUM_MODULE_COLUMN_WIDTH - THUMBNAILS_WIDTH
    if(showInDoubleStudyMode) {
      maxPassagesWidth -= MINIMUM_MODULE_COLUMN_WIDTH
    }
    if(!showNotesInDrawer) {
      maxPassagesWidth -= HIDDEN_COLUMN_WIDTH
    }

    passagesWidth = Math.round(
      Math.max(
        HIDDEN_COLUMN_WIDTH,
        Math.min(
          maxPassagesWidth,
          passageBookmarks.reduce((total, bookmark) => total + (bookmark.width || 0), 0),
        ),
      )
    )

  }


  let notesWidth = 0
  if(!showNotesInDrawer) {

    let maxNotesWidth = width - MINIMUM_MODULE_COLUMN_WIDTH - THUMBNAILS_WIDTH - passagesWidth
    if(showInDoubleStudyMode) {
      maxNotesWidth -= MINIMUM_MODULE_COLUMN_WIDTH
    }

    // notesWidthPercentage is the percentage of the horizontal room without taking the passage column into account
    notesWidth = Math.round(
      Math.max(
        HIDDEN_COLUMN_WIDTH,
        Math.min(
          maxNotesWidth,
          (width - passagesWidth) * (notesWidthPercentage/100),
        ),
      )
    )
  }


  // firstColumnWidthPercentage is the percentage of the horizontal room without taking the passage column or notes column into account
  let module1Width = width - THUMBNAILS_WIDTH - notesWidth - passagesWidth
  let module2Width = 0
  if(showInDoubleStudyMode) {
    const maxModuleWidth = module1Width - MINIMUM_MODULE_COLUMN_WIDTH
    module1Width = Math.round(
      Math.max(
        MINIMUM_MODULE_COLUMN_WIDTH,
        Math.min(
          maxModuleWidth,
          module1Width * (firstColumnWidthPercentage/100),
        ),
      )
    )
    module2Width = Math.round(width - module1Width - THUMBNAILS_WIDTH - notesWidth - passagesWidth)
  }

  const mobileWidths = useMemo(
    () => ({
      mobileSize: true,
      showInDoubleStudyMode: false,
      showNotesInDrawer: true,
      module1Width: width,
      module2Width: 0,
      notesWidth: 0,
      passagesWidth: 0,
    }),
    [ width ],
  )

  const widths = useMemo(
    () => ({
      mobileSize,
      showInDoubleStudyMode,
      showNotesInDrawer,
      module1Width,
      module2Width,
      notesWidth,
      passagesWidth,
    }),
    [ mobileSize, showInDoubleStudyMode, showNotesInDrawer, module1Width, module2Width, notesWidth, passagesWidth ],
  )

  if(mobileSize) return mobileWidths

  return widths
}

export default useProjectSizes
