import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import StudyBibleItemEditorSourceSection from './StudyBibleItemEditorSourceSection'

const StartAndEnd = styled.div`
  display: flex;
`

const StudyBibleItemEditorSermonInfo = ({
  StyledTextField,
  commonTextFieldProps,
  StyledDivider,
  info,
  onChangeInfo,
  updatePreview,
}) => {

  const { mediaUrl, mediaStartTime, mediaEndTime, quote } = info || {}

  const onChangeMediaUrl = useCallback(onChangeInfo(`mediaUrl`), [ onChangeInfo ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeMediaStartTime = useCallback(onChangeInfo(`mediaStartTime`), [ onChangeInfo ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeMediaEndTime = useCallback(onChangeInfo(`mediaEndTime`), [ onChangeInfo ])  // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeQuote = useCallback(onChangeInfo(`quote`), [ onChangeInfo ])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>

      <StyledTextField
        {...commonTextFieldProps}
        label={i18n("Media URL")}
        placeholder={i18n("e.g. http://tapesfromscotland.org/Audio3/3262.mp3")}
        value={mediaUrl}
        onChange={onChangeMediaUrl}
        onBlur={updatePreview}
      />

      <StartAndEnd>
        <StyledTextField
          {...commonTextFieldProps}
          label={i18n("Start time")}
          placeholder={i18n("e.g. 12:25")}
          value={mediaStartTime}
          onChange={onChangeMediaStartTime}
        />
        <StyledTextField
          {...commonTextFieldProps}
          label={i18n("End time")}
          placeholder={i18n("e.g. 15:01")}
          value={mediaEndTime}
          onChange={onChangeMediaEndTime}
        />
      </StartAndEnd>

      <StyledTextField
        {...commonTextFieldProps}
        label={i18n("Quote")}
        placeholder={i18n("e.g. God is most glorified in us when we are most satisfied in him.")}
        multiline
        minRows={2}
        maxRows={5}
        value={quote}
        onChange={onChangeQuote}
        onBlur={updatePreview}
      />

      <StyledDivider />

      <StudyBibleItemEditorSourceSection
        StyledTextField={StyledTextField}
        commonTextFieldProps={commonTextFieldProps}
        info={info}
        onChangeInfo={onChangeInfo}
        updatePreview={updatePreview}
      />

    </>
  )
}

export default memo(StudyBibleItemEditorSermonInfo)