import { memo } from 'react'
import styled from 'styled-components'
import { getUsfmBibleBookAbbr } from '@bibletags/bibletags-ui-helper'
import LaunchIcon from '@material-ui/icons/Launch'
import { getLocFromRef, getRefFromLoc } from '@bibletags/bibletags-versification'

import useVersesPieces from '../../../hooks/useVersesPieces'

import TextContent from '../../common/TextContent'
import LinkIconButton from '../../common/LinkIconButton'
import PassageRef from '../../common/PassageRef'
import ContainerWithPassagePopper from '../../passage/ContainerWithPassagePopper'

const PassageRefHeading = styled.div`
  position: absolute;
  top: -9px;
  left: 18px;
  padding: 0 6px;
  align-self: flex-start;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  background: white;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const Version = styled.span`
  display: inline-block;
  font-weight: 300;
  margin-left: 4px;
  font-size: .75em;
`

const Passage = styled.div`
  margin: 20px 0 10px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 20px 24px;
  border-radius: 5px;
  position: relative;
  min-width: 250px;
`

const LaunchIconButton = styled(LinkIconButton)`
  padding: 8px;
  margin: -5px -6px -5px 0;

  .MuiSvgIcon-root {
    font-size: 11px;
  }
`

const MessagePagePassage = ({
  versionId,
  safeVersionAbbr,
  versionLocSet,
  maxVerses,
}) => {

  const { bookId, chapter, verse } = getRefFromLoc(versionLocSet[0])
  const searchParams = new URLSearchParams(``)
  searchParams.set(`range`, versionLocSet.join(`-`))
  const readLink = `/version/${versionId}/${getUsfmBibleBookAbbr(bookId)}/${chapter}?${searchParams.toString()}`

  const toRef = getRefFromLoc(versionLocSet[1] || versionLocSet[0])
  if(toRef.bookId !== bookId || toRef.chapter > chapter+1) {
    toRef.chapter = chapter
    toRef.verse = verse + maxVerses
  }
  const toLoc = getLocFromRef(toRef)

  let [ pieces ] = useVersesPieces({
    fromLoc: versionLocSet[0],
    toLoc,
    versionId,
  })
  pieces = pieces || []

  let vTagsHit = 0
  const sliceIdx = pieces.findIndex(({ tag }) => {
    if(tag === `v`) {
      vTagsHit++
      if(vTagsHit > maxVerses) {
        return true
      }
    }
    return false
  })
  if(sliceIdx !== -1) {
    pieces = pieces.slice(0, sliceIdx)
    pieces.push({ children: [{ text: ` ...` }] })
  }

  return (
    <Passage>

      <PassageRefHeading>

        <PassageRef
          fromLoc={versionLocSet[0]}
          toLoc={versionLocSet[1]}
        />

        {` `}

        <Version>
          {safeVersionAbbr}
        </Version>

        <LaunchIconButton
          to={readLink}
        >
          <LaunchIcon />
        </LaunchIconButton>

      </PassageRefHeading>

      <ContainerWithPassagePopper
        onTopOfAll
      >
        <TextContent
          pieces={pieces}
          versionId={versionId}
          bookId={bookId}
          startChapter={chapter}
        />
      </ContainerWithPassagePopper>

    </Passage>
  )
}


export default memo(MessagePagePassage)