import { memo } from 'react'
import styled from 'styled-components'

import HomeWhatsNew from "./HomeWhatsNew"
import HomeWhatIs from "./HomeWhatIs"
import HomeWhereToStart from "./HomeWhereToStart"
import HomeEndorsements from "./HomeEndorsements"

const FullScreenContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
`

const DashboardContainer = styled.div`
  transition: width .3s ease-in-out;
  width: 0;
  background-color: white;
  position: relative;
  display: flex;

  ${({ $homeSliderSectionOpen }) => !$homeSliderSectionOpen ? `` : `
    width: 420px;
  `}

  ${({ theme }) => {  // temp for easy reference
    if(!window.loggedTheme) {
      console.log(theme)
      window.loggedTheme = true
    }
  }}
`

const DashboardScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
  min-width: ${({ $fullScreen }) => $fullScreen ? `auto` : `420px`};
`

const DashboardBG = styled.div`
  background-image: url("/sunrise.jpg");
  background-size: cover;
  background-position-x: 64%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: -80vh;
  left: 0px;
  opacity: 0.5;
`

const HomeDashboard = ({
  homeSliderSectionOpen,
  fullScreen,
  toggleSliderSectionOpen,
}) => {

  const Container = fullScreen ? FullScreenContainer : DashboardContainer

  return (
    <Container
      $fullScreen={fullScreen}
      $homeSliderSectionOpen={homeSliderSectionOpen}
    >
      <DashboardBG />
      <DashboardScrollContainer $fullScreen={fullScreen}>
        <HomeWhatsNew />
        <HomeWhatIs
          fullScreen={fullScreen}
          toggleSliderSectionOpen={toggleSliderSectionOpen}
        />
        <HomeWhereToStart />
        <HomeEndorsements />
      </DashboardScrollContainer>
    </Container>
  )
}

export default memo(HomeDashboard)