import { useMemo } from 'react'
import styled from 'styled-components'
import { i18n, getLocale } from 'inline-i18n'

const Space = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.grey[500]};
  margin: 1em 0;
`

const H1 = styled.h1`
  margin: 1em 0 1.1em 0;
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 1.5em;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: bold;

  & + h2,
  & + h3 {
    margin-top: 0;
  }
`

const H2 = styled.h2`
  margin: .6em 0 .7em 0;
  color: ${({ theme }) => theme.palette.grey[700]};
  font-size: 1.3em;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
`

const H3 = styled.h3`
  margin: .4em 0 .5em 0;
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 1.2em;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: normal;
`

const H4 = styled.h4`
  margin: .3em 0 .4em 0;
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 1.15em;
  text-align: ${({ $isRTL }) => $isRTL ? `right` : `left`};
  font-weight: bold;
`

const H5 = styled.h5`
  margin: .3em 0 .4em 0;
  color: ${({ theme }) => theme.palette.grey[700]};
  font-size: 1.05em;
  text-align: ${({ $isRTL }) => $isRTL ? `right` : `left`};
  font-weight: 500;
`

const H6 = styled.h6`
  margin: .3em 0 .4em 0;
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: .9em;
  text-align: ${({ $isRTL }) => $isRTL ? `right` : `left`};
  font-weight: normal;
`

const useOutlineHeadings = () => {

  const locale = getLocale()

  const headings = useMemo(
    () => ([
      {
        type: `space`,
        label: i18n("extra space", "", "outline"),
        Component: Space,
        isCentered: true,
      },
      {
        type: `h1`,
        label: i18n("Book Division", "", "outline"),
        Component: H1,
        isCentered: true,
      },
      {
        type: `h2`,
        label: i18n("Section Heading", "", "outline"),
        Component: H2,
        isCentered: true,
      },
      {
        type: `h3`,
        label: i18n("Subsection Heading", "", "outline"),
        Component: H3,
        isCentered: true,
      },
      {
        type: `h4`,
        label: i18n("Major Heading", "", "outline"),
        Component: H4,
      },
      {
        type: `h5`,
        label: i18n("Minor Heading", "", "outline"),
        Component: H5,
      },
      {
        type: `h6`,
        label: i18n("Subheading", "", "outline"),
        Component: H6,
      },
    ]),
    [ locale ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  return headings

}

export default useOutlineHeadings