import styled from 'styled-components'

const SearchWord = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.grey[700]}88;
  border: 1px solid ${({ theme }) => theme.palette.grey[700]};
  border-radius: 3px;
  padding: 1px 6px;
  margin: -2px 0;
`

const SearchSuggestionSearchWord = ({ ...props }) => (
  <SearchWord
    className="SearchSuggestionSearchWord"
    {...props}
  />
)

export default SearchSuggestionSearchWord