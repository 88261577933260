const fields = `
  id
  title
  content
  importance
  userId
  createdAt
  deletedAt
  expiresAt
`

export default fields