import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Divider from '@material-ui/core/Divider'
import MenuBookIcon from '@material-ui/icons/MenuBook'

import SettingsListHeader from "./SettingsListHeader"
import SettingsVersionsRightPanel from "./SettingsVersionsRightPanel"
import MyVersionsList from "./MyVersionsList"

const ListContainer = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const StyledMenuBookIcon = styled(MenuBookIcon)`
  height: 26px;
  width: auto;
  margin: 0 10px 0 1px;
`

const SettingsVersions = ({
  expandLeftPanel,
  expandRightPanel,
  rightPanelExpanded,
  closeRightPanel,
}) => {

  return (
    <>

      <ListContainer>

        <SettingsListHeader
          icon={<StyledMenuBookIcon />}
          expandLeftPanel={expandLeftPanel}
          expandRightPanel={expandRightPanel}
        >
          {i18n("My Bible Versions")}
        </SettingsListHeader>

        <Divider />

        <MyVersionsList />

      </ListContainer>

      <SettingsVersionsRightPanel
        expanded={rightPanelExpanded}
        close={closeRightPanel}
      />

    </>
  )
}

export default memo(SettingsVersions)