import { MINIMUM_MODULE_COLUMN_WIDTH } from '../utils/constants'
import useEqualObjsMemo from './useEqualObjsMemo'

const useEffectiveVisibility = ({ modulePassages, moduleWidth }) => {

  const effectiveVisibilityById = {}
  let widthRemaining = Math.max(moduleWidth, MINIMUM_MODULE_COLUMN_WIDTH)

  modulePassages.forEach(({ id, info: { visible } }) => {
    effectiveVisibilityById[id] = false
    if(visible && widthRemaining >= MINIMUM_MODULE_COLUMN_WIDTH) {
      effectiveVisibilityById[id] = true
      widthRemaining -= MINIMUM_MODULE_COLUMN_WIDTH
    }
  })

  effectiveVisibilityById.isMaxedOut = widthRemaining < MINIMUM_MODULE_COLUMN_WIDTH

  const memoedEffectiveVisibilityById = useEqualObjsMemo(effectiveVisibilityById)

  return memoedEffectiveVisibilityById
}

export default useEffectiveVisibility