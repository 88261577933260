import { gql } from '@apollo/client'

import channelItem_fields from '../fragments/channelItem_fields'

export default gql
`
  mutation addReactionToChannelItem($channelItemId: ID!, $input: ReactionInput!) {
    addReactionToChannelItem(channelItemId: $channelItemId, input: $input) {
      ${channelItem_fields}
    }
  }
`