import { useCallback } from 'react'

import useSetTimeout from './useSetTimeout'
import useRefState from './useRefState'
import { preventDefaultEvent } from '../utils/misc'
import useEffectAsync from './useEffectAsync'

const useMouseOverOrTouch = ({
  initialTimeoutMs=3000,
  timeoutMs=3000,
  mouseOutTimeoutMs=500,
  forceShow,
  forceHide,
}={}) => {

  const [ setHideTimeout, clearHideTimeout ] = useSetTimeout()
  const [ show, setShow, getShow ] = useRefState(true)

  const mouseOrTouchEvent = useCallback(
    event => {
      const isTouchEvent = /touch/.test(event.type || ``)

      clearHideTimeout()

      if(!getShow()) {
        setShow(true)
        if(isTouchEvent) preventDefaultEvent(event)
      }

      if(timeoutMs != null) {
        setHideTimeout(() => {
          setShow(false)
        }, timeoutMs)
      }

    },
    [ getShow, setShow, setHideTimeout, timeoutMs, clearHideTimeout ],
  )

  const mouseLeaveEvent = useCallback(
    () => {
      if(mouseOutTimeoutMs) {
        setHideTimeout(() => {
          setShow(false)
        }, mouseOutTimeoutMs)
      } else {
        setShow(false)
        clearHideTimeout()
      }
    },
    [ setShow, clearHideTimeout, setHideTimeout, mouseOutTimeoutMs ],
  )

  useEffectAsync(
    () => {
      setHideTimeout(() => {
        setShow(false)
      }, initialTimeoutMs)
    },
    [],
  )

  return {
    $show: (show || !!forceShow) && !forceHide,
    onMouseMove: mouseOrTouchEvent,
    onTouchStart: mouseOrTouchEvent,
    onTouchMove: mouseOrTouchEvent,
    onMouseLeave: mouseLeaveEvent,
  }
}

export default useMouseOverOrTouch
