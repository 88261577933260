const fields = `
  id
  heading
  title
  type
  info
  thumbnailImageUrl
  status
  fromLoc
  toLoc
  editHistory
  adminRating
  userId
  details
  reactions
  tags
  createdAt
  updatedAt
  deletedAt
  numOpenComments
  user {
    id
    name
    image
  }
`

export default fields