import { useMemo } from "react"

import { cloneObj, getPrimaryDate, sortEventsByDate } from "../utils/misc"

const usePersonInfo = ({
  mapLayer,
 }) => {

  const toReturn = useMemo(
    () => {
      const { places=[], persons=[] } = mapLayer.data || {}
      const placesClone = cloneObj(places)

      const eventsByPersonId = {}
      const personDateRangeById = {}

      persons.forEach(({ id }) => {
        eventsByPersonId[id] = []
        placesClone.forEach(place => {
          place.events.forEach(event => {
            if(event.personIds.includes(id)) {
              eventsByPersonId[id].push({
                ...event,
                place,
              })
            }
          })
        })
        sortEventsByDate({ events: eventsByPersonId[id] })

        personDateRangeById[id] = `${getPrimaryDate(eventsByPersonId[id][0]).split(` - `)[0]} - ${getPrimaryDate(eventsByPersonId[id].at(-1)).split(` - `).at(-1)}`

      })

      return {
        eventsByPersonId,
        personDateRangeById,
      }
    },
    [ mapLayer ],
  )

  return toReturn
}

export default usePersonInfo