import { useState, useMemo, useCallback, useRef } from 'react'
// import styled from 'styled-components'
import { getLocFromRef } from '@bibletags/bibletags-versification'

import useInstanceValue from './useInstanceValue'
import useModalAnchor from './useModalAnchor'
import useSetTimeout from './useSetTimeout'
import { isSafari } from '../utils/misc'
// import { preventDefaultEvent } from '../utils/misc'

import OptionsPopper from '../components/common/OptionsPopper'
import PassagePopperPager from '../components/passage/PassagePopperPager'

const bugOffset = isSafari ? 20 : 0  // it seems that the fact the popper is wider than the width of the container minus its padding causes a shift in the text wrap

const usePassagePopper = ({
  width,
  getWidth,
  versionId,
  closePopperOnChangeText,
  topOffset,
  getTopOffset,
  setMaxHeight=true,
  ...otherProps
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const [ popperInfoStack, setPopperInfoStack ] = useState([])
  const getPopperInfoStack = useInstanceValue(popperInfoStack)

  const hasBeenOpen = useRef(false)
  hasBeenOpen.current = hasBeenOpen.current || !!anchorEl

  const [ setPopperInfoStackResetTimeout ] = useSetTimeout()

  const closePopper = useCallback(
    () => {
      closeModal()
      setPopperInfoStackResetTimeout(() => {
        setPopperInfoStack([])
      }, 300)
    },
    [ setPopperInfoStack, closeModal, setPopperInfoStackResetTimeout ],
  )

  // This serves to clear out anchorEl before that el is unloaded due to a search change
  useMemo(closePopper, [ closePopperOnChangeText ])  // eslint-disable-line react-hooks/exhaustive-deps

  const goSetPopperInfo = useCallback(
    (event, newPopperInfo) => {

      if(event && event.isPropsUpdate) {
        const newPopperInfoStack = [ ...getPopperInfoStack() ]
        const lastPopperInfo = newPopperInfoStack[newPopperInfoStack.length - 1] = { ...newPopperInfoStack.at(-1) }
        lastPopperInfo.props = {
          ...lastPopperInfo.props,
          ...newPopperInfo,  // this is really just updates to props in this case
        }
        setPopperInfoStack(newPopperInfoStack)
        return
      }

      const newPopperInfoStack = [
        ...getPopperInfoStack(),
        newPopperInfo,
      ]
      setPopperInfoStack(newPopperInfoStack)
      if(newPopperInfoStack.length === 1) openModal(event)

    },
    [ getPopperInfoStack, setPopperInfoStack, openModal ],
  )

  let { tabs, props: { id, ids, isVs, contextRef, dotId }={} } = popperInfoStack[0] || {}
  ids = useMemo(() => ids || (id && { [id]: null }) || [], [ ids, id ])
  const selectedVsNumId = isVs ? `${versionId}_${getLocFromRef(contextRef).split(':')[0]}` : null
  const selectedDotId = dotId || null

  topOffset = getTopOffset ? getTopOffset() : topOffset

  const passagePopperInfo = useMemo(
    () => ({
      open: !!anchorEl,
      anchorEl,
      onClose: closePopper,
      tabs,
      ...(topOffset ? { topOffset } : {}),
      setMaxHeight,
      ...otherProps,
      children: (
        <PassagePopperPager
          versionId={versionId}
          width={(getWidth ? getWidth() : width) - bugOffset}
          popperInfoStack={popperInfoStack}
          setPopperInfoStack={setPopperInfoStack}
          closePopper={closePopper}
        />
      ),
    }),
    [ anchorEl, closePopper, tabs, topOffset, setMaxHeight, versionId, getWidth, width, popperInfoStack, setPopperInfoStack, ...Object.values(otherProps) ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  const passagePopperComponents = (
    <>

      {hasBeenOpen.current &&
        <OptionsPopper
          {...passagePopperInfo}
        />
      }

    </>
  )

  return {
    ids,
    selectedVsNumId,
    selectedDotId,
    anchorEl,
    closePopper,
    goSetPopperInfo,
    passagePopperInfo,
    passagePopperComponents,
  }

}

export default usePassagePopper