import { memo, useMemo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import {
  IS_EMBED,
  KJV_VERSION,
} from '../../../utils/constants'
import { getBoundedValue, getOrigFromAndToLocs } from '../../../utils/misc'
import useGoUpdateModulePassage from '../../../hooks/useGoUpdateModulePassage'
import useGoUpdateModuleByProject from '../../../hooks/useGoUpdateModuleByProject'
import useVersionInfos from '../../../hooks/useVersionInfos'
import useAppSize from '../../../hooks/useAppSize'

import PassageChooser from '../../chooser/PassageChooser'

const Container1 = styled.div`
  flex: 1;
  overflow-y: auto;
`

const Container2 = styled.div`
  position: relative;
  display: flex;
  padding-bottom: 15px;

  ${({ $moduleWidth }) => $moduleWidth > 700 ? `` : `
    flex-direction: column;
    justify-content: flex-start;
  `}
`

const Container3 = styled.div`
  position: relative;
  margin-right: ${({ $moduleWidth }) => $moduleWidth > 700 ? `30px` : 0};
`

const Heading = styled.div`
  font-size: 20px;
  margin-top: 12px;
  line-height: 26px;
  font-weight: 300;
`

const Instructions = styled.div`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-weight: 300;
  margin-bottom: 15px;
`

const PassageChooserContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  width: 383px;
  max-width: ${({ $moduleWidth }) => $moduleWidth ? `${$moduleWidth - 20}px` : `calc(100vw - 20px)`};
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  left: min(calc(100% - 31px), 355px);
  padding: 8px;
  background: rgb(245 245 245/.8);

  &:hover {
    background: rgb(220 220 220/.8);
  }

  .MuiSvgIcon-root {
    height: 15px;
    width: 15px;
  }
`

const NewModuleHeadingAndPassageChooser = ({
  heading,
  module,
  moduleByProject,
  setUpPiecesAfterPassageChange,
  moduleWidth,
  newOrLearning,
  ...otherProps
}) => {

  const projectId = moduleByProject.id.split(':')[1]
  const [ goCreateModulePassage ] = useGoUpdateModulePassage({
    projectId,
    moduleId: module.id,
    undoRedoStack: `none`,
  })
  const [ x, goDeleteModuleByProject ] = useGoUpdateModuleByProject({ moduleByProject })  // eslint-disable-line no-unused-vars

  const { defaultVersionId, getVersionByIdSync } = useVersionInfos()
  const [ versionId, setVersionId ] = useState(defaultVersionId)
  const { height } = useAppSize()
  const maxHeight = getBoundedValue(height - 370, { min: 340, max: 500 })

  const initialPassageState = useMemo(() => ({ versionId }), [ versionId ])

  const updatePassage = useCallback(
    ({ versionId, refs, pastedInVerses }) => {

      if(versionId && refs) {

        const version = getVersionByIdSync(versionId) || KJV_VERSION

        const newModulePassage = goCreateModulePassage({
          ...getOrigFromAndToLocs({ version, refs }),
          ordering: 1,
          info: {
            versionId,
            visible: true,
            extraWidthFactor: 1,
            ...(!pastedInVerses ? {} : {
              pastedInVerses,
            }),
          },
        })

        setUpPiecesAfterPassageChange({
          newModulePassage,
          savedAt: newModulePassage.savedAt,
        })

      } else if(versionId) {

        setVersionId(versionId)

      }

    },
    [ goCreateModulePassage, getVersionByIdSync, setUpPiecesAfterPassageChange ],
  )

  return (

    <Container1>
      <Container2 $moduleWidth={moduleWidth}>

        <Container3 $moduleWidth={moduleWidth}>

          <Heading>
            {heading}
          </Heading>

          <Instructions>
            {i18n("Select an initial version and passage.")}
          </Instructions>

          <PassageChooserContainer $moduleWidth={moduleWidth}>
            <PassageChooser
              key={module.id}
              type="verses"
              updatePassage={updatePassage}
              initialPassageState={initialPassageState}
              showOther
              maxHeight={maxHeight}
              {...otherProps}
            />
          </PassageChooserContainer>

          <CloseButton
            onClick={goDeleteModuleByProject}
          >
            <CloseIcon />
          </CloseButton>

      </Container3>

      {!IS_EMBED && newOrLearning}

      </Container2>
    </Container1>

  )
}

export default memo(NewModuleHeadingAndPassageChooser)
