import { memo, useEffect, useState, useCallback, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { animated } from "@react-spring/web"
import { useMeasure } from 'react-use'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { useMutation } from '@apollo/client'

import { textToJsx, doWhitespaceTrim } from '../../utils/misc'
import useEffectAsync from '../../hooks/useEffectAsync'
import useRefState from '../../hooks/useRefState'
import useSimpleToggle from '../../hooks/useSimpleToggle'

import Avatar from '../common/Avatar'
import RelativeTime from '../common/RelativeTime'
import ConfirmDialog from '../common/ConfirmDialog'
import FadedLoading from '../common/FadedLoading'
import CustomCheckbox from '../common/CustomCheckbox'
import Reactions from './Reactions'

import updateStudyBibleItemCommentMutation from '../../graphql/mutations/updateStudyBibleItemComment'
import deleteStudyBibleItemCommentMutation from '../../graphql/mutations/deleteStudyBibleItemComment'

const Container = styled(animated.div)`
  width: calc(100% - 28px);
  margin: 0 15px 0 13px;
  display: flex;
  gap: 5px;

  ${({ $checkedOff }) => !$checkedOff ? `` : `
    opacity: .3 !important;
  `};

  ${({ $editing, theme }) => !$editing ? `` : `
    .StudyBibleItemComment-Content {
      background: none;
      outline: 1px solid ${theme.palette.primary.faded};
    }
  `}
`

const ContentAndReactions = styled.div`
  flex: 1;
  min-width: 0;
`

const Content = styled.div`
  background: ${({ theme, $isMyComment }) => $isMyComment ? theme.palette.primary.faded : theme.palette.grey[200]};
  font-size: 15px;
  line-height: 1.3;
  padding: 8px 10px;
  border-radius: 5px;

  p {
    margin: .5em 0;
    overflow-wrap: break-word;
  }

  p:first-of-type {
    margin-top: 0
  }

  p:last-child {
    margin-bottom: 0
  }

`

const ContentHeading = styled.div`
  display: flex;
`

const NameAndTime = styled.div`
  font-size: 11px;
  margin-bottom: 3px;
  flex: 1;
`

const Name = styled.span`
  font-weight: 600;
`

const Time = styled.span`
  font-weight: 300;
  display: inline-block;
  margin-left: 3px;
`

const EditedFlag = styled.span`
  display: inline-block;
  margin-left: 2px;
`

const NewFlag = styled.span`
  display: inline-block;
  margin-left: 3px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 7px;
  background: ${({ theme }) => theme.palette.tertiary.main};
  color: white;
  border-radius: 3px;
  padding: 2px 3px;
  line-height: 1;
  position: relative;
  top: -1px;
`

const Actions = styled.span`
  margin: -4px -4px -4px 3px;
  display: flex;
`

const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;

  .MuiSvgIcon-root {
    height: 13px;
    width: 13px;
  }
`

const StyledTextField = styled(TextField)`

  .MuiInputBase-root {
    padding: 0;
    font-size: inherit;
    line-height: inherit;
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  } 

`

const StyledCustomCheckbox = styled(CustomCheckbox)`
  margin: -4px -5px -10px;

  .MuiFormControlLabel-root {
    margin-right: 0;
  }

  .MuiSvgIcon-root {
    font-size: 14px;
  }

`

const ReactionsAndCheckmark = styled.div`
  display: flex;
  justify-content: space-between;
`

const StudyBibleItemComment = ({
  id,
  content,
  type,
  flagStatus,
  flagHistory,
  studyBibleItemId,
  studyBibleItemDetailId,
  customStudyBibleItemId,
  channelItemId,
  createdAt,
  updatedAt,
  deletedAt,
  isNew,
  reactions,
  user,
  isMyComment,
  style,
  updateHeight,
  refetch,
  refetchItem,
  reactionTypes,
  allowReactionsToSelf,
  enableCheckmark,
  ...otherProps
}) => {

  let [ containerRef, { height } ] = useMeasure()
  const [ showConfirmDelete, toggleShowConfirmDelete ] = useSimpleToggle()
  const [ editing, toggleEditing ] = useSimpleToggle()
  const [ editedContent, setEditedContent, getEditedContent ] = useRefState(``)
  const [ loading, setLoading ] = useState(false)

  const [ updateStudyBibleItemComment ] = useMutation(updateStudyBibleItemCommentMutation)
  const [ deleteStudyBibleItemComment ] = useMutation(deleteStudyBibleItemCommentMutation)

  const reactionToIdObj = useMemo(() => ({ studyBibleItemCommentId: id }), [ id ])

  const onContentChange = useCallback(({ target }) => setEditedContent(target.value), [ setEditedContent ])

  const cancelEditComment = useCallback(
    async () => {
      setEditedContent(content)
      toggleEditing()
    },
    [ setEditedContent, content, toggleEditing ],
  )

  const updateComment = useCallback(
    async () => {

      setLoading(true)

      await updateStudyBibleItemComment({
        variables: {
          id,
          input: {
            content: doWhitespaceTrim(getEditedContent()),
          },
        },
      })

      await refetch()

      toggleEditing()
      setLoading(false)

    },
    [ updateStudyBibleItemComment, id, getEditedContent, refetch, toggleEditing ],
  )

  const deleteComment = useCallback(
    async () => {

      setLoading(true)

      await deleteStudyBibleItemComment({
        variables: {
          id,
        },
      })

      await Promise.all([ refetch(), refetchItem() ])

      toggleShowConfirmDelete()
      setLoading(false)

    },
    [ deleteStudyBibleItemComment, id, refetch, refetchItem, toggleShowConfirmDelete ],
  )

  const onToggleDone = useCallback(
    async () => {

      setLoading(true)

      await updateStudyBibleItemComment({
        variables: {
          id,
          input: {
            flagStatus: flagStatus === `DONE` ? `NONE` : `DONE`,
          },
        },
      })

      await Promise.all([ refetch(), refetchItem() ])

      setLoading(false)

    },
    [ updateStudyBibleItemComment, id, flagStatus, refetch, refetchItem ],
  )

  useEffectAsync(
    () => {
      setEditedContent(content)
    },
    [ content ],
  )

  useEffect(
    () => {
      if(updateHeight && height) {
        updateHeight({ id, height })
        return () => {
          updateHeight({ id, height: 0 })
        }
      }
    },
    [ updateHeight, id, height ],
  )

  const { y, height: ignore, ...otherStyle } = style || {}

  return (
    <Container
      {...otherProps}
      $editing={editing}
      $checkedOff={flagStatus === `DONE`}
      ref={containerRef}
      style={
        !style
          ? null
          : {
            transform: y.to((y) => `translate3d(0,${y}px,0)`),
            ...otherStyle,
          }
      }
    >

      <Avatar
        user={user}
        size="small"
      />

      <ContentAndReactions>

        <Content
          className="StudyBibleItemComment-Content"
          $isMyComment={isMyComment}
        >

          <ContentHeading>

            <NameAndTime>

              <Name>
                {user.name || ``}
              </Name>

              {!!createdAt &&
                <>
                  {` `}
                  <Time>
                    <RelativeTime date={createdAt} />
                  </Time>
                </>
              }

              {updatedAt > createdAt + 500 &&  // 500 here since sometimes the updatedAt time is a few ms after createdAt when it is created
                <>
                  {` `}
                  <EditedFlag>
                    {i18n("(edited)")}
                  </EditedFlag>
                </>
              }

              {isNew &&
                <>
                  {` `}
                  <NewFlag>
                    {i18n("New")}
                  </NewFlag>
                </>
              }

            </NameAndTime>

            {!!isMyComment &&
              <Actions>

                {!editing &&
                  <>

                    <StyledIconButton
                      onClick={toggleEditing}
                    >
                      <EditIcon />
                    </StyledIconButton>

                    <StyledIconButton
                      onClick={toggleShowConfirmDelete}
                    >
                      <DeleteIcon />
                    </StyledIconButton>

                  </>
                }

                {editing &&
                  <>

                    <StyledIconButton
                      onClick={cancelEditComment}
                    >
                      <CloseIcon />
                    </StyledIconButton>

                    <StyledIconButton
                      onClick={updateComment}
                      disabled={doWhitespaceTrim(editedContent) === content}
                    >
                      <CheckIcon />
                    </StyledIconButton>

                  </>
                }

              </Actions>
            }

          </ContentHeading>

          {editing &&
            <StyledTextField
              variant="outlined"
              size="small"
              value={editedContent}
              onChange={onContentChange}
              multiline
              minRows={1}
              fullWidth
              disabled={loading}
              autoFocus
            />
          }

          {(!editing && typeof content === `string`) && textToJsx(content)}
          {(!editing && typeof content !== `string`) && content}

        </Content>

        {!editing && !!reactions &&
          <ReactionsAndCheckmark>
            <Reactions
              reactions={reactions}
              reactionToIdObj={reactionToIdObj}
              types={reactionTypes}
              allowNewReactions={!!allowReactionsToSelf || !isMyComment}
              refetch={refetch}
            />
            {!!enableCheckmark &&
              <Tooltip
                title={
                  flagStatus !== `DONE`
                    ? i18n("Check when done")
                    : ``
                }
              >
                <span>
                  <StyledCustomCheckbox
                    checked={flagStatus === `DONE`}
                    onChange={onToggleDone}
                  />
                </span>
              </Tooltip>
            }
          </ReactionsAndCheckmark>
        }

      </ContentAndReactions>

      <ConfirmDialog
        open={showConfirmDelete}
        onClose={toggleShowConfirmDelete}
        onConfirm={deleteComment}
        title={i18n("Delete Forever")}
        explanation={i18n("Are you sure you want to delete this comment?")}
        confirmButtonLabel={i18n("Delete")}
        loading={loading}
      />

      {loading && <FadedLoading size={20} />}

    </Container>
  )
}

export default memo(StudyBibleItemComment)