import React, { memo, useCallback } from 'react'

import PassagePopperLazyLoadPageViewer from './PassagePopperLazyLoadPageViewer'
import PassagePopperPagerHeader from './PassagePopperPagerHeader'

const PassagePopperPager = ({
  versionId,
  width,
  popperInfoStack,
  setPopperInfoStack,
  closePopper,
  closeAndClearSearch,
}) => {

  const updateProps = useCallback(
    update => {

      const newProps = { ...popperInfoStack.at(-1).props }
      for(let key in update) {
        newProps[key] = update[key]
      }

      const newPopperInfoStack = [
        ...popperInfoStack.slice(0, -1),
        {
          ...popperInfoStack.at(-1),
          props: newProps,
        },
      ]

      setPopperInfoStack(newPopperInfoStack)

    },
    [ popperInfoStack, setPopperInfoStack ],
  )

  const getPageByKey = useCallback(
    index => {
      const { Component, props } = popperInfoStack[index] || {}

      if(!Component) return <div />

      return (
        <>

          {index > 0 &&
            <PassagePopperPagerHeader
              popperInfoStack={popperInfoStack}
              setPopperInfoStack={setPopperInfoStack}
              closePopper={closePopper}
              {...props}
            />
          }

          <Component
            versionId={versionId}
            updateProps={updateProps}
            hasHeader={index > 0}
            closePopper={closePopper}
            {...props}
          />

        </>
      )
    },
    [ versionId, popperInfoStack, setPopperInfoStack, updateProps, closePopper ],
  )

  if(popperInfoStack.length === 0) return null

  const pageKeyIndex = popperInfoStack.length - 1

  return (
    <PassagePopperLazyLoadPageViewer
      pageKey={pageKeyIndex}
      getPageByKey={getPageByKey}
      previousPageKey={pageKeyIndex > 0 ? pageKeyIndex - 1 : null}
      nextPageKey={pageKeyIndex + 1}
      width={width}
    />
  )
}

export default memo(PassagePopperPager)