import { memo } from 'react'
import styled from 'styled-components'

import Loading from './Loading'

const FadedLoading = styled(Loading)`
  background-color: rgb(255 255 255/.75);
  z-index: 9;

  ${({ $fullScreen }) => !$fullScreen ? `` : `
    position: fixed;
  `}
`

export default memo(FadedLoading)
