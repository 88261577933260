import useDataQuery from './useDataQuery'

import { TODO_defaultLanguageId } from '../utils/constants'
import { getHebrewPrefixDefinition } from '../utils/hebrewPrefixAndSuffixInfo'

import definitionQuery from '../graphql/queries/definition'

export const getNakedStrongs = strong => {

  let [ nakedStrongs ] = (strong || ``).match(/[HG][0-9]{5}/) || []

  if(!nakedStrongs) {
    nakedStrongs = (getHebrewPrefixDefinition(((strong || ``).match(/[blkm]/) || [])[0]) || {}).nakedStrongs
  }

  return nakedStrongs

}

const useDefinition = strong => {

  let [ nakedStrongs ] = (strong || ``).match(/[HG][0-9]{5}/) || []
  const languageId = TODO_defaultLanguageId

  let { definition } = useDataQuery({
    definitionQuery,
    variables: {
      id: `${nakedStrongs}-${languageId}`,
    },
    skip: !nakedStrongs,
  })

  if(!nakedStrongs) {
    definition = getHebrewPrefixDefinition(((strong || ``).match(/[blkm]/) || [])[0])
    nakedStrongs = (definition || {}).nakedStrongs
  }

  return {
    nakedStrongs,
    definition,
  }

}

export default useDefinition