const keyDownA = async params => {

  const {
    event,
    modifierKey,
  } = params

  if(!modifierKey) return
  if([ `input`, `textarea` ].includes(((document.activeElement || {}).tagName || ``).toLowerCase())) return

  const searchInputEl = document.querySelector(`.SearchTextField-input`)

  if(searchInputEl) {
    searchInputEl.focus()
    searchInputEl.select()
    event.preventDefault()
  }

}

export default keyDownA