import { useCallback } from 'react'

import useShortcuts from './useShortcuts'
import keyDownEscape from '../components/modules/outline/shortcuts/keyDownEscape'
import keyDownZ from '../components/modules/outline/shortcuts/keyDownZ'
import useInstanceValue from './useInstanceValue'

const keyDownFuncs = {
  keyDownEscape,
  keyDownZ,
}

const useUndoRedoShortcuts = params => {

  const {
    showButtons,
  } = params

  const getShowButtons = useInstanceValue(showButtons)

  const inCorrectContext = useCallback(
    event => {

      // check that floating undo/redo buttons are being shown
      if(!getShowButtons()) return false

      return true

    },
    [ getShowButtons ],
  )

  useShortcuts({
    keyDownFuncs,
    inCorrectContext,
    ...params,
  })

}

export default useUndoRedoShortcuts