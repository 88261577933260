const fields = `
  id
  type
  label
  inEditingMode
  info
  shared
  public
  createdAt
  savedAt
  modifiedAt
  openedOrModifiedAt
  deletedAt
  userId
`

// Not included here, but available:
  // modulePassages
  // moduleByProjects

export default fields

