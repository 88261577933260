import styled from "styled-components"

import PassagePopperHeading from '../components/passage/PassagePopperHeading'

export const isHitStyling = ({ $isHit }) => (
  !$isHit
    ? ``
    : `
      color: #d41b0c;
    `
)

const mt = styled.div`
  margin-top: .6em;
  margin-bottom: .6em;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};

  & + .TextContent-tag-mt1,
  & + .TextContent-tag-mt2,
  & + .TextContent-tag-mt3 {
    margin-top: -1em;
  }

`

const mt2 = styled(mt)`
  font-size: 1.2em;
  font-weight: normal;
  font-style: italic;
`

const mt3 = styled(mt)`
  font-size: .85em;
  font-weight: normal;
`

const styledBibleTextComponentsByTag = {
  container: styled.div`
    flex: 1;
  `,
  viewContainer: styled.div`
    flex: 1;
    overflow: hidden;
  `,
  content: styled.div`
    padding: 20px;
  `,
  parallelContent: styled.div`
    padding-top: 20px;
  `,
  mt,  // major title
  mt2,  // major title - 2
  mt3,
  mt4: mt3,
  mt5: mt3,
  mt6: mt3,
  mt7: mt3,
  mt8: mt3,
  mt9: mt3,
  mte: styled.div`  // major title at ending
    margin-top: .6em;
    margin-bottom: .6em;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
    font-family: ${({ theme }) => theme.typography.fontFamily};
  `,
  ms: styled.div`  // major section
    margin-top: .4em;
    margin-bottom: .3em;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.2em;
    text-align: center;
    font-family: ${({ theme }) => theme.typography.fontFamily};
  `,
  mr: styled.div`  // major section reference range
    margin-top: -.4em;
    margin-bottom: .4em;
    color: ${({ theme }) => theme.palette.grey[500]};
    font-size: .85em;
    text-align: center;
    font-family: ${({ theme }) => theme.typography.fontFamily};
  `,
  s: styled.div`  // section level 1
    margin-top: .6em;
    margin-bottom: .6em;
    color: ${({ theme }) => theme.palette.grey[500]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
  `,
  s2: styled.div`  // section level 2
    margin-top: .6em;
    margin-bottom: .3em;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: .85em;
    font-family: ${({ theme }) => theme.typography.fontFamily};
  `,
  s3: styled.div`  // section level 3
    margin-top: .3em;
    margin-bottom: .3em;
    color: ${({ theme }) => theme.palette.warning.main};
    font-size: .75em;
  `,
  sr: styled.div`  // section reference range
    margin-top: -.4em;
    margin-bottom: .3em;
    color: ${({ theme }) => theme.palette.grey[500]};
    font-size: .75em;
  `,
  r: styled.div`  // section reference range
    margin-top: -.4em;
    margin-bottom: .3em;
    color: ${({ theme }) => theme.palette.grey[500]};
    font-size: .75em;
  `,
  rq: styled.span`  // section reference range
    color: ${({ theme }) => theme.palette.grey[500]};
    font-size: .75em;
  `,
  d: styled.div`
    margin-top: .3em;
    margin-bottom: .7em;
    font-style: italic;
  `,
  sp: styled.div`  // speaker identification
    margin: .6em 1.2em .3em 1.2em;
    color: ${({ theme }) => theme.palette.grey[500]};
    font-size: .85em;
  `,
  sd: styled.div`
    height: 1.2em,
  `,
  sd2: styled.div`
    height: 1.8em,
  `,
  sd3: styled.div`
    height: 2.4em,
  `,
  p: styled.div`
    margin-top: .6em;
    margin-bottom: .6em;
  `,
  m: styled.div`
    margin-top: .6em;
    margin-bottom: .6em;
  `,
  q: styled.div`
    margin-left: 20px;
  `,
  q2: styled.div`
    margin-left: 40px;
  `,
  q3: styled.div`
    margin-left: 60px;
  `,
  q4: styled.div`
    margin-left: 80px;
  `,
  q5: styled.div`
    margin-left: 100px;
  `,
  q6: styled.div`
    margin-left: 120px;
  `,
  q7: styled.div`
    margin-left: 140px;
  `,
  b: styled.div`
    height: .6em;
  `,
  qd: styled.div`
    margin-top: 1.2em;
    font-style: italic;
  `,
  qa: styled.div`  // hebrew letters for acrostics
    color: ${({ theme }) => theme.palette.grey[500]};
    margin: 1.2em 1.2em .3em;
    text-transform: uppercase;
    font-size: .75em;
  `,

  peh: styled.span`
    color: ${({ theme }) => theme.palette.grey[500]};
  `,
  samech: styled.span`
    color: ${({ theme }) => theme.palette.grey[500]};
    margin-left: 1em;
  `,
  selah: styled.span`
    color: ${({ theme }) => theme.palette.grey[500]};
  `,

  nd: styled.span`  // name of diety
    font-variant: small-caps;
  `,
  sc: styled.span`  // small caps
    font-variant: small-caps;
  `,
  jmp: styled.a`  // link
    color: ${({ theme }) => theme.palette.secondary.light};
    text-decoration: underline;
    cursor: pointer;
  `,
  no: styled.span`  // normal text
    font-variant: normal;
    font-style: normal;
    font-weight: normal;
  `,

  f: styled.span`  // footnote
    color: ${({ theme }) => theme.palette.primary.main};
    letter-spacing: 1px;
    display: inline-block;
    white-space: pre;
    transition: color .15s ease-in-out;

    @media (hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.palette.primary.dark};
        cursor: pointer;
      }
    }
  `,

  fe: styled.span`  // endnote (treated the same as footnote)
    color: ${({ theme }) => theme.palette.primary.main};
    letter-spacing: 1px;
    display: inline-block;
    white-space: pre;
  `,

  fk: styled.span`
    color: ${({ theme }) => theme.palette.grey[500]};
    font-variant: small-caps;
  `,

  fp: styled.div`
    margin-top: .3em;
    margin-bottom: .3em;
  `,

  zFootnoteType: PassagePopperHeading,

  x: styled.span`  // crossref
    letter-spacing: 1px;
    display: inline-block;
    white-space: pre;
    color: ${({ theme }) => theme.palette.grey[400]};
    transition: color .15s ease-in-out;

    @media (hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.palette.grey[900]};
        cursor: pointer;
      }
    }
  `,

  xt: styled.span`  // 
    color: ${({ theme }) => theme.palette.primary.main};
    transition: color .15s ease-in-out;

    @media (hover: hover) {
      &:not(.selected):hover {
        color: ${({ theme }) => theme.palette.primary.light};
        cursor: pointer;
      }
    }

    &.selected {
      color: ${({ theme }) => theme.palette.primary.veryLight};
    }
  `,

  xo: styled.span`
    display: none;  // this is unnecessary since the user can see where the dot is
  `,

  zApparatusJson: styled.span`  // apparatus
    color: ${({ theme }) => theme.palette.primary.faded};
    letter-spacing: 1px;
    display: inline-block;
    white-space: pre;
    transition: color .15s ease-in-out;

    &.TextContent-apparatus-with-critical-alt {
      color: ${({ theme }) => theme.palette.primary.main};
    }

    .TextContent-tag-samech + & {
      margin-right: -1em;
      margin-left: 1em;
    }

    @media (hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.palette.primary.dark};
        cursor: pointer;
      }
    }
  `,

  w: styled.span`  // word
    transition: opacity .15s ease-in-out;
    ${isHitStyling}

    ${({ $disabledWordAndVsNumClick, $isOriginalOrLxx }) => ($disabledWordAndVsNumClick || !$isOriginalOrLxx) ? `` : `
      @media (hover: hover) {
        &:hover {
          opacity: .4;
          cursor: pointer;
        }
      }
    `}
  `,

  qc: styled.span` // centered poetic line
    text-align: center;
  `,

  v: styled.span`  // verse number
    font-weight: bold;
    font-style: normal;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    transition: opacity .15s ease-in-out;

    ${({ $disabledWordAndVsNumClick, $disabledVsNumClick }) => ($disabledWordAndVsNumClick || $disabledVsNumClick) ? `` : `
      @media (hover: hover) {
        &:hover {
          opacity: .4;
          cursor: pointer;
        }
      }
    `}
  `,
  vp: styled.span`
    font-weight: bold;
    font-style: normal;
    transition: opacity .15s ease-in-out;

    ${({ $disabledWordAndVsNumClick, $disabledVsNumClick }) => ($disabledWordAndVsNumClick || $disabledVsNumClick) ? `` : `
      @media (hover: hover) {
        &:hover {
          opacity: .4;
          cursor: pointer;
        }
      }
    `}
  `,

  bd: styled.span`  // bold
    font-weight: bold;
  `,
  bdit: styled.span`  // bold, italics
    font-weight: bold;
    font-style: italic;
  `,
  em: styled.span`  // italics
    font-style: italic;
  `,
  it: styled.span`  // italics
    font-style: italic;
  `,

  fq: styled.span`
    font-style: italic;
  `,

  fr: styled.span`
    display: none;  // this is unnecessary since the user can see where the dot is
  `,

}

export default styledBibleTextComponentsByTag