import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
// import styled from 'styled-components'

import StudyBibleItemEditorSourceSection from './StudyBibleItemEditorSourceSection'

// const StyledButton = styled(Button)`
//   margin: 0 15px;
//   width: calc(100% - 30px);
// `

const StudyBibleItemEditorImageInfo = ({
  StyledTextField,
  commonTextFieldProps,
  StyledDivider,
  info,
  onChangeInfo,
  updatePreview,
}) => {

  // const { uneditedImageUrl, imageUrl, sourceUrl, sourceText, sourceThumbnailImgUrl } = info || {}
  const { uneditedImageUrl, imageUrl } = info || {}

  const onChangeUneditedImageUrl = useCallback(onChangeInfo(`uneditedImageUrl`), [ onChangeInfo ])  // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeImage = useCallback(
    type => event => {
      onChangeInfo(`${type}Url`)(event)

      const { target: { value: url } } = event
      const img = new Image()
      img.onload = () => {
        const { naturalWidth, naturalHeight } = img
        const value = {
          width: naturalWidth / 2,
          height: naturalHeight / 2,
        }
        onChangeInfo(`${type}Dimensions`)({ target: { value } })
      }
      img.src = url
    },
    [ onChangeInfo ],
  )

  const onChangeImageUrl = useCallback(onChangeImage(`image`), [ onChangeImage ])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>

      <StyledTextField
        {...commonTextFieldProps}
        label={i18n("Image")}
        value={imageUrl}
        uneditedValue={uneditedImageUrl}
        onChange={onChangeImageUrl}
        onChangeUnedited={onChangeUneditedImageUrl}
        isImageUrl
        includeUploadButton
        minWidth={450}
        minHeight={150}
        maxHeight={1200}
      />

      <StyledDivider />

      <StudyBibleItemEditorSourceSection
        StyledTextField={StyledTextField}
        commonTextFieldProps={commonTextFieldProps}
        info={info}
        onChangeInfo={onChangeInfo}
        updatePreview={updatePreview}
      />

    </>
  )
}

export default memo(StudyBibleItemEditorImageInfo)