import { useEffect } from 'react'

import { capitalize, usingModifierKey } from '../utils/misc'
import useInstanceValue from './useInstanceValue'

const useShortcuts = ({
  keyDownFuncs,
  inCorrectContext,
  ...otherParams
 }) => {

  const getBaseParams = useInstanceValue(otherParams)

  useEffect(
    () => {

      const keyDown = async event => {

        if(inCorrectContext && !inCorrectContext(event)) return

        const keySwaps = {
          " ": "Space",
          "/": "Slash",
        }

        const func = keyDownFuncs[`keyDown${capitalize(keySwaps[event.key] || event.key)}`]
        const { shiftKey, key } = event
        const modifierKey = usingModifierKey(event)

        if(func) {
          await func({
            ...getBaseParams(),
            event,
            modifierKey,
            shiftKey,
            key,
          })
        }
      }

      window.addEventListener('keydown', keyDown)
      return () => window.removeEventListener('keydown', keyDown)
    },
    [ getBaseParams, keyDownFuncs, inCorrectContext ],
  )

}

export default useShortcuts