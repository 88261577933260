import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'

import Loading from "../../common/Loading"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  user-select: none;
`

const Message = styled.div`
  margin-bottom: 10px;
  font-weight: 200;
`

const StudyBibleItemsListMore = ({
  showingCount,
  totalCount,
  goFetchMore,
  loading,
}) => {

  if(totalCount === 0) return null

  return (
    <Container>

      <Message>
        {i18n("Showing {{number}} of {{total}}", {
          number: showingCount,
          total: totalCount,
        })}
      </Message>

      {showingCount < totalCount &&
        <Button
          variant="contained"
          disableElevation
          onClick={goFetchMore}
        >
          {i18n("Load more")}
          {loading && <Loading size={20} bgOpacity={.75} />}
        </Button>
      }

    </Container>
  )
}

export default memo(StudyBibleItemsListMore)