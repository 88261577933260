import { memo } from 'react'
import styled from 'styled-components'

const GetNewApp = styled.div`
  background: black;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
  font-weight: 200;
  font-size: 16px;

  a {
    color: #FFDD88;
  }
`

const NewApp = styled.div`
  font-weight: 300;
  padding-top: 20px;
`

const OldAppMessage = () => {
  return (
    <GetNewApp>
      This app is no longer maintained.
      <NewApp>
        A new app is soon to be available directly from <a target="_blank" rel="noreferrer" href="https://app.biblearc.com">Biblearc.com</a>.
      </NewApp>
    </GetNewApp>
  )
}

export default memo(OldAppMessage)