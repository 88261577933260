import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import RelativeTime from '../../common/RelativeTime'

const Container = styled.div`
  width: 200px;

  @media (max-width: 1260px) {
    width: 120px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const DateLabel = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 12px;
`

const DateValue = styled.div`
  font-size: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ProjectsListDate = ({
  sort,
  project,
}) => {

  const dateType = /At$/.test(sort) ? sort : `createdAt`

  const dateLabel = {
    createdAt: i18n("Created"),
    deletedAt: i18n("Deleted"),
    modifiedAt: i18n("Modified"),
    openedOrModifiedAt: i18n("Opened or modified"),
  }[dateType]

  return (
    <Container>

      <DateLabel>
        {dateLabel}
      </DateLabel>

      <DateValue>
        <RelativeTime date={project[dateType]} />
      </DateValue>

    </Container>
  )
}

export default memo(ProjectsListDate)