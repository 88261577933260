import { memo } from 'react'
// import styled from 'styled-components'

import OutlineRowContent from './OutlineRowContent'

import useVersesPieces from '../../../hooks/useVersesPieces'

const OutlineRow = ({
  fromLoc,
  toLoc,
  versionId,
  pastedInVerses,
  ...otherProps
}) => {

  const [ pieces, loading ] = useVersesPieces({
    fromLoc,
    toLoc,
    versionId,
    pastedInVerses,
  })

  return (
    <OutlineRowContent
      fromLoc={fromLoc}
      toLoc={toLoc}
      versionId={versionId}
      pieces={pieces}
      loading={loading}
      {...otherProps}
    />
  )

}

export default memo(OutlineRow)