import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'

import Header from "../../common/Header"
import LinkButton from '../../common/LinkButton'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 30px 20px 80px;
  background-color: white;
  position: relative;
`

const Content = styled.div`
  width: 900px;
  max-width: calc(100vw - 40px);
  margin: 0 auto;
  font-size: 16px;
`

const DevWorldImgContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`

const DevWorldImg = styled.img`
  width: auto;
  height: 200px;
  vertical-align: top;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Mission = styled.div`
  text-align: center;
  font-size: 20px;
  margin: 30px 0 50px;
  font-weight: 500;
`

const Accomplish = styled.div`
  text-align: center;
  font-weight: 300;
  margin: 0 0 10px;
  font-size: 18px;
`

const Ways = styled.div`
  margin: 0 auto 50px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 10px 25px;
`

const Number = styled.span`
  display: inline-block;
  font-size: 18px;
  margin-right: 5px;
  font-weight: bold;
`

const Way = styled.p`
`

const Paragraph = styled.p`
  margin: 0 0 15px;
`

const KeySentence = styled.span`
  font-weight: 500;
`

const AskSentence = styled.span`
  font-style: italic;
`

const LogoImg = styled.img`
  height: 32px;
  vertical-align: middle;
  margin: -12px -6px -8px -8px;
  transition: transform .15s ease-in-out;

  &:hover {
    transform: scale(1.03);
  }
`

const ByCheck = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]};
  border-radius: 5px;
  width: 400px;
  max-width: 100%;
  padding: 26px;
  margin: 20px auto;
  font-weight: 300;
`

const Address = styled.div`
  margin-top: 15px;
  font-weight: 500;
`

const ButtonContainer = styled.div`
  text-align: center;
  margin: 30px 0;
`

const StyledLinkButton = styled(LinkButton)`
  width: 240px;
  font-size: 18px;
`

const Donate = ({ ...props }) => {

  return (
    <>
      <Header
        {...props} 
        showBiblearcLogo
        title={i18n("Give a Donation")}
      />

      <Container>
        <Content>

          <DevWorldImgContainer>
            <DevWorldImg src="/arabic_bible.png" className="dark-mode-exempt" />
            <DevWorldImg src="/asian_man.png" className="dark-mode-exempt" />
            <DevWorldImg src="/woman_with_bible.png" className="dark-mode-exempt" />
          </DevWorldImgContainer>

          <ContentContainer>

            <Mission>
              {i18n("Our mission is to strengthen the worldwide Church through empowering deeper study of Scripture.")}
            </Mission>

            <Accomplish>
              {i18n("We accomplish this mission in three ways.")}
            </Accomplish>

            <Ways>
              <Way>
                <Number>
                  {i18n("1.")}
                </Number>
                {i18nReact("{{the_biblearc_study_bible}} gives users access to Bible reading, searching, maps, timeline, and study notes.", {
                  the_biblearc_study_bible: (
                    <NavLinkOrAWithDisable to="/about-the-biblearc-study-bible" exact>
                      <LogoImg src="/the_biblearc_study_bible_1.svg" />
                    </NavLinkOrAWithDisable>
                  ),
                })}
              </Way>
              <Way>
                <Number>
                  {i18n("2.")}
                </Number>
                {i18nReact("{{biblearc_tools}} enables users to create “Visual Meditations” using graphical Bible study methods.", {
                  biblearc_tools: (
                    <NavLinkOrAWithDisable to="/about-tools" exact>
                      <LogoImg src="/biblearc_tools_1.svg" />
                    </NavLinkOrAWithDisable>
                  ),
                })}
              </Way>
              <Way>
                <Number>
                  {i18n("3.")}
                </Number>
                {i18nReact("{{biblearc_equip}} offers live and self-paced Bible study courses with personal coaching.", {
                  biblearc_equip: (
                    <NavLinkOrAWithDisable to="https://equip.biblearc.com">
                      <LogoImg src="/biblearc_equip_1.svg" />
                    </NavLinkOrAWithDisable>
                  ),
                })}
              </Way>
            </Ways>

            <Paragraph>
              {i18n("In our efforts to serve the world, we provide The Biblearc Study Bible and Biblearc TOOLS to those in the developing countries under a whatever-you-can-afford policy, and give scholarships to Biblearc EQUIP whenever possible.")}
              {` `}
              {i18n("As a result, we have had the privelege to strengthen many missionaries and nationals as they serve the Lord in hard places.")}
            </Paragraph>

            <Paragraph>
              <KeySentence>
                {i18n("Yet we want to do more.")}
              </KeySentence>
            </Paragraph>

            <Paragraph>
              {i18n("For that reason, we invite you consider partering with our efforts through a donation.")}
              {` `}
              {i18n("Doing so will enable us to grant more scholarships and translate more of our resources.")}
              {` `}
              <AskSentence>
                {i18n("Would you join us in promoting the sound handling of God’s word throughout the world?")}
              </AskSentence>
            </Paragraph>

            <ButtonContainer>
              <StyledLinkButton
                href="https://buy.stripe.com/6oE5mh8AU0MxfssaEF"
                color="primary"
                variant="contained"
                disableElevation
              >
                {i18n("Donate Online")}
              </StyledLinkButton>
            </ButtonContainer>

            <ByCheck>
              {i18n("You may also make a check out to “Bethlehem College and Seminary,” write “For Biblearc” in the memo line, and send it to:")}
              <Address>
                {`Bethlehem College and Seminary`}
                <br />
                {`720 13th Avenue South`}
                <br />
                {`Minneapolis, MN 55415-1793`}
              </Address>
            </ByCheck>

          </ContentContainer>

        </Content>
      </Container>

    </>
  )
}


export default memo(Donate)