import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Fade from '@material-ui/core/Fade'

import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import useModalAnchor from '../../../hooks/useModalAnchor'
import { sortModules } from '../../../utils/misc'

import ProjectsListModule from './ProjectsListModule'
import BasicPopover from '../../common/BasicPopover'

const Container = styled.div`
  width: 200px;
  padding-right: 10px;

  ${({ onClick }) => !onClick ? `` : `
    &:hover {
      cursor: pointer;
    }
    &:hover .projects-list-modules-plus-more {
      color: black;
      transform: translateY(2px);
    }
  `}

  @media (max-width: 1260px) {
    width: 150px;
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  @media (max-width: 400px) {
    width: 100px;
    margin-right: -10px;
  }
`

const StyledBasicPopover = styled(BasicPopover)`
  .MuiPopover-paper {
    padding: 10px;
    margin: -10px;
  }
`

const PlusMore = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[500]};
  line-height: 1.2;
  transition: color .2s ease-in-out, transform .2s ease-in-out;
`

const ProjectsListModules = ({
  moduleByProjects=[],
  disableClickableExpand,
  ...otherProps
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const filteredAndSortedModuleByProjects = useEqualObjsMemo(
    moduleByProjects
      .filter(({ module: { type, modulePassages, createdAt, modifiedAt } }) => !(
        (
          // get rid of unstarted study modules
          [ 'DISCOURSE', 'PHRASING', 'DIAGRAMMING', 'OUTLINE', 'MARKUP' ].includes(type)
          && modulePassages.length === 0
        )
        || (
          // get rid of unstarted study modules
          [ 'NOTES' ].includes(type)
          && (modifiedAt - createdAt) < 1000  // it wasn't modified more than a second after creation
        )
      ))
      .sort(sortModules)
  )

  if(filteredAndSortedModuleByProjects.length > 3) {
  
    return (
      <>
        <Container
          {...otherProps}
          onClick={disableClickableExpand ? null : openModal}
        >
          {filteredAndSortedModuleByProjects.slice(0,2).map(({ module }) => (
            <ProjectsListModule
              key={module.id}
              module={module}
            />
          ))}
          <PlusMore className="projects-list-modules-plus-more">
            {i18n("+ {{number}} more", {
              number: filteredAndSortedModuleByProjects.length - 2,
            })}
          </PlusMore>
        </Container>
        <StyledBasicPopover
          open={!!anchorEl}
          anchorEl={anchorEl}
          marginThreshold={5}
          TransitionComponent={Fade}
          onClose={closeModal}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {filteredAndSortedModuleByProjects.map(({ module }) => (
            <ProjectsListModule
              key={module.id}
              module={module}
            />
          ))}
        </StyledBasicPopover>
      </>
    )

  }

  return (
    <Container
      {...otherProps}
    >
      {filteredAndSortedModuleByProjects.map(({ module }) => (
        <ProjectsListModule
          key={module.id}
          module={module}
        />
      ))}
    </Container>
  )
}

export default memo(ProjectsListModules)