import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
// import Tooltip from '@material-ui/core/Tooltip'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 46px;
`

const Switch = styled.div`
  position: absolute;
  top: 10px;
  z-index: 1;
  display: flex;
  border: 1px solid #707070;
  background: #4c4c4c;
  border-radius: 100px;
  padding: 3px;
`

const Marker = styled.div`
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 3px;
  width: 70px;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out;
  transform: translateX(${({ $view }) => $view === `markup` ? 70 : 0}px);
  background-color: ${({ $view, theme }) => $view === `markup` ? theme.palette.primary.main : theme.palette.secondary.main}66;
  border-radius: 100px;
`

const StyledButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70px;
  padding: 0 5px;
  line-height: 26px;
  white-space: nowrap;
  transition: background-color .25s ease-in-out;
  border-radius: 100px;
  cursor: default;
  user-select: none;
  font-weight: 500;
  font-size: 13px;

  ${({ $selected, onClick }) => ($selected || onClick) ? `` : `
    opacity: .3;
  `}

  ${({ $selected, onClick }) => ($selected || !onClick) ? `` : `
    &:hover {
      background-color: #555555;
      cursor: pointer;
    }
  `}
`

const SearchMarkupSwitch = ({
  view,
  setView,
  ...otherProps
}) => {

  const goSetView = useCallback(({ target }) => setView(target.closest(`[data-view]`).getAttribute(`data-view`)), [ setView ])

  return (
    <Container
      {...otherProps}
    >
      <Switch>

        <Marker $view={view} />

        <StyledButton
          onClick={setView && goSetView}
          data-view="search"
          $selected={view === `search`}
        >
          {i18n("Search")}
        </StyledButton>

        <StyledButton
          onClick={setView && goSetView}
          data-view="markup"
          $selected={view === `markup`}
        >
          {i18n("Markup")}
        </StyledButton>

      </Switch>
    </Container>
  )

}

export default memo(SearchMarkupSwitch)