import { useState, useRef, useCallback } from "react"

import useEffectAsync from "./useEffectAsync"

const useRefState = initialValue => {

  const [ value, setStateValue ] = useState(initialValue)
  const valueRef = useRef(value)
  const callbackRef = useRef()

  const setValue = useCallback(
    (newValue, callback) => {
      valueRef.current = newValue
      setStateValue(newValue)
      callbackRef.current = callback
    },
    [],
  )

  const getValue = useCallback(() => valueRef.current, [])

  useEffectAsync(
    () => {
      const callback = callbackRef.current
      callbackRef.current = undefined
      callback && callback()
    },
    [ value ],
  )

  return [
    value,
    setValue,
    getValue,
  ]
}

export default useRefState
