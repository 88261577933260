import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'

import { getModifierChar } from '../../../utils/misc'
import useSimpleToggle from '../../../hooks/useSimpleToggle'

import CustomCheckbox from '../../common/CustomCheckbox'
import SignInButton from '../../common/SignInButton'

const Container = styled.div`
  padding-bottom: 50vh;
  font-size: 15px;

  p {
    margin-top: 10px;
  }
`

const Rules = styled.div`
  font-style: italic;
  color: ${({ theme }) => theme.palette.primary.dark};
`

const ImageContainer = styled.div`
  margin: 20px 0 20px;
  width: 800px;
  max-width: calc(100vw - 60px);
  position: relative;

  @media (max-width: 700px) {
    max-width: calc(100vw - 20px);
    margin-top: 0;
  }
`

const Image = styled.img`
  width: 100%;
  vertical-align: middle;
`

const ImageShade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.3);
`

const VisionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const VisionHeading = styled.div`
  padding: 15px 20px 10px;
  color: white;
  font-size: 20px;
  font-weight: 200;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 500px) {
    font-size: 17px;
  }
`

const OurVision = styled.span`
  font-weight: bold;
`

const Vision = styled.div`
  padding: 0 20px;
  color: white;
  font-size: 18px;

  @media (max-width: 500px) {
    font-size: 16px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 1.2;
  }
`

const Point = styled.div`
  margin: 3px 0 3px 15px;
`

const Heading = styled.div`
  font-weight: 700;
  text-align: left;
  margin: 20px 0 10px;
  font-size: 17px;
`

const InstructionsReadAffirmation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 30px 0;

  .MuiFormControlLabel-root {
    margin: 0;
  }
`

const ChooseToGetStarted = styled.div`
  font-weight: 500;
  text-align: center;
  margin: 40px 0;
  font-size: 18px;
`

const TaggerInstructions = ({
  instructionsRead,
  setInstructionsRead,
  rulesOpenedAtLeastOnce,
  isLoggedIn,
  ...otherProps
}) => {

  const [ checked, toggleChecked ] = useSimpleToggle()

  return (
    <Container {...otherProps} >

      <ImageContainer className="dark-mode-exempt">
        <Image src="/globe.jpeg" />
        <ImageShade />
        <VisionContainer>
          <VisionHeading>
            {i18n("“Tagging”")}
            {` | `}
            <OurVision>
              {i18n("Our Vision")}
            </OurVision>
          </VisionHeading>
          <Vision>
            {i18n("It is our goal to see every Christian worldwide with free access to the Bible, tagged to the original Hebrew, Aramaic, and Greek, with parsing and lexical information—all in their own language.")}
          </Vision>
        </VisionContainer>
      </ImageContainer>

      <p>
        {i18n("By connecting words between the original languages and a translation (what we call “tagging”), you help us achieve this aim.")}
        {` `}
        {i18n("And the more people who tag, the better tagging data becomes.")}
        {` `}
        {i18n("So we invite you to tag often!")}
      </p>
      <p>
        {i18n("Our server will take this data and automatically produce the following in the language of the tagged translation:")}
      </p>
      <Point>
        {i18n("1.")}
        {` `}
        {i18n("A gloss for each original language word")}
      </Point>
      <Point>
        {i18n("2.")}
        {` `}
        {i18n("An in-depth lexical entry outlining usage")}
      </Point>
      <Point>
        {i18n("3.")}
        {` `}
        {i18n("The ability for users to easily check the original language word behind every word in every verse")}
      </Point>
      <Point>
        {i18n("4.")}
        {` `}
        {i18n("A powerful, integrated search")}
      </Point>

      <Heading>
        {i18n("Two Ways to Contribute Tags")}
      </Heading>
      <p>
        {i18n("First, as you study a passage or do a Bible search, you may notice that a verse is yet untagged or its tags incorrect.")}
        {` `}
        {i18n("Take a minute to tag that verse.")}
      </p>
      <p>
        {i18n("Second, you may want to volunteer a chunk of time to tag whichever verses need tagging.")}
        {` `}
        {i18n("Do so by opening the main menu and selecting “Tag Hebrew and Greek.”")}
        {` `}
        {i18n("If you leave “Browse untagged only” checked, clicking the left and right arrows will bring you directly to verses that need tagging.")}
      </p>

      <Heading>
        {i18n("Basic Instructions")}
      </Heading>
      <Point>
        {i18n("1.")}
        {` `}
        {i18n("Click or tap an original language word, then all of its associated translation words. Repeat for the entire verse.")}
      </Point>
      <Point>
        {i18n("2.")}
        {` `}
        {i18n("Long-press (or {{modifier_char}} click) on original language words to select more than one at a time. Do this when multiple words correspond to the translation word(s).", { modifier_char: getModifierChar()})}
      </Point>
      <Point>
        {i18n("3.")}
        {` `}
        {i18n("Leave words untagged when an original word is not translated, or a translation word is supplied without an original word counterpart.")}
      </Point>
      <Point>
        {i18n("4.")}
        {` `}
        {i18n("You will often find a number of words pre-tagged by our auto-tagger. You should check (and correct, when needed) these tags before submitting.")}
      </Point>

      <Heading>
        {i18n("Helps and Rules")}
      </Heading>
      <p>
        {i18n("Tap an original language word a second time to see its parsing and/or gloss.")}
      </p>
      <p>
        {i18n("On rare occasions, a translation will be based on a textual variant not present in the base Hebrew or Greek text.")}
        {` `}
        We are working on a solution for this.
        {` `}
        For now, please leave such verses untagged.
      </p>

      <Rules>
        {i18n("Before you begin, please carefully review the tagging rules, found by tapping the ⋮ icon at the top.")}
        {` `}
        {i18n("Return to these rules whenever you need a reminder or encounter a tricky verse.")}
        {` `}
        {i18n("You will find several examples there.")}
        {` `}
        {i18n("In addition, feel free to uncheck “Browse untagged only” (also found in the ⋮ menu) to see how other verses have been tagged.")}
      </Rules>

      {!instructionsRead && isLoggedIn &&
        <InstructionsReadAffirmation>

          <CustomCheckbox
            checked={checked}
            onChange={toggleChecked}
            label={i18n("I have read the instructions and tagging rules.")}
            disabled={!rulesOpenedAtLeastOnce}
            color="primary"
          />

          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => setInstructionsRead(true)}
            disabled={!checked}
          >
            {i18n("Get started")}
          </Button>

        </InstructionsReadAffirmation>
      }

      {instructionsRead && isLoggedIn &&
        <ChooseToGetStarted>
          {i18n("Choose a verse above to get started.")}
        </ChooseToGetStarted>
      }

      {!isLoggedIn &&
        <ChooseToGetStarted>
          <SignInButton
            label={i18n("Sign in to get started")}
          />
        </ChooseToGetStarted>
      }

    </Container>
  )
}


export default memo(TaggerInstructions)