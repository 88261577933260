import { gql } from '@apollo/client'

const stripeSubscriptionChangePreview = gql`
  query stripeSubscriptionChangePreview($plan: String!, $term: String!, $donation: Float!, $executeAt: Milliseconds!) {
    stripeSubscriptionChangePreview(plan: $plan, term: $term, donation: $donation, executeAt: $executeAt) {
      totalDueNow
      nextChargeAt
      nextChargeAmount
    }
  }
`

export default stripeSubscriptionChangePreview