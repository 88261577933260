import { gql } from '@apollo/client'

const allVersions = gql`
  query versions {
    versions(limit: -1) {
      versions {
        id
        abbr
        name
        languageId
      }
      count
    }
  }
`

export default allVersions