import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useIsLoggedIn from '../../../hooks/useIsLoggedIn'

import NewOrLearning from '../shared/NewOrLearning'
import LinkButton from '../../common/LinkButton'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

// const Spacer = styled.div`
//   height: 10px;
// `

const StyledLinkButton = styled(LinkButton)`
  background-color: ${({ theme }) => theme.palette.fourthly.main};
  color: white;

  &:hover {
    background-color: ${({ theme }) => theme.palette.fourthly.dark};
  }
`

const Spacer = styled.div`
  height: 10px;
`

const Expl = styled.div`
  margin-top: 12px;
  font-weight: 300;
`

const Links = styled.div`
  display: flex;
  gap: 10px;
  font-size: 12px;
  margin-top: 10px;
`

const GradCap = styled.span`
  display: inline-block;
  margin-right: 5px;
`

const NewToDiagramming = () => {

  const isLoggedIn = useIsLoggedIn()

  const autologin = isLoggedIn ? `?autologin` : ``

  return (
    <NewOrLearning>

      <StyledLinkButton
        href={`https://equip.biblearc.com/course/grammar${autologin}`}
        variant="contained"
        disableElevation
      >
        <GradCap className="dark-mode-exempt">🎓</GradCap>
        {i18n("Take our Grammar course", "", "diagramming")}
      </StyledLinkButton>

      <Spacer />

      <StyledLinkButton
        href={`https://equip.biblearc.com/course/greek-i${autologin}`}
        variant="contained"
        disableElevation
      >
        <GradCap className="dark-mode-exempt">🎓</GradCap>
        {i18n("Take our Greek courses", "", "diagramming")}
      </StyledLinkButton>

      <Expl>
        {i18n("(both teach diagramming)", "", "diagramming")}
      </Expl>

      <Links>
        {i18n("Cheat Sheets:")}
        <NavLinkOrAWithDisable to="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1684445195303-GrammarCheatSheet.pdf">
          {i18n("English")}
        </NavLinkOrAWithDisable>
        <NavLinkOrAWithDisable to="https://docs.google.com/viewer?url=https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1613529921791-DiagrammingComponents1.pdf">
          {i18n("Greek")}
        </NavLinkOrAWithDisable>
      </Links>

    </NewOrLearning>
  )
}

// https://equip.biblearc.com/course/greek-i/89115

export default memo(NewToDiagramming)