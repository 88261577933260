import folderAncestry_fields from '../fragments/folderAncestry_fields'

const fields = `
  id
  name
  starred
  inDoubleStudyMode
  studyModule1Id
  studyModule2Id
  notesModuleId
  notesInDrawer
  notesHeightPercentage
  notesWidthPercentage
  firstColumnWidthPercentage
  passageBookmarks
  createdAt
  savedAt
  modifiedAt
  openedOrModifiedAt
  deletedAt
  folderAncestry {
    ${folderAncestry_fields}
  }
  userId
`

// Not included here, but available:
  // moduleByProjects

export default fields