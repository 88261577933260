import { useCallback } from "react"
import { getPassageInfoArrayFromText } from "@bibletags/bibletags-ui-helper"

import ATag from "../components/modules/notes/ATag"

const useFlipEditorGetDecorators = ({
  getTopOffset,
  getWidth,
}) => {

  const getDecorators = useCallback(
    ({
      findLinkEntities,
      // defaultLinkComponent,
    }) => {

      return [
        {
          strategy: (block, callback, contentState) => {

            findLinkEntities(block, callback, contentState)

            const text = block.getText() || ``
            getPassageInfoArrayFromText({ text })
              .forEach(({ startCharacterIndex, endCharacterIndex }) => (
                callback(startCharacterIndex, endCharacterIndex)
              ))

          },
          component: props => (
            <ATag
              getTopOffset={getTopOffset}
              getWidth={getWidth}
              {...props}
            />
          ),
        },
      ]

    },
    [ getTopOffset, getWidth ],
  )

  return getDecorators
}

export default useFlipEditorGetDecorators



