import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useIsLoggedIn from '../../../hooks/useIsLoggedIn'

import NewOrLearning from '../shared/NewOrLearning'
import LinkButton from '../../common/LinkButton'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Links = styled.div`
  display: flex;
  gap: 10px;
  font-size: 12px;
  margin-top: 15px;
`

const GradCap = styled.span`
  display: inline-block;
  margin-right: 5px;
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  transition: transform .15s ease-in-out;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.03);
    }
  }
`

const MiniCourseImg = styled.img`
  width: 300px;
  max-width: 100%;
  margin: 20px 0 2px;
  vertical-align: middle;
`

const NewToPhrasing = () => {

  const isLoggedIn = useIsLoggedIn()

  const autologin = isLoggedIn ? `?autologin` : ``

  return (
    <NewOrLearning>

      <LinkButton
        href={`https://equip.biblearc.com/course/phrasing${autologin}`}
        variant="contained"
        color="secondary"
        disableElevation
      >
        <GradCap className="dark-mode-exempt">🎓</GradCap>
        {i18n("Take the Phrasing course", "", "phrasing")}
      </LinkButton>

      <StyledNavLinkOrAWithDisable
        to="https://equip.biblearc.com/course/block-diagramming"
      >
        <MiniCourseImg
          src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1723475816766-BlockDiagrammingMiniCourse4xtiny.jpeg"
          className="dark-mode-exempt"
        />
      </StyledNavLinkOrAWithDisable>

      <Links>
        <NavLinkOrAWithDisable to="https://equip.biblearc.com/blog-post/phrasing-demonstrations">
          {i18n("Other Phrasing Demos")}
        </NavLinkOrAWithDisable>
      </Links>

    </NewOrLearning>
  )
}

export default memo(NewToPhrasing)