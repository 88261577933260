import { useState, useEffect, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import { useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'

import useSetTimeout from './useSetTimeout'

import requestEditingLockMutation from '../graphql/mutations/requestEditingLock'
import releaseEditingLockMutation from '../graphql/mutations/releaseEditingLock'

const requestId = uuidv4()  // unique to the tab

const useEditingLock = ({
  type,
  open,
}) => {

  const [ requestEditingLock ] = useMutation(requestEditingLockMutation)
  const [ releaseEditingLock ] = useMutation(releaseEditingLockMutation)

  const [ lockObtained, setLockObtained ] = useState()
  const [ beingUsedByUser, setBeingUsedByUser ] = useState()
  const [ setLockTimeout, clearLockTimeout ] = useSetTimeout()
  const [ reset, setReset ] = useState()

  useEffect(
    () => {

      const variables = {
        type,
        requestId,
      }

      if(open) {

        if(reset) {
          setReset()
          setLockObtained(false)
          setBeingUsedByUser()
        }

        const getAndRenewLock = async () => {

          const { data: { requestEditingLock: { granted, beingUsedByUser } } } = await requestEditingLock({ variables })

          setLockObtained(granted)
          setBeingUsedByUser(beingUsedByUser)

          setLockTimeout(getAndRenewLock, 1000*60)

        }

        getAndRenewLock()

        return () => {
          clearLockTimeout()
          releaseEditingLock({ variables })
          setReset(true)
        }

      }

    },
    [ type, open ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  return useMemo(
    () => ({
      requestingLock: !lockObtained && !beingUsedByUser,
      lockObtained,
      requestId,
      lockedMsg: i18n("Presently being edited by {{name}}.", beingUsedByUser || {}),
      lockedExplanation: i18n("To prevent loss of data, only one person is allowed to edit at any given time. To unlock, the current editor must close out of his/her editing (after which you may continue to be blocked for up to 2 minutes)."),
      reset,
    }),
    [ lockObtained, beingUsedByUser, reset ],
  )

}

export default useEditingLock