import { gql } from '@apollo/client'

import studyBibleItem_fields from '../fragments/studyBibleItem_fields'

export default gql
`
  mutation addReactionToStudyBibleItem($studyBibleItemId: ID!, $input: ReactionInput!) {
    addReactionToStudyBibleItem(studyBibleItemId: $studyBibleItemId, input: $input) {
      ${studyBibleItem_fields}
    }
  }
`