import { gql } from '@apollo/client'

import globalAlertsItem_fields from '../fragments/globalAlertsItem_fields'

export default gql
`
  mutation updateGlobalAlertsItem($id: ID!, $input: GlobalAlertsItemInput!) {
    updateGlobalAlertsItem(id: $id, input: $input) {
      ${globalAlertsItem_fields}
    }
  }
`