import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateModuleSubtables from './useGoUpdateModuleSubtables'

import projectQuery from '../graphql/queries/project'
import updateModulePassagesMutation from '../graphql/mutations/updateModulePassages'
import deleteModulePassagesMutation from '../graphql/mutations/deleteModulePassages'

export const getDefaultModulePassage = () => ({
  __typename: `ModulePassage`,
  info: null,
})

const useGoUpdateModulePassages = ({
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  const client = useApolloClient()

  const [ updateModulePassages, updateModulePassagesResult ] = useMutation(updateModulePassagesMutation)
  const [ deleteModulePassages, deleteModulePassagesResult ] = useMutation(deleteModulePassagesMutation)

  const onUpdate = useCallback(
    ({ newData, isNew }) => {

      if(isNew) {

        const { project } = client.readQuery({
          query: projectQuery,
          variables: {
            id: projectId,
          },
        })

        const data = {
          project: {
            ...project,
            moduleByProjects: project.moduleByProjects.map(moduleByProject => {
              if(moduleByProject.module.id === moduleId) {
                const { module } = moduleByProject
                const { modulePassages } = module
                return {
                  ...moduleByProject,
                  module: {
                    ...module,
                    modulePassages: [
                      ...modulePassages,
                      ...newData,
                    ],
                  },
                }
              }
              return moduleByProject
            }),
          },
        }

        client.writeQuery({
          query: projectQuery,
          data,
          variables: {
            id: projectId,
          },
        })

      }

    },
    [ projectId, moduleId, client ],
  )

  const updateAndDeleteFuncs = useGoUpdateModuleSubtables({
    updateFunc: updateModulePassages,
    updateResult: updateModulePassagesResult,
    deleteFunc: deleteModulePassages,
    deleteResult: deleteModulePassagesResult,
    onUpdate,
    // onDelete,
    moduleId,
    projectId,
    undoRedoStack,
    getDefault: getDefaultModulePassage,
  })

  return updateAndDeleteFuncs
}

export default useGoUpdateModulePassages