import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation deleteModulePassages($moduleId: ID!, $projectId: ID, $ids: [ID]!, $savedAt: Milliseconds!, $updatedSince: Milliseconds) {
    deleteModulePassages(moduleId: $moduleId, projectId: $projectId, ids: $ids, savedAt: $savedAt, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`