import { memo, useContext } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import CheckIcon from '@material-ui/icons/Check'
import queryString from 'query-string'
import { useApolloClient } from '@apollo/client'

import { LoggedInUserContext } from '../../../context/LoggedInUser'
import userQuery from '../../../graphql/queries/user'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useSetTimeout from '../../../hooks/useSetTimeout'
import useEffectAsync from '../../../hooks/useEffectAsync'

import Header from "../../common/Header"
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import LinkButton from '../../common/LinkButton'

const PageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobilePageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`

const HeaderLogoImg = styled.img`
  height: 36px;
  vertical-align: top;
  margin: 5px -4px 0 -6px;
`

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 20px calc(100vh - 150px);
  background-color: white;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SuccessContainer = styled.div`
  margin: 40px 0 -30px;
  display: flex;
  justify-content: center;
`

const Success = styled.div`
  font-size: 18px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.grey[50]};
  align-self: center;
  padding: 10px 17px;
  border-radius: 5px;

  .MuiSvgIcon-root {
    height: 25px;
    width: auto;
    vertical-align: middle;
    color: ${({ theme }) => theme.palette.primary.main};
    margin: -4px 5px 0 -2px;
  }
`

const Features = styled.div`
  text-align: center;
  margin: 60px 0 10px;
  font-weight: 500;
  font-size: 19px;
`

const Concept = styled.div`
  text-align: center;
  margin: 10px 0 10px;
  font-weight: 300;
  font-size: 22px;
  max-width: 600px;
`

const LABLater = styled.div`
  text-align: center;
  margin: 15px 0 30px;
  font-weight: 300;
  font-size: 15px;
`

const FeaturesList = styled.ol`
  max-width: 540px;
  margin: 0 auto 50px;
  font-weight: 300;
`

// const Whoops = styled.div`
//   text-align: center;
//   font-weight: 300;
//   margin-top: 45px;
// `

const SeePromo = styled.div`
  font-size: 14px;
  margin: 100px 0 0;
  text-align: center;
`

const LogoImg = styled.img`
  height: 180px;
  margin: 24px 0 10px;
`

const BigIdea = styled.div`
  text-align: center;
  font-weight: 500;
  margin: -10px 0 5px;
  font-size: 16px;
`

const Price = styled.div`
  font-size: 15px;
  font-weight: 300;
`

const PromoVideoContainer = styled.div`
  margin: 20px 0 0;
  position: relative;
  width: 500px;
  max-width: min(calc((100vh - 59px - 80px) * .5625), calc(100vw - 30px));

  &::before {
    content: "";
    display: block;
    padding-bottom: 177.78%;
  }
`

const PromoVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
`

const GetMy = styled.div`
  background-size: 103% 160%;
  background-position: 0px 0px,0px 0px;
  background-image: url("/underline_scribble.svg");
  padding: 130px 50px;
  margin: -80px -50px 60px;
`

const ButtonImage = styled.img`
  height: 35px;
  vertical-align: top;
  margin: -2px -6px 0 0;
`

const StyledLinkButton = styled(LinkButton)`
  padding: 10px 20px;
  font-size: 16px;

`

const AboutMyBSB = ({ ...props }) => {

  let {
    'purchase-successful': purchaseSuccessful,
    'subscription-granted': subscriptionGranted,
  } = queryString.parse(window.location.search)
  purchaseSuccessful = purchaseSuccessful !== undefined
  subscriptionGranted = subscriptionGranted !== undefined

  const client = useApolloClient()
  const user = useContext(LoggedInUserContext)
  // const { hasMyPlan } = user || {}
  const getUser = useInstanceValue(user)
  const [ setDoUserQueryTimeout ] = useSetTimeout()

  useEffectAsync(
    // Immediately after purchase, keep doing user queries until stripe subscription is found.
    () => {
      if(!purchaseSuccessful) return

      let timesChecked = 0

      const checkAndTryAfterTimeout = () => {
        setDoUserQueryTimeout(
          async () => {
            const user = getUser()
            if(!user) return

            const hasStripeSubscription = !!(user.activeSubscriptions || []).filter(({ type }) => type === 'STRIPE')[0]

            if(!hasStripeSubscription) {
              await client.query({
                context: {
                  isBackupServerQuery: true,  // forcing the await to wait until run through the server via this option
                  forceSaveServerResultToDexie: true,    
                },
                query: userQuery,
                fetchPolicy: `network-only`,
              })
              if(++timesChecked < 10) {
                checkAndTryAfterTimeout()
              }
            }
          },
          1000,
        )
      }

      checkAndTryAfterTimeout()
    },
    [],
  )

  return (
    <>

      <Header {...props}>
        <PageTitle>
          {i18nReact("About {{logo}}", {
            logo: <HeaderLogoImg src="/my_biblearc_study_bible_1.svg" />
          })}
        </PageTitle>
        <MobilePageTitle>
          {i18n("About My BSB")}
        </MobilePageTitle>
      </Header>

      <Container>
        <Content>

          {purchaseSuccessful &&
            <SuccessContainer>
              <Success>
                <CheckIcon />
                {i18n("Purchase successful")}
              </Success>
            </SuccessContainer>
          }

          {subscriptionGranted &&
            <SuccessContainer>
              <Success>
                <CheckIcon />
                {i18n("Subscription granted")}
              </Success>
            </SuccessContainer>
          }

          <LogoImg src="/my_biblearc_study_bible_2.svg" />

          <BigIdea>
            {i18n("Make The Biblearc Study Bible your own.")}
          </BigIdea>

          <Price>
            {i18n("Just $2.99 per month.")}
          </Price>

          <Features>
            {i18n("Concept")}
          </Features>

          <Concept>
            {i18n("Design a personalized study Bible with sermons from your church, your favorite ministry channels, and customized maps.")}
          </Concept>

          <PromoVideoContainer>
            <PromoVideo src="https://player.vimeo.com/video/1031614582?h=2b1482214b" frameborder="0" allowfullscreen />
          </PromoVideoContainer>

          <LABLater>
            {i18n("Note: Look at the Book-style sketches are coming in February.")}
          </LABLater>

          <Features>
            {i18n("Features")}
          </Features>

          <FeaturesList>
            <li>{i18n("Integrate your church’s sermons, and videos from popular ministries.")}</li>
            <li>{i18n("Bookmark sermons, and even specific timestamps within sermons.")}</li>
            <li>{i18n("Choose from 99 different styles to markup your Bible and annotate.")}</li>
            <li>{i18n("Add your own notes to the Map + Timeline and save map snapshots.")}</li>
          </FeaturesList>

          <GetMy>
            <StyledLinkButton
              to="/subscribe"
              variant="outlined"
            >
              {i18nReact("Get {{thing_to_get}}", {
                thing_to_get: (
                  <ButtonImage src="/my_biblearc_study_bible_1.svg" />
                ),
              })}
            </StyledLinkButton>
          </GetMy>

          <SeePromo>
            {i18nReact("Learn about the {{the_biblearc_study_bible}}.", {
              the_biblearc_study_bible: (
                <NavLinkOrAWithDisable to="/about-the-biblearc-study-bible" exact>
                  {i18n("The Biblearc Study Bible")}
                </NavLinkOrAWithDisable>
              )
            })}
          </SeePromo>

        </Content>
      </Container>

    </>
  )
}


export default memo(AboutMyBSB)


// import { combineItems } from "@bibletags/bibletags-ui-helper"

// <Point>
//   {i18n("View sermons from popular ministries of your choosing, right along side your Bible.")}
//   <PopularMinistriesList>
//     <PopularMinistriesCurrent>
//       {i18n("Currently Available:")}
//     </PopularMinistriesCurrent>
//     {` `}
//     {combineItems(...STUDY_BIBLE_CHANNELS.map(({ label }) => label))}
//   </PopularMinistriesList>
// </Point>
// 
// <Point>
//   {i18n("See the sermons from your local church right next to the passages they address.")}
// </Point>
// 
// <Point>
//   {i18n("Bookmark spots in sermons you want to remember.")}
// </Point>
// 
// <Point>
//   {i18n("Save snapshots and add notes within the Map-Timeline.")}
// </Point>
// 
// <Point>
//   {i18n("Markup your Bible with highlights, underlines, circles, and arrows, and add rich text notes.")}
// </Point>
// 
// <Point>
//   {i18n("Create a library of your own “Look at the Book”-style sketches.")}
// </Point>
