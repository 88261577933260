import React from "react"
import styled from 'styled-components'
import ImageIcon from '@material-ui/icons/Image'

const Container = styled.div`
  overflow-x: auto;
  ${({ $placement }) => (
    $placement.match(/^alone/)
      ? `
        text-align: ${$placement.split('-')[1]};
      ` 
      : `
        float: ${$placement};
      `
  )}
`

const StyledImageIcon = styled(ImageIcon)`
  font-size: 100px;
  color: rgba(0,0,0,.2);
`

const Image = ({
  imgSrc,
  placement='alone-center',
  maxWidth,
  width=maxWidth,
  // width=maxWidth is due to maxWidth being the old parameter that is no longer used
  maintainWidth,
  linkHref,
  openInNewTab,
}) => {

  const imageStyle = {}

  if(width) {
    imageStyle.width = width
  }

  if(!maintainWidth) {
    imageStyle.maxWidth = '100%'
  }

  let image = <StyledImageIcon style={imageStyle} />

  if(imgSrc) {
    image = <img src={imgSrc} style={imageStyle} />
  }

  if(linkHref) {
    image = (
      <a
        href={linkHref}
        target={openInNewTab ? "_blank" : null}
      >
        {image}
      </a>
    )
  }

  return (
    <Container
      className={`flipeditor-image flipeditor-image-placement-${placement}`}
      $placement={placement}
    >
      {image}
    </Container>
  )

}

export default Image