import { memo, useMemo } from 'react'
import styled from 'styled-components'

import {
  MINIMUM_PASSAGE_COLUMN_WIDTH,
  MINIMUM_MODULE_COLUMN_WIDTH,
  MINIMUM_NOTES_COLUMN_WIDTH,
  HIDDEN_COLUMN_WIDTH,
  THUMBNAILS_WIDTH,
} from '../../utils/constants'
import useVersionInfos from '../../hooks/useVersionInfos'
import useOpenPassage from '../../hooks/useOpenPassage'

import MinimizedPanel from '../common/MinimizedPanel'
import PassageRef from '../common/PassageRef'

const StyledMinimizedPanel = styled(MinimizedPanel)`
  padding-top: 10px;
`

const PassageMinimizedPanel = ({
  passageBookmarks,
  goUpdateProject,
  getProjectSizes,
}) => {

  const { defaultVersionId } = useVersionInfos()

  const buttonsInfo = useMemo(
    () => {

      const passageBookmarksWithDefault = (
        passageBookmarks.length > 0
          ? passageBookmarks
          : [{
            ref: {
              bookId: 1,
              chapter: 1,
            },
          }]
      )

      return (
        passageBookmarksWithDefault.map(({ ref, versionId=defaultVersionId }, idx) => {

          const { bookId=1, chapter=1 } = ref || {}

          return {
            label: (
              <PassageRef
                refs={[{
                  bookId,
                  chapter,
                }]}
                versionId={versionId}
              />
            ),
            onClick: () => {
              const { module1Width, module2Width, showInDoubleStudyMode, passagesWidth, showNotesInDrawer, notesWidth } = getProjectSizes()

              // TODO: change which passage is active

              let modulesAndNotesWidth = module1Width + module2Width + THUMBNAILS_WIDTH + notesWidth
              const minModulesAndNotesWidth = MINIMUM_MODULE_COLUMN_WIDTH * (showInDoubleStudyMode ? 2 : 1) + THUMBNAILS_WIDTH + MINIMUM_NOTES_COLUMN_WIDTH

              const getNewPassageBookmarks = width => [
                {
                  ...(passageBookmarks[0] || {}),
                  width,
                },
                ...passageBookmarks.slice(1),
              ]
    
              if(showNotesInDrawer || notesWidth <= HIDDEN_COLUMN_WIDTH) {

                // notes is not an expanded panel so things are simple; just take space from modules
                goUpdateProject({
                  passageBookmarks: getNewPassageBookmarks(MINIMUM_PASSAGE_COLUMN_WIDTH),
                })

              } else if(notesWidth + HIDDEN_COLUMN_WIDTH >= MINIMUM_NOTES_COLUMN_WIDTH + MINIMUM_PASSAGE_COLUMN_WIDTH) {

                // reduce notes width only
                modulesAndNotesWidth -= MINIMUM_PASSAGE_COLUMN_WIDTH - HIDDEN_COLUMN_WIDTH
                goUpdateProject({
                  notesWidthPercentage: ((notesWidth - MINIMUM_PASSAGE_COLUMN_WIDTH + HIDDEN_COLUMN_WIDTH) / modulesAndNotesWidth) * 100,
                  passageBookmarks: getNewPassageBookmarks(MINIMUM_PASSAGE_COLUMN_WIDTH),
                })

              } else if(modulesAndNotesWidth + passagesWidth >= minModulesAndNotesWidth + MINIMUM_PASSAGE_COLUMN_WIDTH) {

                // bring notes to minimum expanded and reduce modules
                modulesAndNotesWidth -= MINIMUM_PASSAGE_COLUMN_WIDTH - HIDDEN_COLUMN_WIDTH
                goUpdateProject({
                  notesWidthPercentage: (MINIMUM_NOTES_COLUMN_WIDTH / modulesAndNotesWidth) * 100,
                  passageBookmarks: getNewPassageBookmarks(MINIMUM_PASSAGE_COLUMN_WIDTH),
                })

              } else {

                // minimize notes panel
                goUpdateProject({
                  notesWidthPercentage: 0,
                  passageBookmarks: getNewPassageBookmarks(notesWidth),
                })
              }

            },
          }
        })
      )
    },
    [ passageBookmarks, defaultVersionId, goUpdateProject, getProjectSizes ],
  )

  useOpenPassage({
    expandPassageArea: buttonsInfo[0].onClick,
  })

  return (
    <StyledMinimizedPanel
      buttonsInfo={buttonsInfo}
    />
  )
}

export default memo(PassageMinimizedPanel)