import { memo } from 'react'
// import { i18n, getLocale } from 'inline-i18n'
// import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { preventDefaultEvent } from '../../utils/misc'
import useAccountSetting from '../../hooks/useAccountSetting'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'

import LinkIconButton from './LinkIconButton'
import RelativeTime from './RelativeTime'

const Container = styled.div`
  padding: 10px 16px;
  justify-content: stretch;
  flex: 1;
  min-width: 240px;
  display: flex;
  align-items: center;
  gap: 5px;
`

const Main = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const GoLinkIconButton = styled(LinkIconButton)`
  padding: 10px;
  margin: -5px -5px -5px 0;
  align-self: center;
  
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const ActionIconButton = styled(IconButton)`
  padding: 10px;
  margin: -5px -5px -5px 0;
  align-self: center;
  
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const NoteText = styled.div`
  font-size: 13px;
  background-color: ${({ theme }) => theme.palette.primary.main}44;
  padding: 5px 7px;
  border-radius: 4px;
`

const MapItemName = styled.div`
  margin-bottom: 3px;
  font-weight: 500;
`

const LastUpdated = styled.div`
  font-size: 9px;
  font-weight: 300;
  margin-top: 3px;
`

const MainMenuMapNote = ({
  id,
  mapItemType,
  mapItemId,
  mapItemName,
  noteText,
  updatedAt,
  toggleDrawerOpen,
  deleteMode,
}) => {

  const [ mapNote, setMapNote ] = useAccountSetting(id.split(`:`)[1], {})

  const onClickDelete = useInstanceValuesCallback(
    event => {
      preventDefaultEvent(event)
      setMapNote({
        ...mapNote,
        note: ``,
      })
    },
  )

  return (
    <Container>

      <Main>

        <MapItemName>
          {mapItemName}
        </MapItemName>

        <NoteText>
          {noteText}
        </NoteText>

        {!!updatedAt &&
          <LastUpdated>
            <RelativeTime date={updatedAt} />
          </LastUpdated>
        }

      </Main>

      {!deleteMode &&
        <GoLinkIconButton
          to={`/map?${mapItemType}Id=${encodeURIComponent(mapItemId)}`}
          onClick={toggleDrawerOpen}
        >
          <ArrowForwardIcon />
        </GoLinkIconButton>
      }

      {!!deleteMode &&
        <ActionIconButton
          onClick={onClickDelete}
        >
          <DeleteIcon />
        </ActionIconButton>
      }

    </Container>
  )
}

export default memo(MainMenuMapNote)