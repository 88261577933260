import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { memo, useMemo, Fragment, useCallback } from 'react'
import styled from 'styled-components'

import { IS_EMBED } from '../../../utils/constants'
import usePassageRef from '../../../hooks/usePassageRef'
import { getModuleTypeLabel, isSafari } from '../../../utils/misc'
import { getEmbedMode } from '../../../graphql/links/embedLink'

import PassageRef from '../../common/PassageRef'

const Container = styled.div`
  flex: 2;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  /* border-radius: 9px 9px 0 0; */
  padding: 10px 15px 10px 20px;
  white-space: pre-wrap;
  word-wrap: break-word;

  ${({ $printOrDownloadInfo }) => !$printOrDownloadInfo ? `` : `
    padding: 0 0 0 15px;
  `}

  ol {
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-inline-start: 15px;
  }

  ol {
    font-weight: 700;
  }
  ol ol {
    font-weight: 500;
  }
  ol ol ol {
    font-weight: 400;
  }
  ol ol ol ol {
    font-weight: 300;
  }
  ol ol ol ol ol {
    font-weight: 200;
  }
  ol ol ol ol ol ol {
    font-weight: 100;
  }

  li {
    margin: .5em 0;
    line-height: 1.2;
    transition: opacity .15s ease-in-out;
  }

  li::marker {
    color: ${({ theme }) => theme.palette.grey[500]};
    font-weight: normal;
  }

  ${({ $outlineStyle }) => $outlineStyle !== `STANDARD` ? `` : `
    ol {
      list-style: decimal;
    }
    ol ol {
      list-style: lower-alpha;
    }
    ol ol ol {
      list-style: lower-roman;
    }
    ol ol ol ol {
      list-style: decimal;
    }
    ol ol ol ol ol {
      list-style: lower-alpha;
    }
    ol ol ol ol ol ol {
      list-style: lower-roman;
    }
  `}

  ${({ $outlineStyle }) => $outlineStyle !== `TRAILS` ? `` : `
    ol {
      counter-reset: trail;
    }
    ol li {
      counter-increment: trail;
    }
    li::marker {
      content: counters(trail, '.') '  ';
      font-size: .85em;
    }
  `}

  ${({ $outlineStyle }) => $outlineStyle !== `ROMAN` ? `` : `
    ol {
      list-style: upper-roman;
    }
    ol ol {
      list-style: upper-latin;
    }
    ol ol ol {
      list-style: decimal;
    }
    ol ol ol ol {
      list-style: lower-roman;
    }
    ol ol ol ol ol {
      list-style: lower-alpha;
    }
    ol ol ol ol ol ol {
      list-style: decimal;
    }
  `}

  ${({ $outlineStyle, $printOrDownloadInfo, $noneditableViewingMode }) => $outlineStyle !== `INDENTS` ? `` : `
    padding-left: 5px;

    ${!$printOrDownloadInfo ? `` : `
      padding-left: 0;
      & > ol {
        padding-inline-start: 0;
      }
    `}

    ${$noneditableViewingMode !== `within-notes` ? `` : `
      & > ol {
        padding-inline-start: 10px;
      }
    `}

    ol {
      list-style: none;
    }
  `}
`

const LiContent = styled.span`
  ${({ onClick }) => !onClick ? `` : `
    &:hover {
      cursor: pointer;
      opacity: .5;
    }
  `}
`

const PassageRefContainer = styled.span`
  display: inline-block;
  font-size: .8em;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.grey[600]};
  /* color: ${({ theme }) => theme.palette.secondary.main}; */
`

const Instructions = styled.div`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-weight: 300;
  text-align: center;
  padding: 35px 10px;
  font-size: 14px;

  ol {
    text-align: left;
  }

  li {
    margin: 20px 0;
    font-weight: 500;
    font-size: 13px;
  }
`

const ToGetStarted = styled.div`
  margin: 30px 0;
  font-weight: 500;
  font-size: 13px;
`

const HeadingForNotes = styled.div`
  font-weight: 300;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 3px 0 4px;
  margin: 0 0 15px ${({ $outlineStyle }) => $outlineStyle === `INDENTS` ? 10 : -5}px;
`

const OutlineKey = ({
  rows,
  versionId,
  fromLoc,
  toLoc,
  outlineStyle,
  inEditingMode,
  noneditableViewingMode,
  printOrDownloadInfo,
  virtuoso,
}) => {

  const passageRef = usePassageRef({
    fromLoc,
    toLoc,
    versionId,
  })

  const infoByLevels = useMemo(
    () => {
      const infoByLevels = []
      const levelsRepresented = {}

      rows.forEach(({ modulePieces={} }) => {
        Object.keys(modulePieces).forEach(level => {
          if(levelsRepresented[level] === undefined && /^h/.test(level)) {
            levelsRepresented[level] = true
          }
        })
      })
      let levelIndex = 0
      Object.keys(levelsRepresented).sort().forEach(level => {
        levelsRepresented[level] = levelIndex++
      })

      rows.forEach(({ modulePieces={}, fromLoc, toLocByPosition }, rowIndex) => {
        Object.keys(levelsRepresented).filter(level => modulePieces[level]).sort().forEach(level => {
          let levelArray = infoByLevels
          for(let idx=0; idx < levelsRepresented[level]; idx++) {
            if(levelArray.length === 0) {  // i.e. on first heading and it is not at the base level
              levelArray.push({ kids: [] })
            }
            levelArray = levelArray.at(-1).kids
          }
          levelArray.push({
            content: modulePieces[level].content || ``,
            fromLoc,
            toLoc: toLocByPosition[level],
            kids: [],
            rowIndex,
          })
        })
      })

      return infoByLevels
    },
    [ rows ],
  )

  const onClick = useCallback(
    ({ currentTarget }) => {
      const index = parseInt(currentTarget.getAttribute(`data-row-index`), 10)
      if(typeof index === `number`) {
        virtuoso.current.scrollToIndex({
          index,
          behavior: isSafari ? "auto" : "smooth",  // I might want to have this on "auto" if I have scrolling a great number of indexes
          offset: noneditableViewingMode ? 0 : -62,
        })
      }
    },
    [ noneditableViewingMode, virtuoso ],
  )

  const getOlFromLevel = levelArray => (
    levelArray.length === 0
      ? null
      : (
        (
          <ol>
            {levelArray.map(({ content, fromLoc, toLoc, kids, rowIndex }, idx) => {

              const passageRef = (
                <PassageRef
                  fromLoc={fromLoc}
                  toLoc={toLoc}
                  versionId={versionId}
                  withoutBookName
                />
              )

              return (
                <Fragment key={idx}>

                  {!!fromLoc &&
                    <li>

                      <LiContent
                        onClick={(noneditableViewingMode === `within-notes` || (IS_EMBED && getEmbedMode() !== `prep`)) ? null : onClick}
                        data-row-index={rowIndex}
                        className="print-break-text-block"
                      >

                        {content.replace(/(.)$/, `$1 `)}

                        <PassageRefContainer>
                          {!content && passageRef}
                          {!!content && i18nReact("({{parenthetical_text}})", {
                            parenthetical_text: passageRef,
                          })}
                        </PassageRefContainer>

                      </LiContent>

                      {getOlFromLevel(kids)}

                    </li>
                  }

                {!fromLoc && kids.length > 0 && getOlFromLevel(kids)}

                </Fragment>
              )
            })}
          </ol>
        )
      )
  )

  const isFrozenEmbed = IS_EMBED && getEmbedMode() === `frozen`

  return (
    <Container
      $outlineStyle={outlineStyle}
      $printOrDownloadInfo={printOrDownloadInfo}
      $noneditableViewingMode={noneditableViewingMode}
      className="outline-select-all-container"
    >

      {noneditableViewingMode === `within-notes` &&
        <HeadingForNotes $outlineStyle={outlineStyle}>
          {i18n("{{module_type}} of {{passages}}", {
            module_type: getModuleTypeLabel({ type: `OUTLINE` }),
            passages: passageRef,
          })}
        </HeadingForNotes>
      }

      {getOlFromLevel(infoByLevels)}

      {inEditingMode && infoByLevels.length === 0 && !noneditableViewingMode &&
        <Instructions>
          {i18n("To begin an outline...", "", "outline")}
          <ol>
            <li>
              {i18n("Click on a word wherever you’d like a new paragraph to begin.", "", "outline")}
            </li>
            <li>
              {i18n("Click on a paragraph break’s option icon wherever you’d like to create a heading.", "", "outline")}
            </li>
          </ol>
        </Instructions>
      }

      {!inEditingMode && infoByLevels.length === 0 &&
        <Instructions>
          {i18n("[ Outline goes here ]", "", "outline")}
          {!isFrozenEmbed &&
            <ToGetStarted>
              {i18n("Turn on Editing mode to begin.", "", "outline")}
            </ToGetStarted>
          }
        </Instructions>
      }

    </Container>
  )
}

export default memo(OutlineKey)