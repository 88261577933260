import { useContext } from 'react'

import useDataQuery from './useDataQuery'
import { LoggedInUserContext } from '../context/LoggedInUser'

import studyBibleItemsQuery from '../graphql/queries/studyBibleItems'
import useChannelPriorities from './useChannelPriorities'

const useStudyBibleCuratedItems = ({ chapterLoc, skip }) => {

  const { channelPriorities } = useChannelPriorities()

  const { adminLevel } = useContext(LoggedInUserContext) || {}
  const fetchPolicy = [ 'ADMIN', 'EDITOR', 'CONTRIBUTOR' ].includes(adminLevel) ? `cache-and-network` : `cache-first`

  const typeQueryPortion = (
    channelPriorities.some(({ hide }) => hide)
      ? (
        `type:"${
          channelPriorities
            .filter(({ hide, type }) => type !== `CHANNEL` && !hide)
            .map(({ type }) => type)
            .join(` `)
        }"`
      )
      : ``
  )

  const { studyBibleItems: studyBibleItemsAndCount, loading } = useDataQuery({
    studyBibleItemsQuery,
    variables: {
      query: `status:"DRAFT-UNLOCKED DRAFT-LOCKED PUBLISHED" ${typeQueryPortion} ${chapterLoc}`,  // status part is overwritten for non-admins
    },
    skip: skip || !chapterLoc,
    fetchPolicy,
  })

  const { studyBibleItems=[], count=0 } = studyBibleItemsAndCount || {}

  return {
    studyBibleItems,
    count,
    loading,
  }
}

export default useStudyBibleCuratedItems
