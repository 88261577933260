import LegacyPhrase from "../legacy-phrasing/LegacyPhrase"
// import Phrase from "../phrasing/Phrase"
import LegacyDiscourse from "../legacy-discourse/LegacyDiscourse"
import LegacyDiagram from "../legacy-diagramming/Diagram"
import WordStudy from "../word-study/WordStudy"
import Outline from "../outline/Outline"
import Markup from "../markup/Markup"
import SharedNotes from "../notes/SharedNotes"

const moduleComponentsByType = {
  DISCOURSE: LegacyDiscourse,
  PHRASING: LegacyPhrase,
  DIAGRAMMING: LegacyDiagram,
  OUTLINE: Outline,
  MARKUP: Markup,
  NOTES: SharedNotes,
  "WORD-STUDY": WordStudy,
}

export default moduleComponentsByType