import React, { memo, useCallback } from 'react'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import styled from 'styled-components'

import useVersionInfo from '../../hooks/useVersionInfo'
import usePassageRefPopperInfo from '../../hooks/usePassageRefPopperInfo'

import PassageRef from '../common/PassageRef'
import PassagePopperGoRead from './PassagePopperGoRead'

const Container = styled.div`
  display: flex;
  margin: 7px 0;
`

const HeaderText = styled.div`
  font-size: 14px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 16px;
`

const ContextText = styled.span`
  display: inline-block;
  margin-right: 10px;
`

const ContextRef = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.palette.grey[400]};
`

const ContextVersionId = styled.span`
  display: inline-block;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-left: 6px;
`

const StyledIconButton = styled(IconButton)`
  color: white;
  margin: 0 4px 0 7px;
  padding: 8px;

  &:hover {
    background-color: rgb(255 255 255/.1);
  }

  .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
  }
`

const Spacer = styled.div`
  flex: 1;
`

const PassagePopperPagerHeader = ({
  popperInfoStack,
  setPopperInfoStack,
  versionId,
  contextText,
  contextRef,
  contextRefs,
  closePopper,
  closeAndClearSearch,
  refSets,
  isChapterContext,
  isVs,
}) => {

  const { safeVersionAbbr, version } = useVersionInfo(versionId)
  const { specialMarkup } = usePassageRefPopperInfo({ contextRefs, version })

  const onClick = useCallback(
    () => {
      setPopperInfoStack(popperInfoStack.slice(0, -1))
    },
    [ setPopperInfoStack, popperInfoStack ],
  )

  return (
    <Container>

      <StyledIconButton
        onClick={onClick}
      >
        <ArrowBackIcon />
      </StyledIconButton>

      <HeaderText>

        {!!contextText &&
          <ContextText>
            {contextText}
          </ContextText>
        }

        <ContextRef>
          <PassageRef
            refs={contextRefs || [ contextRef ]}
            abbreviated={!!contextText}
            versionId={versionId}
          />
        </ContextRef>

        {!isVs &&
          <ContextVersionId>
            {safeVersionAbbr}
          </ContextVersionId>
        }

      </HeaderText>

      <Spacer />

      {isChapterContext &&
        <PassagePopperGoRead
          refs={refSets[0]}
          versionId={versionId}
          closePopper={closePopper}
          closeAndClearSearch={closeAndClearSearch}
          specialMarkup={specialMarkup}
        />
      }

    </Container>
  )
}

export default memo(PassagePopperPagerHeader)