import { memo } from 'react'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

const StyledAccordion = styled(Accordion)`
  box-shadow: none;

  &::before {
    content: none;
  }
  &.Mui-expanded {
    margin: 0;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 36px;
  padding: 0 14px;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 12px;

  &.Mui-expanded {
    background: white;
    min-height: 36px;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded {
    margin: 6px 0;
  }

  .MuiAccordionSummary-expandIcon {
    transform: rotate(135deg);
    padding: 0;
    margin: 0 -5px;
  }
  .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
    height: 15px;
  }

  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(180deg);
  }

  @media (hover: hover) {
    &:hover .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
      color: black;
    }
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`

const ParallelAccordion = ({
  summary,
  details,
  ...otherProps
}) => (
  <StyledAccordion
    square
    {...otherProps}
  >
    <StyledAccordionSummary
      expandIcon={<CloseIcon />}
    >
      {summary}
    </StyledAccordionSummary>
    <StyledAccordionDetails>
      {details}
    </StyledAccordionDetails>
  </StyledAccordion>
)

export default memo(ParallelAccordion)