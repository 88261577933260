import { gql } from '@apollo/client'
import myTagSet_fields from '../fragments/myTagSet_fields'

const myTagSetSubmissions = gql`
  query myTagSetSubmissions($bookId: Int!, $chapter: Int!, $versionId: ID!) {
    myTagSetSubmissions(bookId: $bookId, chapter: $chapter, versionId: $versionId) {
      ${myTagSet_fields}
    }
  }
`

export default myTagSetSubmissions