import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import List from '@material-ui/core/List'

import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import useInstanceValue from '../../../hooks/useInstanceValue'

import CustomSlider from '../../common/CustomSlider'
import CustomSwitch from '../../common/CustomSwitch'
import CustomCheckbox from '../../common/CustomCheckbox'
import SubtleDivider from '../../common/SubtleDivider'
import { cloneObj } from '../../../utils/misc'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 15px 2px;
`

const StyledCustomSlider = styled(CustomSlider)`
  background-color: white;
  padding: 10px;
  margin: 5px -10px -10px -10px;
  border-radius: 10px;
`

const StyledSubtleDivider = styled(SubtleDivider)`
  margin: 0 -15px;
`

const LegacyDiscourseSettings = ({
  projectId,
  legacySetting,
  displayedLegacySetting,
  setDisplayedLegacySetting,
  versionIds,
}) => {

  const getDisplayedLegacySetting = useInstanceValue(displayedLegacySetting)

  const firstDisplayedTextSize = (displayedLegacySetting.settings || {})[`textsize-${versionIds[0]}`] || 100

  const getLegacySetting = useInstanceValue(legacySetting)
  const [ goSetLegacySetting ] = useGoSetModuleSetting({
    moduleSetting: legacySetting,
    projectId,
  })

  const updateBaseTextSize = useCallback(
    (event, value) => {
      const displayedLegacySetting = getDisplayedLegacySetting()
      const newDisplayedLegacySetting = cloneObj(displayedLegacySetting)
      newDisplayedLegacySetting.settings = newDisplayedLegacySetting.settings || {}
      for(let versionId of versionIds) {
        newDisplayedLegacySetting.settings[`textsize-${versionId}`] = value
      }
      newDisplayedLegacySetting.settings.inTextResize = true
      setDisplayedLegacySetting(newDisplayedLegacySetting)
    },
    [ getDisplayedLegacySetting, setDisplayedLegacySetting, versionIds ],
  )

  const commitBaseTextSizesChange = useCallback(
    () => {
      const value = getDisplayedLegacySetting()
      delete value.settings.inTextResize
      goSetLegacySetting({
        value,
      })
    },
    [ goSetLegacySetting, getDisplayedLegacySetting ],
  )

  const getToggle = useCallback(
    setting => ({ target }) => {
      const currentLegacySettingValue = getLegacySetting().value
      goSetLegacySetting({
        value: {
          ...currentLegacySettingValue,
          settings: {
            ...(currentLegacySettingValue.settings || {}),
            goToggle: {
              [setting]: target.checked,
            },
          },
        },
      })
    },
    [ getLegacySetting, goSetLegacySetting ],
  )

  const toggleShowArc = useCallback(getToggle(`showarc`), [ getToggle ])  // eslint-disable-line react-hooks/exhaustive-deps
  const toggleShowBracket = useCallback(getToggle(`showbracket`), [ getToggle ])  // eslint-disable-line react-hooks/exhaustive-deps
  const toggleShowMPIndicator = useCallback(getToggle(`showmp`), [ getToggle ])  // eslint-disable-line react-hooks/exhaustive-deps
  const toggleColorCodeArcs = useCallback(getToggle(`colorarcs`), [ getToggle ])  // eslint-disable-line react-hooks/exhaustive-deps
  const toggleInnerBracketLines = useCallback(getToggle(`showinnerlines`), [ getToggle ])  // eslint-disable-line react-hooks/exhaustive-deps
  const toggleStraightLineToMP = useCallback(getToggle(`shiftbrackets`), [ getToggle ])  // eslint-disable-line react-hooks/exhaustive-deps
  const toggleExtraRelationships = useCallback(getToggle(`showextended`), [ getToggle ])  // eslint-disable-line react-hooks/exhaustive-deps

  const { cols=[], showmp, showinnerlines, colorarcs, shiftbrackets, showextended } = legacySetting.value.settings || {}

  return (
    <Container>

      <StyledCustomSlider
        label={i18n("Text Size", "", "discourse")}
        min={50}
        max={200}
        color="secondary"
        value={firstDisplayedTextSize}
        onChange={updateBaseTextSize}
        onChangeCommitted={commitBaseTextSizesChange}
        className="module-settings-textsizes"
      />

      <List>

        <CustomCheckbox
          checked={(cols.slice(-1)[0] || [])[0] === 'a'}
          onChange={toggleShowArc}
          label={i18n("Show arcs", "", "discourse")}
        />

        <CustomCheckbox
          checked={(cols[0] || [])[0] === 'b'}
          onChange={toggleShowBracket}
          label={i18n("Show brackets", "", "discourse")}
        />

      </List>

      <StyledSubtleDivider />

      <List>

        <CustomCheckbox
          checked={!!showmp}
          onChange={toggleShowMPIndicator}
          label={i18n("Show main point indicator", "", "discourse")}
        />

        <CustomCheckbox
          checked={!!showinnerlines}
          onChange={toggleInnerBracketLines}
          label={i18n("Show inner-bracket lines", "", "discourse")}
        />

        <CustomSwitch
          checked={!!colorarcs}
          onChange={toggleColorCodeArcs}
          label={i18n("Color-code the arcs/brackets", "", "discourse")}
        />

        <CustomSwitch
          checked={!!shiftbrackets}
          onChange={toggleStraightLineToMP}
          label={i18n("Straight line to main point", "", "discourse")}
        />

      </List>

      <StyledSubtleDivider />

      <List>

        <CustomSwitch
          checked={!!showextended}
          onChange={toggleExtraRelationships}
          label={i18n("Include extended relationships", "", "discourse")}
        />

      </List>

    </Container>
  )
}

export default memo(LegacyDiscourseSettings)