import { useCallback } from 'react'
import { useMutation, useApolloClient } from '@apollo/client'

import useMutationContext from './useMutationContext'
import useInstanceValue from './useInstanceValue'
import { cloneObj } from '../utils/misc'

import setAccountSettingsMutation from '../graphql/mutations/setAccountSettings'
import accountSettingQuery from '../graphql/queries/accountSetting'

const useGoSetAccountSetting = accountSettingId => {

  const client = useApolloClient()
  const context = useMutationContext()
  const getContext = useInstanceValue(context)

  const [ setAccountSettings, setAccountSettingsResult ] = useMutation(setAccountSettingsMutation)

  const goSetAccountSetting = useCallback(
    newValue => {

      const newData = {
        id: accountSettingId,
        value: newValue,
      }

      const variables = {
        input: {
          accountSettings: [ cloneObj(newData) ],
        },
      }

      const expectedResponseData = [{
        __typename: `AccountSetting`,
        ...cloneObj(newData),
      }]

      setAccountSettings({
        variables,
        context: {
          ...getContext(),
          expectedResponse: {
            setAccountSettings: expectedResponseData,
          },
        },
      })

      client.writeQuery({
        query: accountSettingQuery,
        variables: {
          id: accountSettingId,
        },
        data: {
          accountSetting: expectedResponseData[0],
        },
      })

      // onUpdate && onUpdate({ newData: cloneObj(newData) })

      return cloneObj(newData)
    },
    [ client, setAccountSettings, getContext, accountSettingId ],
  )

  if(setAccountSettingsResult.error) {
    // Nothing to do here since it has gone into queuedMutations and will try again when relevant
    console.error('setAccountSettingsResult.error', setAccountSettingsResult.error)
  }

  return goSetAccountSetting
}

export default useGoSetAccountSetting