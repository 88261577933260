import { memo } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'

const Container = styled.div`
`

const Name = styled.div`
  font-weight: 500;
  font-size: 1.1em;
`

const Also = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const AlsoName = styled.span`
  display: inline-block;
  font-weight: 500;
  color: black;
`

const Meaning = styled.div`
  color: ${({ theme }) => theme.palette.grey[700]};
`

const NameNotes = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const BibleMapInfoName = ({
  isPrimary,
  skipLabel,
  name,
  meaning,
  language,
  notes,
}) => {

  const showMeaning = !!meaning && !!language

  if(skipLabel && !showMeaning && !notes) return null

  return (
    <Container>

      {!skipLabel && isPrimary &&
        <Name>
          {name}
        </Name>
      }

      {!skipLabel && !isPrimary &&
        <Also>
          {i18nReact("Also: {{extra}}", {
            extra: (
              <AlsoName>
                {name}
              </AlsoName>
            ),
          })}
        </Also>
      }

      {showMeaning &&
        <Meaning>
          {i18n("“{{meaning}}” in {{language}}", {
            meaning: meaning,
            language: (
              {
                heb: i18n("Hebrew"),
                arc: i18n("Aramaic"),
                grc: i18n("Greek"),
              }[language] || `?`
            ),
          })}
        </Meaning>
      }

      {!!notes &&
        <NameNotes>
          {notes}
        </NameNotes>
      }

    </Container>
  )
}

export default memo(BibleMapInfoName)