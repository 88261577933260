import { escapeRegex, getIsEntirelyPrefixAndSuffix, getMorphInfo } from "@bibletags/bibletags-ui-helper"
import { i18n } from "inline-i18n"

import useMemoAsync from "./useMemoAsync"
import { cloneObj, equalObjs } from "../utils/misc"
import { getNakedStrongs } from "./useDefinition"
import { getHebrewPrefixDefinition, getHebrewSuffixNakedStrongs } from "../utils/hebrewPrefixAndSuffixInfo"

const COLOR_COMBO_COLORS = {
  light: [
    `#f2e50042`,  // yellow
    `#ff00002b`,  // red
    `#7fd2103b`,  // green
    `#002eff24`,  // blue
    `#b400ff21`,  // purple
    `#3fcaba2b`,  // teal
    `#ffab004d`,  // orange
  ],
  dark: [
    `#e5c7122e`,  // yellow  #5c5947 / #e5c7122e
    `#c73a3a4a`,  // maroon  #654444 / #c73a3a4a
    `#3fcaba1a`,  // teal  #40514f / #3fcaba1a
    `#3d55c278`,  // blue  #3f4a7d / #3d55c278
    `#c636d333`,  // purple  #5a445c / #c636d333
    `#4dd21021`,  // green  #3e5434 / #4dd21021
    `#c16a2033`,  // brown  #604c3b / #c16a2033
  ],
}

const defaultInterlinearInfo = {
  searchInfoByTranslationWordNumberInVerse: {},
  phraseAndTranslationPairs: [],
  infoByWordIdAndPartNumber: {},
}

const useInterlinearInfo = ({
  versionId,
  tags,
  translationWords,
  originalWordPieces,
  colorWordPartCombos,
  lightOrDark="dark"
}) => {

  const interlinearInfo = useMemoAsync(
    async () => {

      if(
        !versionId
        || (tags || []).length === 0
        || (translationWords || []).length === 0
        || (originalWordPieces || []).length === 0
      ) return defaultInterlinearInfo

      const originalWordByWordId = {}
      originalWordPieces.forEach(piece => {
        originalWordByWordId[piece[`x-id`]] = piece
      })

      const searchInfoByTranslationWordNumberInVerse = {}
      const phraseAndTranslationPairs = []
      const infoByWordIdAndPartNumber = {}

      const getPhraseColors = pair => ([ ...new Set(((pair || {}).phrase || []).filter(word => word[`x-id`]).map(({ color }) => color)) ])

      let colorComboIdx = 0
      cloneObj(tags).forEach(tag => {

        if(tag.o.length === 0) return
        if(tag.o.some(wordIdAndPartNumber => !originalWordByWordId[wordIdAndPartNumber.split('|')[0]])) return  // tag to old orig data

        const sortedOrigWordParts = tag.o.sort(
          (a,b) => {
            let [ aWordId, aWordPartNumber ] = a.split('|')
            aWordPartNumber = parseInt(aWordPartNumber, 10)
            let [ bWordId, bWordPartNumber ] = b.split('|')
            bWordPartNumber = parseInt(bWordPartNumber, 10)

            return (
              (
                originalWordByWordId[aWordId].wordNumberInVerse < originalWordByWordId[bWordId].wordNumberInVerse
                || (
                  originalWordByWordId[aWordId].wordNumberInVerse === originalWordByWordId[bWordId].wordNumberInVerse
                  && aWordPartNumber < bWordPartNumber
                )
              )
                ? -1
                : 1
            )
          },
        )

        const wordPartByWordIdAndPartNumber = {}
        let lastOrigWordPartEndsInDash = false
        const phrase = (
          sortedOrigWordParts
            .map((wordIdAndPartNumber, idx) => {
              let [ wordId, wordPartNumber ] = wordIdAndPartNumber.split('|')
              wordPartNumber = parseInt(wordPartNumber, 10)

              const phraseWords = [ originalWordByWordId[wordId] ]

              if((phraseWords[0].children || [])[wordPartNumber - 1]) {
                const wholeWord = phraseWords[0]
                phraseWords[0] = phraseWords[0].children[wordPartNumber - 1]
                phraseWords[0][`x-id`] = wholeWord[`x-id`]
                phraseWords[0].wordPartNumber = wordPartNumber
              }
              wordPartByWordIdAndPartNumber[wordIdAndPartNumber] = phraseWords[0]

              // add on front dash, when relevant
              let [ previousWordId, previousWordPartNumber ] = idx > 0 ? sortedOrigWordParts[idx-1].split('|') : []
              previousWordPartNumber = parseInt(previousWordPartNumber, 10)
              if(
                wordPartNumber > 1
                && (
                  idx === 0
                  || previousWordId !== wordId
                  || previousWordPartNumber !== wordPartNumber - 1
                )
                && !lastOrigWordPartEndsInDash
              ) {
                phraseWords.unshift({
                  text: `–`,
                  wordSplitIndicator: true,
                })
              }

              // add on end dash, when relevant
              let [ nextWordId, nextWordPartNumber ] = idx < sortedOrigWordParts.length-1 ? sortedOrigWordParts[idx+1].split('|') : []
              nextWordPartNumber = parseInt(nextWordPartNumber, 10)
              if(
                ![ NaN, (originalWordByWordId[wordId].children || [0]).length ].includes(wordPartNumber)
                && (
                  idx === sortedOrigWordParts.length-1
                  || nextWordId !== wordId
                  || nextWordPartNumber !== wordPartNumber + 1
                )
              ) {
                phraseWords.push({
                  text: `–`,
                  wordSplitIndicator: true,
                })
                lastOrigWordPartEndsInDash = true
              } else {
                lastOrigWordPartEndsInDash = false
              }

              // add in ellipsis or space, when relevant
              if(
                idx > 0
                && previousWordId !== wordId
              ) {
                phraseWords.unshift({
                  text: (
                    (originalWordByWordId[previousWordId] || {}).wordNumberInVerse + 1 === (originalWordByWordId[wordId] || {}).wordNumberInVerse
                      ? ` `
                      : `…`
                  ),
                })
              }

              return phraseWords
            })
            .flat()
        )

        const ellipsis = i18n("…", "placed between nonconsecutive words")
        const spaceEllipsisSpaceRegex = new RegExp(escapeRegex(`${i18n(" ", "word separator")}${ellipsis}${i18n(" ", "word separator")}`), 'g')
        const translation = (
          [ ...tag.t ]
            .sort((a,b) => a-b)
            .map((wordNumberInVerse, idx, sortedT) => {
              const wordsToAdd = [ (translationWords.find(word => word.wordNumberInVerse === wordNumberInVerse) || {}).text ]

              if(
                idx > 0
                && sortedT[idx-1] + 1 !== wordNumberInVerse
              ) {
                wordsToAdd.unshift(ellipsis)
              }

              return wordsToAdd
            })
            .flat()
            .join(i18n(" ", "word separator"))
            .replace(spaceEllipsisSpaceRegex, ellipsis)
        )

        const searchWords = []
        const searchWordsWithPartsById = {}
        sortedOrigWordParts.forEach((wordIdAndPartNumber, idx) => {
          const [ wordId, wordPartNumber ] = wordIdAndPartNumber.split('|')
          const originalWord = originalWordByWordId[wordId]

          let nakedStrongs = getNakedStrongs(originalWord.strong)

          const partIdx = parseInt(wordPartNumber || 1) - 1
          const strongParts = originalWord.strong.split(/:/g)

          if(!searchWordsWithPartsById[originalWord[`x-id`]]) {
            searchWordsWithPartsById[originalWord[`x-id`]] = []
            searchWords.push(searchWordsWithPartsById[originalWord[`x-id`]])
          }
          const isEntirelyPrefixAndSuffix = getIsEntirelyPrefixAndSuffix(originalWord)
          const { morphParts, mainPartIdx } = getMorphInfo(originalWord.morph)
          const isPrefixOrSuffix = isEntirelyPrefixAndSuffix || partIdx !== mainPartIdx
          if(isPrefixOrSuffix) {
            nakedStrongs = (
              (getHebrewPrefixDefinition(strongParts[partIdx]) || {}).nakedStrongs
              || getHebrewSuffixNakedStrongs(morphParts.at(-1))
            )
          }

          if(nakedStrongs) {
            searchWordsWithPartsById[originalWord[`x-id`]].push(`#${nakedStrongs}`)
          }
        })
        const originalSearchStr = searchWords.map(parts => parts.join(``)).join(` `).replace(/  +/g, ` `).trim()

        tag.t.forEach(wordNumberInVerse => {
          searchInfoByTranslationWordNumberInVerse[wordNumberInVerse] = { phrase, translation, originalSearchStr }
        })
        const phraseColors = getPhraseColors({ phrase })
        phraseAndTranslationPairs.push({
          phrase,
          translations: [{
            text: translation,
            ...(phraseColors.length > 1 ? [] : {
              color: phraseColors[0],
            }),
          }],
        })
        const firstWordId = sortedOrigWordParts[0].split('|')[0]
        const comboColor = (
          (
            sortedOrigWordParts.length > 1
            && (
              colorWordPartCombos
              || !(
                // entire word, and only that word, is grouped
                sortedOrigWordParts.every(wordIdAndPartNumber => wordIdAndPartNumber.split('|')[0] === firstWordId)
                && sortedOrigWordParts.length === (originalWordByWordId[firstWordId].children || []).length
              )
            )
          )
            ? COLOR_COMBO_COLORS[lightOrDark][colorComboIdx++ % COLOR_COMBO_COLORS[lightOrDark].length]
            : null
        )
        const translationIdx = (
          (
            sortedOrigWordParts.length === 2
            && /^Gr,EA/.test(wordPartByWordIdAndPartNumber[sortedOrigWordParts[0]].morph)
            && /^Gr,N/.test(wordPartByWordIdAndPartNumber[sortedOrigWordParts[1]].morph)
            && 1
          )
          || (
            sortedOrigWordParts.length > 1
            && sortedOrigWordParts.some(sortedOrigWordPart => !wordPartByWordIdAndPartNumber[sortedOrigWordPart].color)
            && sortedOrigWordParts.findIndex(sortedOrigWordPart => !wordPartByWordIdAndPartNumber[sortedOrigWordPart].color)
          )
          || 0
        )
        sortedOrigWordParts.forEach((wordIdAndPartNumber, idx) => {
          infoByWordIdAndPartNumber[wordIdAndPartNumber] = {
            wordPart: wordPartByWordIdAndPartNumber[wordIdAndPartNumber],
            translation: idx === translationIdx ? translation : ``,
            comboColor,
          }
        })

      })

      // sort by wordId and wordPartNumber
      phraseAndTranslationPairs.sort(
        (a,b) => {
          const aFirstWord = a.phrase.find(word => word[`x-id`]) || {}
          const bFirstWord = b.phrase.find(word => word[`x-id`]) || {}
          const aWordPartNumber = parseInt(aFirstWord.wordPartNumber, 10)
          const bWordPartNumber = parseInt(bFirstWord.wordPartNumber, 10)

          return (
            (
              originalWordByWordId[aFirstWord[`x-id`]].wordNumberInVerse < originalWordByWordId[bFirstWord[`x-id`]].wordNumberInVerse
              || (
                originalWordByWordId[aFirstWord[`x-id`]].wordNumberInVerse === originalWordByWordId[bFirstWord[`x-id`]].wordNumberInVerse
                && aWordPartNumber < bWordPartNumber
              )
            )
              ? -1
              : 1
          )
        },
      )

      // Combine lines that can be combined
      const combinedPhraseAndTranslationPairs = []
      phraseAndTranslationPairs.forEach(({ phrase, translations }) => {
        const lastPhraseAndTranslationPair = combinedPhraseAndTranslationPairs.at(-1)
        const phraseColors = getPhraseColors({ phrase })
        const lastPhraseColors = getPhraseColors(lastPhraseAndTranslationPair)
        const lastTranslationColors = lastPhraseAndTranslationPair && [ ...new Set(lastPhraseAndTranslationPair.translations.map(({ color }) => color)) ]
        const lastWordId = lastPhraseAndTranslationPair && ((lastPhraseAndTranslationPair.phrase.findLast(word => word[`x-id`]) || {})[`x-id`] || ``).split('|')[0]
        const wordId = ((phrase.filter(word => word[`x-id`])[0] || {})[`x-id`] || ``).split('|')[0]
        if(
          phraseColors.length === 1
          && lastPhraseAndTranslationPair
          && equalObjs(lastPhraseColors.sort(), lastTranslationColors.sort())
          && !lastTranslationColors.includes(phraseColors[0])
          && lastWordId
          && lastWordId === wordId
          && lastPhraseAndTranslationPair.phrase.at(-1).wordSplitIndicator
          && phrase[0].wordSplitIndicator
        ) {
          lastPhraseAndTranslationPair.phrase.pop()
          lastPhraseAndTranslationPair.phrase.push(...phrase.slice(1))
          lastPhraseAndTranslationPair.translations.push(...translations)
        } else {
          combinedPhraseAndTranslationPairs.push(cloneObj({ phrase, translations }))
        }
      })

      // Combine single Hebrew words
      combinedPhraseAndTranslationPairs.forEach(phraseAndTranslationPair => {
        const { phrase } = phraseAndTranslationPair
        const phraseWithCombinedWordParts = []
        phrase.forEach((word, idx) => {
          if(
            idx > 0
            && word[`x-id`]
            && phrase[idx-1][`x-id`] === word[`x-id`]
            && phrase[idx-1].wordPartNumber + 1 === word.wordPartNumber
          ) {
            const lastWordIdx = phraseWithCombinedWordParts.length - 1
            let lastWord = phraseWithCombinedWordParts[lastWordIdx]
            if(!lastWord.children) {
              lastWord = phraseWithCombinedWordParts[lastWordIdx] = {
                children: [ lastWord ],
                [`x-id`]: lastWord[`x-id`],
              }
            }
            lastWord.children.push(word)
          } else {
            phraseWithCombinedWordParts.push(word)
          }
        })
        phraseAndTranslationPair.phrase = phraseWithCombinedWordParts
      })

      return {
        searchInfoByTranslationWordNumberInVerse,  // originalSearchStr, phrase, translation
        phraseAndTranslationPairs: combinedPhraseAndTranslationPairs,  // phrase, translations
        infoByWordIdAndPartNumber,  // wordPart, translation, comboColor
      }

    },
    [ versionId, tags, translationWords, originalWordPieces, colorWordPartCombos, lightOrDark ],
    defaultInterlinearInfo,
  )

  return interlinearInfo

}

export default useInterlinearInfo

// EXAMPLES

// searchInfoByTranslationWordNumberInVerse
// {
//   "1": [
//     {
//       originalSearchStr: "#H12332#v #H82883",
//       phrase: [
//         {
//           x-id,
//           text: "ו",
//           color: "red",
//         },
//         {
//           x-id,
//           text: "יבדל",
//         },
//         {
//           text: "…",
//         },
//         {
//           x-id,
//           text: "בין",
//         },
//       ],
//       translation: "and he separated",
//     },
//   ],
//   ...
// }

// phraseAndTranslationPairs
// [
//   {
//      phrase: [
//        {
//          x-id,
//          children: [
//            {
//              text: "ו",
//              color: "red",
//              wordPartNumber: 1,
//            },
//            {
//              text: "יבדל",
//              wordPartNumber: 2,
//            },
//          ],
//        },
//        {
//          text: "…",
//        },
//        {
//          x-id,
//          text: "בין",
//        },
//      ],
//      translations: [
//        {
//          text: "and he separated",
//          color: null,
//        },
//      ],
//   },
//   ...
// ]

// infoByWordIdAndPartNumber
// {
//   "01h7N|2": {
//     wordPart: {
//       text: "ו",
//       color: "red",
//       nakedStrongs,
//       morphPart,
//       x-id,
//     },
//     translation: "and he separated",
//     comboColor: "#88ddaa",
//   },
//   ...
// },