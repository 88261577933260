import { gql } from '@apollo/client'

import studyBibleItemComment_fields from '../fragments/studyBibleItemComment_fields'

const studyBibleItemComments = gql`
  query studyBibleItemComments($query: String, $limit: Int) {
    studyBibleItemComments(query: $query, limit: $limit) {
      studyBibleItemComments {
        ${studyBibleItemComment_fields}
      }
      count
    }
  }
`

export default studyBibleItemComments