import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import { getPrimaryName } from '../../../utils/misc'

import BibleMapPlaceDebuggerRect from './BibleMapPlaceDebuggerRect'

const Container = styled.div`
  position: absolute;
  z-index: 2;
  margin-left: -4px;
  margin-top: -4px;

  ${({ $journeyColor }) => !$journeyColor ? `` : `
    outline: 4px solid ${$journeyColor}bf;
  `}
`

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: ${({ $selected, theme }) => $selected ? theme.palette.mapColors.selectedPlace : `black`};
  border-radius: 100px;
`

const Label = styled.div`
  position: absolute;
  top: -19px;
  left: 3px;
  white-space: nowrap;
  line-height: 16px;
  text-shadow: 1px 1px 0 rgb(255 255 255/.3);

  ${({ $hideLabel }) => !$hideLabel ? `` : `
    display: none;
  `}
`

export const getApproxRect = ({ names }) => ({
  x: -12,
  y: -28,
  width: names[0].name.length * 6.3 + 25,
  height: 40,
})

const BibleMapPlaceSpot = ({
  place,
  selectThisPlace,
  getStyle,
  missingKeyData,
  ...otherProps
}) => {

  const style = getStyle(place)
  let hideLabel = false

  if(style.opacity === 0 && place.alwaysShowDot) {
    style.opacity = place.opacity || 1
    style.pointerEvents = `auto`
    style.cursor = `pointer`
    hideLabel = true
  }

  return (
    <>
      <Container
        onClick={selectThisPlace}
        style={style}
        {...otherProps}
        $journeyColor={place.journeyColor}
      >
        <Dot
          {...otherProps}
        />
        <Label
          $hideLabel={hideLabel}
        >
          {getPrimaryName(place)}
        </Label>
      </Container>
      <BibleMapPlaceDebuggerRect
        style={style}
        approxRect={getApproxRect(place)}
        missingKeyData={missingKeyData}
      />
    </>
  )
}

export default memo(BibleMapPlaceSpot)