import { memo, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'

import useDataQuery from '../../../hooks/useDataQuery'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { getModuleTypeLabel } from '../../../utils/misc'

import PassageRef from "../../common/PassageRef"
import Avatar from '../../common/Avatar'

import userQuery from '../../../graphql/queries/user'
import useAppSize from '../../../hooks/useAppSize'

const Container = styled.span`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: ${({ $mobileSize }) => $mobileSize ? `column` : `row`};
  margin-right: .17em;
  text-align: left;
  line-height: 1.2;
`

const Title = styled.div`
  white-space: ${({ $forPdf }) => $forPdf ? `normal` : `nowrap`};
  text-overflow: ellipsis;
  font-weight: 500;
  overflow: hidden;
`

const InlineAuthor = styled.span`
  font-weight: 300;
  font-size: .89em;
  margin-left: .5em;
`

const Author = styled.div`
  font-weight: 300;
  font-size: .89em;
  margin-left: .56em;
  display: flex;
  align-items: center;
`

const MobileAuthor = styled(Author)`
  font-size: .61em;
  margin-left: 0;
`

const AuthorName = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const StyledAvatar = styled(Avatar)`
  margin: -2em .25em -2em .4em;
`

const TitleAndAuthorForHeader = ({
  module,
  forPdf,
  ...otherProps
}) => {

  const loggedInUser = useContext(LoggedInUserContext)
  const myWork = module.userId === (loggedInUser || {}).id

  let { user } = useDataQuery({
    userQuery,
    skip: !module.userId || myWork,
    variables: {
      id: module.userId,
    },
  })

  if(myWork) {
    user = loggedInUser
  }

  let { mobileSize } = useAppSize()
  mobileSize = mobileSize && !forPdf

  const { modulePassages=[] } = module
  const { fromLoc, toLoc, info={} } = modulePassages[0] || {}
  const { versionId } = info

  let titleOverride

  if(module.type === `NOTES`) {
    titleOverride = module.label
  }

  return (
    <Container
      {...otherProps}
      $mobileSize={mobileSize}
    >

      <Title $forPdf={forPdf}>

        {
          titleOverride
          || (
            i18nReact("{{module_type}} of {{passages}}", {
              module_type: getModuleTypeLabel(module),
              passages: (
                <PassageRef
                  fromLoc={fromLoc}
                  toLoc={toLoc}
                  convertToVersionId={versionId}
                  abbreviated={mobileSize}
                />
              ),
            })
          )
        }

        {!mobileSize && forPdf &&
          <InlineAuthor>
            {i18n("by")}
            {` `}
            {(user || {}).name}
          </InlineAuthor>
        }

      </Title>

      {!mobileSize && !forPdf &&
        <Author>
          {i18n("by")}
          <StyledAvatar
            size="mini"
            user={user}
          />
          <AuthorName>
            {(user || {}).name}
          </AuthorName>
        </Author>
      }

      {mobileSize &&
        <MobileAuthor>
          {i18n("By")}
          <StyledAvatar
            size="tiny"
            user={user}
          />
          <AuthorName>
            {(user || {}).name}
          </AuthorName>
        </MobileAuthor>
      }

    </Container>
  )
}


export default memo(TitleAndAuthorForHeader)