import { memo } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import SearchIcon from '@material-ui/icons/Search'

import useModalAnchor from "../../hooks/useModalAnchor"
import useEqualObjsMemo from '../../hooks/useEqualObjsMemo'

import SearchSuggestionMenuItems from './SearchSuggestionMenuItems'

const Spacer = styled.div`
  flex: 1;
`

const StyledSearchSuggestionMenuItems = styled(SearchSuggestionMenuItems)`
  .SearchSuggestionSearchWord {
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-color: ${({ theme }) => theme.palette.divider};
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin: -10px -8px -10px 0;
  color: white;
  align-self: center;

  &:hover {
    background-color: rgb(255 255 255/.1);
  }

  .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
  }
`
const OriginalLanguageWordSearchMenu = ({
  strong,
  morph,
  form,
  lemma,
  bookId,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const words = useEqualObjsMemo([{
    strong,
    morph,
    form,
    lemma,
  }])

  return (
    <>

      <Spacer />

      <StyledIconButton
        className="OriginalLanguageWordSearchMenu-IconButton"
        onClick={openModal}
      >
        <SearchIcon />
      </StyledIconButton>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeModal}
      >
        <StyledSearchSuggestionMenuItems
          words={words}
          bookId={bookId}        
          onClose={closeModal}
          tabIndex={-1}  // need to do this because of <Menu>
        />
      </Menu>

    </>
  )
}

export default memo(OriginalLanguageWordSearchMenu)