import { useCallback } from 'react'

import useInstanceValue from './useInstanceValue'
import useGoUpdateModulePieces from './useGoUpdateModulePieces'

const useSetUpPiecesAfterPassageChangeOutlining = ({
  projectId,
  moduleId,
  modulePieces,
}) => {


  const [ goUpdateModulePieces, goDeleteModulePieces ] = useGoUpdateModulePieces({ projectId, moduleId })

  const getModulePieces = useInstanceValue(modulePieces)

  const setUpPiecesAfterPassageChange = useCallback(
    ({ newModulePassage, updatedModulePassage, savedAt }) => {

      if(newModulePassage) {

        const { fromLoc } = newModulePassage
        goUpdateModulePieces(
          [{
            position: `break`,
            ordering: parseInt(`${fromLoc}001`),
          }],
          null,
          { savedAt },
        )

      } else {
  
        const { fromLoc, toLoc } = updatedModulePassage

        const modulePiecesToDelete = getModulePieces().filter(({ ordering }) => (
          ordering < parseInt(`${fromLoc}000`)
          || ordering > parseInt(`${toLoc}999`)
        ))

        goDeleteModulePieces(modulePiecesToDelete, { savedAt })

      }

    },
    [ goUpdateModulePieces, goDeleteModulePieces, getModulePieces ],
  )

  return setUpPiecesAfterPassageChange

}

export default useSetUpPiecesAfterPassageChangeOutlining