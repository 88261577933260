import React from "react"
import VisibilitySensor from 'react-visibility-sensor'

class RawCode extends React.Component {

  state = {
    hasBeenVisible: false,
  }

  setVisibility = isVisible => {
    const { hasBeenVisible } = this.state
    if(isVisible && !hasBeenVisible) {
      this.setState({ hasBeenVisible: true })
    }
  }

  render() {
    let { code } = this.props
    const { hasBeenVisible } = this.state

    if(!hasBeenVisible) {
      code = code.replace(
        /<iframe((?:[^>]|\n|\r)*?[ \n\r\t])src=(?:"[^"]*"|'[^']*'|[^\t\r\n >]*)((?:[^>]|\n|\r)*)>((?:.|\n|\r)*?)<\/iframe>/gi,
        (match, attr1, attr2, innerHtml) => {
          if(/[ \t\n\r]data-flipeditor-force-initial-load[ \t\n\r>]/.test(match)) return match
          return `<iframe ${attr1}${attr2}>${innerHtml}<\/iframe>`
        }
      )
    }

    return (
      <VisibilitySensor
        active={!hasBeenVisible}
        onChange={this.setVisibility}
        partialVisibility={true}
      >
        <React.Fragment>
          <div dangerouslySetInnerHTML={{
            __html: code,
          }} />
        </React.Fragment>
      </VisibilitySensor>
    )
  }

}

export default RawCode