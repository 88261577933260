// See this spreadsheet for data flow information: https://docs.google.com/spreadsheets/d/1HQTbo3YS0IShtKfuxyfFrklCnmju9V432iQmQH2wSzk/edit?usp=sharing

import Dexie from 'dexie'
import { indexedDB as fakeIndexedDB, IDBKeyRange as fakeIDBKeyRange } from "fake-indexeddb"

import { IS_EMBED } from './constants'
import { addOriginalWordsForSearch } from './misc'

// Not graphql or db
  // Y device settings (local only; Local Storage; always stored when LS available; only fetched on load of app [for home] or project; stored on all changes - with/without an acct, with/without offline turned on; e.g. home info: current passages being viewed, scroll positions, recent searches with scroll positions; also, store most recent passages and searches (up to 10? or time-based?) for recently opened projects (up to 10? or time-based?) to fall back to when offline)
  // _ translation file (use current lang chosen by user; update when there is a new version; only allow user to change app language when online)
  // Y offline status (local only; Local Storage - always grab from LS directly; on/off + desired offline translations + status:fetching/ready, lastMutationSyncedAt, and lastQuerySyncedAt for each db table)

// Offline on (no acct needed)
  // Y usfm for originals (from bibletags; no mutations; sync)
    // Get via graphql, updating on every app load (with minimum time passed since last check)
      // ** updates only ** booksWithUpdates(versionId: ID!): [Int]  -- used to see what need refreshing from CloudFront
      // ** updates only ** definitionWordGroupsWithUpdates(languageId: ID!, updatedSince: Date!): [String]  -- used to see what need refreshing from CloudFront
      // ** updates only ** hitsWordGroupsWithUpdates(updatedSince: Date!): [String]  -- used to see what need refreshing from CloudFront
    // Get from CloudFront
      // book x 66 (includes data for word search tables)
      // definitions (language sets) x ? (~100 word groups organized by strongs; H1-H99, H100-H199c, etc)
      // hits x ? (~100 word groups organized by strongs)
    // Query
      // chapter(bookId: Int!, chapter: Int!, versionId: ID!): [Verse]
      // verse(id: ID!): Verse
      // definition(id: ID!): Definition
      // definitionsByPosition(versionId: ID!, loc: String!, wordNum: Int!, languageId: ID!): [Definition]
      // hits(id: ID!): Hits
      // search(query: String!, offset: Int, limit: Int, tagVersionIds: [String]): SearchResult

  // _ usfm for translations (from data.biblearc.com; desired translations chosen by user; no mutations; sync)
    // Get via graphql, updating on every app load (with minimum time passed since last check)
      // ** updates only ** booksWithUpdates(versionId: ID!): [Int]  -- used to see what need refreshing from CloudFront
    // Get from CloudFront
      // book x 66
    // Query
      // chapter(bookId: Int!, chapter: Int!, versionId: ID!): [Verse]
      // verse(id: ID!): Verse

  // _ tagging data (from bibletags; desired translations chosen by user; no mutations; sync)
    // Get via graphql, updating on every app load (with minimum time passed since last check)
      // versionInfo(id: ID!): VersionInfo
      // ** updates only ** tagSets(bookId: Int!, chapter: Int!, verse: Int, versionId: ID!): [TagSet]
      // ** updates only ** translationWordGroupsWithUpdates(versionId: ID!): [String]  -- used to see what need refreshing from CloudFront
    // Get from CloudFront
      // translations for version x ? (~100 word groups organized by strongs)
      // ** initial download only ** tagSets for version x 66
    // Query
      // tagSets(bookId: Int!, chapter: Int!, verse: Int, versionId: ID!): [TagSet]
      // tagSet(id: ID!): TagSet
      // translations(id: ID!): Translations
      // translationsByPosition(versionId: ID!, loc: String!, wordNum: Int!): [Translations]
      // versionInfo(id: ID!): VersionInfo

// Offline on (with acct)
  // Y account settings (from data.biblearc.com; non-display-oriented settings like sharing preferences, subscription status, etc; modifiable items updateable only when there is an active internet connection; all account settings synced to local db only after successful mutation to server)
    // Get via graphql on every app load and every time internet connection restored (with minimum time passed since last check)
      // accountSettings(ifUpdatedSince: Date): AccountSettings
    // Query** (**following** sync if offline on) every time internet connection restored, and every time the tab comes back in focus (with minimum time passed since last check)
      // accountSettings: AccountSettings
    // Mutation
      // setAccountSettings(input: SetAccountSettingsInput!): AccountSettings  (set 1 or more; all returned)

  // Y projects (from data.biblearc.com; sync)
    // Get via graphql on every app load, every time internet connection restored, and every time the tab comes back in focus (with minimum time passed since last check)
      // projects(ifUpdatedSince: Date): [Project]
      // discourseModules(ifUpdatedSince: Date): DiscourseModuleUpdate  -- i.e. { discourseModules: [DiscourseModule], discourseModulePropositions: [DiscourseModuleProposition] }
      // phrasingModules(ifUpdatedSince: Date): PhrasingModuleUpdate
      // wordStudyModules(ifUpdatedSince: Date): WordStudyModuleUpdate
      // ...
    // Query
      // projects(query: String, order: String, offset: Int, limit: Int): [Project]  -- won't have al the data; just the meta data
      // project(uid: ID): Project
      // discourseModule(uid: ID): DiscourseModule
      // discourseModuleProposition(uid: ID): DiscourseModuleProposition
      // phrasingModule(uid: ID): PhrasingModule
      // ...
      // wordStudyModule(uid: ID): WordStudyModule
      // ...
      // ...
    // Query** currently viewed stuff (**following** sync if offline on) every time internet connection restored, and every time the tab comes back in focus (with minimum time passed since last check)
      // projects(uid: ID, ifUpdatedSince: Date): [Project]
      // discourseModules(uid: ID, ifUpdatedSince: Date): DiscourseModuleUpdate
      // phrasingModules(uid: ID, ifUpdatedSince: Date): PhrasingModuleUpdate
      // wordStudyModules(uid: ID, ifUpdatedSince: Date): WordStudyModuleUpdate
      // ...
    // Mutation
      // updateProject(uid: ID, input: ProjectInput!): Project
      // updateDiscourseModule(uid: ID, includeUpdatedSince: Date, input: DiscourseModuleInput!): DiscourseModuleUpdate  -- main discourse table + proposition table data sent together so it can be in a transactions
      // updatePhrasingModule(uid: ID, includeUpdatedSince: Date, input: PhrasingModuleInput!): PhrasingModuleUpdate
      // updateWordStudyModule(uid: ID, includeUpdatedSince: Date, input: WordStudyModuleInput!): WordStudyModuleUpdate
      // ...

  // Y my highlights (with notes and tags)
    // [TODO: similar to projects]

  // Y my highlights key
    // [TODO: similar to projects]

// graphql, but not available offline (i.e. no db) - no acct needed
  // - shared SEARCH
  // - news (use polling)
  // - learning resources (use polling)
  // - highlights from courses? (use polling)
  // - sermons/articles connected to passages (use polling)
  // - discussions connected to passages (use subscription?)
  
// graphql, but not available offline (i.e. no db) - with acct
  // - alerts (use polling)
  // - my course statuses (use polling)

// QUESTIONS

  // What does querySync do?
    // (Used with offline on)
    // Queries accountSettings, projects, discourseModules, phrasingModules, wordStudyModules, etc with ifUpdatedSince set to lastQuerySyncedAt
    // Updates lastQuerySyncedAt to when the queries were sent
  // What does queryRefresh do?
    // Runs queries under the Query** headings above
  // What does mutationSync do?
    // Looks through all user-specific stuff for items updated after relevant lastMutationSyncedAt
    // Sends out the mutations
    // Updates lastMutationSyncedAt to when the mutations were sent
    // Removes items with _deletedAt

  // What is the syncing lifecycle?
    // What is the online only with connection lifecycle?
      // Simply pass through the queries and mutations (optimistic responses)
      // When the tab is refocussed AND a minimum amount of time has passed, do queryRefresh
    // What is the online only without connection lifecycle?
      // Prevent queries (use hook)
      // Prevent mutations (use hook)
      // Display error when there is an outstanding query/mutation that failed
        // Do not indicate online again until there is a successful ping (with db test query) to the server 
      // Suggest they enable offline access next time they have a connection
      // When coming back online AND a minimum amount of time has passed, do queryRefresh
    // What is the offline setting up with connection lifecycle?
      // [normal online only activity]
      // only start fetching user-specific stuff (projects, account settings) when there are no outstanding mutations
      // lastMutationSyncedAt and lastQuerySyncedAt times set from start of downloads/queries
      // When offline is downloaded, run mutationSync and querySync repeatedly (but prevent infinite loop!) until there is no mutation to sync and nothing comes back from querySync
      // Offline is ready
    // What is the offline setting up without connection lifecycle?
      // [normal online only activity, only without the suggestion of going offline]
    // What is the offline with connection lifecycle?
      // Relevant queries and mutations are caught and piped to the local db
        // If it is a delete, add _deletedAt time
        // For such mutations, fire mutationSync after
      // When the tab is refocussed AND a minimum amount of time has passed...
        // do querySync
        // do queryRefresh
    // What is the offline without connection lifecycle?
      // Relevant queries and mutations are caught and piped to the local db
      // When coming back online AND a minimum amount of time has passed...
        // do mutationSync
        // do querySync
        // do queryRefresh

  // history tables? No.
    // Delete and create new versions - no updates.
    // Then, weed out deleted versions over time.
    // In browser, do updates.


// TODOs
  // Write core backend queries and mutations
    // Update Project and Module updatedAt columns when a subtable is modified (When a module is updated, only update the current project's updatedAt, not that of other projects which also contain that module)
    // seeds file
    // language in session-sync-auth?
  // Set up offline mode and sync
    // https://github.com/dfahlander/Dexie.js/blob/master/samples/full-text-search/FullTextSearch.js
  // Have "Reset app" tell them to remove offline access if their issues persist
  // CONSIDER: Allow multiple users to have offline data on the same device??
    // No.
      // On Chrome, someone can switch between people in the browser.
      // Elsewhere, this is beyond what is needed.
      // STILL, keep userId in the local tables in case I want to add this in the future.



export const db = (
  (/[?&]noindexeddb(?:&|$)/.test(window.location.search) || IS_EMBED)  // do fake if embed so as to not affect the app state
    ? new Dexie('biblearc', { indexedDB: fakeIndexedDB, IDBKeyRange: fakeIDBKeyRange })
    : new Dexie('biblearc')
)

db.version(8).stores({

  /*
    Notes:
      - Use useStickyRefState for initial values in things like homePassageBookmarks, homeSliderSectionOpen
        (items which we need a synchonous initial value for and/or need NOT remain synced across tabs)
  */

  // local-only (use for items which should remain synced across tabs)
  localInfo: `id`,  // id is a key like `recentSearches`, `offlineSetupStatus`, `lastOfflineUpdateInfo`
  noLoginAccountSettings: `id`,  // id is a key like `channel-vid-defaults`, `pinned-study-bibles`, `listening-history`
  offlineVersions: `id, setupStatus, ordering`,  // id is the version abbr; e.g. `esv` (without login, also serves to replace accountSettings/[userId]:selected-versions)
  queuedMutations: `id, createdAt, attemptingAt`,  // attemptingAt set to 0 when there is no current attempt

  // clones from server
  users: `id`,
  // languages: null,  // To drop this store (it was present through v4), I would need to set it to null in all future versions. But there is no harm in leaving it.
  versions: `id, &name, languageId, abbr`,
  accountSettings: `id`,
  alertsItems: `id, [userId+createdAt], title`,  // for now, I am not using this but rather requiring the person be online to get alerts; if I change my mind, this is already here
  // colorKeyItems: null,  // To drop this store (it was present through v5), I would need to set it to null in all future versions. But there is no harm in leaving it.
  formattingKeys: `id, name`,
  highlights: `id, [userId+versionId+fromLoc], [userId+fromLoc], [userId+savedAt]`,
  projects: `id, [userId+name], [userId+createdAt], [userId+savedAt], [userId+modifiedAt], [userId+openedOrModifiedAt]`,
  folders: `id, [userId+folderId+name]`,
  tags: `id, [userId+tag]`,
  moduleByProjects: `id, [projectId+ordering], savedAt`,
  modules: `id, [userId+type], [userId+shared+public+type], [userId+public+type], [userId+createdAt], [userId+savedAt], [userId+modifiedAt], [userId+openedOrModifiedAt]`,
  modulePassages: `id, fromLoc, [moduleId+ordering], [moduleId+savedAt], savedAt`,
  moduleSettings: `id, [moduleId+setting], [moduleId+savedAt], savedAt`,
  modulePieces: `id, [moduleId+position+ordering], [moduleId+ordering+position], [moduleId+savedAt], savedAt`,
  moduleDots: `id, [moduleId+color], [moduleId+savedAt], savedAt`,
  moduleMarkups: `id, [moduleId+container], [moduleId+savedAt], savedAt`,
  projectPassageHistoryItems: `++id, [projectId+createdAt], [projectId+chapterLoc], createdAt`,

})

db.on("ready", async () => {
  // Using "ready" instead of "populate" (which runs on db creation
  // and reset) since offlineVersions might be empty due to
  // an update or other unknown cause. But it should never be empty!

  // set up default offlineVersions
  const offlineVersions = await db.offlineVersions.toArray()

  let ordering = 1
  const getOfflineVersionDefault = id => ({
    id,
    setupStatus: "online-only",
    ordering: ordering++,
  })

  if(
    offlineVersions.length === 0
    || offlineVersions.every(({ ordering }) => ordering === undefined)
  ) {

    await db.offlineVersions.clear()

    await db.offlineVersions.bulkAdd([
      getOfflineVersionDefault("esv"),
      getOfflineVersionDefault("nasb"),
      getOfflineVersionDefault("csb"),
      getOfflineVersionDefault("net2"),
      getOfflineVersionDefault("niv11"),
      getOfflineVersionDefault("kjv"),
      {
        ...getOfflineVersionDefault("original"),
        hide: false,
      },
      {
        ...getOfflineVersionDefault("lxx"),
        hide: false,
      },
    ])

  } else if(!offlineVersions.some(({ id }) => id === `lxx`)) {

    await db.offlineVersions.put({
      ...getOfflineVersionDefault("lxx"),
      hide: false,
    })

  }

  const { value: recentSearches=[] } = await db.localInfo.get(`recentSearches`) || {}
  recentSearches.forEach(({ originalWords={} }) => addOriginalWordsForSearch(originalWords))

})