import { gql } from '@apollo/client'
import version_fields from '../fragments/version_fields'

const versions = gql`
  query versions($query: String, $order: String, $offset: Int, $limit: Int) {
    versions(query: $query, order: $order, offset: $offset, limit: $limit) {
      versions {
        ${version_fields}
      }
      count
    }
  }
`

export default versions