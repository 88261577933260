import useShortcuts from './useShortcuts'

import keyDownArrowLeftRight from '../components/common/downloadImageShortcuts/keyDownArrowLeftRight'
import useSetTimeout from './useSetTimeout'

const keyDownFuncs = {
  keyDownArrowLeft: keyDownArrowLeftRight,
  keyDownArrowRight: keyDownArrowLeftRight,
}

const useDownloadImageShortcuts = params => {

  const [ setUpdateTimeout ] = useSetTimeout()

  useShortcuts({
    keyDownFuncs,
    setUpdateTimeout,
    ...params,
  })

}

export default useDownloadImageShortcuts