import { memo } from 'react'
import styled from 'styled-components'

import PassageRef from '../common/PassageRef'

const Container = styled.div`
  font-weight: 900;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 30px;
`

const StudyBibleItemsPassageRef = ({
  className,
  ...otherProps
}) => {

  return (
    <Container className={className} >
      <PassageRef
        {...otherProps}
        withoutBookName
      />
    </Container>
  )
}

export default memo(StudyBibleItemsPassageRef)