import { memo, useState, useCallback } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  MINIMUM_MEDIUM_WIDTH,
} from '../../../utils/constants'
import useAppSize from '../../../hooks/useAppSize'

import Header from "../../common/Header"
import ProjectPopover from "./ProjectPopover"
import ModuleThumbnails from "./ModuleThumbnails"

const StyledButton = styled(Button)`
  height: 38px;
  text-transform: none;
  font-size: 18px;
  margin: 5px 0;

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
    height: 14px;
    color: black !important;
    padding: 0;
    margin: 0 0 1px 0;
    line-height: 1.2;
    display: flex;
    justify-content: flex-start;
  }
`

const ProjectName = styled.span`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 3px;

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
    font-size: 11px;
  }
`

const Untitled = styled.div`
  font-weight: 100;
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${({ theme }) => theme.palette.grey[600]};

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
    display: none;
  }
`

const ProjectHeader = ({
  project,
  setDeleted,
  nonNotesModuleByProjects,
  ...otherProps
}) => {

  const { mobileSize } = useAppSize()

  const [ showOptions, setShowOptions ] = useState(false)

  const openProjectPopover = useCallback(() => setShowOptions(true), [])
  const closeProjectPopover = useCallback(() => setShowOptions(false), [])

  return (
    <>

      <Header
        projectId={project.id}
        showBibleDisplaySettings={!mobileSize}
        hideAlerts={mobileSize}
        title={project.name || i18n("Untitled")}
        {...otherProps}
      >

        <StyledButton
          onClick={openProjectPopover}
          disabled={mobileSize}
        >

          <ProjectName>
            {project.name || <Untitled>{i18n("Untitled")}</Untitled>}
          </ProjectName>

          <StyledExpandMoreIcon />

        </StyledButton>

        {mobileSize &&
          <ModuleThumbnails
            project={project}
            openProjectPopover={openProjectPopover}
            nonNotesModuleByProjects={nonNotesModuleByProjects}
          />
        }

      </Header>

      <ProjectPopover
        open={showOptions}
        onClose={closeProjectPopover}
        project={project}
        setDeleted={setDeleted}
      />

    </>
  )
}


export default memo(ProjectHeader)