import { gql } from '@apollo/client'

const bibleSearchResults = gql`
  query bibleSearchResults($query: String!, $hebrewOrdering: Boolean!, $offset: Int!, $limit: Int!) {
    bibleSearchResults(query: $query, hebrewOrdering: $hebrewOrdering, offset: $offset, limit: $limit) {
      results {
        originalLoc
        versionResults {
          tagSets {
            id
            tags
            status
          }
          usfm
          versionId
        }
      }
      rowCountByBookId
      hitsByBookId
      otherSuggestedQueries {
        suggestedQuery
        resultCount
      }
    }
  }
`

export default bibleSearchResults