import { gql } from '@apollo/client'

import channelItem_fields from '../fragments/channelItem_fields'

const channelItems = gql`
  query channelItems($channelId: ID, $channelUrl: String, $chapterLoc: String!) {
    channelItems(channelId: $channelId, channelUrl: $channelUrl, chapterLoc: $chapterLoc) {
      ${channelItem_fields}
    }
  }
`

export default channelItems