import { useState, useCallback, useMemo } from "react"
import useInstanceValue from "./useInstanceValue"

const useSimpleToggle = defaultValue => {

  const simpleDefaultValue = !!useMemo(() => typeof defaultValue === `function` ? defaultValue() : defaultValue, [])  // eslint-disable-line react-hooks/exhaustive-deps
  const [ value, setValue ] = useState(simpleDefaultValue)
  const getValue = useInstanceValue(value)
  const toggle = useCallback(({ force }={}) => setValue(typeof force === `boolean` ? force : !getValue()), [ getValue ])

  return [
    value,
    toggle,
  ]
}

export default useSimpleToggle
