import { useCallback } from "react"
import { useHistory } from "react-router-dom"

import { getBsbIsAlreadyInstalledAsPWA, isIOS, preventDefaultEvent } from "../utils/misc"

const useOnInstall = () => {

  const history = useHistory()

  const onInstall = useCallback(
    event => {
      if(!isIOS) {
        const bsbIsAlreadyInstalledAsPWA = getBsbIsAlreadyInstalledAsPWA()
        history.push(`/install${bsbIsAlreadyInstalledAsPWA ? `?bsb_already_installed` : ``}`)
        preventDefaultEvent(event)
      }
    },
    [ history ],
  )

  return onInstall
}

export default useOnInstall