import { memo } from 'react'
import styled from 'styled-components'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { i18n } from 'inline-i18n'

import useFormattingKeys from '../../../hooks/useFormattingKeys'

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  margin: -1px -1px 0 -1px;
  padding: 3px 0;
  background-color: white;
  user-select: none;
`

const HandleContainer = styled.div`
  height: 40px;
  width: 40px;
`

const StyledDragHandleIcon = styled(DragHandleIcon)`
  padding: 10px;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey[500]};

  &:hover {
    color: ${({ theme }) => theme.palette.grey[900]};
    cursor: move;
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 9px;
  
  .MuiSvgIcon-root {
    font-size: 17px;
  }
`

const Name = styled.div`
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
`

const SystemLens = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.palette.grey[700]};
  border-radius: 3px;
  color: white;
  font-size: 10px;
  line-height: 1.4;
  font-weight: 400;
  padding: 0 4px;
  vertical-align: top;
  transform: translateY(2px);
  margin-left: 5px;
`

const MyLens = styled(SystemLens)`
  background: ${({ theme }) => theme.palette.primary.dark};
`

const LensSettingsLensListLine = ({
  formattingKeyId,
  onEdit,
  onRemove,
  dragHandleProps,
}) => {

  const { formattingKeysById } = useFormattingKeys()
  const isCustomizedSystemLens = /^system:/.test(((formattingKeysById[formattingKeyId] || {}).info || {}).sourceId || ``)
  const isMine = !isCustomizedSystemLens && !/^system:/.test(formattingKeyId)

  return (
    <Container>

      <Tooltip
        title={i18n("Drag to reorder")}
      >
        <HandleContainer {...dragHandleProps}>
          <StyledDragHandleIcon />
        </HandleContainer>
      </Tooltip>

      <Name>

        {(formattingKeysById[formattingKeyId] || {}).name || `?`}

        {` `}

        {isMine &&
          <MyLens>
            {i18n("My Lens")}
          </MyLens>
        }

        {isCustomizedSystemLens &&
          <MyLens>
            {i18n("System Lens*")}
          </MyLens>
        }

        {!isMine && !isCustomizedSystemLens &&
          <SystemLens>
            {i18n("System Lens")}
          </SystemLens>
        }

      </Name>

      <Tooltip
        title={
          (isCustomizedSystemLens || isMine)
            ? i18n("Edit or delete")
            : i18n("Customize")
        }
      >
        <StyledIconButton
          onClick={onEdit}
        >
          <EditIcon />
        </StyledIconButton>
      </Tooltip>

      <Tooltip
        title={i18n("Remove from this module")}
      >
        <StyledIconButton
          onClick={onRemove}
        >
          <CloseIcon />
        </StyledIconButton>
      </Tooltip>

    </Container>
  )
}

export default memo(LensSettingsLensListLine)