import { memo } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  padding-top: ${({ $withLines, $lineHeight }) => $withLines ? $lineHeight*.4 : 0}px;
  background-color: white;
  background-image: linear-gradient(135deg, #f7ecde66 0%, #f7ecde 41%, #f7ecde99 60%, #f7ecde44 100%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const LinedContainer = styled.div`
  flex: 1;
  padding: ${({ $withLines, $lineHeight }) => $withLines ? `${$lineHeight*.07}px ${$lineHeight*.65}px ${$lineHeight*.4}px` : `0 ${$lineHeight*.65}px`};
  line-height: ${({ $lineHeight }) => $lineHeight}px;
  font-size: ${({ $lineHeight }) => $lineHeight*.7}px;
  min-width: 0;

  ${({ $withLines, $lineHeight }) => !$withLines ? `` : `

    background-image: repeating-linear-gradient(to bottom, transparent 0px, transparent ${$lineHeight-.5}px, rgb(0 0 0/.1) ${$lineHeight-.5}px, rgb(0 0 0/.1) ${$lineHeight}px);

    .lexical-p, .lexical-ul, .lexical-ol {
      margin: 0;
      line-height: ${$lineHeight}px;
    }

  `};

`

const FoldedCorner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 5;

  &::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: #d9cdbe #fff;
    box-shadow: 1px 2px ${({ $foldSize }) => $foldSize/2}px rgb(0 0 0/.1);

    ${({ $foldCorner, $foldSize }) => !($foldCorner === `top-right`) ? `` : `
      top: 0;
      right: 0;
      border-width: 0 ${$foldSize}px ${$foldSize}px 0;
    `}

    ${({ $foldCorner, $foldSize }) => !($foldCorner === `top-left`) ? `` : `
      top: 0;
      left: 0;
      border-width: 0 0 ${$foldSize}px ${$foldSize}px;
    `}

    ${({ $foldCorner, $foldSize }) => !($foldCorner === `bottom-left`) ? `` : `
      bottom: 0;
      left: 0;
      border-width: ${$foldSize}px 0 0 ${$foldSize}px;
    `}

    ${({ $foldCorner, $foldSize }) => !($foldCorner === `bottom-right`) ? `` : `
      bottom: 0;
      right: 0;
      border-width: ${$foldSize}px ${$foldSize}px 0 0;
    `}
  }

`

const ManilaPaper = ({
  foldCorner=`top-right`,
  foldSize=20,
  lineHeight=20,
  withLines,
  className,
  children,
}) => (
  <Container
    className={className}
    $lineHeight={lineHeight}
    $withLines={withLines}
  >
    <FoldedCorner
      $foldCorner={foldCorner}
      $foldSize={foldSize}
      $lineHeight={lineHeight}
    />
    <LinedContainer
      $lineHeight={lineHeight}
      $withLines={withLines}
    >
      {children}
    </LinedContainer>
  </Container>
)

export default memo(ManilaPaper)