import project_fields from './project_fields'
import moduleByProject_fields from './moduleByProject_fields'
import module_fields from './module_fields'
import modulePassage_fields from './modulePassage_fields'

const fields = `
  ${project_fields}
  moduleByProjects {
    ${moduleByProject_fields}
    module {
      ${module_fields}
      modulePassages {
        ${modulePassage_fields}
      }
    }
  }
`

export default fields