import { gql } from '@apollo/client'

import customStudyBibleItem_fields from '../fragments/customStudyBibleItem_fields'

export default gql
`
  mutation removeReactionFromCustomStudyBibleItem($customStudyBibleItemId: ID!, $type: String!) {
    removeReactionFromCustomStudyBibleItem(customStudyBibleItemId: $customStudyBibleItemId, type: $type) {
      ${customStudyBibleItem_fields}
    }
  }
`