import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

const Container = styled.div`
  padding: 25px 30px 30px;
`

const Endorsement = styled.div`
  position: relative;
  color: rgb(0 0 0/.9);
  display: flex;
  align-items: center;
`

const EndorsementText = styled.div`
  background: rgb(255 255 255/.5);
  padding: 12px 15px;
  border-radius: 10px;
  font-size: 15px;
  font-style: italic;
  position: relative;
  margin: 5px 0;
`

const EndorsementImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: -5px;
  position: relative;
  z-index: 1;
`

const Endorser = styled.div`
  text-align: right;
  margin: 0 15px 15px 85px;
  font-size: 12px;
  font-style: normal;
`

const EndorserTitle = styled.span`
  color: rgb(0 0 0/.4);
`

const HomeEndorsements = () => {

  return (
    <Container>

      <Endorsement>
        <EndorsementImage src="/piper.jpeg" className="dark-mode-exempt" />
        <EndorsementText>
          {i18n("“Arcing...has been the methodological key to all that I have seen in the Bible.”")}
        </EndorsementText>
      </Endorsement>
      <Endorser>
        {i18n("John Piper")}
        <EndorserTitle>
          {i18n(", Founder-Teacher, Desiring God. Chancellor, Bethlehem College & Seminary.")}
        </EndorserTitle>
      </Endorser>

      <Endorsement>
        <EndorsementImage src="/taylor.jpeg" className="dark-mode-exempt" />
        <EndorsementText>
          {i18n("“An outstanding tool for serious study of the Bible.”")}
        </EndorsementText>
      </Endorsement>
      <Endorser>
        {i18n("Justin Taylor")}
        <EndorserTitle>
          {i18n(", Senior Vice President and Publisher for Books at Crossway. Editor.")}
        </EndorserTitle>
      </Endorser>

      <Endorsement>
        <EndorsementImage src="/schreiner.png" className="dark-mode-exempt" />
        <EndorsementText>
          {i18n("“[Biblearc’s Bracketing module] is an invaluable tool for studying the word of God.”")}
        </EndorsementText>
      </Endorsement>
      <Endorser>
        {i18n("Thomas Schreiner")}
        <EndorserTitle>
          {i18n(", Professor of NT Interpretation and Biblical Theology at the Southern Baptist Theological Seminary.")}
        </EndorserTitle>
      </Endorser>

      <Endorsement>
        <EndorsementImage src="/naselli.jpeg" className="dark-mode-exempt" />
        <EndorsementText>
          {i18n("“The best part about knowing the biblical languages is tracing the argument. The main tool we use for this at Bethlehem College & Seminary is Biblearc.”")}
        </EndorsementText>
      </Endorsement>
      <Endorser>
        {i18n("Andy Naselli")}
        <EndorserTitle>
          {i18n(", Associate Professor of Systematic Theology and New Testament at Bethlehem College & Seminary.")}
        </EndorserTitle>
      </Endorser>

    </Container>
  )
}

export default memo(HomeEndorsements)