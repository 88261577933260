import { Validator } from 'jsonschema'

export const placeTypeOptions = [
  "CITY",
  "PROVINCE",
  "KINGDOM",
  "SEA",
  "DESERT",
  "AREA",
  "MOUNTAIN",
  "RIVER",
  "ROAD",
  "HOUSE",
  "GARDEN",
  "SPOT",  // only use when none of the others fit
]

const year = "(?:[0-9]{1,4} BC|AD [0-9]{1,4})"
const mouthAndDay = "(?:[0-9]|1[0-2])/(?:[1-3]?[0-9])"
const yearPlus = `${year}(?: ${mouthAndDay}| \\[${mouthAndDay}\\])?`
const id = "[-a-z0-9]{5}"
export const datePattern = `^${yearPlus}(?: - ${yearPlus})?$`
export const dateRangePattern = `^${yearPlus} - ${yearPlus}$`
export const dateRangesPattern = `^${yearPlus} - ${yearPlus} \\| ${id}(?:\\n${yearPlus} - ${yearPlus} \\| ${id})*$`
export const anglePattern = `^[0-9]{1,3}(?:\\.[0-9]+)?$`
export const categoryPattern = `^[^ ].*[^ ]$`
export const minDisplayScalePattern = `^(?:100|[1-9][0-9]?)$`
export const colorPattern = `^#[0-9a-fA-F]{6}$`

const mapDataIsValid = mapData => {

  const validator = new Validator()

  const id = { type: "string", minLength: 5, maxLength: 5 }
  const idAllowNull = { oneOf: [ id, { type: "null" } ]}
  const about = { type: "string" }
  const imgUrl = { type: "string" }
  const loc = { type: "string", pattern: "^[0-9]{8}$" }
  const levelOfCertainty = { type: "number" }
  const levelOfImportance = { type: "number" }
  const color = { type: "string", pattern: colorPattern }
  const search = { type: "string" }
  const date = { type: "string", pattern: datePattern }

  const names = {
    type: "array",
    items: {
      properties: {
        name: { type: "string", minLength: 1 },
        meaning: { type: "string" },
        language: { type: "string", minLength: 3, maxLength: 3 },
        notes: { type: "string" },
      },
      required: [
        "name",
      ],
    },
    minItems: 1,
  }

  const schema = {
    properties: {
      places: {
        type: "array",
        items: {
          properties: {
            id,
            names,
            levelOfImportance,
            search,
            about,
            imgUrl,
            angle: {
              type: "string",
              pattern: anglePattern,
            },
            dateRange: {
              type: "string",
              pattern: dateRangePattern,
            },
            dateRanges: {
              type: "string",
              pattern: dateRangesPattern,
            },
            minDisplayScale: {
              type: "string",
              pattern: minDisplayScalePattern,
            },
            color,
            locations: {
              type: "array",
              items: {
                properties: {
                  id,
                  x: { type: "number" },
                  y: { type: "number" },
                  borders: { type: "string" },
                  bordersAtStart: { type: "string" },
                  bordersAtEnd: { type: "string" },
                  levelOfCertainty,
                  notes: { type: "string" },
                },
                required: [ "id", "x", "y" ],
              },
              minItems: 1,
            },
            type: {
              enum: placeTypeOptions,
            },
            events: {
              type: "array",
              items: {
                properties: {
                  id,
                  names,
                  category: {
                    type: "string",
                    pattern: categoryPattern,
                  },      
                  levelOfImportance,
                  dates: {
                    type: "array",
                    items: {
                      properties: {
                        date,
                        levelOfCertainty,
                        notes: { type: "string" },
                      },
                      required: [ "date" ],
                    },
                    minItems: 1,
                  },
                  passages: {
                    type: "array",
                    items: {
                      properties: {
                        fromLoc: loc,
                        toLoc: loc,
                      },
                      required: [ "fromLoc" ],
                    },
                  },
                  journeyId: idAllowNull,
                  personIds: {
                    type: "array",
                    items: {
                      type: id,
                    },
                  },
                  about,
                  imgUrl,
                },
                required: [ "id", "names", "dates", "passages", "personIds" ],
              },
            },
          },
          required: [ "id", "names", "locations", "type", "events" ],
        },
      },
      journeys: {
        type: "array",
        items: {
          properties: {
            id,
            names,
            levelOfImportance,
            about,
            color,
          },
          required: [ "id", "names" ],
        },
      },
      persons: {
        type: "array",
        items: {
          properties: {
            id,
            names,
            search,
            levelOfImportance,
            about,
            imgUrl,
          },
          required: [ "id", "names" ],
        },
      },
    },
    required: [ "places", "journeys", "persons" ],
    additionalProperties: false,
  }

  const ifThrow = (condition, errMsg, ...other) => {
    if(condition) {
      console.log(`Invalid map data (#1): ${errMsg}`, ...other)
      throw new Error(`Invalid map data: ${errMsg}`)
    }
  }
  
  const validBySchema = validator.validate(mapData, schema, { allowUnknownAttributes: false })

  ifThrow(!validBySchema.valid, (((validBySchema.errors || [])[0] || {}).message || ``).replace(/match pattern.*$/, `match pattern`), validBySchema)

  const placeIds = mapData.places.map(({ id }) => id)
  const eventIds = mapData.places.map(({ events }) => events.map(({ id }) => id)).flat()
  const journeyIds = mapData.journeys.map(({ id }) => id)
  const personIds = mapData.persons.map(({ id }) => id)

  // no dups
  ;[ placeIds, eventIds, journeyIds, personIds ].forEach(uuids => {
    ifThrow([ ...new Set(uuids) ].length !== uuids.length, `dup uuids`, uuids)
  })

  // all references are valid
  mapData.places.forEach(({ parentPlaceId, events }) => {
    ifThrow(parentPlaceId && !placeIds.includes(parentPlaceId), `invalid parentPlaceId`, parentPlaceId, placeIds)
    events.forEach(({ journeyId, personIds: eventPersonIds }) => {
      ifThrow(journeyId && !journeyIds.includes(journeyId), `invalid journeyId`, journeyId, journeyIds)
      const invalidPersonId = eventPersonIds.find(personId => !personIds.includes(personId))
      ifThrow(invalidPersonId, `invalid personId`, invalidPersonId, eventPersonIds, personIds)
    })
  })

  if(!validBySchema) throw new Error(`Invalid map data (#2): ${validBySchema}`)

}

export default mapDataIsValid