import { memo, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import eras from './eras'
import snapshots from './snapshots'
import { timeBetweenMapDates } from '../../../utils/misc'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useAccountSetting from '../../../hooks/useAccountSetting'
import useStickyRefState from '../../../hooks/useStickyRefState'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'

import CustomCheckbox from '../../common/CustomCheckbox'
import InfoDialog from '../../common/InfoDialog'
import CreateSnapshotButton from './CreateSnapshotButton'
import EditSnapshotsButton, { getOrderedSnapshots } from './EditSnapshotsButton'

const SHOW_LESS_NUM = 10

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    max-width: min(440px, calc(100dvw - 10px));
    max-height: calc(100dvh - 10px);
  }
`

const Container = styled.div`
  position: relative;
  max-height: calc(100dvh - 10px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Header = styled.div`
  height: 48px;
`

const Content = styled.div`
  min-height: 0;
  flex: 1;
  overflow-y: auto;
  padding: 0 15px 15px;
`

const Title = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: none;
  font-size: 18px;
  line-height: 48px;
  max-width: 100%;
  padding: 0 80px 0 56px;
`

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -5px;

  & > div {
    margin: -3px 0;
  }
`

const StyledMapIcon = styled.img`
  position: absolute;
  top: 12px;
  left: 13px;
  width: 24px;
  height: 24px;
`

const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 10px;

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const InfoIconButton = styled(CloseIconButton)`
  right: 43px;
  padding: 10px;
`

const DetailHeading = styled.div`
  margin: 10px 0 7px;
  font-weight: 300;
`

const Snapshots = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 20px;

  @media (max-width: 440px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
`

const Eras = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 20px;
`

const SnapshotButton = styled(Button)`
  text-transform: none;
  position: relative;

  ${({ $isMine, theme }) => !$isMine ? `` : `
    border-color: ${theme.palette.primary.main}99;
    background-color: ${theme.palette.primary.main}1a;

    @media (hover: hover) {
      &:hover {
        border-color: ${theme.palette.primary.main}bb;
        background-color: ${theme.palette.primary.main}3a;
      }
    }
  `}
`

const EraButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.grey[700]};
  color: white;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[800]};
  }

  &&.Mui-disabled {
    color: white;
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

const ShowAllLessContainer = styled.div`
  display: flex;
  margin: -15px 0 15px;
`

const ShowAllLess = styled.div`
  font-size: 14px;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  transition: opacity .15s ease-in-out;

  @media (hover: hover) {
    &:hover {
      opacity: .5;
    }
  }
`

const BibleMapPopover = ({
  onClose,
  project,
  setDeleted,
  eraId,
  setEraId,
  title,
  showJourneys,
  showDistance,
  showJourneyKey,
  setShowJourneys,
  setShowDistance,
  setShowJourneyKey,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  updateMapTimelineForItem,
  dateStr,
  timelineDetailsWidth,
  getMapViewInfo,
  ...otherProps
}) => {

  const [ myMapSnapshots, setMyMapSnapshots ] = useAccountSetting(`my-map-snapshots`, [])
  const [ infoDialogOpen, toggleInfoDialogOpen ] = useSimpleToggle()
  const [ showAll, setShowAll ] = useStickyRefState({ id: `BibleMapPopover:showAll`, defaultValue: false })
  const toggleShowAll = useInstanceValuesCallback(() => setShowAll(!showAll))

  const allSnapshots = useMemo(
    () => (
      getOrderedSnapshots([
        ...snapshots,
        ...myMapSnapshots.map(snapshot => ({ ...snapshot, isMine: true })),
      ])
    ),
    [ myMapSnapshots ],
  )

  const showShowAllLessButton = allSnapshots.length > SHOW_LESS_NUM

  return (
    <>

      <StyledPopover
        {...otherProps}
        disableRestoreFocus
        marginThreshold={5}
        TransitionComponent={Fade}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={{
          left: 5,
          top: 5,
        }}
      >

        <Container
          className="BibleMapPopover-Container"
        >

          <Header>

            <StyledMapIcon
              src="/map.svg"
              className="dark-mode-exempt"
            />

            <Title>
              {title}
            </Title>

            <InfoIconButton
              onClick={toggleInfoDialogOpen}
            >
              <InfoOutlinedIcon />
            </InfoIconButton>

            <CloseIconButton
              className="BibleMapPopover-CloseIconButton"
              onClick={onClose}
            >
              <CloseIcon />
            </CloseIconButton>

          </Header>
          
          <Content>

            <DetailHeading>
              {i18n("Quick Snapshots")}
            </DetailHeading>

            <Snapshots>
              {allSnapshots.slice(0, showAll ? Infinity : SHOW_LESS_NUM).map(snapshot => (
                <SnapshotButton
                  key={snapshot.id}
                  variant="outlined"
                  $isMine={!!snapshot.isMine}
                  size="small"
                  onClick={() => {
                    updateMapTimelineForItem({ snapshot })
                    onClose()
                  }}
                >
                  {snapshot.snapshotLabel || snapshot.getSnapshotLabel()}
                </SnapshotButton>
              ))}
              <CreateSnapshotButton
                myMapSnapshots={myMapSnapshots}
                setMyMapSnapshots={setMyMapSnapshots}
                eraId={eraId}
                dateStr={dateStr}
                timelineDetailsWidth={timelineDetailsWidth}
                getMapViewInfo={getMapViewInfo}
                showJourneys={showJourneys}
                showJourneyKey={showJourneyKey}
                showDistance={showDistance}              
              />
              {myMapSnapshots.length > 0 &&
                <EditSnapshotsButton
                  myMapSnapshots={myMapSnapshots}
                  setMyMapSnapshots={setMyMapSnapshots}
                />
              }
            </Snapshots>

            {showShowAllLessButton &&
              <ShowAllLessContainer>
                <ShowAllLess onClick={toggleShowAll}>
                  {showAll ? i18n("show less") : i18n("show all {{number}} snapshots", { number: allSnapshots.length })}
                </ShowAllLess>
              </ShowAllLessContainer>
            }

            <DetailHeading>
              {i18n("Restrict to an Era")}
            </DetailHeading>

            <Eras
              className="BibleMapPopover-Eras"
            >
              {eras.map(({ id, getEraLabel, timelineStartDate, timelineEndDate }) => (
                <EraButton
                  key={id}
                  variant="contained"
                  size="small"
                  disableElevation
                  onClick={() => {
                    if(
                      timeBetweenMapDates(fromDate, timelineStartDate).totalDays > 0
                      || timeBetweenMapDates(timelineEndDate, fromDate).totalDays > 0
                      || (
                        toDate
                        && (
                          timeBetweenMapDates(toDate, timelineStartDate).totalDays > 0
                          || timeBetweenMapDates(timelineEndDate, toDate).totalDays > 0
                        )
                      )
                    ) {
                      setFromDate(timelineStartDate)
                      setToDate(null)
                    }
                    setEraId(id)
                  }}
                  disabled={id === eraId}
                >
                  {getEraLabel()}
                </EraButton>
              ))}
            </Eras>

            <DetailHeading>
              {i18n("Options")}
            </DetailHeading>

            {/* [coming soon...] Events to Show: All | Political Reigns | Books of the Bible | etc */}

            <Checkboxes>

              <CustomCheckbox
                checked={showJourneys}
                onChange={() => setShowJourneys(!showJourneys)}
                label={i18n("Show journeys")}
              />

              <CustomCheckbox
                checked={showJourneyKey}
                onChange={() => setShowJourneyKey(!showJourneyKey)}
                label={i18n("Show journey key")}
                disabled={!showJourneys}
              />

              <CustomCheckbox
                checked={showDistance}
                onChange={() => setShowDistance(!showDistance)}
                label={i18n("Show distance key")}
              />

            </Checkboxes>

            {/* [coming soon...] Show/hide cities colored by kingdom and province (choosing to show this hides journeys) */}

          </Content>

        </Container>

      </StyledPopover>

      <InfoDialog
        open={infoDialogOpen}
        onOkay={toggleInfoDialogOpen}
        title={i18n("About Map + Timeline")}
        explanation={
          [
            i18n("The purpose of this feature of The Biblearc Study Bible is to help you rightly understand God's Word as it records and references historical events."),
            i18n("It does so by visualizing the places and journeys of the Bible for you in a dynamic way."),
            i18n("The conviction behind the dates and locations chosen is our belief in the absolute veracity of all of Scripture."),
          ].join(` `)
        }
      />

    </>
  )
}

export default memo(BibleMapPopover)