import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import CustomSlider from '../../common/CustomSlider'

const StyledCustomSlider = styled(CustomSlider)`
`

const BibleMapEditorLevelOfImportance = ({
  levelOfImportance,
  onChange,
  disabled,
}) => {

  const goOnChange = useCallback((event, newValue) => onChange({ keys: [ `levelOfImportance` ], newValue }), [ onChange ])

  return (
    <StyledCustomSlider
      label={i18n("Level of Importance")}
      min={1}
      max={10}
      step={1}
      valueLabelDisplay="auto"
      color="secondary"
      value={levelOfImportance}
      disabled={disabled}
      onChange={goOnChange}
    />
  )
}

export default memo(BibleMapEditorLevelOfImportance)