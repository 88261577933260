import { memo, useCallback } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { i18n } from 'inline-i18n'

import {
  THUMBNAILS_WIDTH,
  MINIMUM_MEDIUM_WIDTH,
  IS_EMBED,
} from '../../../utils/constants'
import useAppSize from '../../../hooks/useAppSize'
import useGoUpdateProject from '../../../hooks/useGoUpdateProject'
import useDrawerHeight from '../../../hooks/useDrawerHeight'

import ModuleThumbnail from './ModuleThumbnail'
import NewModuleThumbnail from './NewModuleThumbnail'

const Container = styled.div`
  width: ${THUMBNAILS_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7px;

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
    width: 100%;
    height: 32px;
    padding-right: 4px;
    padding-top: 0;
    margin-left: -1px
  }

  ${({ theme }) => !IS_EMBED ? `` : `
    @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
      height: auto;
      border-bottom: 1px solid ${theme.palette.divider};
    }
  `}

`

const ExistingModuleThumbnails = styled.div`
  padding: 2px 15px 0;
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom}px;
  overflow-y: auto;
  margin-top: 7px;

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
    padding: 0;
    margin: 0;
    height: 40px;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }

  ${!IS_EMBED ? `` : `
    @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
      padding: 7px 10px;
      height: 47px;
    }
  `}

`

const PanelButtons = styled.div`
  display: flex;
  justify-content: center;
`

const PanelButtonDivider = styled.div`
  background-color: ${({ theme }) => theme.palette.divider};
  width: 1px;
  margin: 7px 0;
`

const PanelButton = styled(IconButton)`
  margin: 0 -4px;

  img {
    height: 15px;
  }

  ${({ $selected }) => $selected ? `` : `
    opacity: .3;
    transition: opacity .15s ease-in-out;
    background: none !important;

    &:hover {
      opacity: 1;
    }
  `}
`

const ProjectDetailsIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  margin: 1px 0;
`

const Image = styled.img``

const Spacer = styled.div`
  width: 15px;
`

const ModuleThumbnails = ({
  project,
  showInDoubleStudyMode,
  showNotesInDrawer,
  notesDrawerWillCoverThumbnails,
  openProjectPopover,
  embedSettings,
  embedOverflowHeight,
  nonNotesModuleByProjects,
  embedHideModuleThumbnails,
}) => {

  const { mobileSize, mediumSize } = useAppSize()

  const [ goUpdateProject ] = useGoUpdateProject({ project })

  const { effectiveDrawerHeight } = useDrawerHeight({
    heightPercentage: project.notesHeightPercentage,
    embedOverflowHeight,
  })

  const paddingBottom = (showNotesInDrawer && notesDrawerWillCoverThumbnails) ? effectiveDrawerHeight : 0 

  const toggleInDoubleStudyMode = useCallback(
    () => {
      goUpdateProject({
        inDoubleStudyMode: !showInDoubleStudyMode,
      })
    },
    [ goUpdateProject, showInDoubleStudyMode ],
  )

  if(embedHideModuleThumbnails) return <Spacer />

  return (
    <Container>

      {!mobileSize && nonNotesModuleByProjects.length > 0 &&
        <PanelButtons>
          <PanelButton
            $selected={!showInDoubleStudyMode}
            disabled={mediumSize || !showInDoubleStudyMode}
            onClick={toggleInDoubleStudyMode}
            tabIndex={-1}
          >
            <Image src="/one_panel.svg" draggable={false} />
          </PanelButton>
          <PanelButtonDivider />
          <Tooltip
            title={mediumSize ? i18n("Your screen is not wide enough for double-study mode") : ``}
          >
            <span>
              <PanelButton
                $selected={showInDoubleStudyMode}
                disabled={mediumSize || showInDoubleStudyMode}
                onClick={toggleInDoubleStudyMode}
                tabIndex={-1}
              >
                <Image src="/two_panel.svg" draggable={false} />
              </PanelButton>
            </span>
          </Tooltip>
        </PanelButtons>
      }

      <ExistingModuleThumbnails $paddingBottom={paddingBottom}>

        {nonNotesModuleByProjects.map(moduleByProject => (
          <ModuleThumbnail
            key={moduleByProject.id}
            moduleByProject={moduleByProject}
            project={project}
            goUpdateProject={goUpdateProject}
            showInDoubleStudyMode={showInDoubleStudyMode}
          />
        ))}

        <NewModuleThumbnail
          projectId={project.id}
          goUpdateProject={goUpdateProject}
          showInDoubleStudyMode={showInDoubleStudyMode}
          nonNotesModuleByProjects={nonNotesModuleByProjects}
          embedSettings={embedSettings}
        />

        {mobileSize && !!openProjectPopover &&
          <ProjectDetailsIconButton
            onClick={openProjectPopover}
          >
            <ExpandMoreIcon />
          </ProjectDetailsIconButton>
        }

      </ExistingModuleThumbnails>

    </Container>
  )
}

export default memo(ModuleThumbnails)