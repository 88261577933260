import { useCallback, memo, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import Tooltip from '@material-ui/core/Tooltip'
import { getLocFromRef } from "@bibletags/bibletags-versification"
import { Virtuoso } from 'react-virtuoso'
import { getPassageStr } from "@bibletags/bibletags-ui-helper"

import useStartAndEndVersesByChapter from '../../hooks/useStartAndEndVersesByChapter'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import useRefState from '../../hooks/useRefState'

import VersesChooserChapter from './VersesChooserChapter'

const Container = styled.div`
  flex: 1;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  position: relative;
`

const FooterSpacer = styled.div`
  height: 60px;
`

const StyledTextField = styled(TextField)`
  position: absolute;
  bottom: 7px;
  right: 7px;
  left: 7px;
  background-color: white;
  color: black;
  border-radius: 25px;

  .MuiOutlinedInput-root {
    border-radius: 25px;
    ${({ $invalid, theme }) => !$invalid ? `` : `
      background-color: ${theme.palette.tertiary.main}22;
    `}
  }

  && .MuiOutlinedInput-notchedOutline {
    ${({ $invalid, theme }) => !$invalid ? `` : `
      border-color: ${theme.palette.tertiary.main};
    `}
  }

  input {
    padding: 14px 0 14px 16px;
    font-size: 14px;
    color: black;
  }

  .MuiIconButton-edgeEnd {
    margin-right: -9px;
  }

`

const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme, $invalid }) => theme.palette[$invalid ? `tertiary` : `secondary`].main};
  color: white;
  padding: 6px;
  width: 36px;
  height: 36px;
  font-size: 18px;
  font-weight: bold;

  ${({ disabled, theme }) => !disabled ? `` : `
    &.MuiIconButton-root.Mui-disabled {
      background-color: ${theme.palette.grey[200]};
    }
  `}

  &:hover {
    background-color: ${({ theme, $invalid }) => $invalid ? theme.palette.tertiary.main : theme.palette.secondary.dark};
    cursor: ${({ $invalid }) => $invalid ? `default` : `pointer`};
    color: white;
  }
`

const VersesChooser = ({
  versionId,
  selectedBookId,
  initialSelectionRefs=[],
  updatePassage,
  maxChapterSpan,
  autoSelectEntireBook,
  singleVerse,
}) => {

  const [ currentSelectionRefs, setCurrentSelectionRefs, getCurrentSelectionRefs ] = useRefState([])

  const { startAndEndVersesByChapter, skippedLocs } = useStartAndEndVersesByChapter({
    versionId,
    bookId: selectedBookId,
  })

  useLayoutEffectAsync(
    () => {
      setCurrentSelectionRefs(
        (initialSelectionRefs[0] || {}).bookId === selectedBookId
          ? initialSelectionRefs
          : (
            autoSelectEntireBook
              ? (
                [
                  {
                    bookId: selectedBookId,
                    chapter: 1,
                    verse: startAndEndVersesByChapter[0][0],
                  },
                  {
                    bookId: selectedBookId,
                    chapter: startAndEndVersesByChapter.length,
                    verse: startAndEndVersesByChapter.at(-1)[1],
                  },
                ]
              )
              : []
          )
      )
    },
    [ selectedBookId, setCurrentSelectionRefs ],
  )

  const submit = useCallback(
    () => {
      updatePassage({
        versionId,
        refs: getCurrentSelectionRefs(),
      })
    },
    [ updatePassage, versionId, getCurrentSelectionRefs ],
  )

  const selectChapter = useCallback(
    ({ chapter, shiftKey }) => {
      const currentSelectionRefs = getCurrentSelectionRefs()

      const [ startVerse, endVerse ] = startAndEndVersesByChapter[chapter - 1]
      const refs = [
        {
          bookId: selectedBookId,
          chapter,
          verse: startVerse,
        },
        {
          bookId: selectedBookId,
          chapter,
          verse: endVerse,
        },
      ]

      if(shiftKey && currentSelectionRefs.length === 2) {
        if(currentSelectionRefs[0].chapter < chapter) {
          refs[0] = currentSelectionRefs[0]
        }
        if(currentSelectionRefs[1].chapter > chapter) {
          refs[1] = currentSelectionRefs[1]
        }
      }

      setCurrentSelectionRefs(refs)
    },
    [ selectedBookId, startAndEndVersesByChapter, getCurrentSelectionRefs, setCurrentSelectionRefs ],
  )

  const selectVerse = useCallback(
    ({ chapter, verse, shiftKey }) => {
      const currentSelectionRefs = getCurrentSelectionRefs()

      const newRef = {
        bookId: selectedBookId,
        chapter,
        verse,
      }

      if(
        currentSelectionRefs.length === 0
        || (
          currentSelectionRefs.length === 2
          && !shiftKey
        )
      ) {

        setCurrentSelectionRefs([ newRef ])
        if(singleVerse) submit()
  

      } else if(currentSelectionRefs.length === 1) {

        if(getLocFromRef(newRef) !== getLocFromRef(currentSelectionRefs[0])) {
          const refs = [
            currentSelectionRefs[0],
            newRef,
          ]
          refs.sort((a, b) => getLocFromRef(a) < getLocFromRef(b) ? -1 : 1)
          setCurrentSelectionRefs(refs)
        }

      } else {  // currentSelectionRefs.length === 2

        const refs = [ ...currentSelectionRefs ]
        refs[getLocFromRef(newRef) < getLocFromRef(refs[0]) ? 0 : 1] = newRef
        setCurrentSelectionRefs(refs)

      }

    },
    [ selectedBookId, getCurrentSelectionRefs, singleVerse, submit, setCurrentSelectionRefs ],
  )

  const Chapter = memo(({ index }) => {

    const [ startVerse, endVerse ] = startAndEndVersesByChapter[index]
    const chapter = index + 1

    return (
      <VersesChooserChapter
        key={chapter}
        bookId={selectedBookId}
        chapter={chapter}
        startVerse={startVerse}
        endVerse={endVerse}
        skippedLocs={skippedLocs}
        currentSelectionRefs={currentSelectionRefs}
        selectChapter={singleVerse ? null : selectChapter}
        selectVerse={selectVerse}
      />
    )
  })

  const invalid = !!(
    maxChapterSpan
    && currentSelectionRefs.length === 2
    && currentSelectionRefs[1].chapter - currentSelectionRefs[0].chapter >= maxChapterSpan
  )
  const disabled = currentSelectionRefs.length < 1 || invalid
  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <Tooltip
            disableFocusListener
            title={
              invalid
                ? (
                  i18n("Cannot span more than {{number_of_chapters}} chapter(s).", {
                    number_of_chapters: maxChapterSpan,
                  })
                )
                : ``
            }
          >
            <StyledIconButton
              onClick={invalid ? null : submit}
              edge="end"
              disabled={disabled && !invalid}
              $invalid={invalid}
            >
              {invalid ? `!` : <CheckIcon />}
            </StyledIconButton>
          </Tooltip>
        </InputAdornment>
      ),
    }),
    [ disabled, invalid, submit, maxChapterSpan ],
  )

  const textFieldValue = (
    selectedBookId
      ? (
        getPassageStr({
          refs: (
            currentSelectionRefs.length > 0
              ? currentSelectionRefs
              : [{
                bookId: selectedBookId,
              }]
          ),
        })
      )
      : ``
  )

  return (
    <Container>

      <Virtuoso
        key={selectedBookId}
        totalCount={startAndEndVersesByChapter.length}
        itemContent={index => <Chapter index={index} />}
        components={{
          Footer: () => <FooterSpacer />,
        }}
        tabIndex={-1}
      />

      <StyledTextField
        // placeholder={i18n("Type a passage")}
        variant="outlined"
        InputProps={inputProps}
        value={textFieldValue}
        disabled={true}
        // onChange={onChange}
        $invalid={invalid}
      />

    </Container>
  )
}

export default memo(VersesChooser)