import { memo } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 15px;
  margin: 8px 0 5px;

`

const OtherSearchResultsHeader = ({
  children,
}) => {

  return (
    <Container>
      {children}
    </Container>
  )
}

export default memo(OtherSearchResultsHeader)