import { useState, useCallback } from "react"
import { useHistory } from 'react-router-dom'

import useInstanceValue from './useInstanceValue'
import useEffectAsync from "./useEffectAsync"

const useOptionsPopperInfo = ({
  selectText,
  skip,
  ...otherProps  // Pass through to selectText. e.g. mode, inEditingMode, embedFullscreen
}) => {

  const [ optionsPopperInfo, setOptionsPopperInfo ] = useState({})
  const getOptionsPopperInfo = useInstanceValue(optionsPopperInfo)

  const history = useHistory()

  const closeOptions = useCallback(
    () => {
      setOptionsPopperInfo({
        ...getOptionsPopperInfo(),
        open: false,
      })
    },
    [ getOptionsPopperInfo ],
  )

  useEffectAsync(
    () => {
      if(skip) return

      selectText({
        ...otherProps,
        closeOptions,
        getOptionsPopperInfo,
        setOptionsPopperInfo,
        history,
      })

    },
    [ skip, selectText, ...Object.keys(otherProps), ...Object.values(otherProps) ],
  )

  return {
    optionsPopperInfo,
    getOptionsPopperInfo,
    setOptionsPopperInfo,
    closeOptions,
  }
}

export default useOptionsPopperInfo



