import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation permanentlyDeleteProject($id: ID!, $updatedSince: Milliseconds!) {
    permanentlyDeleteProject(id: $id, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`