import { gql } from '@apollo/client'
import tagSet_fields from '../fragments/tagSet_fields'

const tagSet = gql`
  query tagSet($id: ID!) {
    tagSet(id: $id) {
      ${tagSet_fields}
    }
  }
`

export default tagSet