export const BORDER_COLOR = `#ddd`
export const DEFAULT_ACTIVE_COLOR = `#2096f3`
export const INACTIVE_COLOR = `#ddd`
export const LINK_FLOATER_WIDTH = 320
export const BASE_TOOLBAR_HEIGHT = 48
export const BASE_TOOLBAR_HEIGHT_SMALL = 36

export const getToolbarHeight = ({ $toolbarSize }) => $toolbarSize === `small` ? `${BASE_TOOLBAR_HEIGHT_SMALL}px` : `${BASE_TOOLBAR_HEIGHT}px`
export const getToolbarHeightWithBorder = ({ $toolbarSize }) => $toolbarSize === `small` ? `${BASE_TOOLBAR_HEIGHT_SMALL + 2}px` : `${BASE_TOOLBAR_HEIGHT + 2}px`
export const getToolbarSectionMinimumWidth = ({ $toolbarSize }) => $toolbarSize === `small` ? `${BASE_TOOLBAR_HEIGHT_SMALL + 10}px` : `${BASE_TOOLBAR_HEIGHT + 10}px`
export const getToolbarIconFontSize = ({ $toolbarSize }) => $toolbarSize === `small` ? `14px` : `18px`

export const DEFAULT_MODES = [
  'write',
  'insert',
  // 'style',
]