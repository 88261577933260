import { memo, useContext } from 'react'
import { i18n } from 'inline-i18n'
// import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import { NotificationsContext } from '../../context/Notifications'

const NotificationsSubscribeButton = ({
  onClick,
  ...otherProps
}) => {

  const { notificationsStatus, updatingNotificationsStatus } = useContext(NotificationsContext)

  return (
    <Button
      {...otherProps}
      color="secondary"
      disableElevation
      variant="contained"
      onClick={onClick}
      disabled={updatingNotificationsStatus || ![ `NONE`, `INACTIVE` ].includes(notificationsStatus)}
    >
      {i18n("Turn on Notifications")}
    </Button>
  )

}

export default memo(NotificationsSubscribeButton)