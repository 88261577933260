import { memo, useCallback } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'

const Container = styled.div`
  margin-bottom: 5px;
  font-size: 16px;
`

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  height: 12px;
  margin: 0 -6px -2px -1px;
`

const AppItemSearchResultsRow = ({
  suggestedQuery,
  action,
  url,
  path,
  doMenuSearchItemAction,
}) => {

  const onClick = useCallback(
    event => {
      if(doMenuSearchItemAction(action)) {
        event.preventDefault()
      }
    },
    [ action, doMenuSearchItemAction ],
  )

  return (
    <Container>

      <NavLinkOrAWithDisable
        exact
        to={url || path || `#`}
        onClick={onClick}
      >
        {suggestedQuery}
      </NavLinkOrAWithDisable>

      {action === 'open-new-tab' && <StyledOpenInNewIcon />}

    </Container>
  )
}

export default memo(AppItemSearchResultsRow)