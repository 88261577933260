/*

Three ways to keep things "sticky":

1. useStickyRefState (localstorage)

  PARTICULARS:
  * device-specific
  - syncronous
  - works offline
  - not oversized
  * no instant sync (across uses throughout code and tabs)
  - works with non-general ids
  - "unlimited" update frequency
  
  USES:
  - initial values
  - in place of useState when you want it sticky
  
2. useAccountSetting (cloud/indexedDB)

  PARTICULARS:
  * account-specific (works with no login as well)
  - asyncronous
  - works offline
  - not oversized
  * instant sync (across uses throughout code and tabs)
  - general ids only
  - limited update frequency

  USES:
  - user customization

3. localInfo (indexedDB)

  PARTICULARS:
  * device-specific
  - pseudo-syncronous
  - works offline
  - can be large
  * instant sync (across uses throughout code and tabs)
  - general ids only
  - "unlimited" update frequency
  
  USES:
  - device customization

*/

import { useCallback, useMemo } from 'react'

import { equalObjs, getLocalStorage, removeLocalStorage, setLocalStorage } from '../utils/misc'
import useRefState from "./useRefState"
import useSetTimeout from './useSetTimeout'
import useInstanceValue from './useInstanceValue'

const useStickyRefState = ({ key, id, defaultValue, doNotRemoveDefault }) => {

  key = key || `useStickyRefState:${id}`

  const [ value, setValue, getValue ] = useRefState()  // eslint-disable-line no-unused-vars
  // do NOT use value since the useMemo is unconventionally setting this ref state on a key update

  const getDefaultValue = useInstanceValue(defaultValue)
  const [ setSaveTimeout ] = useSetTimeout()

  useMemo(
    () => {
      setValue(getLocalStorage(`initialValue:${key}`, defaultValue))
    },
    [ key ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  const clear = useCallback(() => removeLocalStorage(`initialValue:${key}`), [ key ])

  const goSetValue = useCallback(
    value => {
      setValue(value)
      setSaveTimeout(() => {
        if(!doNotRemoveDefault && equalObjs(value, getDefaultValue())) {
          removeLocalStorage(`initialValue:${key}`)
        } else {
          setLocalStorage(`initialValue:${key}`, value)
        }
      }, 50)
    },
    [ key, getDefaultValue, doNotRemoveDefault, setSaveTimeout, setValue ],
  )

  return [ getValue(), goSetValue, getValue, clear ]
}

export default useStickyRefState
