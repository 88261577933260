import { gql } from '@apollo/client'
import formattingKey_fields from '../fragments/formattingKey_fields'

const formattingKeys = gql`
  query formattingKeys {
    formattingKeys {
      ${formattingKey_fields}
    }
  }
`

export default formattingKeys