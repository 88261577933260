import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import { cutOffString, preventDefaultEvent } from '../../utils/misc'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useAppSize from '../../hooks/useAppSize'

import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'
import Editor from '../editor/Editor'
import ManilaPaper from '../common/ManilaPaper'
import PassageRef from '../common/PassageRef'

const Container1 = styled.div`
  position: relative;
`

const Slot = styled.div`
  position: absolute;
  right: -10px;
  top: -15px;
  bottom: -15px;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  z-index: 2;

  ${({ $isRTL }) => !$isRTL ? `` : `
    right: auto;
    left: -20px;
  `}

`

const Container2 = styled.div`
  margin-bottom: 10px;
  transition: transform .25s ease-in-out;
  z-index: 1;

  ${({ $mini, $miniRevealed, $isRTL }) => (!$mini || ($miniRevealed === $isRTL)) ? `` : `
    transform: translateX(-190px);
  `}
`

const TooltipContent = styled.div`
  .lexical-p, .lexical-ul, .lexical-ol {
    margin: 0;
  }
`

const Heading = styled.div`
  padding: 5px 0;
  font-style: italic;
  font-size: 13px;
  position: relative;
  z-index: 3;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};
  pointer-events: ${({ $hide }) => $hide ? `none` : `all`};
`

const MiniHeading = styled(Heading)`
  min-width: 0;
  max-width: 100%;
  padding: 10px 22px 0 0;
  margin-bottom: -5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const BibleText = styled.span`
  margin-right: 4px;
`

const Content = styled(ManilaPaper)`
  width: 208px;
  max-height: ${({ $mini }) => $mini ? 103 : 150}px;
  min-height: ${({ $mini }) => $mini ? 103 : 58}px;
  overflow: hidden;
  transition: background-color .25s ease-in-out;

  @media (hover: hover) {
    &:hover {
      background-color: #ded7cd;
      cursor: pointer;
    }
  }
`

const TooltipBibleText = styled.div`
  font-weight: 300;
  font-style: italic;
  margin-bottom: 5px;
`

const TooltipNote = styled.div`
  font-size: 10.5px;
  font-weight: 400;
  max-height: 50px;
  overflow: hidden;
`

const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 4;
  padding: 10px;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};
  pointer-events: ${({ $hide }) => $hide ? `none` : `all`};

  .MuiSvgIcon-root {
    font-size: 16px;
  }
`

const MiniPassageRefContainer = styled.div`
  position: absolute;
  top: 0;
  left: -103px;
  width: 103px;
  line-height: 1;
  padding: 8px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transform: rotate(270deg);
  transform-origin: top right;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $visible }) => $visible ? 1 : 0};


  ${({ $isRTL }) => !$isRTL ? `` : `
    justify-content: flex-start;
    transform: rotate(90deg);
    transform-origin: top left;
    left: auto;
    right: -103px;
  `}
`

const EditorContainer = styled.div`
  transition: opacity .25s ease-in-out;
  opacity: ${({ $visible }) => $visible ? 1 : 0};
`

const StudyBibleItemNoteThumbnail = ({
  mini,
  isRTL,
  studyBibleItem,
  setItemInfo,
  onClose,
  fromViewOrMoreLink,
}) => {

  const { highlight } = studyBibleItem
  const {
    // __typename,
    id,
    note,
    // shared,
    // userId,
    // user,  // TODO
    info,
    // createdAt,
    savedAt,
    versionId,
    fromLoc,
    toLoc,
  } = highlight

  const { bibleText } = info
  const quotedBibleText = i18n("“{{quote}}”", { quote: cutOffString(bibleText, 65) })

  // const { miniHeaderSize, studyBibleItemPopoverSize } = useAppSize()
  const { miniHeaderSize } = useAppSize()
  const [ miniRevealed, toggleMiniRevealed ] = useSimpleToggle()

  const linkDisabled = true // studyBibleItemPopoverSize
  const noteContentKey = `${id}-${savedAt}`

  const closeMiniRevealed = useCallback(
    event => {
      preventDefaultEvent(event)
      toggleMiniRevealed({ force: false })
    },
    [ toggleMiniRevealed ],
  )

  const open = useCallback(
    event => {
      if(mini && !miniRevealed) {
        preventDefaultEvent(event)
        toggleMiniRevealed()
      } else if(linkDisabled) {
        setItemInfo({
          highlight,
          versionId,
          anchorEl: (mini || fromViewOrMoreLink) ? document.body : event.currentTarget,
          onClose,
        })
        if(miniRevealed) toggleMiniRevealed()
      }
    },
    [ setItemInfo, highlight, versionId, mini, miniRevealed, toggleMiniRevealed, linkDisabled, onClose, fromViewOrMoreLink ],
  )

  return (
    <>

      {!mini &&
        <Heading>
          <BibleText>
            {quotedBibleText}
          </BibleText>
        </Heading>
      }

      <NavLinkOrAWithDisable
        to={`/study-bible-note/${encodeURIComponent(id)}`}
        disabled={linkDisabled}
      >
        <Container1>

          <Container2
            onClick={open}
            $miniRevealed={miniRevealed}
            $isRTL={isRTL}
            $mini={mini}
          >

            <Tooltip
              title={
                (mini && !miniRevealed)
                  ? (
                    <TooltipContent>
                      <TooltipBibleText>
                        {quotedBibleText}
                      </TooltipBibleText>
                      <TooltipNote>
                        <Editor
                          key={noteContentKey}
                          editable={false}
                          initialContent={note}
                        />
                      </TooltipNote>
                    </TooltipContent>
                  )
                  : ``
              }
              placement={isRTL ? `right` : `left`}
            >
              <div>
                <Content
                  onClick={mini ? toggleMiniRevealed : null}
                  foldCorner={mini ? (isRTL ? `bottom-right` : `bottom-left`) : `top-right`}
                  $mini={mini}
                >

                  {mini &&
                    <MiniHeading
                      $hide={!miniRevealed}
                    >
                      <BibleText>
                        {quotedBibleText}
                      </BibleText>
                    </MiniHeading>
                  }

                  <EditorContainer
                    $visible={!mini || miniRevealed}
                  >
                    <Editor
                      key={noteContentKey}
                      editable={false}
                      initialContent={note}
                    />
                  </EditorContainer>

                  {mini &&
                    <MiniPassageRefContainer
                      $visible={mini && !miniRevealed}
                      $isRTL={isRTL}
                    >
                      <PassageRef
                        fromLoc={fromLoc}
                        toLoc={toLoc}
                        versionId={versionId}
                        withoutBookName
                      />
                    </MiniPassageRefContainer>
                  }

                  {mini &&
                    <CloseIconButton
                      onClick={closeMiniRevealed}
                      $hide={!miniRevealed}
                    >
                      <CloseIcon />
                    </CloseIconButton>
                  }

                </Content>
              </div>
            </Tooltip>

          </Container2>

          {mini && !miniHeaderSize && <Slot $isRTL={isRTL} />}

        </Container1>
      </NavLinkOrAWithDisable>

    </>
  )
}

export default memo(StudyBibleItemNoteThumbnail)