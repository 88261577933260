import { gql } from '@apollo/client'

import user_fields from '../fragments/user_fields'

export default gql
`
  mutation deleteStudyBibleFollow($id: ID!) {
    deleteStudyBibleFollow(id: $id) {
      ${user_fields}
    }
  }
`