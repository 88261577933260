import React from "react"
import styled from 'styled-components'

import InnerFlipEditor from '../InnerFlipEditor'

const StyledInnerFlipEditor = styled(InnerFlipEditor)`

  ${({ $framing }) => `

    ${![ 'dividedOff' ].includes($framing) ? `` : `
      padding: 10px 0 10px 20px;
      border-left: 1px solid rgba(0, 0, 0, 0.25);
    `}

    ${![ 'containedSubtle' ].includes($framing) ? `` : `
      padding: 10px 20px;
      background: rgba(0, 0, 0, 0.05);
    `}

    ${![ 'containedEmphasized' ].includes($framing) ? `` : `
      padding: 10px 20px;
      background: rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.25);
    `}

`}

  ${({ hideOnMobile, flipEditorMode }) => (
    !hideOnMobile
      ? ``
      : (
        flipEditorMode === "edit"
          ? `
            @media (max-width: 750px) {
              opacity: .3;
            }
          `
          : `
            @media (max-width: 750px) {
              display: none;
            }
          `
      )
  )}

`

class Sidebar extends React.Component {

  render() {
    const { className, framing, ...innerFlipEditorProps } = this.props

    const classNames = [
      className,
      `${className}-${framing}`,
    ]

    return (
      <StyledInnerFlipEditor
        className={classNames.join(' ')}
        $framing={framing}
        {...innerFlipEditorProps}
      />
    )
  }

}

export default Sidebar