import { useMeasure } from 'react-use'

import useRefState from './useRefState'
import useStickyRefState from './useStickyRefState'
import useComplexInteractions from './useComplexInteractions'
import useMirrorRefs from './useMirrorRefs'
import useAppSize from './useAppSize'
import useSimpleToggle from './useSimpleToggle'
import useInstanceValuesCallback from './useInstanceValuesCallback'
import { useMemo, useRef } from 'react'
import { timeBetweenMapDates, addDaysToDate, getBoundedValue } from '../utils/misc'

export const MINIMUM_DETAILS_WIDTH = 50
export const MAX_DETAILS_WIDTH = 500

const useInteractiveTimeline = ({
  timelineStartDate,
  timelineEndDate,
}) => {

  const [ fromDate, setFromDate, getFromDate ] = useStickyRefState(({ id: `useInteractiveTimeline:fromDate`, defaultValue: `AD 30`}))
  const [ toDate, setToDate, getToDate ] = useStickyRefState(({ id: `useInteractiveTimeline:toDate`, defaultValue: null }))
  let [ detailsWidth, setDetailsWidth, getDetailsWidth ] = useStickyRefState(({ id: `useInteractiveTimeline:detailsWidth`, defaultValue: 0 }))
  const [ preciseModeOpen ] = useRefState(false)
  // const [ preciseModeOpen, setPreciseModeOpen ] = useRefState(false)
  const [ scanning, setScanning ] = useRefState(false)
  const moveStartingPosition = useRef()

  const [ measureRef, { height } ] = useMeasure()
  const ref = useMirrorRefs(measureRef)

  const { width: appWidth } = useAppSize()
  detailsWidth = Math.min(detailsWidth, appWidth - 70)

  const [ handleMenuOpen, toggleHandleMenuOpen ] = useSimpleToggle()

  const timelineTotalDays = useMemo(() => timeBetweenMapDates(timelineStartDate, timelineEndDate).totalDays, [ timelineStartDate, timelineEndDate ])

  const safeSetDetailsWidth = useInstanceValuesCallback(
    detailsWidth => {
      setDetailsWidth(
        detailsWidth < MINIMUM_DETAILS_WIDTH
          ? 0
          : getBoundedValue(detailsWidth, { min: 0, max: MAX_DETAILS_WIDTH })
      )
    }
  )

  const longPressEvents = useComplexInteractions({
    onDoubleClick: event => {
      const isOnHandle = !!event.target.closest(`.BibleMapTimelineHandle-Container`)
      if(isOnHandle) {
        toggleHandleMenuOpen()
      }
    },
    onStartInteraction: event => {
      const isOnHandle = !!event.target.closest(`.BibleMapTimelineHandle-Container`)
      if(!isOnHandle) {
        const { top } = ref.current.getBoundingClientRect()
        const yDiff = event.clientY - (top + 5)
        const precentageDown = yDiff / (height - 10)
        const days = Math.round(timelineTotalDays * precentageDown)
        if(toDate) {
          const halfOfTheDaysBetweenFromAndTo = parseInt(timeBetweenMapDates(fromDate, toDate).totalDays / 2, 10)
          setFromDate(
            addDaysToDate({
              date: timelineStartDate,
              days: days - halfOfTheDaysBetweenFromAndTo,
              minDate: timelineStartDate,
              maxDate: timelineEndDate,
            })
          )
          setToDate(
            addDaysToDate({
              date: timelineStartDate,
              days: days + halfOfTheDaysBetweenFromAndTo,
              minDate: timelineStartDate,
              maxDate: timelineEndDate,
            })
          )
        } else {
          setFromDate(
            addDaysToDate({
              date: timelineStartDate,
              days,
              minDate: timelineStartDate,
              maxDate: timelineEndDate,
            })
          )
        }
      }
      moveStartingPosition.current = {
        clientX: event.clientX,
        clientY: event.clientY,
        fromDate: getFromDate(),
        toDate: getToDate(),
        detailsWidth,
        fromDateAdjustment: !toDate || !!event.target.closest(`.BibleMapTimelineHandle-Label-fromDate`),
        toDateAdjustment: !!event.target.closest(`.BibleMapTimelineHandle-Label-toDate`),
      }
      setScanning(true)
    },
    onInteractionComplete: () => {
      if(toDate && getFromDate().replace(/ \[.*$/, ``) === getToDate().replace(/ \[.*$/, ``)) {
        setToDate(null)
      }
      setScanning(false)
      moveStartingPosition.current = null
      if(getDetailsWidth() > 0 && getDetailsWidth() < MINIMUM_DETAILS_WIDTH) {
        setTimeout(() => setDetailsWidth(0))  // timeout need so the css transition is turned back on
      }
    },
    onMove: event => {
      const xDiff = event.clientX - moveStartingPosition.current.clientX
      const yDiff = event.clientY - moveStartingPosition.current.clientY

      if(!moveStartingPosition.current.axis) {
        if(Math.abs(xDiff) < 2 && Math.abs(yDiff) < 2) return
        moveStartingPosition.current.axis = Math.abs(xDiff) > Math.abs(yDiff) ? `x` : `y`
      }

      if(moveStartingPosition.current.axis === `x`) {

        setDetailsWidth(
          getBoundedValue(
            moveStartingPosition.current.detailsWidth - xDiff,
            {
              min: 0,
              max: MAX_DETAILS_WIDTH,
            },
          )
        )

      } else if(moveStartingPosition.current.fromDateAdjustment) {

        const precentageMove = yDiff / (height - 10)
        const days = Math.round(timelineTotalDays * precentageMove)
        setFromDate(
          addDaysToDate({
            date: moveStartingPosition.current.fromDate,
            days,
            minDate: timelineStartDate,
            maxDate: moveStartingPosition.current.toDate || timelineEndDate,
          })
        )

      } else if(moveStartingPosition.current.toDateAdjustment) {

        const precentageMove = yDiff / (height - 10)
        const days = Math.round(timelineTotalDays * precentageMove)
        setToDate(
          addDaysToDate({
            date: moveStartingPosition.current.toDate,
            days,
            minDate: moveStartingPosition.current.fromDate,
            maxDate: timelineEndDate,
          })
        )

      } else {

        const precentageMove = yDiff / (height - 10)
        const days = Math.round(timelineTotalDays * precentageMove)
        setFromDate(
          addDaysToDate({
            date: moveStartingPosition.current.fromDate,
            days,
            minDate: timelineStartDate,
            maxDate: timelineEndDate,
          })
        )
        setToDate(
          addDaysToDate({
            date: moveStartingPosition.current.toDate,
            days,
            minDate: timelineStartDate,
            maxDate: timelineEndDate,
          })
        )

      }
    },
  })

  return {
    fromDate,
    toDate,
    detailsWidth,
    safeSetDetailsWidth,
    preciseModeOpen,
    scanning,
    height,
    ref,
    handleMenuOpen,
    toggleHandleMenuOpen,
    setFromDate,
    setToDate,
    ...longPressEvents,
  }

}

export default useInteractiveTimeline