import { setContext } from "@apollo/client/link/context"

import { getLocalStorage, getUpdatedSinceUserUpdateInMemory } from '../../utils/misc'
import AsyncLock from '../../utils/AsyncLock'
import saveDataToDexie from './saveDataToDexie'
import { db } from '../../utils/database'

const MAX_AVG_MS_BETWEEN_REQUESTS = 500
const INITIAL_REQUEST_CREDITS = 500
let requestCredits = INITIAL_REQUEST_CREDITS
setInterval(() => {
  requestCredits = Math.min(requestCredits + 1, INITIAL_REQUEST_CREDITS)
}, MAX_AVG_MS_BETWEEN_REQUESTS)

const lock = new AsyncLock()

const dexieUpdatePreServerLink = setContext(async (operation, context) => {

  const disableLock = await lock.enable()

  const { variables, query } = operation
  const {
    operationName,
    saveFirstToDexie,
    dexieRowLimit,
    updatedSinceType,
    runThroughQueuedMutations,
    attemptingAt,
    queuedMutationId,
    expectedResponse,
  } = context
  const createdAt = Date.now()

  if(--requestCredits < 0) throw new Error(`Infinite server loop detected: ${operationName}`)

  // save locally when relevant
  if(saveFirstToDexie) {

    let data = (expectedResponse || {})[operationName]

    if(!data && /^log/.test(operationName)) {
      data = {
        __typename: operationName.replace(/^log/, ''),
        ...variables.input,
      }
    }

    await saveDataToDexie({
      data,
      dexieRowLimit,
    })
  }

  // add/remove updatedSince to relevant mutations
  if(updatedSinceType) {
    let updatedSince = getLocalStorage(`updatedSince:${updatedSinceType}`, updatedSinceType === `userUpdate` ? 'use-from-memory' : 0)
    if(updatedSince === 'use-from-memory' && updatedSinceType === `userUpdate`) {
      updatedSince = getUpdatedSinceUserUpdateInMemory()
    }
    variables.updatedSince = updatedSince
  } else {
    delete variables.updatedSince
  }

  // put in queue in case it fails or if no connection
  if(runThroughQueuedMutations) {

    const queuedMutation = await db.queuedMutations.get(queuedMutationId)

    if(queuedMutation) {
      await db.queuedMutations.update(queuedMutationId, { attemptingAt })

    } else {
      await db.queuedMutations.add({
        id: queuedMutationId,
        mutation: query,
        variables,
        createdAt,
        attemptingAt,
        updatedSinceType,
      })
    }

  }

  await disableLock()

  return context
})

export default dexieUpdatePreServerLink