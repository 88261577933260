import React from "react"
import styled from 'styled-components'
import ImageIcon from '@material-ui/icons/Image'

const Container = styled.div`
  ${({ $placement, $customSpacing }) => `
    text-align: ${$placement};
    margin: -${$customSpacing} -${$customSpacing} 0 0;
  `}
`

const StyledImageIcon = styled(ImageIcon)`
  vertical-align: middle;
  color: rgba(0,0,0,.2);

  ${({ $height, $customSpacing }) => `
    font-size: ${$height || "150px"};
    height: ${$height || "150px"};
    ${!$customSpacing ? `` : `
      margin: ${$customSpacing} ${$customSpacing} 0 0;
    `}
    max-width: calc(100% - ${$customSpacing || `0px`});
  `}
`

const StyledImage = styled.img`
  margin: 0 10px 10px 0;
  vertical-align: middle;

  ${({ $height, $customSpacing }) => `
    height: ${$height || "150px"};
    ${!$customSpacing ? `` : `
      margin: ${$customSpacing} ${$customSpacing} 0 0;
    `}
    max-width: calc(100% - ${$customSpacing || `0px`});
  `}
`

const ImageList = ({
  images,
  height,
  placement="left",
  customSpacing,
}) => {

  return (
    <Container
      className={`flipeditor-imageList flipeditor-imageList-placement-${placement}`}
      $placement={placement}
      $customSpacing={customSpacing}
    >
      {(images || []).map(({ imgSrc, linkHref, openInNewTab }, idx) => {

        let image = (
          <StyledImageIcon
            key={idx}
            $height={height}
            $customSpacing={customSpacing}
          />
        )

        if(imgSrc) {
          image = (
            <StyledImage
              key={idx}
              src={imgSrc}
              $height={height}
              $customSpacing={customSpacing}
            />
          )
        }

        if(linkHref) {
          image = (
            <a
              key={idx}
              href={linkHref}
              target={openInNewTab ? "_blank" : null}
            >
              {image}
            </a>
          )
        }

        return image
      })}
    </Container>
  )

}

export default ImageList