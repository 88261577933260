import { memo, useContext } from 'react'
import styled from 'styled-components'
import PersonIcon from '@material-ui/icons/Person'

import { LoggedInUserContext } from '../../context/LoggedInUser'

const sizes = {
  normal: {
    dimension: 40,
    fontSize: 20,
    iconSize: 30,
  },
  small: {
    dimension: 30,
    fontSize: 16,
    iconSize: 22,
  },
  mini: {
    dimension: 20,
    fontSize: 12,
    iconSize: 16,
  },
  tiny: {
    dimension: 12,
    fontSize: 8,
    iconSize: 10,
  },
}

const Container = styled.div`

  position: relative;
  border-radius: 100%;
  background: black;
  color: white;
  text-align: center;
  overflow: hidden;
  font-weight: normal !important;

  ${({ $size }) => `
    width: ${sizes[$size].dimension}px;
    height: ${sizes[$size].dimension}px;
    line-height: ${sizes[$size].dimension}px;
    font-size: ${sizes[$size].fontSize}px;
  `}

`

const TheImage = styled.div`

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  ${({ $image, $size }) => `
    background-image: url(${$image});
    background-size: ${sizes[$size].dimension}px;
  `}

`

const StyledPersonIcon = styled(PersonIcon)`
  ${({ $size }) => `
    font-size: ${sizes[$size].iconSize}px;
    height: ${sizes[$size].dimension}px;
  `}
  color: rgba(255,255,255,.4);
`

const Avatar = ({
  user,  // no need to pass if this is for the logged in user
  size="normal",
  className,
}) => {

  const loggedInUser = useContext(LoggedInUserContext)

  user = user || loggedInUser

  return (
    <Container
      $size={size}
      className={className}
    >
      {!!user
        ? (
          <>
            {(user.name || user.email || "?").trim().substr(0,1).toUpperCase()}
            <TheImage
              $image={user.image}
              $size={size}
              className="dark-mode-exempt"
            />
          </>
        )
        : (
          <StyledPersonIcon
            $size={size}
          />
        )
      }
    </Container>
  )
}

export default memo(Avatar)