import { memo } from 'react'
import styled from 'styled-components'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { getTextLanguageId, isRTLText } from '@bibletags/bibletags-ui-helper'
import { i18n } from 'inline-i18n'

import useVersionInfo from '../../hooks/useVersionInfo'
import useVersesPieces from '../../hooks/useVersesPieces'
import { getPassageTextSize, getPassageLineSpacing } from '../../utils/misc'
import { POPPER_LINE_SPACING_SIZES, POPPER_TEXT_SIZES } from '../../utils/constants'

import PassagePopperHeading from './PassagePopperHeading'
import TextContent from '../common/TextContent'

const Container = styled.div`
  ${({ $font, $textSize, $lineSpacing, $isRTL }) => `
    font-family: ${$font || 'inherit'};
    font-size: ${$textSize}px;
    line-height: ${$lineSpacing};
    direction: ${$isRTL ? "rtl" : "ltr"};
  `}
`

const Heading = styled(PassagePopperHeading)`
  text-transform: none;
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.grey[300]};
  font-weight: 300;
`

const NotInThisVersion = styled.div`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 13px;
  font-weight: 200;
`

const VerseNumberPopperContentVersion = ({
  versionId,
  locs,
  fromLoc,
  toLoc,
  hideHeader,
  goSetPopperInfo,
  closeAndClearSearch,
  disabledVsNumClick,
}) => {

  const { bookId, chapter } = (fromLoc || (locs || []).length > 0) ? getRefFromLoc(fromLoc || locs[0]) : {}

  const { safeVersionAbbr, version } = useVersionInfo(versionId)
  const { languageId: preadjustedLanguageId } = version || {}
  const languageId = getTextLanguageId({ languageId: preadjustedLanguageId, bookId })
  const isRTL = isRTLText({ languageId, bookId })
  const spansMoreThanTwoChapters = !!(fromLoc && getRefFromLoc(toLoc || fromLoc).chapter >= chapter + 2)

  const textSize = getPassageTextSize({
    textSizes: POPPER_TEXT_SIZES,
    languageId,
  })

  const lineSpacing = getPassageLineSpacing({
    lineSpacingSizes: POPPER_LINE_SPACING_SIZES,
    languageId,
  })

  const [ pieces ] = useVersesPieces({
    locs,
    fromLoc,
    toLoc,
    versionId,
    skip: spansMoreThanTwoChapters,
  })

  const doesNotExist = (
    (
      (locs || []).length === 0
      && !fromLoc
    )
    || (
      !!pieces
      && pieces.length === 0
    )
  )

  return (
    <Container
      $isRTL={isRTL}
      $textSize={textSize}
      $lineSpacing={lineSpacing}
    >

      {!hideHeader &&
        <Heading>
          {safeVersionAbbr}
        </Heading>
      }

      {spansMoreThanTwoChapters &&
        <NotInThisVersion>
          {i18n("Passage is too long to display here.")}
        </NotInThisVersion>
      }

      {!spansMoreThanTwoChapters && doesNotExist &&
        <NotInThisVersion>
          {i18n("This verse does not exist in this version.")}
        </NotInThisVersion>
      }

      {!!((locs || []).length > 0 || fromLoc) && !doesNotExist && !spansMoreThanTwoChapters &&
        <TextContent
          pieces={pieces}
          versionId={versionId}
          bookId={bookId}
          startChapter={chapter}
          loadingSize={20}
          goSetPopperInfo={goSetPopperInfo}
          closeAndClearSearch={closeAndClearSearch}
          showExpand
          disabledVsNumClick={disabledVsNumClick}
        />
      }

    </Container>
  )
}

export default memo(VerseNumberPopperContentVersion)