import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

export const getBaseHighlightBGImage = color => `linear-gradient(to bottom, transparent 66%, ${color} 66%)`

const StyledMarkupLine = styled(MarkupLine)`
  background-image: ${({ theme, color }) => getBaseHighlightBGImage(theme.palette.markupColors[color].highlight)};
  margin: 0 -.15em;
  padding: 0 .15em;
  z-index: -9;
`

const BaseHighlight = ({
  color,
  className,
  ...otherProps
}) => (
  <StyledMarkupLine
    {...otherProps}
    color={color}
    className={`${className} dark-mode-transform-markup-base-highlight`}
  />
)


export default memo(BaseHighlight)