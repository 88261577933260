import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

const StyledMarkupLine = styled(MarkupLine)`
	background-image: url('${({ theme, color }) => (
    `data:image/svg+xml;utf8,
      <svg viewBox="0 0 852 500" xmlns="http://www.w3.org/2000/svg">
        <ellipse style="fill: ${encodeURIComponent(theme.palette.markupColors[color].line)};" cx="234" cy="234" rx="234" ry="234"/>
      </svg>
    `.replace(/\s*\n\s*/g, ' ')
  )}');
	background-position: left bottom;
	background-size: .35em .35em;
	background-repeat: repeat-x;
  padding-bottom: .4em;
  z-index: -5;
`

const DottedUnderline = ({
  color,
  className,
  ...otherProps
}) => (
  <StyledMarkupLine
    {...otherProps}
    className={`${className} dark-mode-transform-markup-line`}
    color={color}
  />
)


export default memo(DottedUnderline)

