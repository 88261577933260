import { memo, useRef } from 'react'
import styled from 'styled-components'

import useEffectAsync from '../../hooks/useEffectAsync'

const LINES_PER_SECOND = 7
const LINE_WIDTH = 1.5
const SPACE_BETWEEN_LINES = 2
const LINE_COLOR = "#BBB"
const MAX_CANVAS_WIDTH = 4000
const CANVAS_WIDTH = MAX_CANVAS_WIDTH - (MAX_CANVAS_WIDTH % (LINE_WIDTH + SPACE_BETWEEN_LINES))

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`

const Canvases = styled.div`
  position: absolute;
  top: 0;
  left: ${({ $left }) => $left}px;
  transition: .4s linear left;
  opacity: ${({ $playing }) => $playing ? 1 : .3};
  display: flex;
`

const AudioVisualizer = ({
  currentSecs,
  duration,
  maxLineHeight=140,
  height=210,
  playing,
}) => {

  const canvasRef = useRef()
  const totalWidth = duration * LINES_PER_SECOND * (LINE_WIDTH + SPACE_BETWEEN_LINES) - SPACE_BETWEEN_LINES
  const numCanvasesNeeded = Math.ceil(totalWidth / CANVAS_WIDTH) || 1

  useEffectAsync(
    () => {

      const canvases = [ ...canvasRef.current.parentElement.childNodes ]
      const canvasContexts = canvases.map(canvas => canvas.getContext("2d"))
      canvasContexts.forEach(context => {
        context.clearRect(0, 0, CANVAS_WIDTH, height)
        context.fillStyle = LINE_COLOR
      })

      if(duration) {

        const numLinesToMake = parseInt(duration * LINES_PER_SECOND)

        for(let i=0; i<numLinesToMake-100; i++) {
          const left = i * (LINE_WIDTH + SPACE_BETWEEN_LINES)
          const lineHeight = Math.random() * maxLineHeight
          const canvasIdx = parseInt(left / CANVAS_WIDTH, 10)
          canvasContexts[canvasIdx].fillRect(
            left - CANVAS_WIDTH * canvasIdx,
            (height - lineHeight) / 2,
            LINE_WIDTH,
            lineHeight,
          )
        }

      }
    },
    [ duration, maxLineHeight ],
  )

  return (
    <Container>
      <Canvases
        $left={(currentSecs * LINES_PER_SECOND * (LINE_WIDTH + SPACE_BETWEEN_LINES) - SPACE_BETWEEN_LINES) * -1}
        $playing={playing}
      >
        {Array(numCanvasesNeeded).fill().map((x, idx) => (
          <canvas
            key={idx}
            ref={idx === 0 ? canvasRef : null}
            width={CANVAS_WIDTH}
            height={height}
          />
        ))}
      </Canvases>
    </Container>
  )

}

export default memo(AudioVisualizer)