import { memo } from 'react'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import { i18n } from 'inline-i18n'

import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'

const FAQButton = styled(Fab)`
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 0 20px;
`

const QuestionMark = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-right: 10px;
`

const Label = styled.div`
  font-size: 18px;
  font-weight: 300;
`

const FAQFab = ({
  label=i18n("FAQ"),
  icon=<QuestionMark>?</QuestionMark>,
  ...otherProps  // should at least contain `to`
}) => {

  return (
    <NavLinkOrAWithDisable
      {...otherProps}
    >
      <FAQButton
        color="secondary"
        variant="extended"
      >
        {icon}
        <Label>
          {label}
        </Label>
      </FAQButton>
    </NavLinkOrAWithDisable>
  )
}

export default memo(FAQFab)