const keyDownP = async params => {

  const {
    event,
    modifierKey,
    goPrintOrDownload,
  } = params

  if(!modifierKey) return

  event.preventDefault()

  goPrintOrDownload({ action: `print` })
}

export default keyDownP