import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
// import styled from 'styled-components'

import StudyBibleItemEditorSourceSection from './StudyBibleItemEditorSourceSection'

// const StyledButton = styled(Button)`
//   margin: 0 15px;
//   width: calc(100% - 30px);
// `

const StudyBibleItemEditorIntroductionInfo = ({
  StyledTextField,
  commonTextFieldProps,
  StyledDivider,
  info,
  onChangeInfo,
  updatePreview,
}) => {

  const { content } = info || {}

  const onChangeContent = useCallback(onChangeInfo(`content`), [ onChangeInfo ])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>

      <StyledTextField
        {...commonTextFieldProps}
        label={i18n("Content")}
        value={content}
        onChange={onChangeContent}
      />

      <StyledDivider />

      <StudyBibleItemEditorSourceSection
        StyledTextField={StyledTextField}
        commonTextFieldProps={commonTextFieldProps}
        info={info}
        onChangeInfo={onChangeInfo}
        updatePreview={updatePreview}
      />

    </>
  )
}

export default memo(StudyBibleItemEditorIntroductionInfo)