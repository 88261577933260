import React, { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { getTextLanguageId, isRTLText, stripHebrewVowelsEtc, adjustPiecesForSpecialHebrew } from "@bibletags/bibletags-ui-helper"

import { cloneObj, getGrammarColorCSSRules, getOrigVersionInfo } from '../../../utils/misc'

import TaggerOriginalWordPart from './TaggerOriginalWordPart'

const Container = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  position: relative;
  z-index: 1;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${getGrammarColorCSSRules()}
`

const OriginalSnippet = styled.div`
  font-size: 22px;
  line-height: 1.5;

  ${({ $isRTL }) => `
    direction: ${$isRTL ? "rtl" : "ltr"};
  `}
`

const WordPlus = styled.div`
  display: inline-flex;
  align-items: flex-start;

  ${({ $extraSpaceLeft }) => !$extraSpaceLeft ? `` : `
    margin-left: 5px;
  `}
`

const PlainText = styled.span`
  display: inline-block;
  padding: 4px 0 5px;
`

const Slash = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-weight: 200;
  padding: 4px 0 5px;
`

const TaggerOriginalBlock = ({
  originalPieces,
  selectedOriginalWordIdAndPartNumbers,
  setSelectedOriginalWordIdAndPartNumbers,
  contextRef,
  infoByWordIdAndPartNumber,
  setTags,
  tags,
  translationIsRTL,
  goSetPopperInfo,
}) => {

  const { bookId=1, chapter } = contextRef || {}
  const languageId = getTextLanguageId({ languageId: getOrigVersionInfo().languageId, bookId })
  const originalIsRTL = isRTLText({ bookId, languageId })
  const adjustedOriginalPieces = useMemo(
    () => (
      cloneObj(
        adjustPiecesForSpecialHebrew({
          isOriginal: true,
          languageId: `heb+grc`,
          pieces: (
            originalPieces
              .filter((piece, idx) => !(piece.tag === `v` && originalPieces[idx+1].tag === `vp`))
          ),
        })
      )
        .map((piece, idx, array) => {
          // grab non-space starter text from the next piece so that it does not wrap the line to start with a comma or the like

          const pieces = [ piece ]
          const { text, tag } = array[idx+1] || {}
          const nonSpaceStartRegex = /^[^ ]+/

          if(
            piece.tag
            && !tag
            && nonSpaceStartRegex.test(text || ``)
          ) {
            pieces.push({ text: text.match(nonSpaceStartRegex)[0] })
            array[idx+1].text = text.replace(nonSpaceStartRegex, ``)
          }

          return pieces
        })
    ),
    [ originalPieces ],
  )

  const getApproximatOrigWordLength = useCallback(
    ({ text, children, strong }) => {
      const approximateLetterWidth = /^G/.test(strong) ? 10 : 13
      if(text) {
        return stripHebrewVowelsEtc(text).length * approximateLetterWidth
      } else if(children) {
        return children.map(({ text }) => getApproximatOrigWordLength({ text, strong })).reduce((t, len) => t + len, 0)
      } else {
        return 0
      }
    },
    [],
  )

  return (
    <Container>

      <OriginalSnippet
        $isRTL={originalIsRTL}
      >
        
        {adjustedOriginalPieces.map((pieces, idx1) => {

          if(pieces.length === 1 && [ undefined, `c` ].includes(pieces[0].tag)) return pieces[0].text || ``

          return (
            <WordPlus
              key={idx1}
              $extraSpaceLeft={bookId <= 39 && ![ `־` ].includes(pieces.at(-1).text)}
            >
              {pieces.map((piece, idx2) => {

                let { children, tag, text, type } = piece
                if(!children) {
                  children = [{ text }]
                }

                if(!tag) {
                  return (
                    <PlainText key={idx2}>
                      {text || ``}
                    </PlainText>
                  )
                }

                return (
                  children.map(({ color }, idx3) => {

                    const wordIdAndPartNumber = type === `word` ? (bookId < 40 ? `${piece[`x-id`]}|${idx3+1}` : piece[`x-id`]) : ``

                    const pseudoPiece = (
                      piece.content
                        ? piece
                        : {
                          ...cloneObj(piece),
                          children: children.map((child, idx4) => (
                            idx4 === idx3
                              ? child
                              : {}
                          ))
                        }
                    )

                    return (
                      <React.Fragment key={idx3}>

                        <TaggerOriginalWordPart
                          selectedOriginalWordIdAndPartNumbers={selectedOriginalWordIdAndPartNumbers}
                          setSelectedOriginalWordIdAndPartNumbers={setSelectedOriginalWordIdAndPartNumbers}
                          wordIdAndPartNumber={wordIdAndPartNumber}
                          infoByWordIdAndPartNumber={infoByWordIdAndPartNumber}
                          setTags={setTags}
                          tags={tags}
                          bookId={bookId}
                          startChapter={chapter}
                          pseudoPiece={pseudoPiece}
                          approximateOrigWordLength={getApproximatOrigWordLength(pseudoPiece)}
                          color={color}
                          originalIsRTL={originalIsRTL}
                          translationIsRTL={translationIsRTL}
                          goSetPopperInfo={goSetPopperInfo}
                          contextRef={contextRef}
                        />

                        {idx3 < children.length - 1 &&
                          <Slash>/</Slash>
                        }

                      </React.Fragment>
                    )
                  })
                )

              })}
            </WordPlus>
          )
        })}

      </OriginalSnippet>

    </Container>
  )
}

export default memo(TaggerOriginalBlock)