import { memo, useCallback } from 'react'
import styled from 'styled-components'

import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import useInstanceValue from '../../../hooks/useInstanceValue'

import CustomSlider from '../../common/CustomSlider'

const StyledCustomSlider = styled(CustomSlider)`
  background-color: white;
  padding: 10px;
  margin: -10px -10px -20px -10px;
  border-radius: 10px;

  &.module-settings-linespacings {
    margin-bottom: -10px;
  }
`

const SliderModuleSetting = ({
  projectId,
  moduleSetting,
  displayedValue,
  setDisplayedValue,
  ...otherProps
}) => {

  const getDisplayedValue = useInstanceValue(displayedValue)

  const [ goSet ] = useGoSetModuleSetting({
    moduleSetting,
    projectId,
  })

  const updateBase = useCallback(
    (event, value) => {
      setDisplayedValue({
        ...getDisplayedValue(),
        base: value / 100,
      })
    },
    [ getDisplayedValue, setDisplayedValue ],
  )

  const commitChange = useCallback(
    () => {
      goSet({
        value: getDisplayedValue(),
      })
    },
    [ getDisplayedValue, goSet ],
  )

  return (
    <StyledCustomSlider
      min={200}
      max={400}
      color="secondary"
      value={parseInt(displayedValue.base * 100)}
      onChange={updateBase}
      onChangeCommitted={commitChange}
      {...otherProps}
    />
  )
}

export default memo(SliderModuleSetting)