import { memo, useMemo } from 'react'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import { combineItems } from "@bibletags/bibletags-ui-helper"

import {
  MODULE_TYPE_COLOR_MAP,
} from '../../../utils/constants'
import { getModuleTypeLabel, getOrigVersionInfo } from '../../../utils/misc'

import PassageRef from '../../common/PassageRef'

const Container = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[500]};
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ModuleType = styled.span`
  font-weight: bold;
  color: ${({ theme, $moduleType }) => theme.palette[MODULE_TYPE_COLOR_MAP[$moduleType]].main};
`

const Passage = styled.span`
  color: black;
`

const ProjectsListModule = ({
  module,
  ...otherProps
}) => {

  const filteredModulePassages = useMemo(
    () => {
      const distinctFromLocAndToLocCombos = []
      return (
        (module.modulePassages || [])
          .filter(({ fromLoc, toLoc }) => {
            const bothLocs = `${fromLoc} ${toLoc}`
            if(!distinctFromLocAndToLocCombos.includes(bothLocs)) {
              distinctFromLocAndToLocCombos.push(bothLocs)
              return true
            }
            return false
          })
      )
    },
    [ module ],
  )

  if([ `NOTES`, `WORD-STUDY` ].includes(module.type)) {

    if(module.label === getModuleTypeLabel(module) || module.type === `WORD-STUDY`) {

      return(
        <Container {...otherProps}>
          <ModuleType $moduleType={module.type}>
            {getModuleTypeLabel(module)}
          </ModuleType>
        </Container>
      )

    } else {

      return(
        <Container {...otherProps}>
          {i18nReact("{{title}} ({{subtitle}})", {
            title: (
              <ModuleType $moduleType={module.type}>
                {module.label}
              </ModuleType>
            ),
            subtitle: getModuleTypeLabel(module),
          })}
        </Container>
      )

    }

  }

  return (
    <Container {...otherProps}>
      {i18nReact("{{module_type}} of {{passages}}", {
        module_type: (
          <ModuleType $moduleType={module.type}>
            {getModuleTypeLabel(module)}
          </ModuleType>
        ),
        passages: (
          combineItems(
            filteredModulePassages.map(({ fromLoc, toLoc, info={} }) => (
              <Passage
                key={`${fromLoc} ${toLoc}`}
              >
                <PassageRef
                  fromLoc={fromLoc}
                  toLoc={toLoc}
                  convertToVersionId={info.versionId || getOrigVersionInfo()}
                  abbreviated={true}
                />
              </Passage>
            ))
          )
        ),
      })}
    </Container>
  )
}

export default memo(ProjectsListModule)