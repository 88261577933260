import { useEffect, useRef } from 'react'

const scrollToFraction = ({ el, scrollFraction }) => {
  const maxScroll = el.scrollHeight - el.clientHeight
  el.scrollTop = maxScroll * scrollFraction
}

const useScrollSync = ({
  forceUpdateOnChange,
  updateScrollYFraction,
}) => {

  const userScrollingEl = useRef()
  const lastScrollTime = useRef(0)

  const refs = [
    useRef(),
    useRef(),
    useRef(),
  ]

  useEffect(
    () => {

      const els = refs.map(ref => ref.current).filter(Boolean)

      const numEls = els.length

      if(numEls > 0) {

        const matchScrollingFuncs = els.map((el, idx) => () => {
          const scrollingEl = els[idx]

          const now = Date.now()
          if(scrollingEl && now - lastScrollTime.current > 500) {
            userScrollingEl.current = scrollingEl
          }
          lastScrollTime.current = now

          if(scrollingEl !== userScrollingEl.current) return

          const scrollRefMaxScroll = scrollingEl.scrollHeight - scrollingEl.clientHeight
          const scrollFraction = scrollingEl.scrollTop / scrollRefMaxScroll

          els.forEach(el => {
            if(el !== scrollingEl) {
              scrollToFraction({
                el,
                scrollFraction,
              })
            }
          })

          updateScrollYFraction(scrollFraction)
        })

        els.forEach((el, idx) => {
          el.addEventListener('scroll', matchScrollingFuncs[idx])
        })

        return () => {
          els.forEach((el, idx) => {
            el.removeEventListener('scroll', matchScrollingFuncs[idx])
          })
        }
      }
    },
    [ forceUpdateOnChange ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  return refs
}

export default useScrollSync