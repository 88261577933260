import { memo, useCallback } from 'react'
import styled from 'styled-components'

import useGoUpdateModuleDot from '../../hooks/useGoUpdateModuleDot'
import { getFinalPositionInfo, getMovingPositionInfo } from '../../hooks/useDotNotes'

const Cover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  cursor: none;
`

const DotNotePlacementCover = ({
  projectId,
  moduleId,
  onClose,
  setPlacingInfo,
  formattingKeyId,
}) => {

  const [ goCreateModuleDot ] = useGoUpdateModuleDot({
    projectId,
    moduleId,
  })

  const getColor = useCallback(
    ({ clientX, clientY }) => {

      let color = `GREY`
      const markupEl = document.elementsFromPoint(clientX, clientY).find(el => el.matches(`.TextContentMarkupContainer-Component`))
      if(markupEl) {
        color = markupEl.getAttribute(`data-color`) || color
      }

      return color
    },
    [],
  )

  const onClick = useCallback(
    event => {

      const positionInfo = getFinalPositionInfo(event, { formattingKeyId })
      if(!positionInfo) return  // just in case

      const { id } = goCreateModuleDot({
        content: "",
        positionInfo,
        color: getColor(event),
      })

      onClose()

      setTimeout(() => {
        const dotEl = document.body.querySelector(`#Dot-StyledDiv-${id}`)
        if(dotEl) {
          dotEl.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }))
          dotEl.dispatchEvent(new MouseEvent('mouseup', { bubbles: true }))
          setTimeout(() => {
            const editorEl = document.body.querySelector(`.DotPopper-StyledEditor-${id} [data-lexical-editor]`)
            if(editorEl) {
              editorEl.focus()
            }
          }, 1)
        }
      }, 1)

    },
    [ onClose, goCreateModuleDot, formattingKeyId, getColor ],
  )

  const onMouseMove = useCallback(
    event => {

      const positionInfo = getMovingPositionInfo(event)

      setPlacingInfo({
        content: "",
        positionInfo,
        color: getColor(event),
      })

    },
    [ setPlacingInfo, getColor ],
  )

  const onMouseLeave = useCallback(() => setPlacingInfo({}), [ setPlacingInfo ])

  return (
    <Cover
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      className="DotNotePlacementCover-Cover"
    />
  )
}

export default memo(DotNotePlacementCover)