import { memo, useState, useRef } from 'react'
import styled from 'styled-components'
import { useScroll } from 'react-use'

import useMemoAsync from '../../hooks/useMemoAsync'
import useInstanceValue from '../../hooks/useInstanceValue'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import useAppSize from '../../hooks/useAppSize'

import Loading from './Loading'

const Container = styled.div`
  margin-bottom: -15px;
`

const Backdrop = styled.div`

  position: relative;
  overflow: hidden;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  padding: ${({ $padding }) => $padding}px;
  box-shadow: 0 2px 20px rgb(0 0 0/.5);
  background: white;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

`

const ClearLoading = styled(Loading)`
  background-color: transparent;
`

const StyledImg = styled.img`
  width: ${({ $width }) => $width}px;
  transform: translate(${({ $positionAdjustment }) => `${$positionAdjustment}px,${$positionAdjustment}px`});
  vertical-align: middle;

  ${({ src }) => src ? `` : `
    padding-bottom: 100%;
  `}

  ${({ $faded }) => !$faded ? `` : `
    opacity: .5;
  `}
`

const PrintAndPdfPreviewPages = ({
  captureInfo,
  options,
  scrollRef,
  maxOutWidth,
}) => {

  const {
    format,
    marginsInChange,
    moduleWidth,
    moduleWidthInChange,
  } = options

  const gettingAPage = useRef(false)

  const captureInfoWithDataUrlOfPage1 = useMemoAsync(
    async () => {
      gettingAPage.current = true
      const headerDataUrl = captureInfo.getDataUrlForPage && await captureInfo.getDataUrlForPage(0)
      const page1DataUrl = captureInfo.getDataUrlForPage && await captureInfo.getDataUrlForPage(1)
      gettingAPage.current = false
      return {
        ...captureInfo,
        headerDataUrl,
        page1DataUrl,
      }
    },
    [ captureInfo ],
  )

  const {
    headerDataUrl,
    page1DataUrl,
    options: captureInfoOptions={},
    basePageWidth=0,
    pageHeightToWidthProportion=1,
    numPages,
  } = captureInfoWithDataUrlOfPage1 || {}

  const width = Math.min(basePageWidth / 2, maxOutWidth)
  const height = pageHeightToWidthProportion * width
  const displayFactor = basePageWidth / width

  const [ dataUrlsForPage2Plus, setDataUrlsForPage2Plus ] = useState([])
  const getCaptureInfo = useInstanceValue(captureInfo)
  const { height: windowHeight } = useAppSize()
  const { y: scrollY=0 } = useScroll(scrollRef || {})
  const numPagesBeingViewed = !scrollRef ? 1 : Math.ceil((windowHeight + scrollY) / (height + 15))

  const getAnotherPage = async () => {
    const nextPageNumNeedingDataUri = dataUrlsForPage2Plus.length + 2
    if(
      !gettingAPage.current
      && captureInfo.getDataUrlForPage
      && nextPageNumNeedingDataUri <= numPagesBeingViewed
      && nextPageNumNeedingDataUri <= numPages
    ) {
      gettingAPage.current = true
      const newDataUrl = await captureInfo.getDataUrlForPage(nextPageNumNeedingDataUri)
      gettingAPage.current = false
      if(getCaptureInfo() === captureInfo) {  // only add it on if the settings haven't changed
        setDataUrlsForPage2Plus([
          ...dataUrlsForPage2Plus,
          newDataUrl,
        ])
      }
    }
  }

  useLayoutEffectAsync(
    () => {
      setDataUrlsForPage2Plus([])  // this will fire getAnotherPage() via the next useLayoutEffect
    },
    [ captureInfoWithDataUrlOfPage1 ],
  )

  useLayoutEffectAsync(
    () => { getAnotherPage() },
    [ numPagesBeingViewed, dataUrlsForPage2Plus ],
  )

  const dataUrls = [ page1DataUrl, ...dataUrlsForPage2Plus ]

  const { margins } = captureInfoOptions

  const resizingMargins = options.margins !== marginsInChange
  const resizingModuleWidth = moduleWidth !== moduleWidthInChange
  const waitingForRender = [
    `pageSize`,
    `orientation`,
    `moduleWidth`,
    `margins`,
  ].some(key => options[key] !== captureInfoOptions[key])
  const doFadedAndTempResize = resizingMargins || resizingModuleWidth || waitingForRender

  const zoomAdjustment = captureInfoOptions.moduleWidth / moduleWidthInChange

  if(!page1DataUrl || captureInfoOptions.format !== format) return <ClearLoading />

  return (
    <Container>
      {Array(scrollRef ? numPages : 1).fill().map((x, idx) => (
        <Backdrop
          key={idx}
          className="dark-mode-exempt"
          $width={width}
          $height={height}
          $padding={margins / displayFactor}
        >

          {idx === 0 &&
            <StyledImg
              src={headerDataUrl}
              $faded={doFadedAndTempResize}
              $width={(width - (margins * 2) / displayFactor)}
              $positionAdjustment={(marginsInChange - margins) / displayFactor}
            />
          }

          <StyledImg
            src={dataUrls[idx]}
            $faded={doFadedAndTempResize}
            $width={(width - (margins * 2) / displayFactor) * zoomAdjustment}
            $positionAdjustment={(marginsInChange - margins) / displayFactor}
          />

          {!dataUrls[idx] && <ClearLoading />}
          {!resizingMargins && waitingForRender && <ClearLoading />}
          {!resizingModuleWidth && waitingForRender && <ClearLoading />}

        </Backdrop>
      ))}
    </Container>
  )
}

export default memo(PrintAndPdfPreviewPages)