import React, { memo } from 'react'
// import { i18n } from 'inline-i18n'
// import styled from 'styled-components'

import BibleMapInfoEventReference from './BibleMapInfoEventReference'

const BibleMapInfoEventReferences = ({
  passages,
  goSetPopperInfo,
}) => {

  return (
    <>
      {passages.map((passage, idx) => (
        <React.Fragment key={idx}>
          {idx !== 0 && `; `}
          <BibleMapInfoEventReference
            passage={passage}
            goSetPopperInfo={goSetPopperInfo}
          />
        </React.Fragment>
      ))}
    </>
  )
}

export default memo(BibleMapInfoEventReferences)