import { memo, useCallback } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'

const StyledTextField = styled(TextField)`
  margin: 10px 0 10px;
`

const ParagraphsAndHeadingsOptionsForModuleSettings = ({
  projectId,
  moduleSetting,
}) => {

  const [ goSet ] = useGoSetModuleSetting({
    moduleSetting,
    projectId,
  })

  const onChange = useCallback(
    ({ target }) => {
      goSet({
        value: target.value,
      })
    },
    [ goSet ],
  )

  return (
    <StyledTextField
      variant="outlined"
      size="small"
      select
      label={i18n("Paragraphs and Headings")}
      value={moduleSetting.value}
      onChange={onChange}
      fullWidth
    >
      <MenuItem
        value="PARAGRAPHS-AND-HEADINGS"
      >
        {i18n("Show all")}
      </MenuItem>
      <MenuItem
        value="PARAGRAPHS-ONLY"
      >
        {i18n("Show paragraphs only")}
      </MenuItem>
      <MenuItem
        value="NONE"
      >
        {i18n("Hide all")}
      </MenuItem>
    </StyledTextField>
  )
}

export default memo(ParagraphsAndHeadingsOptionsForModuleSettings)