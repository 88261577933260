import { memo } from 'react'
import styled from 'styled-components'

import {
  THUMBNAILS_WIDTH,
  MINIMUM_MEDIUM_WIDTH,
} from '../../../utils/constants'

const ModuleThumbnailContainer = styled.div`
  width: ${THUMBNAILS_WIDTH - 30}px;
  height: ${THUMBNAILS_WIDTH - 30}px;
  min-width: 0;
  margin: 0 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
    width: 30px;
    height: 30px;
    display: inline-flex;
    margin: 1px 7px 1px 1px;
    vertical-align: top;
  }
`

export default memo(ModuleThumbnailContainer)