import { memo, forwardRef, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import { timeBetweenMapDates } from '../../../utils/misc'

const HANDLE_SIZE = 50
const MIN_HEIGHT_TO_SHOW_LETTERS = 73
const MIN_HEIGHT_TO_SHOW_INDIVIDUAL_CIRCLES = 96

const Container = styled.div`
  position: absolute;
  left: -10px;
  width: ${HANDLE_SIZE}px;
  height: ${HANDLE_SIZE}px;
  border-radius: 100px;
  /* background: rgb(0 0 0/.6); */
  background: ${({ theme }) => theme.palette.secondary.dark}aa;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $hasToDate }) => $hasToDate ? `space-between` : `center`};
  white-space: nowrap;
  transform: translateY(-20px);
  border: 1px solid rgb(255 255 255/.5);
  backdrop-filter: blur(5px);
  z-index: 1;
  user-select: none;
  cursor: pointer;
  line-height: 1.2;
  padding: ${({ $hideIndividualCircles }) => $hideIndividualCircles ? `10px 0` : `0 0`};
`

const DateNumber = styled.div`
  font-weight: 300;
  font-size: 15px;
`

const DateLetters = styled.div`
  font-weight: bold;
  font-size: 11px;
  line-height: .5;
  opacity: .6;
`

const Label = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  min-height: 0;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, height .15s ease-in-out;
  width: 48px;
  border-radius: 100px;

  ${({ $hideIndividualCircles }) => $hideIndividualCircles ? `` : `
    background-color: rgb(255 255 255/.1);
    height: 48px;
  `}

  ${({ $hasToDate, $hideIndividualCircles }) => !($hasToDate && $hideIndividualCircles) ? `` : `
    &:hover {
      transform: scale(1.07);
    }
  `}

  ${({ $hasToDate, $hideIndividualCircles }) => !($hasToDate && !$hideIndividualCircles) ? `` : `
    &:hover {
      background-color: rgb(255 255 255/.2);
    }
  `}

`

const BibleMapTimelineHandle = ({
  fromDate,
  toDate,
  height,
  timelineStartDate,
  timelineEndDate,
}, ref) => {

  const handleStyle = useMemo(
    () => {
      const { totalDays: timelineTotalDays } = timeBetweenMapDates(timelineStartDate, timelineEndDate)
      const { totalDays } = timeBetweenMapDates(timelineStartDate, fromDate)
      const daysBetweenFromAndTo = toDate && timeBetweenMapDates(fromDate, toDate).totalDays
      const portionDown = totalDays / timelineTotalDays
      const heightPortion = daysBetweenFromAndTo / timelineTotalDays
      return {
        top: `${(height - 10) * portionDown}px`,
        height: toDate ? `${(height - 10) * heightPortion + HANDLE_SIZE}px` : `${HANDLE_SIZE}px`,
      }
    },
    [ fromDate, toDate, height, timelineStartDate, timelineEndDate ],
  )

  const fromDateNumbers = fromDate.replace(/ \[.*$/, ``).replace(/[ADBC ]/g, ``)
  let toDateNumbers = toDate && toDate.replace(/ \[.*$/, ``).replace(/[ADBC ]/g, ``)
  if(toDateNumbers === fromDateNumbers) {
    toDateNumbers = null
  }
  const hideLetters = toDateNumbers && parseInt(handleStyle.height.slice(0,-2), 10) < MIN_HEIGHT_TO_SHOW_LETTERS
  const hideIndividualCircles = !toDateNumbers || parseInt(handleStyle.height.slice(0,-2), 10) < MIN_HEIGHT_TO_SHOW_INDIVIDUAL_CIRCLES

  return (
    <Container
      ref={ref}
      className="BibleMapTimelineHandle-Container"
      style={handleStyle}
      $hasToDate={!!toDateNumbers}
      $hideIndividualCircles={hideIndividualCircles}
    >
      <Label
        className="BibleMapTimelineHandle-Label-fromDate"
        $hasToDate={!!toDateNumbers}
        $hideIndividualCircles={hideIndividualCircles}
      >
        <DateLetters>
          {(/BC/.test(fromDate) || hideLetters) ? `` : i18n("AD")}
        </DateLetters>
        <DateNumber>
          {fromDateNumbers}
        </DateNumber>
        <DateLetters>
          {(/AD/.test(fromDate) || hideLetters) ? `` : i18n("BC")}
        </DateLetters>
      </Label>
      {!!toDateNumbers &&
        <Label
          className="BibleMapTimelineHandle-Label-toDate"
          $hasToDate
          $hideIndividualCircles={hideIndividualCircles}
        >
          <DateLetters>
            {(/BC/.test(toDate) || hideLetters) ? `` : i18n("AD")}
          </DateLetters>
          <DateNumber>
            {toDateNumbers}
          </DateNumber>
          <DateLetters>
            {(/AD/.test(toDate) || hideLetters) ? `` : i18n("BC")}
          </DateLetters>
        </Label>
      }
    </Container>
  )
}

export default memo(forwardRef(BibleMapTimelineHandle))