import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation deleteHighlights($ids: [ID]!, $savedAt: Milliseconds!, $updatedSince: Milliseconds) {
    deleteHighlights(ids: $ids, savedAt: $savedAt, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`