import { memo, useCallback, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory, useLocation } from "react-router-dom"

import useProjectsTitleAndIcon from '../../../hooks/useProjectsTitleAndIcon'

import ProjectsRightPanelModules from './ProjectsRightPanelModules'

const Container = styled.div`
  width: 300px;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-direction: column;
  user-select: none;
  position: relative;

  @media (max-width: 900px) {
    background-color: white;
    position: absolute;
    z-index: 3;
    top: 59px;
    bottom: 0;
    transition: right .2s ease-in-out;
    right: ${({ $expanded }) => $expanded ? 0 : `-300px`};
  }
`

const StyledTextField = styled(TextField)`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  background: white;
  z-index: 1;

  .MuiFormLabel-root {
    background: white;
    border-radius: 3px;
  }
`

const ScrollableContainer = styled.div`
  flex: 1;
  padding: 80px 20px 50px;
  overflow-y: auto;
`

const SeeResultsButton = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 30px 20px;
  display: none;
  
  @media (max-width: 900px) {
    display: block;
  }
`

const ProjectsRightPanel = ({
  searchInputRef,
  expanded=false,
  close,
}) => {

  const { title } = useProjectsTitleAndIcon()
  const history = useHistory()
  const location = useLocation()

  const search = new URLSearchParams(location.search).get(`search`) || ``

  const inputProps = useMemo(
    () => ({
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }),
    [],
  )

  const onChange = useCallback(
    ({ target }) => {
      const searchParams = new URLSearchParams(window.location.search)

      if(target.value) {
        searchParams.set(`search`, target.value)
      } else {
        searchParams.delete(`search`)
      }

      history.replace({
        // pathname: window.location.pathname,
        search: searchParams.toString(),
      })
    },
    [ history ],
  )

  return (
    <Container $expanded={expanded}>

      <StyledTextField
        label={i18n("Search {{context}}", {
          context: title,
        })}
        variant="outlined"
        InputProps={inputProps}
        value={search}
        onChange={onChange}
        inputRef={searchInputRef}
      />

      <ScrollableContainer>

        <ProjectsRightPanelModules />

      </ScrollableContainer>

      <SeeResultsButton
        variant="contained"
        color="primary"
        onClick={close}
      >
        {i18n("See results")}
      </SeeResultsButton>

    </Container>
  )
}

export default memo(ProjectsRightPanel)