import { useContext, useMemo } from 'react'

import { OfflineSetupStatusContext } from '../context/LocalInfo'
import { LastApolloResponseContext } from '../context/LastApolloResponse'

const useMutationContext = () => {

  const offlineSetupStatus = useContext(OfflineSetupStatusContext)
  const { setLastApolloResponse } = useContext(LastApolloResponseContext)

  const context = useMemo(
    () => ({
      offlineSetupStatus,
      setLastApolloResponse,
    }),
    [ offlineSetupStatus, setLastApolloResponse ]
  )

  return context
}

export default useMutationContext