import { memo, useCallback, useContext, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { cloneObj, equalObjs, getDateAsString, timeBetweenMapDates } from '../../../utils/misc'

import ConfirmDialog from '../../common/ConfirmDialog'
import MyPlanPromoSpot from '../../common/MyPlanPromoSpot'
import InfoDialog from '../../common/InfoDialog'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import useRefState from '../../../hooks/useRefState'

export const getOrderedSnapshots = snapshots => {
  const orderedSnapshots = [ ...snapshots ]
  const getDate = ({ date=`` }={}) => date.split(` - `)[0]
  orderedSnapshots.sort((a,b) => (
    timeBetweenMapDates(getDate(a), getDate(b)).totalDays > 0
      ? -1
      : 1
  ))
  return orderedSnapshots
}

const StyledIconButton = styled(IconButton)`
  padding: 7px;
  margin: -1px 0 -1px -5px;
  align-self: center;

  .MuiSvgIcon-root {
    font-size: 18px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main}3a;
      color: rgb(0 0 0/.8);
    }
  }

  @media (max-width: 440px) {
    display: none;
  }

`

const SnapshotButton = styled(Button)`
  text-transform: none;
  position: relative;
  display: none;

  .MuiButton-startIcon {
    margin-right: 4px;
  }

  @media (max-width: 440px) {
    display: flex;
  }
`

const DeleteIconButton = styled(IconButton)`
  padding: 10px;
  margin-right: -5px;

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const SnapshotRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const SnapshotRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const EditSnapshotsButton = ({
  myMapSnapshots,
  setMyMapSnapshots,
}) => {

  const { hasMyPlan } = useContext(LoggedInUserContext) || {}
  const [ dialogOpen, toggleDialogOpen ] = useSimpleToggle()
  const [ myMapSnapshotsInEdit, setMyMapSnapshotsInEdit, getMyMapSnapshotsInEdit ] = useRefState(myMapSnapshots)

  const onChange = useCallback(
    ({ target }) => {
      const dataId = target.closest(`[data-id]`).getAttribute(`data-id`)
      const newMyMapSnapshotsInEdit = cloneObj(getMyMapSnapshotsInEdit())
      const newMyMapSnapshotInEdit = newMyMapSnapshotsInEdit.find(({ id }) => id === dataId)
      newMyMapSnapshotInEdit.snapshotLabel = target.value
      setMyMapSnapshotsInEdit(newMyMapSnapshotsInEdit)
    },
    [ getMyMapSnapshotsInEdit, setMyMapSnapshotsInEdit ],
  )

  const onDelete = useInstanceValuesCallback(
    ({ target }) => {
      const dataId = target.closest(`[data-id]`).getAttribute(`data-id`)
      const newMyMapSnapshotsInEdit = cloneObj(getMyMapSnapshotsInEdit())
      setMyMapSnapshotsInEdit(newMyMapSnapshotsInEdit.filter(({ id }) => id !== dataId))
    }
  )

  const goSaveSnapshots = useInstanceValuesCallback(
    () => {
      const myMapSnapshotsInEdit = cloneObj(getMyMapSnapshotsInEdit())
      myMapSnapshotsInEdit.forEach(myMapSnapshotInEdit => {
        myMapSnapshotInEdit.snapshotLabel = myMapSnapshotInEdit.snapshotLabel.replace(/\s\s+/g, ` `).trim()
      })
      setMyMapSnapshots(myMapSnapshotsInEdit)
      toggleDialogOpen({ force: false })
    }
  )

  useLayoutEffectAsync(
    () => {
      if(dialogOpen) {
        setMyMapSnapshotsInEdit(
          getOrderedSnapshots(
            myMapSnapshots
          )
        )
      }
    },
    [ dialogOpen ],
  )

  const disabled = useMemo(
    () => (
      equalObjs(getOrderedSnapshots(myMapSnapshots), myMapSnapshotsInEdit)
      || myMapSnapshotsInEdit.some(({ snapshotLabel }) => !snapshotLabel.replace(/\s\s+/g, ` `).trim())
    ),
    [ myMapSnapshots, myMapSnapshotsInEdit ],
  )

  return (
    <>

      <Tooltip
        title={i18n("Edit and delete my snapshots")}
      >
        <StyledIconButton
          onClick={toggleDialogOpen}
        >
          <EditIcon />
        </StyledIconButton>
      </Tooltip>

      <SnapshotButton
        variant="outlined"
        size="small"
        color="primary"
        onClick={toggleDialogOpen}
        startIcon={<EditIcon />}
      >
        {i18n("Edit and delete my snapshots")}
      </SnapshotButton>

      <ConfirmDialog
        open={dialogOpen && hasMyPlan}
        onCancel={toggleDialogOpen}
        onConfirm={goSaveSnapshots}
        disabled={disabled}
        title={i18n("Edit and Delete Snapshots")}
        confirmButtonLabel={i18n("Save Changes")}
        explanation={
          <SnapshotRows>
            {myMapSnapshotsInEdit.map(({ id, snapshotLabel, date }) => (
              <SnapshotRow>
                <TextField
                  data-id={id}
                  onChange={onChange}
                  variant="outlined"
                  size="small"
                  label={getDateAsString({ date })}
                  fullWidth
                  value={snapshotLabel}
                  inputProps={{ maxLength: 30 }}
                />
                <DeleteIconButton
                  data-id={id}
                  onClick={onDelete}
                >
                  <DeleteIcon />
                </DeleteIconButton>
              </SnapshotRow>
            ))}
          </SnapshotRows>
        }
      />

      <InfoDialog
        open={dialogOpen && !hasMyPlan}
        onOkay={toggleDialogOpen}
        showCloseIconButton
        title={i18n("Delete a Snapshot")}
        explanation={
          <MyPlanPromoSpot
            subscribeToMessage={i18n("Subscribe to edit map snapshots.")}
          />
        }
      />

    </>
  )
}

export default memo(EditSnapshotsButton)