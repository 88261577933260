import systemFormattingKeys from '../utils/systemFormattingKeys'
import useModuleSettings from './useModuleSettings'

export const defaultSettingValues = {
  textSizes: { base: 2.6, grc: 2.9, heb: 2.9 },  // base: 2-4; grc/heb: 2-4
  lineSpacings: { base: 2.6, grc: 2.5, heb: 2.7 },  // base: 2-3.5; grc/heb: 2-3
  showVsNums: true,
  showCfs: true,
  showNotes: true,
  showCantillation: true,
  showHebrewVowels: true,
  showGreekAccents: true,
  greekPunctuation: `ENGLISH`,
  paragraphFormatting: `PARAGRAPHS-ONLY`,
  showContext: true,
  proceedingContext: ``,
  followingContext: ``,
  formattingKeyInfos: systemFormattingKeys.map(({ id, info: { colors=[], colorDefault={} }={} }) => ({
    id,
    colors: [
      ...((colors.length > 0 || colorDefault.value || colorDefault.type) ? [] : [{
        color: `YELLOW`,
        value: ``,
      }])
    ],
  })),
  currentFrmtgKeyId: null,
  lenses: `ON`,  // or OFF
  lastScrollPosition: {
    loc: null,
    offset: 0,  // ? for the header row
  },
}

const useMarkupSettings = ({
  moduleId,
  skip,
}) => {

  const markupSettings = useModuleSettings({
    moduleId,
    defaultSettingValues,
    skip,
  })

  return markupSettings

}

export default useMarkupSettings