import { memo, useMemo, useState } from 'react'
import styled from 'styled-components'

// import useInstanceValue from '../../../hooks/useInstanceValue'
import useDataQuery from '../../../hooks/useDataQuery'
import useEffectAsync from '../../../hooks/useEffectAsync'
import moduleComponentsByType from '../shared/moduleComponentsByType'

import StudyModuleInNotesFindModule from './StudyModuleInNotesFindModule'
import LegacyStudyModuleUponPrintInNotes from './LegacyStudyModuleUponPrintInNotes'
import Loading from '../../common/Loading'

import moduleQuery from '../../../graphql/queries/module'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${({ $height }) => $height};
`

const StudyModuleInNotes = ({
  moduleId,
  goPrintOrDownload,
  onLoad,
  projectId,
  disableLegacyModules,
  setPopoverComponent,
  flipEditorUpdateSettings,
  flipEditorBlock,
}) => {

  // const user = useContext(LoggedInUserContext)
  // const { hasToolsPlan } = user || {}

  const { module={}, loading: loadingModule } = useDataQuery({
    moduleQuery,
    variables: {
      id: moduleId,
    },
    skip: !moduleId,
  })

  const isLegacyModule = [ `DISCOURSE`, `PHRASING`, `DIAGRAMMING` ].includes((module || {}).type)
  const disable = !!disableLegacyModules && isLegacyModule

  const Module = useMemo(() => moduleComponentsByType[(module || {}).type], [ module ])

  const [ height, setHeight ] = useState(400)
  // const [ printDialogInfo, setPrintDialogInfo ] = useState()

  const loaded = !module || !moduleId || disable
  useEffectAsync(
    () => {
      if(loaded) {
        onLoad && onLoad()
      }
    },
    [ loaded ],
  )

  if(disable) return <LegacyStudyModuleUponPrintInNotes />

  if(!module || !moduleId) {
    return (
      <StudyModuleInNotesFindModule
        includeNotFoundMessage={!!moduleId}
        projectId={projectId}
        setPopoverComponent={setPopoverComponent}
        blockKey={flipEditorBlock.key}
        updateSettings={flipEditorUpdateSettings}
      />
    )
  }

  return (
    <Container $height={isLegacyModule ? `${height}px` : `auto`}>

      {loadingModule && <Loading />}

      {!loadingModule &&
        <Module
          key={module.id}
          module={module}
          noneditableViewingMode="within-notes"
          setHeight={setHeight}
          // sharedPrintDialogInfo={printDialogInfo}
          // clearSharedPrintDialogInfo={clearSharedPrintDialogInfo}
          goPrintEncompassingNotesTab={goPrintOrDownload}
          onLoad={onLoad}
        />
      }

    </Container>
  )

}

export default memo(StudyModuleInNotes)