import { gql } from '@apollo/client'

import { getUserFields } from '../fragments/user_fields'

export default gql
`
  mutation requestEditingLock($type: String!, $requestId: String!) {
    requestEditingLock(type: $type, requestId: $requestId) {
      granted
      beingUsedByUser {
        ${getUserFields()}
      }
    }
  }
`