import React from "react"
import styled from 'styled-components'
import ReactTable from 'react-table'
import TableContainer from './Table/TableContainer'

import getTableInfoFromData from './Table/getTableInfoFromData'

const PAGE_SIZE = 100

const Container2 = styled.div`
  ${({ $width, $maintainWidth }) => `
    width: ${$width || '600px'};
    ${$maintainWidth ? `` : `max-width: 100%;`}
    display: inline-block;
    text-align: initial;
  `}
`

const TableHeader = styled.div`
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(0,0,0,.5);
  font-weight: 600;
  margin-bottom: 10px;
`

const TableFooter = styled.div`
  margin-top: 13px;
  font-size: 12px;
  color: rgba(0,0,0,.5);
`

class Table extends React.Component {

  convertContentToColumnsAndData = () => {
    const { tableData='', columnAlignments=[] } = this.props

    const { columnNames, data } = getTableInfoFromData(tableData)

    const columns = columnNames.map((columnName, idx) => ({
      Header: columnName,
      accessor: columnName.replace(/\./g, '_PERIOD_'),
      Cell: ({ value }) => <div className={`rt-align-${columnAlignments[idx] || 'default'}`}>{value}</div>,
    }))

    return {
      data,
      columns,
    }
  }

  render() {
    const { tableHeader, maxWidth, width=maxWidth, maintainWidth, placement: textAlign='center', sortable, filterable, tableFooter } = this.props
    // width=maxWidth is due to maxWidth being the old parameter that is no longer used

    const { data, columns } = this.convertContentToColumnsAndData()

    return (
      <TableContainer style={{ textAlign }}>
        <Container2
          $width={width}
          $maintainWidth={maintainWidth}
        >
          {tableHeader &&
            <TableHeader className="flipeditor-table-header">
              {tableHeader}
            </TableHeader>
          }
          <ReactTable
            data={data}
            columns={columns}
            showPagination={data.length > PAGE_SIZE}
            defaultPageSize={PAGE_SIZE}
            showPageSizeOptions={false}
            sortable={sortable}
            filterable={filterable}
            minRows={1}
            className={`-striped`}
          />
          {tableFooter &&
            <TableFooter className="flipeditor-table-footer">
              {tableFooter}
            </TableFooter>
          }
        </Container2>
      </TableContainer>
    )
  }
}

export default Table