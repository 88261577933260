import { useRef } from "react"
import { useTween as useTweenReactUse } from 'react-use'

const useTween = (...params) => {

  const hasHadCleanStart = useRef(false)

  let tweenNum = useTweenReactUse(...params)

  if(tweenNum < 1) {
    hasHadCleanStart.current = true
  }

  if(!hasHadCleanStart.current) {
    tweenNum = 0
  }

  if(params[1] === 0) {
    tweenNum = 1
  }

  return tweenNum
}

export default useTween