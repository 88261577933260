import { memo, useCallback, useState, useRef, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { getStroke } from 'perfect-freehand'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ReplayIcon from '@material-ui/icons/Replay'
import { usePrevious } from 'react-use'

import getSvgPathFromStroke from '../../utils/getSvgPathFromStroke'
import useInstanceValue from '../../hooks/useInstanceValue'
import useTween from '../../hooks/useTween'
import useSetTimeout from '../../hooks/useSetTimeout'
import useRefState from '../../hooks/useRefState'
import { sketchUtensilInfos, pressureUtensilInfos } from '../../utils/formatting'
import { cloneObj } from '../../utils/misc'

const NUM_PSEUDO_POINTS_BETWEEN_STROKES = 5
const NUM_POINTS_TO_START_STROKES_WITH = 5

const Container = styled.div`
  position: relative;
  user-select: none;
`

const Svg = styled.svg`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: none;

  ${({ onPointerDown }) => onPointerDown ? `` : `
    pointer-events: none;
  `}
`

const ReplayIconButton = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 8px;
  z-index: 1;
  transition: opacity .3s ease-in-out;
  opacity: ${({ $visible }) => $visible ? 1 : 0};
  pointer-events: ${({ $visible }) => $visible ? `all` : `none`};

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const Path = styled.path`
  fill: ${({ theme, $color, $utensil }) => (theme.palette.markupColors[$color] || {})[[ `HIGHLIGHTER`, `MINIHIGHLIGHTER` ].includes($utensil) ? `highlight` : `arrow`] || $color};
  opacity: ${({ $selected }) => $selected ? .5 : 1};
`

const getStrokeOptionsObj = strokeOptions => {
  const strokeOptionsObj = cloneObj(strokeOptions)
  if(typeof strokeOptions.easing === `function`) {
    for(let utensilType in pressureUtensilInfos) {
      if(strokeOptions.easing === pressureUtensilInfos[utensilType].easing) {
        strokeOptionsObj.easing = utensilType
        break
      }
    }
  }
  return strokeOptionsObj
}

const getStrokeOptionsFromObj = strokeOptionsObj => {
  const strokeOptions = cloneObj(strokeOptionsObj || {})
  if(typeof strokeOptions.easing === `string`) {
    strokeOptions.easing = pressureUtensilInfos[strokeOptions.easing].easing
  }
  return strokeOptions
}

const getAdjustedStroke = (...params) => {

  const stroke = getStroke(...params)
    .map(point => (
      point.map(xOrY => (
        Math.round(xOrY * 100, 10) / 100
      ))
    ))

  return stroke
}

const FreehandContainer = ({
  children,
  svgStrokes=[],
  updateSvgStrokes,
  selectedStrokeIdx,
  options,
  animate,
  readyForAnimation=true,
  animationMsPerPoint=30,
  ...otherProps
}) => {

  const svgRef = useRef()
  const svgBoundingClientRect = useRef({})

  const [ currentPoints, setCurrentPoints ] = useState()
  const getCurrentPoints = useInstanceValue(currentPoints)

  const [ simulatePressure, setSimulatePressure, getSimulatePressure ] = useRefState(true)
  const [ sampleMovements, setSampleMovements ] = useState(false)
  const pointIdx = useRef(0)
  const [ resentingAnimation, setResetingAnimation ] = useState(false)
  const [ setResetAnimationTimeout ] = useSetTimeout()

  animate = !!(animate && !updateSvgStrokes && svgStrokes.every(({ points }) => !!points) && !resentingAnimation)
  const totalNumberOfPoints = animate && svgStrokes.map(({ points }, idx) => points.length - (NUM_POINTS_TO_START_STROKES_WITH - 1) + (idx < svgStrokes.length-1 ? NUM_PSEUDO_POINTS_BETWEEN_STROKES : 0)).reduce((acc, val) => acc + val, 0)
  const tweenMs = (animate && readyForAnimation) ? totalNumberOfPoints * animationMsPerPoint : 0
  const previousTweenMn = usePrevious(tweenMs)
  let portionOfPointsToShow = useTween(`linear`, tweenMs)

  if(tweenMs && tweenMs !== previousTweenMn) {
    // initially, when tweenMs changes, portionOfPointsToShow gets wrongly set to 1; set it to 0 instead
    portionOfPointsToShow = 0
  }

  if(animate && (portionOfPointsToShow < 1 || !readyForAnimation)) {
    const newSvgStrokes = cloneObj(svgStrokes)
    let numPointsStillToShow = readyForAnimation ? totalNumberOfPoints * portionOfPointsToShow : 0
    for(let idx=0; idx<svgStrokes.length; idx++) {
      if(numPointsStillToShow <= 0) {
        newSvgStrokes[idx].points = []
      } else {
        newSvgStrokes[idx].points = newSvgStrokes[idx].points.slice(0, numPointsStillToShow + (NUM_POINTS_TO_START_STROKES_WITH - 1))
        numPointsStillToShow -= newSvgStrokes[idx].points.length - (NUM_POINTS_TO_START_STROKES_WITH - 1)
        numPointsStillToShow -= NUM_PSEUDO_POINTS_BETWEEN_STROKES
        numPointsStillToShow = Math.max(numPointsStillToShow, 0)
      }
    }
    svgStrokes = newSvgStrokes
  }

  const utensilInfo = useMemo(
    () => {
      const info = sketchUtensilInfos[(options || {}).utensil] || sketchUtensilInfos.MARKER
      if(info.pressureOverrideOn && !simulatePressure) {
        return pressureUtensilInfos[(options || {}).utensil] || pressureUtensilInfos.MARKER
      }
      return info
    },
    [ options, simulatePressure ],
  )

  const redoAnimation = useCallback(
    () => {
      setResetingAnimation(true)
      setResetAnimationTimeout(() => setResetingAnimation(false), 1)
    }, 
    [ setResetAnimationTimeout ],
  )

  const getPoint = useCallback(
    event => {

      const point = [
        event.pageX - svgBoundingClientRect.current.x,
        event.pageY - svgBoundingClientRect.current.y,
      ]
      if(!getSimulatePressure()) {
        point.push(
          utensilInfo.pressureOverrideOn
            ? parseInt(event.pressure * 100, 10) / 100
            : .5
        )
      }

      return point

    }, 
    [ getSimulatePressure, utensilInfo ],
  )

  const handlePointerDown = useCallback(
    event => {
      if(event.buttons === undefined) return  // prevent ios "scribble" call
      svgBoundingClientRect.current = svgRef.current.getBoundingClientRect()
      event.target.setPointerCapture(event.pointerId)
      const hasPressureData = event.pressure !== 0.5  // 0.5 indicates that hardware does not support pressure
      setSimulatePressure(!utensilInfo.pressureOverrideOn || !hasPressureData)
      setSampleMovements(hasPressureData)
      setCurrentPoints([getPoint(event)])
      pointIdx.current = 0
    }, 
    [ utensilInfo, getPoint, setSimulatePressure ],
  )

  const handlePointerMove = useCallback(
    event => {
      if(event.buttons === undefined) return  // prevent ios "scribble" call
      if(!getCurrentPoints()) return  // invalid if the sketch starts off the image
      if(event.buttons !== 1) return
      if(sampleMovements) {
        if(pointIdx.current++ % 7 !== 0) return
      }
      setCurrentPoints([
        ...getCurrentPoints(),
        getPoint(event),
      ])
    },
    [ getCurrentPoints, sampleMovements, getPoint ],
  )

  const handlePointerUp = useCallback(
    event => {
      if(event.buttons === undefined) return  // prevent ios "scribble" call
      if(!getCurrentPoints()) return  // invalid if the sketch starts off the image
      if(event.buttons !== 0) return
      updateSvgStrokes([
        ...svgStrokes,
        {
          points: getCurrentPoints(),
          strokeOptionsObj: getStrokeOptionsObj({
            ...utensilInfo,
            simulatePressure,
          }),
          options,
        },
      ])
      setCurrentPoints()
    },
    [ svgStrokes, options, utensilInfo, simulatePressure, getCurrentPoints, updateSvgStrokes ],
  )

  return (
    <Container {...otherProps}>

      {children}

      <Svg
        ref={svgRef}
        onPointerDown={updateSvgStrokes ? handlePointerDown : null}
        onPointerMove={updateSvgStrokes ? handlePointerMove : null}
        onPointerUp={updateSvgStrokes ? handlePointerUp : null}
      >
        {svgStrokes.map(({ points, strokeOptionsObj, stroke, options }, idx) => (
          <Path
            key={idx}
            d={
              points
                ? getSvgPathFromStroke(getAdjustedStroke(points, getStrokeOptionsFromObj(strokeOptionsObj)))
                : getSvgPathFromStroke(stroke)
            }
            $color={options.color}
            $utensil={options.utensil}
            $selected={idx === selectedStrokeIdx}
          />
        ))}
        {!!currentPoints &&
          <Path
            d={
              getSvgPathFromStroke(
                getAdjustedStroke(
                  currentPoints,
                  {
                    ...utensilInfo,
                    simulatePressure,
                  }
                )
              )
            }
            $color={options.color}
            $utensil={options.utensil}
          />
        }
      </Svg>

      <Tooltip
        title={i18n("Replay animation")}
        placement="top"
      >
        <ReplayIconButton
          $visible={animate && readyForAnimation && portionOfPointsToShow === 1}
          onClick={redoAnimation}
        >
          <ReplayIcon />
        </ReplayIconButton>
      </Tooltip>

    </Container>
  )
}

export default memo(FreehandContainer)