import { memo, useCallback, useRef, useMemo } from 'react'
// import styled from 'styled-components'
// import { $getRoot, $getSelection } from 'lexical'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
// import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
// import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin'
// import { TablePlugin } from '@lexical/react/LexicalTablePlugin'
// import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin'
// import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'
// import { HeadingNode, QuoteNode } from '@lexical/rich-text'
// import { CodeNode } from '@lexical/code'
import { ListItemNode, ListNode } from '@lexical/list'
// import { LinkNode } from '@lexical/link'
// import { BannerNode, ImageNode } from './nodes'
// import { CustomDraggableBlockPlugin, DraggableWrapper } from './plugins'
import {
  $convertFromMarkdownString,
  // $convertToMarkdownString,
  TRANSFORMERS,
} from '@lexical/markdown'

import EditorContainer, { theme } from './EditorContainer'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import ToolbarPlugin from './ToolbarPlugin'
import MaxLengthPlugin from './MaxLengthPlugin'

// const URL_MATCHER =
//   /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

// const MATCHERS = [
//   (text) => {
//     const match = URL_MATCHER.exec(text)
//     if(match === null) {
//       return null
//     }
//     const fullMatch = match[0];
//     return {
//       index: match.index,
//       length: fullMatch.length,
//       text: fullMatch,
//       url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
//       // attributes: { rel: 'noreferrer', target: '_blank' }, // Optional link attributes
//     }
//   },
// ]

export const isEmptyContent = content => {
  try {
    const { root } = JSON.parse(content)
    return (
      root.children.length === 1
      && root.children[0].type === `paragraph`
      && root.children[0].children.length === 0
    )
  } catch(err) {}
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
const onError = (error) => {
  console.error(error)
  // throw error
}

const Editor = ({
  editable=true,
  complex,
  initialContent,  // can be markdown or lexical JSON
  onUpdate,
  autoFocus,
  maxLength,
  className,
  ...otherProps
}) => {

  editable = !!editable
  const editorRef = useRef(null)
  const latestValue = useRef(initialContent)

  const onChange = useCallback(
    editorState => {
      let editorStateJSON = editorState.toJSON()
      try {
        // prevent change from empty to empty to NOT fire onUpdate
        const { format, indent, direction, children } = editorStateJSON.root
        if(
          latestValue.current === ``
          && !format
          && !indent
          && !direction
          && children.length === 1
          && !children[0].format
          && !children[0].indent
          && !children[0].direction
          && children[0].type === 'paragraph'
          && children[0].children.length === 0
        ) {
          editorStateJSON = ``
        }
      } catch(err) {}
      const newValue = typeof editorStateJSON === `object` ? JSON.stringify(editorStateJSON) : editorStateJSON
      if(newValue !== latestValue.current) {
        onUpdate && onUpdate(JSON.stringify(editorState.toJSON()))
        latestValue.current = newValue
      }
    },
    [ onUpdate ],
  )

  const initialConfig = useMemo(
    () => {

      let editorState

      if(/^\{"root":/.test(initialContent)) {
        // looks like lexical JSON
        try {
          JSON.parse(initialContent)
          editorState = initialContent
        } catch(error) {}
      }

      if(!editorState) {
        // assume markdown
        editorState = () => $convertFromMarkdownString(initialContent || ``, TRANSFORMERS)
      }

      return {
        editable,
        editorState,
        // html,
        nodes: [
          // BannerNode,
          // HeadingNode,
          // ImageNode,
          // QuoteNode,
          // CodeNode,
          ListNode,
          ListItemNode,
          // LinkNode,
        ],
        // namespace: 'editor',
        theme,
        onError,
      }
    },
    [],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  useLayoutEffectAsync(
    () => {
      if(editorRef.current) {
        editorRef.current.setEditable(editable)
      }
    },
    [ editable ],
  )

  return (
    <EditorContainer className={`${className || ``} Editor-EditorContainer`}>
      <LexicalComposer initialConfig={initialConfig}>
        {editable && <ToolbarPlugin complex={complex} />}
        {!!maxLength && <MaxLengthPlugin maxLength={maxLength} />}
        <RichTextPlugin
          contentEditable={<ContentEditable {...otherProps} />}
          // placeholder={<div>Enter some text...</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <OnChangePlugin
          onChange={onChange}
          // ignoreHistoryMergeTagChange={false}  // true by default
          // ignoreSelectionChange
        />
        {!!autoFocus && <AutoFocusPlugin />}
        {/* {false && <LinkPlugin />} */}
        {<ListPlugin />}
        {/* {false && <CheckListPlugin />} */}
        {/* {false && <TablePlugin />} */}
        {/* {false && <AutoLinkPlugin matchers={MATCHERS} />} */}
        {/* {false && <MarkdownShortcutPlugin />} */}
        {<EditorRefPlugin editorRef={editorRef} />}
      </LexicalComposer>
    </EditorContainer>
  );
}

export default memo(Editor)
