import { useCallback, useState } from "react"
import { v4 as uuidv4 } from 'uuid'

import useGoUpdateModule from "./useGoUpdateModule"
import useDuplicateModuleSettings from './useDuplicateModuleSettings'
import useDuplicateModulePassages from './useDuplicateModulePassages'
import useDuplicateModulePieces from './useDuplicateModulePieces'
import useDuplicateModuleDots from "./useDuplicateModuleDots"
import useDuplicateModuleMarkups from "./useDuplicateModuleMarkups"
import useInstanceValue from "./useInstanceValue"
import { getNewOrdering, cloneObj } from '../utils/misc'

const useDuplicateModule = ({
  module,
  moduleByProject,
  newOrdering,
}) => {

  const getModule = useInstanceValue(module)

  const projectId = moduleByProject.id.split(':')[1]
  const oldOrdering = moduleByProject.ordering

  const [ newModuleId, setNewModuleId ] = useState(uuidv4())

  const [ goCreateModule ] = useGoUpdateModule({ projectId })

  const goDuplicateModulePassages = useDuplicateModulePassages({
    moduleToCopyFrom: module,
    newModuleId,
    projectId,
  })

  const goDuplicateModuleSettings = useDuplicateModuleSettings({
    moduleToCopyFrom: module,
    newModuleId,
    projectId,
  })

  const goDuplicateModulePieces = useDuplicateModulePieces({
    moduleToCopyFrom: module,
    newModuleId,
    projectId,
  })

  const goDuplicateModuleDots = useDuplicateModuleDots({
    moduleToCopyFrom: module,
    newModuleId,
    projectId,
  })

  const goDuplicateModuleMarkups = useDuplicateModuleMarkups({
    moduleToCopyFrom: module,
    newModuleId,
    projectId,
  })

  const goDuplicateModule = useCallback(
    async () => {

      const ordering = newOrdering !== undefined ? newOrdering : getNewOrdering(oldOrdering)
      const newModuleData = cloneObj(getModule())
      delete newModuleData.modulePassages
      delete newModuleData.createdAt
      delete newModuleData.savedAt
      delete newModuleData.modifiedAt
      delete newModuleData.openedOrModifiedAt
      delete newModuleData.deletedAt
      delete newModuleData.userId
      delete newModuleData.__typename
      newModuleData.id = newModuleId
      newModuleData.shared = false
      newModuleData.public = false

      goCreateModule(
        newModuleData,
        { ordering },
        { skipCreateModuleSettings: true },
      )

      const { modulePassageIdMap } = goDuplicateModulePassages()
      await Promise.all([
        goDuplicateModuleSettings(),
        (async () => {
          const { modulePieceIdMap } = await goDuplicateModulePieces()
          const { moduleDotIdMap } = await goDuplicateModuleDots({ modulePieceIdMap })
          await goDuplicateModuleMarkups({ modulePassageIdMap, modulePieceIdMap, moduleDotIdMap })
        })(),
      ])

      setNewModuleId(uuidv4())

    },
    [ getModule, goCreateModule, goDuplicateModulePassages, goDuplicateModuleSettings, goDuplicateModulePieces, goDuplicateModuleDots, goDuplicateModuleMarkups, newModuleId, newOrdering, oldOrdering ],
  )

  return goDuplicateModule
}

export default useDuplicateModule
