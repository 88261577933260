import { memo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'

const Container = styled.div`
`

const TagContainer = styled.div`
  margin: -4px 15px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  &:empty {
    display: none;
  }
`

const filter = createFilterOptions()

const TagEditor = ({
  options,
  tags,
  updateTags,
  prefix=``,
  StyledTextField,
  chipColor,
  textFieldProps,
  ...otherProps
}) => {

  StyledTextField = StyledTextField || TextField

  const [ newTag, setNewTag ] = useState(``)

  const onChangeAddNewTag = useCallback((event, tag) => setNewTag(tag), [ setNewTag ])

  const addTag = useCallback(
    (event, newTag) => {
      if(!(newTag || {}).tag) return

      const existingTags = [ ...tags ]
      if(!existingTags.some(({ tag }) => tag === newTag.tag)) {
        updateTags([ ...existingTags, newTag ])
      }

      requestAnimationFrame(() => setNewTag(``))
    },
    [ tags, updateTags ],
  )

  const removeTag = useCallback(
    tagToRemove => {
      updateTags(
        tags.filter(({ tag }) => tag !== tagToRemove)
      )
    },
    [ tags, updateTags ],
  )

  const filterAddNewTagOptions = useCallback(
    (options, params) => {
      const existingTagTags = tags.map(({ tag }) => tag)
      const filtered = filter(options, params).filter(({ tag }) => !existingTagTags.includes(tag))

      // Suggest the creation of a new value
      const newTagSuggestion = (params.inputValue.replace(/ /g, '-').match(/\p{L}(?:\p{L}|[-_+&/%])+\p{L}/gu) || [])[0]
      if(newTagSuggestion && !filtered.some(({ tag }) => tag === `${prefix}${newTagSuggestion}`)) {
        filtered.push({
          tag: `${prefix}${newTagSuggestion}`,
          label: `Add #${newTagSuggestion}`
        })
      }

      return filtered
    },
    [ tags, prefix ],
  )

  return (
    <Container {...otherProps}>

      <Autocomplete
        onInputChange={onChangeAddNewTag}
        onChange={addTag}
        options={options}
        filterOptions={filterAddNewTagOptions}
        getOptionLabel={option => option.label || `#${option.tag}`}
        value={null}
        autoHighlight
        clearOnBlur
        freeSolo
        handleHomeEndKeys
        inputValue={newTag}
        renderInput={params => (
          <StyledTextField
            {...params}
            label={i18n("Add Tags")}
            variant="outlined"
            {...textFieldProps}
          />
        )}
      />

      <TagContainer>
        {tags.map(({ tag }) => (
          <Chip
            key={tag}
            label={`#${tag.slice(prefix.length)}`}
            color={chipColor}
            size="small"
            onDelete={() => removeTag(tag)}
          />
        ))}
      </TagContainer>

    </Container>
  )
}

export default memo(TagEditor)