import { gql } from '@apollo/client'

const chapter = gql`
  query chapter($bookId: Int!, $chapter: Int!, $versionId: ID!) {
    chapter(bookId: $bookId, chapter: $chapter, versionId: $versionId) {
      id
      usfm
    }
  }
`

export default chapter