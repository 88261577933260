import { memo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

const Container = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: row-reverse;

  @media(max-width: 730px) {
    flex-direction: column;
  }
`

const DevWorldImgContainer = styled.div`
  width: 180px;

  @media(max-width: 730px) {
    width: 100%;
  }`

const DevWorldImg = styled.img`
  width: 100%;
  height: auto;
  vertical-align: top;
  margin: 0 0 7px 15px;

  @media(max-width: 730px) {
    width: auto;
    height: 130px;
    max-height: calc((100vw - 100px) * .25);
    margin: 0 7px 10px 0;
  }
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Paragraph = styled.p`
  margin: 0 0 10px;
`

const KeySentence = styled.span`
  font-weight: 500;
`

const AskSentence = styled.span`
  font-style: italic;
`

const DonationSection = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
`

const DonationSectionHeading = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`

const CustomDonation = styled.div`
  display: flex;
`

const Buttons = styled.div`
`

const DonateButton = styled(Button)`
  height: 56px;
  margin: 7px;
`

const NoThanksButton = styled(Button)`
  align-self: center;
  margin: 15px 0;
`

const CustomTextField = styled(TextField)`
  margin: 7px;
  flex: 1;
`

const TaxDeductible = styled.div`
  text-align: center;
`

const suggestedMonthlyDonationAmounts = [ 5, 10, 25, 50, 100 ]
const suggestedAnnualDonationAmounts = [ 25, 50, 100, 250, 1000 ]

const Partner = ({
  term,
  setDonation,
}) => {

  const [ customDonationAmount, setCustomDonationAmount ] = useState("")

  const updateCustomDonationAmount = useCallback(
    ({ target }) => {
      setCustomDonationAmount(target.value.replace(/[^0-9.]/g, ''))
    },
    [ setCustomDonationAmount ],
  )

  const selectAmount = useCallback(
    ({ currentTarget }) => {
      const amount = parseInt(currentTarget.getAttribute('data-amount'), 10)
      setDonation(amount)
    },
    [ setDonation ],
  )

  const customDonationAmountIsValid = (
    customDonationAmount.length > 0
    && /^(?:[1-9][0-9]*)?(?:\.[0-9]{2})?$/.test(customDonationAmount)
  )

  return (
    <Container>

      <DevWorldImgContainer>
        <DevWorldImg src="/arabic_bible.png" className="dark-mode-exempt" />
        <DevWorldImg src="/asian_man.png" className="dark-mode-exempt" />
        <DevWorldImg src="/woman_with_bible.png" className="dark-mode-exempt" />
      </DevWorldImgContainer>

      <ContentContainer>

        <Paragraph>
          {i18n("Our mission is to strengthen the worldwide Church through empowering deeper study of Scripture.")}
        </Paragraph>

        <Paragraph>
          {i18n("Inline with this aim, we provide the Biblearc TOOLS to those in the developing countries under a whatever-you-can-afford policy, and give scholarships to Biblearc EQUIP whenever possible.")}
          {` `}
          {i18n("As a result, we have had the privelege to strengthen many missionaries and nationals as they serve the Lord in hard places.")}
        </Paragraph>

        <Paragraph>
          <KeySentence>
            {i18n("Yet we want to do more.")}
          </KeySentence>
        </Paragraph>
        
        <Paragraph>
          {i18n("For that reason, we are inviting you to add on a regular donation to your subscription fee.")}
          {` `}
          {i18n("Doing so will enable us to grant more scholarships and translate more of our resources.")}
          {` `}
          <AskSentence>
            {i18n("Would you join us in promoting the sound handling of God’s word throughout the world?")}
          </AskSentence>
        </Paragraph>

        <DonationSection>

          <DonationSectionHeading>
            {term === "ANNUALLY"
              ? i18n("Give annually")
              : i18n("Give monthly")
            }
          </DonationSectionHeading>

          <Buttons>

            {(term === "ANNUALLY" ? suggestedAnnualDonationAmounts : suggestedMonthlyDonationAmounts).map(suggestedDonationAmount => (
              <DonateButton
                key={suggestedDonationAmount}
                data-amount={suggestedDonationAmount}
                variant="contained"
                disableElevation
                onClick={selectAmount}
              >
                {i18n("${{amount}}", {  // eslint-disable-line no-template-curly-in-string
                  amount: suggestedDonationAmount,
                })}
              </DonateButton>
            ))}

          </Buttons>

          <CustomDonation>
            <CustomTextField
              variant="outlined"
              onChange={updateCustomDonationAmount}
              value={customDonationAmount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {i18n("$")}
                  </InputAdornment>
                ),
              }}
    
            />
            <DonateButton
              data-amount={customDonationAmount}
              variant="contained"
              disableElevation
              onClick={selectAmount}
              disabled={!customDonationAmountIsValid}
            >
              {i18n("Give")}
            </DonateButton>
          </CustomDonation>

          <NoThanksButton
            data-amount="0"
            variant="outlined"
            disableElevation
            onClick={selectAmount}
          >
            {i18n("No thank you")}
          </NoThanksButton>

          <TaxDeductible>
            {i18n("Donations are tax-deductible.")}
          </TaxDeductible>

        </DonationSection>

      </ContentContainer>

    </Container>
  )
}


export default memo(Partner)