import { memo, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n, getLocale } from 'inline-i18n'
import { useMutation } from '@apollo/client'
import { useLocation } from "react-router-dom"
import { useUnmount } from 'react-use'

import usePassageRefs from '../../hooks/usePassageRefs'
import useUpdatedChannelItem from '../../hooks/useUpdatedChannelItem'
import useAccountSetting from '../../hooks/useAccountSetting'
import useUpdateListeningHistory from '../../hooks/useUpdateListeningHistory'
import { cloneObj, findBookmarkInsertIdx } from '../../utils/misc'

import VideoPlayer from '../common/VideoPlayer'
import RelativeTime from '../common/RelativeTime'

import addReactionToChannelItemMutation from '../../graphql/mutations/addReactionToChannelItem'
import removeReactionFromChannelItemMutation from '../../graphql/mutations/removeReactionFromChannelItem'
import updateChannelItemBookmarkMutation from '../../graphql/mutations/updateChannelItemBookmark'

const Container = styled.div`
  width: 400px;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 10px);
  border-radius: 0;
  display: flex;
  flex-direction: column;
  user-select: none;
`

const VideoContainer = styled.div`
  position: relative;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 11px 3px 0;
  user-select: text;
`

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  line-height: 1.2;
`

const EventType = styled.div`
  font-weight: 300;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// const Broadcaster = styled.div`
//   font-weight: 300;
//   font-size: 12px;
// `

const Speaker = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  margin-bottom: 10px;
`

const SpeakerName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 1;
`

const DateSpot = styled.div`
  font-weight: 200;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NormalDate = styled.span`
  display: inline-block;
  font-size: 10px;
  margin-left: 2px;
`

const Downloads = styled.div`
  font-weight: 200;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PassageRefContainer = styled.div`
  font-size: 18px;
  font-weight: 200;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 5px;
  line-height: 1.2;
`

// const BroadcasterImage = styled.img`
//   width: 12px;
//   height: 12px;
//   margin-right: 4px;
//   object-fit: cover;
//   border-radius: 2px;
// `

const SpeakerImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
  object-fit: cover;
  border-radius: 50%;
`

const Placeholder = styled.div`
  width: 100%;
  max-width: min(100%, calc(100vw - 20px));
  background-color: black;
`

const Placeholder2 = styled.div`
  padding-bottom: ${({ $doAudio }) => $doAudio ? `210px` : `56.25%`};
  width: 100%;
`

export const removeBookmarkFromReactions = ({ type, updatedChannelItem }) => {
  const reactions = cloneObj(updatedChannelItem.reactions || {})
  reactions.myReactionTypes = reactions.myReactionTypes.filter(t => t !== type)
  if((reactions.details || {}).myCreatedAts) {
    delete reactions.details.myCreatedAts[type]
  }
  reactions[type]--
  if(reactions[type] === 0) {
    delete reactions[type]
  }
  return reactions
}

const addBookmarkToReactions = ({ type, updatedChannelItem }) => {
  const reactions = cloneObj(updatedChannelItem.reactions || {})
  const { myReactionTypes=[] } = reactions
  myReactionTypes.splice(findBookmarkInsertIdx({ myReactionTypes, bookmarkMs: parseInt(type.split(`:`)[1], 10) }), 0, type)
  reactions.myReactionTypes = myReactionTypes
  reactions[type] = reactions[type] || 0
  reactions[type]++
  return reactions
}

const ChannelItem = ({
  studyBibleItem,
  onClose,
  versionId,
  autoplay=true,
}) => {

  const location = useLocation()
  const updatedChannelItem = useUpdatedChannelItem(studyBibleItem)
  const isOnMessagePage = new RegExp(`^(/church/[^/]+)?/message/${studyBibleItem.id}$`).test(location.pathname)
  const { studyBibleFollow: { channelId }={} } = studyBibleItem

  const {
    id,
    url,
    // heading,
    title,
    // type,
    info,
    // thumbnailImageUrl,
    // status,
    // fromLoc,
    // toLoc,
    // editHistory,
    // adminRating,
    // userId,
    // details=[],
    // tags,
    // createdAt,
    // updatedAt,
    // deletedAt,
    reactions,
  } = updatedChannelItem

  const {
    sermonID,
    // languageCode,
    eventType,
    // broadcasterID,
    broadcasterName,
    // broadcasterImageUrl,
    // broadcasterLocation,
    // broadcasterMinister,
    // broadcasterSiteUrl,
    // broadcasterSermonCount,
    // speakerID,
    speakerName,
    speakerImageUrl,
    // speakerSermonCount,
    audioDuration,
    videoDuration,
    audioUrl,
    videoUrl,
    downloads,
    date,
    dateIsApproximate,
    locSets=[],
  } = info || {}

  // const isChannelPage = new RegExp(`^/church/${channelId}(?:/|$)`).test(location.pathname)

  const [ defaultSpeed, setDefaultSpeed ] = useAccountSetting(`def-channel-speed`, 1)
  const [ defaultDoAudio, setDefaultDoAudio ] = useAccountSetting(`def-channel-do-audio`, false)  // i.e. audio or video (when both available)
  const [ listeningHistory, setListeningHistory, x, loadingListeningHistory ] = useAccountSetting(`listening-history`, [])  // eslint-disable-line no-unused-vars
  const thisListeningHistoryItem = listeningHistory.find(item => item.id === id)

  const hasVideoOption = !!(videoDuration || videoUrl)
  const doAudio = (
    thisListeningHistoryItem
      ? thisListeningHistoryItem.doAudio
      : (
        defaultDoAudio
        || !hasVideoOption
      )
  )

  const updateListeningHistory = useUpdateListeningHistory({
    channelItem: updatedChannelItem,
    doAudio,
    channelId,
  })

  const goSetDoAudio = useCallback(
    doAudio => {
      setDefaultDoAudio(doAudio)
      updateListeningHistory({ doAudio })
    },
    [ setDefaultDoAudio, updateListeningHistory ],
  )

  const [ addReactionToChannelItem ] = useMutation(addReactionToChannelItemMutation)
  const [ removeReactionFromChannelItem ] = useMutation(removeReactionFromChannelItemMutation)
  const [ updateChannelItemBookmark ] = useMutation(updateChannelItemBookmarkMutation)

  const passageRefs = usePassageRefs({
    locSets,
    convertToVersionId: versionId,
    abbreviated: locSets.length > 1,
  })

  useUnmount(() => updateListeningHistory({ open: false }))

  const titleJsx = useMemo(
    () => (
      <TitleContainer>
        
        <Title>
          {title}
        </Title>

        <PassageRefContainer className="ChannelItem-PassageRefContainer">
          {passageRefs}
        </PassageRefContainer>

        {/* {!isChannelPage && !!broadcasterName &&
          <Broadcaster>
            <BroadcasterImage
              src={broadcasterImageUrl}
            />
            {broadcasterName}
          </Broadcaster>
        } */}

        {!!speakerName &&
          <Speaker>
            <SpeakerImage
              src={speakerImageUrl}
            />
            <SpeakerName>
              {speakerName}
            </SpeakerName>
          </Speaker>
        }

        {!!eventType &&
          <EventType>
            {eventType}
          </EventType>
        }

        {!!date &&
          <DateSpot>
            <RelativeTime date={date} />
            {` `}
            {!dateIsApproximate &&
              <NormalDate>
                {i18n("({{parenthetical_text}})", { parenthetical_text: new Date(date || 0).toLocaleDateString(getLocale()) })}
              </NormalDate>
            }
          </DateSpot>
        }

        {!!downloads &&
          <Downloads>
            {i18n("{{num}} downloads", { num: downloads.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") })}
          </Downloads>
        }

      </TitleContainer>
    ),
    [ title, eventType, date, downloads, speakerImageUrl, speakerName, passageRefs, dateIsApproximate ],
  )

  const getBaseChannelItemQueryResult = useCallback(
    () => {

      const { __typename, id, title, heading, thumbnailImageUrl, info } = updatedChannelItem

      return {
        __typename,
        id,
        title,
        heading,
        thumbnailImageUrl,
        info,
      }

    },
    [ updatedChannelItem ],
  )

  const addBookmark = useCallback(
    bookmarkMs => {

      const type = `BOOKMARK:${bookmarkMs}`
      const reactions = addBookmarkToReactions({ type, updatedChannelItem })

      addReactionToChannelItem({
        variables: {
          channelItemId: updatedChannelItem.id,
          input: {
            type,
          },
        },
        optimisticResponse: {
          addReactionToChannelItem: {
            ...getBaseChannelItemQueryResult(),
            reactions,
          },
        },
      })

    },
    [ addReactionToChannelItem, updatedChannelItem, getBaseChannelItemQueryResult ],
  )

  const removeBookmark = useCallback(
    type => {

      const reactions = removeBookmarkFromReactions({ type, updatedChannelItem })

      removeReactionFromChannelItem({
        variables: {
          channelItemId: updatedChannelItem.id,
          type,
        },
        optimisticResponse: {
          removeReactionFromChannelItem: {
            ...getBaseChannelItemQueryResult(),
            reactions,
          },
        },
      })

    },
    [ removeReactionFromChannelItem, updatedChannelItem, getBaseChannelItemQueryResult ],
  )

  const updateBookmark = useCallback(
    ({ oldMs, newMs }) => {

      const reactionsInProgress = addBookmarkToReactions({ type: `BOOKMARK:${newMs}`, updatedChannelItem })
      const reactions = removeBookmarkFromReactions({
        type: `BOOKMARK:${oldMs}`,
        updatedChannelItem: {
          ...updatedChannelItem,
          reactions: reactionsInProgress,
        },
      })

      updateChannelItemBookmark({
        variables: {
          channelItemId: updatedChannelItem.id,
          oldMs,
          newMs,
        },
        optimisticResponse: {
          updateChannelItemBookmark: {
            ...getBaseChannelItemQueryResult(),
            reactions,
          },
        },
      })

    },
    [ updateChannelItemBookmark, updatedChannelItem, getBaseChannelItemQueryResult ],
  )

  // TODO: Have this change for fullscreen mode
  const quality = `lo_`
  // const quality = ``  // normal
  // const quality = `1080p_`  // not available for all video

  let src = (
    doAudio
      ? (audioUrl || videoUrl)
      : videoUrl
  ) || url

  if(/sermonaudio/.test(url)) {
    src = (
      doAudio
        ? `https://media-cloud.sermonaudio.com/audio/${sermonID}.mp3`
        : `https://media-cloud-video.sermonaudio.com/1/,abr_video_${quality}${sermonID}.mp4,.urlset/master.m3u8`
    )
  }

  let studyBibleLink = `/message/${encodeURIComponent(id)}`
  const [ churchPath ] = location.pathname.match(/^\/church\/[^/]+/) || []
  if(churchPath) {
    studyBibleLink = `${churchPath}${studyBibleLink}`
  }

  let duration = (doAudio && audioDuration) || videoDuration
  duration = typeof duration === `number` ? duration : null

  return (
    <Container className="ChannelItem-Container">

      <VideoContainer>
        {!!loadingListeningHistory &&
          <Placeholder className="dark-mode-exempt">
            <Placeholder2 $doAudio={doAudio} />
          </Placeholder>
        }
        {!loadingListeningHistory &&
          <VideoPlayer
            key={id}
            id={id}
            src={src}
            autoplay={autoplay}
            customControls
            onClose={isOnMessagePage ? null : onClose}
            sourceLink={url}
            studyBibleLink={studyBibleLink}
            reactions={reactions}
            addBookmark={addBookmark}
            removeBookmark={removeBookmark}
            updateBookmark={updateBookmark}
            duration={duration}
            initialCurrentSec={(thisListeningHistoryItem || {}).currentSecs}
            defaultSpeed={(thisListeningHistoryItem || {}).speed || defaultSpeed}
            setDefaultSpeed={setDefaultSpeed}
            // numComments={numComments}
            // updateMyBookmarks={updateMyBookmarks}
            title={titleJsx}
            doAudio={doAudio}
            setDoAudio={hasVideoOption ? goSetDoAudio : null}
            hasAudio={!!audioDuration}
            updateListeningHistory={updateListeningHistory}
            sharingTitle={i18n("{{title}} ({{passage_refs}})", {
              title,
              passage_refs: passageRefs,
            })}
            sharingText={i18n("By {{author}}", {
              author: speakerName || broadcasterName,
            })}
          />
        }
      </VideoContainer>

    </Container>
  )
}

export default memo(ChannelItem)