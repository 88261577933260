import { gql } from '@apollo/client'

const definition = gql`
  query definition($id: ID!) {
    definition(id: $id) {
      id
      lex
      nakedLex
      lexUnique
      vocal
      simplifiedVocal
      hits
      lxx
      lemmas
      forms
      pos
      gloss
      syn
      rel
      lexEntry
      editorId
    }
  }
`

export default definition