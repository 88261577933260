import { getEmbedProjectId } from "./embedLink"
import { addNewPassageToModulePiece } from "./embedLinkQueries"

const embedLinkMutations = async ({
  operationName,
  variables,
  embedData,
  embedSettings,
  embedMode,
  goSave,
  expectedResponse,
}) => {

  console.log('save to embed', operationName, variables)

  const projectId = getEmbedProjectId()
  const savedAt = variables.savedAt || (variables.input || {}).savedAt || ((variables.input || [])[0] || {}).savedAt || Date.now()

  const updateModifiedAtForModule = moduleId => {
    goSave({
      key: `Module:${moduleId}`,
      newValues: {
        modifiedAt: savedAt,
        openedOrModifiedAt: savedAt,
      },
    })
  }
  
  const updateModifiedAtForProject = () => {
    goSave({
      key: `Project:${projectId}`,
      newValues: {
        modifiedAt: savedAt,
        openedOrModifiedAt: savedAt,
      },
    })
  }

  switch(operationName) {

    case `updateProject`: 
    case `updateModule`:
    case `updateModuleByProject`:
    case `updateFormattingKey`: {

      let { id, input } = variables

      const key = `${operationName.replace(/^update/, ``)}:${id}`

      if(operationName === `updateModuleByProject`) {
        input = {
          ...input,
          projectId,
        }
      }

      goSave({
        key,
        newValues: input,
      })

      if(input.savedAt && ![ `updateFormattingKey`, `updateProject` ].includes(operationName)) {
        updateModifiedAtForProject()
      }

      return expectedResponse[operationName]

    }

    case `deleteModule`:
    case `deleteModuleByProject`:
    case `deleteFormattingKey`: {

      const { id } = variables

      const key = `${operationName.replace(/^delete/, ``)}:${id}`

      goSave({
        key,
        value: undefined,
      })

      if(operationName === `deleteModule`) {
        goSave({
          key: `ModuleByProject:${id}:${projectId}`,
          value: undefined,
        })
      }

      if(operationName === `deleteModuleByProject`) {
        goSave({
          key: `Module:${id.split(':')[0]}`,
          value: undefined,
        })
      }

      return expectedResponse[operationName]

    }

    case `setAccountSettings`: {

      const { input } = variables

      input.forEach(({ id, value }) => {
        goSave({
          key: `AccountSetting:${id}`,
          value: {
            __typename: `AccountSetting`,
            id,
            value,
          },
        })
      })

      return expectedResponse[operationName]

    }

    case `setModuleSetting`: {

      const { id, input: { value, savedAt } } = variables
      const key = `ModuleSetting:${id}`

      goSave({
        key,
        value: {
          __typename: `ModuleSetting`,
          id,
          value,
          savedAt,
        },
      })

      updateModifiedAtForModule(id.split(`:`)[0])
      updateModifiedAtForProject()

      return expectedResponse[operationName]

    }

    case `updateModulePassages`:
    case `updateModulePieces`:
    case `updateModuleDots`:
    case `updateModuleMarkups`: {

      const keyPrefix = operationName.replace(/^update|s$/g, ``)

      const { input, moduleId } = variables

      for(let inputItem of input) {

        const { id, ...newValues } = inputItem
        const key = `${keyPrefix}:${id}`
        newValues.moduleId = moduleId

        goSave({
          key,
          newValues,
        })

      }

      updateModifiedAtForModule(moduleId)
      updateModifiedAtForProject()

      if(
        operationName === `updateModulePieces`
        && embedSettings.embedType === `passages`
        && embedMode === `prep`
      ) {
        // add on a new passage
        addNewPassageToModulePiece(expectedResponse[operationName].modulePiece.rows[0])
      }

      return expectedResponse[operationName]

    }

    case `deleteModulePassages`:
    case `deleteModulePieces`:
    case `deleteModuleDots`:
    case `deleteModuleMarkups`: {

      const keyPrefix = operationName.replace(/^delete|s$/g, ``)

      const { moduleId, ids } = variables

      Object.values(embedData)
        .filter(row => (
          row.__typename === keyPrefix
          && row.moduleId === moduleId
          && ids.includes(row.id)
      ))
        .forEach(({ id }) => {
          goSave({
            key: `${keyPrefix}:${id}`,
            value: undefined,
          })
        })

      updateModifiedAtForModule(moduleId)
      updateModifiedAtForProject()
  
      return expectedResponse[operationName]

    }

    case `logProjectPassageHistoryItem`:
    case `logQueryItem`: {
      return true  // make these look like they worked
    }

    default: {
      return null
    }

  }

}
    
export default embedLinkMutations