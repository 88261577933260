import { memo, useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

// import { DEFAULT_NUM_VERSES_PER_BLOCK_FOR_DISCOURSE } from '../../../utils/constants'
// import useSetUpPiecesAfterPassageChangePhrasing from '../../../hooks/useSetUpPiecesAfterPassageChangePhrasing'
// import { getModuleTextFontSize } from '../../../utils/misc'
// import useDiscourseSettings from '../../../hooks/useDiscourseSettings'
import useLegacySettings from '../../../hooks/useLegacySettings'
import useEffectiveVisibility from '../../../hooks/useEffectiveVisibility'
import useDiscourseShortcuts from '../../../hooks/useDiscourseShortcuts'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useFocusContainerSetRef from '../../../hooks/useFocusContainerSetRef'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { useMeasure, useCounter } from 'react-use'

import { equalObjs } from '../../../utils/misc'

import LegacyIFrame from '../shared/LegacyIFrame'
import DotNotesButton from '../shared/DotNotesButton'
import ModuleContainer from '../shared/ModuleContainer'
import NewModuleHeadingAndPassageChooser from '../shared/NewModuleHeadingAndPassageChooser'
import NewToDiscourse from './NewToDiscourse'
import LegacyDiscourseSettings from './LegacyDiscourseSettings'
import LegacyDiscourseOptionsColumns from './LegacyDiscourseOptionsColumns'
import DiscourseOptionsChangePassage from './DiscourseOptionsChangePassage'
import ModuleTitleButton from '../shared/ModuleTitleButton'
import EditingButton from '../shared/EditingButton'
import ContainerWithPassagePopper from '../../passage/ContainerWithPassagePopper'

const noop = ()=>{}

const Container = styled.div`
  flex: 1;
  position: relative;
  display: flex;

  ${({ $moduleWidth }) => $moduleWidth > 700 ? `` : `
    flex-direction: column;
    justify-content: flex-start;
  `}
`

const PrintContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 9999;
`

const HeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: rgb(255 255 255/.85);
  backdrop-filter: blur(3px);
  z-index: 1;
`

const StyledContainerWithPassagePopper = styled(ContainerWithPassagePopper)`
  flex: 1;
  display: flex;
`

const Discourse = ({
  module,
  projectId,
  moduleByProject,
  onDeleteModuleByProject,
  noneditableViewingMode,
  sharedPrintDialogInfo,
  clearSharedPrintDialogInfo,
  goPrintEncompassingNotesTab,
  goSketch,
  setHeight,
  embedFullScreen,
  onLoad,
  ...otherProps
}) => {

  const { id, modulePassages } = module

  const [ printDialogInfo=sharedPrintDialogInfo, setPrintDialogInfo ] = useState()
  const [ dotNoteButtonClickCounter, { inc }] = useCounter(0)
  const incrementDotNoteButtonClickCounter = useCallback(() => inc(), [ inc ])

  const [ undoClickCounter, { inc: inc2 }] = useCounter(0)
  const incrementUndoClickCounter = useCallback(() => inc2(), [ inc2 ])

  let [ ref, { width: moduleWidth } ] = useMeasure()
  moduleWidth = moduleWidth || otherProps.width || 0
  const containerSetRef = useFocusContainerSetRef({ focusElSelector: `[data-module-id="${module.id}"]` })

  const effectiveVisibilityById = useEffectiveVisibility({ modulePassages, moduleWidth: 9999 })  // this is just a placeholder for now

  const closePrint = useCallback(
    () => {
      setPrintDialogInfo()
      clearSharedPrintDialogInfo && clearSharedPrintDialogInfo()
    },
    [ clearSharedPrintDialogInfo ],
  )

  // const setUpPiecesAfterPassageChange = useSetUpPiecesAfterPassageChangePhrasing({
  //   projectId,
  //   moduleId: id,
  //   defaultBlockSize: DEFAULT_NUM_VERSES_PER_BLOCK_FOR_DISCOURSE,
  // })

  // const { textSizesSetting, ...otherDiscourseSettings } = useDiscourseSettings({ moduleId: id })
  const { legacySetting, moduleSettingsLoading } = useLegacySettings({ moduleId: id })
  const { arcorbracket } = (legacySetting.value || {}).settings || {}

  const [ goUpdateModule ] = useGoUpdateModule({ module })
  const getModule = useInstanceValue(module)
  const cancelClickOrMoveRef = useRef()

  useDiscourseShortcuts({
    getModule,
    cancelClickOrMoveRef,  
    goUpdateModule,
    setPrintDialogInfo,
    noneditableViewingMode,
  })

  const versionIds = module.modulePassages.map(({ info: { versionId } }) => versionId)
  const [ displayedLegacySetting, setDisplayedLegacySetting ] = useState(legacySetting.value)

  useEffectAsync(
    () => {
      setDisplayedLegacySetting(legacySetting.value)
    },
    [ legacySetting, setDisplayedLegacySetting ],
  )

  const adjustmentType = (
    (displayedLegacySetting !== legacySetting.value && !equalObjs(displayedLegacySetting, {}) && `textsizes`)
  )

  return (
    <ModuleContainer
      ref={ref}
      data-container-module-id={module.id}
      noneditableViewingMode={noneditableViewingMode}
      moduleId={module.id}
      {...otherProps}
    >

      {!noneditableViewingMode && modulePassages.length === 0 &&
        <NewModuleHeadingAndPassageChooser
          heading={
            (arcorbracket === `a` && i18n("New Arc", "", "discourse"))
            || (arcorbracket === `b` && i18n("New Bracket", "", "discourse"))
            || i18n("New...", "", "discourse")
          }
          module={module}
          moduleByProject={moduleByProject}
          setUpPiecesAfterPassageChange={noop}
          moduleWidth={moduleWidth}
          newOrLearning={<NewToDiscourse />}
          maxChapterSpan={3}
        />
      }

      {modulePassages.length > 0 &&
        <Container
          ref={containerSetRef}
        >

          {!noneditableViewingMode &&
            <>

              <HeaderBackground />

              <ModuleTitleButton
                module={module}
                moduleByProject={moduleByProject}
                onDeleteModuleByProject={onDeleteModuleByProject}
                goPrintOrDownload={setPrintDialogInfo}
                goSketch={goSketch}
                giveDownloadChoices
                goUndo={incrementUndoClickCounter}
                width={moduleWidth}
                topSection={
                  <DiscourseOptionsChangePassage
                    module={module}
                    projectId={projectId}
                    setUpPiecesAfterPassageChange={noop}
                  />
                }
                columnsSection={
                  <LegacyDiscourseOptionsColumns
                    modulePassages={modulePassages}
                    projectId={projectId}
                    moduleId={id}
                    setUpPiecesAfterPassageChange={noop}
                    visibilityById={effectiveVisibilityById}
                  />
                }
                settingsSection={
                  <LegacyDiscourseSettings
                    projectId={projectId}
                    legacySetting={legacySetting}
                    displayedLegacySetting={displayedLegacySetting}
                    setDisplayedLegacySetting={setDisplayedLegacySetting}
                    versionIds={versionIds}
                  />
                }
                $adjustmentType={adjustmentType}
              />

              {module.inEditingMode &&
                <DotNotesButton
                  onClick={incrementDotNoteButtonClickCounter}
                />
              }

              <EditingButton
                module={module}
              />

            </>
          }

          {noneditableViewingMode ===  `embed` &&
            <ModuleTitleButton
              module={module}
              width={moduleWidth}
              disabled
            />
          }

          <StyledContainerWithPassagePopper
            onTopOfAll
            noTextSelection
          >
            <LegacyIFrame
              module={module}
              projectId={projectId}
              legacySetting={adjustmentType === `textsizes` ? { ...legacySetting, value: displayedLegacySetting } : legacySetting}
              dotNoteButtonClickCounter={dotNoteButtonClickCounter}
              undoClickCounter={undoClickCounter}
              moduleSettingsLoading={moduleSettingsLoading}
              goPrintOrDownload={goPrintEncompassingNotesTab || setPrintDialogInfo}
              noneditableViewingMode={noneditableViewingMode}
              setHeight={setHeight}
              embedFullScreen={embedFullScreen}
              onLoad={onLoad}
            />
          </StyledContainerWithPassagePopper>

          {!!printDialogInfo &&
            <PrintContainer>
              <LegacyIFrame
                module={module}
                projectId={projectId}
                legacySetting={legacySetting}
                moduleSettingsLoading={moduleSettingsLoading}
                doImmediateAction={`${printDialogInfo.action}:${printDialogInfo.type}:${moduleWidth}`}
                onClosePrint={closePrint}
              />
            </PrintContainer>
          }

        </Container>
      }

    </ModuleContainer>
  )
}

export default memo(Discourse)