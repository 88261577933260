import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'

import CustomSwitch from '../../common/CustomSwitch'

const StyledTextField = styled(TextField)`
  margin: 15px 0 10px;
`

const OutlineKeyOptions = ({
  outlineStyleSetting,
  outlineVisibilitySetting,
  projectId,
  ...otherProps
}) => {

  const [ goSetOutlineStyle ] = useGoSetModuleSetting({
    moduleSetting: outlineStyleSetting,
    projectId,
  })

  const [ goSetOutlineVisibility ] = useGoSetModuleSetting({
    moduleSetting: outlineVisibilitySetting,
    projectId,
  })

  const onChangeOutlineStyle = useCallback(
    event => {
      const { value } = event.target
      if(!value) {
        event.preventDefault()
      } else {
        goSetOutlineStyle({ value })
      }
    },
    [ goSetOutlineStyle ],
  )

  const toggleShow = useCallback(
    ({ target }) => {
      goSetOutlineVisibility({ value: target.checked ? `SHOW` : `HIDE` })
    },
    [ goSetOutlineVisibility ],
  )

  return (
    <List>

      <StyledTextField
        {...otherProps}
        select
        label={i18n("Outline Style", "", "outline")}
        fullWidth
        variant="outlined"
        value={outlineStyleSetting.value}
        onChange={onChangeOutlineStyle}
        size="small"
      >
        <MenuItem
          value="STANDARD"
        >
          {i18n("Standard", "", "outline")}
        </MenuItem>
        <MenuItem
          value="TRAILS"
        >
          {i18n("Number Trails", "", "outline")}
        </MenuItem>
        <MenuItem
          value="ROMAN"
        >
          {i18n("Roman Numerals", "", "outline")}
        </MenuItem>
        <MenuItem
          value="INDENTS"
        >
          {i18n("Indents Only", "", "outline")}
        </MenuItem>
      </StyledTextField>

      <CustomSwitch
        checked={outlineVisibilitySetting.value === `SHOW`}
        onChange={toggleShow}
        label={i18n("Show outline", "", "outline")}
      />

    </List>
  )
}

export default memo(OutlineKeyOptions)