import { useCallback, useRef } from 'react'
import useSetTimeout from './useSetTimeout';
import { preventDefaultEvent, usingModifierKey, vibrate } from '../utils/misc';
import useInstanceValue from './useInstanceValue';

const getEventInfo = (originalEvent, { doPreventDefault }={}) => {
  if(originalEvent && doPreventDefault) preventDefaultEvent(originalEvent)
  const event = ((originalEvent || {}).touches || [])[0] || originalEvent
  const { clientX, clientY } = event || {}
  return {
    event,
    clientX,
    clientY,
    touchEvent: (originalEvent || {}).touches ? originalEvent : null,
  }
}

const getIsTouchEvent = event => /touch/.test((event || {}).type)

const useComplexInteractions = ({
  onLongPress,
  onClick,
  onDoubleClick,
  onMove,
  onPinchToZoom,
  onStartInteraction,
  onInteractionComplete,
  skipPreventDefault,
  skipPreventDefaultOnTouch,
  longPressDelay=300,
  doubleClickDelay=300,
  disableModifierKeyLongPress,
  doNotVibrate,
}) => {

  const [ setLongPressTimeout, clearLongPressTimeout ] = useSetTimeout()
  const [ setDoubleClickCheckTimeout ] = useSetTimeout()
  const [ setResetTimeout ] = useSetTimeout()
  const getOnLongPress = useInstanceValue(onLongPress)
  const getOnClick = useInstanceValue(onClick)
  const getOnDoubleClick = useInstanceValue(onDoubleClick)
  const getOnMove = useInstanceValue(onMove)
  const getOnPinchToZoom = useInstanceValue(onPinchToZoom)
  const getOnStartInteraction = useInstanceValue(onStartInteraction)
  const getOnInteractionComplete = useInstanceValue(onInteractionComplete)
  const doNotStart = useRef(false)
  const waitingOnSecondClick = useRef(false)

  const onStart = useCallback(
    originalEvent => {
      if(doNotStart.current) return

      let isComplete = false
      let isTouch = getIsTouchEvent(originalEvent)
      const doPreventDefault = !(skipPreventDefault || (isTouch && skipPreventDefaultOnTouch))

      const { event, clientX, clientY } = getEventInfo(originalEvent, { doPreventDefault })

      getOnStartInteraction() && getOnStartInteraction()(event, originalEvent)

      if(!disableModifierKeyLongPress && usingModifierKey(originalEvent)) {
        getOnLongPress() && getOnLongPress()(event, originalEvent)
        return
      }

      let isClick = true
      let initialPinchDistance

      const onDone = onDoneOriginalEvent => {
        if(isComplete) return  // onDone should only get run once
        getEventInfo(onDoneOriginalEvent, { doPreventDefault })

        clearLongPressTimeout()

        if(isClick) {

          getOnClick() && getOnClick()(event, originalEvent, onDoneOriginalEvent)

          if(waitingOnSecondClick.current) {
            waitingOnSecondClick.current = false
            getOnDoubleClick() && getOnDoubleClick()(event, originalEvent, onDoneOriginalEvent)
          }

          waitingOnSecondClick.current = true
          setDoubleClickCheckTimeout(() => { waitingOnSecondClick.current = false }, doubleClickDelay)

        }

        document.body.removeEventListener('mousemove', onMove)
        document.body.removeEventListener('mouseup', onDone)
        document.body.removeEventListener('mouseleave', onDone)
        document.body.removeEventListener('touchmove', onMove)
        document.body.removeEventListener('touchend', onDone)
        document.body.removeEventListener('touchcancel', onDone)
  
        isComplete = true
        setResetTimeout(() => doNotStart.current = false, isTouch ? 100 : 0)  // the timeout is needed to ignore the onMouseDown event that follows on touch

        getOnInteractionComplete() && getOnInteractionComplete()(event, originalEvent, onDoneOriginalEvent)
      }

      const onMove = originalEvent => {
        const { event, clientX: moveClientX, clientY: moveClientY } = getEventInfo(originalEvent, { doPreventDefault: true })
        const touches = originalEvent.touches || []
        if(initialPinchDistance) {
          isClick = false
          clearLongPressTimeout()
          if(touches.length !== 2) {
            onDone(originalEvent)
          } else if(getOnPinchToZoom()) {
            // get new distance and compare to initialPinchDistance
            const newPinchDistance = Math.hypot(
              touches[0].clientX - touches[1].clientX,
              touches[0].clientY - touches[1].clientY
            )
            getOnPinchToZoom()({
              pinchChangeInPixels: newPinchDistance - initialPinchDistance,
              x: (touches[0].clientX + touches[1].clientX) / 2,
              y: (touches[0].clientY + touches[1].clientY) / 2,
              event,
              originalEvent,
            })
          }
        } else if(touches.length === 2) {
          isClick = false
          clearLongPressTimeout()
          initialPinchDistance = Math.hypot(
            touches[0].clientX - touches[1].clientX,
            touches[0].clientY - touches[1].clientY
          )
        } else {
          getOnMove() && getOnMove()(event, originalEvent)
          if(Math.abs(moveClientX - clientX) + Math.abs(moveClientY - clientY) < 7) return
          isClick = false
          clearLongPressTimeout()
        }
      }

      document.body.addEventListener('mousemove', onMove)
      document.body.addEventListener('mouseup', onDone)
      document.body.addEventListener('mouseleave', onDone)
      document.body.addEventListener('touchmove', onMove, { passive: false })
      document.body.addEventListener('touchend', onDone)
      document.body.addEventListener('touchcancel', onDone)

      setLongPressTimeout(() => {
        isClick = false
        !doNotVibrate && vibrate()
        getOnLongPress() && getOnLongPress()(event, originalEvent)
      }, longPressDelay)

      doNotStart.current = true

    },
    [ skipPreventDefault, disableModifierKeyLongPress, longPressDelay, setLongPressTimeout, clearLongPressTimeout, getOnClick, doubleClickDelay,
      getOnLongPress, doNotVibrate, getOnStartInteraction, getOnInteractionComplete, getOnMove, getOnDoubleClick, setDoubleClickCheckTimeout,
      getOnPinchToZoom, setResetTimeout, skipPreventDefaultOnTouch ]
  )

  return {
    onMouseDown: onStart,
    onTouchStart: onStart,
    onClick: skipPreventDefault ? null : preventDefaultEvent,
  }
}

export default useComplexInteractions