import React from "react"
import styled from 'styled-components'
import { getLocale } from 'inline-i18n'

import FlipEditor from './FlipEditor'

const Container = styled.div`
  position: relative;
`

const Title = styled.div`
  line-height: 48px;
  font-size: 18px;
  color: ${({ $appearReadOnly }) => $appearReadOnly ? `rgba(0,0,0,.2)` : `rgba(0,0,0,.7)`};
  font-weight: 500;  
`

class InnerFlipEditor extends React.Component {

  updateContent = initialContent => {
    const { flipEditorUpdateSettings } = this.props

    flipEditorUpdateSettings({
      initialContent,
    })

  }

  setParentReadOnly = event => {
    const { flipEditorSetReadOnly } = this.props

    flipEditorSetReadOnly(event.type === 'focus')
  }

  render() {
    const { id, filterCustomComponentTypes, initialContent, title, openAssetListDialog, 
            flipEditorMode, flipEditorReadOnly, flipEditorPreppedCustomComponentTypes,
            flipEditorCustomComponentDataByBlockKey, flipEditorCustomComponentDataByComponentType,
            flipEditorToolbarSize, flipEditorHideAllSettingsIcons, className,
            style, openUploadAssetDialog } = this.props

    const locale = getLocale()

    const customComponentTypes = filterCustomComponentTypes(flipEditorPreppedCustomComponentTypes)

    return (
      <Container
        className={className}
        style={style}
        onFocus={this.setParentReadOnly}
        onBlur={this.setParentReadOnly}
      >
        {flipEditorMode === 'edit' &&
          <Title
            $appearReadOnly={flipEditorReadOnly}
          >
            {title.lang[locale] || (title.lang || {}).en}
          </Title>
        }
        <FlipEditor
          id={id}
          customComponentTypes={customComponentTypes}
          customComponentDataByBlockKey={flipEditorCustomComponentDataByBlockKey}
          customComponentDataByComponentType={flipEditorCustomComponentDataByComponentType}
          initialContent={initialContent}
          mode={flipEditorMode}
          updateContent={this.updateContent}
          appearReadOnly={flipEditorReadOnly}
          noPaddingBottom={true}
          openAssetListDialog={openAssetListDialog}
          openUploadAssetDialog={openUploadAssetDialog}
          locale={locale}
          toolbarSize={flipEditorToolbarSize}
          hideAllSettingsIcons={flipEditorHideAllSettingsIcons}
          isInnerFlipEditor={true}
        />
      </Container>
    )
  }

}

export default InnerFlipEditor