import { memo, useRef, useMemo } from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from 'recharts'

const colors = [
  // `#bdac59`,
  // `#5c829a`,
  // `#C95047`,
  // `#95bb80`,
  // `#d68945`,
  // `#a7a7a7`,
  `#ec6b63`,
  `#a1afff`,
  `#cccc03`,
  `#eca43b`,
  `#5db939`,
  `#d09ccc`,
  `#ffa3b3`,
  `#46b9bb`,
  `#acacac`,
]

const StatsLineChart = ({
  data,
  lines,
  tooltip,
  width=800,
}) => {

  data = data || []

  const idxAddOn = useRef(parseInt(Math.random() * 10))

  const dataKeys = useMemo(
    () => (
      (lines || Object.keys(data[0] || {}))
        .filter(dataKey => ![ `date` ].includes(dataKey))
    ),
    [ lines, data ],
  )

  return (
    <LineChart
      width={width}
      height={300}
      data={data}
    >
      {dataKeys.map((dataKey, idx) => (
        <Line
          key={idx}
          type="monotone"
          dataKey={dataKey}
          stroke={colors[(idx + idxAddOn.current) % colors.length]}
        />
      ))}
      <CartesianGrid stroke="#ccc" />
      <XAxis dataKey="date" />
      <YAxis />
      {tooltip || <Tooltip />}
      {dataKeys.length > 1 && <Legend />}
    </LineChart>
  )
}

export default memo(StatsLineChart)