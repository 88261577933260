const fields = `
  id
  url
  title
  heading
  thumbnailImageUrl
  info
  reactions
`

export default fields