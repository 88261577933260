import { memo, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { getLocFromRef, getRefFromLoc, getCorrespondingRefs, getNextTranslationRef } from '@bibletags/bibletags-versification'
import { getPassageStr } from '@bibletags/bibletags-ui-helper'
// import { i18n } from 'inline-i18n'

import useVersesPieces from '../../../hooks/useVersesPieces'
import useVersionInfos from '../../../hooks/useVersionInfos'
import useVersionInfo from '../../../hooks/useVersionInfo'
import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import useObjFromArrayOfObjs from '../../../hooks/useObjFromArrayOfObjs'
import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useUpdateEffectAsync from '../../../hooks/useUpdateEffectAsync'
import { cloneObj, equalObjs, getOrigVersionInfo, safeJSONParse } from '../../../utils/misc'
import { IS_EMBED, KJV_VERSION } from '../../../utils/constants'
import { getEmbedMode } from '../../../graphql/links/embedLink'

import Loading from '../../common/Loading'
import FadedLoading from '../../common/FadedLoading'
import VerseNumberPopperContent from '../../passage/VerseNumberPopperContent'

const IFrame = styled.iframe`
  border: none;
  user-select: none;
  flex: 1;
`

const DummyAnchorEl = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: -1;
`

let lastDummyModuleId = 0

const getPassageAndVersesByVersionIdAndLoc = ({ module, versionInfos, versesPiecesSets, kjvVersion, versionIds }) => {

  const { fromLoc, toLoc } = module.modulePassages[0]
  const legacyVersion = (
    (
      module.type === 'DIAGRAMMING'
      && (versionInfos[0] || {}).version
    ) || kjvVersion
  )

  let fromRef, toRef

  if(parseInt(fromLoc, 10) > 67000000) {
    fromRef = getRefFromLoc(fromLoc)
    toRef = getRefFromLoc(toLoc || fromLoc)
    return {
      passage: {
        testament: "AP",
        bookindex: fromRef.bookId,
        startchap: fromRef.chapter,
        startvs: fromRef.verse,
        endchap: toRef.chapter,
        endvs: toRef.verse,
        ref: ``,
      },
      versesByVersionIdAndLoc: {},
    }
  }

  const [ convertedFromRefs, convertedToRefs ] = [ fromLoc, toLoc ].map((loc, idx) => (
    getCorrespondingRefs({
      baseVersion: {
        ref: getRefFromLoc(loc),
        info: getOrigVersionInfo(),
      },
      lookupVersionInfo: legacyVersion,
      directionToTryIfSkipped: idx === 0 ? `next` : `previous`,
    })
  ))

  fromRef = convertedFromRefs[0]
  toRef = convertedToRefs.at(-1)

  const { bookId } = fromRef
  const passage = {
    testament: bookId <= 39 ? "OT" : "NT",
    bookindex: bookId,
    startchap: fromRef.chapter,
    startvs: fromRef.verse,
    endchap: toRef.chapter,
    endvs: toRef.verse,
    ref: getPassageStr({ refs: [ fromRef, toRef ] }),
  }

  const versesByVersionIdAndLoc = {}
  versionInfos.forEach(({ version=KJV_VERSION }) => {

    const versesByLoc = {}
    let { chapter, verse } = (
      (getCorrespondingRefs({
        baseVersion: {
          ref: (
            getCorrespondingRefs({
              baseVersion: {
                ref: fromRef,
                info: legacyVersion,
              },
              lookupVersionInfo: getOrigVersionInfo(),
              directionToTryIfSkipped: `next`,
            })[0]
          ),
          info: getOrigVersionInfo(),
          directionToTryIfSkipped: `next`,
        },
        lookupVersionInfo: version,
      }) || [])[0]
    ) || {}

    let loc
    const [ pieces ] = versesPiecesSets.find(({ versionId }) => versionId === version.id).piecesAndLoading

    pieces.forEach(piece => {
      const { tag, content } = piece

      if(tag === 'c') {
        chapter = parseInt(content)
      } else if([ 'v', 'd' ].includes(tag)) {

        verse = tag === 'd' ? 0 : parseInt(content)
        loc = getLocFromRef({ bookId, chapter, verse })
        let kjvloc = loc
        try {
          kjvloc = getLocFromRef(
            getCorrespondingRefs({
              baseVersion: {
                ref: (
                  getCorrespondingRefs({
                    baseVersion: {
                      ref: getRefFromLoc(loc),
                      info: version,
                    },
                    lookupVersionInfo: getOrigVersionInfo(),
                  })[0]
                ),
                info: getOrigVersionInfo(),
              },
              lookupVersionInfo: kjvVersion,
            })[0]
          ).split(':')[0]
        } catch(e) {
          console.log(`Could not get kjvloc`, loc, version.id, e)
        }
        const kjvRef = getRefFromLoc(kjvloc)

        versesByLoc[loc] = {
          loc,
          book: bookId,
          heborder: version.hebrewOrdering,
          chapter,
          verse,
          kjvloc,
          kjvchapter: kjvRef.chapter,
          kjvverse: kjvRef.verse,
          strippedcontent: ``,
          content: ``,
          parsing: null,
        }

      } else {
        const addOnContent = piece2 => {
          const { text, children, nextChar } = piece2
          if(children) {
            children.forEach(child => addOnContent(child))
          } else if(versesByLoc[loc]) {
            if(text) {
              versesByLoc[loc].strippedcontent += [ 'nd', 'sc' ].includes(tag) ? text.toUpperCase() : text
            } else if(nextChar === ' ') {
              versesByLoc[loc].strippedcontent += nextChar
            }
          } else if(`${text || ``}${nextChar || ``}`.trim()) {
            console.error(`Legacy getData: Unexpected content before verse: ${text || nextChar}`, piece2, pieces)
          }
        }
        addOnContent(piece)
      }
    })

    for(let loc in versesByLoc) {
      versesByLoc[loc].content = versesByLoc[loc].strippedcontent = versesByLoc[loc].strippedcontent.trim()
    }

    versesByVersionIdAndLoc[version.id] = versesByLoc

  })

  ;[ ...versionIds, 'mine' ].filter(versionId => /^external_/.test(versionId) || !versionInfos.some(({ id }) => id === versionId)).forEach(versionId => {
    const versesByLoc = {}

    const { info: { pastedInVerses={} }={} } = module.modulePassages.find(({ info }) => (info.versionId === versionId)) || {}

    let ref = cloneObj(fromRef)
    delete ref.wordRanges
    const toLoc = getLocFromRef(toRef).split(':')[0]
    while(getLocFromRef(ref) <= toLoc) {
      const loc = getLocFromRef(ref)

      versesByLoc[loc] = {
        loc,
        book: ref.bookId,
        heborder: false,
        chapter: ref.chapter,
        verse: ref.verse,
        kjvloc: loc,
        kjvchapter: ref.chapter,
        kjvverse: ref.verse,
        strippedcontent: pastedInVerses[loc] || ``,
        content: pastedInVerses[loc] || ``,
        parsing: null,
      }

      ref = getNextTranslationRef({ ref, info: KJV_VERSION })
      if(getLocFromRef(ref) === `01001001`) break

    }

    versesByVersionIdAndLoc[versionId] = versesByLoc
  })

  return {
    passage,
    versesByVersionIdAndLoc,
  }

}

const LegacyIFrame = ({
  module,
  projectId,
  legacySetting,
  dotNoteButtonClickCounter,
  undoClickCounter,
  moduleSettingsLoading,
  onClosePrint,
  doImmediateAction,
  goSetPopperInfo,
  goPrintOrDownload,
  noneditableViewingMode,
  embedFullScreen,
  setHeight,
  onLoad,
}) => {

  const dummyModuleId = useRef(++lastDummyModuleId).current
  const iframeSource = useRef()
  const iframeOrigin = useRef()

  const [ iFrameLoaded, setIFrameLoaded ] = useState(false)
  const [ dummyAnchorElCoords, setDummyAnchorElCoords ] = useState({})

  const getLegacySetting = useInstanceValue(legacySetting)

  const [ goUpdateModule ] = useGoUpdateModule({ module })
  const getModule = useInstanceValue(module)
  const getSetHeight = useInstanceValue(setHeight)

  const moduleTable = module.type.toLowerCase().replace(/^diagramming$/, 'diagram')

  // TODO: The filter in the next line is temporary with legacy modules and until I set up versions of type PERSONAL
  const versionIdsWithMine = module.modulePassages.map(({ info: { versionId } }) => versionId)
  const versionIds = versionIdsWithMine.filter(versionId => versionId !== 'mine')
  const { versionInfos } = useVersionInfos({ versionIds })
  const versionInfoById = useObjFromArrayOfObjs(versionInfos || [])
  const { version: kjvVersion } = useVersionInfo(`kjv`)

  const getUseVersesPiecesParams = idx => {
    if(!versionIds[idx] || !versionInfoById[versionIds[idx]]) return { skip: true }

    const { fromLoc, toLoc } = module.modulePassages[idx] || {}
    const [ convertedFromRefs, convertedToRefs ] = [ fromLoc, toLoc ].map((loc, idx2) => (
      getCorrespondingRefs({
        baseVersion: {
          ref: getRefFromLoc(loc),
          info: getOrigVersionInfo(),
        },
        lookupVersionInfo: versionInfoById[versionIds[idx]].version,
        directionToTryIfSkipped: idx2 === 0 ? `next` : `previous`,
      })
    ))

    if((convertedFromRefs || []).length === 0 || (convertedToRefs || []).length === 0) return { skip: true }

    return {
      fromLoc: getLocFromRef(convertedFromRefs[0]).split(':')[0],
      toLoc: getLocFromRef(convertedToRefs.at(-1)).split(':')[0],
      versionId: versionIds[idx],
    }
  }

  const versesPiecesSets = [
    {
      versionId: versionIds[0],
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(0)),
    },
    {
      versionId: versionIds[1],
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(1)),
    },
    {
      versionId: versionIds[2],
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(2)),
    },
    {
      versionId: versionIds[3],
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(3)),
    },
    {
      versionId: versionIds[4],
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(4)),
    },
    {
      versionId: versionIds[5],
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(5)),
    },
    {
      versionId: versionIds[6],
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(6)),
    },
    {
      versionId: versionIds[7],
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(7)),
    },
    {
      versionId: versionIds[8],
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(8)),
    },
    {
      versionId: versionIds[9],
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(9)),
    },
  ]

  const [ goSetLegacy ] = useGoSetModuleSetting({
    moduleSetting: legacySetting,
    projectId,
  })

  const ready = !!(
    !moduleSettingsLoading
    && versesPiecesSets.every(({ piecesAndLoading }) => !piecesAndLoading[1])
    // && versionIds.filter((x, idx) => versesPiecesSets[idx].piecesAndLoading[0]).length === (versionInfos || []).length
    && versionInfos
    && versionInfos.every(({ id }) => versesPiecesSets.some(({ versionId, piecesAndLoading }) => (versionId === id && piecesAndLoading[0] )))
    && kjvVersion
  )

  const hasBeenReady = useRef(false)
  hasBeenReady.current = hasBeenReady.current || ready

  useEffect(
    () => {

      if(!ready) return

      const onMessage = async ({ origin, source, data }) => {

        if(
          !/(?:\/\/(?:app\.)?biblearc\.com|\/\/localhost:3000)$/.test(origin)
          && origin !== window.location.origin
        ) return
        if(typeof data !== 'string') return

        const { id, action, key, value } = safeJSONParse(data, {})

        if(!id) return

        if(parseInt(id, 10) !== dummyModuleId) {
          console.log(`LegacyIFrame SAVE called for id: ${id}. This is likely not a problem if there are two modules being viewed.`)
          return
        }

        iframeSource.current = source
        iframeOrigin.current = origin

        switch(action) {  // eslint-disable-line default-case

          case 'getData': {

            if(
              !equalObjs(getLegacySetting(), legacySetting)
              || !equalObjs(getModule(), module)
            ) {
              throw new Error(`Error in legacy module load.`)
            }

            const { passage, versesByVersionIdAndLoc } = getPassageAndVersesByVersionIdAndLoc({ module, versionInfos, versesPiecesSets, kjvVersion, versionIds })

            // This block due to a bug I had before
            if(versionIdsWithMine.includes('mine') && legacySetting.value.texts && !legacySetting.value.texts.includes('mine')) {
              legacySetting = cloneObj(legacySetting)  // eslint-disable-line react-hooks/exhaustive-deps
              legacySetting.value.texts.push('mine')
            }

            const keys = {
              project: {
                id: dummyModuleId,
                name: "",
                passage: false,
                columnarrangements: [{
                  width: 1,
                  modules: [
                    {
                      id: dummyModuleId,
                      label: module.type,
                      table: moduleTable,
                    }
                  ],
                  selected: `${moduleTable}${dummyModuleId}`
                }],
                userid: noneditableViewingMode ? 1 : null,  // passing userId:1 indicates it is shared
                noneditableViewingMode,
                embedMode: IS_EMBED ? getEmbedMode() : null,
                fullScreenOnInitialValue: embedFullScreen,
              },
              [`${moduleTable}${dummyModuleId}`]: {
                dots: {},
                [module.type === 'DISCOURSE' ? `arcs` : `content`]: [],
                settings: module.type === 'PHRASING' ? { lWidPerc: .5, numColsShown: 2 } : {},
                ...legacySetting.value,
                ...(module.type === 'DIAGRAMMING' ? { openlowerpanel: "scripturetext" } : {}),
                id: dummyModuleId,
                label: module.type,
                passage,
                texts: ((module.type === 'PHRASING' && legacySetting.value.texts) || versionIds).filter(versionId => [ ...versionIds, null, 'mine' ].includes(versionId)),
                openmenubutton: (module.inEditingMode && !doImmediateAction && !noneditableViewingMode) ? 'editing' : '',
                creationtime: parseInt(module.createdAt / 1000, 10),
                lastsavetime: parseInt(module.modifiedAt / 1000, 10),
              },
              versesByVersionIdAndLoc,
            }

            iframeSource.current.postMessage(
              JSON.stringify({
                action: 'setData',
                keys,
              }),
              iframeOrigin.current,
            )
            break
          }

          case 'loaded': {
            setIFrameLoaded(true)
            onLoad && onLoad()
            break
          }

          case 'print': {
            goPrintOrDownload({ action: `print` })
            break
          }

          case 'download': {
            goPrintOrDownload({ action: `download` })
            break
          }

          case 'closePrint': {
            onClosePrint()
            break
          }

          case 'showVsComparison': {
            const { kjvloc, left, top } = value

            setDummyAnchorElCoords({ left: `${left-10}px`, top: `${top-10}px` })

            goSetPopperInfo({ currentTarget: document.getElementById(`${dummyModuleId}:dummy-anchor-el`) }, {
              Component: VerseNumberPopperContent,
              props: {
                versionId: 'kjv',
                contextRef: getRefFromLoc(kjvloc),
                goSetPopperInfo,
              },
            })

            break
          }

          case 'save': {

            if(noneditableViewingMode) break  // don't try to save if they don't have that ability!
            // If the previous line doesn't always work, see "IF SAVE NOT PREVENTED IN LegacyIFrame, then do this" in biblearc.js

            // fix a bug in a hacky way since this is not long-term code
            try {
              delete value.settings.goToggle
            } catch(err) {}

            if(key === `${moduleTable}${dummyModuleId}`) {
              if(equalObjs(getLegacySetting().value, value)) {
                console.log('LegacyIFrame SAVE called, but took no action because there was not a change.')
              } else {
                goSetLegacy({ value })
              }
            } else if(key !== 'project') {
              console.warn(`LegacyIFrame SAVE called for id: ${id}, key: ${key}`)
            }
            break
          }

          case 'reportFocus':
          case 'reportBlur': {
            if(![ `shared-page`, `sketch` ].includes(noneditableViewingMode)) {
              const notesModuleEl = (
                noneditableViewingMode === `within-notes`
                  ? document.querySelector(`.notes-module`)
                  : document.querySelector(`[data-container-module-id="${module.id}"]`)
              )
              if(notesModuleEl) {
                notesModuleEl.classList[action === `reportFocus` ? `add` : `remove`]('pseudo-focus')
              }
            }
            break
          }

          case 'reportTab': {
            const focussableElementsSelector = 'a:not([disabled]):not([tabindex="-1"]), button:not([disabled]):not([tabindex="-1"]), input[type=text]:not([disabled]):not([tabindex="-1"]), [tabindex]:not([disabled]):not([tabindex="-1"])';
            const focussableElements = [ ...document.body.querySelectorAll(focussableElementsSelector) ]
            const indexToFocus = focussableElements.indexOf(document.getElementById(`${dummyModuleId}:dummy-anchor-el`)) + 1
            focussableElements[indexToFocus].focus()
            break
          }

          case 'reportSearchShortcut': {
            window.dispatchEvent(
              new KeyboardEvent(
                'keydown',
                {
                  metaKey: true,
                  ctrlKey: true,
                  key: `/`,
                },
              )
            )
            break
          }

          case 'toggleInEditingMode': {
            const currentInEditingMode = !!getModule().inEditingMode
            const newInEditingMode = typeof value === 'boolean' ? value : !currentInEditingMode
            if(newInEditingMode !== currentInEditingMode) {
              goUpdateModule({ inEditingMode: newInEditingMode })
            }
            break
          }

          case 'setHeight': {
            const setHeight = getSetHeight()
            if(setHeight) {
              const extraEmbedHeightPerModuleType = {
                DIAGRAMMING: 100,
                DISCOURSE: 150,
                PHRASING: 150,
              }
              setHeight(
                value
                + (
                  IS_EMBED
                    ? extraEmbedHeightPerModuleType[module.type]
                    : 0
                )
                + (
                  (
                    !noneditableViewingMode
                    && getModule().inEditingMode
                    && (
                      (module.type === `PHRASING` && 100)
                      || (module.type === `DIAGRAMMING` && 10)
                    )
                  ) || 0
                )
              )
            }

            break
          }

        }
      }

      window.addEventListener('message', onMessage)
      return () => window.removeEventListener('message', onMessage)
    },
    [ hasBeenReady.current ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  useUpdateEffectAsync(
    () => {

      if(!ready) return
      if(!iframeSource.current || !iframeOrigin.current) return
      if(versesPiecesSets.some(({ piecesAndLoading }) => piecesAndLoading[1])) return

      const { passage, versesByVersionIdAndLoc } = getPassageAndVersesByVersionIdAndLoc({ module, versionInfos, versesPiecesSets, kjvVersion, versionIds })

      iframeSource.current.postMessage(
        JSON.stringify({
          action: 'updatePassage',
          moduleId: `${moduleTable}${dummyModuleId}`,
          passage,
          versionIds,
          versionIdsWithMine,
          visibleVersionIds: module.modulePassages.filter(({ info: { visible } }) => visible).map(({ info: { versionId } }) => versionId),
          versesByVersionIdAndLoc,
        }),
        iframeOrigin.current,
      )
    },
    [ ready, JSON.stringify(module.modulePassages.map(({ info: { visible } }) => visible)), ...versesPiecesSets.map(({ piecesAndLoading }) => piecesAndLoading[0]) ],
  )

  const memoedLegacySettingSettings = useEqualObjsMemo(legacySetting.value.settings)
  useEffectAsync(
    () => {

      if(!iframeSource.current || !iframeOrigin.current) return

      iframeSource.current.postMessage(
        JSON.stringify({
          action: 'updateSettings',
          moduleId: `${moduleTable}${dummyModuleId}`,
          settings: memoedLegacySettingSettings,
        }),
        iframeOrigin.current,
      )

    },
    [ memoedLegacySettingSettings ],
  )

  useEffectAsync(
    () => {

      if(!iframeSource.current || !iframeOrigin.current) return

      iframeSource.current.postMessage(
        JSON.stringify({
          action: 'updateFullScreen',
          fullScreenOn: embedFullScreen,
        }),
        iframeOrigin.current,
      )

    },
    [ embedFullScreen ],
  )

  useEffectAsync(
    () => {

      if(!iframeSource.current || !iframeOrigin.current) return
      if(doImmediateAction || noneditableViewingMode) return

      iframeSource.current.postMessage(
        JSON.stringify({
          action: 'setEditing',
          moduleId: `${moduleTable}${dummyModuleId}`,
          inEditingMode: module.inEditingMode,
        }),
        iframeOrigin.current,
      )

    },
    [ module.inEditingMode, !iframeSource.current, !iframeOrigin.current ],
  )

  useUpdateEffectAsync(
    () => {

      if(!iframeSource.current || !iframeOrigin.current) return

      iframeSource.current.postMessage(
        JSON.stringify({
          action: 'addDotNote',
          moduleId: `${moduleTable}${dummyModuleId}`,
        }),
        iframeOrigin.current,
      )

    },
    [ dotNoteButtonClickCounter ],
  )

  useUpdateEffectAsync(
    () => {

      if(!iframeSource.current || !iframeOrigin.current) return

      iframeSource.current.postMessage(
        JSON.stringify({
          action: 'undo',
          moduleId: `${moduleTable}${dummyModuleId}`,
        }),
        iframeOrigin.current,
      )

    },
    [ undoClickCounter ],
  )

  if(!hasBeenReady.current) return <Loading />

  return (
    <>
      <IFrame
        src={`/legacy/index.html?mode=newbiblearc&id=${dummyModuleId}&release=${encodeURIComponent(process.env.REACT_APP_RELEASE_DATE_AND_TIME)}${doImmediateAction ? `&immediateaction=${doImmediateAction}` : ``}`}
        tabIndex={-1}
        className={/^print/.test(doImmediateAction || ``) ? `dark-mode-exempt` : ``}
      />
      <DummyAnchorEl
        id={`${dummyModuleId}:dummy-anchor-el`}
        style={dummyAnchorElCoords}
        tabIndex={0}
        data-module-id={module.id}
      />
      {!iFrameLoaded && <Loading />}
      {!ready && <FadedLoading />}
    </>
  )
}

export default memo(LegacyIFrame)