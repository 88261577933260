import { memo } from 'react'
import styled from 'styled-components'

import { MINIMUM_PASSAGE_COLUMN_WIDTH } from "../../../utils/constants"
import styledResizableDiv from '../../../utils/styledResizableDiv'

import NotesContainerWithFocusIndicator from '../../common/NotesContainerWithFocusIndicator'
import Notes from '../../modules/notes/Notes'

const Container = styledResizableDiv`
  display: flex;
  position: relative;
`

const InnerContainer = styled(NotesContainerWithFocusIndicator)`
  flex: 1;
  min-width: 0;
  padding: 0 ${({ $minimizeInPanel }) => $minimizeInPanel ? `0` : `15px`};
  display: flex;
`

const ProjectNotesInPanel = ({
  width,
  ...otherProps
}) => {

  const minimizeInPanel = width < MINIMUM_PASSAGE_COLUMN_WIDTH

  return (
    <Container $width={width}>
      <InnerContainer $minimizeInPanel={minimizeInPanel}>
        <Notes
          minimizeInPanel={minimizeInPanel}
          {...otherProps}
        />
      </InnerContainer>
    </Container>
  )
}

export default memo(ProjectNotesInPanel)