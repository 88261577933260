import { usePrevious } from "react-use"

import { setLocalStorage, removeLocalStorage, equalObjs } from '../utils/misc'
import useEffectAsync from "./useEffectAsync"

const useInitialValueUpdater = obj => {

  const previousObj = usePrevious(obj) || {}

  useEffectAsync(
    () => {
      const { keyPrefix=``, removeValue, ...updateObj } = obj
      Object.keys(updateObj).forEach(key => {
        if(equalObjs(updateObj[key], removeValue)) {
          removeLocalStorage(`initialValue:${keyPrefix}${key}`)
        } else if(updateObj[key] !== previousObj[key]) {
          setLocalStorage(`initialValue:${keyPrefix}${key}`, updateObj[key])
        }
      })
    },
    [ obj ],
  )
}

export default useInitialValueUpdater
