import { gql } from '@apollo/client'
import user_fields from '../fragments/user_fields'

export default gql
`
  mutation updateStripeSubscription($plan: String!, $term: String!, $donation: Float!, $executeAt: Milliseconds!, $couponId: ID) {
    updateStripeSubscription(plan: $plan, term: $term, donation: $donation, executeAt: $executeAt, couponId: $couponId) {
      ${user_fields}
    }
  }
`