import { memo, useContext, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import CheckIcon from '@material-ui/icons/Check'
import ShareIcon from '@material-ui/icons/Share'
import LinkIcon from '@material-ui/icons/Link'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import useAppSize from "../../../hooks/useAppSize"
import useVersionInfos from '../../../hooks/useVersionInfos'
import useUpdatePassageBookmark from '../../../hooks/useUpdatePassageBookmark'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import Header from "../../common/Header"
import HomeMiniPassageRefNavigator from './HomeMiniPassageRefNavigator'
import LinkIconButton from '../../common/LinkIconButton'
import useShareOrCopy from '../../../hooks/useShareOrCopy'

const LogoImage = styled.img`
  height: 38px;
  vertical-align: top;
  margin: 0 -6px;

  @media (max-width: 400px) {
    display: none;
  }
`

const HeaderText = styled.div`
  font-weight: 300;
  line-height: 48px;
  font-size: 18px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`

const IconImage = styled.img`
  width: 29px;
  height: 29px;
  margin: 9.5px 8px 9.5px 4px;
  object-fit: cover;
  border-radius: 5px;
  position: relative;

  &::after {  
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    content: "";
  }
`

const MiniStudyBibleIconButton = styled(LinkIconButton)`
  position: absolute;
  right: 48px;
  z-index: 1;

  ${({ $showStudyBible, theme }) => !$showStudyBible ? `` : `
    color: ${theme.palette.primary.main};
  `}

  .MuiSvgIcon-root {
    transform: rotate(180deg);
  }

  @media (max-width: 370px) {
    display: none;
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 10px;
  margin-left: 2px;
  display: block;

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const LeftExtraImage = styled(IconImage)`
  position: absolute;
  left: 53px;
`

const HomeHeader = ({
  homeSliderSectionOpen,
  toggleHomeSliderSectionOpen,
  homePassageBookmarks,
  setHomePassageBookmarks,
  getHomePassageBookmarks,
  showSliderSection,
  ...otherProps
}) => {

  const { hasMyPlan } = useContext(LoggedInUserContext) || {}

  const { miniHeaderSize } = useAppSize()

  const { defaultVersionId, unhiddenSelectedVersionInfos } = useVersionInfos()
  const { updatePassageBookmark } = useUpdatePassageBookmark({
    getPassageBookmarks: getHomePassageBookmarks,
    setPassageBookmarks: setHomePassageBookmarks,
    unhiddenSelectedVersionInfos,
    defaultVersionId,  
  })
  const { showStudyBible=true } = homePassageBookmarks[0] || {}

  const { copyOrShare, willShare, justCopiedOrShared } = useShareOrCopy({
    shareData: {
      title: i18n("The Biblearc Study Bible"),
      text: i18n("Read and search the Bible with Greek and Hebrew tools and personalized study Bible entries. For Deeper Study of Scripture."),
      url: document.location.href,
    },
  })

  const toggleShowStudyBible = useCallback(
    () => {
      updatePassageBookmark({
        columnIdx: 0,
        showStudyBible: !showStudyBible,
      })
    },
    [ updatePassageBookmark, showStudyBible ],
  )

  return (
    <>

      <Header
        {...otherProps}
        showSectionOpen={showSliderSection}
        sliderSectionOpen={homeSliderSectionOpen}
        toggleSliderSectionOpen={toggleHomeSliderSectionOpen}
        showBibleDisplaySettings
        centerExtraComponent={
          miniHeaderSize
          && (
            <HomeMiniPassageRefNavigator
              passageBookmark={homePassageBookmarks[0]}
              setPassageBookmarks={setHomePassageBookmarks}
              getPassageBookmarks={getHomePassageBookmarks}
            />
          )
        }
        leftExtraComponent={
          miniHeaderSize
          && (
            <LeftExtraImage
              src={`/favicon_192.png`}
              className="dark-mode-exempt"
            />
          )
        }
        rightExtraComponent={
          miniHeaderSize
          && (
            <MiniStudyBibleIconButton
              $showStudyBible={showStudyBible}
              onClick={toggleShowStudyBible}
              // onMouseDown={preventDefaultEvent}
              tabIndex={-1}
            >
              <VerticalSplitIcon />
            </MiniStudyBibleIconButton>
          )
        }
      >
        <HeaderText>
          <IconImage
            src={`/favicon_192.png`}
            className="dark-mode-exempt"
          />
          <LogoImage
            src={
              hasMyPlan
                ? "/my_biblearc_study_bible_1.svg"
                : "/the_biblearc_study_bible_1.svg"
            }
            // className="dark-mode-exempt"
          />
          <Tooltip
            title={willShare ? i18n("Share") : i18n("Copy link to share")}
          >
            <StyledIconButton
              onClick={copyOrShare}
              color={justCopiedOrShared ? `primary` : `default`}
              className="HomeHeader-LinkIconButton"
            >
              {justCopiedOrShared ? <CheckIcon /> : (willShare ? <ShareIcon /> : <LinkIcon />)}
            </StyledIconButton>
          </Tooltip>
        </HeaderText>
      </Header>

    </>
  )
}

export default memo(HomeHeader)