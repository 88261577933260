import useDataQuery from './useDataQuery'

import channelItemQuery from '../graphql/queries/channelItem'

const useUpdatedChannelItem = channelItemPlus => {

  const { id } = channelItemPlus || {}

  const { channelItem } = useDataQuery({
    channelItemQuery,
    variables: {
      id,
    },
    skip: !id,
  })

  return {
    ...(channelItemPlus || {}),
    ...(channelItem || {}),
  }
}

export default useUpdatedChannelItem