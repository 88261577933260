import { memo, useContext } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import CheckIcon from '@material-ui/icons/Check'
import queryString from 'query-string'
import { useApolloClient } from '@apollo/client'

import { LoggedInUserContext } from '../../../context/LoggedInUser'
import userQuery from '../../../graphql/queries/user'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useSetTimeout from '../../../hooks/useSetTimeout'
import useEffectAsync from '../../../hooks/useEffectAsync'

import Header from "../../common/Header"
import Schedule from "./Schedule"
import LinkButton from '../../common/LinkButton'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const PageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobilePageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`

const HeaderLogoImg = styled.img`
  height: 36px;
  vertical-align: top;
  margin: 5px -4px 0 -6px;
`

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px calc(100vh - 250px);
  background-color: white;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
`

const SuccessContainer = styled.div`
  margin: 40px 0 -30px;
  display: flex;
  justify-content: center;
`

const Success = styled.div`
  font-size: 18px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.grey[50]};
  align-self: center;
  padding: 10px 17px;
  border-radius: 5px;

  .MuiSvgIcon-root {
    height: 25px;
    width: auto;
    vertical-align: middle;
    color: ${({ theme }) => theme.palette.primary.main};
    margin: -4px 5px 0 -2px;
  }
`

const Welcome = styled.div`
  text-align: center;
  margin: 70px 0 30px;
  font-size: 22px;
  font-weight: 500;
`

const TagLine1 = styled.div`
  text-align: center;
`

const TagLine2 = styled.div`
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
`

const ButtonContainer = styled.div`
  margin: 10px 0;
  text-align: center;
`

const GettingStarted = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: 200;
  margin-bottom: 10px;
`

const EquipLogoImg = styled.img`
  height: 44px;
  vertical-align: top;
  margin: -7px -12px -7px -6px;
`

const UseFor = styled.div`
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.palette.primary.faded};
  padding: 4px 15px;
  text-align: center;
`

const EquipLogoImg2 = styled.img`
  height: 30px;
  vertical-align: top;
  margin: -4px -6px -4px -6px;
`

const UseForSection = styled.div`
  margin: 50px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AlternativelyUseFor = styled.div`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
`

const ThreeSpotToStart = styled.div`
  text-align: center;
  margin-bottom: 40px;
`

const SpotHeader = styled.div`
  font-weight: 700;
  font-size: 22px;
  margin: 40px 0 12px;
`

const SpotDesc = styled.div`
  margin: 10px 0;
`

const PathCourses = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  @media (max-width: 900px) {
    flex-direction: column;
    flex-wrap: none;
    align-items: center;
  }
`

const PathCourseSection = styled.div`
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 10px;
  margin: 10px;
  padding: 20px;

  @media (max-width: 900px) {
    width: auto;
  }
`

const PathCourseImagesLead = styled.div`
  margin: 0 0 10px;
  text-align: center;
  font-weight: 100;
`

const PathCourseImages = styled.div`
  margin: 5px 0 0;
  align-self: center;
  display: flex;
`

const PathCourse = styled.a`
  margin: 0 7px;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;

  &:hover img {
    transform: scale(1.15);
  }
`

const PathCourseLabel = styled.div`
  text-align: center;
  display: relative;
  z-index: 1;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`

const ContactUsMessage = styled.div`
  text-align: center;
  margin: 50px 0 20px;
`

const PathCourseImg = styled.img`
  height: 109px;
  transition: transform .15s ease-in-out;

  @media (max-width: 450px) {
    height: 70px;
  }
`

const LRVideoImg = styled.img`
  display: block;
  margin: 20px auto;
  width: 864px;
  max-width: 100%;
  transition: opacity .15s ease-in-out;

  &:hover {
    opacity: .6;
  }
`

const ContactButton = styled(LinkButton)`
  display: block;
  margin: 0 auto;
`

const YourEquipSubscription = ({ ...props }) => {

  let {
    'subscription-granted': subscriptionGranted,
    'purchase-successful': purchaseSuccessful,
  } = queryString.parse(window.location.search)
  purchaseSuccessful = purchaseSuccessful !== undefined
  subscriptionGranted = subscriptionGranted !== undefined

  const client = useApolloClient()
  const user = useContext(LoggedInUserContext)
  const getUser = useInstanceValue(user)
  const [ setDoUserQueryTimeout ] = useSetTimeout()

  const autologin = user ? `?autologin` : ``

  useEffectAsync(
    // Immediately after purchase, keep doing user queries until stripe subscription is found.
    () => {
      if(!purchaseSuccessful) return

      let timesChecked = 0

      const checkAndTryAfterTimeout = () => {
        setDoUserQueryTimeout(
          async () => {
            const user = getUser()
            if(!user) return

            const hasStripeSubscription = !!(user.activeSubscriptions || []).filter(({ type }) => type === 'STRIPE')[0]

            if(!hasStripeSubscription) {
              await client.query({
                context: {
                  isBackupServerQuery: true,  // forcing the await to wait until run through the server via this option
                  forceSaveServerResultToDexie: true,    
                },
                query: userQuery,
                fetchPolicy: `network-only`,
              })
              if(++timesChecked < 10) {
                checkAndTryAfterTimeout()
              }
            }
          },
          1000,
        )
      }

      checkAndTryAfterTimeout()
    },
    [],
  )

  return (
    <>

      <Header {...props}>
        <PageTitle>
          {i18nReact("Your {{logo}} Subscription", {
            logo: <HeaderLogoImg src="/biblearc_equip_1.svg" />
          })}
        </PageTitle>
        <MobilePageTitle>
          {i18n("EQUIP")}
        </MobilePageTitle>
      </Header>

      <Container>
        <Content>

          {purchaseSuccessful &&
            <SuccessContainer>
              <Success>
                <CheckIcon />
                {i18n("Purchase successful")}
              </Success>
            </SuccessContainer>
          }

          {subscriptionGranted &&
            <SuccessContainer>
              <Success>
                <CheckIcon />
                {i18n("Subscription granted")}
              </Success>
            </SuccessContainer>
          }

          <Welcome>
            {i18nReact("Welcome to {{something}}", {
              something: <EquipLogoImg src="/biblearc_equip_1.svg" />,
            })}
          </Welcome>

          <TagLine1>
            {i18n("Come. Learn.")}
          </TagLine1>
          <TagLine2>
            {i18n("Dig deep.")}
          </TagLine2>

          <ButtonContainer>
            <LinkButton
              href="https://equip.biblearc.com/all-courses"
              color="secondary"
              variant="contained"
              disableElevation
            >
              {i18n("Browse All Courses")}
            </LinkButton>
          </ButtonContainer>

          <UseForSection>

            <UseFor>
              {i18nReact("Use {{biblearc_equip}} to learn deeper study of Scripture.", {
                biblearc_equip: <EquipLogoImg2 src="/biblearc_equip_1.svg" />,
              })}
            </UseFor>

            <AlternativelyUseFor>
              {i18nReact("(Use {{my_biblearc_study_bible}} for general Bible reading.)", {
                my_biblearc_study_bible: (
                  <NavLinkOrAWithDisable to="/your-my-bsb-subscription" exact>
                    {i18n("My Biblearc Study Bible")}
                  </NavLinkOrAWithDisable>
                ),
              })}
            </AlternativelyUseFor>
            <AlternativelyUseFor>
              {i18nReact("(Use {{biblearc_tools}} for in-depth study of a passage or topic.)", {
                biblearc_tools: (
                  <NavLinkOrAWithDisable to="/your-tools-subscription" exact>
                    {i18n("Biblearc TOOLS")}
                  </NavLinkOrAWithDisable>
                ),
              })}
            </AlternativelyUseFor>

          </UseForSection>

          <GettingStarted>
            {i18n("Getting Started")}
          </GettingStarted>

          <ThreeSpotToStart>
            {i18n("There are three great trailheads from which to begin your journey...")}
          </ThreeSpotToStart>

          <SpotHeader>
            {i18n("1. Path Courses")}
          </SpotHeader>

          <SpotDesc>
            {i18n("We aim to equip the church for faithful, worship-filled study of the Scriptures. These courses lie at the very center of this aim. They will not be easy, but they will forever change your study of God’s word.")}
          </SpotDesc>

          <PathCourses>

            <PathCourseSection>

              <PathCourseImagesLead>
                {i18n("For beginners...")}
              </PathCourseImagesLead>

              <PathCourseImages>
                <PathCourse href={`https://equip.biblearc.com/course/markup${autologin}`}>
                  <PathCourseImg
                    src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1617982503188-iconmarkup.png"
                  />
                  <PathCourseLabel>
                    {i18n("Markup")}
                  </PathCourseLabel>
                </PathCourse>
                <PathCourse href={`https://equip.biblearc.com/course/discovery${autologin}`}>
                  <PathCourseImg
                    src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1617982414645-iconcfs.png"
                  />
                  <PathCourseLabel>
                    {i18n("Discovery!")}
                  </PathCourseLabel>
                </PathCourse>
                <PathCourse href={`https://equip.biblearc.com/course/interpretation${autologin}`}>
                  <PathCourseImg
                    src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1617981471871-InterpretationIcon.png"
                  />
                  <PathCourseLabel>
                    {i18n("Interpretation")}
                  </PathCourseLabel>
                </PathCourse>
              </PathCourseImages>

            </PathCourseSection>
            <PathCourseSection>

              <PathCourseImagesLead>
                {i18n("For those looking to dig deep...")}
              </PathCourseImagesLead>

              <PathCourseImages>
                <PathCourse href={`https://equip.biblearc.com/course/phrasing${autologin}`}>
                  <PathCourseImg
                    src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1562087864918-sign2-phrasing.png"
                  />
                  <PathCourseLabel>
                    {i18n("Phrasing")}
                  </PathCourseLabel>
                </PathCourse>
                <PathCourse href={`https://equip.biblearc.com/course/bracketing${autologin}`}>
                  <PathCourseImg
                    src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1562609397510-sign2-bracketing.png"
                  />
                  <PathCourseLabel>
                    {i18n("Bracketing")}
                  </PathCourseLabel>
                </PathCourse>
                <PathCourse href={`https://equip.biblearc.com/course/arcing${autologin}`}>
                  <PathCourseImg
                    src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1562087889040-sign2-arcing.png"
                  />
                  <PathCourseLabel>
                    {i18n("Arcing")}
                  </PathCourseLabel>
                </PathCourse>
              </PathCourseImages>

            </PathCourseSection>
            <PathCourseSection>

              <PathCourseImagesLead>
                {i18n("For those wanting to teach and be taught...")}
              </PathCourseImagesLead>

              <PathCourseImages>
                <PathCourse href={`https://equip.biblearc.com/course/didactics${autologin}`}>
                  <PathCourseImg
                    src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1562609435911-sign2-didactics.png"
                  />
                  <PathCourseLabel>
                    {i18n("Didactics")}
                  </PathCourseLabel>
                </PathCourse>
                <PathCourse href={`https://equip.biblearc.com/course/treasury${autologin}`}>
                  <PathCourseImg
                    src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1617981798332-TreasuryIcon.png"
                  />
                  <PathCourseLabel>
                    {i18n("Treasury")}
                  </PathCourseLabel>
                </PathCourse>
              </PathCourseImages>

            </PathCourseSection>
            <PathCourseSection>

              <PathCourseImagesLead>
                {i18n("For the most serious students...")}
              </PathCourseImagesLead>

              <PathCourseImages>
                <PathCourse href={`https://equip.biblearc.com/course/greek-i${autologin}`}>
                  <PathCourseImg
                    src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1562609568015-sign2-greek.png"
                  />
                  <PathCourseLabel>
                    {i18n("Greek")}
                  </PathCourseLabel>
                </PathCourse>
                <PathCourse href={`https://equip.biblearc.com/course/hebrew-i${autologin}`}>
                  <PathCourseImg
                    src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1562088542644-sign2-hebrew.png"
                  />
                  <PathCourseLabel>
                    {i18n("Hebrew")}
                  </PathCourseLabel>
                </PathCourse>
              </PathCourseImages>

            </PathCourseSection>

          </PathCourses>

          <SpotHeader>
            {i18n("2.")}
            {` `}
            {i18n("LIVE Courses")}
          </SpotHeader>

          <SpotDesc>
            {i18n("Every month at Biblearc EQUIP is filled with one or more live courses. For 2025, this includes the following.")}
          </SpotDesc>

          <Schedule />

          <SpotHeader>
            {i18n("3. Learning Resources Training Videos")}
          </SpotHeader>

          <SpotDesc>
            {i18n("A person learns to cook well by first seeing and tasting delicious dishes. That is the inspiration! We take a similar approach with our Learning Resources Video Library. We first present you with the glorious truths born out of diligent study. Then, once you’re inspired, the second video in the set brings you into the kitchen.")}
          </SpotDesc>

          <SpotDesc>
            {i18nReact("Check out the example below, or {{browse_them_all}}.", {
              browse_them_all: (
                <a href={`https://equip.biblearc.com/learningresources${autologin}`}>
                  {i18n("browse through them all")}
                </a>
              ),
            })}
          </SpotDesc>

          <a href={`https://equip.biblearc.com/blog-post/lr-114${autologin}`}>
            <LRVideoImg
              src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1629741297354-ScreenShot20210823at1.54.11PM.png"
              className="dark-mode-exempt"
            />
          </a>
          
          <ContactUsMessage>
            {i18n("Need help planning out your path to deeper study of Scripture?")}
          </ContactUsMessage>

          <ContactButton
            href={`https://equip.biblearc.com/contact${autologin}`}
            color="secondary"
            variant="contained"
          >
            {i18n("Contact us")}
          </ContactButton>

        </Content>
      </Container>

    </>
  )
}


export default memo(YourEquipSubscription)