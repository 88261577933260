const fields = `
  id
  content
  type
  flagStatus
  flagHistory
  studyBibleItemId
  studyBibleItemDetailId
  customStudyBibleItemId
  channelItemId
  createdAt
  updatedAt
  deletedAt
  reactions
  isNew
  user {
    id
    name
    image
  }
`

export default fields