import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'

import { cloneObj, pascalToCamelCase } from '../utils/misc'
import { defaultExpectedUserUpdate, goGetExtraExpectedResponse, recordExpectedResponseData } from './useGoUpdateTable'
import { pushUndoRedoItem } from './useGoUndo'
import useMutationContext from './useMutationContext'
import useInstanceValue from './useInstanceValue'

const useGoUpdateModuleSubtables = ({
  updateFunc,
  updateResult,
  deleteFunc,
  deleteResult,
  singleItemByIdQuery,
  pluralQuery,
  onUpdate,
  onDelete,
  moduleId,
  projectId,
  undoRedoStack=`undo`,
  getDefault,
}) => {

  const tableName = getDefault().__typename
  const queryName = pascalToCamelCase(tableName)

  const context = useMutationContext()
  const getContext = useInstanceValue(context)
  const client = useApolloClient()

  const goUpdateModuleSubtables = useCallback(
    (doUpdateByKeyOrUpdateObjs, moduleSubtables, { savedAt, skipModuleAndProjectUpdate }={}) => {

      if((moduleSubtables || []).some(moduleSubtable => moduleSubtable.moduleId !== moduleId)) throw new Error(`Invalid call to goUpdateModuleSubtables: wrong moduleId.`)

      const isNew = !moduleSubtables
      const now = savedAt || Date.now()
      moduleSubtables = moduleSubtables || Array(doUpdateByKeyOrUpdateObjs.length).fill().map(getDefault)

      const updates = (
        doUpdateByKeyOrUpdateObjs instanceof Array
          ? (
            doUpdateByKeyOrUpdateObjs.map(({ savedAt, __typename, moduleId, ...updateObj }, idx) => ({
              id: moduleSubtables[idx].id || uuidv4(),
              savedAt: now,
              ...updateObj,
            }))
          )
          : (
            moduleSubtables.map(moduleSubtable => {
              const updateObj = {
                id: moduleSubtable.id || uuidv4(),
                savedAt: now
              }
    
              Object.keys(doUpdateByKeyOrUpdateObjs).forEach(key => {
                updateObj[key] = doUpdateByKeyOrUpdateObjs[key](moduleSubtable[key])
              })
    
              return updateObj
            })
          )
      )

      if(updates.length !== moduleSubtables.length) throw new Error(`Invalid call to goUpdateModuleSubtables: doUpdateByKeyOrUpdateObjs and moduleSubtables are of different lengths.`)
      if(moduleSubtables.length === 0) return []

      const newModuleSubtables = cloneObj(moduleSubtables).map((moduleSubtable, idx) => ({
        ...moduleSubtable,
        ...updates[idx],
        moduleId,
      }))

      if([ `undo`, `redo` ].includes(undoRedoStack)) {
        if(isNew && ![ `ModuleSetting` ].includes(tableName)) {
          pushUndoRedoItem({
            type: undoRedoStack,
            action: `delete${tableName}s`,
            data: cloneObj(newModuleSubtables),
          })
        } else {
          const undoUpdates = updates.map((updateObj, idx) => {
            const undoUpdateObj = {}
            Object.keys(updateObj).forEach(key => {
              if(![ `savedAt`, `id` ].includes(key)) {
                undoUpdateObj[key] = moduleSubtables[idx][key]
              }
            })
            return undoUpdateObj
          })
          pushUndoRedoItem({
            type: undoRedoStack,
            action: `update${tableName}s`,
            data: cloneObj(newModuleSubtables),
            updateObjs: cloneObj(undoUpdates),
          })
        }
      }

      const variables = {
        moduleId,
        projectId,
        input: cloneObj(updates),
      }

      const rows = cloneObj(newModuleSubtables)

      const expectedResponseData = {
        ...defaultExpectedUserUpdate,
        [queryName]: {
          __typename: `${tableName}Update`,
          deletedIds: [],
          rows,
        },
        ...(skipModuleAndProjectUpdate ? {} : goGetExtraExpectedResponse({ tableName, projectId, moduleId, client, now })),
      }

      updateFunc({
        variables,
        context: {
          ...getContext(),
          expectedResponse: {
            [`update${tableName}s`]: expectedResponseData,
          },
        },
      })

      recordExpectedResponseData({ client, expectedResponseData: expectedResponseData })

      if(isNew) {
        if(singleItemByIdQuery) {
          cloneObj(newModuleSubtables).forEach(item => {
            client.writeQuery({
              query: singleItemByIdQuery,
              data: {
                [queryName]: item,
              },
              variables: {
                id: item.id,
              },
            })
          })
        }
        if(pluralQuery) {
          const result = client.readQuery({
            query: pluralQuery,
            variables: {
              moduleId,
            },
          })
          const data = cloneObj((result || {})[`${queryName}s`] || null) || []
          if(data) {
            const sortKey = newModuleSubtables[0].ordering ? `ordering` : `id`
            data.push(
              ...(
                cloneObj(newModuleSubtables)
                  .filter(newModuleSubtable => (
                    // Only add if it's not already in the list (which can be caused by an undo + redo)
                    !data.some(({ id }) => id === newModuleSubtable.id)
                  ))
              )
            )
            data.sort((a,b) => a[sortKey] < b[sortKey] ? -1 : 1)
            client.writeQuery({
              query: pluralQuery,
              variables: {
                moduleId,
              },
              data: {
                [`${queryName}s`]: data,
              },
              broadcast: true,
            })
          }
        }
      }

      onUpdate && onUpdate({
        oldData: !isNew ? cloneObj(moduleSubtables) : null,
        newData: cloneObj(newModuleSubtables),
        isNew,
      })

      return cloneObj(newModuleSubtables)

    },
    [ moduleId, projectId, updateFunc, getContext, client, onUpdate, singleItemByIdQuery, pluralQuery, undoRedoStack, tableName, queryName, getDefault ],
  )

  if(updateResult.error) {
    // Nothing to do here since it has gone into queuedMutations and will try again when relevant
    console.error(`update${tableName}s.error`, updateResult.error)
  }

  const goDeleteModuleSubtables = useCallback(
    (moduleSubtables, { savedAt, skipModuleAndProjectUpdate }={}) => {

      const now = savedAt || Date.now()

      if(moduleSubtables.some(moduleSubtable => moduleSubtable.moduleId !== moduleId)) throw new Error(`Invalid call to goUpdateModuleSubtables: wrong moduleId.`)
      if(moduleSubtables.length === 0) return now

      if([ `undo`, `redo` ].includes(undoRedoStack)) {
        const updateObjs = cloneObj(moduleSubtables)
        pushUndoRedoItem({
          type: undoRedoStack,
          action: `update${tableName}s`,
          updateObjs,
        })
      }

      const expectedResponseData = {
        ...defaultExpectedUserUpdate,
        [queryName]: {
          __typename: `${tableName}Update`,
          deletedIds: moduleSubtables.map(({ id }) => id),
          rows: [],
        },
        ...(skipModuleAndProjectUpdate ? {} : goGetExtraExpectedResponse({ tableName, projectId, moduleId, client, now })),
      }

      deleteFunc({
        variables: {
          moduleId,
          projectId,
          ids: moduleSubtables.map(({ id }) => id),
          savedAt: now,
        },
        context: {
          ...getContext(),
          // TODO: the UserUpdate which is returned should take care of deleting the subtables (when relevant)
          expectedResponse: {
            [`delete${tableName}s`]: expectedResponseData,
          },
        },
      })

      recordExpectedResponseData({ client, expectedResponseData: expectedResponseData })

      onDelete && onDelete({ ids: moduleSubtables.map(({ id }) => id) })

      return now

    },
    [ moduleId, projectId, deleteFunc, getContext, client, onDelete, undoRedoStack, tableName, queryName ],
  )

  if(deleteResult.error) {
    // Nothing to do here since it has gone into queuedMutations and will try again when relevant
    console.error(`delete${tableName}s.error`, deleteResult.error)
  }

  return [
    goUpdateModuleSubtables,
    goDeleteModuleSubtables,
  ]
}

export default useGoUpdateModuleSubtables