import { useMemo } from "react"
import { getBookIdListWithCorrectOrdering } from "@bibletags/bibletags-versification"

import useVersionInfo from './useVersionInfo'

const useBookIds = versionId => {

  const { version={} } = useVersionInfo(versionId)

  const bookIds = useMemo(
    () => getBookIdListWithCorrectOrdering({ versionInfo: version }),
    [ version ],
  )

  return bookIds
}

export default useBookIds
