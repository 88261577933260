import { memo } from 'react'
// import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import List from '@material-ui/core/List'

// import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'

import SubtleDivider from '../../common/SubtleDivider'
import CheckBoxModuleSetting from '../shared/CheckBoxModuleSetting'
import SliderModuleSetting from '../shared/SliderModuleSetting'
import GreekPunctuationOptionsForModuleSettings from '../shared/GreekPunctuationOptionsForModuleSettings'
import ParagraphsAndHeadingsOptionsForModuleSettings from '../shared/ParagraphsAndHeadingsOptionsForModuleSettings'
// import CustomSwitch from '../../common/CustomSwitch'

const Container = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  padding: 15px 15px 5px;
`

const StyledSubtleDivider = styled(SubtleDivider)`
  margin: 0 -15px;
`

const MarkupSettings = ({
  projectId,
  versionId,
  languageId,
  displayedTextSizes,
  displayedLineSpacings,
  setDisplayedTextSizes,
  setDisplayedLineSpacings,
  textSizesSetting,
  lineSpacingsSetting,
  showVsNumsSetting,
  showCfsSetting,
  showNotesSetting,
  showCantillationSetting,
  showHebrewVowelsSetting,
  showGreekAccentsSetting,
  greekPunctuationSetting,
  paragraphFormattingSetting,
  showContextSetting,
  lensesSetting,
}) => {

  // const [ goSetLenses ] = useGoSetModuleSetting({
  //   moduleSetting: lensesSetting,
  //   projectId,
  // })

  // const toggleLenses = useCallback(
  //   ({ target }) => {
  //     goSetLenses({ value: target.checked ? `ON` : `OFF` })
  //   },
  //   [ goSetLenses ],
  // )

  return (
    <Container>

      <SliderModuleSetting
        projectId={projectId}
        moduleSetting={textSizesSetting}
        displayedValue={displayedTextSizes}
        setDisplayedValue={setDisplayedTextSizes}
        label={i18n("Text Size", "", "markup")}
        className="module-settings-textsizes"
      />

      <SliderModuleSetting
        projectId={projectId}
        moduleSetting={lineSpacingsSetting}
        displayedValue={displayedLineSpacings}
        setDisplayedValue={setDisplayedLineSpacings}
        label={i18n("Line Spacing", "", "markup")}
        max={350}
        className="module-settings-linespacings"
      />

      <List>

        <CheckBoxModuleSetting
          projectId={projectId}
          moduleSetting={showVsNumsSetting}
          label={i18n("Show verse numbers", "", "markup")}
        />

        {versionId !== `original` &&
          <CheckBoxModuleSetting
            projectId={projectId}
            moduleSetting={showCfsSetting}
            label={i18n("Show cross-reference dots", "", "markup")}
          />
        }

        <CheckBoxModuleSetting
          projectId={projectId}
          moduleSetting={showNotesSetting}
          label={i18n("Show translation note dots", "", "markup")}
        />

        {versionId === `original` && languageId === `heb` &&
          <>
            <CheckBoxModuleSetting
              projectId={projectId}
              moduleSetting={showHebrewVowelsSetting}
              label={i18n("Show vowels", "", "markup")}
            />

            <CheckBoxModuleSetting
              projectId={projectId}
              moduleSetting={showCantillationSetting}
              label={i18n("Show cantillation", "", "markup")}
            />
          </>
        }

        {versionId === `original` && languageId === `grc` &&
          <>
            <CheckBoxModuleSetting
              projectId={projectId}
              moduleSetting={showGreekAccentsSetting}
              label={i18n("Show accents", "", "markup")}
            />

            <GreekPunctuationOptionsForModuleSettings
              projectId={projectId}
              moduleSetting={greekPunctuationSetting}
            />
          </>
        }

      </List>

      <StyledSubtleDivider />

      <List>

        <ParagraphsAndHeadingsOptionsForModuleSettings
          projectId={projectId}
          moduleSetting={paragraphFormattingSetting}
        />

        <CheckBoxModuleSetting
          projectId={projectId}
          moduleSetting={showContextSetting}
          label={i18n("Show context summaries", "", "markup")}
        />

      </List>

      {/* <StyledSubtleDivider />

      <List>

        <CustomSwitch
          checked={lensesSetting.value === `ON`}
          onChange={toggleLenses}
          label={i18n("Markup using lenses", "", "outline")}
        />

      </List> */}

    </Container>
  )
}

export default memo(MarkupSettings)