import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import FadedLoading from './FadedLoading'

const DialogTitleItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const StyledIconButton = styled(IconButton)`
  margin: -9px -16px -10px 0;
`

const InfoDialog = ({
  onOkay,
  title=i18n("Note"),
  explanation,
  okayButtonLabel=i18n("Okay"),
  color="primary",
  loading,
  showCloseIconButton,
  ...props
}) => {

  return (
    <Dialog
      {...props}
    >
      <DialogTitle>
        <DialogTitleItems>
          {title}
          {!!showCloseIconButton &&
            <StyledIconButton
              onClick={onOkay}
            >
              <CloseIcon />
            </StyledIconButton>
          }
        </DialogTitleItems>
      </DialogTitle>
      <DialogContent>
        {typeof explanation === 'string'
          ?
            <DialogContentText>
              {explanation}
            </DialogContentText>
          : explanation
        }
      </DialogContent>
      {!showCloseIconButton &&
        <DialogActions>
          <Button
            onClick={onOkay}
            variant="contained"
            color={color}
          >
            {okayButtonLabel}
          </Button>
        </DialogActions>
      }
      {loading && <FadedLoading />}
    </Dialog>
  )
}

export default memo(InfoDialog)