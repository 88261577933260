import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

const StyledMarkupLine = styled(MarkupLine)`
  background-color: ${({ theme, color }) => theme.palette.markupColors[color].highlight};
  z-index: -1;
`

// TODO

const Replacement = ({
  color,
  ...otherProps
}) => (
  <StyledMarkupLine
    {...otherProps}
    color={color}
  />
)


export default memo(Replacement)