import { useEffect } from 'react'
import { useUpdate } from 'react-use'

import useEqualObjsMemo from './useEqualObjsMemo'
import { addUpdateEmbedSettingsEvent, getEmbedSettings, removeUpdateEmbedSettingsEvent } from '../graphql/links/embedLink'

export const useEmbedSettings = () => {

  const update = useUpdate()

  useEffect(
    () => {
      addUpdateEmbedSettingsEvent(update)
      return () => removeUpdateEmbedSettingsEvent(update)
    },
    [ update ],
  )

  const embedSettings = useEqualObjsMemo(getEmbedSettings())

  return embedSettings
}

export default useEmbedSettings