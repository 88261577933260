import { memo } from 'react'
import styled from 'styled-components'
// import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'

import Header from "../../common/Header"

const MessageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
`

const Message = styled.div`
  font-weight: 100;
  font-size: 26px;
`

const FAQ = ({ ...props }) => {

  return (
    <>
      <Header {...props} />

      <MessageContainer>
        <Message>
          {i18n("FAQ")}
        </Message>
      </MessageContainer>

    </>
  )
}


export default memo(FAQ)