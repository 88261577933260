import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

const PassagePopperHeading = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 12px;
  margin-bottom: 3px;

  &:not(:first-child) {
    margin-top: 15px;
  }
`

export default memo(PassagePopperHeading)