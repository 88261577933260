import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'

import SearchSuggestion from './SearchSuggestion'
import SearchInstructions from './SearchInstructions'
import useInstanceValue from '../../hooks/useInstanceValue'

const Container = styled.div`
  flex-shrink: 1;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ $isEmbedSearch }) => !$isEmbedSearch ? `` : `
    flex: 1;
  `}

`

const SearchSuggestions = ({
  autoCompleteSuggestions,
  selectedIdx,
  userHasEnteredText,
  onClick,
  isEmbedSearch,
}) => {

  // Do not show hover when they are using arrows or typing
  const [ keyboardFocussed, setKeyboardFocussed ] = useState(true)
  const getKeyboardFocus = useInstanceValue(keyboardFocussed)
  useEffect(
    () => {

      const mouseMove = () => {
        if(getKeyboardFocus()) {
          setKeyboardFocussed(false)
        }
      }

      const keyDown = () => {
        if(!getKeyboardFocus()) {
          setKeyboardFocussed(true)
        }
      }

      window.addEventListener('mousemove', mouseMove)
      window.addEventListener('keydown', keyDown)

      return () => {
        window.removeEventListener('mousemove', mouseMove)
        window.removeEventListener('keydown', keyDown)
      }

    },
    [ getKeyboardFocus ],
  )

  return (
    <Container $isEmbedSearch={isEmbedSearch}>

      {autoCompleteSuggestions.map((suggestion, idx) => (
        <SearchSuggestion
          key={idx}
          selected={idx === selectedIdx}
          suggestion={suggestion}
          doNotShowHover={keyboardFocussed}
          onClick={onClick}
          idx={idx}
        />
      ))}

      {!userHasEnteredText &&
        <>
          {autoCompleteSuggestions.length > 0 && <Divider />}
          <SearchInstructions
            abbreviated={autoCompleteSuggestions.length >= 3}
          />
        </>
      }

    </Container>
  )
}

export default memo(SearchSuggestions)