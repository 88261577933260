import React from "react"
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0;
  padding-left: 80px;
  min-height: 90px;

  ${({ $placement }) => $placement === 'alone' ? `` : `
    float: ${$placement};
  `}
`

const Quote = styled.div`
  ::before {
    content: "“";
    position: absolute;
    font-size: 78px;
    top: -16px;
    left: 32px;
  }

  font-size: 20px;
`

const Citation = styled.div`
  ::before {
    content: "—";
    color: rgba(0,0,0,.2);
  }

  margin-top: 10px;
  font-size: 15px;
  color: rgba(0,0,0,.5);
`

const PullQuote = ({
  quote="",
  citation="",
  placement="alone",
}) => {

  return (
    <Container
      className="flipeditor-pullquote"
      $placement={placement}
    >
      <Quote className="flipeditor-pullquote-quote">
        {quote}
      </Quote>
      {!!citation.trim() &&
        <Citation className="flipeditor-pullquote-citation">
          {citation}
        </Citation>
      }
    </Container>
  )

}

export default PullQuote
