const keyDownSpace = async params => {

  const {
    event,
    noneditableViewingMode,
    getModule,
    goUpdateModule,
  } = params

  if(noneditableViewingMode) return

  const { inEditingMode } = getModule()

  event.preventDefault()

  await goUpdateModule({ inEditingMode: !inEditingMode })

}

export default keyDownSpace