import { memo } from 'react'

import SearchSuggestionTranslationMenuItems from './SearchSuggestionTranslationMenuItems'
import SearchSuggestionOriginalMenuItems from './SearchSuggestionOriginalMenuItems'

const SearchSuggestionMenuItems = ({
  words,
  ...otherProps
}) => {

  const isOrigLanguageSearch = !!words
  const MenuItems = isOrigLanguageSearch ? SearchSuggestionOriginalMenuItems : SearchSuggestionTranslationMenuItems

  return (
    <MenuItems
      words={words}
      {...otherProps}
    />
  )
}

export default memo(SearchSuggestionMenuItems)