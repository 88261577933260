import { useMemo } from 'react'

import useVersionInfos from './useVersionInfos'

const emptyObj = {}

const useVersionInfo = versionId => {

  const versionIds = useMemo(() => [ versionId ], [ versionId ])

  const { versionInfo } = useVersionInfos({
    versionIds,
    skip: !versionId,
  })

  return versionInfo || emptyObj
}

export default useVersionInfo
