import useModuleSettings from './useModuleSettings'

export const defaultSettingValues = {
  legacy: {},
}

const useLegacySettings = ({
  moduleId,
  skip,
}) => {

  const legacySettings = useModuleSettings({
    moduleId,
    defaultSettingValues,
    skip,
  })

  return legacySettings

}

export default useLegacySettings