import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'

import useMapEditorItem from '../../../hooks/useMapEditorItem'
import { GET_DEFAULT_EVENT } from './BibleMap'
import { getPrimaryDate, getPrimaryName, preventDefaultEvent } from '../../../utils/misc'
import { anglePattern, colorPattern, dateRangePattern, dateRangesPattern, minDisplayScalePattern, placeTypeOptions } from './mapDataIsValid'

import FadedLoading from '../../common/FadedLoading'
import BibleMapEditorNames from './BibleMapEditorNames'
import BibleMapEditorBody from './BibleMapEditorBody'
import BibleMapEditorLevelOfImportance from './BibleMapEditorLevelOfImportance'

const EventsHeading = styled.div`
  font-size: 16px;
`

const EventRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  transition: transform .15s ease-in-out;

  &:hover {
    transform: translateX(10px);
    cursor: pointer;
  }
`

const StyledEditIcon = styled(EditIcon)`
  font-size: 14px;
  margin: -1px 0 0 9px;
`

const None = styled.div`
  font-style: italic;
  margin: -10px 0;
  font-weight: 300;
`

const PlaceId = styled.div`
  margin: -20px 0 -10px;
  font-weight: 300;
  text-align: right;
  font-size: 12px;
`

const AddEventButton = styled(Button)`
  margin-bottom: 5px;
`

const BibleMapEditorPlace = ({
  mapLayerId,
  placeInEdit,
  requestingLock,
  lockObtained,
  updating,
  doUpdateMapLayer,
  setEventInEdit,
  mapLayerIds,
  ...otherProps
}) => {

  const inEdit = { ...placeInEdit }
  delete inEdit.events

  const { onChange, edited, buttons } = useMapEditorItem({
    mapLayerId,
    type: `place`,
    inEdit,
    requestingLock,
    lockObtained,
    updating,
    doUpdateMapLayer,
    ...otherProps,
  })

  const { id, names, levelOfImportance, search, angle, minDisplayScale, dateRange, dateRanges, color, about, type } = edited

  const commonProps = {
    onChange: onChange,
    disabled: !lockObtained || updating,
    variant: "outlined",
    size: "small",
    fullWidth: true,
  }

  const createNewEvent = useCallback(
    event => {
      preventDefaultEvent(event)
      setEventInEdit(GET_DEFAULT_EVENT(mapLayerIds))
    },
    [ setEventInEdit, mapLayerIds ],
  )

  return (
    <>

      <BibleMapEditorBody>

        <BibleMapEditorNames
          names={names}
          mapLayerIds={mapLayerIds}
          {...commonProps}
        />

        <TextField
          value={`type ${type}`}
          label={i18n("Location Type")}
          select
          {...commonProps}
        >
          {placeTypeOptions.map(typeOption => (
            <MenuItem
              key={typeOption}
              value={`type ${typeOption}`}
            >
              {typeOption}
            </MenuItem>
          ))}
        </TextField>

        {[ `KINGDOM` ].includes(type) && !!id &&
          <PlaceId>
            ID:
            {` `}
            {id}
          </PlaceId>
        }

        {[ `RIVER` ].includes(type) &&
          <TextField
            value={angle || ``}
            data-keys={`angle`}
            label={i18n("Angle")}
            InputProps={{
              endAdornment: <InputAdornment position="end">{i18n("degrees")}</InputAdornment>,
            }}
            error={!!angle && !new RegExp(anglePattern).test(angle || ``)}
            {...commonProps}
          />
        }

        {[ `RIVER`, `KINGDOM`, `PROVINCE`, `AREA`, `SEA`, `DESERT` ].includes(type) &&
          <TextField
            value={minDisplayScale || ``}
            data-keys={`minDisplayScale`}
            label={i18n("Minimum Display Scale")}
            placeholder="1 – 100 (default: 10)"
            error={!!minDisplayScale && !new RegExp(minDisplayScalePattern).test(minDisplayScale || ``)}
            {...commonProps}
          />
        }

        {[ `KINGDOM`, `AREA` ].includes(type) &&
          <TextField
            value={dateRange || ``}
            data-keys={`dateRange`}
            label={i18n("Establishment Date Range")}
            placeholder="e.g. 312 BC - AD 100"
            error={
              !new RegExp(dateRangePattern).test(dateRange || ``)
              && !(type === `AREA` && !dateRange)  // optional textfield for AREA
            }
            {...commonProps}
          />
        }

        {[ `KINGDOM` ].includes(type) &&
          <TextField
            value={color || ``}
            data-keys={`color`}
            label={i18n("Color")}
            placeholder="e.g. #FF0000"
            error={!new RegExp(colorPattern).test(color || ``)}
            {...commonProps}
          />
        }

        {[ `PROVINCE`, `CITY` ].includes(type) &&
          <TextField
            value={dateRanges || ``}
            data-keys={`dateRanges`}
            label={i18n("Date Range + Kingdom ID Sets")}
            placeholder="e.g. 251 BC - 32 BC | 284d7"
            error={!new RegExp(dateRangesPattern).test(dateRanges || ``)}
            multiline
            {...commonProps}
          />
        }


        <TextField
          value={about || ``}
          data-keys={`about`}
          label={i18n("About")}
          multiline
          {...commonProps}
        />

        <TextField
          value={search || ``}
          data-keys={`search`}
          label={i18n("Search Text")}
          placeholder={i18n("E.g. {{example}}", { example: `jerusalem / salem` })}
          {...commonProps}
        />

        <BibleMapEditorLevelOfImportance
          levelOfImportance={levelOfImportance || 1}
          {...commonProps}
        />

        <EventsHeading>
          {i18n("Events")}
        </EventsHeading>

        {placeInEdit.events.length === 0 &&
          <None>
            {i18n("None")}
          </None>
        }

        {placeInEdit.events.map(event => (
          <EventRow
            key={event.id}
            onClick={() => setEventInEdit(event)}
          >
            {getPrimaryName(event)}
            {` `}
            ({getPrimaryDate(event)})
            <StyledEditIcon />
          </EventRow>
        ))}

        <AddEventButton
          onClick={createNewEvent}
          {...commonProps}
          variant="contained"
          onChange={null}
          disableElevation
          {...(otherProps.placeId ? {} : {
            disabled: true,
          })}
        >
          {i18n("Add an Event")}
        </AddEventButton>

        {(requestingLock || updating) && <FadedLoading />}

      </BibleMapEditorBody>

      {buttons}

    </>
  )
}

export default memo(BibleMapEditorPlace)