import { useCallback } from "react"
import { useApolloClient } from '@apollo/client'

import useInstanceValue from "./useInstanceValue"
import { defaultSettingValues as legacyDefaultSettingValues } from "./useLegacySettings"
import { defaultSettingValues as outlineDefaultSettingValues } from "./useOutlineSettings"
import { defaultSettingValues as markupDefaultSettingValues } from "./useMarkupSettings"
import useGoSetModuleSetting from "./useGoSetModuleSetting"
import useMutationContext from "./useMutationContext"
import { camelToDashCase, cloneObj } from "../utils/misc"

import moduleSettingQuery from '../graphql/queries/moduleSetting'

const defaultSettingValuesByModuleType = {
  DISCOURSE: legacyDefaultSettingValues,  // TODO
  PHRASING: legacyDefaultSettingValues,  // TODO
  DIAGRAMMING: legacyDefaultSettingValues,  // TODO
  OUTLINE: outlineDefaultSettingValues,
  MARKUP: markupDefaultSettingValues,
  NOTES: [],
}

const useDuplicateModuleSettings = ({
  moduleToCopyFrom,
  newModuleId,
  projectId,
}) => {

  const context = useMutationContext()
  const getContext = useInstanceValue(context)
  const client = useApolloClient()

  const defaultSettingValues = defaultSettingValuesByModuleType[moduleToCopyFrom.type] || []

  const newModuleSettingsArray = Object.keys(defaultSettingValues).map(setting => ({
    __typename: `ModuleSetting`,
    id: `${newModuleId}:${camelToDashCase(setting || ``)}`,
    value: cloneObj(defaultSettingValues[setting]),
  }))

  let idx = -1
  const getParams = idx => ({ moduleSetting: newModuleSettingsArray[idx], projectId, isBulkCreate: true })
  const goSetFunctions = [  // if I change this, I must change useModuleSettings and also consider changing batchMax
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
    useGoSetModuleSetting(getParams(++idx))[0],
  ]

  const getGoSetFunctions = useInstanceValue(goSetFunctions)
  const getNewModuleSettingsArray = useInstanceValue(newModuleSettingsArray)
  const getModuleIdToCopyFrom = useInstanceValue(moduleToCopyFrom.id)

  const goDuplicateModuleSettings = useCallback(
    async () => {

      const moduleIdToCopyFrom = getModuleIdToCopyFrom()
      const newModuleSettingsArray = getNewModuleSettingsArray()
      const goSetFunctions = getGoSetFunctions()

      await Promise.all(
        newModuleSettingsArray.map(async ({ id, value: defaultValue }, idx) => {
          const [ x, settingInDashCase ] = id.split(':')  // eslint-disable-line no-unused-vars
          const { data: { moduleSetting: { value } } } = await client.query({
            query: moduleSettingQuery,
            variables: {
              id: `${moduleIdToCopyFrom}:${settingInDashCase}`,
              defaultValue,
            },
            context: getContext(),
          })
          goSetFunctions[idx]({
            value,
          })
        })
      )

    },
    [ client, getModuleIdToCopyFrom, getNewModuleSettingsArray, getGoSetFunctions, getContext ],
  )

  return goDuplicateModuleSettings
}

export default useDuplicateModuleSettings
