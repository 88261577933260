import { memo, useCallback } from 'react'

import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'

import CustomCheckbox from '../../common/CustomCheckbox'

const CheckBoxModuleSetting = ({
  projectId,
  moduleSetting,
  label,
}) => {

  const [ goSet ] = useGoSetModuleSetting({
    moduleSetting,
    projectId,
  })

  const toggle = useCallback(
    ({ target }) => {
      goSet({
        value: target.checked,
      })
    },
    [ goSet ],
  )

  return (
    <CustomCheckbox
      checked={moduleSetting.value}
      onChange={toggle}
      label={label}
    />
  )
}

export default memo(CheckBoxModuleSetting)