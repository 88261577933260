const findEntities = entityType => (block, callback, contentState) => {
  block.findEntityRanges(
    character => {
      const entityKey = character.getEntity()
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === entityType
      )
    },
    callback
  )
}

export default findEntities