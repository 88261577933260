const keyDownEscape = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
  } = params

  if(modifierKey) return
  if(shiftKey) return

  event.preventDefault()
  window.getSelection().empty()

}

export default keyDownEscape