import { memo } from 'react'
import styled from 'styled-components'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

const StyledRadioGroup = styled(RadioGroup)`
  padding: 0 0 0 6px;
`

const StyledFormControlLabel = styled(FormControlLabel)`

  padding: 0 16px;
  margin: -2px 0 -2px -11px;

  .MuiRadio-root {
    padding: 8px;
  }

  .MuiTypography-root {
    margin-top: 1px;
  }

`

const CustomRadioGroup = ({
  radios,
  otherRadioProps={},
  ...otherProps
}) => (
  <StyledRadioGroup
    {...otherProps}
  >
    {radios.map((radio, idx) => (
      <StyledFormControlLabel
        key={radio.value || idx}
        control={<Radio size="small" />}
        {...radio}
        {...otherRadioProps}
      />
    ))}
  </StyledRadioGroup>
)

export default memo(CustomRadioGroup)