import { memo } from 'react'
import styled from 'styled-components'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import Tooltip from '@material-ui/core/Tooltip'
import { i18n } from 'inline-i18n'
import { getLanguageInfo } from '@bibletags/bibletags-ui-helper'

import MyVersionsListOptionsMenu from "./MyVersionsListOptionsMenu"
// import MyVersionsOfflineAvailableSwitch from "./MyVersionsOfflineAvailableSwitch"

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  margin: -1px -1px 0 -1px;
  padding: 15px;
  background-color: white;
`

const HandleContainer = styled.div`
  height: 36px;
  width: 36px;
`

const StyledDragHandleIcon = styled(DragHandleIcon)`
  padding: 6px;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey[500]};

  &:hover {
    color: ${({ theme }) => theme.palette.grey[900]};
    cursor: move;
  }
`

const BasicVersionInfo = styled.div`
  flex: 1;
  padding: 0 5px;

  ${({ $hide, theme }) => !$hide ? `` : `
    color: ${theme.palette.grey[500]};
  `}
`

const VersionAbbr = styled.div`
  font-weight: 700;
  font-size: 16px;
`

const Hidden = styled.div`
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  color: white;
  border-radius: 10px;
  padding: 2px 8px;
  margin-left: 10px;
  position: relative;
  top: -2px;
`

const VersionLanguage = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  margin-left: 12px;
  color: ${({ theme }) => theme.palette.grey[600]};
`

const VersionName = styled.div`
  font-size: 15px;
`

const MyVersionsListLine = ({
  id,
  // ordering,
  hide,
  safeVersionAbbr,
  version,
  dragHandleProps,
}) => {

  const { name, languageId } = version || {}

  return (
    <Container>

      <BasicVersionInfo $hide={hide}>

        <VersionAbbr>

          {safeVersionAbbr}

          <VersionLanguage>
            {getLanguageInfo(languageId).nativeName}
          </VersionLanguage>

          {!!hide &&
            <>
              {` `}
              <Tooltip
                title={i18n("This version cannot be removed as the app’s core functionality depends on it. However, it will not show in the version chooser.")}
              >
                <Hidden>
                  {i18n("Hidden")}
                </Hidden>
              </Tooltip>
            </>
          }

        </VersionAbbr>

        <VersionName>
          {name}
        </VersionName>

        {/* <MyVersionsOfflineAvailableSwitch
          id={id}
          ordering={ordering}
          setupStatus={setupStatus}
          disabled={false}
        /> */}

      </BasicVersionInfo>

      <Tooltip
        title={i18n("Drag to reorder")}
      >
        <HandleContainer {...dragHandleProps}>
          <StyledDragHandleIcon />
        </HandleContainer>
      </Tooltip>

      <MyVersionsListOptionsMenu
        versionId={id}
      />

    </Container>
  )
}

export default memo(MyVersionsListLine)