import { useCallback, useRef } from 'react'

import useSetTimeout from './useSetTimeout'
import useInstanceValue from './useInstanceValue'

const useDoubleClickCallback = callback => {

  const getCallback = useInstanceValue(callback)
  const [ setDoubleClickCheckTimeout ] = useSetTimeout()
  const waitingOnSecondClick = useRef(false)

  const onClick = useCallback(
    event => {

      if(waitingOnSecondClick.current) {
        waitingOnSecondClick.current = false
        getCallback()(event)
      }

      waitingOnSecondClick.current = true
      setDoubleClickCheckTimeout(() => { waitingOnSecondClick.current = false }, 300)

    },
    [ getCallback, setDoubleClickCheckTimeout ],
  )

  return onClick
}

export default useDoubleClickCallback