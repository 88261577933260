import { memo, useContext } from 'react'
import { useNetworkState } from 'react-use'
import styled from 'styled-components'
import { i18n, getLocale } from 'inline-i18n'
import Button from '@material-ui/core/Button'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'

import { LoggedInUserContext } from '../../context/LoggedInUser'
import { getAdminLevelText } from '../../utils/misc'
import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useModalAnchor from '../../hooks/useModalAnchor'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'

import SubscriptionInfo from './SubscriptionInfo'
// import MyCertificates from './MyCertificates'
// import ProjectSyncManagement from './ProjectSyncManagement'  // TEMP
import Avatar from './Avatar'
import JoinTheMailingListButton from './JoinTheMailingListButton'
import OptionsPopover from './OptionsPopover'
import AvatarEditorDialog from './AvatarEditorDialog'

const Container = styled.div`
  padding: 20px;
  max-width: 380px;
`

const StyledAvatar = styled(Avatar)`
  position: absolute;
  top: 4px;
  right: 8px;

`

const NameLabel = styled.div`
  font-weight: 700;
  font-size: 17px;
  margin-right: 40px;
  margin-top: -4px;
`

const EmailLabel = styled.div`
  font-weight: 300;
  opacity: .7;
  margin-right: 40px;
  margin-bottom: 5px;
`

const AdminLabel = styled.div`
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 5px;
`

const BlockedLabel = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
`

// const LanguageLabel = styled.div`
//   font-size: 13px;
// `

const UserSinceLabel = styled.div`
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 10px;
`

const ButtonsContainer = styled.div`
  margin: -4px;
`

const StyledButton = styled(Button)`
  margin: 4px;
`

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  margin: 20px -20px;
`

const JoinContainer = styled.div`
  margin: 5px 0 -7px;
  line-height: 1.7;
`

const StyledJoinTheMailingListButton = styled(JoinTheMailingListButton)`
  font-size: 12px;
  display: inline-block;
`

const AccountMenu = ({ 
  closeModalAndDrawer,
}) => {

  const user = useContext(LoggedInUserContext)
  const { online } = useNetworkState()
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)
  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const [ avatarEditorOpen, toggleAvatarEditorOpen ] = useSimpleToggle()

  const adminLevelText = getAdminLevelText(user.adminLevel)

  const closeEditor = useInstanceValuesCallback(
    () => {
      toggleAvatarEditorOpen({ force: false})
      closeModalAndDrawer()
    },
  )

  return (
    <Container>

      <StyledAvatar />

      <NameLabel>
        {user.name || i18n("Unknown name")}
      </NameLabel>

      <EmailLabel>
        {user.email || i18n("Unknown email")}
      </EmailLabel>

      {!!adminLevelText &&
        <AdminLabel>
          {adminLevelText}
        </AdminLabel>
      }

      {!!user.blockedFromSharing &&
        <BlockedLabel>
          {i18n("Blocked from sharing")}
        </BlockedLabel>
      }

      {/* <LanguageLabel>
        {i18n("Site language: {{language}}", {
          language: user.languageId,
        })}
      </LanguageLabel> */}

      <UserSinceLabel>
        {i18n("User since {{date}}", {
          date: new Date(user.createdAt).toLocaleDateString(getLocale()),
        })}
      </UserSinceLabel>

      <ButtonsContainer>

        {/* <StyledButton
          variant="contained"
          size="small"
          disableElevation
          // onClick={resetApp}
        >
          {i18n("Edit account")}
        </StyledButton> */}

        {/* <StyledButton
          variant="contained"
          size="small"
          disableElevation
          // onClick={resetApp}
        >
          {i18n("Email preferences")}
        </StyledButton> */}

        {[ 'ADMIN', 'REPORTING', 'EDITOR', 'CONTRIBUTOR', 'MARKETING' ].includes(user.adminLevel) &&
          <NavLinkOrAWithDisable
            exact
            to="/admin"
            disabled={!online}
          >
            <StyledButton
              variant="contained"
              size="small"
              disableElevation
              disabled={!online}
              >
              {i18n("Admin")}
            </StyledButton>
          </NavLinkOrAWithDisable>
        }

        <StyledButton
          variant="contained"
          size="small"
          disableElevation
          disabled={!online}
          onClick={openModal}
        >
          {i18n("Account")}
        </StyledButton>

        <OptionsPopover
          anchorEl={anchorEl}
          onClose={closeModal}
        >
          <MenuList onClick={closeModal}>
            <MenuItem
              onClick={window.sessionSyncAuth.updateAccount}
            >
              {i18n("Change name or email")}
            </MenuItem>
            <MenuItem
              onClick={toggleAvatarEditorOpen}
            >
              {i18n("Change profile image")}
            </MenuItem>
          </MenuList>
        </OptionsPopover>

        <StyledButton
          variant="outlined"
          size="small"
          disableElevation
          disabled={!online}
          onClick={window.sessionSyncAuth.logOut}
        >
          {i18n("Log Out")}
        </StyledButton>

      </ButtonsContainer>

      {!channelIdInPWA &&
        <JoinContainer>
          <StyledJoinTheMailingListButton
            displayAsLink
          />
        </JoinContainer>
      }

      {!channelIdInPWA &&
        <>

          {/* <Divider />
          <MyCertificates /> */}

          <Divider />
          <SubscriptionInfo closeModalAndDrawer={closeModalAndDrawer} />

        </>
      }

      {/* {user.hasToolsPlan &&
        <>
          <Divider />
          <ProjectSyncManagement />
        </>
      } */}

      <AvatarEditorDialog
        open={avatarEditorOpen}
        onClose={closeEditor}
      />

    </Container>
  )
}

  // Account menu (click avatar)
    // [Name]
    // [email]
    // [adminLevel]
    // [blockedFromSharing]
    // [language]
    // [createdAt "User since"]
    // Account settings
    // ---
    // Certificates
    // [list]
    // ---
    // Subscription status
    // Subscription explanation, including free for developing world
    // Ability for someone to request free sub for developing world, or scholarship for course
    // Credits (need to think through this)
    // Payment history
    // Donation receipt address (needed??)
    // ---  // only show next section if they have a sub
    // (toggle) Projects available offline  [projectsInfo.offlineSetupStatus === 'online-only' | 'offline-doing-initial-sync' | 'offline-ready']
      // indicate sync status by avatar
        // no login OR no subscription (no icon)
        // projects offline on
          // with internet connection
            // saved on device, saving in the cloud (cloud upload)
            // saved on device and in the cloud (cloud done)
          // without internet connection
            // saved on device, waiting on connection to save in the cloud (domain verification OR system security update good)
            // saved on device and in the cloud (domain verification OR system security update good)
        // projects offline off
          // with internet connection
            // saving in the cloud (cloud upload)
            // saved in the cloud (cloud done)
          // without internet connection
            // waiting on connection to save in the cloud (cloud off)
            // saved in the cloud (cloud off)
    // [icon matching that on avater] Sync status
    // ---
    // Email preferences
    // ---
    // Log out

export default memo(AccountMenu)