import { useEffect, useContext, useRef } from 'react'
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
// import { useHistory } from "react-router-dom"
import { usePrevious } from 'react-use'

import { OfflineVersionsContext } from '../context/LocalInfo'
import { OfflineSetupStatusContext } from '../context/LocalInfo'
import { equalObjs, callOnFocus } from '../utils/misc'
import useStickyRefState from './useStickyRefState'
import useEffectAsync from './useEffectAsync'

const useDataQuery = props => {

  // const history = useHistory()

  let query, dataSetName
  let { refetchOnFocus, fetchPolicyNetworkNoRerun, dataOnError, sticky, ...propsWithoutQuery } = props
  const { skip } = propsWithoutQuery
  for(let key in props) {
    if(/Query$/.test(key)) {
      query = props[key]
      delete propsWithoutQuery[key]
      dataSetName = key.replace(/Query$/, '')
      break
    }
  }

  const offlineVersions = useContext(OfflineVersionsContext)
  const offlineSetupStatus = useContext(OfflineSetupStatusContext)

  const loadedOnce = useRef()
  const prevVars = usePrevious(propsWithoutQuery.variables)
  if(!equalObjs(propsWithoutQuery.variables, prevVars)) {
    loadedOnce.current = false
  }

  let { error, data, refetch, ...otherParams } = useQuery(
    query,
    {
      ...propsWithoutQuery,
      ...(!fetchPolicyNetworkNoRerun ? {} : {
        fetchPolicy: 'network-only',
        nextFetchPolicy: loadedOnce.current ? 'cache-first' : null,  // meaning that we shouldn't go back to the server for offset=0 when fetchMore is called
      }),
      context: {
        ...(propsWithoutQuery.context || {}),
        ...(![ 'chapter', 'verse' ].includes(dataSetName) ? {} : {
          // including this otherwise causes an unnecessary refetch of data
          offlineVersions,
        }),
        offlineSetupStatus,
      },
    },
  )

  const [ stickyData, setStickyData ] = useStickyRefState({ id: `useDataQuery:${dataSetName}:${JSON.stringify(propsWithoutQuery.variables)}` })
  let dataToReturn = data || {}
  if(sticky && !data && stickyData !== undefined) {
    if(dataOnError === undefined) {
      dataOnError = stickyData
    }
    if(!error) {
      dataToReturn = { [dataSetName]: stickyData }
    }
  }

  if(otherParams.networkStatus === 7) {
    loadedOnce.current = true
  }

  // If it goes offline and comes back, refetch queries with errors
  const hasError = !!error
  useEffect(
    () => {
      const listener = () => {
        if(window.navigator.onLine && hasError) {
          refetch()
        }
      }
      window.addEventListener('online', listener)
      return () => window.removeEventListener('online', listener)
    },
    [ refetch, hasError ],
  )

  useEffect(
    () => {
      if(!refetchOnFocus || skip) return
      return callOnFocus(refetch)
    },
    [ refetchOnFocus, skip, refetch ],
  )

  useEffectAsync(
    () => {
      if(data && sticky) {
        setStickyData(data[dataSetName])
      }
    },
    [ data, sticky ],
  )

  if(error) {

    if(error.message === 'No connection') {
      return { offline: true }
    }

    if(window.navigator.onLine) {
      Sentry.captureException(
        error,
      )
    }

    // if(dataOnError !== undefined) {
    //   console.error(error)
    //   dataToReturn = { [dataSetName]: dataOnError, error }
    // } else {
    //   throw new Error(`GraphQL ${dataSetName} query error: ${error.message}`)
    // }

    console.error(error)
    dataToReturn = { [dataSetName]: dataOnError, error }

  }

  return {
    ...otherParams,
    refetch,
    ...dataToReturn,
  }

}

export default useDataQuery