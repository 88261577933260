import { memo } from 'react'

import StickyNote from './StickyNote'
import Dot from './Dot'

const DotNotes = ({
  moduleDots,
  goSetPopperInfo,
  ...otherProps
}) => {

  return (
    <>

      {moduleDots.map(moduleDot => {
        const Component = moduleDot.positionInfo.isStickyNote ? StickyNote : Dot
        return (
          <Component
            key={moduleDot.id || `new`}
            moduleDot={moduleDot}
            {...otherProps}
          />
        )
      })}

    </>
  )
}

export default memo(DotNotes)