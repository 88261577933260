import { memo, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { cloneObj, equalObjs, getLocalStorage, getPrimaryName } from '../../../utils/misc'

const Container = styled.div`
  margin: -10px 0 0;
`

const Heading = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin: 0 0 5px;
`

const AreYouSure = styled.div`
  margin: 20px 0 5px;
  font-style: italic;
`

const Section = styled.div`
  margin: 0 0 10px;
`

const UnsavedSection = styled.div`
  margin: 0 0 10px;
  border: 1px solid red;
  background-color: #FF000044;
  border-radius: 4px;
  padding: 10px;
`

const Item = styled.div`
`

const Warning = styled.div`
  font-weight: 500;
  font-style: italic;
  margin-bottom: 10px;
`

const New = styled.span`
  display: inline-block;
  background-color: green;
  color: white;
  border-radius: 10px;
  margin: -5px 0 -1px 5px;
  padding: 0 5px;
  line-height: 14px;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  vertical-align: middle;
`

const Deleted = styled(New)`
  background-color: red;
`

const getPlacesWithoutEvents = places => cloneObj(places).map(place => {
  delete place.events
  return place
})

const getEventsFromPlaces = places => places.map(({ events }) => events).flat()

const getModified = ({ draftItems, publishedItems }) => {
  const modifiedItems = draftItems.filter(item => {
    const publishedItem = publishedItems.find(({ id }) => id === item.id)
    if(equalObjs(item, publishedItem)) {
      return false
    } else if(!publishedItem) {
      item.new = true
      return true
    } else {
      return true
    }
  })
  publishedItems.forEach(item => {
    if(!draftItems.find(({ id }) => id === item.id)) {
      modifiedItems.push({ ...item, deleted: true })
    }
  })
  return modifiedItems
}

const PublishMapConfirmInfo = ({
  layer,
  getMapLayerState,
  getMapLayerId,
 }) => {

  const draftMapLayer = getMapLayerState({ layer, stage: `DRAFT` })[0]
  const publishedMapLayer = getMapLayerState({ layer, stage: `PUBLISHED` })[0]

  const {
    modifiedPlaces,
    modifiedEvents,
    modifiedJourneys,
    modifiedPersons,
  } = useMemo(
    () => {

      const draftData = cloneObj(draftMapLayer.data)
      const publishedData = cloneObj(publishedMapLayer.data)

      const modifiedPlaces = getModified({
        draftItems: getPlacesWithoutEvents(draftData.places),
        publishedItems: getPlacesWithoutEvents(publishedData.places),
      })

      const modifiedEvents = getModified({
        draftItems: getEventsFromPlaces(draftData.places),
        publishedItems: getEventsFromPlaces(publishedData.places),
      })

      const modifiedJourneys = getModified({
        draftItems: draftData.journeys,
        publishedItems: publishedData.journeys,
      })

      const modifiedPersons = getModified({
        draftItems: draftData.persons,
        publishedItems: publishedData.persons,
      })

      return {
        modifiedPlaces,
        modifiedEvents,
        modifiedJourneys,
        modifiedPersons,    
      }

    },
    [ draftMapLayer, publishedMapLayer ],
  )


  const editedWithoutSave = useMemo(
    () => {
      const edited = []
      const mapLayerId = getMapLayerId({ layer, stage: `DRAFT` })
      for(let key in localStorage) {
        const prefix = `initialValue:useStickyRefState:${mapLayerId} `
        if(key.indexOf(prefix) === 0) {
          const [ type, id ] = key.slice(prefix.length).split(`:`)
          const items = (
            type === `event`
              ? draftMapLayer.data.places.map(place => place.events).flat()
              : draftMapLayer.data[`${type}s`]
          )
          const nameOfEdited = getPrimaryName(items.find(item => item.id === id))
          if(nameOfEdited) {
            edited.push(`${nameOfEdited} (${type})`)
          } else {
            const newItem = getLocalStorage(key, {})
            const nameOfNew = getPrimaryName(newItem)
            if(nameOfNew) {
              edited.push(`${nameOfNew} (new ${type})`)
            }
          }
        }
      }
      return edited
    },
    [ getMapLayerId, layer, draftMapLayer ],
  )

  return (
    <Container>

      {modifiedPlaces.length > 0 &&
        <Section>
          <Heading>
            {i18n("Modified Places")}
          </Heading>
          {modifiedPlaces.map(place => (
            <Item key={place.id}>
              {getPrimaryName(place)}
              {place.new && <New>{i18n("New")}</New>}
              {place.deleted && <Deleted>{i18n("Deleted")}</Deleted>}
            </Item>
          ))}
        </Section>
      }

      {modifiedEvents.length > 0 &&
        <Section>
          <Heading>
            {i18n("Modified Events")}
          </Heading>
          {modifiedEvents.map(event => (
            <Item key={event.id}>
              {getPrimaryName(event)}
              {event.new && <New>{i18n("New")}</New>}
              {event.deleted && <Deleted>{i18n("Deleted")}</Deleted>}
            </Item>
          ))}
        </Section>
      }

      {modifiedJourneys.length > 0 &&
        <Section>
          <Heading>
            {i18n("Modified Journeys")}
          </Heading>
          {modifiedJourneys.map(journey => (
            <Item key={journey.id}>
              {getPrimaryName(journey)}
              {journey.new && <New>{i18n("New")}</New>}
              {journey.deleted && <Deleted>{i18n("Deleted")}</Deleted>}
            </Item>
          ))}
        </Section>
      }

      {modifiedPersons.length > 0 &&
        <Section>
          <Heading>
            {i18n("Modified People")}
          </Heading>
          {modifiedPersons.map(person => (
            <Item key={person.id}>
              {getPrimaryName(person)}
              {person.new && <New>{i18n("New")}</New>}
              {person.deleted && <Deleted>{i18n("Deleted")}</Deleted>}
            </Item>
          ))}
        </Section>
      }

      {editedWithoutSave.length > 0 &&
        <UnsavedSection>

          <Heading>
            {i18n("Unsaved Items")}
          </Heading>

          <Warning>
            {i18n("Warning: These changes have not been saved and will not be published.")}
          </Warning>

          {editedWithoutSave.map((name, idx) => (
            <Item key={idx}>
              {name}
            </Item>
          ))}

        </UnsavedSection>
      }

      <AreYouSure>
        {i18n("Are you sure you want to publish the draft?")}
      </AreYouSure>

    </Container>
  )
}

export default memo(PublishMapConfirmInfo)