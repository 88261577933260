import { memo, useCallback, useMemo } from 'react'
// import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'

import { getPrimaryDate, getPrimaryName, isIOSOrAndroid, timeBetweenMapDates } from '../../../utils/misc'
import useAppSize from '../../../hooks/useAppSize'
import useStickyRefState from '../../../hooks/useStickyRefState'

import JourneyArrow from './JourneyArrow'

const Container = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 15px;
  left: 15px;
  display: flex;
  flex-direction: column;
  padding: 6px 0;

  ${isIOSOrAndroid ? `` : `
    background-color: rgb(255 255 255 / .4);
    backdrop-filter: blur(3px);
  `}

  ${!isIOSOrAndroid ? `` : `
    background-color: rgb(255 255 255 / .75);
  `}

`

const Journey = styled.div`
  position: relative;
  color: black;
  padding: 0 10px 0 50px;
  transition: opacity .15s ease-in-out;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      opacity: .7;
    }
  }
`

const Measure = styled.div`
  margin: 1px 10px 5px;
  border-bottom: 1px solid black;
  font-size: 12px;
  transition: width .25s ease-in-out;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
`

const Num = styled.span`
  font-weight: normal;
`

const BibleMapKey = ({
  showDistance,
  showJourneyKey,
  journeys,
  eventsByJourneyId,
  colorByJourneyId,
  mapViewInfo,
  updateMapTimelineForItem,
}) => {

  const { width } = useAppSize()
  const [ showKm, setShowKm ] = useStickyRefState({ id: `BibleMapKey:showKm`, defaultValue: false })
  const toggleShowKm = useCallback(() => setShowKm(!showKm), [ showKm, setShowKm ])

  const orderedJourneys = useMemo(
    () => {
      const orderedJourneys = [ ...journeys ]
      orderedJourneys.sort((a,b) => (
        timeBetweenMapDates(
          getPrimaryDate(eventsByJourneyId[a.id][0]),
          getPrimaryDate(eventsByJourneyId[b.id][0])
        ).totalDays > 0
          ? -1
          : 1
      ))
      return orderedJourneys
    },
    [ journeys, eventsByJourneyId ],
  )

  const getPlaceInfo = useCallback(
    ({ place }) => ({
      top: 10,
      left: place === `b` ? 55 : 0,
    }),
    [],
  )

  const pixelsPerMile = 1 / (((mapViewInfo.baseMapWidth / mapViewInfo.scale) * 2.25) / width)
  const pixelsPerKm = pixelsPerMile / 1.609
  const pixelsPer = showKm ? pixelsPerKm : pixelsPerMile
  const number = parseInt(200 / pixelsPer, 10)
  const num = <Num>{number}</Num>

  return (
    <Container
      className="BibleMapKey-Container"
    >

      {showJourneyKey && orderedJourneys.map(journey => (
        <Journey
          key={journey.id}
          onClick={() => updateMapTimelineForItem({ journeyId: journey.id })}
        >
          <JourneyArrow
            className="BibleMapKey-JourneyArrow"
            placeA="a"
            placeB="b"
            getPlaceInfo={getPlaceInfo}
            color={colorByJourneyId[journey.id]}
            scale={100}
          />
          {getPrimaryName(journey)}
        </Journey>
      ))}

      {showDistance &&
        <Measure
          style={{ width: pixelsPer * number }}
          onClick={toggleShowKm}
        >
          {!showKm && i18nReact("{{num}} mi", { num })}
          {showKm && i18nReact("{{num}} km", { num })}
        </Measure>
      }

    </Container>
  )
}

export default memo(BibleMapKey)