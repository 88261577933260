import { memo, useContext, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Divider from '@material-ui/core/Divider'
import WebIcon from '@material-ui/icons/Web'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { db } from '../../../utils/database'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import useAppSize from '../../../hooks/useAppSize'
import useStickyRefState from '../../../hooks/useStickyRefState'
import { shouldRecommendInstall } from '../../../utils/misc'

import SettingsListHeader from "./SettingsListHeader"

import { DarkModeContext } from '../../../context/LocalInfo'
import CustomSwitch from '../../common/CustomSwitch'
import CustomCheckbox from '../../common/CustomCheckbox'

const ListContainer = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  margin-right: -1px;
`

const StyledWebIcon = styled(WebIcon)`
  height: 26px;
  width: auto;
  margin: 0 10px 0 1px;
`

const Options = styled.div`
  min-height: 0;
  flex: 1;
  overflow: auto;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
  }
`

const SettingsUI = ({
  expandLeftPanel,
}) => {

  const darkMode = useContext(DarkModeContext)
  const [ preferredDarkMode, setPreferredDarkMode ] = useState(() => window.matchMedia('(prefers-color-scheme: dark)').matches ? `ON` : `OFF`)
  const effectiveDarkMode = darkMode === `AUTO` ? preferredDarkMode : darkMode

  const onChangeDarkMode = useCallback(({ target: { value } }) => db.localInfo.put({ id: 'darkMode', value }), [])
  const toggleAutomatic = useCallback(() => db.localInfo.put({ id: 'darkMode', value: (darkMode === `AUTO` ? effectiveDarkMode : `AUTO`) }), [ darkMode, effectiveDarkMode ])

  const { mobileSize } = useAppSize()
  const [ hideInstallPrompt, setHideInstallPrompt ] = useStickyRefState({ id: 'SettingsUI:hideInstallPrompt', defaultValue: false })
  const toggleHideInstallPrompt = useInstanceValuesCallback(() => setHideInstallPrompt(!hideInstallPrompt))

  useEffect(
    () => {

      const onChange = event => setPreferredDarkMode(event.matches ? `ON` : `OFF`)
      const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')
      mediaQueryList.addEventListener('change', onChange)

      return () => mediaQueryList.removeEventListener('change', onChange)

    },
    [],
  )

  return (
    <>

      <ListContainer>

        <SettingsListHeader
          icon={<StyledWebIcon />}
          expandLeftPanel={expandLeftPanel}
        >
          {i18n("User Interface Settings")}
        </SettingsListHeader>

        <Divider />

        <Options>

          <Line>

            <TextField
              select
              variant="outlined"
              label={i18n("Appearance")}
              value={effectiveDarkMode}
              onChange={onChangeDarkMode}
              disabled={darkMode === `AUTO`}
            >
              <MenuItem value="OFF">
                {i18n("Light Mode")}
              </MenuItem>
              <MenuItem value="ON">
                {i18n("Dark Mode")}
              </MenuItem>
            </TextField>

            <CustomSwitch
              checked={darkMode === `AUTO`}
              onChange={toggleAutomatic}
              label={i18n("Automatically detect system value")}
            />

          </Line>

          {shouldRecommendInstall && !mobileSize &&
            <Line>

              <CustomCheckbox
                checked={hideInstallPrompt}
                onChange={toggleHideInstallPrompt}
                label={i18n("Hide prompt to install The Biblearc Study Bible")}
              />

            </Line>
          }

        </Options>


      </ListContainer>

    </>
  )
}

export default memo(SettingsUI)