import React, { memo, useCallback } from 'react'
import IconButton from '@material-ui/core/IconButton'
import LaunchIcon from '@material-ui/icons/Launch'
import styled from 'styled-components'

import useOpenPassage from '../../hooks/useOpenPassage'

const ReadIconButton = styled(IconButton)`
  color: white;
  margin: 0 7px 0 4px;
  padding: 8px;
  background-color: ${({ theme }) => theme.palette.grey[800]}cc;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[700]}88;
  }

  .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
  }
`

const PassagePopperGoRead = ({
  refs,
  versionId,
  closePopper,
  closeAndClearSearch,
  specialMarkup,
  ...otherProps
}) => {

  const { openPassage } = useOpenPassage()

  const goRead = useCallback(
    () => {
      closePopper && closePopper()
      closeAndClearSearch && closeAndClearSearch()
      openPassage({
        refs: refs.slice(0,1),
        versionId,
        specialMarkup,
      })
    },
    [ closePopper, closeAndClearSearch, openPassage, refs, versionId, specialMarkup ],
  )

  return (
    <ReadIconButton
      onClick={goRead}
      {...otherProps}
    >
      <LaunchIcon />
    </ReadIconButton>
  )
}

export default memo(PassagePopperGoRead)