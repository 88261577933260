import { memo } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  background-color: ${({ $color, theme }) => (theme.palette.markupColors[$color] || theme.palette.markupColors.GREY).arrow};
  color: white;
`

const StickyNote = ({
  moduleDot,
}) => {

  const top = 300
  const left = 100
  const width = 300
  const height = 400

  return (
    <Container
      $color={moduleDot.color}
      style={{
        top,
        left,
        width,
        height,
      }}
      // onClick=
      draggable
      className="StickyNote-Container"
    >
      {moduleDot.contents}
    </Container>
  )
}

export default memo(StickyNote)