import { useState, useCallback } from "react"

import useSetTimeout from "./useSetTimeout"

const useLogInCallback = () => {

  const [ navigatingToLogin, setNavigatingToLogin ] = useState(false)
  const [ setNavigatingToLoginTimeout ] = useSetTimeout()

  const logIn = useCallback(
    () => {
      setNavigatingToLogin(true)
      window.sessionSyncAuth.logIn()
      setNavigatingToLoginTimeout(() => setNavigatingToLogin(false), 1000*10)
    },
    [ setNavigatingToLogin ,setNavigatingToLoginTimeout ],
  )

  return {
    logIn,
    navigatingToLogin,
  }
}

export default useLogInCallback