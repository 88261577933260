import React, { memo, useRef } from 'react'
import styled from 'styled-components'

import useSetTimeout from '../../hooks/useSetTimeout'
import useRefState from '../../hooks/useRefState'
import useEffectAsync from '../../hooks/useEffectAsync'

const Container = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  display: ${({ $top }) => typeof $top === `number` ? `block` : `none`};
  top: ${({ $top }) => $top}px;
  transition: opacity .2s ease-in-out;
  opacity: ${({ $show }) => $show ? 1 : 0};
`

const PassageStudyBibleNotesPiece = ({
  top,
  delay,
  children,
}) => {

  const [ props, setProps, getProps ] = useRefState({ $top: top, $show: false })  // never start off showing; always fade in
  const [ setDelayTimeout ] = useSetTimeout()
  const containerRef = useRef()

  useEffectAsync(
    () => {

      if(props.$top !== top && props.$show) {
        const goSetProps = () => {
          const pageContainerEl = containerRef.current && containerRef.current.closest(`.LazyLoadPageViewer-PageContainer`)
          if(
            pageContainerEl
            && !pageContainerEl.nextSibling  // i.e. not in animation to next page
          ) {
            setProps({
              ...getProps(),
              $top: top,  // update the top value
            })
          }
        }
        setDelayTimeout(goSetProps, delay)

      } else {

        const goSetProps = () => {
          const pageContainerEl = containerRef.current && containerRef.current.closest(`.LazyLoadPageViewer-PageContainer`)
          if(
            pageContainerEl
            && !pageContainerEl.nextSibling  // i.e. not in animation to next page
          ) {
            setProps({
              $top: top,
              $show: typeof top === `number`,
            })
          }
        }
        setDelayTimeout(goSetProps, delay)

      }

    },
    [ top ],
  )

  return (
    <Container
      ref={containerRef}
      {...props}
    >
      {children}
    </Container>
  )
}

export default memo(PassageStudyBibleNotesPiece)