const x = () => null
export default x

// import { memo, useState, useCallback, useRef } from 'react'
// // import { i18n } from 'inline-i18n'
// import styled from 'styled-components'
// import Dialog from '@material-ui/core/Dialog'
// import IconButton from '@material-ui/core/IconButton'
// import CloseIcon from '@material-ui/icons/Close'

// import useInstanceValue from '../../hooks/useInstanceValue'
// import usePassageRef from '../../hooks/usePassageRef'
// // import LZMA from '../../utils/lzma_worker'

// import CustomSlider from './CustomSlider'
// import SketchModuleVirtuoso from './SketchModuleVirtuoso'
// import SketchModuleFixedHeight from './SketchModuleFixedHeight'

// const StyledDialog = styled(Dialog)`
//   .MuiBackdrop-root {
//     background: transparent;
//   }

//   .MuiPaper-root {
//     width: 100vw;
//     max-width: none;
//     max-height: none;
//     height: 100vh;
//     border-radius: 0;
//     margin: 0;
//     display: flex;
//     align-items: center;
//     background: white;
//     overflow: hidden;
//   }

//   @media print {
//     display: none;
//   }
// `

// const ScrollContainer = styled.div`
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   overflow: auto;
// `

// const OptionsPanel = styled.div`
//   position: absolute;
//   bottom: 0;
//   width: 400px;
//   height: 50px;
//   background-color: rgb(255 255 255/.85);
//   backdrop-filter: blur(3px);
//   display: flex;
//   flex-direction: column;
//   box-shadow: 0 0 30px rgb(0 0 0/.3);
// `

// const StyledCustomSlider = styled(CustomSlider)`
//   position: absolute;
//   top: -7px;
//   left: -2px;
//   right: -2px;
// `

// const CloseButton = styled(IconButton)`
//   position: absolute;
//   top: 1px;
//   right: 1px;
//   padding: 8px;
//   background: rgb(245 245 245/.8);

//   .MuiSvgIcon-root {
//     height: 15px;
//     width: 15px;
//   }
// `

// const Sketch = ({
//   info,
//   close,
// }) => {

//   const {
//     module,
//     closing,
//   } = info || {}

//   const [ mode, setMode ] = useState(`draw`)
//   const [ zoom, setZoom ] = useState(100)
//   const getMode = useInstanceValue(mode)

//   const containerRef = useRef()

//   const { modulePassages=[] } = module || {}
//   const { fromLoc, toLoc, info: modulePassageInfo={} } = modulePassages[0] || {}
//   const { versionId } = modulePassageInfo
//   const passageRef = usePassageRef({
//     fromLoc,
//     toLoc,
//     convertToVersionId: versionId,
//   })

//   const updateZoom = useCallback((event, value) => setZoom(value), [])

//   const onLoad = useCallback(
//     () => {

//     },
//     [],
//   )

//   // useEffectAsync(
//   //   () => {
//   //     let t = Date.now()
//   //     LZMA.compress(svg, 1, (result, error) => {
//   //       // const compressedString = new TextDecoder().decode(new Uint8Array(result))
//   //       // const compressedString = (new Uint8Array(result)).toString()
//   //       const compressedString = String.fromCharCode(...result.map(num => num + 128))
//   //       // console.log('result>>', result, Math.min(...result), Math.max(...result), compressedString.split('').map(char => char.charCodeAt(0) - 128))
//   //       console.log('result', compressedString.length / 1000, compressedString.length / svg.length, Date.now() - t)
//   //       t = Date.now()
        
//   //       // console.log('result--', Array.from(new TextEncoder().encode(compressedString)), result)
//   //       LZMA.decompress(compressedString.split('').map(char => char.charCodeAt(0) - 128), (result2, error) => {
//   //         console.log('result2', result2.slice(0, 100), Date.now() - t)

//   //       })

//   //     })


//   //   },
//   //   [],
//   // )

//   return null
//   if(!module) return null

//   const naturalWidth = 800
//   const scale = zoom / 100

//   const SketchModule = [ `OUTLINE` ].includes(module.type) ? SketchModuleVirtuoso : SketchModuleFixedHeight

//   // I AM HERE: 
//     // get slider to continue to follow even when the mouse goes outside the UI element
//     // Get the component structure in place for a fixed "natural width" + zooming and layers
//       // Heading for both fixed and virtuoso
//       // tie overlay to scrolling
//     // Get the options panel working
//       // draw/pointer toggle
//         // [draw]:
//           // utensil
//           // color
//           // selector
//             // [cycle through in reverse order]
//             // ability to change utensil or color
//             // ability to move (with minimum grab area)
//             // ability to delete (trash can shows at bottom-right of doodle)
//             // tap off to unselect
//           // undo/redo
//         // pointer
//           // fade layers toggle
//           // layer toggles
//           // new layer button

//       // DATA
//         // module
//           // moduleMarkup
//             // container = sketchLayer:${uid}
//             // positionInfo
//               // ...
//         // passage
//           // highlight
//             // `sketch` column
//               // naturalWidth - set based on current width in module when created (min: 300; max: 1200)
//               // color
//               // utensil
//               // fade on
//               // layers (probably will only allow a single layer in the UI)
//                 // show (boolean)
//                 // lines - about 1k per line (after compression); max them out at 1mb for a module
//                   // svg (compressed)

//   return (
//     <StyledDialog
//       onClose={close}
//       open={!!info && !closing}
//     >

//       <ScrollContainer ref={containerRef}>
//         <SketchModule
//           module={module}
//           naturalWidth={naturalWidth}
//           scale={scale}
//           containerRef={containerRef}
//           noneditableViewingMode="sketch"
//           // onLoad={onLoad}
//           // sharedPrintDialogInfo={printDialogInfo}
//           // clearSharedPrintDialogInfo={clearSharedPrintDialogInfo}
//           // goPrintOrDownload={goPrintOrDownloadWithBaseParams}
//         />
//       </ScrollContainer>

//       <OptionsPanel>
//         <StyledCustomSlider
//           min={30}
//           max={400}
//           color="secondary"
//           value={zoom}
//           onChange={updateZoom}
//         />
//       </OptionsPanel>

//       <CloseButton
//         onClick={close}
//       >
//         <CloseIcon />
//       </CloseButton>

//     </StyledDialog>
//   )

// }

// export default memo(Sketch)