import { useMemo } from 'react'

import useGoUpdateModulePassages from './useGoUpdateModulePassages'

const useGoUpdateModulePassage = ({
  modulePassage,
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  moduleId = moduleId || modulePassage.moduleId

  const [ goUpdate, goDelete ] = useGoUpdateModulePassages({
    projectId,
    moduleId,
    undoRedoStack,
  })

  const toReturn = useMemo(
    () => ([
      (updateObj={}, options) => (
        goUpdate(
          [ updateObj ],
          modulePassage && [ modulePassage ],
          options,
        )[0]
      ),
      ...(modulePassage ? [ () => goDelete([ modulePassage ]) ] : []),
    ]),
    [ modulePassage, goUpdate, goDelete ],
  )

  return toReturn
}

export default useGoUpdateModulePassage