import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router-dom"

import { getEmbedMode, goSave } from '../../../graphql/links/embedLink'
import useEffectAsync from '../../../hooks/useEffectAsync'

import SearchModal from '../../search/SearchModal'
import EmbedSettingsIconButton from './EmbedSettingsIconButton'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmbedSearch = ({
  embedSettings,
  searchInfo,
}) => {

  const { searchText } = searchInfo || {}

  const history = useHistory()

  const setCurrentSearch = useCallback(
    searchText => {
      goSave({
        key: `searchInfo`,
        newValues: {
          searchText,
        },
      })
    },
    [],
  )

  useEffectAsync(
    () => {
      if(searchText) {
        history.replace({
          hash: `#search=${encodeURIComponent(searchText)}`,
          state: {
            executeSearch: true,
          },
        })
      }
    },
    [ searchText, history ],
  )

  return (
    <Container>
      <SearchModal
        setCurrentSearch={setCurrentSearch}
        searchBarOpen
        isEmbedSearch
      />
      {getEmbedMode() === `prep` &&
        <EmbedSettingsIconButton
          embedSettings={embedSettings}
        />
      }
    </Container>
  )

}

export default memo(EmbedSearch)