import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'

import { isIOS, isAndroid } from '../../../utils/misc'

import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Container = styled.div`
  padding: 5px 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.3;
`

const WhereToStart = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 3px;
  opacity: .9;
  display: flex;
  align-items: center;
  text-align: center;
`

const WhereToStartLines = styled.div`
  width: 25px;
  height: 1px;
  margin: 0 5px;
  background-image: linear-gradient(to right, rgb(0 0 0/.3) 0%, transparent 100%);

  &:first-child {
    transform: rotate(180deg);
  }

`

const DescriptionLine1 = styled.div`
  text-align: center;
  font-weight: 300;
  margin-top: 4px;
  position: relative;
`

const DescriptionLine2 = styled.div`
  text-align: center;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: -10px;
  right: -65px;

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`

const GettingStartedIconButton = styled(StyledIconButton)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const ToolsIconButton = styled(StyledIconButton)`
  color: ${({ theme }) => theme.palette.secondary.main};
`

const EquipIconButton = styled(StyledIconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
`

const PlanImage = styled.img`
  height: 33px;
  margin-top: 20px;
`

const GetTheAppContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 10px 0 0;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: rgb(0 0 0/.07);
  transition: background-color .15s ease-in-out;

  @media (hover: hover) {
    &:hover {
      background-color: rgb(0 0 0/.12);
      cursor: pointer;
    }
  }
`

const GetTheApp = styled.div`
`

const IOSPreviewImage = styled.img`
  width: 20px;
  height: 20px;
`

const HomeWhereToStart = () => {

  return (
    <Container>

      <WhereToStart>
        <WhereToStartLines />
        {i18n("Getting Started")}
        <WhereToStartLines />
      </WhereToStart>

      <PlanImage src="/the_biblearc_study_bible_1.svg"/>

      <DescriptionLine1>
        {i18n("Read and search the Bible")}
        <NavLinkOrAWithDisable to="/about-the-biblearc-study-bible" exact>
          <GettingStartedIconButton>
            <InfoIcon />
          </GettingStartedIconButton>
        </NavLinkOrAWithDisable>
      </DescriptionLine1>
      <DescriptionLine2>
        {i18n("with maps and study notes")}
      </DescriptionLine2>

      {!isIOS && !isAndroid &&
        <NavLinkOrAWithDisable to="/about-the-biblearc-study-bible" exact>
          <GetTheAppContainer>
            <IOSPreviewImage
              src={`/favicon_192.png`}
              className="dark-mode-exempt"
            />
            <GetTheApp>
              {i18n("Get the app!")}
            </GetTheApp>
          </GetTheAppContainer>
        </NavLinkOrAWithDisable>
      }

      <PlanImage src="/biblearc_tools_1.svg"/>

      <DescriptionLine1>
        {i18n("“Visual meditations” using")}
        <NavLinkOrAWithDisable to="/about-tools" exact>
          <ToolsIconButton>
            <InfoIcon />
          </ToolsIconButton>
        </NavLinkOrAWithDisable>
      </DescriptionLine1>
      <DescriptionLine2>
        {i18n("graphical Bible study methods")}
      </DescriptionLine2>

      <PlanImage src="/biblearc_equip_1.svg"/>

      <DescriptionLine1>
        {i18n("The place for learning")}
        <NavLinkOrAWithDisable to="https://equip.biblearc.com">
          <EquipIconButton>
            <InfoIcon />
          </EquipIconButton>
        </NavLinkOrAWithDisable>
      </DescriptionLine1>
      <DescriptionLine2>
        {i18n("deeper study of Scripture")}
      </DescriptionLine2>

      {/* Brief description of BSB, MY, TOOLS, and EQUIP
        - BSB
          - Get help from others' study
          - link to /about-the-biblearc-study-bible-subscription
          - tell them to look left!
        - MY
          - Personalize, highlight, and add notes
          - link to /about-my-biblearc-study-bible-subscription
        - TOOLS
          - Visual meditations using graphical Bible study methods (do your own!)
          - link to /about-tools-subscription
        - EQUIP
          - The place for learning deeper study of Scripture
          - link to equip.biblearc.com */}

      {/* - subscribe button */}

    </Container>
  )
}

export default memo(HomeWhereToStart)