import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

const StyledIconButton = styled(IconButton)`
  user-select: none;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  min-width: 0;
  width: 34px;
  height: 34px;
  border: 2px solid white;
  color: rgb(0 0 0/.55);

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.faded};
  }

  .MuiSvgIcon-root {
    width: 18px;
    transition: transform .3s ease-in-out;
  }
`

const AddingNewContainer = styled.div`
  position: absolute;
  z-index: 6;
  top: 12px;
  right: 142px;
  width: 0;
  display: flex;
  align-items: center;

  ${({ $addingNew }) => !$addingNew ? `` : `

    .DotNotesButton-Info {
      min-width: 138px;
    }

    .MuiSvgIcon-root {
      transform: rotate(45deg);
    }

  `}
`

const Info = styled.div`
  font-size: 12px;
  line-height: 10px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.palette.grey[700]};
  color: white;
  min-width: 0;
  transition: min-width 0.3s ease-in-out;
  border-radius: 50px;
  margin-right: -34px;
  padding-right: 34px;
`

const Heading = styled.div`
  padding-left: 13px;
  font-weight: bold;
`

const Instructions = styled.div`
  padding-left: 13px;
  font-size: 10px;
  opacity: 0.75;
`

const DotNotesButton = ({
  onClick,
  addingNew,
  onClose,
}) => {

  return (
    <AddingNewContainer
      $addingNew={addingNew}
    >

      <Info className="DotNotesButton-Info">
        <Heading>
          {i18n("Add a Dot Note")}
        </Heading>
        <Instructions>
          {i18n("Click on your work.")}
        </Instructions>
      </Info>

      <Tooltip
        title={addingNew ? i18n("Cancel") : i18n("Add a “dot note”")}
      >
        <StyledIconButton
          onClick={addingNew ? onClose : onClick}
          color="primary"
          tabIndex={-1}
        >
          <AddIcon />
        </StyledIconButton>
      </Tooltip>

    </AddingNewContainer>
  )

}

export default memo(DotNotesButton)