import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import { getPrimaryName } from '../../../utils/misc'

const Dot = styled.div`
  position: absolute;
  z-index: 2;
  margin-left: -7px;
  margin-top: -7px;
  border-radius: 100px;
  background: ${({ $selected, $stage, theme }) => ($selected && $stage !== `PUBLISHED`) ? theme.palette.mapColors.selectedPlace : `transparent`};
  color: ${({ $selected, $stage, theme }) => ($selected && $stage === `PUBLISHED`) ? theme.palette.mapColors.selectedPlace : theme.palette.mapColors.river};
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
`

const Label = styled.div`
  position: absolute;
  white-space: nowrap;
  line-height: 16px;
  font-size: 10.5px;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: bold;
  opacity: .8;
  transform: rotate(${({ $degrees }) => $degrees}deg);
`

const BibleMapPlaceRiver = ({
  place,
  selectThisPlace,
  getStyle,
  missingKeyData,
  ...otherProps
}) => {

  const style = getStyle(place)

  return (
    <Dot
      onClick={selectThisPlace}
      style={style}
      {...otherProps}
    >
      <Label
        $degrees={place.angle || 0}
      >
        {getPrimaryName(place)}
      </Label>
    </Dot>
  )
}

export default memo(BibleMapPlaceRiver)