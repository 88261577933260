import { gql } from '@apollo/client'

import studyBibleItem_fields from '../fragments/studyBibleItem_fields'

export default gql
`
  mutation removeReactionFromStudyBibleItem($studyBibleItemId: ID!, $type: String!) {
    removeReactionFromStudyBibleItem(studyBibleItemId: $studyBibleItemId, type: $type) {
      ${studyBibleItem_fields}
    }
  }
`