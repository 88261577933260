import { useMemo } from "react"

export const normalizeEditorContent = content => {

  try {

    const jsonContent = JSON.parse(content)

    if(!jsonContent.blocks || !jsonContent.entityMap) {
      throw new Error(`invalid-content`)
    }

    // get rid of any erroneous atomic blocks, fix others
    jsonContent.blocks = jsonContent.blocks.filter(block => {
      const { type, entityRanges } = block
      if(type !== `atomic`) return true
      if(!jsonContent.entityMap[(entityRanges[0] || {}).key]) return false
      entityRanges[0].offset = 0
      return true
    })

    return JSON.stringify(jsonContent)

  } catch(e) {
    if(e.message === `invalid-content`) {
      console.error(`Invalid editor content: ${content}`)
      throw new Error(`Invalid editor content.`)
    }
  }

  return content

}

const useFlipEditorContent = ({ modulePiece }) => {

  const normalizedContent = useMemo(
    () => {

      const { content="" } = modulePiece || {}
      return normalizeEditorContent(content)

    },
    [ modulePiece ],
  )

  return normalizedContent
}

export default useFlipEditorContent
