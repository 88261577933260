const keyDownArrowLeft = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    gotoPreviousChapter,
  } = params

  if(!shiftKey && !modifierKey) {
    event.preventDefault()
    gotoPreviousChapter()
  }

}

export default keyDownArrowLeft