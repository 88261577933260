import { i18n } from 'inline-i18n'

const keyDownP = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    printOrDownloadDialogOpen,
    setSnackbarInfo,
  } = params

  if(modifierKey && !shiftKey) {
    event.preventDefault()

    const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
    const snackbarInfo = {
      open: true,
      message: (
        printOrDownloadDialogOpen
          ? i18n("Click the “Next” button to print.")
          : i18n("To print, click a module or notes title button and select “Print” in the Actions section.")
      ),
      hideButton: true,
      onClose: closeSnackbar,
    }
    setSnackbarInfo(snackbarInfo)

  }

}

export default keyDownP