import { memo, useMemo, useCallback, useState, useRef } from 'react'
import styled from 'styled-components'
import { Virtuoso } from 'react-virtuoso'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { isOriginalLanguageSearch } from "@bibletags/bibletags-ui-helper"
import { useHistory } from "react-router-dom"

import useFormattedSearchValue from '../../hooks/useFormattedSearchValue'

import BibleSearchHeader from './BibleSearchHeader'
import BibleSearchResultsOriginalRow from './BibleSearchResultsOriginalRow'
import BibleSearchResultsTranslationRow from './BibleSearchResultsTranslationRow'
import BibleSearchResultsBookBreakdown from './BibleSearchResultsBookBreakdown'
import BibleSearchOtherSuggestedQueries from './BibleSearchOtherSuggestedQueries'
import SearchResultsError from './SearchResultsError'
import SearchResultsErrorMessage from './SearchResultsErrorMessage'

const Container = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`

const Results = styled.div`
  flex: 1;
  position: relative;
`

const HeaderSpacer = styled.div`
  height: 62px;
`

const Suggestion = styled.span`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  padding: 0 4px;
  margin: 1px;
  border-radius: 3px;
  transition: border-color .15s ease-in-out;

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`

const BibleSearchResults = ({
  searchText,
  includeVersionIds,
  inVersionAbbrs,
  inVersionIds,
  goSetPopperInfo,
  closeAndClearSearch,
  disabled,
  error,

  // results:
  rowCountByBookId,
  hitsByBookId,
  otherSuggestedQueries,
}) => {

  const history = useHistory()

  const isOrigLanguageSearch = isOriginalLanguageSearch(searchText)

  const [ visibleRange, setVisibleRange ] = useState({
    startIndex: 0,
    endIndex: 0,
  })

  const [ placeholderHeight, setPlaceholderHeight ] = useState(80)

  const virtuosoRef = useRef()

  const Row = isOrigLanguageSearch ? BibleSearchResultsOriginalRow : BibleSearchResultsTranslationRow

  const totalRows = useMemo(
    () => (rowCountByBookId || []).reduce((total, count) => total + count, 0),
    [ rowCountByBookId ],
  )

  const getBookIdFromIndex = useCallback(
    index => {
      let total = 0
      let bookId
      for(bookId=1; bookId<rowCountByBookId.length; bookId++) {
        total += rowCountByBookId[bookId]
        if(index < total) break
      }
      return bookId
    },
    [ rowCountByBookId ],
  )

  const searchTextToSuggest = (
    (error || {}).message === `Search exceeds maximum complexity`
    && inVersionAbbrs.length > 1
    && !/ in:[A-Z0-9]{2,9}(?:[, ]|$)/.test(searchText)
    && `${searchText} in:${inVersionAbbrs[0]}`
  )

  const formattedSearchTextToSuggest = useFormattedSearchValue({ value: searchTextToSuggest })

  const goSearchSuggestion = useCallback(
    () => {
      history.replace({
        hash: `#search=${encodeURIComponent(searchTextToSuggest)}`,
        state: {
          executeSearch: true,
        },
      })
    },
    [ history, searchTextToSuggest ],
  )

  if(totalRows <= 0) {

    return (
      <SearchResultsError>
        {
          totalRows === -1
            ? i18n("Invalid Bible search")
            : i18n("No Bible results")
        }
        {!!(error || {}).message &&
          <SearchResultsErrorMessage>
            {error.message}
            {searchTextToSuggest &&
              <div>
                {i18nReact("Try searching {{suggestion}}", {
                  suggestion: (
                    <Suggestion onClick={goSearchSuggestion}>
                      {formattedSearchTextToSuggest}
                    </Suggestion>
                  ),
                })}
              </div>
            }
          </SearchResultsErrorMessage>
        }
      </SearchResultsError>
    )
  }

  return (
    <Container>
    
      <Results>

        <BibleSearchHeader
          searchText={searchText}
          includeVersionIds={includeVersionIds}
          inVersionAbbrs={inVersionAbbrs}
          inVersionIds={inVersionIds}
          totalRows={totalRows}
          hitsByBookId={hitsByBookId}
          disabled={disabled}
        />

        <Virtuoso
          totalCount={totalRows}
          itemContent={index => (
            <Row
              key={index}
              searchText={searchText}
              index={index}
              bookId={getBookIdFromIndex(index)}
              placeholderHeight={placeholderHeight}
              setPlaceholderHeight={setPlaceholderHeight}
              goSetPopperInfo={goSetPopperInfo}
              closeAndClearSearch={closeAndClearSearch}
            />
          )}
          rangeChanged={setVisibleRange}
          overscan={{
            main: 0,
            reverse: 0,
          }}
          components={{
            Header: () => <HeaderSpacer />,
            Footer: () => (
              <BibleSearchOtherSuggestedQueries
                otherSuggestedQueries={otherSuggestedQueries}
              />
            ),
          }}
          ref={virtuosoRef}
          tabIndex={-1}
        />

      </Results>

      <BibleSearchResultsBookBreakdown
        rowCountByBookId={rowCountByBookId}
        hitsByBookId={hitsByBookId || rowCountByBookId}
        hebrewOrdering={isOrigLanguageSearch}
        visibleRange={visibleRange}
        virtuosoRef={virtuosoRef}
        totalRows={totalRows}
      />

    </Container>
  )
}

export default memo(BibleSearchResults)