import { memo, useCallback, useState, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import useModalAnchor from '../../../hooks/useModalAnchor'
import useFormattingKeys from '../../../hooks/useFormattingKeys'
import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import systemFormattingKeys from '../../../utils/systemFormattingKeys'
import { cloneObj } from '../../../utils/misc'

import InfoDialog from '../../common/InfoDialog'
import LensSettingsLensList from './LensSettingsLensList'
import MenuDivider from '../../common/MenuDivider'
import EditLensDialog from './EditLensDialog'

const MAX_LENS_NAME_LENGTH_FOR_MENU = 25

const StyledInfoDialog = styled(InfoDialog)`
  .MuiDialog-paper {
    width: 450px;
    max-width: calc(100vw - 30px);
    margin: 15px;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 8px;
  left: 8px;
`

const CreateMenuItem = styled(MenuItem)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
`

const System = styled.div`
  display: inline-block;
  border-radius: 3px;
  font-size: 10px;
  line-height: 1.4;
  font-weight: 400;
  padding: 0 4px;
  margin-left: 6px;
  background: ${({ theme }) => theme.palette.grey[700]};
  color: white;
`

const Mine = styled(System)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
`

const TitleLine = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  flex: 1;
`

const StyledIconButton = styled(IconButton)`
  padding: 9px;
  margin: -10px -10px -10px 5px;
  
  .MuiSvgIcon-root {
    font-size: 22px;
  }
`

const LensSettingsDialog = ({
  open,
  onDone,
  filteredFormattingKeyInfos,
  formattingKeyInfosSetting,
  projectId,
}) => {

  const { formattingKeys } = useFormattingKeys({ type: `MARKUP` })
  const [ formattingKeyIdInEdit, setFormattingKeyIdInEdit ] = useState()
  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const { anchorEl: anchorEl2, openModal: openModal2, closeModal: closeModal2 } = useModalAnchor()

  const [ goSetFormattingKeyInfos ] = useGoSetModuleSetting({
    moduleSetting: formattingKeyInfosSetting,
    projectId,
  })

  const formattingKeyInfoIds = useMemo(() => filteredFormattingKeyInfos.map(({ id }) => id), [ filteredFormattingKeyInfos ])
  const unusedFormattingKeys = formattingKeys.filter(({ id }) => !formattingKeyInfoIds.includes(id))

  const addLens = useCallback(
    ({ target }) => {
      const id = target.closest(`[data-id]`).getAttribute('data-id')
      goSetFormattingKeyInfos({
        value: [
          ...cloneObj(formattingKeyInfosSetting.value),
          {
            id,
            colors: [],
          },
        ],
      })
    },
    [ formattingKeyInfosSetting.value, goSetFormattingKeyInfos ],
  )

  const updateToSystemLenses = useCallback(
    () => {
      const newFormattingKeyInfos = []
      systemFormattingKeys.forEach(({ id }) => {
        const formattingKeyInfo = filteredFormattingKeyInfos.find(formattingKeyInfo => formattingKeyInfo.id === id)
        newFormattingKeyInfos.push(
          formattingKeyInfo
          || {
            id,
            colors: [],
          }
        )
      })
      goSetFormattingKeyInfos({ value: newFormattingKeyInfos })
      closeModal2()
    },
    [ filteredFormattingKeyInfos, goSetFormattingKeyInfos, closeModal2 ],
  )

  const createNewLens = useCallback(
    () => {
      closeModal()
      setFormattingKeyIdInEdit(`new`)
    },
    [ closeModal, setFormattingKeyIdInEdit ],
  )

  const onCancelEdit = useCallback(() => setFormattingKeyIdInEdit(), [ setFormattingKeyIdInEdit ])

  return (
    <>

      <StyledInfoDialog
        open={open}
        onOkay={onDone}
        okayButtonLabel={i18n("Done")}
        title={
          <TitleLine>
            <Title>
              {i18n("Manage Lenses for This Module", "", "markup")}
            </Title>
            <StyledIconButton onClick={openModal2}>
              <MoreVertIcon />
            </StyledIconButton>
            <Menu
              anchorEl={anchorEl2}
              open={!!anchorEl2}
              onClose={closeModal2}
            >
              <MenuItem
                onClick={updateToSystemLenses}
              >
                {i18n("Use system lenses")}
              </MenuItem>
            </Menu>
          </TitleLine>
        }
        explanation={
          <>

            <LensSettingsLensList
              formattingKeyInfos={formattingKeyInfosSetting.value}
              filteredFormattingKeyInfos={filteredFormattingKeyInfos}
              goSetFormattingKeyInfos={goSetFormattingKeyInfos}
              setFormattingKeyIdInEdit={setFormattingKeyIdInEdit}
            />

            <StyledButton
              color="primary"
              onClick={openModal}
            >
              {i18n("Add Lenses")}
            </StyledButton>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={closeModal}
            >
              {unusedFormattingKeys.map(({ id, name, info }) => {
                const isCustomizedSystemLens = /^system:/.test(info.sourceId || ``)
                const isMine = !isCustomizedSystemLens && !/^system:/.test(id)

                return (
                  <MenuItem
                    key={id}
                    data-id={isCustomizedSystemLens ? info.sourceId : id}
                    onClick={addLens}
                  >
                    {name.length > MAX_LENS_NAME_LENGTH_FOR_MENU ? `${name.slice(0,MAX_LENS_NAME_LENGTH_FOR_MENU-2)}...` : name}
                    {isMine &&
                      <Mine>
                        {i18n("My Lens")}
                      </Mine>
                    }
                    {isCustomizedSystemLens &&
                      <Mine>
                        {i18n("System Lens*")}
                      </Mine>
                    }
                    {!isMine && !isCustomizedSystemLens &&
                      <System>
                        {i18n("System Lens")}
                      </System>
                    }
                  </MenuItem>
                )
              })}
              {unusedFormattingKeys.length > 0 && <MenuDivider />}
              <CreateMenuItem
                onClick={createNewLens}
              >
                {i18n("Create a new lens")}
              </CreateMenuItem>
            </Menu>

          </>
        }
      />

      <EditLensDialog
        onCancel={onCancelEdit}
        formattingKeyInfos={formattingKeyInfosSetting.value}
        goSetFormattingKeyInfos={goSetFormattingKeyInfos}
        formattingKeyId={formattingKeyIdInEdit}
      />

    </>
  )
}

export default memo(LensSettingsDialog)