import { memo } from 'react'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { i18n } from 'inline-i18n'

import useModalAnchor from "../../../hooks/useModalAnchor"

import SearchSuggestionMenuItems from '../../common/SearchSuggestionMenuItems'

const StyledSearchSuggestionMenuItems = styled(SearchSuggestionMenuItems)`
  .SearchSuggestionSearchWord {
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-color: ${({ theme }) => theme.palette.divider};
  }
`

const PassageInNotesWordSelectionSearchMenuItem = ({
  ...otherProps
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  return (
    <>

      <MenuItem
        onClick={openModal}
        tabIndex={-1}
      >
        {i18n("Search...")}
      </MenuItem>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeModal}
        disableAutoFocus
        disableEnforceFocus
        autoFocus={false}
      >
        <StyledSearchSuggestionMenuItems
          {...otherProps}
          onClose={closeModal}
        />
      </Menu>

    </>
  )
}

export default memo(PassageInNotesWordSelectionSearchMenuItem)