import { useState, useContext, useCallback } from 'react'
import { LastApolloResponseContext } from '../context/LastApolloResponse'

import useEffectAsync from './useEffectAsync'

const useDataUpdated = ({
  markUpdated,
}) => {

  const [ dataUpdated, setDataUpdated ] = useState(false)

  const { lastApolloResponse } = useContext(LastApolloResponseContext)

  useEffectAsync(
    () => {
      if(!dataUpdated && lastApolloResponse) {
        setDataUpdated(!!markUpdated(lastApolloResponse))
      }
    },
    [ lastApolloResponse ],
  )

  const resetDataUpdated = useCallback(() => setDataUpdated(false), [])

  return [
    dataUpdated,
    resetDataUpdated,
  ]
}

export default useDataUpdated