import { memo } from 'react'
import styled from 'styled-components'
import ChatIcon from '@material-ui/icons/Chat'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import { i18n } from 'inline-i18n'

import useIsLoggedIn from '../../hooks/useIsLoggedIn'

import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'

const ContactUsButton = styled(Fab)`
  position: fixed;
  bottom: 15px;
  right: 15px;
`

const ContactUsFab = ({
  title=i18n("Need help? Email us."),
  icon=<ChatIcon />,
  to,
  ...otherProps
}) => {

  const isLoggedIn = useIsLoggedIn()

  const autologin = isLoggedIn ? `?autologin` : ``

  return (
    <NavLinkOrAWithDisable
      to={to || `https://equip.biblearc.com/contact${autologin}`}
    >
      <Tooltip title={title}>
        <ContactUsButton color="secondary">
          {icon}
        </ContactUsButton>
      </Tooltip>
    </NavLinkOrAWithDisable>
  )
}

export default memo(ContactUsFab)