import { memo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import { db } from '../../utils/database'
import useEffectAsync from '../../hooks/useEffectAsync'

const StyledButton = styled(Button)`
  color: #bdac59 !important;
`

const ConfirmResetAppDialog = ({
  open,
  onClose,
  goResetServiceWorker,
  ...props
}) => {

  const hasAccessToken = !!window.sessionSyncAuth.getAccessToken()  // cannot utilize useIsLoggedIn here since it is outside the provider scope

  const [ numQueuedMutations, setNumQueuedMutations ] = useState(null)

  const doRefresh = useCallback(() => document.location.reload(), [])

  const doReset = useCallback(
    () => {

      // clear offline storage
      db.delete()
      localStorage.clear()

      // reset the service worker
      goResetServiceWorker && goResetServiceWorker()

      if(window.channelIdInPWA) {
        // navigate to church Bible home
        document.location = `/church/${window.channelIdInPWA}?church_app`
        return
      }

      // navigate to homepage
      document.location = '/'

    },
    [ goResetServiceWorker ],
  )

  useEffectAsync(
    async () => {

      setNumQueuedMutations(null)

      if(open) {
        setNumQueuedMutations(await db.queuedMutations.count())
      }

    },
    [ open ],
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...props}
    >
      <DialogTitle>
        {i18n("Reset App")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>

          {!hasAccessToken && i18n("Resetting your app will clear “My Bible Versions” including those downloaded for offline use.")}

          {hasAccessToken &&
            <>
              {i18n("Resetting your app will clear all offline data, settings, and Bible versions.")}
              {` `}
              {!!numQueuedMutations &&
                i18n("Warning: This will cause you to lose {{number}} outstanding edit(s) made offline.", {
                  number: numQueuedMutations,
                })
              }
              {numQueuedMutations === 0 &&
                i18n("(But don’t worry—all your edits are saved in the cloud.)", {
                  number: numQueuedMutations,
                })
              }
            </>
          }

          {` `}
          {i18n("Try refreshing the app before taking this step.")}

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledButton
          autoFocus
          onClick={doRefresh}
          color="primary"
        >
          {i18n("Refresh")}
        </StyledButton>
        <StyledButton
          onClick={doReset}
          color="primary"
        >
          {i18n("Reset app")}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default memo(ConfirmResetAppDialog)