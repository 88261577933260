import { gql } from '@apollo/client'

import channel_fields from '../fragments/channel_fields'

export default gql
`
  mutation updateChannel($id: ID!, $input: ChannelInput!) {
    updateChannel(id: $id, input: $input) {
      ${channel_fields}
    }
  }
`