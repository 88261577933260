import { memo, useCallback, useMemo } from 'react'
import { i18n } from 'inline-i18n'
// import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import IconButton from '@material-ui/core/IconButton'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import HeadsetIcon from '@material-ui/icons/Headset'
import HistoryIcon from '@material-ui/icons/History'
import CloseIcon from '@material-ui/icons/Close'

import useAccountSetting from '../../hooks/useAccountSetting'
import useSetChannelItemInfo from '../../hooks/useSetChannelItemInfo'
import useVersionInfos from '../../hooks/useVersionInfos'
import useModalAnchor from '../../hooks/useModalAnchor'
import { preventDefaultEvent } from '../../utils/misc'
import useAppSize from '../../hooks/useAppSize'

import OptionsPopover from './OptionsPopover'
import RelativeTime from './RelativeTime'

const FILL_SCREEN_MAX_WIDTH = 499

const Content = styled.div`
  max-height: 500px;
  display: flex;
  flex-direction: column;

  ${({ $isMini }) => !$isMini ? `` : `
    max-height: none;
    height: calc(100vh - 10px);
  `}
`

const StyledOndemandVideoIcon = styled(OndemandVideoIcon)`
  font-size: 21px;
  margin: 2px 9px 1px 3px;
`

const StyledHeadsetIcon = styled(HeadsetIcon)`
  font-size: 23px;
  margin: 1px 8px 0 2px;
`

const StyledHistoryIcon = styled(HistoryIcon)`
  font-size: 23px;
  margin: 1px 8px 0 2px;
`

const ListenHistoryHeader = styled.div`
  background: ${({ theme }) => theme.palette.grey[200]};
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 22px;
`

const HeaderText = styled.div`
  flex: 1;
`

const ItemContent = styled.span`
  flex: 1;
  min-width: 240px;
  font-weight: 300;
  white-space: nowrap;
  display: flex;
  align-items: center;
  max-width: min(calc(100vw - 40px), 300px);

  ${({ $isMini }) => !$isMini ? `` : `
    width: calc(100vw - 10px);
    max-width: none;
  `}
`

const ItemText = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.05;
  padding: 1px 0 1px;
`

const ItemDate = styled.div`
  font-weight: 500;
  font-size: 11px;
  margin: 0 0 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ItemTitle = styled.div`
  margin: 0 0 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledMenuList = styled(MenuList)`
  overflow: auto;
`

const StyledMenuItem = styled(MenuItem)`
  padding: 10px 16px;
  justify-content: stretch;
`

const StyledIconButton = styled(IconButton)`
  padding: 9px;
  
  .MuiSvgIcon-root {
    font-size: 22px;
  }
`

const CloseIconButton = styled(IconButton)`
  padding: 10px;
  margin: -5px -5px -5px 0;
  
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const None = styled.div`
  width: 320px;
  max-width: 100%;
  text-align: center;
  font-weight: 300;
  padding: 30px 0;
`

const MainMenuListeningHistory = ({
  channelIdInPWA,
  toggleDrawerOpen,
  setSnackbarInfo,
}) => {

  const [ listeningHistory, setListeningHistory ] = useAccountSetting(`listening-history`, [])
  const setChannelItemInfo = useSetChannelItemInfo()
  const { defaultVersionId } = useVersionInfos()
  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const { width } = useAppSize()

  const isMini = width <= FILL_SCREEN_MAX_WIDTH

  const filteredListeningHistory = useMemo(
    () => {
      if(!channelIdInPWA) return listeningHistory
      return listeningHistory.filter(({ channelId }) => channelId === channelIdInPWA)
    },
    [ listeningHistory, channelIdInPWA ],
  )

  const onClickListen = useCallback(
    ({ studyBibleItem={}, channelId }={}) => {
      channelId = channelId || filteredListeningHistory[0].channelId
      const studyBibleFollow = {
        id: `none`,
        channelId,
      }
      setChannelItemInfo({
        studyBibleItem: {
          id: filteredListeningHistory[0].id,
          title: filteredListeningHistory[0].title,
          ...studyBibleItem,
          ...(channelId ? { studyBibleFollow } : {}),
          type: `CHANNEL`
        },
        versionId: defaultVersionId,
        anchorEl: true,
      })
      toggleDrawerOpen(false)
    },
    [ setChannelItemInfo, toggleDrawerOpen, filteredListeningHistory, defaultVersionId ],
  )

  const onClickListeningHistory = useCallback(
    event => {
      preventDefaultEvent(event)
      openModal(event)
    },
    [ openModal ],
  )

  return (
    <>

      {filteredListeningHistory.length > 0 && !!channelIdInPWA &&
        <ListItem
          button
          onClick={onClickListen}
          className="MainMenuListeningHistory-latest"
        >
          <ListItemText primary={
            <ItemContent>
              {!filteredListeningHistory[0].doAudio && <StyledOndemandVideoIcon />}
              {filteredListeningHistory[0].doAudio && <StyledHeadsetIcon />}
              <ItemText>
                {filteredListeningHistory[0].title}
              </ItemText>
            </ItemContent>
          } />
        </ListItem>
      }

      <ListItem
        button
        onClick={onClickListeningHistory}
        className="MainMenuListeningHistory-history"
      >
        <ListItemText primary={
          <ItemContent>
            <StyledHistoryIcon />
            <ItemText>
              {i18n("Listening History")}
            </ItemText>
          </ItemContent>
        } />
      </ListItem>

      <OptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
        hideArrow
      >
        <Content $isMini={isMini}>
          {isMini &&
            <ListenHistoryHeader>
              <HeaderText>
                {i18n("Listening History")}
              </HeaderText>
              <StyledIconButton onClick={closeModal}>
                <CloseIcon />
              </StyledIconButton>
            </ListenHistoryHeader>
          }
          <StyledMenuList>
            {filteredListeningHistory.length === 0 &&
              <None>
                {i18n("None")}
              </None>
            }
            {filteredListeningHistory.map(({ id, title, doAudio, lastViewedAt, channelId }) => (
              <StyledMenuItem
                key={id}
                onClick={event => {
                  preventDefaultEvent(event)
                  onClickListen({ studyBibleItem: { id, title }, channelId })
                }}
              >
                <ItemContent $isMini={isMini}>
                  {!doAudio && <StyledOndemandVideoIcon />}
                  {doAudio && <StyledHeadsetIcon />}
                  <ItemText>
                    <ItemTitle>
                      {title}
                    </ItemTitle>
                    <ItemDate>
                      <RelativeTime date={lastViewedAt} />
                    </ItemDate>
                  </ItemText>
                  <CloseIconButton
                    onClick={event => {
                      preventDefaultEvent(event)
                      setListeningHistory(
                        listeningHistory.filter(item => item.id !== id)
                      )
                      const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
                      const snackbarInfo = {
                        open: true,
                        message: i18n("Listening history item deleted."),
                        buttonLabel: i18n("Restore"),
                        onClick: () => {
                          setListeningHistory(listeningHistory)
                          closeSnackbar()
                        },
                        onClose: closeSnackbar,
                      }
                      setSnackbarInfo(snackbarInfo)                  
                    }}
                  >
                    <CloseIcon />
                  </CloseIconButton>
                </ItemContent>
              </StyledMenuItem>
            ))}
          </StyledMenuList>
        </Content>
      </OptionsPopover>

    </>
  )
}

export default memo(MainMenuListeningHistory)