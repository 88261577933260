import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import FilterHdrIcon from '@material-ui/icons/FilterHdr'

import { getPrimaryName } from '../../../utils/misc'

import BibleMapPlaceDebuggerRect from './BibleMapPlaceDebuggerRect'

const Container = styled.div`
  position: absolute;
  z-index: 2;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  margin-top: -12px;
  display: flex;
  justify-content: center;
`

const StyledFilterHdrIcon = styled(FilterHdrIcon)`
  font-size: 24px;
  color: ${({ $selected, theme }) => $selected ? theme.palette.mapColors.selectedPlace : `black`};
`

const Label = styled.div`
  white-space: nowrap;
  line-height: 16px;
  position: absolute;
  top: 20px;
  text-shadow: 1px 1px 0 rgb(255 255 255/.3);

  ${({ $hideLabel }) => !$hideLabel ? `` : `
    display: none;
  `}
`

export const getApproxRect = ({ names }) => {
  const width = names[0].name.length * 6.3 + 15
  return {
    x: parseInt(width/-2, 10),
    y: -12,
    width,
    height: 40,
  }
}

const BibleMapPlaceMountain = ({
  place,
  selectThisPlace,
  getStyle,
  missingKeyData,
  ...otherProps
}) => {

  const style = getStyle(place)
  let hideLabel = false

  if(style.opacity === 0 && place.alwaysShowDot) {
    style.opacity = place.opacity || 1
    style.pointerEvents = `auto`
    style.cursor = `pointer`
    hideLabel = true
  }

  return (
    <>
      <Container
        onClick={selectThisPlace}
        style={style}
        {...otherProps}
      >
        <StyledFilterHdrIcon
          {...otherProps}
        />
        <Label
          $hideLabel={hideLabel}
        >
          {getPrimaryName(place)}
        </Label>
      </Container>
      <BibleMapPlaceDebuggerRect
        style={style}
        approxRect={getApproxRect(place)}
        missingKeyData={missingKeyData}
      />
    </>
  )
}

export default memo(BibleMapPlaceMountain)