import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import Header from "../../common/Header"
import ContactUsFab from '../../common/ContactUsFab'
import LinkButton from '../../common/LinkButton'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 20px;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  padding: 30px 0 300px;
`

const Heading = styled.h1`

`

const Question = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-top: 40px;
`

const Answer = styled.div`
  margin-bottom: 20px;
`

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 70px 0;
`

const GroupSubscriptionsFAQ = ({ ...props }) => {

  return (
    <>
      <Header {...props} />

      <Container>
        <Content>

          <Heading>
            {i18n("FAQ")}
          </Heading>

          <Question>
            {i18n("Must all group members begin their subscription at the same time?")}
          </Question>
          <Answer>
            {i18n("No. Each individual member begins his/her year or 3-months when the invitation is accepted.")}
          </Answer>

          <Question>
            {i18n("Can users with existing subscriptions participate in my group subscription?")}
          </Question>
          <Answer>
            {i18n("Yes. Their individually paid subscription will be automatically canceled and their group subscription will begin when the existing subscription period comes to an end.")}
          </Answer>

          <Question>
            {i18n("How do I add myself to the group subscription?")}
          </Question>
          <Answer>
            {i18n("Send an invitation to yourself and then click the link you receive.")}
          </Answer>

          <Question>
            {i18n("Do unused group subscription slots expire?")}
          </Question>
          <Answer>
            {i18n("No.")}
          </Answer>

          <Question>
            {i18n("What happens when a group member’s subscription comes to an end?")}
          </Question>
          <Answer>
            {i18n("He/she will receive an email saying that the subscription is expired and be invited to resubscribe.")}
            {` `}
            {i18n("Until then, this person’s projects will remain accessible, but uneditable.")}
          </Answer>

          <Question>
            {i18n("Can I change the size of my group subscription after I have purchased it?")}
          </Question>
          <Answer>
            {i18n("No. However, you can create an additional group subscription.")}
          </Answer>

          <Question>
            {i18n("Can I pay for a group subscription on a monthly basis?")}
          </Question>
          <Answer>
            {i18n("No. To keep things simple, clear, and the most cost-effective, group subscription are only purchased on an annual or 3-month basis.")}
          </Answer>

          <Question>
            {i18n("How can I share the group subscription cost with my group participants?")}
          </Question>
          <Answer>
            {i18n("There is no built-in way to do this. Rather, group subscriptions must be purchased by a single user. Any sharing will need to be facilitated between you and your friends.")}
          </Answer>

          <BackButtonContainer>
            <LinkButton
              to="/group-subscriptions"
              variant="contained"
              color="primary"
              disableElevation
            >
              {i18n("Back to Group Subscriptions")}
            </LinkButton>
          </BackButtonContainer>

        </Content>
      </Container>

      <ContactUsFab />

    </>
  )
}


export default memo(GroupSubscriptionsFAQ)