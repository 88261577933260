import { memo, forwardRef, useCallback } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"
import { usePrevious } from "react-use"

const variants = {
  enter: direction => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: direction => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0
  }),
}

const transition = {
  x: {
    type: "spring",
    stiffness: 300,
    damping: 30,
  },
  opacity: {
    duration: 0.2,
  }
}

const dragConstraints = {
  left: 0,
  right: 0,
}

const Container = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
`

const PageContainer = styled(motion.div)`
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const LazyLoadPageViewer = ({
  pageKey,
  getPageByKey,
  page,
  setPageKey,
  previousPageKey,
  nextPageKey,
  onAnimationComplete,
  swipeable,
  ...otherProps
}, ref) => {

  const lastPageKey = usePrevious(pageKey)

  const direction = (
    lastPageKey === previousPageKey
      ? 1
      : (
        lastPageKey === nextPageKey
          ? -1
          : 0
      )
  )

  const onDragEnd = useCallback(
    (e, { offset, velocity }) => {
      /**
       * Experimenting with distilling swipe offset and velocity into a single variable, so the
       * less distance a user has swiped, the more velocity they need to register as a swipe.
       * Should accomodate longer swipes and short flicks without having binary checks on
       * just distance thresholds and velocity > 0.
       */
      const swipeConfidenceThreshold = 10000
      const swipe = Math.abs(offset.x) * velocity.x

      if(offset.x < 0 && swipe < -swipeConfidenceThreshold && nextPageKey != null) {
        setPageKey(nextPageKey)
      } else if(offset.x > 0 && swipe > swipeConfidenceThreshold && previousPageKey != null) {
        setPageKey(previousPageKey)
      }
    },
    [ setPageKey, previousPageKey, nextPageKey ],
  )

  // By passing an absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new div. So you can infinitely paginate.

  return (
    <Container
      ref={ref}
      {...otherProps}
    >
      <AnimatePresence initial={false} custom={direction}>
        <PageContainer
          key={pageKey}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={transition}
          className="LazyLoadPageViewer-PageContainer"
          onAnimationComplete={onAnimationComplete}
          {...(!swipeable ? {} : {
            drag: "x",
            dragConstraints: dragConstraints,
            dragElastic: 1,
            onDragEnd: onDragEnd,
          })}
        >
          {page || getPageByKey(pageKey)}
        </PageContainer>
      </AnimatePresence>
    </Container>
  )
}

export default memo(forwardRef(LazyLoadPageViewer))