import { memo, forwardRef, useCallback, useRef } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'

import { addDaysToDate, getDateAsString, timeBetweenMapDates } from '../../../utils/misc'
import { MINIMUM_DETAILS_WIDTH } from '../../../hooks/useInteractiveTimeline'
import useAppSize from '../../../hooks/useAppSize'
import useInstanceValue from '../../../hooks/useInstanceValue'
import eras from './eras'

import BibleMapEvents from './BibleMapEvents'
import BibleMapPrecisionScroll from './BibleMapPrecisionScroll'
import OptionsPopover from '../../common/OptionsPopover'
import BibleMapTimelineHandle from './BibleMapTimelineHandle'

const DetailsContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: 59px;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  opacity: ${({ $detailsWidth }) => $detailsWidth > MINIMUM_DETAILS_WIDTH ? 1 : 0};
  width: ${({ $detailsWidth }) => $detailsWidth + 35}px;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
`

const PrecisionScrollContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
`

const TimelineContainer = styled.div`
  z-index: 5;
  position: absolute;
  top: 99px;
  bottom: 40px;
  right: ${({ $detailsWidth }) => $detailsWidth + 20}px;
  padding: 0 10px;
  display: flex;
  justify-content: stretch;
  cursor: pointer;

  ${({ $scanning }) => $scanning ? `` : `
    transition: right 0.3s ease-in-out;
  `}
`

const Timeline = styled.div`
  width: 10px;
  border-radius: 5px;
  background: black;
  border: 1px solid black;
`

const TimelineLabelTop = styled.div`
  position: absolute;
  left: -10px;
  width: 40px;
  height: 20px;
  top: -30px;
  white-space: nowrap;
  text-align: center;
  pointer-events: none;
  user-select: none;
  color: black;
  transition: color 0.3s ease-in-out, text-shadow .3s ease-in-out;
  ${({ $detailsWidth }) => $detailsWidth <= MINIMUM_DETAILS_WIDTH ? `` : `
    color: rgb(255 255 255/.8);
    text-shadow: 0 0 25px black, -0.5px 1px 15px rgb(0 0 0/.6), -0.5px 1px 10px rgb(0 0 0/.4), 0 .5px 4px rgb(0 0 0/.2);
  `}
`

const TimelineLabelBottom = styled(TimelineLabelTop)`
  top: auto;
  bottom: -30px;
`

const TimelineEras = styled.div`
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 10px;
  right: 10px;
`

const TimelineEra = styled.div`
  position: absolute;
  top: ${({ $topPercent }) => $topPercent * 100}%;
  height: ${({ $heightPercent }) => $heightPercent * 100}%;
  left: 0;
  width: 10px;
  background: ${({ $color }) => $color}aa;
`

const BibleMapTimeline = ({
  mapLayer,
  timelineStartDate,
  timelineEndDate,
  fromDate,
  toDate,
  detailsWidth,
  safeSetDetailsWidth,
  handleMenuOpen,
  toggleHandleMenuOpen,
  setFromDate,
  setToDate,
  preciseModeOpen,
  scanning,
  height,
  colorByJourneyId,
  eraId,
  updateMapTimelineForItem,
  ...timelineProps
}, ref) => {

  const handleRef = useRef()
  const { width } = useAppSize()

  const getFromDate = useInstanceValue(fromDate)
  const getToDate = useInstanceValue(toDate)
  const { totalDays: timelineTotalDays } = timeBetweenMapDates(timelineStartDate, timelineEndDate)

  const toggleDateRangeOnOff = useCallback(
    () => {
      if(getToDate()) {
        setFromDate(
          addDaysToDate({
            date: getFromDate(),
            days: parseInt(timeBetweenMapDates(getFromDate(), getToDate()).totalDays / 2, 10),
            minDate: timelineStartDate,
            maxDate: timelineEndDate,
          })
        )
        setToDate(null)
      } else {
        setFromDate(
          addDaysToDate({
            date: getFromDate(),
            days: timelineTotalDays * -0.1,
            minDate: timelineStartDate,
            maxDate: timelineEndDate,
          })
        )
        setToDate(
          addDaysToDate({
            date: getFromDate(),
            days: timelineTotalDays * 0.1,
            minDate: timelineStartDate,
            maxDate: timelineEndDate,
          })
        )
      }
      toggleHandleMenuOpen()
    },
    [ setFromDate, setToDate, timelineStartDate, timelineEndDate, timelineTotalDays, getFromDate, getToDate, toggleHandleMenuOpen ],
  )

  return (
    <>

      <DetailsContainer
        className={`dark-mode-exempt BibleMapTimeline-DetailsContainer-${detailsWidth > MINIMUM_DETAILS_WIDTH ? `open` : `closed`}`}
        $detailsWidth={detailsWidth}
        $scanning={scanning}
      >
        <BibleMapEvents
          mapLayer={mapLayer}
          timelineStartDate={timelineStartDate}
          timelineEndDate={timelineEndDate}
          height={height}
          colorByJourneyId={colorByJourneyId}
          eraId={eraId}
          updateMapTimelineForItem={updateMapTimelineForItem}
        />
      </DetailsContainer>

      <PrecisionScrollContainer>
        <BibleMapPrecisionScroll
          mapLayer={mapLayer}
        />
      </PrecisionScrollContainer>

      <TimelineContainer
        ref={ref}
        className="dark-mode-exempt"
        $detailsWidth={detailsWidth}
        $scanning={scanning}
        {...timelineProps}
      >
        <Timeline>
          <TimelineLabelTop
            $detailsWidth={detailsWidth}
          >
            {timelineStartDate}
          </TimelineLabelTop>
          <BibleMapTimelineHandle
            ref={handleRef}
            fromDate={fromDate}
            toDate={toDate}
            height={height}
            timelineStartDate={timelineStartDate}
            timelineEndDate={timelineEndDate}
          />
          <TimelineLabelBottom
            $detailsWidth={detailsWidth}
          >
            {timelineEndDate}
          </TimelineLabelBottom>
          <TimelineEras>
            {eraId === `bible` && eras.slice(1).map(era => {
              const daysToStart = timeBetweenMapDates(timelineStartDate, era.timelineStartDate).totalDays
              const daysToEnd = timeBetweenMapDates(timelineStartDate, era.timelineEndDate).totalDays
              const topPercent = daysToStart / timelineTotalDays
              const heightPercent = (daysToEnd - daysToStart) / timelineTotalDays
              return (
                <Tooltip
                  key={era.id}
                  title={i18n("Era of the {{era}} ({{date}})", {
                    era: era.getEraLabel(),
                    date: `${getDateAsString({ date: era.timelineStartDate })} – ${getDateAsString({ date: era.timelineEndDate })}`,
                  })}
                >
                  <TimelineEra
                    $topPercent={topPercent}
                    $heightPercent={heightPercent}
                    $color={era.color}
                    $roundBottom={era.timelineEndDate === timelineEndDate}
                  />
                </Tooltip>
              )
            })}
          </TimelineEras>
        </Timeline>
      </TimelineContainer>

      <OptionsPopover
        anchorEl={handleMenuOpen && handleRef.current}
        onClose={toggleHandleMenuOpen}
        showToSide={(detailsWidth+35)/width < .5 ? `left` : `right`}
      >
        <MenuList>
          <MenuItem
            onClick={toggleDateRangeOnOff}
          >
            {toDate ? i18n("Turn off date range") : i18n("Turn on date range")}
          </MenuItem>
          <MenuItem
            // onClick={}
            disabled
          >
            {i18n("View all events at this date")}
            {` (coming soon)`}
          </MenuItem>
        </MenuList>
      </OptionsPopover>

    </>
  )
}

export default memo(forwardRef(BibleMapTimeline))