import { gql } from '@apollo/client'

import channel_fields from '../fragments/channel_fields'

const channel = gql`
  query channel($id: ID!) {
    channel(id: $id) {
      ${channel_fields}
    }
  }
`

export default channel