import { memo } from 'react'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import styled from 'styled-components'
import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 3px;
  left: 4px;
  z-index: 1;
  color: ${({ theme, $color }) => theme.palette[$color || `primary`].main};
  padding: 10px;
`

const StyledHelpOutlineIcon = styled(HelpOutlineIcon)`
  color: ${({ theme }) => theme.palette.grey[700]};
  height: 16px;
  width: 16px;
`

const SubscribedInfoIcon = ({
  to,
  onClick,
  ...otherProps
}) => (

  <NavLinkOrAWithDisable
    to={to}
    exact
  >
    <StyledIconButton onClick={onClick} >
      <StyledHelpOutlineIcon {...otherProps} />
    </StyledIconButton>
  </NavLinkOrAWithDisable>

)

export default memo(SubscribedInfoIcon)
