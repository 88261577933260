const fields = `
  id
  heading
  text
  url
  info
  fromLoc
  toLoc
  userId
  channelId
  createdAt
  updatedAt
  deletedAt
  reactions
`

export default fields