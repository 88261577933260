const systemFormattingKeys = [
  {
    id: "system:attention-getters",
    name: "Attention Getters",
    type: "MARKUP",
    info: {
      styles: [
        {
          markupType: "TALL-HIGHLIGHT",
          label: "Grabbed My Attention",
        },
        {
          markupType: "BASE-HIGHLIGHT",
          label: "Related Details",
        },
      ],
    },
    public: false,
  },
  {
    id: "system:questions",
    name: "Questions",
    type: "MARKUP",
    info: {
      styles: [
        {
          markupType: "ZIG-ZAG-UNDERLINE",
          label: "I Have a Question",
        },
        {
          markupType: "BASE-HIGHLIGHT",
          label: "Related Details",
        },
      ],
    },
    public: false,
  },
  {
    id: "system:topics",
    name: "Topics",
    type: "MARKUP",
    info: {
      styles: [
        {
          markupType: "TALL-HIGHLIGHT",
          label: "Topic",
        },
        {
          markupType: "BASE-HIGHLIGHT",
          label: "Related Details",
        },
        {
          markupType: "CIRCLE",
          label: "Main Topic",
        },
      ],
    },
    public: false,
  },
  {
    id: "system:commands",
    name: "Commands+",
    type: "MARKUP",
    info: {
      styles: [
        {
          markupType: "TALL-HIGHLIGHT",
          label: "Commands",
        },
        {
          markupType: "NORMAL-UNDERLINE",
          label: "Anchors",
        },
        {
          markupType: "DASH-UNDERLINE",
          label: "Aims",
        },
        {
          markupType: "DOTTED-UNDERLINE",
          label: "Means",
        },
        {
          markupType: "ZIG-ZAG-UNDERLINE",
          label: "Manners",
        },
      ],
    },
    public: false,
  },
  {
    id: "system:context",
    name: "Context",
    type: "MARKUP",
    info: {
      styles: [
        {
          markupType: "ARROW-HIGHLIGHT-LEFT",
          label: "Connects to What Came Before",
        },
        {
          markupType: "ARROW-HIGHLIGHT-RIGHT",
          label: "Connects to What Follows",
        },
        {
          markupType: "ARROW-HIGHLIGHT-DOUBLE",
          label: "Connects to Both",
        },
      ],
    },
    public: false,
  },
  {
    id: "system:transitions",
    name: "Transitions",
    type: "MARKUP",
    info: {
      styles: [
        {
          markupType: "ARROW-HIGHLIGHT-RIGHT",
          label: "Transitional Marker",
        },
      ],
      colors: [
        {
          color: "RED",
          type: "Change in",
          value: "Time",
        },
        {
          color: "BLUE",
          type: "Change in",
          value: "Location",
        },
        {
          color: "GREEN",
          type: "Change in",
          value: "Speaker",
        },
        {
          color: "YELLOW",
          type: "Change in",
          value: "Subject",
        },
        {
          color: "ORANGE",
          value: "Rhetorical Shift",
        },
      ],
    },
    public: false,
  },
  {
    id: "system:theology",
    name: "Theology",
    type: "MARKUP",
    info: {
      styles: [
        {
          markupType: "NORMAL-HIGHLIGHT",
          label: "Informs Our Doctrine",
        },
      ],
      colorDefault: {
        example: "Justification",
      },
    },
    public: false,
  },
]

export default systemFormattingKeys