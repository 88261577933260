import { useCallback } from 'react'

import useShortcuts from './useShortcuts'
import keyDownP from '../components/modules/notes/shortcuts/keyDownP'
import keyDownD from '../components/modules/notes/shortcuts/keyDownD'

const keyDownFuncs = {
  keyDownP,
  keyDownD,
}

const useNotesShortcuts = params => {

  const {
    // getModule,
    cancelClickOrMoveRef={},
    noneditableViewingMode,
  } = params

  const inCorrectContext = useCallback(
    event => {

      // check that we are focused on this module
      if(![ `shared-page`, `sketch`].includes(noneditableViewingMode)) {
        if(!document.activeElement) return false
        if(!document.activeElement.closest('.notes-module')) return false
      }

      if(cancelClickOrMoveRef.current) {
        event.preventDefault()
        if(event.key === 'Escape') {
          cancelClickOrMoveRef.current()
        }
        return false
      }

      return true

    },
    [ cancelClickOrMoveRef, noneditableViewingMode ],
  )

  useShortcuts({
    keyDownFuncs,
    inCorrectContext,
    ...params,
  })

}

export default useNotesShortcuts