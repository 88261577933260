import useModuleSettings from './useModuleSettings'

export const defaultSettingValues = {
  textSizes: { base: 2.6, grc: 2.9, heb: 2.9 },  // base: 2-4; grc/heb: 2-4
  lineSpacings: { base: 2.6, grc: 2.5, heb: 2.7 },  // base: 2-3.5; grc/heb: 2-3
  showVsNums: false,
  showCfs: true,
  showNotes: true,
  showCantillation: true,
  showHebrewVowels: true,
  showGreekAccents: true,
  greekPunctuation: `ENGLISH`,
  outlineStyle: `STANDARD`,
  outlineVisibility: `SHOW`,
  formattingKeyId: `basic-highlighting`,
  formattingKeyView: `FULL`,
  lastScrollPosition: {
    index: 0,
    offset: -60,  // 60 for the header row
    align: `start`,
  },
}

const useOutlineSettings = ({
  moduleId,
  skip,
}) => {

  const outlineSettings = useModuleSettings({
    moduleId,
    defaultSettingValues,
    skip,
  })

  return outlineSettings

}

export default useOutlineSettings