import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation updateModuleByProject($id: ID!, $input: ModuleByProjectInput!, $updatedSince: Milliseconds) {
    updateModuleByProject(id: $id, input: $input, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`