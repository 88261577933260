import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateModuleSubtables from './useGoUpdateModuleSubtables'
import { cloneObj } from '../utils/misc'

import moduleDotQuery from '../graphql/queries/moduleDot'
import moduleDotsQuery from '../graphql/queries/moduleDots'
import updateModuleDotsMutation from '../graphql/mutations/updateModuleDots'
import deleteModuleDotsMutation from '../graphql/mutations/deleteModuleDots'

export const getDefaultModuleDot = () => ({
  __typename: `ModuleDot`,
  content: "",
  positionInfo: null,
  modulePieceId: null,
})

const useGoUpdateModuleDots = ({
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  const client = useApolloClient()

  const [ updateModuleDots, updateModuleDotsResult ] = useMutation(updateModuleDotsMutation)
  const [ deleteModuleDots, deleteModuleDotsResult ] = useMutation(deleteModuleDotsMutation)

  const onUpdate = useCallback(
    ({ oldData, newData, isNew }) => {

      newData.forEach((updatedModuleDot, idx) => {

        const oldModuleDot = (oldData || [])[idx]

        const updateQuery = ({ modulePieceId, doRemove }={}) => {

          const queryParams = {
            query: moduleDotsQuery,
            variables: {
              moduleId,
              modulePieceId,
            },
          }
  
          const { moduleDots } = client.readQuery(queryParams) || {}
  
          if(moduleDots) {
            if(!doRemove && !moduleDots.some(({ id }) => id === updatedModuleDot.id)) {
              client.writeQuery({
                ...queryParams,
                data: {
                  moduleDots: [ ...cloneObj(moduleDots), updatedModuleDot ].sort((a,b) => a.id < b.id ? -1 : 1),
                },
              })
            } else if(doRemove && moduleDots.some(({ id }) => id === updatedModuleDot.id)) {
              client.writeQuery({
                ...queryParams,
                data: {
                  moduleDots: cloneObj(moduleDots).filter(({ id }) => id === oldModuleDot.id),
                },
              })
            }
          }
  
        }
  
        if(!isNew) {
          if(oldModuleDot.modulePieceId !== updatedModuleDot.modulePieceId) {
            // remove it from the old modulePieceId
            updateQuery({
              modulePieceId: oldModuleDot.modulePieceId,
              doRemove: true,
            })
          }
        }
  
        // add it to the new modulePieceId
        updateQuery({ modulePieceId: updatedModuleDot.modulePieceId })

      })

    },
    [ moduleId, client ],
  )

  const updateAndDeleteFuncs = useGoUpdateModuleSubtables({
    updateFunc: updateModuleDots,
    updateResult: updateModuleDotsResult,
    deleteFunc: deleteModuleDots,
    deleteResult: deleteModuleDotsResult,
    singleItemByIdQuery: moduleDotQuery,
    pluralQuery: moduleDotsQuery,
    onUpdate,
    // onDelete,
    moduleId,
    projectId,
    undoRedoStack,
    getDefault: getDefaultModuleDot,
  })

  return updateAndDeleteFuncs
}

export default useGoUpdateModuleDots