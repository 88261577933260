import useGoUpdateModulePassages from '../hooks/useGoUpdateModulePassages'
import useGoUpdateModulePieces from '../hooks/useGoUpdateModulePieces'
import useGoUpdateModuleMarkups from '../hooks/useGoUpdateModuleMarkups'
import useGoUpdateModuleDots from '../hooks/useGoUpdateModuleDots'
import useGoSetModuleSetting from '../hooks/useGoSetModuleSetting'
import useLayoutEffectAsync from '../hooks/useLayoutEffectAsync'

// modulePassage

const UpdateModulePassages = ({ moduleId, projectId, data, updateObjs, undoRedoStack }) => {
  // data is null/undefined if this is a create

  const [ goUpdateModulePassages ] = useGoUpdateModulePassages({
    projectId,
    moduleId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goUpdateModulePassages(updateObjs, data) }, [])

  return null
}

const DeleteModulePassages = ({ moduleId, projectId, data, undoRedoStack }) => {

  const [ x, goDeleteModulePassages ] = useGoUpdateModulePassages({  // eslint-disable-line no-unused-vars
    projectId,
    moduleId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goDeleteModulePassages(data) }, [])

  return null
}

// modulePiece

const UpdateModulePieces = ({ moduleId, projectId, data, updateObjs, undoRedoStack }) => {
  // data is null/undefined if this is a create

  const [ goUpdateModulePieces ] = useGoUpdateModulePieces({
    projectId,
    moduleId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goUpdateModulePieces(updateObjs, data) }, [])

  return null
}

const DeleteModulePieces = ({ moduleId, projectId, data, undoRedoStack }) => {

  const [ x, goDeleteModulePieces ] = useGoUpdateModulePieces({  // eslint-disable-line no-unused-vars
    projectId,
    moduleId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goDeleteModulePieces(data) }, [])

  return null
}

// moduleMarkup

const UpdateModuleMarkups = ({ moduleId, projectId, data, updateObjs, undoRedoStack }) => {
  // data is null/undefined if this is a create

  const [ goUpdateModuleMarkups ] = useGoUpdateModuleMarkups({
    projectId,
    moduleId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goUpdateModuleMarkups(updateObjs, data) }, [])

  return null
}

const DeleteModuleMarkups = ({ moduleId, projectId, data, undoRedoStack }) => {

  const [ x, goDeleteModuleMarkups ] = useGoUpdateModuleMarkups({  // eslint-disable-line no-unused-vars
    projectId,
    moduleId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goDeleteModuleMarkups(data) }, [])

  return null
}

// moduleDot

const UpdateModuleDots = ({ moduleId, projectId, data, updateObjs, undoRedoStack }) => {
  // data is null/undefined if this is a create

  const [ goUpdateModuleDots ] = useGoUpdateModuleDots({
    projectId,
    moduleId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goUpdateModuleDots(updateObjs, data) }, [])

  return null
}

const DeleteModuleDots = ({ moduleId, projectId, data, undoRedoStack }) => {

  const [ x, goDeleteModuleDots ] = useGoUpdateModuleDots({  // eslint-disable-line no-unused-vars
    projectId,
    moduleId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goDeleteModuleDots(data) }, [])

  return null
}

// moduleSetting

const UpdateModuleSetting = ({ projectId, data, updateObj, undoRedoStack }) => {

  const [ goUpdateModuleSetting ] = useGoSetModuleSetting({
    moduleSetting: data,
    projectId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goUpdateModuleSetting(updateObj) }, [])

  return null
}

const undoComponentsByAction = {
  updateModulePassages: UpdateModulePassages,
  deleteModulePassages: DeleteModulePassages,
  updateModulePieces: UpdateModulePieces,
  deleteModulePieces: DeleteModulePieces,
  updateModuleMarkups: UpdateModuleMarkups,
  deleteModuleMarkups: DeleteModuleMarkups,
  updateModuleDots: UpdateModuleDots,
  deleteModuleDots: DeleteModuleDots,
  updateModuleSetting: UpdateModuleSetting,
}

export default undoComponentsByAction