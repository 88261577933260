import { memo } from 'react'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'

import useFormattingKey from '../../../hooks/useFormattingKey'

import FormattingKey from './FormattingKey'

const StyledFormattingKey = styled(FormattingKey)`
  width: 100%;
  max-width: 170px;
  position: relative;
  z-index: 0;
  padding: 5px;
  font-size: 12px;
  font-weight: normal;

  .formatting-key-explanation {
    font-weight: 300;
    margin: 
  }
`

const Content = styled.div`
  width: 100%;
  margin: -6px 0 -6px -16px;
  padding: 6px 0 6px 16px;
  box-sizing: content-box;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FormattingKeyOptionsMenuItemContent = ({
  id,
}) => {

  const formattingKey = useFormattingKey(id)

  return (
    <Tooltip
      title={
        formattingKey.hasLabels
          ? (
            <StyledFormattingKey
              id={formattingKey.id}
              hideName
            />
          )
          : (formattingKey.explanation || "")
      }
      placement="right"
      arrow
      TransitionComponent={Fade}
    >
      <Content>
        {formattingKey.name}
      </Content>
    </Tooltip>
  )
}

export default memo(FormattingKeyOptionsMenuItemContent)