import React, { memo, useMemo } from 'react'

import useRefState from '../../hooks/useRefState'
import useSetTimeout from '../../hooks/useSetTimeout'
import { dedup } from '../../utils/misc'

import PassageStudyBibleNotesPiece from './PassageStudyBibleNotesPiece'

const PassageStudyBibleNotesPieces = ({
  pieces,
}) => {

  const [ oldChildrenByKey, setOldChildrenByKey ] = useRefState({})
  const [ setClearOldTimeout ] = useSetTimeout()

  const piecesWithOld = useMemo(
    () => {

      const oldKeys = Object.keys(oldChildrenByKey)
      const updatedOldChildrenByKey = { ...oldChildrenByKey }
      const childrenByKey = {}
      pieces.forEach(({ key, children }) => {
        childrenByKey[key] = children
        if(!updatedOldChildrenByKey[key]) {
          updatedOldChildrenByKey[key] = children
        }
      })
      if(Object.values(oldChildrenByKey).length !== Object.values(updatedOldChildrenByKey).length) {
        setOldChildrenByKey(updatedOldChildrenByKey)
        setClearOldTimeout(() => setOldChildrenByKey(childrenByKey), 250)  // needs to be longer than the .2s in PassageStudyBibleNotesPiece-Container
      } else if(Object.keys(childrenByKey).sort().join(`\n`) !== Object.keys(oldChildrenByKey).sort().join(`\n`)) {
        setClearOldTimeout(() => setOldChildrenByKey(childrenByKey), 250)  // needs to be longer than the .2s in PassageStudyBibleNotesPiece-Container
      }

      return dedup(
        [
          ...pieces,
          ...oldKeys.map(key => ({
            key,
            delay: 0,
            children: updatedOldChildrenByKey[key],
          }))
        ],
        ({ key }) => key,
      )

    },
    [ pieces, oldChildrenByKey, setClearOldTimeout, setOldChildrenByKey ],
  )

  return (
    piecesWithOld.map(({ key, ...props }) => (
      <PassageStudyBibleNotesPiece
        key={key}
        {...props}
      />
    ))
  )
}

export default memo(PassageStudyBibleNotesPieces)