import { memo, useCallback, useMemo, useState, useContext } from 'react'
import styled from 'styled-components'
import { useLocation } from "react-router-dom"

import {
  MINIMUM_MODULE_COLUMN_WIDTH,
  MINIMUM_PASSAGE_COLUMN_WIDTH,
  MINIMUM_NOTES_COLUMN_WIDTH,
  HIDDEN_COLUMN_WIDTH,
  THUMBNAILS_WIDTH,
  MAX_NOTES_DRAWER_WIDTH,
  IS_EMBED,
} from "../../../utils/constants"
import { cloneObj, sortModules } from '../../../utils/misc'
import useDataQuery from '../../../hooks/useDataQuery'
import useProjectSizes from '../../../hooks/useProjectSizes'
import useGoUpdateProject from '../../../hooks/useGoUpdateProject'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useRestoreDeletedModuleByProject from '../../../hooks/useRestoreDeletedModuleByProject'
import useProjectShortcuts from '../../../hooks/useProjectShortcuts'
import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useThrottleCallback from '../../../hooks/useThrottleCallback'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import moduleComponentsByType from '../../modules/shared/moduleComponentsByType'
import { getEmbedMode } from '../../../graphql/links/embedLink'

import ProjectHeader from "./ProjectHeader"
import AppContent from "../../common/AppContent"
import ModuleThumbnails from "./ModuleThumbnails"
import Loading from "../../common/Loading"
import ResizableWidthContainer from "../../common/ResizableWidthContainer"
import ProjectPassageArea from "./ProjectPassageArea"
import ProjectNoModulesPlaceholder from "./ProjectNoModulesPlaceholder"
import ProjectNotesInPanel from "./ProjectNotesInPanel"
import ProjectNotesInDrawer from "./ProjectNotesInDrawer"
import ModuleAndThumbnail from "./ModuleAndThumbnail"
import ProjectNotFound from "./ProjectNotFound"
import ProjectNoTools from './ProjectNoTools'
import CustomSnackbar from '../../common/CustomSnackbar'
import EmbedRichTextBox from '../embed/EmbedRichTextBox'

import projectQuery from '../../../graphql/queries/project'

const ColumnsContainer = styled.div`
  flex: 1;
  min-width: 0;
  position: relative;
`

const getModifiedModuleAndMBP = ({ moduleByProjects, studyModuleId, hasToolsPlan }) => {
  const moduleByProject = moduleByProjects.find(({ module }) => module.id === studyModuleId) || {}
  let { module } = moduleByProject
  if(module && !hasToolsPlan) {
    module = {
      ...module,
      inEditingMode: null,
    }
  }
  return {
    moduleByProject,
    module,
  }
}

const Project = ({
  goPrintOrDownload,
  goSketch,
  printOrDownloadDialogOpen,
  embedId,
  embedProjectId,
  embedFullScreen,
  embedSetHeight,
  embedSettings={},
  embedOverflowHeight,
  ...otherProps
}) => {

  const location = useLocation()
  const id = embedProjectId || location.pathname.split('/')[2]
  const isNonPoppedOutEmbed = IS_EMBED && !embedFullScreen
  const noneditableViewingMode = IS_EMBED && getEmbedMode() === `frozen` && `embed`

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan=true } = user || {}

  const { project={}, loading: loadingProject } = useDataQuery({
    projectQuery,
    variables: {
      id,
    },
  })

  const [ goUpdateProject ] = useGoUpdateProject({ project })
  const { onDeleteModuleByProject, restoreComponents } = useRestoreDeletedModuleByProject({ projectId: id, goUpdateProject })

  const {
    moduleByProjects=[],
    studyModule1Id,
    studyModule2Id,
    inDoubleStudyMode=false,
    notesInDrawer=false,
    notesWidthPercentage=25,
    firstColumnWidthPercentage=50,
    passageBookmarks=[],
  } = project || {}

  const [ deleted, setDeleted ] = useState(false)
  const [ snackbarInfo, setSnackbarInfo ] = useState({ open: false })

  useEffectAsync(
    () => {
      setDeleted(false)
    },
    [ id ],
  )

  const { module: module1, moduleByProject: moduleByProject1 } = useMemo(
    () => getModifiedModuleAndMBP({ moduleByProjects, studyModuleId: studyModule1Id, hasToolsPlan }),
    [ moduleByProjects, studyModule1Id, hasToolsPlan ],
  )

  const { module: module2, moduleByProject: moduleByProject2 } = useMemo(
    () => getModifiedModuleAndMBP({ moduleByProjects, studyModuleId: studyModule2Id, hasToolsPlan }),
    [ moduleByProjects, studyModule2Id, hasToolsPlan ],
  )
 
  const Module1 = useMemo(() => moduleComponentsByType[(module1 || {}).type] || ProjectNoModulesPlaceholder, [ module1 ])
  const Module2 = useMemo(() => moduleComponentsByType[(module2 || {}).type] || ProjectNoModulesPlaceholder, [ module2 ])

  const projectSizes = useProjectSizes({
    inDoubleStudyMode,
    notesInDrawer: notesInDrawer || (IS_EMBED && !embedSettings.includeNotes),
    notesWidthPercentage,
    firstColumnWidthPercentage,
    passageBookmarks,
    moduleByProjects,
  })

  let {
    showInDoubleStudyMode,
    showNotesInDrawer,
    module1Width,
    module2Width,
    notesWidth,
    passagesWidth,
    mobileSize,
  } = projectSizes

  if(isNonPoppedOutEmbed) {
    showInDoubleStudyMode = false
    showNotesInDrawer = true
  }

  const getPassageBookmarks = useInstanceValue(passageBookmarks)
  const getProjectSizes = useInstanceValue(projectSizes)

  useProjectShortcuts({
    printOrDownloadDialogOpen,
    setSnackbarInfo,
  })

  const nonNotesModuleByProjects = useEqualObjsMemo(
    moduleByProjects
      .filter(({ module }) => module.type !== `NOTES`)
      .sort(sortModules)
  )

  const embedHideModuleThumbnails = (
    IS_EMBED
    && [ `modules` ].includes(embedSettings.embedType)
    && getEmbedMode() !== `prep`
    && (
      !embedSettings.allowStudentsToAddModules
      || embedSettings.viewOnly
    )
    && nonNotesModuleByProjects.length <= 1
  )

  const [ setHeight ] = useThrottleCallback(
    height => {
      if(!embedSetHeight) return
      // this function is only called for embedType === `modules`
      const numNonNotesModules = (project.moduleByProjects || []).filter(({ module }) => module.type !== `NOTES`).length
      if(
        (
          embedSettings.allowStudentsToAddModules
          && !embedSettings.viewOnly
        )
        || numNonNotesModules > 1
        || getEmbedMode() === `edit`
      ) {
        height = Math.max(height, 350)
      }
      if(mobileSize && !embedHideModuleThumbnails) {
        height += 47
      }
      embedSetHeight(height)
    },
    100,
  )

  const onResizeComplete = useCallback(
    widths => {
      if(mobileSize) return  // just in case

      const updates = {}

      const totalWidth = widths.reduce((total, width) => total + width, 0)
      const newPassagesWidth = widths.at(-1)

      let currentPassageBookmarks = getPassageBookmarks()

      if(!currentPassageBookmarks[0]) {
        currentPassageBookmarks = [{}]
      }

      if(newPassagesWidth !== currentPassageBookmarks[0].width) {
        updates.passageBookmarks = cloneObj(currentPassageBookmarks)
        updates.passageBookmarks[0].width = newPassagesWidth
      }

      if(!showNotesInDrawer) {
        // the percentage of the horizontal room without taking the passage column into account
        updates.notesWidthPercentage = (widths.at(-2) / (totalWidth - newPassagesWidth)) * 100
      }

      if(showInDoubleStudyMode) {
        // the percentage of the horizontal room without taking the passage column or notes column into account
        updates.firstColumnWidthPercentage = (
          (widths[0] - THUMBNAILS_WIDTH)
          / (totalWidth - newPassagesWidth - (!showNotesInDrawer ? widths.at(-2) : 0) - THUMBNAILS_WIDTH)
        ) * 100
      }

      if(Object.keys(updates).length > 0) {
        goUpdateProject(updates)
      }
    },
    [ mobileSize, showInDoubleStudyMode, showNotesInDrawer, getPassageBookmarks, goUpdateProject ],
  )

  if(!project || deleted) {
    return (
      <ProjectNotFound
        id={id}
        deletedJustNow={deleted}
        {...otherProps}
      />
    )
  }

  if(IS_EMBED && embedSettings.embedType !== `modules`) {
    if(loadingProject) return <Loading />

    return (
      <EmbedRichTextBox
        project={project}
        goPrintOrDownload={goPrintOrDownload}
        embedSettings={embedSettings}
        embedSetHeight={embedSetHeight}
        embedOverflowHeight={embedOverflowHeight}
      />
    )
  }

  const showPassageArea = !mobileSize && !isNonPoppedOutEmbed

  return (
    <>

      {!IS_EMBED &&
        <ProjectHeader
          project={project}
          setDeleted={setDeleted}
          nonNotesModuleByProjects={nonNotesModuleByProjects}
          showBibleDisplaySettings={showPassageArea && passagesWidth >= MINIMUM_PASSAGE_COLUMN_WIDTH}
          {...otherProps}
        />
      }

      {IS_EMBED && mobileSize && !embedHideModuleThumbnails &&
        <ModuleThumbnails
          project={project}
          nonNotesModuleByProjects={nonNotesModuleByProjects}
          embedSettings={embedSettings}
        />
      }

      {!hasToolsPlan && <ProjectNoTools />}

      <AppContent key={id}>

        {loadingProject && <Loading />}

        {!loadingProject &&
          <ColumnsContainer>

            <ResizableWidthContainer
              onResizeComplete={onResizeComplete}
              defaultMinimumColumnWidth={MINIMUM_MODULE_COLUMN_WIDTH}
            >
              {mobileSize &&
                <Module1
                  key={(module1 || {}).id}
                  module={module1}
                  projectId={project.id}
                  moduleByProject={moduleByProject1}
                  goPrintOrDownload={goPrintOrDownload}
                  goSketch={goSketch}
                  onDeleteModuleByProject={onDeleteModuleByProject}
                  width={module1Width}
                  padding={`0 10px`}
                  setHeight={setHeight}
                  noneditableViewingMode={noneditableViewingMode}
                  embedFullScreen={embedFullScreen}
                />
              }

              {!mobileSize &&
                <ModuleAndThumbnail
                  width={module1Width + THUMBNAILS_WIDTH}
                  minWidth={MINIMUM_MODULE_COLUMN_WIDTH + THUMBNAILS_WIDTH}
                  xPositionAdjustment={showInDoubleStudyMode ? THUMBNAILS_WIDTH * -.5 : 0}
                >
                  <Module1
                    key={(module1 || {}).id}
                    module={module1}
                    projectId={project.id}
                    moduleByProject={moduleByProject1}
                    goPrintOrDownload={goPrintOrDownload}
                    goSketch={goSketch}
                    onDeleteModuleByProject={onDeleteModuleByProject}
                    padding={`0 0 0 15px`}
                    setHeight={setHeight}
                    noneditableViewingMode={noneditableViewingMode}
                    embedFullScreen={embedFullScreen}
                    width={module1Width}
                  />
                  <ModuleThumbnails
                    project={project}
                    nonNotesModuleByProjects={nonNotesModuleByProjects}
                    showInDoubleStudyMode={showInDoubleStudyMode}
                    showNotesInDrawer={showNotesInDrawer}
                    notesDrawerWillCoverThumbnails={showInDoubleStudyMode || module1Width < MAX_NOTES_DRAWER_WIDTH}
                    embedSettings={embedSettings}
                    embedOverflowHeight={embedOverflowHeight}
                    embedHideModuleThumbnails={embedHideModuleThumbnails}
                  />
                </ModuleAndThumbnail>
              }

              {showInDoubleStudyMode &&
                <Module2
                  key={(module2 || {}).id}
                  module={module2}
                  projectId={project.id}
                  moduleByProject={moduleByProject2}
                  goPrintOrDownload={goPrintOrDownload}
                  goSketch={goSketch}
                  onDeleteModuleByProject={onDeleteModuleByProject}
                  width={module2Width}
                  padding={`0 15px 0 0`}
                  noneditableViewingMode={noneditableViewingMode}
                  embedFullScreen={embedFullScreen}
                />
              }

              {!showNotesInDrawer && (!IS_EMBED || !!embedSettings.includeNotes) &&
                <ProjectNotesInPanel
                  project={project}
                  getProjectSizes={getProjectSizes}
                  width={notesWidth}
                  minWidth={MINIMUM_NOTES_COLUMN_WIDTH}
                  hiddenWidth={HIDDEN_COLUMN_WIDTH}
                  goPrintOrDownload={goPrintOrDownload}
                  embedSettings={embedSettings}
                  onDeleteModuleByProject={onDeleteModuleByProject}
                />
              }

              {showPassageArea &&
                <ProjectPassageArea
                  project={project}
                  getProjectSizes={getProjectSizes}
                  goUpdateProject={goUpdateProject}
                  width={passagesWidth}
                  minWidth={MINIMUM_PASSAGE_COLUMN_WIDTH}
                  hiddenWidth={HIDDEN_COLUMN_WIDTH}
                />
              }

            </ResizableWidthContainer>

            {!!showNotesInDrawer && (!IS_EMBED || !!embedSettings.includeNotes) &&
              <ProjectNotesInDrawer
                project={project}
                modulesWidth={module1Width + module2Width + (mobileSize ? 0 : THUMBNAILS_WIDTH)}
                goPrintOrDownload={goPrintOrDownload}
                embedSettings={embedSettings}
                embedOverflowHeight={embedOverflowHeight}
                onDeleteModuleByProject={onDeleteModuleByProject}
              />
            }

          </ColumnsContainer>

        }

      </AppContent>

      <CustomSnackbar {...snackbarInfo} />
      {restoreComponents}

    </>
  )
}


export default memo(Project)