import { useContext } from 'react'
import { ChannelItemInfoContext } from '../context/ChannelItemInfo'

const useSetChannelItemInfo = () => {

  const { channelItemInfo, setChannelItemInfo } = useContext(ChannelItemInfoContext) || {}  // eslint-disable-line no-unused-vars

  return setChannelItemInfo
}

export default useSetChannelItemInfo
