import { useCallback } from "react"
import { useApolloClient } from '@apollo/client'

import { cloneObj } from '../utils/misc'
import useMutationContext from './useMutationContext'
import useInstanceValue from "./useInstanceValue"

import moduleMarkupsQuery from '../graphql/queries/moduleMarkups'
import useGoUpdateModuleMarkups from "./useGoUpdateModuleMarkups"

const useDuplicateModuleMarkups = ({
  moduleToCopyFrom,
  newModuleId,
  projectId,
}) => {

  const context = useMutationContext()
  const getContext = useInstanceValue(context)
  const client = useApolloClient()

  const [ goUpdateModuleMarkups ] = useGoUpdateModuleMarkups({
    projectId,
    moduleId: newModuleId,
  })

  const goDuplicateModuleMarkups = useCallback(
    async maps => {

      // get all markups
      const { data: { moduleMarkups } } = await client.query({
        query: moduleMarkupsQuery,
        variables: {
          moduleId: moduleToCopyFrom.id,
        },
        context: getContext(),
      })

      const newModuleMarkups = moduleMarkups.map(row => {
        const newModuleMarkup = cloneObj(row)
        delete newModuleMarkup.id
        // Needs to (sometimes) map container:
          // modulePassageId:${uid}
          // moduleDotId:${uid}
          // moduleMarkupId:${uid} (used?)
          // blockKey:${id} (or whatever it is for lexical)
          // sketchLayer:${uid}
          // (should not map formattingKeyId as that is account-wide)
        const [ x, containerType, oldValue ] = (row.container || ``).match(/^([^:]+):(.*)$/) || []  // eslint-disable-line no-unused-vars
        if(maps[`${containerType}Map`]) {
          newModuleMarkup.container = `${containerType}:${maps[`${containerType}Map`][oldValue] || null}`
        }
        return newModuleMarkup
      })

      goUpdateModuleMarkups(newModuleMarkups)

    },
    [ moduleToCopyFrom, client, getContext, goUpdateModuleMarkups ],
  )

  return goDuplicateModuleMarkups
}

export default useDuplicateModuleMarkups