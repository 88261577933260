import { memo, useMemo, useCallback } from 'react'
import { getLocFromRef, getCorrespondingRefs } from '@bibletags/bibletags-versification'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useVersionInfos from '../../hooks/useVersionInfos'
import useStickyRefState from '../../hooks/useStickyRefState'
import useTagSet from '../../hooks/useTagSet'
import useInterlinearInfo from '../../hooks/useInterlinearInfo'
import useEmbedSettings from '../../hooks/useEmbedSettings'
import { getOrigVersionInfo, isLegacyOriginalVersion, normalizeVersionId } from '../../utils/misc'
import { IS_EMBED } from '../../utils/constants'

import PassagePopperContainer from './PassagePopperContainer'
import VerseNumberPopperContentVersion from './VerseNumberPopperContentVersion'
import VerseNumberPopperSwitch from './VerseNumberPopperSwitch'
import VerseNumberPopperContentChart from './VerseNumberPopperContentChart'
import VerseNumberPopperContentInline from './VerseNumberPopperContentInline'
import ApparatusPopperContent from './ApparatusPopperContent'

const NoCorrespondingOriginal = styled.div`
  padding: 20px;
  font-size: 14px;
  padding-top: ${({ $hasHeader }) => $hasHeader ? 0 : 20}px;
`

const StyledPassagePopperContainer = styled(PassagePopperContainer)`
  && {
    padding-top: ${({ $hasHeader }) => $hasHeader ? `38px` : `54px`};
  }
`

const VerseNumberPopperContent = ({
  versionId,
  contextRef,
  goSetPopperInfo,
  closeAndClearSearch,
  // updateProps,
  hasHeader,
}) => {

  const [ view, setView ] = useStickyRefState({ id: `VerseNumberPopperContent:view`, defaultValue: `versions` })
  const [ showGloss, setShowGloss ] = useStickyRefState({ id: `VerseNumberPopperContent:showGloss`, defaultValue: false })
  const { unhiddenSelectedVersionInfos, defaultVersionId } = useVersionInfos({ restrictToTestamentBookId: contextRef.bookId })
  const { disableOriginalLanguageHelps } = useEmbedSettings()
  const disableOrigTabs = IS_EMBED && disableOriginalLanguageHelps
  const effectiveView = disableOrigTabs ? `versions` : view

  versionId = (
    (
      normalizeVersionId(versionId) === `original`
      || isLegacyOriginalVersion(versionId)
    )
      ? (
        (defaultVersionId === `original` && `esv`)
        || defaultVersionId
      )
      : versionId
  )

  const {
    tags,
    tagSetStatus,
    translationWords,
    originalRefs,
    originalPieces,
    originalWordPieces,
    loading,
  } = useTagSet({
    passageRef: contextRef,
    versionId,
  })

  const {
    phraseAndTranslationPairs,
    infoByWordIdAndPartNumber,
  } = useInterlinearInfo({
    versionId,
    tags,
    tagSetStatus,
    translationWords,
    originalWordPieces,  
  })

  const effectiveShowGloss = showGloss || [ undefined, `automatch`, `none` ].includes(tagSetStatus)

  const versionInfosToDisplay = useMemo(
    () => unhiddenSelectedVersionInfos.filter(({ id }) => id !== `original`),
    [ unhiddenSelectedVersionInfos ],
  )

  const apparatusItems = useMemo(
    () => {
      let apparatusPieces = (originalPieces || []).filter(({ tag }) => tag === "zApparatusJson")
      return (
        (originalRefs || [])
          .map(({ wordRanges, ...originalRef }, idx) => (
            apparatusPieces[idx]
              ? {
                ...apparatusPieces[idx],
                withCriticalAlt: (apparatusPieces[idx].apparatusJson.critical || []).some(reading => reading.includes(':')),
                originalRef,
              }
              : null
            ))
          .filter(Boolean)
      )
    },
    [ originalRefs, originalPieces ],
  )

  const compareOrigTexts = useCallback(
    ({ baseWords, apparatusJson, originalRef }) => {
      goSetPopperInfo(null, {
        Component: ApparatusPopperContent,
        props: {
          apparatusJson,
          baseWords,
          contextRef: originalRef,
          goSetPopperInfo,
          closeAndClearSearch,
        },
      })
    },
    [ goSetPopperInfo, closeAndClearSearch ],
  )

  if(!originalRefs) {
    return (
      <NoCorrespondingOriginal $hasHeader={hasHeader}>
        {i18n("This verse does not exist in the original and so is not aligned with any other version.")}
      </NoCorrespondingOriginal>
    )
  }

  return (
    <>

      <VerseNumberPopperSwitch
        hasHeader={hasHeader}
        view={effectiveView}
        setView={disableOrigTabs ? null : setView}
        bookId={contextRef.bookId}
        onClick={() => {
          goSetPopperInfo(
            { isPropsUpdate: true },
            {
              ids: undefined,
              versionId,
              contextRef,
              goSetPopperInfo,
              closeAndClearSearch,
            },
          )
        }}
      />

      <StyledPassagePopperContainer $hasHeader={hasHeader}>

        {effectiveView === `versions` && !!originalRefs && versionInfosToDisplay.map(({ version, id }) => {

          const locs = (
            originalRefs
              .map(originalRef => (
                getCorrespondingRefs({
                  baseVersion: {
                    ref: originalRef,
                    info: getOrigVersionInfo(),
                  },
                  lookupVersionInfo: version,
                })
              ))
              .filter(Boolean)
              .flat()
              .map(getLocFromRef)
          )

          return (
            <VerseNumberPopperContentVersion
              key={id}
              versionId={id}
              locs={locs}
              goSetPopperInfo={goSetPopperInfo}
              closeAndClearSearch={closeAndClearSearch}
              disabledVsNumClick
            />
          )
        })}

        {effectiveView === `chart` &&
          <VerseNumberPopperContentChart
            contextRef={contextRef}
            goSetPopperInfo={goSetPopperInfo}
            compareOrigTexts={compareOrigTexts}
            tagSetStatus={tagSetStatus}
            originalRefs={originalRefs}
            originalWordPieces={originalWordPieces}
            loading={loading}
            apparatusItems={apparatusItems}
            phraseAndTranslationPairs={phraseAndTranslationPairs}
            showGloss={effectiveShowGloss}
            setShowGloss={setShowGloss}
            versionId={versionId}
          />
        }

        {effectiveView === `inline` &&
          <VerseNumberPopperContentInline
            contextRef={contextRef}
            compareOrigTexts={compareOrigTexts}
            tagSetStatus={tagSetStatus}
            originalRefs={originalRefs}
            originalPieces={originalPieces}
            originalWordPieces={originalWordPieces}
            loading={loading}
            apparatusItems={apparatusItems}
            infoByWordIdAndPartNumber={infoByWordIdAndPartNumber}
            showGloss={effectiveShowGloss}
            setShowGloss={setShowGloss}
            versionId={versionId}
          />
        }

      </StyledPassagePopperContainer>

    </>
  )
}

export default memo(VerseNumberPopperContent)