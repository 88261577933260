import { useCallback, useState } from 'react'

const useModalAnchor = () => {

  const [ anchorEl, setAnchorEl ] = useState(null)
  const [ anchorPosition, setAnchorPosition ] = useState(null)

  const openModal = useCallback(
    ({ currentTarget }) => {
      setAnchorEl(currentTarget)
      const { left, top } = currentTarget.getBoundingClientRect()
      setAnchorPosition({ left, top })
    },
    [],
  )

  const closeModal = useCallback(
    () => {
      setAnchorEl(null)
      setAnchorPosition(null)
    },
    [],
  )

  return {
    anchorEl,
    anchorPosition,  // use this when doing a Menu or OptionsPopover from within an OptionsPopper
    openModal,
    closeModal,
  }
}

export default useModalAnchor
