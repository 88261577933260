import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation updateModuleDots($moduleId: ID!, $projectId: ID, $input: [ModuleDotInput]!, $updatedSince: Milliseconds) {
    updateModuleDots(moduleId: $moduleId, projectId: $projectId, input: $input, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`