import { useEffect, useRef } from 'react'

import { setEmbedOnTop } from '../graphql/links/embedLink'
import useEffectAsync from './useEffectAsync'

const popOnTopByKey = {}
let idx = 0
let onTop = false

const goSetEmbedOnTop = () => {
  const newOnTop = Object.values(popOnTopByKey).some(Boolean)
  if(onTop !== newOnTop) {
    onTop = newOnTop
    setEmbedOnTop(onTop)
  }
}

export const useSetEmbedOnTop = open => {

  const key = useRef()

  useEffect(
    () => {
      key.current = `key${++idx}`

      return () => {
        delete popOnTopByKey[key.current]
        goSetEmbedOnTop()
      }
    },
    [],
  )

  useEffectAsync(
    () => {
      popOnTopByKey[key.current] = !!open
      goSetEmbedOnTop()
    },
    [ open ],
  )

}

export default useSetEmbedOnTop