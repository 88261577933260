import { useCallback } from "react"
import ReactGA from "react-ga4"

import useEffectAsync from "./useEffectAsync"

const on = document.location.hostname === `app.biblearc.com`
let ready = Promise.resolve()

if(on) {
  ready = new Promise(resolve => {
    ReactGA.initialize("G-FXMS0CM3LX", {
      gtagUrl: `/analytics.js`,
    })
    setTimeout(resolve, 1000)  // Give time for GA to fully load
  })
}

const useAnalytics = ({
  eventReady,
  deps=[],
  eventObj,
  getEventObjs,
}={}) => {

  // Already captures 

  // ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });

  const reportEvent = useCallback(
    async params => {
      if(on && typeof params === `object`) {
        await ready
        // console.log(`ANALYTICS:`, params)
        ReactGA.event({
          category: `BibleUsage`,
          ...params,
        })
      }
    },
    [],
  )

  useEffectAsync(
    async () => {
      if(eventReady) {
        if(getEventObjs) {
          try {
            await Promise.all(getEventObjs().map(reportEvent))
          } catch(err) {
            console.error(`Analytics reporting error`, err)
          }
        } else {
          await reportEvent(eventObj)
        }
      }
    },
    [ ...deps, eventReady ],
  )

  return {
    reportEvent,
  }
}

export default useAnalytics
