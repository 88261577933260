import { gql } from '@apollo/client'

export default gql
`
  mutation createStudyBibleAssetUploadLink($contentType: String!) {
    createStudyBibleAssetUploadLink(contentType: $contentType) {
      uploadUrl
      url
    }
  }
`