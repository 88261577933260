import { forwardRef, memo, useMemo, useContext, useState } from 'react'
import styled from 'styled-components'
import { getPiecesFromUSFM, getTextLanguageId, isRTLText } from "@bibletags/bibletags-ui-helper"
import { i18n } from 'inline-i18n'
import { useMeasure } from 'react-use'

import useDataQuery from '../../hooks/useDataQuery'
import useVersionInfo from '../../hooks/useVersionInfo'
import useNextPrevChapters from '../../hooks/useNextPrevChapters'
import useAppSize from '../../hooks/useAppSize'
import useAnalytics from '../../hooks/useAnalytics'
import useEffectAsync from '../../hooks/useEffectAsync'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import useIsLoggedIn from '../../hooks/useIsLoggedIn'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import { getVersionIdForBibleTags, getPassageTextSize, getPassageLineSpacing } from '../../utils/misc'
import { getChapterHighlightsVariables } from '../../hooks/useGoUpdateHighlights'
import {
  PassageTextSizesContext,
  PassageLineSpacingSizesContext,
  PassageShowNotesContext,
  PassageShowHighlightsContext,
  PassageShowCfsContext,
  PassageShowCantillationContext,
  PassageShowGreekAccentsContext,
  PassageShowHebrewVowelsContext,
  PassageGreekPunctuationContext,
} from '../../context/LocalInfo'
import { POPPER_LINE_SPACING_SIZES, POPPER_TEXT_SIZES } from '../../utils/constants'

import PassageStudyBibleNotes from './PassageStudyBibleNotes'
import TextContent from '../common/TextContent'
import TextContentMarkupContainer from '../markup/TextContentMarkupContainer'
import ContainerWithPassagePopper from './ContainerWithPassagePopper'
import InfoDialog from '../common/InfoDialog'
import MyPlanPromoSpot from '../common/MyPlanPromoSpot'

import chapterQuery from '../../graphql/queries/chapter'
import highlightsQuery from '../../graphql/queries/highlights'

const ScrollContainer = styled.div`
  min-height: 0;
  flex-basis: 0;
  flex: 1;
  overflow: auto;
  outline: none;
`

const StyledTextContentMarkupContainer = styled(TextContentMarkupContainer)`
  padding-top: ${({ $topPadding=0 }) => $topPadding};
  min-height: 100%;
  display: flex;
  align-items: stretch;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: calc(((100vh - 59px - 48px) / ${({ $partOfParallel }) => $partOfParallel ? `2` : `1`}) - 80px);  // 59px is the header; 48px is the passage ref; 80px extra to look good
  position: relative;
`

const BibleTextAndStudyBibleContainer = styled.div`
  flex: 1;
  max-width: ${({ $showStudyBible }) => $showStudyBible ? 1050 : 900}px;
  margin: 0 auto;
  display: flex;
  overflow: visible;
`

const BibleTextContainer = styled(ContainerWithPassagePopper)`
  flex: 1;
  padding: 0 20px 20px;
  color: black;

  ${({ $font, $textSize, $lineSpacing, $isRTL }) => `
    font-family: ${$font || 'inherit'};
    font-size: ${$textSize}px;
    line-height: ${$lineSpacing};
    direction: ${$isRTL ? "rtl" : "ltr"};
  `}
`

const NoContent = styled.div`
  min-height: 0;
  flex-basis: 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  font-size: 16px;
`

const PassageContent = ({
  bookId,
  chapter,
  versionId,
  specialMarkup,
  showStudyBible,
  partOfParallel,
  topPadding,
  initialScrollYFraction,
  columnIdx,
  width,
  goSetPopperInfo,
  doPopperTextSizeAndLineSpacing,
  locsToHighlight,
  showChapterNumbers,
  closeAndClearSearch,
  updatePassageBookmark,
  chapterInfo,
  onLoad,
  setSnackbarInfo,
  channelIdOrUrl,
  noHighlights,
}, ref) => {

  showStudyBible = showStudyBible || !!channelIdOrUrl

  const [ bibleTextContainerRef, { width: bibleTextContainerWidth } ] = useMeasure()
  const [ relativeYAndHeightByLoc, setRelativeYAndHeightByLoc ] = useState({})
  const isLoggedIn = useIsLoggedIn()
  const [ showNoteInfoDialog, toggleShowNoteInfoDialog ] = useSimpleToggle()

  const displayOptionValues = (
    useContext(PassageShowNotesContext) +
    useContext(PassageShowCfsContext) +
    useContext(PassageShowCantillationContext) +
    useContext(PassageShowHebrewVowelsContext) +
    useContext(PassageShowGreekAccentsContext) +
    useContext(PassageGreekPunctuationContext) +
    ``
  )

  const { version } = useVersionInfo(versionId)
  const bibleTagsVersionId = getVersionIdForBibleTags({ versionId, bookId })

  const { wordDividerRegex, languageId: preadjustedLanguageId } = version || {}
  const languageId = getTextLanguageId({ languageId: preadjustedLanguageId, bookId })
  const isRTL = isRTLText({ languageId, bookId })

  const passageShowHighlights = useContext(PassageShowHighlightsContext)
  const textSizes = useContext(PassageTextSizesContext)
  const textSize = getPassageTextSize({
    textSizes: (
      doPopperTextSizeAndLineSpacing
        ? POPPER_TEXT_SIZES
        : textSizes
    ),
    languageId,
  })
  const lineSpacingSizes = useContext(PassageLineSpacingSizesContext)
  const lineSpacing = getPassageLineSpacing({
    lineSpacingSizes: (
      doPopperTextSizeAndLineSpacing
        ? POPPER_LINE_SPACING_SIZES
        : lineSpacingSizes
    ),
    languageId,
  })

  const { height: windowHeight } = useAppSize()

  // TODO
  const { font } = {} // displaySettings (also consider Hebrew accents, cantilation marks, etc - possibly hiding them via css?; toast about cantilation first time they display Hebrew?)

  const { previousChapter, nextChapter } = useNextPrevChapters({
    bookId,
    chapter,
    versionId,
  })

  const { chapter: vss } = useDataQuery({
    chapterQuery,
    variables: {
      bookId,
      chapter,
      versionId: bibleTagsVersionId,
    },
    dataOnError: null,
  })

  const { highlights: { highlights }={} } = useDataQuery({
    highlightsQuery,
    variables: getChapterHighlightsVariables({ versionId, bookId, chapter }),
    skip: !isLoggedIn || !!noHighlights || !passageShowHighlights,  // user still able to view highlights and notes, even without active MyBSB sub
    dataOnError: null,
  })

  const [ nonNoteTypeHighlights, notes ] = useMemo(
    () => {
      if(!highlights) return [ null, null ]

      const nonNoteTypeHighlights = highlights.filter(({ info: { type } }) => type !== `NOTE`)
      const notes = highlights.filter(({ info: { type } }) => type === `NOTE`)

      return [ nonNoteTypeHighlights, notes ]
    },
    [ highlights ],
  )

  useDataQuery({
    chapterQuery,
    variables: {
      ...(previousChapter || {}),
      versionId: bibleTagsVersionId,
    },
    skip: !previousChapter,
    dataOnError: null,
  })

  useDataQuery({
    chapterQuery,
    variables: {
      ...(nextChapter || {}),
      versionId: bibleTagsVersionId,
    },
    skip: !nextChapter,
    dataOnError: null,
  })

  const vssLength = (vss || []).length
  useAnalytics({
    eventReady: vssLength > 0 && !!versionId,
    deps: [ vss ],
    eventObj: {
      action: `Read:${versionId}`,
      value: vssLength,  // number of verses
    },
  })

  const pieces = useMemo(
    () => {
      if(!vss) return null

      return getPiecesFromUSFM({
        usfm: vss.map(({ usfm }) => usfm).join('\n'),
        wordDividerRegex,
        splitIntoWords: true,
      })
    },
    [ vss, wordDividerRegex ],
  )

  const contentReady = !!pieces
  useLayoutEffectAsync(
    () => {
      const { current: el } = ref || {}

      if(contentReady && el) {
        const maxScroll = el.scrollHeight - el.clientHeight
        el.scrollTop = maxScroll * initialScrollYFraction
      }
    },
    [ contentReady ],
  )

  // transfer focus on page turn
  useLayoutEffectAsync(
    () => () => {
      if(ref && document.activeElement === ref.current) {
        const pairOfScrollableContainers = document.querySelectorAll(`[data-passage-column-idx="${columnIdx}"]`)
        pairOfScrollableContainers.forEach(el => {
          if(el !== ref.current) {
            el.focus()
          }
        })
      }
    },
    [ columnIdx, ref ],
  )

  useEffectAsync(
    () => {
      if(contentReady) {
        onLoad && onLoad()
      }
    },
    [ contentReady ],
  )

  useEffectAsync(
    () => {
      const { current: el } = ref || {}

      if(contentReady && el) {
        const relativeYAndHeightByLoc = {}
        const { y: containerY } = el.getBoundingClientRect()
        ;[ ...el.querySelectorAll(`.TextContent-verse`) ].forEach(verseEl => {
          const wordEl = verseEl.querySelector(`[data-word-loc]`)
          if(wordEl) {
            const loc = wordEl.getAttribute(`data-word-loc`).split(':')[0]
            const { y, height } = verseEl.getBoundingClientRect()
            relativeYAndHeightByLoc[loc] = {
              y: y + el.scrollTop - containerY - 48,  // 48 is the padding height for the passage header
              height,
            }
          }
        })
        setRelativeYAndHeightByLoc(relativeYAndHeightByLoc)
      }
    },
    [ contentReady, bibleTextContainerWidth, lineSpacing, textSize, displayOptionValues, pieces, showChapterNumbers ],
  )

  if(pieces && pieces.length === 0) {
    return (
      <NoContent>
        {i18n("This chapter does not exist in this version.")}
      </NoContent>
    )
  }

  return (
    <>

      <ScrollContainer
        ref={ref}
        tabIndex={columnIdx !== undefined ? 0 : null}
        data-passage-column-idx={columnIdx}
        className="PassageContent-ScrollContainer"
      >
        <StyledTextContentMarkupContainer
          className="PassageContent-StyledTextContentMarkupContainer"
          $partOfParallel={partOfParallel}
          $topPadding={topPadding}
          isRTL={isRTL}
          markup={specialMarkup || nonNoteTypeHighlights}
          selectMarkupOnDoubleClick
        >

          <BibleTextAndStudyBibleContainer
            $showStudyBible={showStudyBible}
          >

            <BibleTextContainer
              ref={bibleTextContainerRef}
              className="PassageContent-BibleTextContainer"
              $font={font}
              $textSize={textSize}
              $lineSpacing={lineSpacing}
              $isRTL={isRTL}
              width={width}
              goSetPopperInfoOverride={goSetPopperInfo}
              versionId={versionId}
              bookId={bookId}
              topOffset={partOfParallel ? ((windowHeight - 107) / 2 + 107) : 107}  // 107 = 59px for the header + 48px for passage area header
              updatePassageBookmark={updatePassageBookmark}
              setSnackbarInfo={setSnackbarInfo}
              highlights={nonNoteTypeHighlights}
              showMarkupOption={!noHighlights}
              toggleShowNoteInfoDialog={toggleShowNoteInfoDialog}
            >
              <TextContent
                pieces={pieces}
                versionId={versionId}
                bookId={bookId}
                startChapter={chapter}
                // width={width}  // this is not used in TextContent and so I am unsure why it was here; commenting it out for now; delete later if no ill effects
                locsToHighlight={locsToHighlight}
                showChapterNumbers={showChapterNumbers}
                closeAndClearSearch={closeAndClearSearch}
              />
            </BibleTextContainer>

            {showStudyBible &&
              <PassageStudyBibleNotes
                key={isRTL ? `rtl` : `ltr`}  // this prevents an issue with the transition in mini mode
                chapterInfo={chapterInfo}
                versionId={versionId}
                width={width}
                channelIdOrUrl={channelIdOrUrl}
                relativeYAndHeightByLoc={relativeYAndHeightByLoc}
                notes={notes}
              />
            }

          </BibleTextAndStudyBibleContainer>


        </StyledTextContentMarkupContainer>
      </ScrollContainer>

      <InfoDialog
        open={showNoteInfoDialog}
        onOkay={toggleShowNoteInfoDialog}
        title={i18n("My Biblearc Study Bible")}
        okayButtonLabel={i18n("Close")}
        showCloseIconButton
        explanation={
          <MyPlanPromoSpot
            subscribeToMessage={i18n("Subscribe to add notes.")}
          />
        }
      />

    </>
  )
}

export default memo(forwardRef(PassageContent))