/*

Three ways to keep things "sticky":

1. useStickyRefState (localstorage)

  PARTICULARS:
  * device-specific
  - syncronous
  - works offline
  - not oversized
  * no instant sync (across uses throughout code and tabs)
  - works with non-general ids
  - "unlimited" update frequency
  
  USES:
  - initial values
  - in place of useState when you want it sticky
  
2. useAccountSetting (cloud/indexedDB)

  PARTICULARS:
  * account-specific (works with no login as well)
  - asyncronous
  - works offline
  - not oversized
  * instant sync (across uses throughout code and tabs)
  - general ids only
  - limited update frequency

  USES:
  - user customization

3. localInfo (indexedDB)

  PARTICULARS:
  * device-specific
  - pseudo-syncronous
  - works offline
  - can be large
  * instant sync (across uses throughout code and tabs)
  - general ids only
  - "unlimited" update frequency
  
  USES:
  - device customization

*/

import { useContext, useCallback } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'

import { LoggedInUserContext } from '../context/LoggedInUser'
import useIsLoggedIn from './useIsLoggedIn'
import useDataQuery from './useDataQuery'
import useGoSetAccountSetting from './useGoSetAccountSetting'
import { equalObjs } from '../utils/misc'
import { db } from '../utils/database'

import accountSettingQuery from '../graphql/queries/accountSetting'

const noop = ()=>{}

const useAccountSetting = (setting, defaultValue, options) => {

  const { skip } = options || {}
  const isLoggedIn = useIsLoggedIn()

  // get variables if for they are logged in

  const user = useContext(LoggedInUserContext)

  const accountSettingId = `${(user || {}).id}:${setting}`
  const { accountSetting, loading } = useDataQuery({
    accountSettingQuery,
    skip: !isLoggedIn || skip,
    refetchOnFocus: true,  // NOTE: this query doesn't go to the server, but needs this param to get the updated value from dexie
    variables: {
      id: accountSettingId,
    },
  }) || {}
  const { value=defaultValue } = accountSetting || {}
  const goSetAccountSetting = useGoSetAccountSetting(accountSettingId)

  const noLoginAccountSetting = useLiveQuery(
    () => (!skip && db.noLoginAccountSettings.get(setting)),
    [ setting ],
    {},
  )

  const { value: noLoginValue=defaultValue } = noLoginAccountSetting || {}

  const setNoLoginValue = useCallback(
    async value => {
      // note that this is async in contrast to goSetAccountSetting
      await db.noLoginAccountSettings.put({
        id: setting,
        value,
      })
    },
    [ setting ],
  )

  const toggleValue = useCallback(
    async () => {
      if(isLoggedIn) {
        goSetAccountSetting(!value)
      } else {
        await setNoLoginValue(!noLoginValue)
      }
    },
    [ isLoggedIn, value, noLoginValue, goSetAccountSetting, setNoLoginValue ],
  )

  if(skip) {
    return [
      defaultValue,
      noop,
      noop,
      false,
    ]
  } else if(isLoggedIn) {
    return [
      value,
      goSetAccountSetting,
      toggleValue,
      loading && !accountSetting,
    ]
  } else {
    return [
      noLoginValue,
      setNoLoginValue,
      toggleValue,
      !!(noLoginAccountSetting && equalObjs(noLoginAccountSetting, {})),
    ]
  }

}

export default useAccountSetting