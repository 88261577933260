import { memo, useCallback } from 'react'
import { i18n, getLocale } from 'inline-i18n'
import styled from 'styled-components'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import SnoozeIcon from '@material-ui/icons/Snooze'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import HeadsetIcon from '@material-ui/icons/Headset'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import { GET_STUDY_BIBLE_ITEM_TYPES } from '../../utils/constants'
import { getSecondsFromTimeString, getTimeStringFromSeconds, preventDefaultEvent } from '../../utils/misc'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useAppSize from '../../hooks/useAppSize'

import PassageRef from '../common/PassageRef'
import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'
import RelativeTime from '../common/RelativeTime'
import StudyBibleItemNoteThumbnail from './StudyBibleItemNoteThumbnail'
import StudyBibleItemEventThumbnail from './StudyBibleItemEventThumbnail'

const THUMBNAIL_BORDER_RADIUS = 10

const Container1 = styled.div`
  position: relative;
`

const Slot = styled.div`
  position: absolute;
  right: -10px;
  top: -15px;
  bottom: -15px;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  z-index: 2;

  ${({ $isRTL }) => !$isRTL ? `` : `
    right: auto;
    left: -20px;
  `}

`

const Container2 = styled.div`
  margin-bottom: 10px;
  transition: transform .25s ease-in-out;
  z-index: 1;

  ${({ $mini, $miniRevealed, $isRTL }) => (!$mini || ($miniRevealed === $isRTL)) ? `` : `
    transform: translateX(-190px);
  `}

`

const Heading = styled.div`
  margin: 5px 0;
  font-style: italic;
  font-size: 13px;
`

const Thumbnail = styled.div`
  width: 208px;
  height: 117px;
  transition: opacity .15s ease-in-out;
  position: relative;
  color: white;
  background-color: ${({ $type }) => $type ? GET_STUDY_BIBLE_ITEM_TYPES()[$type].color : `white`};
  background-image: url(${({ $thumbnailImageUrl }) => $thumbnailImageUrl});
  background-size: cover;
  background-position: center;
  cursor: pointer;

  ${({ $thumbnailImageUrl, $type }) => !$thumbnailImageUrl ? `` : `
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgb(0 0 0/.7);
      border-radius: ${$type === `CHANNEL` ? 0 : THUMBNAIL_BORDER_RADIUS}px;
    }
  `}


  ${({ $status, $type }) => $status === `PUBLISHED` ? `` : `
    background-color: white;
    color: ${$type ? GET_STUDY_BIBLE_ITEM_TYPES()[$type].color : `black`};
  `}

  ${({ $type }) => $type === `CHANNEL` ? `` : `
    border-radius: ${THUMBNAIL_BORDER_RADIUS}px;
  `}

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(135deg, rgb(0 0 0/.1) 0%, rgb(255 255 255/.4) 41%, rgb(255 255 255/.3) 60%, rgb(0 0 0/.1) 100%);
    transition: .3s ease-in-out;
    background-size: 300% auto;
    border-radius: ${({ $type }) => $type === `CHANNEL` ? 0 : THUMBNAIL_BORDER_RADIUS}px;
  }

  &:hover:after {
    background-position-x: 99%;
  }

  &:hover .StudyBibleItemThumbnail-playicon {
    transform: scale(1.1);
  }
`

const DotHeading = styled.div`
  font-weight: 300;
  font-style: italic;
`

const DotTitle = styled.div`
  font-size: 12px;
`

const ThumbnailMainContent = styled.div`
  position: absolute;
  top: 12px;
  right: ${({ $mini }) => $mini ? 38 : 12}px;
  left: 12px;
  bottom: 33px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $visible }) => $visible ? 1 : 0};
`

const ThumbnailTitle = styled.div`
  min-height: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.15;
  overflow: hidden;
`

const ThumbnailSpeaker = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  margin-right: 30px;
`

const ThumbnailSpeakerImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 5px;
  object-fit: cover;
  border-radius: 50%;
`

const MiniSpeakerImage = styled(ThumbnailSpeakerImage)`
  transform: rotate(90deg);
  margin: ${({ $isRTL }) => $isRTL ? `-5px 0 -5px 7px` : `-5px 7px -5px 0`};
`

const ThumbnailSpeakerName = styled.div`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 1;
`

const ThumbnailDate = styled.div`
  font-size: 10px;
`

const TypeAndStatusContainer = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  display: flex;
  gap: 5px;
  align-items: flex-end;
`

// const ChannelImage = styled.img`
//   height: 11px;
//   margin: 3px;
//   opacity: .9;
// `

const Type = styled.div`
  border-radius: 100px;
  background: rgb(0 0 0/.2);
  padding: 3px 5px;
  font-size: 8px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 400;

  ${({ $status, $type }) => $status === `PUBLISHED` ? `` : `
    color: white;
    background-color: ${GET_STUDY_BIBLE_ITEM_TYPES()[$type].color};
  `}
`

const IconContainer = styled.div`
  margin: -20px 0 -5px;
  .MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }
`

const StyledPlayCircleFilledIcon = styled(PlayCircleFilledIcon)`
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 33px;
  height: 33px;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;
  opacity: ${({ $visible }) => $visible ? 1 : 0};
`

const ClipLength = styled.div`
  position: absolute;
  bottom: 4px;
  right: 37px;
  font-size: 11px;
  color: white;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $visible }) => $visible ? 1 : 0};

  ${({ $status, $type }) => $status === `PUBLISHED` ? `` : `
    color: ${GET_STUDY_BIBLE_ITEM_TYPES()[$type].color};
  `}
`

const MediaTypes = styled.div`
  padding: 1.5px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $visible }) => $visible ? 1 : 0};
`

const BookmarkIconContainer = styled.div`
  position: relative;
  display: flex;
  color: rgb(255 255 255/.7);
  font-size: 8px;
  margin: -2px -7px -2px -3px;
  font-weight: 500;

  .MuiSvgIcon-root {
    font-size: 26px;
  }

  ${({ $hasMine, theme }) => !$hasMine ? `` : `
    color: ${theme.palette.primary.light};
    font-weight: bold;
    font-size: 11px;
  `}

  ${({ $mini, $miniRevealed, $isRTL }) => !($mini && !$miniRevealed && $isRTL) ? `` : `
    transform: translateX(178px);
  `}

`

const NumBookmarks = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 1.5px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
`

const StyledOndemandVideoIcon = styled(OndemandVideoIcon)`
  font-size: 12px;
`

const StyledHeadsetIcon = styled(HeadsetIcon)`
  font-size: 12px;
`

const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1;
  padding: 10px;
  color: ${({ $status, $type }) => $status ===`PUBLISHED` ?  `white` : GET_STUDY_BIBLE_ITEM_TYPES()[$type].color};
  transition: opacity .25s ease-in-out;
  opacity: ${({ $visible }) => $visible ? 1 : 0};

  &:hover {
    background: rgb(0 0 0/.15);
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }
`

const MiniPassageRefContainer = styled.div`
  position: absolute;
  top: 0;
  left: -117px;
  width: 117px;
  line-height: 1;
  padding: 8px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transform: rotate(270deg);
  transform-origin: top right;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $visible }) => $visible ? 1 : 0};


  ${({ $isRTL }) => !$isRTL ? `` : `
    justify-content: flex-start;
    transform: rotate(90deg);
    transform-origin: top left;
    left: auto;
    right: -117px;
  `}
`

const StudyBibleItemThumbnail = ({
  studyBibleItem,
  versionId,
  mini,
  isRTL,
  setItemInfo,
  onClose,
  fromViewOrMoreLink,
}) => {

  const { miniHeaderSize, studyBibleItemPopoverSize } = useAppSize()
  const [ miniRevealed, toggleMiniRevealed ] = useSimpleToggle()

  const {
    id,
    heading,
    title,
    type,
    info={},
    thumbnailImageUrl,
    status=`PUBLISHED`,
    fromLoc,
    toLoc,
    // editHistory,
    // adminRating,
    // userId,
    // details,
    // tags,
    // createdAt,
    // updatedAt,
    // deletedAt,
    // studyBibleFollow={},
    reactions=[],
  } = studyBibleItem

  let { mediaStartTime, mediaEndTime, audioDuration, videoDuration, speakerName, speakerImageUrl, date, dateIsApproximate } = info || {}
  speakerImageUrl = !/\/generic\.png$/.test(speakerImageUrl || ``) ? speakerImageUrl : null
  const durationInSeconds = Math.max(getSecondsFromTimeString(mediaEndTime) - getSecondsFromTimeString(mediaStartTime), 0) || audioDuration || videoDuration || null
  const hasMine = (reactions.myReactionTypes || []).length > 0
  const numOtherBookmarks = Object.values(reactions || {}).reduce((total, num) => total + (typeof num === `number` ? num : 0), 0)
  const numBookmarksText = (
    hasMine
      ? (
        reactions.myReactionTypes.length > 9
          ? `9+`
          : reactions.myReactionTypes.length
      )
      : (
        numOtherBookmarks > 99
          ? `99+`
          : numOtherBookmarks
      )
  )
  const linkDisabled = studyBibleItemPopoverSize || type === `CHANNEL`

  const statusIcon = (
    (
      status !== `PUBLISHED`
      && (
        <IconContainer>
          {{
            "DRAFT-UNLOCKED": <LockOpenIcon />,
            "DRAFT-LOCKED": <LockIcon />,
            "DEFERRED": <SnoozeIcon />,
          }[status]}
        </IconContainer>
      )
    ) || null
  )

  const closeMiniRevealed = useCallback(
    event => {
      preventDefaultEvent(event)
      toggleMiniRevealed({ force: false })
    },
    [ toggleMiniRevealed ],
  )

  const open = useCallback(
    event => {
      if(mini && !miniRevealed) {
        preventDefaultEvent(event)
        toggleMiniRevealed()
      } else if(linkDisabled) {
        setItemInfo({
          studyBibleItem,
          versionId,
          anchorEl: event.currentTarget,
        })
        if(miniRevealed) toggleMiniRevealed()
      }
    },
    [ setItemInfo, studyBibleItem, versionId, mini, miniRevealed, toggleMiniRevealed, linkDisabled ],
  )

  if(type === `NOTE`) {
    return (
      <StudyBibleItemNoteThumbnail
        mini={mini}
        isRTL={isRTL}
        studyBibleItem={studyBibleItem}
        setItemInfo={setItemInfo}
        onClose={onClose}
        fromViewOrMoreLink={fromViewOrMoreLink}
      />
    )
  }

  if(type === `EVENT`) {
    return (
      <StudyBibleItemEventThumbnail
        mini={mini}
        isRTL={isRTL}
        studyBibleItem={studyBibleItem}
      />
    )
  }

  return (
    <>

      {!mini &&
        <Heading>
          {heading || i18n("[ heading ]")}
        </Heading>
      }

      <NavLinkOrAWithDisable
        to={`/study-bible/${encodeURIComponent(id)}`}
        disabled={linkDisabled}
      >
        <Container1>

          <Container2
            onClick={open}
            $miniRevealed={miniRevealed}
            $isRTL={isRTL}
            $mini={mini}
            className={`StudyBibleItemThumbnail-Container2-${(mini && !miniRevealed) ? `mini` : `normal`}`}
          >

            <Tooltip
              title={
                (mini && !miniRevealed)
                  ? (
                    <>
                      <DotHeading>
                        {heading}
                      </DotHeading>
                      <DotTitle>
                        {title}
                      </DotTitle>
                    </>
                  )
                  : ``
              }
              placement={isRTL ? `right` : `left`}
            >
              <Thumbnail
                className={`${thumbnailImageUrl ? `dark-mode-exempt` : ``} StudyBibleItemThumbnail-Thumbnail`}
                $thumbnailImageUrl={thumbnailImageUrl}
                $type={type}
                $status={status}
                $isRTL={isRTL}
              >

                <ThumbnailMainContent
                  $mini={mini}
                  $visible={!mini || miniRevealed}
                >
                  <ThumbnailTitle>
                    {title}
                  </ThumbnailTitle>
                  {!!speakerName &&
                    <ThumbnailSpeaker>
                      {!!speakerImageUrl &&
                        <ThumbnailSpeakerImage
                          src={speakerImageUrl}
                        />
                      }
                      <ThumbnailSpeakerName>
                        {speakerName}
                      </ThumbnailSpeakerName>
                    </ThumbnailSpeaker>
                  }
                  {!!date &&
                    <ThumbnailDate>
                      {dateIsApproximate
                        ? <RelativeTime date={date} />
                        : new Date(date || 0).toLocaleDateString(getLocale())
                      }
                    </ThumbnailDate>
                  }
                </ThumbnailMainContent>

                <TypeAndStatusContainer>

                  {type === `CHANNEL` && Object.values(reactions || {}).length > 0 &&
                    <BookmarkIconContainer
                      $hasMine={hasMine}
                      $isRTL={isRTL}
                      $mini={mini}
                      $miniRevealed={miniRevealed}
                    >
                      <BookmarkIcon/>
                      <NumBookmarks>{numBookmarksText}</NumBookmarks>
                    </BookmarkIconContainer>
                  }

                  {type === `CHANNEL` &&
                    <MediaTypes $visible={!mini || miniRevealed} >
                      {!!audioDuration && <StyledHeadsetIcon />}
                      {!!videoDuration && <StyledOndemandVideoIcon />}
                    </MediaTypes>
                  }

                  {/* {type === `CHANNEL` &&
                    <ChannelImage
                      src={
                        (
                          STUDY_BIBLE_CHANNELS.find(({ channelUrl }) => channelUrl === studyBibleFollow.channelUrl)
                          || STUDY_BIBLE_CHANNELS[0]
                        ).darkLogoSrc
                      }
                    />
                  } */}

                  {type !== `CHANNEL` &&
                    <Type
                      $type={type}
                      $status={status}
                    >
                      {GET_STUDY_BIBLE_ITEM_TYPES()[type].label}
                    </Type>
                  }

                  {statusIcon}

                </TypeAndStatusContainer>

                {[ `SERMON`, `LECTURE`, `PODCAST`, `COURSE`, `CHANNEL` ].includes(type) &&
                  <ClipLength
                    $type={type}
                    $status={status}
                    $visible={!mini || miniRevealed}
                  >
                    {typeof durationInSeconds === `number` ? getTimeStringFromSeconds(durationInSeconds): ``}
                  </ClipLength>
                }

                {[ `SERMON`, `LECTURE`, `PODCAST`, `COURSE`, `CHANNEL` ].includes(type) &&
                  <StyledPlayCircleFilledIcon
                    className="StudyBibleItemThumbnail-playicon"
                    $visible={!mini || miniRevealed}
                  />
                }

                {mini &&
                  <MiniPassageRefContainer
                    $visible={mini && !miniRevealed}
                    $isRTL={isRTL}
                  >
                    {!!speakerImageUrl && !isRTL &&
                      <MiniSpeakerImage
                        $visible={mini && !miniRevealed}
                        src={speakerImageUrl}
                      />
                    }
                    <PassageRef
                      fromLoc={fromLoc}
                      toLoc={toLoc}
                      convertToVersionId={versionId}
                      withoutBookName
                    />
                    {!!speakerImageUrl && isRTL &&
                      <MiniSpeakerImage
                        $visible={mini && !miniRevealed}
                        src={speakerImageUrl}
                        $isRTL
                      />
                    }
                  </MiniPassageRefContainer>
                }

                {mini &&
                  <CloseIconButton
                    $status={status}
                    $type={type}
                    $visible={miniRevealed}
                    onClick={closeMiniRevealed}
                    className={`StudyBibleItemThumbnail-CloseIconButton-${miniRevealed ? `visible` : `hidden`}`}
                  >
                    <CloseIcon />
                  </CloseIconButton>
                }

              </Thumbnail>
            </Tooltip>

          </Container2>

          {mini && !miniHeaderSize && <Slot $isRTL={isRTL} />}

        </Container1>
      </NavLinkOrAWithDisable>

    </>
  )
}

export default memo(StudyBibleItemThumbnail)