import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useNumChapters from '../../hooks/useNumChapters'

import ChapterChooserChapter from './ChapterChooserChapter'

const Container = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 7px;
  margin-bottom: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`

const ChapterChooser = ({
  versionId,
  selectedBookId,
  selectedChapter,
  setChapter,
}) => {

  const numChapters = useNumChapters({
    versionId,
    bookId: selectedBookId,
  })

  return (
    <Container>
      {Array(numChapters).fill().map((x, idx) => {

        const chapter = idx + 1

        return (
          <ChapterChooserChapter
            key={chapter}
            chapter={chapter}
            isSelected={chapter === selectedChapter}
            setChapter={setChapter}
          />
        )
      })}
    </Container>
  )
}

export default memo(ChapterChooser)