import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import { useMutation } from '@apollo/client'
import Autocomplete from '@material-ui/lab/Autocomplete'

import useDataQuery from '../../../hooks/useDataQuery'
import useMutationContext from '../../../hooks/useMutationContext'

import ConfirmDialog from "../../common/ConfirmDialog"

import usersQuery from '../../../graphql/queries/users'
import deleteUserMutation from '../../../graphql/mutations/deleteUser'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  max-width: 100%;
`

const StyledTextField = styled(TextField)`
  width: 300px;
  margin-bottom: 25px;
`

const MergeFrom = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`

const Warning = styled.div`
  align-self: stretch;
  margin-bottom: 10px;
`

const getAll = option => option

const UserTabConfirmMergeDialog = ({
  onCancel,
  selectedUser,
  setSelectedUser,
  ...otherProps
}) => {

  const context = useMutationContext()

  const [ open, setOpen ] = useState(false)
  const [ searchText, setSearchText ] = useState(``)
  const [ mergeToUser, setMergeToUser ] = useState()
  const [ doingAction, setDoingAction ] = useState(false)

  const { id } = selectedUser || {}
  const { id: mergeToUserId } = mergeToUser || {}

  const onChange = useCallback(
    ({ target }) => {
      setMergeToUser()
      setSearchText(target.value)
    },
    [],
  )

  const onOpen = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => setOpen(false), [])

  const { users: { users=[] }={}, loading } = useDataQuery({
    usersQuery,
    skip: !searchText.trim(),
    variables: {
      query: `not-id:${id} ${searchText.toLowerCase().trim()}`,
    },
    fetchPolicy: 'network-only',
  })

  const [ deleteUser ] = useMutation(deleteUserMutation)

  const getOptionLabel = useCallback(user => `${user.email}${user.name ? ` (${user.name})` : ``} [${user.id}]`, [])
  const getOptionMergeTo = useCallback((option, value) => getOptionLabel(option) === getOptionLabel(value), [ getOptionLabel ])

  const onSelect = useCallback(
    (event, user) => {
      setMergeToUser(user)
      setSearchText(``)
    },
    [],
  )

  const goCancel = useCallback(
    async () => {
      setMergeToUser()
      onCancel()
    },
    [ onCancel ],
  )

  const goMergeAndDeleteUser = useCallback(
    async () => {

      setDoingAction(true)

      await deleteUser({
        variables: {
          id,
          mergeToUserId,
        },
        context,
      })

      goCancel()
      setSelectedUser()
      setMergeToUser()
      setDoingAction(false)

    },
    [ deleteUser, context, id, mergeToUserId, goCancel, setSelectedUser ],
  )

  return (
    <ConfirmDialog
      {...otherProps}
      onCancel={goCancel}
      onConfirm={goMergeAndDeleteUser}
      title={i18n("Merge and Delete Forever?", "", "admin")}
      explanation={
        <Container>

          <MergeFrom>
            {i18n("Merge from: {{email1}}", "", "admin", {
              email1: selectedUser.email,
            })}
          </MergeFrom>

          <Autocomplete
            filterOptions={getAll}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            onChange={onSelect}
            getOptionLabel={getOptionLabel}
            getOptionMergeTo={getOptionMergeTo}
            noOptionsText={i18n("None found", "", "admin")}
            options={users}
            loading={loading}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label={i18n("Merge to...", "", "admin")}
                variant="outlined"
                value={searchText}
                onChange={onChange}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />

          {mergeToUser &&
            <Warning>
              {i18n("My Biblearc Study Bible and Biblearc TOOLS data from {{email1}} will be merged into the account held by {{email2}}. (Course data on Biblearc EQUIP will NOT be merged.) Then the account held by {{email1}} will be permanently deleted across all of Biblearc, causing any Biblearc EQUIP data to be lost forever. Are you sure?", "", "admin", {
                email1: selectedUser.email,
                email2: mergeToUser.email,
              })}
            </Warning>
          }
        </Container>
      }
      confirmButtonLabel={i18n("Merge and Delete Forever", "", "admin")}
      doubleConfirm={!!mergeToUser}
      disabled={!mergeToUser}
      loading={doingAction}
    />
  )
}


export default memo(UserTabConfirmMergeDialog)