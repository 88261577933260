import { useCallback } from "react"
import { useApolloClient } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'

import { cloneObj } from '../utils/misc'
import useMutationContext from './useMutationContext'
import useInstanceValue from './useInstanceValue'
import useGoUpdateModuleDots from "./useGoUpdateModuleDots"

import moduleDotsQuery from '../graphql/queries/moduleDots'

const useDuplicateModuleDots = ({
  moduleToCopyFrom,
  newModuleId,
  projectId,
}) => {

  const context = useMutationContext()
  const getContext = useInstanceValue(context)
  const client = useApolloClient()

  const [ goUpdateModuleDots ] = useGoUpdateModuleDots({
    projectId,
    moduleId: newModuleId,
  })

  const goDuplicateModuleDots = useCallback(
    async ({ modulePieceIdMap }) => {

      // get all dots
      const { data: { moduleDots } } = await client.query({
        query: moduleDotsQuery,
        variables: {
          moduleId: moduleToCopyFrom.id,
        },
        context: getContext(),
      })

      const moduleDotIdMap = {}

      const newModuleDots = moduleDots.map(row => {
        const newModuleDot = cloneObj(row)
        moduleDotIdMap[row.id] = newModuleDot.id = uuidv4()
        newModuleDot.modulePieceId = modulePieceIdMap[row.modulePieceId] || null
        return newModuleDot
      })

      goUpdateModuleDots(newModuleDots)

      return { moduleDotIdMap }

    },
    [ moduleToCopyFrom, client, getContext, goUpdateModuleDots ],
  )

  return goDuplicateModuleDots
}

export default useDuplicateModuleDots