import { gql } from '@apollo/client'
import groupSubscription_fields from '../fragments/groupSubscription_fields'
import { subsetDefs, subsetMaps } from '../fragments/subset_parameters'

const groupSubscriptions = gql`
  query groupSubscriptions($query: String, ${subsetDefs}) {
    groupSubscriptions(query: $query, ${subsetMaps}) {
      groupSubscriptions {
        ${groupSubscription_fields}
      }
      count
    }
  }
`

export default groupSubscriptions