import { memo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'
import SnoozeIcon from '@material-ui/icons/Snooze'

import { GET_STUDY_BIBLE_ITEM_TYPES } from '../../utils/constants'
import useVersionInfos from '../../hooks/useVersionInfos'

import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'
import PassageRef from '../common/PassageRef'

const Container = styled.div`
  margin-bottom: 10px;
  cursor: pointer;

  &:hover .StudyBibleItemSearchResultsRow-Title {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`

const Type = styled.div`
  background: ${({ $color, theme }) => $color || theme.palette.mapColors.event};
  border-radius: 50px;
  color: white;
  line-height: 1.1;
  padding: 2px 5px;
  font-size: 9px;
  text-transform: uppercase;
  white-space: nowrap;
  flex-shrink: 0;
`

const TitlePlus = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  .MuiSvgIcon-root {
    font-size: 15px;
  }
`

const Heading = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const PassageContainer = styled.span`
  color: ${({ theme }) => theme.palette.grey[800]};
  margin-left: 3px;
`

const StudyBibleItemSearchResultsRow = ({
  id,
  title,
  heading,
  fromLoc,
  toLoc,
  status,
  type,
}) => {

  const { defaultVersionId } = useVersionInfos()
  const { label, color } = GET_STUDY_BIBLE_ITEM_TYPES()[type] || {}

  const statusIcon = {
    "DRAFT-UNLOCKED": <LockOpenIcon />,
    "DRAFT-LOCKED": <LockIcon />,
    "DEFERRED": <SnoozeIcon />,
  }[status] || null

  return (
    <NavLinkOrAWithDisable to={`/study-bible/${id}`}>
      <Container>

        <TitlePlus>

          <Title className="StudyBibleItemSearchResultsRow-Title">
            {title}
          </Title>

          <Type $color={color}>
            {label}
          </Type>

          {statusIcon}

        </TitlePlus>

        <Heading>
          {heading}
          {` `}
          <PassageContainer>
            <PassageRef
              fromLoc={fromLoc}
              toLoc={toLoc}
              convertToVersionId={defaultVersionId}
              abbreviated
            />
          </PassageContainer>
        </Heading>

      </Container>
    </NavLinkOrAWithDisable>
  )
}

export default memo(StudyBibleItemSearchResultsRow)