import { useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import { useMutation, useApolloClient } from '@apollo/client'

import useStickyRefState from './useStickyRefState'
import useSimpleToggle from './useSimpleToggle'
import useEffectAsync from './useEffectAsync'
import useInstanceValue from './useInstanceValue'

import ConfirmDialog from '../components/common/ConfirmDialog'
import PublishMapConfirmInfo from '../components/pages/map/PublishMapConfirmInfo'

import mapLayerQuery from '../graphql/queries/mapLayer'
import publishMapLayerMutation from '../graphql/mutations/publishMapLayer'

const getMapLayerId = ({ layer, stage }) => `mapLayer layer:${layer} stage:${stage}`

// const getBlankMapLayerForTesting = () => ([
//   {
//     __typename: "MapLayer",
//     createdAt: 0,
//     data: {
//       places: [],
//       persons: [],
//       journeys: [],
//     },
//   },
//   () => {},
// ])

const useMapLayers = ({
  layer,
  stage,
  toggleViewDraft,
 }) => {

  const client = useApolloClient()

  const mapLayerInfoIdxById = {}
  let i = 0
  const getMapLayerParams = id => {
    mapLayerInfoIdxById[id] = i++
    return { id, defaultValue: {} }
  }
  const mapLayersInfo = [
    useStickyRefState(getMapLayerParams(`mapLayer layer:BIBLE stage:PUBLISHED`)),
    useStickyRefState(getMapLayerParams(`mapLayer layer:BIBLE stage:DRAFT`)),
    useStickyRefState(getMapLayerParams(`mapLayer layer:KINGDOMS stage:PUBLISHED`)),
    useStickyRefState(getMapLayerParams(`mapLayer layer:KINGDOMS stage:DRAFT`)),
    useStickyRefState(getMapLayerParams(`mapLayer layer:CHURCH-HISTORY stage:PUBLISHED`)),
    useStickyRefState(getMapLayerParams(`mapLayer layer:CHURCH-HISTORY stage:DRAFT`)),
  ]

  // const mapLayersInfo = [
  //   getMapLayerParams(`mapLayer layer:BIBLE stage:PUBLISHED`) || getBlankMapLayerForTesting(),
  //   getMapLayerParams(`mapLayer layer:BIBLE stage:DRAFT`) || getBlankMapLayerForTesting(),
  //   useStickyRefState(getMapLayerParams(`mapLayer layer:KINGDOMS stage:PUBLISHED`)),
  //   useStickyRefState(getMapLayerParams(`mapLayer layer:KINGDOMS stage:DRAFT`)),
  //   useStickyRefState(getMapLayerParams(`mapLayer layer:CHURCH-HISTORY stage:PUBLISHED`)),
  //   useStickyRefState(getMapLayerParams(`mapLayer layer:CHURCH-HISTORY stage:DRAFT`)),
  // ]

  const getMapLayersInfo = useInstanceValue(mapLayersInfo)
  const getMapLayerInfoIdxById = useInstanceValue(mapLayerInfoIdxById)

  const getMapLayerState = useCallback(
    ({ layer, stage }) => {
      const mapLayerId = getMapLayerId({ layer, stage })
      return getMapLayersInfo()[getMapLayerInfoIdxById()[mapLayerId]]
    },
    [ getMapLayersInfo, getMapLayerInfoIdxById ],
  )

  const [ publishMapLayer ] = useMutation(publishMapLayerMutation)

  const [ updating, setUpdating ] = useState(false)
  const [ showConfirmPublish, toggleShowConfirmPublish ] = useSimpleToggle()

  const goPublish = useCallback(
    async () => {

      setUpdating(true)

      const [ mapLayer ] = getMapLayerState({ layer, stage: `DRAFT` })
      const { createdAt } = mapLayer
    
      await publishMapLayer({
        variables: {
          layer,
          createdAt,
        },
      })

      const setPublishedMapLayer = getMapLayerState({ layer, stage: `PUBLISHED` })[1]
      setPublishedMapLayer(mapLayer)

      setUpdating(false)
      toggleShowConfirmPublish()
      toggleViewDraft()

    },
    [ publishMapLayer, layer, getMapLayerState, toggleShowConfirmPublish, toggleViewDraft ],
  )

  useEffectAsync(
    async () => {

      const [ mapLayer ] = getMapLayerState({ layer, stage })

      const { data: { mapLayer: serverMapLayer } } = await client.query({
        query: mapLayerQuery,
        variables: {
          layer,
          stage,
          createdSince: mapLayer.createdAt || 0,
        },
        fetchPolicy: `network-only`,
      })

      if(!serverMapLayer) return

      const mapLayerId = getMapLayerId({ layer, stage })
      const setMapLayer = getMapLayersInfo()[mapLayerInfoIdxById[mapLayerId]][1]
      setMapLayer(serverMapLayer)

    },
    [ layer, stage ],
  )

  return {
    getMapLayerState,
    getMapLayerId,
    toggleShowConfirmPublish,
    confirmDialog: (
      <ConfirmDialog
        open={showConfirmPublish}
        onCancel={toggleShowConfirmPublish}
        onConfirm={goPublish}
        explanation={
          <PublishMapConfirmInfo
            getMapLayerState={getMapLayerState}
            layer={layer}
            getMapLayerId={getMapLayerId}
          />
        }
        confirmButtonLabel={i18n("Publish")}
        loading={updating}
        color="secondary"
      />
    ),
  }
}

export default useMapLayers