import { db } from '../../utils/database'

import { getQueryAndWhere, getSubsetArgs, escapeStrForRegex } from '../../utils/misc'

// Should match its counterpart in biblearc-data
const FLAG_MAP = {
  id: {
    column: "id",
    multiValue: true,
  },
  name: {
    column: "name",
  },
  starred: {
    column: "starred",
    isBoolean: true,
  },
  userId: {
    column: "userId",
  },
  folderId: {
    column: "folderId",
  },
  moduleType: {
    multiValue: true,
    model: "module",
    column: "type",
  },
  deleted: {
    special: "deletedAt",
  },
}

const projects = async args => (

  await db.transaction(
    'r',
    db.projects,
    db.moduleByProjects,
    db.modules,
    db.modulePassages,
    async () => {

      const { query, where } = getQueryAndWhere({
        args,
        FLAG_MAP,
      })

      const { order, offset, limit } = getSubsetArgs({
        args,
        orderOptions: [
          'openedOrModifiedAt DESC',
          'modifiedAt DESC',
          'name',
          'createdAt',
          'createdAt DESC',
        ],
      })

      let projects = db.projects.orderBy(`[userId+${order[0][0].replace(/,/g,'+')}]`)

      if(order[0][1] === 'DESC') {
        projects = projects.reverse()
      }

      Object.values(where).forEach(filterFunc => {
        projects = projects.filter(filterFunc)
      })

      if(query) {
        const queryRegex = new RegExp(`(?:^| )${escapeStrForRegex(query).replace(/ /g, '[^ ]* ')}[^ ]*`, 'i')
        projects = projects.filter(({ name }) => queryRegex.test(name))
      }

      projects = await projects.toArray()
      const count = projects.length
      projects = projects.slice(offset, offset + limit)

      await Promise.all(projects.map(async project => {

        project.moduleByProjects = await db.moduleByProjects
          .where('projectId')
          .equals(project.id)
          .toArray()

        await Promise.all(project.moduleByProjects.map(async moduleByProject => {

          const moduleId = moduleByProject.id.split(':')[0]

          moduleByProject.module = await db.modules.get(moduleId)

          moduleByProject.module.modulePassages = await db.modulePassages
            .where('moduleId')
            .equals(moduleId)
            .sortBy('ordering')

        }))

      }))

      return {
        projects,
        count,
      }

    }
  )

)

export default projects