import projects from './projects'

const project = async args => {

  const { id } = args

  const result = await projects({
    query: `id:"${id}"`,
  })

  return result.projects[0]

}

export default project