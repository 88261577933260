import { useCallback, useContext } from 'react'

import { RecentSearchesContext } from '../context/LocalInfo'
import { cloneObj } from '../utils/misc'
import useInstanceValue from './useInstanceValue'
import { db } from '../utils/database'

const MAX_RECENT_ITEMS = 500

const useRecentSearchManager = () => {

  const recentSearches = useContext(RecentSearchesContext)
  const getRecentSearches = useInstanceValue(recentSearches || [])

  const getRecentSearchSuggestionOptions = useCallback(
    ({ userHasEnteredText }) => {

      if(userHasEnteredText) {
        return getRecentSearches()
      } else {
        const now = Date.now()
        return (
          getRecentSearches()
            .filter(({ lastUsedAt }) => now - lastUsedAt < 1000*60*60*24*30)  // only show the tops items from the last month
        )
      }

    },
    [ getRecentSearches ],
  )

  const addToRecentSearches = useCallback(
    async newSearchInfo => {

      // start with current value of recentSearches
      let recentSearches = cloneObj(getRecentSearches())

      // add to the beginning
      recentSearches.unshift({
        ...newSearchInfo,
        from: "search-history",
        lastUsedAt: Date.now(),
        numUses: 1,
      })

      // filter out if already in the list + update numUses
      const { action, path, url, refs, suggestedQuery } = newSearchInfo
      const newPseudoId = `${action || `basic`}:${JSON.stringify(path || url || refs || suggestedQuery)}`
      recentSearches = recentSearches.filter((recentSearch, idx) => {
        if(idx === 0) return true
        const { action, path, url, refs, suggestedQuery } = recentSearch
        const pseudoId = `${action || `basic`}:${JSON.stringify(path || url || refs || suggestedQuery)}`
        if(pseudoId === newPseudoId) {
          recentSearches[0].numUses += recentSearch.numUses
          return false
        }
        return true
      })

      // remove excess based on lastUsedAt and numUses
      const adjustedLastUsedAts = recentSearches.map(({ lastUsedAt, numUses }, idx) => ({
        at: lastUsedAt + (numUses - 1) * (1000*60*60*24*7),  // a week of sticking power more for each additional use
        idx,
      }))
      adjustedLastUsedAts.sort((a,b) => a.at - b.at)
      for(let idx=0; recentSearches.length - idx > MAX_RECENT_ITEMS; idx++) {
        recentSearches[adjustedLastUsedAts[idx].idx] = null
      }
      recentSearches = recentSearches.filter(Boolean)

      await db.localInfo.put({
        id: 'recentSearches',
        value: recentSearches,
      })

    },
    [ getRecentSearches ],
  )

  const removeFromRecentSearches = useCallback(
    async suggestedQuery => {

      let recentSearches = cloneObj(getRecentSearches())

      recentSearches = recentSearches.filter(recentSearch => recentSearch.suggestedQuery !== suggestedQuery)

      await db.localInfo.put({
        id: 'recentSearches',
        value: recentSearches,
      })

    },
    [ getRecentSearches ],
  )

  return {
    getRecentSearchSuggestionOptions,
    addToRecentSearches,
    removeFromRecentSearches,
  }

}

export default useRecentSearchManager



// {
//   from: "search-history",
//   resultCount: 3,
//   suggestedQuery: "charity",
// },
// {
//   from: "recent-projects",
//   suggestedQuery: "Understanding Calvary",
//   path: "/project/123",
//   action: "open",
// },
// {
//   from: "menu-item",
//   suggestedQuery: "All Courses",
//   url: "https://equip.biblearc.com/blah",
//   action: "open-new-tab",
// },
// {
//   from: "equip-course",
//   suggestedQuery: "The Person of Christ",
//   logo: "EQUIP",
//   url: "https://equip.biblearc.com/blah2",
//   action: "open-new-tab",
// },
// {
//   from: "bible-book",
//   suggestedQuery: "Colossians",
// },
// {
//   from: "passage",
//   suggestedQuery: "Colossians 1 ESV",
//   refs: [{
//     bookId: 1,
//     chapter: 1,
//   }],
//   versionId: 'esv',
//   action: "read",
// },
// {
//   from: "look-up",
//   originalWords: {
//     H43000: {
//       gloss: "an iron bar",
//       lex: "מְטִיל",
//     },
//   },
//   resultCount: 1,
//   suggestedQuery: "#H43000",
// },
// {
//   from: "tags",
//   suggestedQuery: "#calvary",
//   path: "/projects#...",
//   action: "open",
// },