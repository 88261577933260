import { useCallback } from 'react'

import useRefState from './useRefState'

const useMediaSeek = ({
  goSeek,
  focusPlayButton,
  duration,
  clear,
}) => {

  const [ left, setLeft ] = useRefState(null)
  const [ seeking, setSeeking, getSeeking ] = useRefState(false)

  const mouseMoveEvent = useCallback(
    ({ target, clientX }) => {
      const seekContainer = target.closest(`[data-seek-container]`)
      const ignoreSeek = target.closest(`[data-seek-none]`)

      if(!getSeeking()) {
        if(ignoreSeek) {
          setLeft(null)
        } else if(seekContainer) {
          const { left, width } = seekContainer.getBoundingClientRect()
          const newLeft = Math.min(Math.max(clientX - left, 0), width)
          setLeft(newLeft)
        }
      }
    },
    [ setLeft, getSeeking ],
  )

  const mouseLeaveEvent = useCallback(() => setLeft(null), [ setLeft ])

  const mouseDownOrTouchStartEvent = useCallback(
    event => {

      event.preventDefault()

      const seekContainer = event.target.closest(`[data-seek-container]`)
      const { left, width } = seekContainer.getBoundingClientRect()
      const isTouch = (event.touches || []).length > 0

      const move = ({ clientX }) => {
        const newLeft = Math.min(Math.max(clientX - left, 0), width)
        setLeft(newLeft)
        goSeek((newLeft / width) * duration)
      }

      const touchMove = event => {
        event.preventDefault()
        if(event.touches.length !== 1) {
          done()
        } else {
          move(event.touches[0])
        }
      }

      const done = () => {
        if(isTouch) {
          document.body.removeEventListener(`touchmove`, touchMove)
          document.body.removeEventListener(`touchend`, done)
          document.body.removeEventListener(`touchcancel`, done)
        } else {
          document.body.removeEventListener(`mousemove`, move)
          document.body.removeEventListener(`mouseup`, done)
          document.body.removeEventListener(`mouseleave`, done)
        }
        setSeeking(false)
        setLeft(null)
        focusPlayButton()
      }

      if(isTouch) {
        document.body.addEventListener(`touchmove`, touchMove)
        document.body.addEventListener(`touchend`, done)
        document.body.addEventListener(`touchcancel`, done)
      } else {
        document.body.addEventListener(`mousemove`, move)
        document.body.addEventListener(`mouseup`, done)
        document.body.addEventListener(`mouseleave`, done)
      }

      setSeeking(true)
      move(isTouch ? event.touches[0] : event)
      clear()

    },
    [ goSeek, duration, setLeft, setSeeking, focusPlayButton, clear ],
  )

  return {
    onMouseMove: mouseMoveEvent,
    onMouseLeave: mouseLeaveEvent,
    onMouseDown: mouseDownOrTouchStartEvent,
    onTouchStart: mouseDownOrTouchStartEvent,
    hoverProgressProps: (
      !left
        ? null
        : {
          style: {
            left: `${left}px`,
          },
          $seeking: seeking,
        }
    ),
    "data-seek-container": 1,
  }
}

export default useMediaSeek
