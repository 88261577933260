import { gql } from '@apollo/client'

import notificationsDevice_fields from '../fragments/notificationsDevice_fields'

export default gql
`
  mutation updateNotificationsDevice($id: ID!, $input: NotificationsDeviceInput!) {
    updateNotificationsDevice(id: $id, input: $input) {
      ${notificationsDevice_fields}
    }
  }
`