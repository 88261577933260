import { useCallback } from "react"
import { v4 as uuidv4 } from 'uuid'

import { cloneObj } from '../utils/misc'
import useGoUpdateModulePassages from "./useGoUpdateModulePassages"

const useDuplicateModulePassages = ({
  moduleToCopyFrom,
  newModuleId,
  projectId,
}) => {

  const [ goUpdateModulePassages ] = useGoUpdateModulePassages({
    projectId,
    moduleId: newModuleId,
  })

  const goDuplicateModulePassages = useCallback(
    async () => {

      // get all passages
      const { modulePassages } = moduleToCopyFrom

      const modulePassageIdMap = {}

      const newModulePassages = modulePassages.map(row => {
        const newModulePassage = cloneObj(row)
        modulePassageIdMap[row.id] = newModulePassage.id = uuidv4()
        return newModulePassage
      })

      goUpdateModulePassages(newModulePassages)

      return { modulePassageIdMap }

    },
    [ moduleToCopyFrom, goUpdateModulePassages ],
  )

  return goDuplicateModulePassages
}

export default useDuplicateModulePassages