import { memo } from 'react'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import { NavLink } from "react-router-dom"

const StylelessA = styled.a`
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`

const LinkIconButton = ({
  href,
  to,
  ...otherProps
}) => {

  if(to) {
    return (
      <StyledNavLink
        exact
        to={to}
        tabIndex={-1}
      >
        <IconButton
          {...otherProps}
        />
      </StyledNavLink>
    )
  }

  return (
    <StylelessA
      href={href}
      target="_blank"
      rel="noreferrer"
      tabIndex={-1}
    >
      <IconButton
        {...otherProps}
      />
    </StylelessA>
  )
}

export default memo(LinkIconButton)