import { memo, useMemo, useState } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useDefinitions from '../../hooks/useDefinitions'
import { getGrammarColorCSSRules } from '../../utils/misc'

import OriginalLanguageWordInfo from '../common/OriginalLanguageWordInfo'
import MorphInfo from './MorphInfo'
import Loading from '../common/Loading'
import OriginalWithInlineDefs from './OriginalWithInlineDefs'
import ClickWordForMoreInfo from './ClickWordForMoreInfo'

export const GRAMMAR_COLOR_BRIGHTNESS = 1.2

const Container = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${getGrammarColorCSSRules({ filter: `brightness(${GRAMMAR_COLOR_BRIGHTNESS * 100}%)` })}
`

const StyledLoading = styled(Loading)`
  background: none;
  position: relative;
  padding: 100px 0;
  margin-bottom: 38px;
`

const VerseNumberPopperContentInline = ({
  contextRef,
  compareOrigTexts,
  tagSetStatus,
  originalRefs,
  originalPieces,
  originalWordPieces,
  loading,
  apparatusItems,
  infoByWordIdAndPartNumber,
  showGloss,
  setShowGloss,
  versionId,
}) => {

  const [ selectedOriginalWordId, setSelectedOriginalWordId ] = useState()

  const [ definitionsByStrong, loadingDefinitions ] = useDefinitions(originalWordPieces)


  const filteredPieces = useMemo(
    () => (originalPieces || []).filter(({ type, text, content, children }) => type === `word` || text || content || children),
    [ originalPieces ],
  )

  // const ids = {}
  // const translationWordNumbers = []
  // highlightedTags.forEach(({ o, t }) => {
  //   const colors = []
  //   o.forEach(idWithPart => {
  //     const el = containerRef.current.querySelector(`[data-word-part="${idWithPart}"]`)
  //     if(el) {
  //       const color = el.getAttribute(`data-color`)
  //       if(color) colors.push(color)
  //     }
  //   })
  //   t.forEach(tWordNum => {
  //     ids[`${versionId}_${loc}:${tWordNum}`] = colors.length === 1 ? colors[0] : null
  //     translationWordNumbers.push(tWordNum)
  //   })
  // })

  const wordInfo = useMemo(
    () => {
      if(!originalWordPieces || !selectedOriginalWordId) return null
      const word = originalWordPieces.find(piece => piece[`x-id`] === selectedOriginalWordId)
      if(!word) return null
      const { lemma, strong, morph, text, children } = word
      const form = text || (children || []).map(({ text }) => text).join(``)
      return {
        lemma,
        strong,
        morph,
        form,
      }
    },
    [ originalWordPieces, selectedOriginalWordId ],
  )

  const ready = !loading && !loadingDefinitions

  return (
    <Container>

      {!ready && <StyledLoading size={30} />}

      {ready &&
        <>

          <OriginalWithInlineDefs
            originalPieces={filteredPieces}
            selectedOriginalWordId={selectedOriginalWordId}
            contextRef={originalRefs[0]}
            selectWord={setSelectedOriginalWordId}
            showGloss={showGloss || [ undefined, `automatch`, `none` ].includes(tagSetStatus)}
            definitionsByStrong={definitionsByStrong}
            infoByWordIdAndPartNumber={infoByWordIdAndPartNumber}
          />

          <MorphInfo
            {...(wordInfo || {})}
            hasHeader
          />

          {!wordInfo &&
            <ClickWordForMoreInfo
              contextRef={contextRef}
              compareOrigTexts={compareOrigTexts}
              apparatusItems={apparatusItems}
              tagSetStatus={tagSetStatus}
              showGloss={showGloss}
              setShowGloss={setShowGloss}
              versionId={versionId}
            />
          }

          {!!wordInfo &&
            <OriginalLanguageWordInfo
              {...wordInfo}
              contextRef={originalRefs[0]}
              // basicInfoOnly
            />
          }

        </>
      }

    </Container>
  )
}

export default memo(VerseNumberPopperContentInline)