import React from "react"

class ATag extends React.PureComponent {

  render() {
    const { contentState, entityKey, children } = this.props

    const entity = contentState.getEntity(entityKey)
    const { url, target, openInNewTab } = entity.getData()

    return (
      <a
        href={url || "about:blank"}
        target={openInNewTab ? "_blank" : target}
      >
        {children}
      </a>
    )
  }
}

export default ATag