import { memo } from 'react'
import styled from 'styled-components'
// import SearchIcon from '@material-ui/icons/Search'
// import Button from '@material-ui/core/Button'
// import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Container = styled.div`
  padding: 25px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.3;
`

// const DividerLine = styled.div`
//   align-self: stretch;
//   margin-top: 25px;
//   height: 1px;
//   background-image: linear-gradient(to right, rgb(255 255 255/0) 0%, rgb(255 255 255/1) 50%, rgb(255 255 255/0) 100%);
// `

// const Lead = styled.div`
//   font-weight: 300;
//   font-size: 11px;
// `

// const Substance = styled.div`
//   font-size: 19px;
//   font-weight: bold;
//   margin-bottom: 5px;
//   color: ${({ $color }) => $color || `black`};
// `

// const Action = styled.div`
// `

// const StyledButton = styled(Button)`
//   text-transform: none;
// `

// const StyledSearchIcon = styled(SearchIcon)`
//   color: ${({ theme }) => theme.palette.grey[700]};
//   width: 17px;
//   height: 17px;
//   margin: 0 3px;
// `

/*
  Announcement slot (use alerts to control)
    - Can also contain svg or png instead of text
*/

const HomeWhatsNew = () => {

  return (
    <Container>
      
      {/* TODO: Get this from alerts */}

      {/* <Lead>
        New on Biblearc EQUIP...
      </Lead>

      <Substance
        $color={`#660000`}
      >
        2023 LIVE Tracks
      </Substance>

      <Action>
        <NavLinkOrAWithDisable to="https://equip.biblearc.com/blog-post/new-on-biblearc-equip-2023-live-tracks">
          <StyledButton
            size="small"
            variant="outlined"
          >
            Learn More
          </StyledButton>
        </NavLinkOrAWithDisable>
      </Action>

      <DividerLine /> */}

    </Container>
  )
}

export default memo(HomeWhatsNew)