import { memo } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

const Container = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

const StyledButton = styled(Button)`
  padding: 10px 1px;
  min-width: auto;
  min-height: 64px;
  margin: 3px 5px;
  text-transform: none;
  border-color: ${({ theme }) => theme.palette.divider};
  color: ${({ theme }) => theme.palette.grey[900]};
  flex-direction: column;

  .MuiButton-label {
    writing-mode: vertical-rl;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const MinimizedPanel = ({
  buttonsOnTop,
  buttonsInfo,
  ...otherProps
}) => {

  return (
    <Container {...otherProps} >

      {buttonsOnTop}

      {buttonsInfo.map(({ label, onClick }, idx) => (
        <StyledButton
          key={idx}
          variant="outlined"
          size="small"
          onClick={onClick}
        >
          {label}
        </StyledButton>
      ))}

    </Container>
  )
}

export default memo(MinimizedPanel)