import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { useToggle } from 'react-use'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { NavLink } from "react-router-dom"

import { IS_EMBED, MINIMUM_MEDIUM_WIDTH } from '../../utils/constants'

import DetailAccordion from './DetailAccordion'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'

const Container = styled.div`
  padding: 15px;
  font-size: 13px;
  max-width: 400px;
  position: relative;

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
    max-width: none;
  }
`

const AbbrContainer = styled(Container)`
  display: flex;
  gap: 10px;
`

const AbbrContainerLeft = styled.div`
  flex: 1;
`

const Heading = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
`

const Tip = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
`

const TipTitle = styled.div`
  color: ${({ theme }) => theme.palette.grey[800]};
`

const ExampleLine = styled.div`
  margin: 8px 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`

const Example = styled.div`
  margin: 3px 7px 0;
  align-self: flex-start;
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 12px;
  z-index: 1;
  position: relative;
`

const ExampleContent = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 3px;
  padding: 1px 6px;
  color: ${({ theme }) => theme.palette.grey[900]};
`

const LearnMore = styled.div`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.grey[500]};
  transition: color .15s ease-in-out;
  font-size: 12px;
  margin: 2px 4px;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: black;
    }
  }
`

const ExampleSecondLineExplanation = styled.div`
  margin: 3px 0 5px 19px;
  line-height: 1.2;
  font-size: 11px;
`

const NoDetailsSpacer = styled.div`
  height: 7px;
`

const Detail = styled.div`
  color: ${({ theme }) => theme.palette.grey[600]};
  margin: 0 0 10px 26px;
  font-size: 12px;
`

const Version = styled.span`
  color: ${({ theme }) => theme.palette.grey[600]};
`

const HashOrSlash = styled.span`
  color: ${({ theme }) => theme.palette.grey[500]};
`

const QuotationMark = styled.span`
  color: rgb(40 174 39);
`

const DotOrPlaceholderStar = styled.span`
  color: rgb(40 174 39);
`

const ParenthasesOrSlash = styled.span`
  color: rgb(15 106 186);
`

const Star = styled.span`
  color: rgb(230 151 5);
`

const Flag = styled.span`
  color: ${({ theme }) => theme.palette.grey[600]};
`

const NoteAboutDetails = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 11px;
  margin: 0 0 7px 26px;
  font-style: italic;
`

const FinalNote = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 12px;
`

const LinkKeyBlock = styled.div`
  margin: 3px 7px;
  font-size: 11px;
`

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.palette.grey[500]};
`

const StyledDetailAccordion = styled(DetailAccordion)`
  && {
    margin: 0;
  }

  .DetailAccordion-StyledAccordionSummary {
    margin: 0;
  }
`

const miniCourseImgSize = 160

const MiniCourseLink1 = styled(NavLinkOrAWithDisable)`
  transition: transform .15s ease-in-out;
  transform-origin: ${miniCourseImgSize}px ${miniCourseImgSize}px;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.5);
    }
  }

  @media (max-width: 399px) {
    display: none;
  }

`

const MiniCourseLink2 = styled(MiniCourseLink1)`
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
  transform-origin: ${miniCourseImgSize}px 0px;
`

const MiniCourseImg = styled.img`
  width: ${miniCourseImgSize}px;
  max-width: 100%;
  vertical-align: middle;
`

const SearchInstructions = ({
  abbreviated,
}) => {

  const [ viewAbbreviated, toggleViewAbbreviated ] = useSimpleToggle(abbreviated)

  const [ searchBibleWordExpanded, toggleSearchBibleWordExpanded ] = useToggle(false)
  const goToggleSearchBibleWordExpanded = useCallback(() => toggleSearchBibleWordExpanded(), [ toggleSearchBibleWordExpanded ])

  const [ searchOrigLangExpanded, toggleSearchOrigLangExpanded ] = useToggle(false)
  const goToggleSearchOrigLangExpanded = useCallback(() => toggleSearchOrigLangExpanded(), [ toggleSearchOrigLangExpanded ])

  const [ searchWordCombosExpanded, toggleSearchWordCombosExpanded ] = useToggle(false)
  const goToggleSearchWordCombosExpanded = useCallback(() => toggleSearchWordCombosExpanded(), [ toggleSearchWordCombosExpanded ])

  if(viewAbbreviated) {
    return (
      <AbbrContainer>
        <AbbrContainerLeft>

          <Heading>
            {i18n("Examples")}
          </Heading>

          <ExampleLine>

            <ExampleContent>
              {i18nReact("1 John 1:9 {{version}}", {
                version: (
                  <Version>
                    {i18n("NASB")}
                  </Version>
                ),
              })}
            </ExampleContent>
            <ExampleContent>
              {i18nReact("love {{flag}}", {
                flag: (
                  <Flag>
                    {i18n("in:1Jn")}
                  </Flag>
                ),
              })}
            </ExampleContent>
            <ExampleContent>
              {i18nReact("{{quotation_mark}}because {{ellipsis}} love{{quotation_mark}}", {
                quotation_mark: <QuotationMark>"</QuotationMark>,
                ellipsis: <DotOrPlaceholderStar>...</DotOrPlaceholderStar>
              })}
            </ExampleContent>
            <ExampleContent>
              <HashOrSlash>#</HashOrSlash>elohiym
            </ExampleContent>
            <ExampleContent>
              <HashOrSlash>#</HashOrSlash>πιστεύω
            </ExampleContent>
            <ExampleContent>
              <HashOrSlash>#</HashOrSlash>
              {i18n("{{strongs}}", {
                strongs: "G41000",
              })}
              <HashOrSlash>#</HashOrSlash>
              {i18n("aorist")}
            </ExampleContent>
            <ExampleContent>
              {i18nReact("{{quotation_mark}}word{{star}} of {{left_parentases}}{{quotation_mark}}the lord{{quotation_mark}} {{slash}} god{{right_parentases}}{{quotation_mark}}", {
                quotation_mark: <QuotationMark>"</QuotationMark>,
                left_parentases: <ParenthasesOrSlash>(</ParenthasesOrSlash>,
                right_parentases: <ParenthasesOrSlash>)</ParenthasesOrSlash>,
                slash: <ParenthasesOrSlash>/</ParenthasesOrSlash>,
                star: <Star>*</Star>,
              })}
            </ExampleContent>

            <LearnMore
              onClick={toggleViewAbbreviated}
            >
              {i18n("See search tips")}
            </LearnMore>

          </ExampleLine>

        </AbbrContainerLeft>

        {!IS_EMBED &&
          <MiniCourseLink1
            to="https://equip.biblearc.com/course/searching"
          >
            <MiniCourseImg
              src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1723477050240-SearchingMiniCourse4xtiny.jpeg"
              className="dark-mode-exempt"
            />
          </MiniCourseLink1>
        }

      </AbbrContainer>
    )
  }

  return (
    <Container>

      <Heading>
        {i18n("Search tips")}
      </Heading>

      <Tip>
        <TipTitle>
          {i18n("Look-up a passage")}
        </TipTitle>
        <Example>
          {i18nReact("E.g. {{example}}", {
            example: (
              <ExampleContent>
                {i18n("John 3")}
              </ExampleContent>
            ),
          })}
        </Example>
        <Example>
          {i18nReact("E.g. {{example}}", {
            example: (
              <ExampleContent>
                {i18nReact("1 John 1:9 {{version}}", {
                  version: (
                    <Version>
                      {i18n("NASB")}
                    </Version>
                  ),
                })}
              </ExampleContent>
            ),
          })}
        </Example>
        <NoDetailsSpacer />
      </Tip>

      <Tip>
        <TipTitle>
          {i18n("Search the Bible for a word")}
        </TipTitle>
        <Example>
          {i18nReact("E.g. {{example}}", {
            example: (
              <ExampleContent>
                {i18n("love")}
              </ExampleContent>
            ),
          })}
        </Example>
        <StyledDetailAccordion
          expanded={searchBibleWordExpanded}
          onChange={goToggleSearchBibleWordExpanded}
          summary={i18n("Advanced")}
          details={
            <>
              <Detail>
                {i18n("Words that start a certain way")  }
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("lov{{star}}", {
                          star: <Star>*</Star>,
                        })}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds love, loves, loving, etc."),
                  })}
                </Example>
              </Detail>
              <Detail>
                {i18n("Specific version(s)")}
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("love {{flag}}", {
                          flag: (
                            <Flag>
                              {i18n("in:ESV,NASB")}
                            </Flag>
                          ),
                        })}
                      </ExampleContent>
                    ),
                  })}
                </Example>
              </Detail>
              <Detail>
                {i18n("Specific book(s) of the Bible")}
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("love {{flag}}", {
                          flag: (
                            <Flag>
                              {i18n("in:1Jn")}
                            </Flag>
                          ),
                        })}
                      </ExampleContent>
                    ),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("love {{flag}}", {
                          flag: (
                            <Flag>
                              {i18n("in:prophets")}
                            </Flag>
                          ),
                        })}
                      </ExampleContent>
                    ),
                  })}
                </Example>
              </Detail>
            </>
          }
        />
      </Tip>

      <Tip>
        <TipTitle>
          {i18n("Search the Bible in its original languages")}
        </TipTitle>
        <Example>
          {i18nReact("E.g. {{example}} {{explanation}}", {
            example: (
              <ExampleContent>
                <HashOrSlash>#</HashOrSlash>elohiym
              </ExampleContent>
            ),
            explanation: i18n("finds {{word}}", {
              word: "אֱלֹהִים",
            }),
          })}
        </Example>
        <Example>
          {i18nReact("E.g. {{example}} {{explanation}}", {
            example: (
              <ExampleContent>
                <HashOrSlash>#</HashOrSlash>G30560
              </ExampleContent>
            ),
            explanation: i18n("finds {{word}}", {
              word: "λόγος",
            }),
          })}
        </Example>
        <StyledDetailAccordion
          expanded={searchOrigLangExpanded}
          onChange={goToggleSearchOrigLangExpanded}
          summary={i18n("Advanced")}
          details={
            <>
              <Detail>
                {i18n("Type # plus the original language word")}
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>πιστεύω
                      </ExampleContent>
                    ),
                  })}
                </Example>
              </Detail>
              <Detail>
                {i18n("Type # plus a grammatical detail")}
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("noun")}
                      </ExampleContent>
                    ),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("noun")}
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("feminine")}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds feminine nouns"),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("{{strongs}}", {
                          strongs: "G41000",
                        })}
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("aorist")}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds {{word}} in the aorist", {
                      word: "πιστεύω",
                    }),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>
                        {i18nReact("aorist{{slash}}imperfect", {
                          slash: <HashOrSlash>/</HashOrSlash>
                        })}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds aorists and imperfects"),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("verb")}
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("not:indicative")}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds non-indicative verbs"),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("b")}
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("noun")}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds nouns with a ב prepositional prefix"),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("verb")}
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("h->")}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds verbs with a directional ה"),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("suffix:3ms")}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds words with a 3ms suffix"),
                  })}
                </Example>
                <LinkKeyBlock>
                  <StyledNavLink exact to="/grammar-search-key">
                    {i18n("Grammatical Detail Search Key")}
                  </StyledNavLink>
                </LinkKeyBlock>
              </Detail>
              {/* <Detail>
                {i18n("Include synonyms in the results")}
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>G41000+
                      </ExampleContent>
                    ),
                    explanation: i18n("finds {{word}} and its synonyms", {
                      word: "πιστεύω",
                    }),
                  })}
                </Example>
              </Detail>
              <Detail>
                {i18n("Include related words in the results")}
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>G41000~
                      </ExampleContent>
                    ),
                    explanation: i18n("finds {{word}} and related words", {
                      word: "πιστεύω",
                    }),
                  })}
                </Example>
              </Detail>
              <Detail>
                {i18n("Search all original language words with a particular translation")}
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        {i18n("=love")}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds words sometimes translated “love”"),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        {i18n("=lov*")}
                      </ExampleContent>
                    ),
                    explanation: i18n("also includes words translated loves, loving, etc."),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        {i18n("=love[ESV]")}
                      </ExampleContent>
                    ),
                    explanation: i18n("considers only the ESV translation"),
                  })}
                </Example>
              </Detail>
              <Detail>
                {i18n("Search the LXX")}
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        <HashOrSlash>#</HashOrSlash>
                        {i18n("{{strongs}} in:{{version}}", {
                          strongs: "G41000",
                          version: "LXX",
                        })}
                      </ExampleContent>
                    ),
                  })}
                </Example>
              </Detail> */}
            </>
          }
        />
      </Tip>

      <Tip>
        <TipTitle>
          {i18n("Search word combos")}
        </TipTitle>
        <Example>
          {i18nReact("E.g. {{example}} {{explanation}}", {
            example: (
              <ExampleContent>
                {i18n("grace truth")}
              </ExampleContent>
            ),
            explanation: i18n("finds verses with both words"),
          })}
        </Example>
        <Example>
          {i18nReact("E.g. {{example}} {{explanation}}", {
            example: (
              <ExampleContent>
                {i18nReact("{{quotation_mark}}{{hash}}{{strongs}} {{hash}}accusative{{quotation_mark}}", {
                  quotation_mark: <QuotationMark>"</QuotationMark>,
                  hash: <HashOrSlash>#</HashOrSlash>,
                  strongs: "G43140",
                })}
              </ExampleContent>
            ),
            explanation: i18n("finds {{word}} + accusative", {
              word: "πρός",
            }),
          })}
        </Example>
        <StyledDetailAccordion
          expanded={searchWordCombosExpanded}
          onChange={goToggleSearchWordCombosExpanded}
          summary={i18n("Advanced")}
          details={
            <>
              <NoteAboutDetails>
                {i18n("Note: Everything but scope works also with original languages.")}
              </NoteAboutDetails>
              <Detail>
                {i18n("Exact phrases")}
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("{{quotation_mark}}love of god{{quotation_mark}}", {
                          quotation_mark: <QuotationMark>"</QuotationMark>,
                        })}
                      </ExampleContent>
                    ),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("{{quotation_mark}}cut {{star}} off{{quotation_mark}}", {
                          quotation_mark: <QuotationMark>"</QuotationMark>,
                          star: <DotOrPlaceholderStar>*</DotOrPlaceholderStar>
                        })}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds “cut them/me/him off”"),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("{{quotation_mark}}because {{ellipsis}} love{{quotation_mark}}", {
                          quotation_mark: <QuotationMark>"</QuotationMark>,
                          ellipsis: <DotOrPlaceholderStar>...</DotOrPlaceholderStar>
                        })}
                      </ExampleContent>
                    ),
                    explanation: i18n("(“followed by” search)"),
                  })}
                  <ExampleSecondLineExplanation>
                    {i18n("Finds “because your steadfast love”")}
                    <br />
                    {i18n("Does NOT find “I love the Lord because”")}
                  </ExampleSecondLineExplanation>
                </Example>
              </Detail>
              <Detail>
                {i18n("One word or another")}
                <Example>
                  {i18nReact("E.g. {{example}} {{explanation}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("faith {{slash}} belief", {
                          star: <Star>*</Star>,
                          slash: <ParenthasesOrSlash>/</ParenthasesOrSlash>
                        })}
                      </ExampleContent>
                    ),
                    explanation: i18n("finds faith, belief"),
                  })}
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("{{two_plus}} faith hope love", {
                          two_plus: <ParenthasesOrSlash>2+</ParenthasesOrSlash>,
                        })}
                      </ExampleContent>
                    ),
                  })}
                  <ExampleSecondLineExplanation>
                    {i18n("Finds verses with at least two of these words")}
                  </ExampleSecondLineExplanation>
                </Example>
              </Detail>
              <Detail>
                {i18n("Changing the scope")}
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("god love {{flag}}", {
                          flag: (
                            <Flag>
                              {i18n("same:phrase")}
                            </Flag>
                          ),
                        })}
                      </ExampleContent>
                    ),
                  })}
                  <ExampleSecondLineExplanation>
                    {i18n("Finds “love of God”")}
                    <br />
                    {i18n("Does NOT find “love the brotherhood, fear God”")}
                  </ExampleSecondLineExplanation>
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("god love {{flag}}", {
                          flag: (
                            <Flag>
                              {i18n("same:sentence")}
                            </Flag>
                          ),
                        })}
                      </ExampleContent>
                    ),
                  })}
                  <ExampleSecondLineExplanation>
                    {i18n("Finds “I thank God... [5] because of your love”")}
                  </ExampleSecondLineExplanation>
                </Example>
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("god love {{flag}}", {
                          flag: (
                            <Flag>
                              {i18n("same:paragraph")}
                            </Flag>
                          ),
                        })}
                      </ExampleContent>
                    ),
                  })}
                  <ExampleSecondLineExplanation>
                    {i18n("Finds “Blessed be God... In love...”")}
                  </ExampleSecondLineExplanation>
                </Example>
              </Detail>
              <Detail>
                {i18n("Complex searches")}
                <Example>
                  {i18nReact("E.g. {{example}}", {
                    example: (
                      <ExampleContent>
                        {i18nReact("{{quotation_mark}}word{{star}} of {{left_parentases}}{{quotation_mark}}the lord{{quotation_mark}} {{slash}} god{{right_parentases}}{{quotation_mark}}", {
                          quotation_mark: <QuotationMark>"</QuotationMark>,
                          left_parentases: <ParenthasesOrSlash>(</ParenthasesOrSlash>,
                          right_parentases: <ParenthasesOrSlash>)</ParenthasesOrSlash>,
                          slash: <ParenthasesOrSlash>/</ParenthasesOrSlash>,
                          star: <Star>*</Star>,
                        })}
                      </ExampleContent>
                    ),
                  })}
                  <ExampleSecondLineExplanation>
                    {i18n("Finds “word(s) of God” and “word(s) of the Lord”")}
                  </ExampleSecondLineExplanation>
                </Example>
              </Detail>
            </>
          }
        />
      </Tip>

      {!IS_EMBED &&
        <FinalNote>
          {/* {i18n("Search results also include your projects, highlights, alerts, Study Bible notes, Bible versions, online courses, learning resources, help topics, and others’ work shared with you.")} */}
          {i18n("Search results also include your projects.")}
        </FinalNote>
      }

      {!IS_EMBED &&
        <MiniCourseLink2
          to="https://equip.biblearc.com/course/searching"
        >
          <MiniCourseImg
            src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1723477050240-SearchingMiniCourse4xtiny.jpeg"
            className="dark-mode-exempt"
          />
        </MiniCourseLink2>
      }

    </Container>
  )
}

export default memo(SearchInstructions)