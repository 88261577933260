import { useCallback } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'
import { cloneObj } from '../utils/misc'

import formattingKeysQuery from '../graphql/queries/formattingKeys'
import updateFormattingKeyMutation from '../graphql/mutations/updateFormattingKey'
import deleteFormattingKeyMutation from '../graphql/mutations/deleteFormattingKey'

export const getDefaultFormattingKey = () => ({
  __typename: `FormattingKey`,
  name: "",
  type: "MARKUP",
  info: {},
  public: false,
})

const useGoUpdateFormattingKey = ({
  formattingKey,
  undoRedoStack,
}) => {

  const client = useApolloClient()

  const [ updateFormattingKey, updateFormattingKeyResult ] = useMutation(updateFormattingKeyMutation)
  const [ deleteFormattingKey, deleteFormattingKeyResult ] = useMutation(deleteFormattingKeyMutation)

  const onUpdate = useCallback(
    ({ newData }) => {
      // update formattingKeys query

      let { formattingKeys } = client.readQuery({
        query: formattingKeysQuery,
      }) || {}

      if(
        formattingKeys
        && !formattingKeys.some(({ id }) => id === newData.id)
      ) {
        client.writeQuery({
          query: formattingKeysQuery,
          data: {
            formattingKeys: [
              ...cloneObj(formattingKeys),
              newData,
            ],
          },
        })
      }

    },
    [ client ],
  )

  const goUpdate = useGoUpdateTable({
    currentData: formattingKey || getDefaultFormattingKey(),
    updateFunc: updateFormattingKey,
    updateResult: updateFormattingKeyResult,
    deleteFunc: deleteFormattingKey,
    deleteResult: deleteFormattingKeyResult,
    undoRedoStack,
    onUpdate,
  })

  return goUpdate
}

export default useGoUpdateFormattingKey