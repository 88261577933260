import * as Sentry from '@sentry/react'

import { db } from '../../utils/database'
import { pascalToCamelCase } from '../../utils/misc'

const saveDataToDexie = async ({
  data,
  dexieRowLimit,
}) => {

  if(!data) return

  const __typename = data.__typename || (data[0] || {}).__typename

  if(!__typename) return

  const operationsByTableName = {}
  const tables = []

  const addOperations = ({ tableName, operation, dataArray }) => {

    if(!dataArray || dataArray.length === 0) return

    operationsByTableName[tableName] = operationsByTableName[tableName] || {}
    operationsByTableName[tableName][operation] = operationsByTableName[tableName][operation] || []
    operationsByTableName[tableName][operation].push(...dataArray)

    if(!tables.includes(db[tableName])) {
      tables.push(db[tableName])
    }

  }

  if(__typename === `User`) {

    // Shouldn't get here, but just in case
    const error = `saveDataToDexie called on data with __typename === 'User'`
    console.error(error, data)
    Sentry.captureException(
      error,
      {
        extra: {
          data,
        },
      }
    )

  } if(__typename === `UserUpdate`) {
    for(let tableNameInSingular in data) {

      if([ 'moreToGet', 'newUpdatedSince' ].includes(tableNameInSingular)) continue
      if(!data) continue

      const { rows, deletedIds } = data[tableNameInSingular] || {}
      const tableName = `${tableNameInSingular}s`

      addOperations({
        tableName,
        operation: `bulkPut`,
        dataArray: rows,
      })

      addOperations({
        tableName,
        operation: `bulkDelete`,
        dataArray: deletedIds,
      })

    }

  } else if(/Set$/.test(__typename)) {

    const tableName = `${pascalToCamelCase(__typename.replace(/Set$/, ''))}s`
    addOperations({
      tableName,
      operation: `bulkPut`,
      dataArray: data[tableName],
    })

  } else if(data instanceof Array) {

    const tableName = `${pascalToCamelCase(__typename)}s`
    addOperations({
      tableName,
      operation: `bulkPut`,
      dataArray: data,
    })

  } else {

    const tableName = `${pascalToCamelCase(__typename)}s`
    addOperations({
      tableName,
      operation: `bulkPut`,
      dataArray: [data],
    })

  }

  if(tables.length === 0) return

  await db.transaction(
    'rw',
    tables,
    async () => {

      for(let tableName in operationsByTableName) {
        for(let operation in operationsByTableName[tableName]) {
          // following run in parallel with the rest given that there is no await
          db[tableName][operation](operationsByTableName[tableName][operation])
        }
      }

      if(dexieRowLimit) {
        for(let table of tables) {
          const numRows = await table.count()
          const numToDelete = numRows - dexieRowLimit
          if(numToDelete > 0) {
            // following run in parallel with the rest given that there is no await
            table
              .orderBy('createdAt')
              .limit(numToDelete)
              .delete()
          }
        }
      }
    },
  )

  // Possibilities covered:

    // UserUpdate
    //   folder: FolderUpdate
    //   project: ProjectUpdate
    //   tag: TagUpdate
    //   moduleByProject: ModuleByProjectUpdate
    //   module: ModuleUpdate
    //   modulePassage: ModulePassageUpdate
    //   moduleSetting: ModuleSettingUpdate
    //   modulePiece: ModulePieceUpdate
    //   moduleDot: ModuleDotUpdate
    //   moduleMarkup: ModuleMarkupUpdate
    //   formattingKey: FormattingKeyUpdate
    //   highlight: HighlightUpdate
    //     rows: [Highlight]
    //     deletedIds: [String]

    // [AccountSetting]
    // AlertsItem
    // AlertsItemSet.alertsItems:[AlertsItem]
    // Version
    // VersionSet.versions:[Version]

    // logProjectPassageHistoryItem
    // logQueryItem

}

export default saveDataToDexie