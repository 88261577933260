import React, { useMemo, useCallback } from 'react'

import useRefState from '../hooks/useRefState'
import useSetTimeout from '../hooks/useSetTimeout'

export const StudyBibleItemInfoContext = React.createContext({})

export const StudyBibleItemInfoContextProvider = ({ children }) => {

  const [ recentlyClosedNote, setRecentlyClosedNote ] = useRefState(false)
  const [ setRecentlyClosedNoteTimeout ] = useSetTimeout()

  const [ studyBibleItemInfo, setStudyBibleItemInfo, getStudyBibleItemInfo ] = useRefState({})
  const closeStudyBibleItemInfo = useCallback(
    ({ keyOverride }={}) => {
      const studyBibleItemInfo = getStudyBibleItemInfo()
      setStudyBibleItemInfo({
        ...studyBibleItemInfo,
        anchorEl: null,
        goStraightToEditor: false,
        keyOverride,
      })
      studyBibleItemInfo.onClose && studyBibleItemInfo.onClose(studyBibleItemInfo)
      if(((studyBibleItemInfo.highlight || {}).info || {}).type === `NOTE`) {
        setRecentlyClosedNote(true)
        setRecentlyClosedNoteTimeout(() => setRecentlyClosedNote(false), 1000*8)
      }
    },
    [ setStudyBibleItemInfo, getStudyBibleItemInfo, setRecentlyClosedNote, setRecentlyClosedNoteTimeout ],
  )

  const value = useMemo(
    () => ({
      studyBibleItemInfo: {
        ...studyBibleItemInfo,
        onClose: closeStudyBibleItemInfo,
      },
      setStudyBibleItemInfo,
      getStudyBibleItemInfo,
      closeStudyBibleItemInfo,
      recentlyClosedNote,
    }),
    [ studyBibleItemInfo, setStudyBibleItemInfo, getStudyBibleItemInfo, closeStudyBibleItemInfo, recentlyClosedNote ]
  )

  return (
    <StudyBibleItemInfoContext.Provider value={value}>
      {children}
    </StudyBibleItemInfoContext.Provider>
  )
}