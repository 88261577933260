import { gql } from '@apollo/client'

const stripeCurrentSale = gql`
  query stripeCurrentSale {
    stripeCurrentSale {
      id
      name
      metadata
      amountOff
      percentOff
      created
      redeemBy
      duration
      durationInMonths
      maxRedemptions
      timesRedeemed
    }
  }
`

export default stripeCurrentSale