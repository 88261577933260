import { memo, useContext } from 'react'
import { createGlobalStyle } from 'styled-components'

import { DarkModeContext } from '../../context/LocalInfo'
import { IS_EMBED } from '../../utils/constants'
import useEffectAsync from '../../hooks/useEffectAsync'
import { getLocalStorage, setLocalStorage } from '../../utils/misc'
import useThemeColor from '../../hooks/useThemeColor'
import { getBaseHighlightBGImage } from '../markup/BaseHighlight'

const darkModeSelectors = [
  `.Routes-AppContainer > :not(.dark-mode-exempt)`,
  `body > .MuiDrawer-root .MuiDrawer-paper`,
  `body > .MuiDialog-root .MuiDialog-paper`,
  `body > .MuiPopover-root`,
  `body > .options-popper:not(.dark-mode-exempt)`,
  `.SearchModal-Container`,
]

const darkModeStyle = `

  body {
    background: black;
  }

  ${darkModeSelectors.join(`,`)} {
    filter: invert(1) hue-rotate(180deg) contrast(.7);
  }

  ${darkModeSelectors.map(selector => `${selector} .dark-mode-exempt`).join(`,`)},
  iframe[src*="vimeo.com"],
  iframe[src*="youtube"],
  iframe[src*="youtu.be"],
  iframe[src*="sermonaudio"]:not([src*="/a/"]) {
    filter: invert(1) hue-rotate(180deg) contrast(1.1) saturate(1.3);
  }

  .dark-mode-exempt:fullscreen,
  iframe[src*="vimeo.com"]:fullscreen,
  iframe[src*="youtube"]:fullscreen,
  iframe[src*="youtu.be"]:fullscreen,
  iframe[src*="sermonaudio"]:not([src*="/a/"]):fullscreen {
    filter: none;
  }

  .dark-mode-exempt.dark-mode-exempt iframe {
    filter: none;
  }

  .ChannelItemSnackbar .MuiSnackbarContent-message:focus-within {
    box-shadow: 0px 3px 12px -1px rgba(0,0,0,0.3),0px 6px 20px 0px rgba(0,0,0,0.2),0px 1px 36px 0px rgba(0,0,0,0.18);
  }

  .MuiBackdrop-root {
    background-color: rgb(38 38 38/.2);
    backdrop-filter: blur(4px);
  }

  .dark-mode-box-shadow {
    box-shadow: 0 5px 15px -3px rgb(255 255 255 / 60%), 0 8px 17px 1px rgb(255 255 255 / 54%), 0 3px 18px 2px rgb(255 255 255 / 22%);
  }

  .dark-mode-exempt .dark-mode-box-shadow {
    box-shadow: 0 5px 15px -3px rgb(0 0 0 / 60%), 0 8px 17px 1px rgb(0 0 0 / 54%), 0 3px 18px 2px rgb(0 0 0 / 22%);
  }

  .dark-mode-box-shadow-lite,
  .MuiPaper-elevation8 {
    box-shadow: 0 1px 25px -3px rgb(0 0 0 / 7%), 0 2px 27px 1px rgb(0 0 0 / 3%), 0 0 28px 2px rgb(0 0 0 / 2%);
  }

  .MuiPaper-elevation6 {
    box-shadow: 0 0 30px -3px rgb(0 0 0 / 12%), 0 0 31px 1px rgb(0 0 0 / 7%), 0 0 32px 2px rgb(0 0 0 / 4%);
  }

  .TextContent-tag-v, .TextContent-tag-vp {
    color: #9e9e9e;
  }

  .dark-mode-transform-markup-GREEN.dark-mode-transform-markup-highlight,
  .dark-mode-transform-markup-GREEN.dark-mode-transform-markup-base-highlight {
    filter: invert(1) hue-rotate(180deg) contrast(.5) saturate(4);
  }

  .dark-mode-transform-markup-YELLOW {
    filter: brightness(.5) saturate(2.5);
  }

  .dark-mode-transform-markup-YELLOW.dark-mode-transform-markup-arrow {
    filter: brightness(.8) saturate(1.5);
  }

  .dark-mode-transform-markup-YELLOW.dark-mode-transform-markup-highlight,
  .dark-mode-transform-markup-YELLOW.dark-mode-transform-markup-base-highlight {
    filter: invert(1) hue-rotate(180deg) contrast(.2) saturate(10);
  }

  .FormattingMenuList .dark-mode-transform-markup-YELLOW:not(.dark-mode-transform-markup-arrow):not(.dark-mode-transform-markup-highlight):not(.dark-mode-transform-markup-base-highlight) {
    filter: brightness(.5) saturate(18);
  }

  .dark-mode-transform-markup-ORANGE {
    filter: brightness(.5) saturate(3.5);
  }

  .dark-mode-transform-markup-ORANGE.dark-mode-transform-markup-arrow {
    filter: brightness(.8) saturate(1.5);
  }

  .dark-mode-transform-markup-ORANGE.dark-mode-transform-markup-highlight {
    background-color: #e89600dd;
    filter: none;
  }

  .dark-mode-transform-markup-ORANGE.dark-mode-transform-markup-base-highlight {
    background-image: ${getBaseHighlightBGImage(`#e89600dd`)};
    filter: none;
  }

  .dark-mode-transform-markup-BLUE.dark-mode-transform-markup-highlight,
  .dark-mode-transform-markup-BLUE.dark-mode-transform-markup-base-highlight {
    filter: brightness(2.5) saturate(1.5);
  }

  .dark-mode-transform-markup-LIGHT-BLUE.dark-mode-transform-markup-highlight {
    background-color: #00adaf88;
  }

  .dark-mode-transform-markup-LIGHT-BLUE.dark-mode-transform-markup-base-highlight {
    background-image: ${getBaseHighlightBGImage(`#00adaf88`)};
  }

  .FormattingMenuList .dark-mode-transform-markup-BLUE.dark-mode-transform-markup-arrow {
    filter: brightness(.6) saturate(1.5);
  }

  .dark-mode-transform-markup-PURPLE {
    filter: brightness(2.5) saturate(.5);
  }

  .dark-mode-transform-markup-PURPLE.dark-mode-transform-markup-arrow {
    filter: brightness(1) saturate(1.5);
  }

  .dark-mode-transform-markup-PURPLE.dark-mode-transform-markup-highlight,
  .dark-mode-transform-markup-PURPLE.dark-mode-transform-markup-base-highlight {
    && {
      filter: brightness(2.5) saturate(1.5);
    }
  }

  .dark-mode-transform-markup-RED {
    filter: brightness(1.5) saturate(1.5);
  }

  .dark-mode-transform-markup-PINK {
    filter: brightness(.3) saturate(3);
  }

  .dark-mode-transform-markup-PINK.dark-mode-transform-markup-arrow {
    filter: brightness(.75) saturate(1.2);
  }

  .dark-mode-transform-markup-PINK.dark-mode-transform-markup-highlight {
    background-color: #ff627f87;
  }

  .dark-mode-transform-markup-PINK.dark-mode-transform-markup-base-highlight {
    background-image: ${getBaseHighlightBGImage(`#ff627f87`)};
  }

  .BibleMapHeader-StyledHeader {
    background-color: rgb(255 255 255/.9);
  }

  .BibleMapInfoEvent-Chip.BibleMapInfoEvent-Chip {
    filter: invert(1) hue-rotate(180deg);
  }

  @media (hover: hover) {
    .BibleMapInfoEvent-ChipButton:hover {
      opacity: 1;
      .BibleMapInfoEvent-Chip {
        opacity: .8;
      }
      .MuiSvgIcon-root {
        opacity: 1;
      }
    }
  }

  .BibleMapKey-Container {
    background-color: rgb(255 255 255/1);
    backdrop-filter: none;
  }

  .BibleMapKey-JourneyArrow {
    filter: invert(1) hue-rotate(180deg) contrast(1.43) saturate(1);
  }

  .BibleMapPlaceInfo-EditorContainer {
    background-color: #bdac5966;
  }

`

const GlobalStyleOn = createGlobalStyle`
  ${darkModeStyle}
`

const GlobalStyleAuto = createGlobalStyle`
  @media (prefers-color-scheme: dark) {
    ${darkModeStyle}
  }
`

const DarkModeGlobalStyle = () => {

  const darkMode = useContext(DarkModeContext)

  const effectiveDarkModeOn = (
    darkMode === `AUTO`
      ? (
        window.matchMedia
        && window.matchMedia('(prefers-color-scheme: dark)').matches
      )
      : darkMode === `ON`
  )

  useThemeColor({ effectiveDarkModeOn })

  useEffectAsync(
    () => {
      const errorBondaryInfo = getLocalStorage(`errorBondaryInfo`, {})
      errorBondaryInfo.effectiveDarkModeOn = effectiveDarkModeOn
      setLocalStorage(`errorBondaryInfo`, errorBondaryInfo)
    },
    [ effectiveDarkModeOn ],
  )

  if(darkMode === `OFF` || IS_EMBED) return null
  if(darkMode === `ON`) return <GlobalStyleOn />
  return <GlobalStyleAuto />

}

export default memo(DarkModeGlobalStyle)