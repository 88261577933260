class AsyncLock {

  constructor() {
    this.promise = Promise.resolve()
    this.lockGroup = []
  }

  enable = async () => {

    const oldPromise = this.promise
    let currentResolve
    this.promise = new Promise(resolve => currentResolve = resolve)

    this.lockGroup.push(this.promise)
    const currentLockGroup = this.lockGroup
    requestAnimationFrame(() => this.lockGroup = [])

    await oldPromise

    return async () => {
      currentResolve()
      await Promise.all(currentLockGroup)
    }
  }

}

export default AsyncLock