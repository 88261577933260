import { gql } from '@apollo/client'

import studyBibleItem_fields from '../fragments/studyBibleItem_fields'

const studyBibleItem = gql`
  query studyBibleItem($id: ID!) {
    studyBibleItem(id: $id) {
      ${studyBibleItem_fields}
    }
  }
`

export default studyBibleItem