import { gql } from '@apollo/client'

const autoCompleteSuggestions = gql`
  query autoCompleteSuggestions($incompleteQuery: String!, $languageIds: [ID]!, $projectId: ID) {
    autoCompleteSuggestions(incompleteQuery: $incompleteQuery, languageIds: $languageIds, projectId: $projectId) {
      from
      suggestedQuery
      originalWords
      resultCount
      action
      actionType
      path
    }
  }
`

export default autoCompleteSuggestions