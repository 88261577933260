import React, { memo, useCallback, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { useHistory } from 'react-router-dom'

import { getDateAsString, getPrimaryDate, getPrimaryName } from '../../utils/misc'
import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'

import PassageRef from '../common/PassageRef'

const Container = styled.div`
  margin-bottom: 10px;
  cursor: pointer;

  &:hover .MapSearchResultsRow-Name {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`

const Type = styled.div`
  background: ${({ $color, theme }) => $color || theme.palette.mapColors.event};
  border-radius: 50px;
  color: white;
  line-height: 1.1;
  padding: 2px 5px;
  font-size: 9px;
  text-transform: uppercase;
`

const NamePlus = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const Date = styled.div`
  font-size: 12px;
  font-weight: 300;
  white-space: nowrap;
`

const InfoType = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const Info = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const MapSearchResultsRow = ({
  place,
  event,
  person,
  journey,
  eventsByJourneyId,
  eventJourney,
  closeAndClearSearch,
}) => {

  const history = useHistory()
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  const goToPlace = useCallback(
    () => {
      let url = channelIdInPWA ? `/church/${channelIdInPWA}/map` : `/map`
      if(place) {
        url += `?placeId=${place.id}`
      } else if(event) {
        url += `?eventId=${event.id}`
      } else if(journey) {
        url += `?journeyId=${journey.id}`
      } else if(person) {
        url += `?personId=${person.id}`
      }
      history.push(url)
      closeAndClearSearch()
    },
    [ history, channelIdInPWA, place, event, person, journey, closeAndClearSearch ],
  )

  let {
    id,
    names,
    // levelOfImportance,
    about,
    // imgUrl,
    dateRange,
    color,
    type,
    dates,
    passages,
    journeyId,
    // personIds,
    events,
  } = place || event || person || journey || {}

  const places = ((journey ? eventsByJourneyId[id] : events) || []).map(({ place }) => place)
  const hasPlaces = (places || []).length > 0
  const hasEvents = (events || []).length > 0
  const hasPassages = (passages || []).length > 0

  about = (
    about
    || (
      journeyId
      && i18nReact("A part of {{journey}}", {
        journey: (
          <InfoType>
            {getPrimaryName(eventJourney)}
          </InfoType>
        ),
      })
    )
  )

  return (
    <Container
      onClick={goToPlace}
    >

      <NamePlus>

        <Name className="MapSearchResultsRow-Name">
          {getPrimaryName({ names })}
        </Name>

        <Type $color={color}>
          {
            type
            || (event && i18n("Event"))
            || (person && i18n("Person"))
            || (journey && i18n("Journey"))
          }
        </Type>

        {!!(dateRange || dates) &&
          <Date>
            {getDateAsString({ date: dateRange || getPrimaryDate({ dates }) })}
          </Date>
        }

      </NamePlus>

      {hasEvents && 
        <Info>
          <InfoType>
            {i18n("Events")}:
          </InfoType>
          {` `}
          {events.map(event => getPrimaryName(event)).join(`, `)}
        </Info>
      }

      {!hasEvents && hasPlaces && 
        <Info>
          <InfoType>
            {i18n("Places")}:
          </InfoType>
          {` `}
          {places.map(place => getPrimaryName(place)).join(`, `)}
        </Info>
      }

      {!hasEvents && !hasPlaces && hasPassages &&
        <Info>
          {passages.map((passage, idx) => (
            <React.Fragment key={idx}>
              {idx > 0 && `; `}
              <PassageRef {...passage} />
            </React.Fragment>
          ))}
        </Info>
      }

      {!hasEvents && !hasPlaces && !hasPassages && !!about &&
        <Info>
          {about}
        </Info>
      }

    </Container>
  )
}

export default memo(MapSearchResultsRow)