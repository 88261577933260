import { memo, useRef } from 'react'
import styled from 'styled-components'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { i18n } from 'inline-i18n'
import Tooltip from '@material-ui/core/Tooltip'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import useOutlineDivider from '../../../hooks/useOutlineDivider'

import TextContentMarkupContainer from '../../markup/TextContentMarkupContainer'
import TextContent from '../../common/TextContent'
import TooltipNote from '../../common/TooltipNote'
import OutlineBreakOptionsIcon from './OutlineBreakOptionsIcon'

const Container = styled.div`

  ${({ $inEditingMode, $wordLocAlreadyWithBreak, theme }) => !$inEditingMode ? `` : `

    position: relative;

    .TextContent-tag-f,
    .TextContent-tag-x,
    .TextContent-tag-xt,
    .TextContent-tag-zApparatusJson {
      cursor: text !important;
      color: ${theme.palette.grey[300]} !important;
    }

    .TextContent-tag-v:hover,
    .TextContent-tag-vp:hover,
    .TextContent-tag-w:hover {
      cursor: text;
      opacity: 1;
    }

    .text-content-word:not([data-word-loc="${$wordLocAlreadyWithBreak}"]) {
      cursor: pointer;
    }

  `}

`

const StyledMoreHorizIcon = styled(MoreHorizIcon)`
  font-size: inherit;
  margin: -1em -1.3em -1em 0.4em;
  vertical-align: middle;
  opacity: .25;
  width: 0.9em;
  height: 0.9em;
`

const OutlinePassageBlock = ({
  fromLoc,
  // toLoc,
  versionId,
  pieces,
  nextBreakIsAnAutoBreak,
  goCreateModulePiece,
  modulePieces,
  projectId,
  isScrolling,
  goSetPopperInfo,
  inEditingMode,
  isRTL,
  ...otherProps
}) => {


  const [ fromLocNoWord, fromLocWordNumber=`1` ] = fromLoc.split(':')
  const wordLocAlreadyWithBreak = modulePieces ? `${fromLocNoWord}:${fromLocWordNumber}` : null

  const { bookId, chapter, verse } = getRefFromLoc(fromLoc)

  const containerRef = useRef()

  const { events, breakMark } = useOutlineDivider({
    isScrolling,
    containerRef,
    inEditingMode,
    goCreateModulePiece,
    wordLocAlreadyWithBreak,
    isRTL,
  })

  return (
    <Container
      ref={containerRef}
      $inEditingMode={inEditingMode}
      $wordLocAlreadyWithBreak={wordLocAlreadyWithBreak}
      {...events}
    >

      <TextContentMarkupContainer
        isRTL={isRTL}
        // markup={markup}
        markup={[]}
        // onLoad={onLoad}
        className="outline-passage-block-text-container options-popper-no-pointer-events"
        // data-blockkey={blockKey}
      >

        {!!modulePieces &&
          <OutlineBreakOptionsIcon
            inEditingMode={inEditingMode}
            modulePieces={modulePieces}
            projectId={projectId}
            goCreateModulePiece={goCreateModulePiece}
          />
        }

        <TextContent
          {...otherProps}
          pieces={pieces}
          versionId={versionId}
          bookId={bookId}
          startChapter={chapter}
          startVerse={verse}
          // wordClassName="outline-text-word"
          showChapterNumbers
          goSetPopperInfo={goSetPopperInfo}
          ignoreClick={inEditingMode}
        />

        {nextBreakIsAnAutoBreak &&
          <Tooltip
            title={
              <TooltipNote
                note={i18n("Temporary system line break")}
                secondaryNote={i18n("(used to speed up rendering)")}
              />
            }
          >
            <StyledMoreHorizIcon />
          </Tooltip>
        }

      </TextContentMarkupContainer>

      {breakMark}

    </Container>
  )
}

export default memo(OutlinePassageBlock)