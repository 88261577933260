import { memo, useContext } from 'react'
import styled from 'styled-components'

import { LoggedInUserContext } from '../../../context/LoggedInUser'

import { IS_EMBED } from '../../../utils/constants'
import LegacyPhrasingOptionsColumn from './LegacyPhrasingOptionsColumn'
import LegacyPhrasingOptionsNewColumn from './LegacyPhrasingOptionsNewColumn'

const Container = styled.div`
  padding: 15px;
  white-space: nowrap;
  max-width: 500px;
  overflow-x: auto;
`

const LegacyPhrasingOptionsColumns = ({
  modulePassages,
  visibilityById,
  ...otherProps
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  return (
    <Container>

      {modulePassages.map(modulePassage => (
        <LegacyPhrasingOptionsColumn
          key={modulePassage.id}
          modulePassages={modulePassages}
          modulePassage={modulePassage}
          visibilityById={visibilityById}
          {...otherProps}
        />
      ))}

      {(hasToolsPlan || IS_EMBED) && modulePassages.length < 5 &&  // Switch to 10 with non-legacy
        <LegacyPhrasingOptionsNewColumn
          modulePassages={modulePassages}
          {...otherProps}
        />
      }

    </Container>
  )
}

export default memo(LegacyPhrasingOptionsColumns)