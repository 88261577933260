import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { DragDropContext, Draggable } from "react-beautiful-dnd"

import { reorder } from '../../../utils/misc'
import useChannelPriorities from '../../../hooks/useChannelPriorities'

import MyChannelsListLine from "./MyChannelsListLine"
import StudyBibleFollowAddButton from '../../passage/StudyBibleFollowAddButton'
import Droppable from '../../common/Droppable'

const Container = styled.div`
  min-height: 0;
  flex-shrink: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`

const Contents = styled.div`
  min-height: 0;
  flex-shrink: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-anchor: none;
  padding-bottom: 80px;
`

const Lines = styled.div`
`

const StyledStudyBibleFollowAddButton = styled(StudyBibleFollowAddButton)`
  margin-top: 30px;
`

const MyChannelsList = () => {

  const { studyBibleFollows, channelPriorities, updateChannelPriorities } = useChannelPriorities()

  const onDragEnd = useCallback(
    result => {
      if(!result.destination) return
      if(result.destination.index === result.source.index) return

      const newStudyBibleFollow = reorder({
        list: channelPriorities,
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })

      updateChannelPriorities(newStudyBibleFollow)

    },
    [ channelPriorities, updateChannelPriorities ],
  )

  return (
    <Container>

      <Contents>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Lines>
                    {channelPriorities.map(({ type, studyBibleFollowId, hide }, index) => (
                      <Draggable
                        key={studyBibleFollowId || type}
                        draggableId={studyBibleFollowId || type}
                        index={index}
                      >
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <MyChannelsListLine
                              {...(studyBibleFollows.find(({ id }) => id === studyBibleFollowId) || {})}
                              type={type}
                              hide={hide}
                              toggleHide={!studyBibleFollowId && (() => {
                                const updatedChannelPriority = { ...channelPriorities[index] }
                                updatedChannelPriority.hide = !updatedChannelPriority.hide
                                updateChannelPriorities([
                                  ...channelPriorities.slice(0, index),
                                  updatedChannelPriority,
                                  ...channelPriorities.slice(index + 1),
                                ])
                              })}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </Lines>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <StyledStudyBibleFollowAddButton />

      </Contents>

      {/* <CustomSnackbar {...snackbarInfo} /> */}

    </Container>
  )
}

export default memo(MyChannelsList)