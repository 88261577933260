import { i18n } from 'inline-i18n'

const snapshots = [
  // {
  //   id: `early`,
  //   getSnapshotLabel: () => i18n("Early Man"),
  // },
  {
    id: `patriarchs`,
    getSnapshotLabel: () => i18n("The Patriarchs"),
    eraId: `patriarchs`,
    date: `1990 BC`,
    timelineDetailsWidth: 250,
    maxTimelineDetailsPercent: 0.15,
    showTimelineCutoffWidth: 800,
    scaleAndPositionInfo: {
      lowestX: 0.6754717,
      lowestY: 0.4279932,
      highestX: 0.9681845,
      highestY: 0.712991,
    },
    options: {
      showJourneys: true,
      showJourneyKey: true,
    },
  },
  {
    id: `exodus`,
    getSnapshotLabel: () => i18n("The Exodus and Conquest"),
    eraId: `exodus`,
    date: `1440 BC`,
    timelineDetailsWidth: 250,
    maxTimelineDetailsPercent: 0.25,
    showTimelineCutoffWidth: 700,
    scaleAndPositionInfo: {
      lowestX: 0.6845981,
      lowestY: 0.536057,
      highestX: 0.7878738,
      highestY: 0.72408,
    },
    options: {
      showJourneys: true,
      showJourneyKey: true,
    },
  },
  {
    id: `tribes`,
    getSnapshotLabel: () => i18n("The 12 Tribes of Israel"),
    eraId: `bible`,
    date: `1400 BC`,
    timelineDetailsWidth: 0,
    scaleAndPositionInfo: {
      lowestX: 0.731320,
      lowestY: 0.5784138,
      highestX: 0.7570253,
      highestY: 0.640333,
    },
    options: {
      showJourneys: false,
    },
  },
  // {
  //   id: `judges`,
  //   getSnapshotLabel: () => i18n("Israel’s Judges"),
  // },
  // {
  //   id: `kings`,
  //   getSnapshotLabel: () => i18n("Kings of Israel and Judah"),
  // },
  // {
  //   id: `united`,
  //   getSnapshotLabel: () => i18n("The United Kingdom"),
  // },
  // {
  //   id: `northern`,
  //   getSnapshotLabel: () => i18n("The Northern Kingdom"),
  // },
  // {
  //   id: `southern`,
  //   getSnapshotLabel: () => i18n("The Southern Judah"),
  // },
  // {
  //   id: `empires`,
  //   getSnapshotLabel: () => i18n("Empires in the Bible"),
  // },
  // {
  //   id: `return`,
  //   getSnapshotLabel: () => i18n("Return from Exile"),
  // },
  {
    id: `jesus`,
    getSnapshotLabel: () => i18n("Jesus’ Birth and Ministry"),
    eraId: `jesus`,
    date: `6 BC [10/1]`,
    timelineDetailsWidth: 250,
    maxTimelineDetailsPercent: 0.2,
    showTimelineCutoffWidth: 800,
    scaleAndPositionInfo: {
      lowestX: 0.6990493,
      lowestY: 0.5704709,
      highestX: 0.7610318,
      highestY: 0.652752,
    },
    options: {
      showJourneys: true,
      showJourneyKey: true,
    },
  },
  {
    id: `paul`,
    getSnapshotLabel: () => i18n("Paul’s Journeys"),
    eraId: `apostles`,
    date: `AD 46`,
    timelineDetailsWidth: 250,
    maxTimelineDetailsPercent: 0.15,
    showTimelineCutoffWidth: 800,
    scaleAndPositionInfo: {
      lowestX: 0.38351947,
      lowestY: 0.25492449,
      highestX: 0.85886986,
      highestY: 0.66701557,
    },
    options: {
      showJourneys: true,
      showJourneyKey: true,
    },
  },
  // {
  //   id: `paul1`,
  //   getSnapshotLabel: () => i18n("Paul’s 1st Missionary Journey"),
  // },
  // {
  //   id: `paul2`,
  //   getSnapshotLabel: () => i18n("Paul’s 2nd Missionary Journey"),
  // },
  // {
  //   id: `paul3`,
  //   getSnapshotLabel: () => i18n("Paul’s 3rd Missionary Journey"),
  // },
  // {
  //   id: `paulrome`,
  //   getSnapshotLabel: () => i18n("Paul’s Journey to Rome"),
  // },
]

export default snapshots