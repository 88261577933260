import studyBibleItemComment_fields from '../fragments/studyBibleItemComment_fields'

const fields = `
  id
  name
  shortName
  location
  preferredVersionId
  description
  hidden
  channelUrls
  createdAt
  updatedAt
  deletedAt
  purchasedAt
  channelAdmins {
    id
    adminLevel
    user {
      id
      name
      email
      image
    }
  }
  stats
  recentComments {
    ${studyBibleItemComment_fields}
  }
  purchaseInfo
`

// Note: For channel, deletedAt and channelAdmins only included if person is a channelAdmin (on a user query) or an ADMIN or EDITOR (on a channels query)

export default fields