import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { preventDefaultEvent } from '../../../utils/misc'

import PassageRefButton from '../../passage/PassageRefButton'

const Container = styled.div`
  display: flex;
`

const StyledIconButton = styled(IconButton)`
  padding: 6px;
  margin: 6px 4px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: white;

  @media (hover: hover) {
    &:hover {
      background: white;
    }
  }

  &.Mui-disabled {
    background: white;
  }

`

const StyledPassageRefButton = styled(PassageRefButton)`
  margin: 5px;
`

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  height: 18px;
`

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  height: 18px;
`

const TaggerMiniPassageRefNavigator = ({
  loc,
  versionId,
  onClickPrevious,
  onClickNext,
  ...otherProps
}) => {

  const commonProps = {
    disabled: false,
    onMouseDown: preventDefaultEvent,
    tabIndex: -1,
  }

  return (
    <Container>

      <StyledIconButton
        {...commonProps}
        onClick={onClickPrevious}
        disabled={!onClickPrevious}
      >
        <StyledArrowBackIcon />
      </StyledIconButton>

      <StyledPassageRefButton
        {...otherProps}
        versionId={versionId}
        abbreviated
        mini
        type="verse"
        showParallel={false}
        hideOriginals
      />

      <StyledIconButton
        {...commonProps}
        onClick={onClickNext}
        disabled={!onClickNext}
      >
        <StyledArrowForwardIcon />
      </StyledIconButton>

    </Container>
  )
}

export default memo(TaggerMiniPassageRefNavigator)