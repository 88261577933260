import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import usePassageNextPreviousInfo from '../../hooks/usePassageNextPreviousInfo'

const Container = styled.div`
  display: flex;
`

const StyledIconButton = styled(IconButton)`
  padding: 6px;
  margin: 6px 4px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
`

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  height: 18px;
`

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  height: 18px;
`

const PassageNextPreviousButtons = ({
  updatePassageBookmark,
  bookId,
  chapter,
  versionId,
  columnIdx,
}) => {

  const { previousProps, nextProps } = usePassageNextPreviousInfo({
    updatePassageBookmark,
    bookId,
    chapter,
    versionId,
    columnIdx,  
  })

  return (
    <Container>

      <StyledIconButton {...previousProps} >
        <StyledArrowBackIcon />
      </StyledIconButton>

      <StyledIconButton {...nextProps} >
        <StyledArrowForwardIcon />
      </StyledIconButton>

    </Container>
  )
}

export default memo(PassageNextPreviousButtons)