import { memo, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import { cloneObj, getEarlierDate, getLaterDate, getPrimaryDate, timeBetweenMapDates } from '../../../utils/misc'

import BibleMapEvent from './BibleMapEvent'

const LINE_HEIGHT = 23
const MIN_SPACE_BETWEEN = 2
const TOP_ADD_ON = 35
const WIGGLE_AMOUNT = 15
const HEIGHT_ADD_ON = 12

const Container = styled.div`
  color: white;
  min-height: 0;
`

const BibleMapEvents = ({
  mapLayer,
  timelineStartDate,
  timelineEndDate,
  height,
  colorByJourneyId,
  eraId,
  updateMapTimelineForItem,
}) => {

  const itemsToShow = useMemo(
    () => {

      const { places=[], journeys=[] } = mapLayer.data || {}

      const eventsWithPlaceObjs = []

      // show journeys as pseudo events, with a priority equal to the sum of the events
      const journeyEventsById = {}
      if(eraId === `bible`) {
        journeys.forEach(journey => {
          const journeyEvent = {
            id: journey.id,
            type: `journey`,
            color: colorByJourneyId[journey.id],
            dates: [{ startDate: `AD 5000`, endDate: `5000 BC` }],
            names: cloneObj(journey.names),
            passages: [],
            levelOfImportance: 0,
            eventsAndPlaces: [],
          }
          eventsWithPlaceObjs.push(journeyEvent)
          journeyEventsById[journey.id] = journeyEvent
        })
      }
      places.map(place => place.events.forEach(event => {
        const { journeyId } = event
        if(journeyEventsById[journeyId]) {
          journeyEventsById[journeyId].levelOfImportance = Math.max(journeyEventsById[journeyId].levelOfImportance, event.levelOfImportance || 0)
          journeyEventsById[journeyId].dates[0].startDate = getEarlierDate(journeyEventsById[journeyId].dates[0].startDate, getPrimaryDate(event).split(` - `)[0].replace(/ \[.*$/, ``))
          journeyEventsById[journeyId].dates[0].endDate = getLaterDate(journeyEventsById[journeyId].dates[0].endDate, getPrimaryDate(event).split(` - `).at(-1).replace(/ \[.*$/, ``))
          journeyEventsById[journeyId].passages.push(...cloneObj(event.passages))
          journeyEventsById[journeyId].eventsAndPlaces.push(cloneObj({ event, place }))
        } else {
          eventsWithPlaceObjs.push(cloneObj(({ type: `event`, ...event, place })))
        }
      }))
      Object.values(journeyEventsById).forEach(journeyEvent => {
        journeyEvent.dates[0] = { date: `${journeyEvent.dates[0].startDate} - ${journeyEvent.dates[0].endDate}` }
      })

      // add in creation + flood
      eventsWithPlaceObjs.push({
        id: `creation`,
        type: `creation`,
        dates: [{ date: "4004 BC" }],
        names: [{ name: i18n("Creation") }],
        passages: [{ fromLoc: "01001001" }],
        levelOfImportance: 1000,
      })
      eventsWithPlaceObjs.push({
        id: `flood`,
        type: `flood`,
        dates: [{ date: "2348 BC" }],
        names: [{ name: i18n("The Flood") }],
        passages: [{ fromLoc: "01007011" }],
        levelOfImportance: 500,
      })

      // sort events by level of importance, most important first
      const getLoI = ({ levelOfImportance }={}) => levelOfImportance || 0
      eventsWithPlaceObjs.sort((a,b) => {
        return (
          getLoI(a) === getLoI(b)
            ? getLoI(b.place) - getLoI(a.place)
            : getLoI(b) - getLoI(a)
        )
      })

      // try to place each
      const placements = []  // [ null, null, <eventWithInfo>, <eventWithInfo>, ... ]
      const { totalDays } = timeBetweenMapDates(timelineStartDate, timelineEndDate)
      eventsWithPlaceObjs.forEach(event => {
        if(!getPrimaryDate(event)) console.log("no date", event)
        if(!getPrimaryDate(event)) return
        const [ startDate, endDate ] = getPrimaryDate(event).split(` - `)
        const maxTopOfArea = height + HEIGHT_ADD_ON - LINE_HEIGHT
        const getTop = date => {
          const percentageFromTop = timeBetweenMapDates(timelineStartDate, date).totalDays / totalDays
          return Math.round(percentageFromTop * maxTopOfArea)
        }
        const startTop = getTop(startDate)
        const endTop = getTop(endDate || startDate)
        const minTop = Math.max(startTop - WIGGLE_AMOUNT, 0)
        const maxTop = Math.min(endTop + WIGGLE_AMOUNT, maxTopOfArea)
        const idealTop = Math.max(Math.min(Math.round((startTop + endTop) / 2), maxTop), minTop)

        if(minTop > maxTopOfArea) return
        if(maxTop < 0) return

        const insert = top => {
          Array(LINE_HEIGHT).fill().forEach((x, idx) => {
            placements[top + idx] = event
          })
        }
        const firstUnavailableIndex = Array(LINE_HEIGHT + MIN_SPACE_BETWEEN*2).fill().findIndex((x, idx) => placements[idealTop + idx - MIN_SPACE_BETWEEN])
        if(firstUnavailableIndex === -1) {
          // spot available!
          insert(idealTop)
        } else {
          const amountNeededBefore = (LINE_HEIGHT + MIN_SPACE_BETWEEN*2) - firstUnavailableIndex
          if(
            idealTop - minTop >= amountNeededBefore
            && Array(amountNeededBefore).fill().every((x, idx) => !placements[idealTop - idx - MIN_SPACE_BETWEEN - 1])
          ) {
            // spot available when we move it up amountNeededBefore!
            insert(idealTop - amountNeededBefore)
          } else {
            const lastUnavailableIndex = Array(LINE_HEIGHT + MIN_SPACE_BETWEEN*2).fill().findLastIndex((x, idx) => placements[idealTop + idx - MIN_SPACE_BETWEEN])
            const amountNeededAfter = lastUnavailableIndex + 1
            if(
              maxTop - idealTop >= amountNeededAfter
              && Array(amountNeededAfter).fill().every((x, idx) => !placements[idealTop + LINE_HEIGHT + MIN_SPACE_BETWEEN*2 + idx])
            ) {
              // spot available when we move it down amountNeededAfter!
              insert(idealTop + amountNeededAfter)
            }
          }
        }
      })

      // LINE_HEIGHT
      const itemsToShow = []

      placements.forEach((event, idx) => {
        if(event && event !== (itemsToShow.at(-1) || {}).event) {
          itemsToShow.push({
            event,
            top: idx + TOP_ADD_ON,
          })
        }
      })

      return itemsToShow

    },
    [ mapLayer, timelineStartDate, timelineEndDate, height, colorByJourneyId, eraId ],
  )

  return (
    <Container>

      {itemsToShow.map(({ top, event }) => (
        <BibleMapEvent
          key={event.id}
          top={top}
          updateMapTimelineForItem={updateMapTimelineForItem}
          {...event}
        />
      ))}

    </Container>
  )
}

export default memo(BibleMapEvents)