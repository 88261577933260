import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

const Container = styled.div`
  width: 48px;
  align-self: flex-start;
  padding: 10px 10px;
`

const StyledAddIcon = styled(AddIcon)`
  color: ${({ theme }) => theme.palette.grey[700]};
  height: 16px;
`

const StyledButton = styled(Button)`
  transform: rotate(90deg);
  transform-origin: 13px;
  white-space: nowrap;
  border-color: ${({ theme }) => theme.palette.divider};
  color: ${({ theme }) => theme.palette.grey[500]};
  padding: 1px 10px;

  .MuiButton-startIcon {
    margin-right: 3px;
    margin-left: -4px;
  }

  &.Mui-focusVisible {
    box-shadow: 0 0 3px -1px rgba(0,0,0,0.3), 0 -1px 7px 0 rgba(0,0,0,0.24), 0 0 12px 0 rgba(0,0,0,0.22);
  }

  .MuiTouchRipple-root {
    display: none;
  }

`

const PassageAddColumnButton = ({
  addColumn,
}) => {

  return (
    <Container>
      <StyledButton
        variant="outlined"
        startIcon={<StyledAddIcon />}
        size="small"
        onClick={addColumn}
      >
        {i18n("Add column")}
      </StyledButton>
    </Container>
  )
}

export default memo(PassageAddColumnButton)