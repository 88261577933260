import { useMemo } from 'react'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import useDataQuery from './useDataQuery'
import useNextPrevChapters from './useNextPrevChapters'

import nextAndPreviousLocsWithoutTaggingQuery from '../graphql/queries/nextAndPreviousLocsWithoutTagging'
import chapterQuery from '../graphql/queries/chapter'

const usePrevNextTaggingLoc = ({
  currentLoc,
  direction,  // "previous" or "next"
  versionId,
}) => {

  const passageRef = getRefFromLoc(currentLoc || ``)
  const { bookId, chapter } = passageRef || {}

  const previousOrNextChapter = useNextPrevChapters({
    bookId,
    chapter,
    versionId,
  })[`${direction}Chapter`]

  const { chapter: vss } = useDataQuery({
    chapterQuery,
    variables: {
      bookId,
      chapter,
      versionId,
    },
    dataOnError: null,
    skip: !bookId,
  })

  const { chapter: vssInPreviousOrNextChapter } = useDataQuery({
    chapterQuery,
    variables: {
      ...(previousOrNextChapter || {}),
      versionId,
    },
    dataOnError: null,
    skip: !previousOrNextChapter,
  })

  const previousOrNextTaggingLoc = useMemo(
    () => {

      if(!vss) return null

      const locsInThisChapter = vss.map(({ id }) => id.split(`-`)[0])
      const indexOfCurrentLoc = locsInThisChapter.findIndex(loc => loc === currentLoc)
      const previousOrNextLoc = locsInThisChapter[indexOfCurrentLoc + (direction === `previous` ? -1 : 1)]

      if(previousOrNextLoc) return previousOrNextLoc

      const locsInPreviousOrNextChapter = (vssInPreviousOrNextChapter || []).map(({ id }) => id.split(`-`)[0])

      if(locsInPreviousOrNextChapter) {
        return locsInPreviousOrNextChapter.at(direction === `previous` ? -1 : 0)
      }

      return null
      
    },
    [ currentLoc, vss, vssInPreviousOrNextChapter, direction ],
  )

  return previousOrNextTaggingLoc

}

const usePrevNextTaggingLocs = ({
  currentLoc,
  versionId,
  viewUntaggedOnly,
}) => {

  const { nextAndPreviousLocsWithoutTagging } = useDataQuery({
    nextAndPreviousLocsWithoutTaggingQuery,
    variables: {
      versionId,
      ...getRefFromLoc((currentLoc || ``).split(`:`)[0]),
    },
    fetchPolicy: `network-only`,
    dataOnError: {},
    skip: !viewUntaggedOnly || !currentLoc,
  })

  const previousLoc = usePrevNextTaggingLoc({
    currentLoc: viewUntaggedOnly ? null : currentLoc,
    direction: `previous`,
    versionId,
  })

  const nextLoc = usePrevNextTaggingLoc({
    currentLoc: viewUntaggedOnly ? null : currentLoc,
    direction: `next`,
    versionId,
  })

  return (
    nextAndPreviousLocsWithoutTagging
    || { previousLoc, nextLoc }
  )

}

export default usePrevNextTaggingLocs