import { useContext, useMemo } from "react"
import { findAutoCompleteSuggestions, getRefsFromPassageStr, isOriginalLanguageSearch } from "@bibletags/bibletags-ui-helper"
import { getLocFromRef } from "@bibletags/bibletags-versification"

import useDataQuery from './useDataQuery'
import useVersionInfos from './useVersionInfos'
import { GET_SETTINGS_FOR_SEARCH, GET_HELP_FOR_SEARCH } from '../utils/constants'
import useVersesPieces from "./useVersesPieces"
import useEqualObjsMemo from "./useEqualObjsMemo"
import useBibleSearchResults from "./useBibleSearchResults"
import useIsLoggedIn from "./useIsLoggedIn"
import useMenuSearchItems from "./useMenuSearchItems"
import useMapLayers from "./useMapLayers"
import useJourneyInfo from "./useJourneyInfo"
import usePersonInfo from "./usePersonInfo"
import { ChannelIdInPWAContext } from "../context/ChannelIdInPWA"

import projectsQuery from '../graphql/queries/projects'
import studyBibleItemsQuery from '../graphql/queries/studyBibleItems'
import { getPrimaryName, getIsTagSearch } from "../utils/misc"
// import highlightsQuery from '../graphql/queries/highlights'

const useSearchResults = ({ searchText }) => {

  let { unhiddenSelectedVersionInfos=[] } = useVersionInfos()

  const menuItemsForSearch = useMenuSearchItems()
  const settingsForSearch = useMemo(() => GET_SETTINGS_FOR_SEARCH(), [])
  const helpForSearch = useMemo(() => GET_HELP_FOR_SEARCH(), [])

  const isLoggedIn = useIsLoggedIn()
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  const isOrigLanguageSearch = isOriginalLanguageSearch(searchText)
  const isTagSearch = getIsTagSearch(searchText)

  const layer = `BIBLE`
  const stage = `PUBLISHED`
  const { getMapLayerState } = useMapLayers({ layer, stage })
  const [ mapLayer ] = getMapLayerState({ layer, stage })
  const { eventsByJourneyId } = useJourneyInfo({ mapLayer })
  const { eventsByPersonId } = usePersonInfo({ mapLayer })

  //////////////////////////////
  // BEGIN COLLECTING RESULTS //
  //////////////////////////////

  // 1. Passage look-ups

  const { refs=[], versionId } = (!isOrigLanguageSearch && getRefsFromPassageStr(searchText)) || {}
  const foundPassageRef = refs.length !== 0
  const searchWasForEntireChapter = foundPassageRef && refs[0].verse === undefined

  const [ fromLoc, toLoc ] = (
    !foundPassageRef
      ? []
      : (
        searchWasForEntireChapter
          ? [
            getLocFromRef({ ...refs[0], verse: 1 }),
            getLocFromRef({ ...refs[0], verse: 3 }),
          ]
          : refs.map(getLocFromRef)
      )
  )

  if(foundPassageRef) {
    const restrictToTestament = refs[0].bookId <= 39 ? `ot` : `nt`
    unhiddenSelectedVersionInfos = (
      unhiddenSelectedVersionInfos
        .filter(({ version: { partialScope }={} }) => (
          !partialScope
          || partialScope === restrictToTestament
        ))
    )
  }

  const getExtraInfo = idx => (
    ((versionId && idx > 0) || !foundPassageRef)
      ? {}
      : {
        versionId: !versionId && (unhiddenSelectedVersionInfos[idx] || {}).id,
        bookId: refs[0].bookId,
        ...(
          searchWasForEntireChapter
            ? {
              fromLoc: fromLoc.slice(0, -3),
            }
            : {
              fromLoc,
              toLoc,
            }
        ),
        startChapter: refs[0].chapter,
      }
  )

  const getUseVersesPiecesParams = (idx, vId) => ({
    fromLoc,
    toLoc,
    versionId: vId || (unhiddenSelectedVersionInfos[idx] || {}).id,
    skip: !foundPassageRef || ((!!versionId || !unhiddenSelectedVersionInfos[idx] || (toLoc && idx > 3)) && !vId),
  })

  let passageInfoSets = [
    {
      ...getExtraInfo(0),
      versionId: foundPassageRef && (versionId || (unhiddenSelectedVersionInfos[0] || {}).id),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(0, versionId)),
    },
    {
      ...getExtraInfo(1),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(1)),
    },
    {
      ...getExtraInfo(2),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(2)),
    },
    {
      ...getExtraInfo(3),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(3)),
    },
    {
      ...getExtraInfo(4),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(4)),
    },
    {
      ...getExtraInfo(5),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(5)),
    },
    {
      ...getExtraInfo(6),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(6)),
    },
    {
      ...getExtraInfo(7),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(7)),
    },
    {
      ...getExtraInfo(8),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(8)),
    },
    {
      ...getExtraInfo(9),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(9)),
    },
  ]

  let passageInfoLoading = false
  passageInfoSets.forEach(set => {
    set.pieces = set.piecesAndLoading[0]
    passageInfoLoading = passageInfoLoading || set.piecesAndLoading[1]
    delete set.piecesAndLoading
  })

  // 2. Bible search

  const { bibleSearchResults, includeVersionIds, inVersionAbbrs, inVersionIds, bibleSearchError } = useBibleSearchResults({
    searchText,
    isInitialSearch: true,
    skip: foundPassageRef,
  })

  // 3. Projects

  const { projects: projectsAndCount } = useDataQuery({
    projectsQuery,
    variables: {
      query: searchText,
      limit: 3,
    },
    skip: (isOrigLanguageSearch && !isTagSearch) || !isLoggedIn || !!channelIdInPWA,
  })

  // 5. Tags

  // 6. Highlights

  const highlightsAndCount = { highlights: [], count: 0 }
  // const { highlights: highlightsAndCount } = useDataQuery({
  //   highlightsQuery,
  //   variables: {
  //     query: fromLoc,
  //     limit: 3,
  //   },
  //   skip: isOrigLanguageSearch,
  // })
  // also look by content of the text highlighted?

  // 7. Map

  let maps = []
  if(mapLayer.data) {
    const searchTextLowerCase = searchText.toLowerCase()
    const searchTextPieces = searchTextLowerCase.split(/ /g)
    const pushToMaps = ({ items, type, journeys=[], ...extra }) => {
      items.forEach(item => {
        const itemName = getPrimaryName(item).toLowerCase()
        if(searchTextPieces.every(searchTextPiece => itemName.includes(searchTextPiece))) {
          maps.push({
            [type]: item,
            [`${type}Journey`]: (item.journeyId && journeys.find(({ id }) => id === item.journeyId)),
            exactMatch: itemName === searchTextLowerCase,
            ...extra,
          })
        }
      })
    }
    pushToMaps({
      items: (mapLayer.data.places || []),
      type: `place`,
    })
    pushToMaps({
      items: (mapLayer.data.persons || []).filter(({ id }) => eventsByPersonId[id].length > 0),
      type: `person`
    })
    pushToMaps({
      items: (mapLayer.data.journeys || []).filter(({ id }) => eventsByJourneyId[id].length > 0),
      type: `journey`,
      eventsByJourneyId,
    })
    pushToMaps({
      items: (mapLayer.data.places || []).map(place => place.events.map(event => ({ ...event, place }))).flat(),
      type: `event`,
      journeys: (mapLayer.data.journeys || []),
    })
    maps.sort((a,b) => (
      a.exactMatch
        ? -1
        : a.levelOfImportance - b.levelOfImportance
    ))
    maps = maps.slice(0,6)
  }
  const mapsAndCount = { maps, count: maps.length }

  // 8. EQUIP: Online Courses, LR library

  // 9. Shared with me (use searchShared query)

  // 10. BSB curated

  const { studyBibleItems: studyBibleItemsAndCount } = useDataQuery({
    studyBibleItemsQuery,
    variables: {
      query: searchText,
      order: `status,adminRating DESC,createdAt DESC`,
      limit: 5,
    },
    skip: (isOrigLanguageSearch && !isTagSearch) || !!channelIdInPWA,
  })

  // 11. channel items (create new channelItemsSearch query)

  // 12. Bible versions

  const { versions } = useVersionInfos({
    searchText,
    limit: 2,
    skip: isOrigLanguageSearch,
  })

  // 13. App items (menu, settings)

  const appItems = findAutoCompleteSuggestions({
    str: searchText,
    suggestionOptions: (
      isOrigLanguageSearch
        ? []
        : [
          ...menuItemsForSearch,
          ...settingsForSearch,
        ]
    ),
    max: 3,
  })

  // 14. Tours

  // 15. Help items

  const helpItems = findAutoCompleteSuggestions({
    str: searchText,
    suggestionOptions: (
      isOrigLanguageSearch
        ? []
        : helpForSearch
    ),
    max: 3,
  })

  // 16. Alerts

    // use alertsItems query (or what is already stored locally?)

  // 17. EQUIP: blog posts


  passageInfoSets = useEqualObjsMemo(passageInfoSets.filter(({ versionId, pieces }) => versionId && (pieces || []).length > 0))

  return {
    foundPassageRef,
    passageInfoLoading,
    passageInfoSets,
    bibleSearchResults,
    bibleSearchError,
    includeVersionIds,
    inVersionAbbrs,
    inVersionIds,
    mapsAndCount,
    projectsAndCount,
    highlightsAndCount,
    studyBibleItemsAndCount,
    versions,
    appItems,
    helpItems,
  }
}

export default useSearchResults