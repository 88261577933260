import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { getTextLanguageId, isRTLText } from "@bibletags/bibletags-ui-helper"
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import useVersionInfo from '../../../hooks/useVersionInfo'
import useOutlineHeadings from '../../../hooks/useOutlineHeadings'

import OutlineHeading from './OutlineHeading'

import OutlinePassageBlock from './OutlinePassageBlock'

const Container = styled.div`
  padding: 0 15px;
  display: flex;
  flex-direction: column;
`

const NewParagraphSpace = styled.div`
  height: .6em;
`

const OutlineRowContent = ({
  fromLoc,
  toLoc,
  versionId,
  pieces,
  nextBreakIsAnAutoBreak,
  goCreateModulePiece,
  modulePieces,
  projectId,
  isScrolling,
  goSetPopperInfo,
  inEditingMode,
  ...otherProps
}) => {

  const { bookId } = getRefFromLoc(fromLoc)
  const { version={} } = useVersionInfo(versionId)
  const { languageId: preadjustedLanguageId } = version
  const languageId = getTextLanguageId({ languageId: preadjustedLanguageId, bookId })
  const isRTL = isRTLText({ languageId, bookId, pieces })

  const headings = useOutlineHeadings()

  const headingModulePieces = useMemo(
    () => (
      Object.values(modulePieces || {})
        .filter(({ position }) => position !== `break`)
        .sort((a,b) => headings.findIndex(({ type }) => type === a.position) < headings.findIndex(({ type }) => type === b.position) ? -1 : 1)
    ),
    [ modulePieces, headings ],
  )

  const headingTypesInUse = useMemo(() => headingModulePieces.map(({ position }) => position), [ headingModulePieces ])

  return (
    <Container>

      {!!modulePieces && <NewParagraphSpace />}

      {headingModulePieces.map(modulePiece => (
        <OutlineHeading
          key={modulePiece.id}
          modulePiece={modulePiece}
          projectId={projectId}
          headingTypesInUse={headingTypesInUse}
          inEditingMode={inEditingMode}
          isRTL={isRTL}
        />
      ))}

      <OutlinePassageBlock
        {...otherProps}
        fromLoc={fromLoc}
        toLoc={toLoc}
        versionId={versionId}
        pieces={pieces}
        projectId={projectId}
        nextBreakIsAnAutoBreak={nextBreakIsAnAutoBreak}
        goCreateModulePiece={goCreateModulePiece}
        modulePieces={modulePieces}
        isScrolling={isScrolling}
        goSetPopperInfo={goSetPopperInfo}
        inEditingMode={inEditingMode}
        isRTL={isRTL}
      />

    </Container>
  )

}

export default memo(OutlineRowContent)