/*

See OptionsPopper.js for consideration of poppers and popovers

*/

import { memo, useMemo, useRef } from 'react'
import styled from 'styled-components'
import Fade from '@material-ui/core/Fade'

import useAppSize from '../../hooks/useAppSize'

import BasicPopover from './BasicPopover'

const StyledPopover = styled(BasicPopover)`

  ${({ $showToSide, $hideArrow, $showAbove }) => $hideArrow ? `` : `

    .MuiPopover-paper {
      overflow: visible;
      transform: ${
        ($showToSide === `left` && `translateX(-5px)`)
        || ($showToSide === `right` && `translateX(5px)`)
        || ($showAbove && `translateY(5px)`)
        || `translateY(-5px)`
      };
    }

    .MuiPopover-paper::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: white transparent transparent transparent;
      position: absolute;
      top: -10px;
      transform: rotate(180deg);
      left: calc(50% - 5.5px);

      ${!$showAbove ? `` : `
        bottom: -10px;
        top: auto;
        transform: none;
      `}

      ${$showToSide !== `left` ? `` : `
        bottom: auto;
        left: auto;
        right: -10px;
        top: calc(50% - 5.5px);
        transform: rotate(270deg);
      `}

      ${$showToSide !== `right` ? `` : `
        bottom: auto;
        right: auto;
        left: -10px;
        top: calc(50% - 5.5px);
        transform: rotate(90deg);
      `}
    }

  `}
`

const OptionsPopover = ({
  anchorEl,
  forceAbove=false,
  forceBelow=false,
  showToSide,
  hideArrow=false,
  horizontal,
  vertical,
  tabs,
  ...otherProps
}) => {

  const { height: windowHeight } = useAppSize()

  const previousShowAboveRef = useRef(!!forceAbove)
  const showAbove = useMemo(
    () => {
      if(forceAbove || forceBelow || !anchorEl) return previousShowAboveRef.current

      const { top, height } = anchorEl.getBoundingClientRect()
      previousShowAboveRef.current = (
        top > 375
        || top > windowHeight - top - height
      )

      return previousShowAboveRef.current
    },
    [ anchorEl, forceAbove, forceBelow, windowHeight ],
  )

  horizontal = horizontal || (hideArrow ? 'left' : 'center')

  const origins = useMemo(
    () => (
      (showToSide === `left` && {
        anchorOrigin: {
          vertical: `center`,
          horizontal: `left`,
        },
        transformOrigin: {
          vertical: `center`,
          horizontal: `right`,
        },
      })
      || (showToSide === `right` && {
        anchorOrigin: {
          vertical: `center`,
          horizontal: `right`,
        },
        transformOrigin: {
          vertical: `center`,
          horizontal: `left`,
        },
      })
      || (showAbove && {
        anchorOrigin: {
          vertical: vertical || 'top',
          horizontal,
        },
        transformOrigin: {
          vertical: vertical || 'bottom',
          horizontal,
        },
      })
      || {
        anchorOrigin: {
          vertical: vertical || 'bottom',
          horizontal,
        },
        transformOrigin: {
          vertical: vertical || 'top',
          horizontal,
        },
      }
    ),
    [ showToSide, showAbove, horizontal, vertical ],
  )

  return (
    <StyledPopover
      open={!!anchorEl}
      anchorEl={anchorEl}
      marginThreshold={5}
      TransitionComponent={Fade}
      $showToSide={showToSide}
      $hideArrow={hideArrow}
      $showAbove={showAbove}
      {...origins}
      {...otherProps}
    />
  )
}

export default memo(OptionsPopover)