const keyDownP = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    goPrintOrDownload,
  } = params

  if(modifierKey && !shiftKey) {
    event.preventDefault()
    goPrintOrDownload({ action: `print` })
  }

}

export default keyDownP