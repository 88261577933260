const fields = `
  id
  projectId
  ordering
  savedAt
`

// Not included here, but available:
  // project
  // module

export default fields