const fields = `
  id
  content
  info
  position
  ordering
  savedAt
  moduleId
`

// Not included here, but available:
  // module

export default fields