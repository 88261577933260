import { gql } from '@apollo/client'

import customStudyBibleItem_fields from '../fragments/customStudyBibleItem_fields'

const customStudyBibleItem = gql`
  query customStudyBibleItem($id: ID!) {
    customStudyBibleItem(id: $id) {
      ${customStudyBibleItem_fields}
    }
  }
`

export default customStudyBibleItem