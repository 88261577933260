/*

Considerations for poppers and popovers

--OptionsPopover--

  Use for:
    1. Simply UI components that grab complete focus for a user decision/selection
  
  Attributes: 
    - Has entire window to work with
    - Nothing else can get focus
    - Nothing scrollable
    - Nothing should be faded
    - Click anywhere on bg makes it go away, and does nothing else

--OptionsPopper--

  Use for:
    1. Passage, module, and note tab clickables (vs num, cf, dot notes, etc) that provide more information
    2. Passage text selection options

  Attributes: 
    - Contained within the scrollable area
    - Panel scrollables can still be scrolled
    - Click anywhere on panel makes it go away, and does nothing else
    - Panel faded except for clicked item (exception: when anchored by text selection)
    - User may interact with other panels while it is open (effective exception when anchored by text selection)

--Menu--

  Use for:
    1. Options Menu (usually opened by clicking a MoreVertIcon button)

*/

import { memo, useCallback, useMemo, useState, useRef } from 'react'
import styled from 'styled-components'
import Fade from '@material-ui/core/Fade'
import Popper from '@material-ui/core/Popper'
import { Modal } from '@material-ui/core'

import useAppSize from '../../hooks/useAppSize'
import useSetEmbedOnTop from '../../hooks/useSetEmbedOnTop'
import useEffectAsync from '../../hooks/useEffectAsync'
import { isTouchDevice, preventDefaultEvent } from '../../utils/misc'

import Loading from './Loading'

const getColor = ({ $bgColor, theme }) => (theme.palette.markupColors[$bgColor] || {}).arrow || $bgColor || theme.palette.grey[800]

const StyledModal = styled(Modal)`
  & > div {
    background-color: transparent !important;
  }
`

const ClearCover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  cursor: default;
  user-select: none;
`

const PseudoEl = styled.div`
  position: ${({ $onTopOfAll }) => $onTopOfAll ? `fixed` : `absolute`};
  z-index: -1;
`

const StyledPopper = styled(Popper)`

  z-index: 1300;
  position: absolute;
  box-sizing: border-box;

  &[x-out-of-boundaries],
  &[x-placement="${({ placement }) => placement === `top` ? `bottom` : `top`}"] {
    visibility: hidden;
  }

  ${({ $hide }) => !$hide ? `` : `
    display: none;
  `}

`

const OuterContainer = styled.div`
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 7px 1px rgb(0 0 0 / 14%), 0px 3px 8px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
`

const Container = styled.div`

  background: ${getColor};
  color: white;
  border-radius: 4px;
  overflow: hidden;

  ${({ $maxHeight }) => !$maxHeight ? `` : `
    .OptionsPopper-max-height-child {
      max-height: ${$maxHeight}px;
    }
  `}

  .MuiListItem-root:hover {
    background: rgb(255 255 255/.1);
  }

  .MuiDivider-root {
    background: rgb(255 255 255/.25);
  }

`

const Arrow = styled.div`

  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: ${getColor} transparent transparent transparent;
  position: absolute;

  ${({ $placement }) => {
    switch($placement) {
      case `left`: {
        return `
          right: -10px;
          transform: rotate(270deg);
        `
      }
      case `right`: {
        return `
          left: -10px;
          transform: rotate(90deg);
        `
      }
      case `bottom`: {
        return `
          top: -10px;
          transform: rotate(180deg);
        `
      }
      default: {
        return `
          bottom: -10px;
          transform: none;
        `
      }
    }
  }}

  &::before {
    content: "";
    position: absolute;
    left: -5px;
    top: -15px;
    height: 5px;
    width: 10px;
    background: ${getColor};
  }

`

const anchorElOffset = 15
const padding = 15

const OptionsPopper = ({
  open: openProp,
  anchorEl: anchorElProp,
  pseudoElStyle,
  onClose,
  forceAbove=false,
  forceBelow=false,
  hideArrow=false,
  onTopOfAll=false,
  isModal=false,
  setMaxHeight=false,
  verticalSpaceNeeded,
  preferShowAbove,
  preferShowBelow,
  placement,
  topOffset=0,  // for headers
  bottomOffset=0,  // for notes drawer, etc
  tabs,
  bgColor,
  isAnchoredBySelection=false,
  loading,
  children,
  allowDarkMode,
  className,
  ...otherProps
}) => {

  const [ open, setOpen ] = useState(false)
  const [ arrowRef, setArrowRef ] = useState()
  const { height: windowHeight } = useAppSize()

  const pseudoElRef = useRef()
  const anchorEl = pseudoElStyle ? pseudoElRef.current : anchorElProp
  const lastAnchorEl = useRef()
  lastAnchorEl.current = anchorEl || lastAnchorEl.current

  // TODO: if verticalSpaceNeeded in the available area but not above or below as-is, scroll anchorEl to the top and show below
  // TODO: if there simply is not enough room then show as OptionsPopover without arrow

  const availableSpaceInWindow = useRef(100)
  const showAbove = useRef(true)

  forceBelow = !!(isAnchoredBySelection && isTouchDevice()) || forceBelow

  useMemo(
    () => {

      if(!anchorEl) return 

      let { top, height } = anchorEl.getBoundingClientRect()
      const bottom = windowHeight - top - height - bottomOffset
      top -= topOffset

      if(forceBelow) {
        showAbove.current = false
      } else if(forceAbove) {
        showAbove.current = true
      } else if(preferShowAbove && verticalSpaceNeeded && top > verticalSpaceNeeded + anchorElOffset + padding) {
        showAbove.current = true
      } else if(preferShowBelow && verticalSpaceNeeded && bottom > verticalSpaceNeeded + anchorElOffset + padding) {
        showAbove.current = false
      } else {
        showAbove.current = top > bottom
      }

      availableSpaceInWindow.current = (showAbove.current ? top : bottom) - anchorElOffset - padding - 1

    },
    // since anchorEl will always be the same element when pseudoElStyle is used, I need pseudoElStyle and open in the following array...
    [ open, pseudoElStyle, anchorEl, forceAbove, forceBelow, windowHeight, topOffset, bottomOffset, preferShowAbove, preferShowBelow, verticalSpaceNeeded ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onUpdate = useCallback(
    ({ flipped, hide }) => {
      if(flipped || hide) {
        onClose && onClose()
      }
    },
    [ onClose ],
  )

  useEffectAsync(
    () => {
      // we want the value of `open` to be delayed in turning on until after a paint
      // so that the pseudo element is first positioned
      setOpen(openProp)
    },
    [ openProp ],
  )

  useSetEmbedOnTop(open)

  const boundariesElement = onTopOfAll ? 'window' : null
  const maxHeight = setMaxHeight ? availableSpaceInWindow.current : null
  const effectiveOpen = !!(open && openProp)
  placement = placement || (showAbove.current ? `top` : `bottom`)

  return (
    <>

      <PseudoEl
        ref={pseudoElRef}
        style={pseudoElStyle}
        $onTopOfAll={onTopOfAll}
      />

      {isModal &&
        <StyledModal
          open={effectiveOpen}
          onClose={onClose}
          children={<div />}
        />
      }

      <StyledPopper
        className={`${className} options-popper ${allowDarkMode ? `` : `dark-mode-exempt`}`}
        contentEditable={false}
        open={effectiveOpen}
        anchorEl={lastAnchorEl.current}
        transition
        disablePortal={!onTopOfAll}
        modifiers={{
          preventOverflow: {
            boundariesElement,
            padding: 8,
          },
          offset: {
            offset: `0, ${anchorElOffset}px`,
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
          flip: {
            enabled: false,
            // disabled: !onTopOfAll,
            // padding,
            // boundariesElement,
          },
          hide: {
            enabled: false,
          },
          computeStyle: {
            x: showAbove.current ? `top` : `bottom`,  // indicates where a change in size grows to; not sure why `x` and not `y`, but it works
          },
        }}
        placement={placement}
        popperOptions={{
          onUpdate,
        }}
        onMouseDown={isAnchoredBySelection ? preventDefaultEvent : null}  // preventDefaultEvent disallows text selection in popper
        {...otherProps}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={50}>
            <OuterContainer
              className="OptionsPopper-OuterContainer"
            >
              {!hideArrow &&
                <Arrow
                  ref={setArrowRef}
                  $placement={placement}
                  $bgColor={bgColor}
                />
              }
              <Container
                className="OptionsPopper-Container"
                $bgColor={bgColor}
                $maxHeight={maxHeight}
              >
                {children}
              </Container>
              {!!loading && <Loading bgOpacity={0} />}
            </OuterContainer>
          </Fade>
        )}
      </StyledPopper>

      {effectiveOpen && !!anchorElProp &&
        <ClearCover
          className="options-popper-clear-cover"
          // onTouchStart={preventDefaultEvent}
          onMouseDown={preventDefaultEvent}
          onClick={onClose}
        />
      }

    </>
  )
}

export default memo(OptionsPopper)