import React from "react"
import styled from 'styled-components'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

const Container = styled.div`
`

const StyledInsertDriveFileIcon = styled(InsertDriveFileIcon)`
  font-size: 130px;
  color: rgba(0,0,0,.2);
`

const ThumbnailLink = styled.a`
  float: left;
  display: block;
`

const Thumbnail = styled.img`
  width: 130px;
`

const Title = styled.a`
  display: block;
  padding-top: 10px;
  margin-left: 150px;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: inherit;

  :hover {
    text-decoration: underline;
  }
`

const Type = styled.span`
  display: inline-block;
  margin: 0 10px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: normal;
  color: rgba(0,0,0,.5);
`

const Description = styled.div`
  margin-top: 15px;
  margin-left: 150px;
`

const ClearLeft = styled.div`
  clear: left;
`

const Document = ({
  title="",
  description="",
  docHref="",
  thumbImgSrc,
}) => {

  const docTypeMatch = docHref.match(/\.([a-z0-9]+)$/i)

  return (
    <Container className="flipeditor-document">
      <ThumbnailLink
        className="flipeditor-document-thumbnail"
        href={docHref}
        target="_blank"
        download
      >
        {!!thumbImgSrc && <Thumbnail src={thumbImgSrc} />}
        {!thumbImgSrc && <StyledInsertDriveFileIcon />}
      </ThumbnailLink>
      {!!title.trim() &&
        <Title
          className="flipeditor-document-title"
          href={docHref}
          target="_blank"
          download
        >
          {title}
          {!!docTypeMatch &&
            <Type className="flipeditor-document-type">
              {docTypeMatch[1]}
            </Type>
          }
        </Title>
      }
      {!!description.trim() &&
        <Description className="flipeditor-document-description">
          {description}
        </Description>
      }
      <ClearLeft />
    </Container>
  )

}

export default Document