import { gql } from '@apollo/client'
import moduleMarkup_fields from '../fragments/moduleMarkup_fields'

const moduleMarkups = gql`
  query moduleMarkups($moduleId: ID!, $container: String) {
    moduleMarkups(moduleId: $moduleId, container: $container) {
      ${moduleMarkup_fields}
    }
  }
`

export default moduleMarkups