import { memo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Tooltip from '@material-ui/core/Tooltip'
import AssignmentIcon from '@material-ui/icons/Assignment'
import styled from 'styled-components'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import useVersionInfos from '../../hooks/useVersionInfos'

import OptionsPopover from './OptionsPopover'
import PasteInExternalTextDialog from './PasteInExternalTextDialog'

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  .MuiTooltip-tooltip {
    margin: 0 -10px;
  }
`

const StyledAssignmentIcon = styled(AssignmentIcon)`
  width: 16px;
  height: 16px;
  margin-right: 3px;
`

const ToBePastedNote = styled.div`
  font-size: 9px;
  margin-top: -2px;
  font-weight: 400;
`

const VersionPopover = ({
  versionsAlreadyInUse=[],
  restrictToTestamentBookId,
  onSelectVersion,
  onClose,
  fromLoc,
  toLoc,
  ...otherProps
}) => {

  const [ pasteInExternalTextDialogInfo, setPasteInExternalTextDialogInfo ] = useState({})

  const { unhiddenSelectedVersionInfos } = useVersionInfos({ restrictToTestamentBookId })

  const closePasteInExternalTextDialog = useCallback(
    () => {
      setPasteInExternalTextDialogInfo({
        ...pasteInExternalTextDialogInfo,
        versionId: null,
      })
    },
    [ pasteInExternalTextDialogInfo ],
  )

  const goSelectVersion = useCallback(
    params => {
      onSelectVersion(params)
      closePasteInExternalTextDialog()
      onClose()
    },
    [ onSelectVersion, closePasteInExternalTextDialog, onClose ],
  )

  const onClick = useCallback(
    ({ target }) => {
      const versionId = target.closest(`[data-id]`).getAttribute('data-id')
      if(versionId === `paste-in`) {
        setPasteInExternalTextDialogInfo({
          versionId,
          refs: [
            getRefFromLoc(fromLoc),
            getRefFromLoc(toLoc),
          ],
        })
      } else {
        goSelectVersion({ versionId })
      }
    },
    [ fromLoc, toLoc, goSelectVersion ],
  )

  return (
    <>

      <OptionsPopover
        onClose={onClose}
        {...otherProps}
      >
        <MenuList>

          {unhiddenSelectedVersionInfos.map(({ id, name, safeVersionAbbr }) => (
            <StyledTooltip
              key={id}
              placement="left"
              title={
                versionsAlreadyInUse.includes(id)
                  ? i18n("Already in use")
                  : ""
              }
            >
              <div>
                <MenuItem
                  onClick={onClick}
                  data-id={id}
                  disabled={versionsAlreadyInUse.includes(id)}
                >
                  {name
                    ? (
                      i18n("{{title}} ({{subtitle}})", {
                        title: name,
                        subtitle: safeVersionAbbr,
                      })
                    )
                    : safeVersionAbbr
                  }
                </MenuItem>
              </div>
            </StyledTooltip>
          ))}

          {/* TODO: This is temporary with legacy modules and until I set up versions of type PERSONAL */}
          <StyledTooltip
            placement="left"
            title={
              versionsAlreadyInUse.includes(`mine`)
                ? i18n("Already in use")
                : "Your own translation or paraphrase"
            }
          >
            <div>
              <MenuItem
                onClick={onClick}
                data-id="mine"
                disabled={versionsAlreadyInUse.includes(`mine`)}
              >
                MINE
              </MenuItem>
            </div>
          </StyledTooltip>

          <StyledTooltip
            placement="left"
            title={
              <>
                {i18n("Alternative Text")}
                <ToBePastedNote>
                  {i18n("To be pasted in")}
                </ToBePastedNote>
              </>
            }
          >
            <div>
              <MenuItem
                onClick={onClick}
                data-id="paste-in"
              >
                <StyledAssignmentIcon />
                {i18n("Other")}
              </MenuItem>
            </div>
          </StyledTooltip>

        </MenuList>
      </OptionsPopover>

      <PasteInExternalTextDialog
        open={!!pasteInExternalTextDialogInfo.versionId}
        onClose={closePasteInExternalTextDialog}
        onConfirm={goSelectVersion}
        {...pasteInExternalTextDialogInfo}
      />

    </>
  )
}

export default memo(VersionPopover)