import React, { forwardRef, memo, useMemo, useRef, useContext, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { useMutation, useApolloClient } from '@apollo/client'
import { getRefFromLoc, getLocFromRef, getCorrespondingRefs } from '@bibletags/bibletags-versification'

import { KJV_VERSION } from '../../utils/constants'
import { getOrigVersionInfo, getGrammarColorCSSRules } from '../../utils/misc'
import { LoggedInUserContext } from '../../context/LoggedInUser'
import usePassagePopper from '../../hooks/usePassagePopper'
import useAppSize from '../../hooks/useAppSize'
import useOptionsPopperInfo from '../../hooks/useOptionsPopperInfo'
import useTextSelectionInfo from '../../hooks/useTextSelectionInfo'
import useMutationContext from '../../hooks/useMutationContext'
import useVersionInfo from '../../hooks/useVersionInfo'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import useSetStudyBibleItemInfo from '../../hooks/useSetStudyBibleItemInfo'
import defaultSelectText from './selection-utils/selectText'

import OptionsPopper from '../common/OptionsPopper'

import createStudyBibleItemMutation from '../../graphql/mutations/createStudyBibleItem'

const Container = styled.div`

  ${({ $optionsPopperOpen }) => !$optionsPopperOpen ? `` : `
    & > :not(.options-popper):not(.options-popper-clear-cover):not(.options-popper-no-pointer-events-exempt),
    .options-popper-no-pointer-events,
    .options-popper-no-pointer-events-children > * {
      pointer-events: none;
    }
  `}

  ${({ $popperOpen, $wordIds, $selectedVsNumId, $selectedDotId, theme }) => !$popperOpen ? `` : `
    color: ${theme.palette.grey[400]} !important;

    .fade-when-passage-popper-open,
    .TextContent-tag-zApparatusJson,
    .TextContent-tag-x,
    .TextContent-tag-f {
      opacity: .3;  ${/* Opacity instead of color since color has a transition on it (for the tags) that I do not want in effect. */ ``}
    }

    ${Object.keys($wordIds).map(id => {
      const selector = `[data-word-id="${id.replace(/"/g, '')}"]`
      return `
        ${selector} {
          color: ${$wordIds[id] || `black`};
        }
        ${getGrammarColorCSSRules({ selector })}
      `
    })}

    ${!$selectedVsNumId ? `` : `
      [data-vsnum-id="${$selectedVsNumId}"] {
        color: black;
      }
    `}

    ${!$selectedDotId ? `` : `
      [data-dot-id="${$selectedDotId}"] {
        color: black;
        opacity: 1;
      }
    `}

  `}
`

let instanceIdx = 0

const ContainerWithPassagePopper = ({
  // usePassagePopper props
  width,  // pass in unless onTopOfAll === true
  versionId,  // also for useOptionsPopperInfo
  bookId,  // also for useOptionsPopperInfo
  closePopperOnChangeText,

  // useOptionsPopperInfo props
  selectText=defaultSelectText,
  markup,
  updateMarkup,
  highlights,
  moduleMarkups,
  formattingKeyId,
  formattingKeyInfo,
  updateFormattingKeyInfo,
  lenses,
  selectedColor,
  setSelectedColor,
  projectId,
  moduleId,
  existingMarkupDetailsByType,
  mode,
  inEditingMode,
  noTextSelection,
  updatePassageBookmark,
  showMarkupOption,
  toggleShowNoteInfoDialog,

  // OptionsPopper props
  onTopOfAll,
  verticalSpaceNeeded,
  topOffset,
  bottomOffset,
  setMaxHeight,
  preferShowAbove,
  overridesByPopperType={},  // should be memoed before sent in
  setSnackbarInfo,

  goSetPopperInfoOverride,
  className,
  children,
  ...otherProps
}, ref) => {

  const { adminLevel } = useContext(LoggedInUserContext) || {}
  const [ createStudyBibleItem ] = useMutation(createStudyBibleItemMutation)
  const context = useMutationContext()
  const { version } = useVersionInfo(versionId)
  const client = useApolloClient()
  const [ showEdit, toggleShowEdit ] = useSimpleToggle()
  const setStudyBibleItemInfo = useSetStudyBibleItemInfo()

  const { width: windowWidth } = useAppSize()
  width = width || windowWidth

  let {
    ids,
    selectedVsNumId,
    selectedDotId,
    anchorEl,
    goSetPopperInfo,
    passagePopperInfo,
    closePopper: closePassagePopper,
  } = usePassagePopper({
    width,
    versionId,
    closePopperOnChangeText,
    setMaxHeight,
  })
  goSetPopperInfo = goSetPopperInfoOverride || goSetPopperInfo

  const showCreateStudyBibleItem = !!(
    ['ADMIN', 'EDITOR', 'CONTRIBUTOR'].includes(adminLevel)
    && updatePassageBookmark
  )
  const goCreateStudyBibleItem = useCallback(
    async ({ selectionStart, selectionEnd }) => {
      if(!showCreateStudyBibleItem) return

      const [ fromRefs, toRefs ] = [ selectionStart, selectionEnd ].map(({ loc, wordNumberInVerse }, idx) => (
        getCorrespondingRefs({
          baseVersion: {
            ref: getRefFromLoc(`${loc}:${wordNumberInVerse}`),
            info: version || KJV_VERSION,
          },
          lookupVersionInfo: getOrigVersionInfo(),
          directionToTryIfSkipped: idx === 0 ? `next` : `previous`,
        })
      ))
    
      if(!fromRefs || !toRefs) return

      toggleShowEdit(true)

      const { data: { createStudyBibleItem: studyBibleItem } } = await createStudyBibleItem({
        variables: {
          input: {
            fromLoc: getLocFromRef(fromRefs[0]).split(':')[0],
            toLoc: getLocFromRef(toRefs.at(-1)).split(':')[0],
          },
        },
        context,
      })

      await client.refetchQueries({
        include: [ 'studyBibleItems' ],
        // onQueryUpdated: ({ options: { variables }}) => projectId === variables.id,
        // TODO: get fewer by checking that we are in the same chapter
      })

      toggleShowEdit(false)
      setStudyBibleItemInfo({
        studyBibleItem,
        versionId,
        goStraightToEditor: true,
      })

      window.getSelection().empty()

    },
    [ showCreateStudyBibleItem, version, createStudyBibleItem, context, client, setStudyBibleItemInfo, toggleShowEdit, versionId ],
  )

  const instanceKey = useRef(`key${++instanceIdx}`)
  const textSelectionInfo = useTextSelectionInfo(`.container-with-passage-popper[data-instancekey="${instanceKey.current}"]`)
  const { optionsPopperInfo, closeOptions } = useOptionsPopperInfo({
    selectText,
    textSelectionInfo,
    markup,
    updateMarkup,
    highlights,
    showMarkupOption,
    moduleMarkups,
    formattingKeyId,
    formattingKeyInfo,
    updateFormattingKeyInfo,
    lenses,
    selectedColor,
    setSelectedColor,
    moduleId,
    projectId,
    existingMarkupDetailsByType,
    mode,
    inEditingMode,
    noTextSelection,
    bookId,
    versionId,
    onTopOfAll: (overridesByPopperType.options || {}).onTopOfAll || onTopOfAll,
    goCreateStudyBibleItem: showCreateStudyBibleItem && goCreateStudyBibleItem,
    setSnackbarInfo,
    setStudyBibleItemInfo,
    toggleShowNoteInfoDialog,
  })

  const optionsPopperOpen = !!optionsPopperInfo.open
  const passagePopperOpen = !!passagePopperInfo.open

  const lastOpenPopper = useRef(`passage`)
  useMemo(
    () => {
      if(optionsPopperOpen || passagePopperOpen) {
        lastOpenPopper.current = optionsPopperOpen ? `options` : `passage`
      }
    },
    [ optionsPopperOpen, passagePopperOpen ],
  )
  const popperInfo = lastOpenPopper.current === `passage` ? passagePopperInfo : optionsPopperInfo

  const overrides = overridesByPopperType[lastOpenPopper.current]
  if(overrides) {
    onTopOfAll = overrides.onTopOfAll || onTopOfAll
    verticalSpaceNeeded = overrides.verticalSpaceNeeded || verticalSpaceNeeded
    topOffset = overrides.topOffset || topOffset
    bottomOffset = overrides.bottomOffset || bottomOffset
    setMaxHeight = overrides.setMaxHeight || setMaxHeight
    preferShowAbove = overrides.preferShowAbove || preferShowAbove
  }

  useLayoutEffectAsync(
    () => {
      if(optionsPopperOpen) {
        // selected text overrides passage popper click
        closePassagePopper()
      }
    },
    [ optionsPopperOpen ],
  )

  const childrenWithAddlProps = useMemo(
    () => (
      React.Children.map(children, child => (
        React.isValidElement(child)
          ? (
            React.cloneElement(child, {
              goSetPopperInfo,
            })
          )
          : child
      ))
    ),
    [ children, goSetPopperInfo ],
  )

  return (
    <Container
      ref={ref}
      $popperOpen={!!(ids && anchorEl)}
      $optionsPopperOpen={!!popperInfo.open}
      $wordIds={ids}
      $selectedVsNumId={selectedVsNumId}
      $selectedDotId={selectedDotId}
      className={`${className} container-with-passage-popper`}
      data-instancekey={instanceKey.current}
      {...otherProps}
    >

      {childrenWithAddlProps}

      <OptionsPopper
        onClose={closeOptions}  // overwriten if it is a passage popper
        onTopOfAll={onTopOfAll}
        verticalSpaceNeeded={verticalSpaceNeeded}
        preferShowAbove={preferShowAbove}
        topOffset={topOffset}
        bottomOffset={bottomOffset}
        setMaxHeight={setMaxHeight}
        loading={showEdit}
        {...popperInfo}
      />

    </Container>
  )
}

export default memo(forwardRef(ContainerWithPassagePopper))