import { memo, useState, useCallback } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { i18n } from 'inline-i18n'

import useModalAnchor from '../../../hooks/useModalAnchor'

import ConfirmDialog from '../../common/ConfirmDialog'
import MenuDivider from '../../common/MenuDivider'
import DangerMenuItem from '../../common/DangerMenuItem'

const Container = styled.div`
  padding: 0 5px;
`

const ProjectsListTrashMenu = ({
  project,
  goRestoreProjects,
  goPermanentlyDeleteProjects,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const [ showConfirm, setShowConfirm ] = useState(false)

  const confirmPermanentlyDeleteProject = useCallback(
    () => {
      closeModal()
      setShowConfirm(true)
    },
    [ setShowConfirm, closeModal ],
  )

  const onConfirmCancel = useCallback(() => setShowConfirm(false), [ setShowConfirm ])

  const goRestoreProject = useCallback(
    () => {
      closeModal()
      goRestoreProjects({ projectIds: [ project.id ] })
    },
    [ goRestoreProjects, project, closeModal ],
  )

  const goPermanentlyDeleteProject = useCallback(
    () => {
      onConfirmCancel()
      goPermanentlyDeleteProjects({ projectIds: [ project.id ] })
    },
    [ goPermanentlyDeleteProjects, project, onConfirmCancel ],
  )

  return (
    <Container>

      <IconButton
        onClick={openModal}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeModal}
      >
        <MenuItem
          onClick={goRestoreProject}
        >
          {i18n("Restore")}
        </MenuItem>
        <MenuDivider />
        <DangerMenuItem
          onClick={confirmPermanentlyDeleteProject}
        >
          {i18n("Delete forever")}
        </DangerMenuItem>
      </Menu>

      <ConfirmDialog
        open={showConfirm}
        onClose={onConfirmCancel}
        onConfirm={goPermanentlyDeleteProject}
        title={i18n("Delete Forever?")}
        explanation={i18n("“{{name}}” will be permanently deleted and you will not be able to restore it.", {
          name: project.name || i18n("Untitled"),
        })}
        confirmButtonLabel={i18n("Delete forever")}
        doubleConfirm
      />

    </Container>
  )
}

export default memo(ProjectsListTrashMenu)