import { gql } from '@apollo/client'

import { subsetDefs, subsetMaps } from '../fragments/subset_parameters'
import customStudyBibleItem_fields from '../fragments/customStudyBibleItem_fields'

const customStudyBibleItems = gql`
  query customStudyBibleItems($query: String, ${subsetDefs}) {
    customStudyBibleItems(query: $query, ${subsetMaps}) {
      customStudyBibleItems {
        ${customStudyBibleItem_fields}
      }
      count
    }
  }
`

export default customStudyBibleItems