import { useCallback } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import useMutationContext from './useMutationContext'
import useInstanceValue from './useInstanceValue'
import { cloneObj, getVersionIdForBibleTags } from '../utils/misc'

import myTagSetSubmissionsQuery from '../graphql/queries/myTagSetSubmissions'
import submitTagSetMutation from '../graphql/mutations/submitTagSet'

const useGoSubmitTagSet = ({
  tagSet: currentTagSet,
  translationWords,
  getTags,
  setTagsSticky,
 }) => {

  const client = useApolloClient()
  const context = useMutationContext()
  const getContext = useInstanceValue(context)

  const [ submitTagSet, submitTagSetResult ] = useMutation(submitTagSetMutation)

  const goSubmitTagSet = useCallback(
    ({ properlyCaptitalizedTranslationWordByWordNumberInVerse={} }={}) => {

      const tags = cloneObj(getTags())
      const { id, status } = currentTagSet
      const [ loc, versionId, wordsHash ] = id.split(`-`)
      const { bookId, chapter } = getRefFromLoc(loc)

      const originalVersionIdForBibleTags = getVersionIdForBibleTags({ versionId: `original`, bookId })

      // TODO: this needs to be calculated for each tag based on comparing tagSet with tags
      const alignmentType = `without-suggestion`  // affirmation, correction, without-suggestion

      const tagSubmissions = (
        tags.map(tag => ({
          origWordsInfo: tag.o.map(wordIdAndPartNumber => {
            const [ wordId, wordPartNumber ] = wordIdAndPartNumber.split(`|`)
            return {
              [`${originalVersionIdForBibleTags}WordId`]: wordId,
              wordPartNumber: parseInt(wordPartNumber, 10) || 1,
            }
          }),
          translationWordsInfo: tag.t.map(wordNumberInVerse => ({
            word: (
              properlyCaptitalizedTranslationWordByWordNumberInVerse[wordNumberInVerse]
              || (translationWords.find(word => word.wordNumberInVerse === wordNumberInVerse) || {}).text
            ),
            wordNumberInVerse,
          })),
          alignmentType,
        }))
      )

      const variables = {
        input: {
          loc,
          versionId,
          wordsHash,
          tagSubmissions,
        },
      }

      const myTagSet = {
        __typename: `MyTagSet`,
        id,
        tags: cloneObj(tags),
      }

      let tagSet = cloneObj(currentTagSet)

      if([ `none`, `automatch` ].includes(status)) {
        tagSet = {
          ...tagSet,
          tags: cloneObj(tags),
          status: `unconfirmed`,
        }
      }

      const expectedResponse = {
        submitTagSet: {
          __typename: "TagSetSubmissionUpdate",
          myTagSet,
          tagSet,
        },
      }

      submitTagSet({
        variables,
        context: {
          ...getContext(),
          expectedResponse,
        },
        optimisticResponse: cloneObj(expectedResponse),
      })

      const myTagSetSubmissionsParams = {
        query: myTagSetSubmissionsQuery,
        variables: {
          bookId,
          chapter,
          versionId,
        },
      }

      const { myTagSetSubmissions } = client.readQuery(myTagSetSubmissionsParams)

      if(
        myTagSetSubmissions
        && !myTagSetSubmissions.find(myTagSet => myTagSet.id === id)
      ) {
        client.writeQuery({
          ...myTagSetSubmissionsParams,
          data: {
            myTagSetSubmissions: cloneObj([
              ...myTagSetSubmissions,
              myTagSet,
            ]),
          },
        })
      }

      setTagsSticky(myTagSet.tags)  // this needed in order to trigger the sticky ref state to clear

      return cloneObj(myTagSet)
    },
    [ submitTagSet, getContext, currentTagSet, translationWords, getTags, client, setTagsSticky ],
  )

  if(submitTagSetResult.error) {
    // Nothing to do here since it has gone into queuedMutations and will try again when relevant
    console.error('submitTagSetResult.error', submitTagSetResult.error)
  }

  return goSubmitTagSet
}

export default useGoSubmitTagSet