const cnsl = {
  log: console.log,
  warn: console.warn,
  error: console.error,
}

const filterConsole = type => (...args) => {

  if(!window.showAllConsoleLogs) {
    if(
      args.some(arg => (
        false
        || /^Warning: findDOMNode is deprecated and will be removed in the next major release/.test(arg)  // caused by MaterialUI 4
        || /Support for defaultProps will be removed from memo components in a future major release/.test(arg)  // caused by react-beautiful-dnd
      ))
    ) return
  }

  cnsl[type](...args)

}

console.log = filterConsole(`log`)
console.warn = filterConsole(`warn`)
console.error = filterConsole(`error`)