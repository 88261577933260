import { gql } from '@apollo/client'

const stripeSubscriptionNextInvoice = gql`
  query stripeSubscriptionNextInvoice {
    stripeSubscriptionNextInvoice {
      total
      attemptAt
    }
  }
`

export default stripeSubscriptionNextInvoice