import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import selectWholeWords from '../../../../utils/selectWholeWords'

import FormattingMenuList from '../../../markup/FormattingMenuList'

const Container = styled.div`
  direction: ltr;  // we do not want to inherit the rtl of the container if reading Hebrew
`

const selectText = async ({
  textSelectionInfo,
  closeOptions,
  getOptionsPopperInfo,
  setOptionsPopperInfo,
  ...otherProps
}) => {

  const {
    selection,
    textRange,
    commonAncestorEl,
    knownToBeStillSelecting,
  } = textSelectionInfo

  const scrollContainerEl = commonAncestorEl ? commonAncestorEl.closest(`.MarkupMainPanel-Container`) : null

  if(
    !textRange
    || knownToBeStillSelecting
    || !scrollContainerEl
    || commonAncestorEl.closest(`[contenteditable]`)
  ) {

    const { $type } = getOptionsPopperInfo()
    if($type === 'selection' || !window.getSelection().isCollapsed) {
      closeOptions()
    }

    return
  }

  const {
    // words,
    // bookId,
    // selectionStart,
    // selectionEnd,
    // completeVersesSelection,
    // completeVersesSelection,
    // allWordEls,
    // isAtStartOfFirstWordEl,
    // isAtEndOfLastWordEl,
    // selectionWithinOneWord,
    // partialWordSelection,
    // words,
    // bookId,
    selectionStart,
    selectionEnd,
    firstWordEl,
    lastWordEl,
  } = selectWholeWords({
    selection,
    textSelectionInfo,
  })

  const boundingClientRect = textRange.getBoundingClientRect()
  const containerBoundingClientRect = scrollContainerEl.getBoundingClientRect()

  const pseudoElStyle = {
    top: boundingClientRect.top - containerBoundingClientRect.top + scrollContainerEl.scrollTop,
    // left: boundingClientRect.left - containerBoundingClientRect.left - 15,  // 15 for the negative margin
    left: boundingClientRect.left - containerBoundingClientRect.left,
    width: boundingClientRect.width,
    height: boundingClientRect.height,
  }

  if(
    boundingClientRect.width === undefined
    || !firstWordEl
    || !lastWordEl
  ) {
    closeOptions()
    return
  }

  setOptionsPopperInfo({
    open: true,
    $type: 'selection',
    isAnchoredBySelection: true,
    pseudoElStyle,
    tabs: [
      {
        icon: null,
        tooltipLabel: i18n("Info", "", "phrasing"),
        content: (
          null
        ),
      },
      {
        icon: null,
        tooltipLabel: i18n("Edit", "", "phrasing"),
        content: (
          null
        ),
      },
    ],
    children: (
      <Container>

        <FormattingMenuList
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
          firstWordEl={firstWordEl}
          lastWordEl={lastWordEl}
          {...otherProps}
        />

      </Container>
    ),
  })
}

export default selectText