import { memo, forwardRef, useContext } from 'react'
import styled from 'styled-components'
import { NavLink } from "react-router-dom"

import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'

const A = styled.a`

  ${({ $removeLinkStyle }) => !$removeLinkStyle ? `` : `

    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
    }

  `}

`

const StyledNavLink = styled(NavLink)`

  ${({ $removeLinkStyle }) => !$removeLinkStyle ? `` : `

    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
    }

  `}

`

const NavLinkOrAWithDisable = ({
  disabled,
  children,
  to,
  exact,
  keepLinkStyle,
  sameTab,
  ...otherProps
}, ref) => {

  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  if(disabled) {
    return (
      <span ref={ref} {...otherProps}>
        {children}
      </span>
    )
  }

  const removeLinkStyle = !keepLinkStyle && typeof children !== `string`

  if(
    to
    && channelIdInPWA
    && /^\/(?:message|map|settings|tag|version)(?:\/|$)/.test(to)
  ) {
    to = `/church/${channelIdInPWA}${to}`
  }

  if(/^https?:\/\//.test(to)) {
    return (
      <A
        ref={ref}
        href={to}
        target={sameTab ? "" : "_blank"}
        rel="noreferrer"
        $removeLinkStyle={removeLinkStyle}
        {...otherProps}
      >
        {children}
      </A>
    )
  }

  return (
    <StyledNavLink
      ref={ref}
      to={to}
      exact={exact}
      $removeLinkStyle={removeLinkStyle}
      {...otherProps}
    >
      {children}
    </StyledNavLink>
  )
}

export default memo(forwardRef(NavLinkOrAWithDisable))