import { memo } from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import InfoIcon from '@material-ui/icons/Info'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import useSimpleToggle from '../../../hooks/useSimpleToggle'

import InfoDialog from '../../common/InfoDialog'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  user-select: none;
`

const Spacer = styled.div`
  flex: 1;
`

const Title = styled.div`
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
`

const StyledButton = styled(Button)`
  height: 38px;
  text-transform: none;
  font-size: 18px;
  font-weight: normal;
  margin: -6px -8px;
  padding-right: 3px;
  
  @media (min-width: 801px) {
    &:hover {
      background: none;
      cursor: default;
    }
    .MuiTouchRipple-root {
      display: none;
    }
  }
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin: 0 3px;
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`

const StyledIconButton = styled(IconButton)`
  margin: -20px -8px -20px 5px;
  color: black;

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const ExpandIconButton = styled(StyledIconButton)`
  display: none;

  @media (max-width: 900px) {
    display: block;
  }
`

const SettingsListHeader = ({
  icon,
  children,
  expandLeftPanel,
  expandRightPanel,
  extraInfo,
}) => {

  const [ showInfo, toggleShowInfo ] = useSimpleToggle()

  return (
    <Container>

      <StyledButton
        onClick={expandLeftPanel}
      >

        {icon}

        <Title>
          {children}
        </Title>

        <StyledExpandMoreIcon />

      </StyledButton>

      <Spacer />

      {!!extraInfo &&
        <>

          <StyledIconButton
            onClick={toggleShowInfo}
          >
            <InfoIcon />
          </StyledIconButton>

          <InfoDialog
            open={showInfo}
            onOkay={toggleShowInfo}
            explanation={extraInfo}
            color="secondary"
          />

        </>
      }

      {!!expandRightPanel &&
        <ExpandIconButton
          onClick={expandRightPanel}
        >
          <AddIcon />
        </ExpandIconButton>
      }

    </Container>
  )
}

export default memo(SettingsListHeader)