import { gql } from '@apollo/client'

import { getUserFields } from '../fragments/user_fields'

export default gql
`
  mutation createUser($email: String!) {
    createUser(email: $email) {
      ${getUserFields({ isAdmin: true })}
    }
  }
`