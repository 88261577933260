import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'

const DangerMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  .MuiListItemIcon-root {
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`

export default DangerMenuItem