import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useBookIds from '../../hooks/useBookIds'

import BookChooserBook from './BookChooserBook'

const Container = styled.div`
  height: 100%;
  padding: 7px 0;
  overflow: auto;
`

const BookChooser = ({
  versionId,
  selectedBookId,
  setBookId,
}) => {

  const bookIds = useBookIds(versionId)

  return (
    <Container>
      {bookIds.map(bookId => (
        <BookChooserBook
          key={bookId}
          bookId={bookId}
          isSelected={bookId === selectedBookId}
          setBookId={setBookId}
        />
      ))}
    </Container>
  )
}

export default memo(BookChooser)