import { useEffect, useState } from 'react'
import useInstanceValue from './useInstanceValue'

window.biblearcExecutePostponedFocus = ()=>{}

const useFocusContainerSetRef = ({
  focusElSelector,
}) => {

  const [ containerRef, setContainerRef ] = useState()
  const getFocusElSelector = useInstanceValue(focusElSelector)

  useEffect(
    () => {

      if(!containerRef) return

      const mouseDownOrTouchStart = async event => {

        const { target } = event

        const toBeActiveElement = document.querySelector(getFocusElSelector())

        if(target.closest('[data-postpone-focus="true"]')) {

          window.biblearcExecutePostponedFocus = () => {
            toBeActiveElement && toBeActiveElement.focus()
            window.biblearcExecutePostponedFocus = ()=>{}
          }

        } else {

          if(target.closest(getFocusElSelector()) !== toBeActiveElement) {
            const mouseUp = () => {
              toBeActiveElement && toBeActiveElement.focus()
              containerRef.removeEventListener('mouseup', mouseUp)
            }
            containerRef.addEventListener('mouseup', mouseUp)
            // Next comment line left for troubleshooting
            // setTimeout(() => console.log('document.activeElement', document.activeElement), 500)
          } else {
            toBeActiveElement && toBeActiveElement.focus()
          }

        }

      }

      containerRef.addEventListener('mousedown', mouseDownOrTouchStart)
      // containerRef.addEventListener('touchstart', mouseDownOrTouchStart)  // DO NOT USE: This stops onClick from working; the mousedown event handles the focus
      
      return () => {
        if(!containerRef) return
        containerRef.removeEventListener('mousedown', mouseDownOrTouchStart)
        // containerRef.removeEventListener('touchstart', mouseDownOrTouchStart)
      }

    },
    [ containerRef, getFocusElSelector ],

  )
  return setContainerRef
}

export default useFocusContainerSetRef