import { gql } from '@apollo/client'

import globalAlertsItem_fields from '../fragments/globalAlertsItem_fields'

const globalAlertsItems = gql`
  query globalAlertsItems($query: String, $limit: Int) {
    globalAlertsItems(query: $query, limit: $limit) {
      ${globalAlertsItem_fields}
    }
  }
`

export default globalAlertsItems