import { memo } from 'react'
import { i18n } from 'inline-i18n'
// import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import useThrottleCallback from '../../../hooks/useThrottleCallback'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useEffectAsync from '../../../hooks/useEffectAsync'

import BibleMapInfoName from './BibleMapInfoName'
import BibleMapInfoEvent from './BibleMapInfoEvent'
import BibleMapInfoBody from './BibleMapInfoBody'
import Editor, { isEmptyContent } from '../../editor/Editor'
import RelativeTime from '../../common/RelativeTime'

const About = styled.div`
`

const Notes = styled.div`
`

const AboutContent = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const Events = styled.div`
`

const Heading = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LastUpdated = styled.div`
  font-size: 9px;
  font-weight: 300;
  text-align: right;
  margin: 5px 0 0;
`

const EventItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0;
`

const NoInfo = styled.div`
  font-weight: 200;
`

const EditorContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main}44;
  padding: ${({ $editing }) => $editing ? `43px 12px 1px 12px` : `1px 12px`};
  border-radius: 5px;
  margin: 5px 0 0;
  overflow-y: auto;
  max-height: min(300px, calc(100vh - 250px));
  position: relative;

  .Editor-EditorContainer {
    margin: -3px 0;
  }

  .ToolbarPlugin-Container {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
  }

`

const EditNotesIconButton = styled(IconButton)`
  padding: 10px;
  margin: -20px -8px -20px 0;

  .MuiSvgIcon-root {
    font-size: 16px;
  }

  ${({ $editing, theme }) => !$editing ? `` : `
    color: ${theme.palette.primary.main};
  `};
`

const BibleMapPlaceInfo = ({
  place,
  setSelectedJourney,
  setSelectedPerson,
  journeys,
  persons,
  expandedEventId,
  setExpandedEventId,
  mapNotePlace,
  setMapNotePlace,
}) => {

  const { mapNoteEvents={} } = mapNotePlace
  const hasNote = mapNotePlace.note !== undefined

  const goSave = useInstanceValuesCallback(note => setMapNotePlace({ ...mapNotePlace, mapNoteEvents, note, updatedAt: Date.now() }))
  const [ goThrottledSave, flushSave ] = useThrottleCallback(goSave, 500)
  const [ editing, toggleEditing ] = useSimpleToggle(false)

  useEffectAsync(
    () => {
      if(mapNotePlace.note === ``) {
        // set to editing if it was just created
        toggleEditing({ force: true })
      }
    },
    [ mapNotePlace.note ],
  )

  useEffectAsync(
    () => {
      if(
        !editing
        && (
          mapNotePlace.note === ``
          || isEmptyContent(mapNotePlace.note)
        )
      ) {
        goSave()
      }
    },
    [ editing ],
  )

  return (
    <BibleMapInfoBody
      className="BibleMapPlaceInfo-BibleMapInfoBody"
    >

      {place.names.map(({ id, ...nameInfo }, idx) => (
        <BibleMapInfoName
          key={id}
          type="place"
          // isPrimary={idx === 0}
          skipLabel={idx === 0}
          {...nameInfo}
        />
      ))}

      {!!place.about &&
        <About>
          <Heading>
            {i18n("About")}
          </Heading>
          <AboutContent>
            {place.about}
          </AboutContent>
        </About>
      }

      {!place.about && place.events.length === 0 &&
        <NoInfo>
          {i18n("No further information available.")}
        </NoInfo>
      }

      {hasNote &&
        <Notes>
          <Heading>
            {i18n("My Notes")}
            <EditNotesIconButton
              $editing={editing}
              onClick={toggleEditing}
            >
              <EditIcon />
            </EditNotesIconButton>
          </Heading>
          <EditorContainer
            $editing={editing}
            className="BibleMapPlaceInfo-EditorContainer"
          >
            <Editor
              key={`${place.id} ${editing ? `editing` : `not-editing`}`}
              editable={editing}
              initialContent={mapNotePlace.note}
              onUpdate={goThrottledSave}
              onBlur={flushSave}
              autoFocus
              maxLength={2000}
            />
          </EditorContainer>
          {!!mapNotePlace.updatedAt &&
            <LastUpdated>
              <RelativeTime date={mapNotePlace.updatedAt} />
            </LastUpdated>
          }
        </Notes>
      }


      {place.events.length > 0 &&
        <Events>
          <Heading>
            {i18n("Events")}
          </Heading>
          <EventItems>
            {place.events.map(event => (
              <BibleMapInfoEvent
                key={event.id}
                {...event}
                place={place}
                explanded={expandedEventId === event.id}
                setExpandedEventId={setExpandedEventId}
                setSelectedJourney={setSelectedJourney}
                setSelectedPerson={setSelectedPerson}
                journeys={journeys}
                persons={persons}
                mapNoteEvent={mapNoteEvents[event.id] || {}}
                setMapNoteEvent={setMapNoteEvent => {
                  setMapNotePlace({
                    ...mapNotePlace,
                    mapNoteEvents: {
                      ...mapNoteEvents,
                      [event.id]: setMapNoteEvent,
                    },
                  })
                }}
              />
            ))}
          </EventItems>
        </Events>
      }

    </BibleMapInfoBody>
  )
}

export default memo(BibleMapPlaceInfo)