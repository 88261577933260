const fields = `
  id
  note
  info
  shared
  fromLoc
  fromWordNumber
  fromCharacterNumber
  toLoc
  toWordNumber
  toCharacterNumber
  createdAt
  savedAt
  userId
  versionId
`

export default fields