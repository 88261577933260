import { memo, useCallback } from 'react'
// import styled from 'styled-components'
import List from '@material-ui/core/List'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import FormatClearIcon from '@material-ui/icons/FormatClear'
import DeleteIcon from '@material-ui/icons/Delete'
import { i18n } from 'inline-i18n'

import { IS_EMBED } from '../../../utils/constants'
import { getEmbedSettings } from '../../../graphql/links/embedLink'

import DangerMenuItem from '../../common/DangerMenuItem'

const PassageInNotesActions = ({
  updateMarkup,
  flipEditorDeleteThisBlock,
  onClose,
}) => {

  // const user = useContext(LoggedInUserContext)
  // const { hasToolsPlan } = user || {}

  // const { bookId } = refs[0] || {}
  // const [ selectedVersionId, setSelectedVersionId ] = useState(versionId)

  // const [ printDialogInfo, setPrintDialogInfo ] = useState()

  // const { openPassage } = useOpenPassage()

  // const goRead = useCallback(
  //   () => {
  //     const refs = [ getRefFromLoc(fromLoc) ]
  //     if(toLoc && toLoc !== fromLoc) {
  //       refs.push(getRefFromLoc(toLoc))
  //     }
  //     closeAndClearSearch()
  //     openPassage({
  //       refs,
  //       versionId,
  //     })
  //   },
  //   [ fromLoc, toLoc, versionId, openPassage, closeAndClearSearch ],
  // )


  const clearMarkup = useCallback(
    () => {
      updateMarkup([])
    },
    [ updateMarkup ],
  )

  const deleteThisBlock = useCallback(
    () => {
      onClose()
      setTimeout(flipEditorDeleteThisBlock)  // timeout needed for the close to work
    },
    [ flipEditorDeleteThisBlock, onClose ],
  )

  return (
    <>

      <List>

        <MenuItem
          onClick={clearMarkup}
        >
          <ListItemIcon>
            <FormatClearIcon fontSize="small" />
          </ListItemIcon>
          {i18n("Clear Markup")}
        </MenuItem>

        {IS_EMBED && getEmbedSettings().embedType === `passages` &&
          <DangerMenuItem
            onClick={deleteThisBlock}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            {i18n("Delete")}
          </DangerMenuItem>
        }

      </List>

    </>
  )

}

export default memo(PassageInNotesActions)