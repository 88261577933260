import { useLocation, useHistory } from 'react-router-dom'
import { usePrevious } from 'react-use'

import { getPrimaryDate, timeBetweenMapDates } from "../utils/misc"
import useEffectAsync from "./useEffectAsync"
import useInstanceValuesCallback from './useInstanceValuesCallback'
import useAppSize from './useAppSize'
import eras from '../components/pages/map/eras'
import snapshots from '../components/pages/map/snapshots'

const JOURNEY_ZOOM_VERTICAL_MARGIN = 84
const JOURNEY_ZOOM_HORIZONTAL_MARGIN = 80

const useUpdateMapTimelineFromQueryString = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  placeOpen,
  selectedPlace,
  setSelectedPlace,
  setExpandedEventId,
  setSelectedJourney,
  setSelectedPerson,
  closeSelected,
  allPlaces,
  allJourneys,
  persons,
  eventsByJourneyId,
  eventsByPersonId,
  getEraId,
  setEraId,
  mapViewInfo,
  goCenterAndScale,
  safeSetDetailsWidth,
  setShowJourneys,
  setShowJourneyKey,
  setShowDistance,
 }) => {

  const location = useLocation()
  const history = useHistory()
  const previousLocation = usePrevious(location)
  const { width } = useAppSize()

  const updateMapTimelineForItem = useInstanceValuesCallback(
    ({
      date,
      placeId,
      eventId,
      journeyId,
      personId,
      snapshotId,
      snapshot,
      doTransition=true,
    }) => {

      let newPlace, newJourney

      const changeEraToBibleIfNecessary = (fromDate, toDate) => {
        const { timelineStartDate, timelineEndDate } = eras.find(({ id }) => id === getEraId()) || {}
        if(
          timeBetweenMapDates(timelineStartDate, fromDate).totalDays < 0
          || timeBetweenMapDates(toDate || fromDate, timelineEndDate).totalDays < 0
        ) {
          setEraId(`bible`)
        }
      }

      const goCenterAndScaleOnArea = ({ lowestX, highestX, lowestY, highestY }) => {

        const { width, height, baseMapWidth, baseMapHeight } = mapViewInfo
        const portionNeededOfWidth = highestX - lowestX
        const maxPortionOfWidthVisible = (width - JOURNEY_ZOOM_HORIZONTAL_MARGIN*2) / baseMapWidth
        const neededScaleForWidth = maxPortionOfWidthVisible / portionNeededOfWidth
        const portionNeededOfHeight = highestY - lowestY
        const maxPortionOfHeightVisible = (height - JOURNEY_ZOOM_VERTICAL_MARGIN*2) / baseMapHeight
        const neededScaleForHeight = maxPortionOfHeightVisible / portionNeededOfHeight

        goCenterAndScale({
          scale: Math.min(neededScaleForWidth, neededScaleForHeight),
          x: (lowestX + highestX) / 2,
          y: (lowestY + highestY) / 2,
          doTransition,
        })

      }

      snapshot = snapshot || (snapshotId && snapshots.find(({ id }) => id === snapshotId))
      if(snapshot) {

        let {
          eraId,
          timelineDetailsWidth,
          maxTimelineDetailsPercent,
          showTimelineCutoffWidth,
          scaleAndPositionInfo,
          options,
        } = snapshot

        date = date || snapshot.date
        placeId = placeId || snapshot.placeId
        eventId = eventId || snapshot.eventId
        journeyId = journeyId || snapshot.journeyId
        personId = personId || snapshot.personId

        if(eraId && eraId !== getEraId()) {
          setEraId(eraId)
        }

        if(typeof timelineDetailsWidth === `number`) {
          if(showTimelineCutoffWidth && width < showTimelineCutoffWidth) {
            timelineDetailsWidth = 0
          }
          if(maxTimelineDetailsPercent) {
            timelineDetailsWidth = Math.min(
              timelineDetailsWidth,
              (width - 70) * maxTimelineDetailsPercent,
            )
          }
          safeSetDetailsWidth(timelineDetailsWidth)
        }

        if(scaleAndPositionInfo) {
          goCenterAndScaleOnArea(scaleAndPositionInfo)
        }

        if(options) {
          const setOptionFunctions = {
            showJourneys: setShowJourneys,
            showJourneyKey: setShowJourneyKey,
            showDistance: setShowDistance,
          }
          Object.keys(setOptionFunctions).forEach(key => {
            if(typeof options[key] === `boolean`) {
              setOptionFunctions[key](options[key])
            }
          })
        }

      }

      if(date) {
        const [ newFromDate, newToDate ] = date.split(` - `)
        setFromDate(newFromDate)
        setToDate(newToDate)
        changeEraToBibleIfNecessary(newFromDate, newToDate)
      }

      const updateDateIfCurrentNotWithinEvents = events => {
        if(
          !date
          && events.length > 0
          && !events.some(event => {
            const [ startDate, endDate ] = getPrimaryDate(event).split(` - `)
            return (
              timeBetweenMapDates(startDate, toDate || fromDate).totalDays >= 0
              && timeBetweenMapDates(fromDate, endDate || startDate).totalDays >= 0
            )
          })
        ) {
          const newFromDate = getPrimaryDate(events[0]).split(` - `)[0]
          setFromDate(newFromDate)
          setToDate(null)
          changeEraToBibleIfNecessary(newFromDate)
        }
      }

      const openSelectedPlaceId = placeOpen && selectedPlace.id

      const place = placeId && allPlaces.find(({ id }) => id === placeId)
      if(place && place.id !== openSelectedPlaceId) {
        setSelectedPerson()
        setSelectedJourney()
        setSelectedPlace(place)
        newPlace = place
        setExpandedEventId()
        updateDateIfCurrentNotWithinEvents(place.events)
      }

      const event = eventId && allPlaces.map(({ events }) => events).flat().find(({ id }) => id === eventId)
      if(event) {
        if(!place) {
          const place = allPlaces.find(({ events }) => events.includes(event))
          setSelectedPerson()
          setSelectedJourney()
          setSelectedPlace(place)
          newPlace = place
        }
        setExpandedEventId(eventId)
        updateDateIfCurrentNotWithinEvents([event])
      }

      const journey = journeyId && allJourneys.find(({ id }) => id === journeyId)
      if(journey) {
        if(
          !place
          && !eventsByJourneyId[journeyId].map(({ place }) => place.id).includes(openSelectedPlaceId)
          && eventsByJourneyId[journeyId][0]
        ) {
          setSelectedPerson()
          setSelectedPlace(eventsByJourneyId[journeyId][0].place)
          setExpandedEventId()
        }
        setTimeout(() => setSelectedJourney(journey))
        newJourney = journey
        updateDateIfCurrentNotWithinEvents(eventsByJourneyId[journeyId])
      }

      const person = personId && persons.find(({ id }) => id === personId)
      if(person) {
        if(
          !place
          && !eventsByPersonId[personId].map(({ place }) => place.id).includes(openSelectedPlaceId)
          && eventsByPersonId[personId][0]
        ) {
          setSelectedJourney()
          setSelectedPlace(eventsByPersonId[personId][0].place)
          newPlace = place
          setExpandedEventId()
        }
        setTimeout(() => setSelectedPerson(person))
        updateDateIfCurrentNotWithinEvents(eventsByPersonId[personId])
      }

      if(
        !place
        && !event
        && !journey
        && !person
      ) closeSelected()

      if(newPlace) {

        const { x, y } = newPlace.locations[0] || {}
        goCenterAndScale({ x, y, doTransition })

      } else if(newJourney) {

        let lowestX = Infinity
        let highestX = 0
        let lowestY = Infinity
        let highestY = 0
        ;(eventsByJourneyId[newJourney.id] || []).forEach(({ place: { locations: [ location ]=[] }={} }) => {
          if(!location) return
          const { x, y } = location
          if(x < lowestX) lowestX = x
          if(x > highestX) highestX = x
          if(y < lowestY) lowestY = y
          if(y > highestY) highestY = y
        })

        goCenterAndScaleOnArea({ lowestX, highestX, lowestY, highestY })

      }

    }
  )

  useEffectAsync(
    () => {

      const searchParams = new URLSearchParams(location.search)
      const date = (searchParams.get(`date`) || ``).replace(/_/g, ` `)
      const placeId = searchParams.get(`placeId`)
      const eventId = searchParams.get(`eventId`)
      const journeyId = searchParams.get(`journeyId`)
      const personId = searchParams.get(`personId`)
      const snapshotId = searchParams.get(`snapshotId`)
      const doTransition = !!previousLocation && /^\/map(?:\/|[?#]|$)/.test(previousLocation.pathname)

      if(searchParams.size > 0) {

        updateMapTimelineForItem({
          date,
          placeId,
          eventId,
          journeyId,
          personId,
          snapshotId,
          doTransition,
        })

        history.replace({ search: null })

      }

    },
    [ location ],
  )

  return updateMapTimelineForItem
}

export default useUpdateMapTimelineFromQueryString