import { gql } from '@apollo/client'

const accountSetting = gql`
  query accountSetting($id: ID!) {
    accountSetting(id: $id) {
      id
      value
    }
  }
`

export default accountSetting