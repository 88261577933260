import { memo, useContext } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import CheckIcon from '@material-ui/icons/Check'
import queryString from 'query-string'
import { useApolloClient } from '@apollo/client'

import { LoggedInUserContext } from '../../../context/LoggedInUser'
import userQuery from '../../../graphql/queries/user'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useSetTimeout from '../../../hooks/useSetTimeout'
import useEffectAsync from '../../../hooks/useEffectAsync'

import Header from "../../common/Header"
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import LinkButton from '../../common/LinkButton'

const PageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobilePageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`

const HeaderLogoImg = styled.img`
  height: 36px;
  vertical-align: top;
  margin: 5px -4px 0 -6px;
`

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px calc(100vh - 250px);
  background-color: white;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
`

const SuccessContainer = styled.div`
  margin: 40px 0 -30px;
  display: flex;
  justify-content: center;
`

const Success = styled.div`
  font-size: 18px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.grey[50]};
  align-self: center;
  padding: 10px 17px;
  border-radius: 5px;

  .MuiSvgIcon-root {
    height: 25px;
    width: auto;
    vertical-align: middle;
    color: ${({ theme }) => theme.palette.primary.main};
    margin: -4px 5px 0 -2px;
  }
`

const Welcome = styled.div`
  text-align: center;
  margin: 70px 0 25px;
  font-size: 22px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const MyLogoImg = styled.img`
  height: 44px;
  vertical-align: top;
  margin: -7px -12px -7px -6px;
`

const TagLine1 = styled.div`
  text-align: center;
  font-size: 18px;
`

const Whoops = styled.div`
  text-align: center;
  font-weight: 300;
  margin-top: 45px;
`

const UseFor = styled.div`
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.palette.primary.faded};
  padding: 4px 15px;
  text-align: center;
`

const MyLogoImg2 = styled.img`
  height: 30px;
  vertical-align: top;
  margin: -4px -6px -4px -6px;
`

const Helps = styled.div`
  margin: 60px 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HelpHeader = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: 200;
  margin-bottom: 20px;
`

const UseForSection = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AlternativelyUseFor = styled.div`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
`

const HelpSubhead = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin: 30px 0 15px;
`

const SeePromo = styled.div`
  font-size: 14px;
  margin: 10px 0;
  text-align: center;
`

const LABLater = styled.div`
  text-align: center;
  margin: 25px 0 10px;
  font-weight: 400;
  font-size: 15px;
`

const FeaturesList = styled.ol`
  max-width: 540px;
  margin: 0 auto;
  font-weight: 300;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 40px;
`

const GoToBiblearcBibleButton = styled(LinkButton)`
  display: block;
  margin: 0 auto 10px;
`

const YourMyBSBSubscription = ({ ...props }) => {

  let {
    'purchase-successful': purchaseSuccessful,
    'subscription-granted': subscriptionGranted,
  } = queryString.parse(window.location.search)
  purchaseSuccessful = purchaseSuccessful !== undefined
  subscriptionGranted = subscriptionGranted !== undefined

  const client = useApolloClient()
  const user = useContext(LoggedInUserContext)
  const { hasMyPlan } = user || {}
  const getUser = useInstanceValue(user)
  const [ setDoUserQueryTimeout ] = useSetTimeout()

  useEffectAsync(
    // Immediately after purchase, keep doing user queries until stripe subscription is found.
    () => {
      if(!purchaseSuccessful) return

      let timesChecked = 0

      const checkAndTryAfterTimeout = () => {
        setDoUserQueryTimeout(
          async () => {
            const user = getUser()
            if(!user) return

            const hasStripeSubscription = !!(user.activeSubscriptions || []).filter(({ type }) => type === 'STRIPE')[0]

            if(!hasStripeSubscription) {
              await client.query({
                context: {
                  isBackupServerQuery: true,  // forcing the await to wait until run through the server via this option
                  forceSaveServerResultToDexie: true,    
                },
                query: userQuery,
                fetchPolicy: `network-only`,
              })
              if(++timesChecked < 10) {
                checkAndTryAfterTimeout()
              }
            }
          },
          1000,
        )
      }

      checkAndTryAfterTimeout()
    },
    [],
  )

  return (
    <>

      <Header {...props}>
        <PageTitle>
          {i18nReact("Your {{logo}} Subscription", {
            logo: <HeaderLogoImg src="/my_biblearc_study_bible_1.svg" />
          })}
        </PageTitle>
        <MobilePageTitle>
          {i18n("My BSB")}
        </MobilePageTitle>
      </Header>

      <Container>
        <Content>

          {purchaseSuccessful &&
            <SuccessContainer>
              <Success>
                <CheckIcon />
                {i18n("Purchase successful")}
              </Success>
            </SuccessContainer>
          }

          {subscriptionGranted &&
            <SuccessContainer>
              <Success>
                <CheckIcon />
                {i18n("Subscription granted")}
              </Success>
            </SuccessContainer>
          }

          <Welcome>
            {i18nReact("Welcome to {{something}}", {
              something: <MyLogoImg src="/my_biblearc_study_bible_1.svg" />,
            })}
          </Welcome>

          <TagLine1>
            {i18n("Your personalized study Bible.")}
          </TagLine1>

          {!hasMyPlan &&
            <Whoops>
              {i18n("[[ You do not have an active My Biblearc Study Bible subscription. ]]")}
            </Whoops>
          }

          <Helps>

            <HelpHeader>
              {i18n("Getting Started")}
            </HelpHeader>

            <UseForSection>

              <UseFor>
                {i18nReact("Use {{biblearc_tools}} for general Bible reading.", {
                  biblearc_tools: <MyLogoImg2 src="/my_biblearc_study_bible_1.svg" />,
                })}
              </UseFor>

              <AlternativelyUseFor>
                {i18nReact("(Use {{biblearc_tools}} for in-depth study of a passage or topic.)", {
                  biblearc_tools: (
                    <NavLinkOrAWithDisable to="/your-tools-subscription" exact>
                      {i18n("Biblearc TOOLS")}
                    </NavLinkOrAWithDisable>
                  ),
                })}
              </AlternativelyUseFor>
              <AlternativelyUseFor>
                {i18nReact("(Use {{biblearc_equip}} to learn deeper study of Scripture.)", {
                  biblearc_equip: (
                    <NavLinkOrAWithDisable to="/your-equip-subscription">
                      {i18n("Biblearc EQUIP")}
                    </NavLinkOrAWithDisable>
                  ),
                })}
              </AlternativelyUseFor>

            </UseForSection>

            <HelpSubhead>
              {i18n("Features")}
            </HelpSubhead>

            <FeaturesList>
              <li>{i18n("Integrate your church’s sermons, and videos from popular ministries.")}</li>
              <li>{i18n("Bookmark sermons, and even specific timestamps within sermons.")}</li>
              <li>{i18n("Choose from 99 different styles to markup your Bible and annotate.")}</li>
              <li>{i18n("Add your own notes to the Map + Timeline and save map snapshots.")}</li>
            </FeaturesList>

            <LABLater>
              {i18n("Note: Look at the Book-style sketches are coming in February.")}
            </LABLater>

            <Buttons>

              <GoToBiblearcBibleButton
                to="/"
                variant="contained"
                color="primary"
                disableElevation
              >
                {i18n("Go to The Biblearc Study Bible")}
              </GoToBiblearcBibleButton>

              <GoToBiblearcBibleButton
                to="/map"
                variant="outlined"
                color="primary"
              >
                {i18n("Go to Biblearc Maps")}
              </GoToBiblearcBibleButton>

            </Buttons>

          </Helps>

          <SeePromo>
            {i18nReact("See the {{my_biblearc_study_bible_promo_page}} for further inspiration.", {
              my_biblearc_study_bible_promo_page: (
                <NavLinkOrAWithDisable to="/about-my-bsb" exact>
                  {i18n("My Biblearc Study Bible promotional page")}
                </NavLinkOrAWithDisable>
              )
            })}
          </SeePromo>

          <SeePromo>
            {i18nReact("Learn about the {{the_biblearc_study_bible}} more broadly.", {
              the_biblearc_study_bible: (
                <NavLinkOrAWithDisable to="/about-the-biblearc-study-bible" exact>
                  {i18n("The Biblearc Study Bible")}
                </NavLinkOrAWithDisable>
              )
            })}
          </SeePromo>

        </Content>
      </Container>

    </>
  )
}


export default memo(YourMyBSBSubscription)