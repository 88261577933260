import { memo, useCallback, useContext, useEffect } from 'react'
import { useNetworkState } from 'react-use'
import { useApolloClient } from '@apollo/client'

import { db } from '../../utils/database'
import { callOnFocus } from '../../utils/misc'
import { TAB_ID } from '../../utils/constants'
import { OfflineSetupStatusContext, LastOfflineUpdateInfoContext } from '../../context/LocalInfo'
import useIsLoggedIn from '../../hooks/useIsLoggedIn'
import useInstanceValue from '../../hooks/useInstanceValue'
import useEffectAsync from '../../hooks/useEffectAsync'

import userUpdateQuery from '../../graphql/queries/userUpdate'

const LOAD_TIME = Date.now()

const SyncManager = () => {

  const client = useApolloClient()

  const isLoggedIn = useIsLoggedIn()

  const { online } = useNetworkState()

  const offlineSetupStatus = useContext(OfflineSetupStatusContext)
  const lastOfflineUpdateInfo = useContext(LastOfflineUpdateInfoContext)

  const getOfflineSetupStatus = useInstanceValue(offlineSetupStatus)

  const queryUserUpdate = useCallback(
    async () => {
      await client.query({
        query: userUpdateQuery,
        fetchPolicy: `network-only`,
        context: {
          offlineSetupStatus: getOfflineSetupStatus(),
        },
      })
    },
    [ getOfflineSetupStatus, client ],
  )

  useEffect(
    () => {
      if(!isLoggedIn) return
      if(!online) return

      let statusChanged = false

      ;(async () => {

        switch(offlineSetupStatus) {  // eslint-disable-line default-case
          case 'off':
          case 'on-mutation-sync': {

            const sendQueuedMutations = async () => {

              if(statusChanged) return

              const numQueuedMutations = await db.queuedMutations.count()

              if(statusChanged) return

              if(numQueuedMutations > 0) {  // i.e. there are outstanding mutations

                // Find queued mutations which were attempted more than one minute ago
                const queuedMutations = (
                  await db.queuedMutations
                    .where('attemptingAt')
                    .below(Date.now() - 1000 * 60)  // one minute ago
                    .sortBy('createdAt')
                ).slice(0, 10)  // matches the the batch size in apollo

                if(statusChanged) return

                if(queuedMutations.length > 0) {

                  await Promise.all(queuedMutations.map(({ id, mutation, variables, attemptingAt, updatedSinceType }) => (
                    client.mutate({
                      mutation,
                      variables,
                      context: {
                        offlineSetupStatus,
                        presetQueuedMutationId: id,
                        attemptingAt,
                        updatedSinceType,
                      },
                    })
                  )))

                  sendQueuedMutations()

                } else {
                  // all outstanding mutations were attempted less than one minute ago
                  // so don't send them quite yet
                  setTimeout(sendQueuedMutations, 1000)
                }

              } else if(offlineSetupStatus === 'on-mutation-sync') {

                await db.localInfo.put({
                  id: 'offlineSetupStatus',
                  value: 'on-query-sync',
                })

              } else {  // offlineSetupStatus === 'off'

                await queryUserUpdate()

              }

            }

            sendQueuedMutations()

            break
          }

          case 'on-query-sync': {
            await queryUserUpdate()
            break
          }

        }

      })()

      return () => {
        statusChanged = true
      }
    },
    [ isLoggedIn, online, offlineSetupStatus, queryUserUpdate, client ],
  )

  useEffectAsync(
    async () => {

      if(!online) {

        // set attemptingAt to null for entire queue
        await db.queuedMutations
          .where('attemptingAt')
          .above(0)
          .modify({ attemptingAt: 0 })

        switch(offlineSetupStatus) {  // eslint-disable-line default-case
          case 'on-query-sync':
          case 'on-ready': {

            // set to on-mutation-sync
            await db.localInfo.put({
              id: 'offlineSetupStatus',
              value: 'on-mutation-sync',
            })

            break
          }
        }

      }

    },
    [ online ],
  )

  const getShouldRunUserUpdateOnFocus = useInstanceValue(online && isLoggedIn && [ 'off', 'on-ready' ].includes(offlineSetupStatus))

  useEffect(
    () => {
      const doUserUpdateQuery = async () => {
        if(getShouldRunUserUpdateOnFocus()) {
          await queryUserUpdate()
        }
      }

      return callOnFocus(doUserUpdateQuery)
    },
    [ getShouldRunUserUpdateOnFocus, client, queryUserUpdate ],
  )

  useEffectAsync(
    () => {
      const doingDexieQueries = [ 'on-ready', 'on-mutation-sync' ].includes(offlineSetupStatus)
      const { tabId, time } = lastOfflineUpdateInfo || {}

      if(
        doingDexieQueries
        && tabId
        && tabId !== TAB_ID
        && time > LOAD_TIME
      ) {
        client.refetchQueries({
          include: 'active',
          onQueryUpdated: observableQuery => (
            [
              `formattingKeys`,
              `highlights`,
              `projects`,
              `project`,
              `folders`,
              `folderAncestry`,
              `tags`,
              `modules`,
              `module`,
              `moduleSetting`,
              `modulePieces`,
              `modulePiece`,
              `moduleDots`,
              `moduleDot`,
              `moduleMarkups`,
              `moduleMarkup`,
            ].includes(observableQuery.queryName)
          ),
        })
      }
    },
    [ lastOfflineUpdateInfo ],
  )

  return null
}

export default memo(SyncManager)