import { memo } from 'react'
import styled from 'styled-components'

const Note = styled.div`
  text-align: center;
`

const SecondaryNote = styled.div`
  font-weight: 300;
`

const TooltipNote = ({
  note,
  secondaryNote,
}) => (
  <Note>

    {note}

    {!!secondaryNote &&
      <SecondaryNote>
        {secondaryNote}
      </SecondaryNote>
    }

  </Note>
)

export default memo(TooltipNote)
