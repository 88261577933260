import { memo, useCallback, useState, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import queryString from 'query-string'
import { useMutation } from '@apollo/client'

import { LoggedInUserContext } from '../../../context/LoggedInUser'
import useMutationContext from '../../../hooks/useMutationContext'

import Header from "../../common/Header"
import FadedLoading from '../../common/FadedLoading'
import LinkButton from '../../common/LinkButton'

import requestFreeToolsSubscriptionMutation from '../../../graphql/mutations/requestFreeToolsSubscription'
import useInstanceValue from '../../../hooks/useInstanceValue'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 50px 20px 80px;
  background-color: white;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 300px;
  text-align: center;
`

const StyledTextField = styled(TextField)`
  width: 300px;
  margin: 10px 0;
`

const Usage = styled.div`
  font-weight: 500;
  margin: 30px 0 0;
`

const UsageExplanation = styled.div`
  font-size: 14px;
`

const UsageTextFieldContainer = styled.div`
  margin: 30px 0;
  width: 500px;
  max-width: calc(100vw - 40px);
`

const Granted = styled.div`
  margin: 50px 0 20px;
  font-size: 18px;
  font-weight: bold;
`

const RequestFreeTools = ({ ...props }) => {

  const context = useMutationContext()
  const [ requestFreeToolsSubscription ] = useMutation(requestFreeToolsSubscriptionMutation)

  const user = useContext(LoggedInUserContext)
  const { country } = queryString.parse(window.location.search)

  const [ usage, setUsage ] = useState(``)
  const onUsageChange = useCallback(({ target }) => setUsage(target.value), [])
  const getUsage = useInstanceValue(usage)
  const [ requestState, setRequestState ] = useState()

  const goRequestFreeToolsSub = useCallback(
    async () => {

      setRequestState(`requesting`)

      try {
        await requestFreeToolsSubscription({
          variables: {
            input: {
              country,
              usage: getUsage(),
            },
          },
          context,
        })
      } catch(err) {
        console.log('err', err)
        setRequestState(`rejected`)
        return
      }

      setRequestState(`approved`)

    },
    [ requestFreeToolsSubscription, country, getUsage, context ],
  )

  return (
    <>

      <Header {...props} />

      <Container>
        <Content>

          <div>
            <StyledTextField
              label={i18n("Name")}
              variant="outlined"
              value={user.name || i18n("Unknown name")}
              disabled
            />
          </div>

          <div>
            <StyledTextField
              label={i18n("Country")}
              variant="outlined"
              value={country}
              disabled
            />
          </div>

          <Usage>
            {i18n("In a couple of sentences, describe how to plan to use Biblearc TOOLS.")}
          </Usage>

          <UsageExplanation>
            {i18n("Your answer will be used to encourage those who donate to support Biblearc’s ministry.")}
          </UsageExplanation>

          <UsageTextFieldContainer>
            <TextField
              variant="outlined"
              size="small"
              value={usage}
              onChange={onUsageChange}
              multiline
              fullWidth
              minRows={3}
              disabled={!!requestState}
            />
          </UsageTextFieldContainer>

          <div>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={goRequestFreeToolsSub}
              disabled={!country || usage.trim().length < 10 || !!requestState}
            >
              {i18n("Request a Free Biblearc TOOLS Subscription")}
            </Button>
          </div>

          {requestState === `requesting` && <FadedLoading />}

          {requestState === `approved` &&
            <>
              <Granted>
                {i18n("Your request have been provisionally approved.")}
              </Granted>
              <LinkButton
                color="primary"
                variant="outlined"
                to="/your-tools-subscription"
              >
                {i18n("Get Started with Biblearc TOOLS")}
              </LinkButton>
            </>
          }

          {requestState === `rejected` &&
            <Granted>
              {i18n("Sorry. Your request has been rejected.")}
            </Granted>
          }

        </Content>
      </Container>

    </>
  )
}


export default memo(RequestFreeTools)