import { memo, useCallback } from 'react'

import BibleMapPlaceCity from './BibleMapPlaceCity'
import BibleMapPlaceProvince from './BibleMapPlaceProvince'
import BibleMapPlaceKingdom from './BibleMapPlaceKingdom'
import BibleMapPlaceSea from './BibleMapPlaceSea'
import BibleMapPlaceDesert from './BibleMapPlaceDesert'
import BibleMapPlaceArea from './BibleMapPlaceArea'
import BibleMapPlaceMountain from './BibleMapPlaceMountain'
import BibleMapPlaceRiver from './BibleMapPlaceRiver'
import BibleMapPlaceSpot from './BibleMapPlaceSpot'

const Null = () => null

const PlaceComponentsByType = {
  CITY: BibleMapPlaceCity,
  PROVINCE: BibleMapPlaceProvince,
  KINGDOM: BibleMapPlaceKingdom,
  SEA: BibleMapPlaceSea,
  DESERT: BibleMapPlaceDesert,
  AREA: BibleMapPlaceArea,
  MOUNTAIN: BibleMapPlaceMountain,
  RIVER: BibleMapPlaceRiver,
  ROAD: BibleMapPlaceSpot,  // TODO: switch to BibleMapPlaceRoad,
  HOUSE: BibleMapPlaceSpot,  // TODO: switch to BibleMapPlaceHouse,
  GARDEN: BibleMapPlaceSpot,  // TODO: switch to BibleMapPlaceGarden,
  SPOT: BibleMapPlaceSpot,
}

const BibleMapPlace = ({
  place,
  setSelectedPlace,
  getStyle,
  stage,
  mapLayerId,
  ...otherProps
}) => {

  const Place = PlaceComponentsByType[place.type] || Null

  const missingKeyData = (
    stage === `DRAFT`
    && (
      ([ `CITY`, `SPOT `].includes(place.type) && place.events.length === 0)
      || ([ `KINGDOM` ].includes(place.type) && !place.dateRange)
      || ([ `PROVINCE`, `CITY` ].includes(place.type) && !place.dateRanges)
      || (place.events.length === 0 && !place.about)
    )
  )

  const selectThisPlace = useCallback(() => (setSelectedPlace && setSelectedPlace(place)), [ setSelectedPlace, place ])

  return (
    <Place
      selectThisPlace={selectThisPlace}
      place={place}
      getStyle={getStyle}
      $stage={stage}
      missingKeyData={missingKeyData}
      {...otherProps}
    />
  )
}

export default memo(BibleMapPlace)