import React from "react"
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 10px;
  margin-left: -10px;
  overflow: hidden;
  z-index: 45;
`

const Char = styled.div`
  font-size: 100vh;
  line-height: 1;
  font-family: Arial;
  caret-color: black;
  color: transparent;
  position: absolute;
  top: 0;
  right: 0;
`

const AtomicCursor = () => (
  <Container>
    <Char>&#792;</Char>
  </Container>
)
// &#792; is being used simply because it is a zero-width character

export default AtomicCursor