import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation setModuleSetting($id: ID!, $projectId: ID, $input: ModuleSettingInput!, $updatedSince: Milliseconds) {
    setModuleSetting(id: $id, projectId: $projectId, input: $input, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`