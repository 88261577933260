import styled from 'styled-components'

const Div = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: inset 0 0 15px rgb(0 0 0/.15);
  margin: 0 -20px;
  padding: 0 20px;
`

const VerticalInsetShadow = ({
  className=``,
  ...otherProps
}) => (
  <Div
    className={`VerticalInsetShadow ${className}`}
    data-test="hi"
    {...otherProps}
  />
)

export default VerticalInsetShadow