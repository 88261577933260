import { useState } from "react"

import useEffectAsync from "./useEffectAsync"

const useMemoAsync = (asyncFunc, deps=[], defaultReturnValue) => {

  const [ returnValue, setReturnValue ] = useState(defaultReturnValue)

  useEffectAsync(
    async () => setReturnValue(await asyncFunc()),
    deps,
  )

  return returnValue
}

export default useMemoAsync
