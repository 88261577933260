import { memo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'

const Cell = styled.div`
  width: 450px;

  && {
    flex-shrink: 1;
  }
`

const StudyBibleItemGenealogyInfoAndDetails = ({
  info,
  details=[],
}) => {

  return (
    <Cell>

    </Cell>
  )
}

export default memo(StudyBibleItemGenealogyInfoAndDetails)