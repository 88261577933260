import { useState } from 'react'
import { useMountedState } from 'react-use'

import useEffectAsync from './useEffectAsync'

const useDelay = (value, delay=300) => {

  const isMounted = useMountedState()
  const [ valueAfterDelay, setValueAfterDelay ] = useState(value)

  useEffectAsync(
    () => {
      setTimeout(() => {
        if(isMounted) {
          setValueAfterDelay(value)
        }
      }, delay)
    },
    [ value ],
  )

  return valueAfterDelay

}

export default useDelay
