import { gql } from '@apollo/client'
import tagSet_fields from '../fragments/tagSet_fields'

export default gql
`
  mutation submitTagSet($input: TagSetInput!) {
    submitTagSet(input: $input) {
      myTagSet {
        id
        tags
      }
      tagSet {
        ${tagSet_fields}
      }
    }
  }
`