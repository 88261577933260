import { gql } from '@apollo/client'

import { subsetDefs, subsetMaps } from '../fragments/subset_parameters'
import studyBibleItem_fields from '../fragments/studyBibleItem_fields'

const studyBibleItems = gql`
  query studyBibleItems($query: String, ${subsetDefs}) {
    studyBibleItems(query: $query, ${subsetMaps}) {
      studyBibleItems {
        ${studyBibleItem_fields}
      }
      count
    }
  }
`

export default studyBibleItems