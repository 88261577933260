import { memo, useCallback } from 'react'

import OptionsPopover from '../common/OptionsPopover'
import PassageChooser from '../chooser/PassageChooser'

const PassagePopover = ({
  initialPassageState,
  updatePassageBookmark,
  onClose,
  type,
  showParallel,
  hideOriginals,
  disableVersionChange,
  showBasedUponMessage,
  versionsAlreadyInUse,
  maxChapterSpan,  // only relevant when type === `verses`
  autoSelectEntireBook,  // only relevant when type === `verses`
  ...otherProps
}) => {

  const hasChapter = !!initialPassageState.chapter

  const updatePassage = useCallback(
    passage => {
      updatePassageBookmark({
        scrollYFraction: 0,
        ...passage,
      })
      if(
        passage.ref
        || (
          type === `verse`
          && (
            hasChapter
            || passage.refs
          )
        )
      ) {
        onClose()
      }
    },
    [ updatePassageBookmark, onClose, type, hasChapter ],
  )

  return (
    <OptionsPopover
      onClose={onClose}
      hideArrow
      {...otherProps}
    >

      <PassageChooser
        initialPassageState={initialPassageState}
        updatePassage={updatePassage}
        type={type}
        showParallel={showParallel}
        hideOriginals={hideOriginals}
        disableVersionChange={disableVersionChange}
        showBasedUponMessage={showBasedUponMessage}
        versionsAlreadyInUse={versionsAlreadyInUse}
        maxChapterSpan={maxChapterSpan}
        autoSelectEntireBook={autoSelectEntireBook}
      />

    </OptionsPopover>
  )
}

export default memo(PassagePopover)