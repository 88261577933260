import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
// import Button from '@material-ui/core/Button'
import Button from '@material-ui/core/Button'
import { useMutation } from '@apollo/client'
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Error'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMountedState } from 'react-use'
import { getLanguageInfo } from '@bibletags/bibletags-ui-helper'

import useDataQuery from '../../../hooks/useDataQuery'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useMutationContext from '../../../hooks/useMutationContext'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { cloneObj } from '../../../utils/misc'

import Loading from "../../common/Loading"

import allVersionsQuery from '../../../graphql/queries/allVersions'
import populateWordsMutation from '../../../graphql/mutations/populateWords'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
`

const PopulateWordsDetails = styled.div`
  padding: 20px;
 `

const PopulateWordsDetail = styled.div`
  line-height: 25px;
  display: flex;
  align-items: center;
`

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: 5px;
`

const StyledCheckIcon = styled(CheckIcon)`
  height: 17px;
`

const StyledErrorIcon = styled(ErrorIcon)`
  height: 17px;
`

const BibleDataTab = () => {

  const { versions: { versions }={} } = useDataQuery({ versionsQuery: allVersionsQuery })
  const [ populateWords ] = useMutation(populateWordsMutation)
  const context = useMutationContext()

  const [ populateWordsStatuses, setPopulateWordsStatuses ] = useState({})
  const getPopulateWordsStatuses = useInstanceValue(populateWordsStatuses)

  const isMounted = useMountedState()

  const goPopulateWords = useCallback(
    async () => {

      const populateWordsStatuses = cloneObj(getPopulateWordsStatuses())
      const languageIdsWithVersions = Object.keys(populateWordsStatuses)

      populateWordsStatuses.overall = `running`

      for(let languageId of languageIdsWithVersions) {
        if(languageId === `overall`) continue

        populateWordsStatuses[languageId] = `running`
        setPopulateWordsStatuses(populateWordsStatuses)

        let success = false
        try {
          success = (await populateWords({
            variables: {
              input: {
                languageId,
              },
            },
            context,
          })).data.populateWords
        } catch(err) {
          console.error(err)
        }

        if(!isMounted()) return

        populateWordsStatuses[languageId] = success ? `succeeded` : `failed`
        setPopulateWordsStatuses(populateWordsStatuses)

      }

      populateWordsStatuses.overall = `done`
      setPopulateWordsStatuses({ ...populateWordsStatuses })  // without making this a new object, it doesn't update since this component uses memo()

    },
    [ populateWords, context, getPopulateWordsStatuses, isMounted ],
  )

  useEffectAsync(
    () => {

      if(!versions) return

      const newPopulateWordsStatuses = {
        overall: `ready`,
      }
      ;[ ...new Set(versions.map(({ languageId }) => languageId)) ].forEach(languageId => {
        newPopulateWordsStatuses[languageId] = null
      })
      setPopulateWordsStatuses(newPopulateWordsStatuses)

    },
    [ versions ],
  )

  const populateWordsStatusesKeysToShow = Object.keys(populateWordsStatuses).filter(key => key !== `overall` && populateWordsStatuses[key])

  return (
    <Container>

      <Button
        variant="contained"
        disableElevation
        disabled={populateWordsStatuses.overall !== `ready`}
        onClick={goPopulateWords}
      >
        Populate words for search
        {populateWordsStatuses.overall === `running` && <Loading size={20} bgOpacity={.5} />}
      </Button>

      <PopulateWordsDetails>
        {populateWordsStatusesKeysToShow.map(languageId => (
          <PopulateWordsDetail key={`${languageId} ${populateWordsStatuses[languageId]}`}>
            {getLanguageInfo(languageId).englishName}
            {` `}
            [{languageId}]
            {` `}
            {populateWordsStatuses[languageId] === `running` && <StyledCircularProgress size={13} />}
            {populateWordsStatuses[languageId] === `succeeded` && <StyledCheckIcon />}
            {populateWordsStatuses[languageId] === `failed` && <StyledErrorIcon />}
          </PopulateWordsDetail>
        ))}
      </PopulateWordsDetails>

    </Container>
  )
}


export default memo(BibleDataTab)