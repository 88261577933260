import { useCallback, useRef } from 'react'
import useSetTimeout from './useSetTimeout'
import useInstanceValue from './useInstanceValue'

const useNoDoubleClickCallback = callback => {

  const [ setIgnoreTimeout ] = useSetTimeout()
  const ignore = useRef(false)
  const getCallback = useInstanceValue(callback)

  const goCallback = useCallback(
    (...params) => {
      if(ignore.current) return
      ignore.current = true
      setIgnoreTimeout(() => { ignore.current = false }, 400)
      getCallback()(...params)
    },
    [ getCallback, setIgnoreTimeout],
  )

  return goCallback
}

export default useNoDoubleClickCallback