import { memo, useCallback, useMemo } from 'react'
import { useLocation } from "react-router-dom"
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { combineItems } from "@bibletags/bibletags-ui-helper"

import useVersionInfo from '../../hooks/useVersionInfo'
import useModalAnchor from '../../hooks/useModalAnchor'

import PassageRef from '../common/PassageRef'
import PassagePopover from './PassagePopover'

const StyledButton = styled(Button)`
  height: 38px;
  text-transform: none;
  font-size: 18px;
  font-weight: 500;
  margin: 5px 10px;

  ${({ $mini }) => !$mini ? `` : `
    .MuiButton-label {
      flex-direction: column;
    }
  `}
`

const PassageRefContainer = styled.span`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const VersionAbbrs = styled.span`
  display: inline-flex;
  align-items: center;
  margin: 3px 5px 0 8px;
  font-size: 12px;
  font-weight: 300;

  ${({ $mini }) => !$mini ? `` : `
    margin: -10px -5px 0 0;
  `}

`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${({ theme }) => theme.palette.grey[600]};
`

const MiniExpandMoreIcon = styled(StyledExpandMoreIcon)`
  font-size: 15px;
`

const PassageRefButton = ({
  bookId,
  chapter,
  verse,
  versionId,
  abbreviated,
  parallelVersionId,
  updatePassageBookmark,
  setThisAsDragColumn,
  clearDragColumn,
  mini,
  type,
  showParallel,
  hideOriginals,
  className,
  ...otherProps
}) => {

  const { safeVersionAbbr } = useVersionInfo(versionId)
  const { safeVersionAbbr: safeParallelVersionAbbr } = useVersionInfo(parallelVersionId)
  const location = useLocation()
  const isChurchBible = /^\/church\/[^/]+$/.test(location.pathname)

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const onClick = useCallback(
    event => {
      window.biblearcExecutePostponedFocus()
      openModal(event)
    },
    [ openModal ],
  )

  const initialPassageState = useMemo(
    () => ({
      bookId: bookId || 1,
      chapter,
      versionId,
      parallelVersionId,
    }),
    [ bookId, chapter, versionId, parallelVersionId ],
  )

  const versionAbbrs = combineItems(
    safeVersionAbbr,
    safeParallelVersionAbbr,
  )

  return (
    <>

      <StyledButton
        {...otherProps}
        onClick={onClick}
        draggable={!!setThisAsDragColumn}
        onDragStart={setThisAsDragColumn}
        onDragEnd={clearDragColumn}
        data-postpone-focus
        tabIndex={-1}
        $mini={mini}
        className={`${className} PassageRefButton`}
      >

        <PassageRefContainer>
          {!bookId && !abbreviated && (type === `verse` ? i18n("Choose a verse") : i18n("Choose a passage"))}
          {!bookId && abbreviated && i18n("Choose")}
          {!!bookId &&
            <PassageRef
              refs={[{
                bookId,
                chapter,
                verse,
              }]}
              versionId={versionId}
              abbreviated={abbreviated}
            />
          }
        </PassageRefContainer>

        {!!bookId &&
          <VersionAbbrs $mini={mini}>
            {versionAbbrs}
            {mini && <MiniExpandMoreIcon />}
          </VersionAbbrs>
        }

        {!mini && <StyledExpandMoreIcon />}

      </StyledButton>

      <PassagePopover
        anchorEl={anchorEl}
        onClose={closeModal}
        initialPassageState={initialPassageState}
        showParallel={showParallel !== undefined ? showParallel : !isChurchBible}
        hideOriginals={hideOriginals}
        updatePassageBookmark={updatePassageBookmark}
        type={type}
      />

    </>
  )
}

export default memo(PassageRefButton)