import { memo, useCallback, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { getSystemFormattingKeys, getHasLabels } from '../../../utils/formatting'
import useFormattingKey from '../../../hooks/useFormattingKey'

import MenuDivider from '../../common/MenuDivider'
import CustomSwitch from '../../common/CustomSwitch'
import FormattingKeyOptionsMenuItemContent from './FormattingKeyOptionsMenuItemContent'

const splitOnHasLabels = keys => {
  const withLabels = []
  const withoutLabels = []
  keys.forEach(key => {
    if(getHasLabels(key)) {
      withLabels.push(key)
    } else {
      withoutLabels.push(key)
    }
  })
  return [ withLabels, withoutLabels ]
} 

const StyledTextField = styled(TextField)`
  margin: 15px 0 10px;

  /* The next two rules cover the text so that the tooltip does not appear */

  .MuiSelect-selectMenu {
    position: relative;
  }

  .MuiSelect-selectMenu::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
  }
`

const FormattingKeysGroupLabel = styled.div`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-weight: 300;
  font-size: 11px;
  padding: 5px 15px;
`

const FormattingKeyOptions = ({
  formattingKeyId,
  formattingKeyView,
  commitSettings,
  hideShowButton,
  ...otherProps
}) => {

  const formattingKey = useFormattingKey(formattingKeyId)

  const onChangeFormattingKeyId = useCallback(
    event => {
      const { value } = event.target
      if(!value) {
        event.preventDefault()
      } else {
        commitSettings({ formattingKeyId: value })
      }
    },
    [ commitSettings ],
  )

  const toggleShowButton = useCallback(
    ({ target }) => {
      commitSettings({ formattingKeyView: target.checked ? `BUTTON` : `NONE` })
    },
    [ commitSettings ],
  )

  const toggleShowFull = useCallback(
    ({ target }) => {
      commitSettings({ formattingKeyView: target.checked ? `FULL` : `NONE` })
    },
    [ commitSettings ],
  )

  const getMenuItem = ({ id }) => (
    <MenuItem
      key={id}
      value={id}
    >
      <FormattingKeyOptionsMenuItemContent
        id={id}
      />
    </MenuItem>
  )

  const [ systemWithLabels, systemWithoutLabels ] = useMemo(() => splitOnHasLabels(getSystemFormattingKeys()), [])

  return (
    <List>

      <StyledTextField
        {...otherProps}
        select
        label={i18n("Formatting Set")}
        fullWidth
        variant="outlined"
        value={formattingKey.id}
        onChange={onChangeFormattingKeyId}
        size="small"
      >
        <FormattingKeysGroupLabel>
          {i18n("Without Labels")}
        </FormattingKeysGroupLabel>
        {systemWithoutLabels.map(getMenuItem)}
        <MenuDivider />
        <FormattingKeysGroupLabel>
          {i18n("With Labels and Keys")}
        </FormattingKeysGroupLabel>
        {systemWithLabels.map(getMenuItem)}
      </StyledTextField>

      <CustomSwitch
        checked={formattingKeyView === `FULL`}
        onChange={toggleShowFull}
        label={i18n("Show formatting key", "", "notes")}
        disabled={!formattingKey.hasLabels}
      />

      {!hideShowButton &&
        <CustomSwitch
          checked={formattingKeyView === `BUTTON`}
          onChange={toggleShowButton}
          label={i18n("Show formatting key button", "", "notes")}
          disabled={!formattingKey.hasLabels}
        />
      }

    </List>
  )
}

export default memo(FormattingKeyOptions)