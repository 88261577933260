import { memo } from 'react'
import styled from 'styled-components'

import Loading from './Loading'

const CHECKER_SIZE = 20
const CHECKER_COLOR = `#EEE`

const Backdrop = styled.div`

  position: relative;
  overflow: hidden;
  width: ${({ $width }) => $width ? `${$width/2}px` : `100%`};
  min-width: ${({ $width }) => $width ? `${$width/2}px` : `100%`};
  min-height: ${({ $height }) => $height};  // this is needed due to Safari behavior with overflow:hidden

  ${({ $inMobileView }) => $inMobileView ? `` : `
    box-shadow: 0 2px 20px rgb(0 0 0/.5);
    background-image:
      linear-gradient(45deg, ${CHECKER_COLOR} 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, ${CHECKER_COLOR} 75%),
      linear-gradient(45deg, transparent 75%, ${CHECKER_COLOR} 75%),
      linear-gradient(45deg, ${CHECKER_COLOR} 25%, #fff 25%);
    background-size: ${CHECKER_SIZE}px ${CHECKER_SIZE}px;
    background-position: 0 0, 0 0, -${CHECKER_SIZE/2}px -${CHECKER_SIZE/2}px, ${CHECKER_SIZE/2}px ${CHECKER_SIZE/2}px;
  `}

`

const ClearLoading = styled(Loading)`
  background-color: transparent;
`

const StyledImg = styled.img`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  vertical-align: middle;

  ${({ src }) => src ? `` : `
    padding-bottom: 100%;
  `}

  ${({ $faded }) => !$faded ? `` : `
    opacity: .5;
  `}
`

const ImagePreview = ({
  captureInfo,
  options,
  scrollRef,
}) => {

  const {
    format,
    moduleWidth,
    moduleWidthInChange,
    imageWidthInChange,
  } = options

  const captureInfoOptions = captureInfo.options || {}

  const resizingImageWidth = imageWidthInChange !== captureInfoOptions.imageWidth
  const resizingModuleWidth = moduleWidth !== moduleWidthInChange
  const waitingToRenderNewModuleWidth = moduleWidth !== captureInfoOptions.moduleWidth
  const doFadedAndTempResize = resizingModuleWidth || waitingToRenderNewModuleWidth

  if(!captureInfo.dataUrl || captureInfoOptions.format !== format) return <ClearLoading />

  return (
    <Backdrop
      className="dark-mode-exempt"
      $width={format === `png` ? imageWidthInChange : null}
      $height={resizingImageWidth ? `auto` : `${captureInfo.imageHeight/2}px`}
      $inMobileView={!scrollRef}
    >

      <StyledImg
        src={captureInfo.dataUrl}
        $faded={doFadedAndTempResize}
        $width={
          doFadedAndTempResize
            ? `${(captureInfoOptions.moduleWidth / moduleWidthInChange) * 100}%`
            : `100%`
        }
        $height={(doFadedAndTempResize || resizingImageWidth) ? `auto` : `${captureInfo.imageHeight/2}px`}
      />

      {!resizingModuleWidth && waitingToRenderNewModuleWidth && <ClearLoading />}

    </Backdrop>
  )
}

export default memo(ImagePreview)