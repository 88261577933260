import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import OtherSearchResultsHeader from './OtherSearchResultsHeader'
import ProjectSearchResultsRow from './ProjectSearchResultsRow'

const Container = styled.div`
`

const ProjectSearchResults = ({
  count,
  projects,
}) => {

  return (
    <Container>
    
      <OtherSearchResultsHeader>
        {i18n("My Projects")}
      </OtherSearchResultsHeader>

      {projects.map(project => (
        <ProjectSearchResultsRow
          key={project.id}
          {...project}
        />
      ))}

    </Container>
  )
}

export default memo(ProjectSearchResults)