import { memo, useCallback, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { useToggle } from 'react-use'

import { PRICING } from '../../../utils/constants'

import CustomCheckbox from '../../common/CustomCheckbox'

const Container = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SwitchButtonGroup = styled(ButtonGroup)`
  margin-bottom: 15px;
  align-self: flex-end;
`

const SwitchButton = styled(Button)`
  ${({ $selected, theme }) => $selected ? `` : `
    color: ${theme.palette.grey[500]};
    &:hover {
      color: black;
    }
  `}
  
  ${({ $selected, theme }) => !$selected ? `` : `
    background: ${theme.palette.grey[300]};
    color: black !important;
  `}
`

const Box = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 10px;
  padding: 0 20px;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`

const Plan = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`

const PlanImage = styled.img`
  height: 100px;
  align-self: center;
`

const MyBiblearcStudyBibleImage = styled(PlanImage)`
  height: 120px;
  margin: -10px 0;
`

const Cost = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.palette.grey[500]};
  padding-top: 10px;
`

const Price = styled.span`
  font-weight: bold;
  color: black;
`

const BilledAnnually = styled.div`
  align-self: center;
  margin: 2px 0 20px;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const SaveWithAnnual = styled(BilledAnnually)`
`

const AnnualBilling = styled.span`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.grey[800]};
  }
`

const SelectButton = styled(Button)`
  margin-bottom: 15px;
  max-width: 600px;
  align-self: center;
`

const IncludesItem = styled.img`
  height: 36px;
  align-self: flex-start;
  margin: -3px 0;
`

const Description = styled.div`
  margin-top: 10px;

  @media (max-width: 950px) {
    text-align: center;
  }
`

const Checkboxes = styled.div`
  margin: 5px auto;
`

const CheckboxGroup = styled.div`
  margin-top: 5px;
  float: left;
`

// const SpecialNote = styled.div`
//   color: #d13636;
//   font-weight: bold;
//   margin: 10px 0 0;
// `

// const QuestionsAboutPricing = styled.div`
//   margin: 10px 0 0;
//   font-style: italic;
// `

const PlanSubHeading = styled.div`
  font-weight: 500;
  margin: 10px 0 5px;
`

const PlanDivider = styled.div`
  width: 1px;
  background: ${({ theme }) => theme.palette.divider};
  margin: 0 20px;

  @media (max-width: 950px) {
    width: auto;
    height: 1px;
    margin: 0 -20px;
  }
`

const SaleImg = styled.img`
  width: 100%;
`

const SaleOnTermButton = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.error.dark};
  color: white;
  border-radius: 3px;
  right: 5px;
  top: -13px;
  padding: 0 5px;
  font-size: 10px;
`

const MyCheckbox = styled(CustomCheckbox)`
  margin: -4px 0;
  white-space: nowrap;

  .MuiFormControlLabel-root {
    margin-right: 10px;
  }

  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: ${({ theme }) => theme.palette.tertiary.main};
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 16px;
  }

  .MuiFormControlLabel-label.Mui-disabled {
    color: inherit;
    margin-left: -2px;
  }
`

const ToolsCheckbox = styled(MyCheckbox)`
  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

const EquipCheckbox = styled(MyCheckbox)`
  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const NeedToLearn = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 10px 20px;
  margin: 20px -20px -20px;
  text-align: center;
`

const NeedToLearnLine1 = styled.div`
  font-weight: 300;
`

const SmallEquipLogoImg = styled.img`
  height: 27px;
  vertical-align: middle;
  margin: -12px -4px -10px;
`

const MyFocusWord = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.tertiary.light};
`

const ToolsFocusWord = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.light};
`

const EquipFocusWord = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
`

const Plans = ({
  selectButtonLabel=i18n("Select"),
  setPlanInfo,
  stripeCurrentSale,
}) => {

  const [ payAnnually, setPayAnnually ] = useToggle(false)

  const { planTermCombos="", imageUrl="" } = (stripeCurrentSale || {}).metadata || {}
  const planTermCombosArray = useMemo(() => planTermCombos.split(' '), [ planTermCombos ])
  const thereIsCurrentlyAMonthlySale = useMemo(() => planTermCombosArray.some(combo => combo.split(':')[1] === 'MONTHLY'), [ planTermCombosArray ])
  const thereIsCurrentlyAnAnnualSale = useMemo(() => planTermCombosArray.some(combo => combo.split(':')[1] === 'ANNUALLY'), [ planTermCombosArray ])

  const myCheckboxLabels = useMemo(() => ([
    [
      i18n("Your Church’s Sermons"),
      i18n("Ministry Channels"),
      i18n("Sermon Bookmarks"),
      i18n("99 Highlighters+"),
    ],
    [
      i18n("Add Notes to Verses"),
      i18n("Save Map Snapshots"),
      i18n("Add Map Notes"),
      i18n("Universal Search"),
    ],
  ]), [])

  const toolsCheckboxLabels = useMemo(() => ([
    [
      i18n("Projects"),
      i18n("Arcing Module"),
      i18n("Bracketing Module"),
      i18n("Phrasing Module"),
    ],
    [
      i18n("Diagramming Module"),
      i18n("Outline Module"),
      i18n("Markup Module"),
      i18n("Easy Sharing"),
    ],
  ]), [])

  const equipCheckboxLabels = useMemo(() => ([
    [
      i18n("LIVE Courses"),
      i18n("Self-Paced Courses"),
      i18n("Personal Coaching"),
      i18n("Assignment Feedback"),
    ],
    [
      i18n("Interactive Quizzes"),
      i18n("Assessments"),
      i18n("Earn Certificates"),
      i18n("200+ Demo Videos"),
    ],
  ]), [])

  const setPayAnnuallyOn = useCallback(() => setPayAnnually(true), [ setPayAnnually ])
  const setPayAnnuallyOff = useCallback(() => setPayAnnually(false), [ setPayAnnually ])
  const goSetPlanInfo = useCallback(
    plan => {
      const term = payAnnually ? 'ANNUALLY' : 'MONTHLY'
      setPlanInfo({
        plan,
        term,
        showApplySaleToCurrent: planTermCombosArray.includes(`${plan}:${term}`),
      })
    },
    [ planTermCombosArray, payAnnually, setPlanInfo ],
  )
  const selectMy = useCallback(() => goSetPlanInfo('MY'), [ goSetPlanInfo ])
  const selectTools = useCallback(() => goSetPlanInfo('TOOLS'), [ goSetPlanInfo ])
  const selectEquip = useCallback(() => goSetPlanInfo('EQUIP'), [ goSetPlanInfo ])

  const getCostExtra = ({ percentage, plan }) => (
    <>
      {payAnnually &&
        <BilledAnnually>
          {i18n("${{amount}} billed annually", { amount: PRICING[plan].ANNUALLY })  /* eslint-disable-line no-template-curly-in-string */}
        </BilledAnnually>
      }
      {!payAnnually &&
        <SaveWithAnnual
          onClick={setPayAnnuallyOn}
        >
          {i18nReact("Save {{percentage}}% with {{annual_billing}}", {
            percentage,
            annual_billing: (
              <AnnualBilling>
                {i18n("annual billing")}
              </AnnualBilling>
            ),
          })}
        </SaveWithAnnual>
      }
    </>
  )

  return (
    <Container>

      <SwitchButtonGroup
        size="small"
      >
        <SwitchButton
          $selected={!payAnnually}
          onClick={setPayAnnuallyOff}
          disabled={!payAnnually}
        >
          {i18n("Pay monthly")}
          {thereIsCurrentlyAMonthlySale && <SaleOnTermButton>{i18n("Sale")}</SaleOnTermButton>}
        </SwitchButton>
        <SwitchButton
          $selected={payAnnually}
          onClick={setPayAnnuallyOn}
          disabled={payAnnually}
        >
          {i18n("Pay annually")}
          {thereIsCurrentlyAnAnnualSale && <SaleOnTermButton>{i18n("Sale")}</SaleOnTermButton>}
        </SwitchButton>
      </SwitchButtonGroup>

      <Box>

        <Plan>
          <MyBiblearcStudyBibleImage src="/my_biblearc_study_bible_2.svg" />
          <Cost>
            {i18nReact("{{amount}} / month", {
              amount: (
                <Price>
                  {
                    i18n("${{amount}}", {  // eslint-disable-line no-template-curly-in-string
                      amount: (payAnnually ? PRICING.MY.ANNUALLY / 12 : PRICING.MY.MONTHLY).toFixed(2),
                    })
                  }
                </Price>
              ),
            })}
          </Cost>
          {getCostExtra({ percentage: 33, plan: 'MY' })}
          <SelectButton
            variant="contained"
            disableElevation
            fullWidth
            onClick={selectMy}
          >
            {selectButtonLabel}
          </SelectButton>
          {planTermCombosArray.includes(`MY:${payAnnually ? `ANNUALLY` : `MONTHLY`}`) &&
            <SaleImg src={imageUrl} className="dark-mode-exempt" />
          }
          <Description>
            {i18nReact("Design a {{personalized_study_bible}} with sermons from your church, ministry channels, and customized maps.", {
              personalized_study_bible: (
                <MyFocusWord>
                  {i18n("personalized study bible")}
                </MyFocusWord>
              ),
            })}
          </Description>
          <Checkboxes>
            {myCheckboxLabels.map((group, idx) => (
              <CheckboxGroup key={idx}>
                {group.map(label => (
                  <MyCheckbox
                    key={label}
                    label={label}
                    checked
                    disabled
                  />
                ))}
              </CheckboxGroup>
            ))}
          </Checkboxes>
        </Plan>

        <PlanDivider />

        <Plan>
          <PlanImage src="/biblearc_tools_2.svg" />
          <Cost>
            {i18nReact("{{amount}} / month", {
              amount: (
                <Price>
                  {
                    i18n("${{amount}}", {  // eslint-disable-line no-template-curly-in-string
                      amount: (payAnnually ? PRICING.TOOLS.ANNUALLY / 12 : PRICING.TOOLS.MONTHLY).toFixed(2),
                    })
                  }
                </Price>
              ),
            })}
          </Cost>
          {getCostExtra({ percentage: 20, plan: 'TOOLS' })}
          <SelectButton
            variant="contained"
            disableElevation
            fullWidth
            onClick={selectTools}
          >
            {selectButtonLabel}
          </SelectButton>
          {planTermCombosArray.includes(`TOOLS:${payAnnually ? `ANNUALLY` : `MONTHLY`}`) &&
            <SaleImg src={imageUrl} className="dark-mode-exempt" />
          }
          <Description>
            {i18nReact("Create {{bible_study_projects}} with graphical study methods and enhanced notes.", {
              bible_study_projects: (
                <ToolsFocusWord>
                  {i18n("Bible study projects")}
                </ToolsFocusWord>
              )
            })}
            {` `}
            {i18n("Great for sermon prep and more.")}
          </Description>
          <Checkboxes>
            {toolsCheckboxLabels.map((group, idx) => (
              <CheckboxGroup key={idx}>
                {group.map(label => (
                  <ToolsCheckbox
                    key={label}
                    label={label}
                    checked
                    disabled
                  />
                ))}
              </CheckboxGroup>
            ))}
          </Checkboxes>
          <PlanSubHeading>
            {i18n("Also Includes:")}
          </PlanSubHeading>
          <IncludesItem src="/my_biblearc_study_bible_1.svg" />
          <NeedToLearn>
            <NeedToLearnLine1>
              {i18n("Need to learn Arcing, Phrasing, etc?")}
            </NeedToLearnLine1>
            {i18nReact("Get {{logo}}", {
              logo: <SmallEquipLogoImg src="/biblearc_equip_1.svg" />
            })}
          </NeedToLearn>
        </Plan>

        <PlanDivider />

        <Plan>
          <PlanImage src="/biblearc_equip_2.svg" />
          <Cost>
            {i18nReact("{{amount}} / month", {
              amount: (
                <Price>
                  {
                    i18n("${{amount}}", {  // eslint-disable-line no-template-curly-in-string
                      amount: (payAnnually ? PRICING.EQUIP.ANNUALLY / 12 : PRICING.EQUIP.MONTHLY).toFixed(2),
                    })
                  }
                </Price>
              ),
            })}
          </Cost>
          {getCostExtra({ percentage: 37, plan: 'EQUIP' })}
          <SelectButton
            variant="contained"
            disableElevation
            fullWidth
            onClick={selectEquip}
          >
            {selectButtonLabel}
          </SelectButton>
          {planTermCombosArray.includes(`EQUIP:${payAnnually ? `ANNUALLY` : `MONTHLY`}`) &&
            <SaleImg src={imageUrl} className="dark-mode-exempt" />
          }
          <Description>
            {i18nReact("{{online_courses}} in Bible study and a video library.", {
              online_courses: (
                <EquipFocusWord>
                  {i18n("Online courses")}
                </EquipFocusWord>
              )
            })}
            {` `}
            {i18n("Learn Arcing, Phrasing, Interpretation, Greek, and much more.")}
          </Description>
          <Checkboxes>
            {equipCheckboxLabels.map((group, idx) => (
              <CheckboxGroup key={idx}>
                {group.map(label => (
                  <EquipCheckbox
                    key={label}
                    label={label}
                    checked
                    disabled
                  />
                ))}
              </CheckboxGroup>
            ))}
          </Checkboxes>
          <PlanSubHeading>
            {i18n("Also Includes:")}
          </PlanSubHeading>
          <IncludesItem src="/my_biblearc_study_bible_1.svg" />
          <IncludesItem src="/biblearc_tools_1.svg" />
        </Plan>

      </Box>

    </Container>
  )
}


export default memo(Plans)