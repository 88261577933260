import { useCallback } from 'react'

const useOnPassageLoadScrollCallback = ({
  ref,
  selector,
}) => {

  const onLoad = useCallback(
    () =>  {
      const highlightedLocEls = [ ...ref.current.querySelectorAll(selector) ]
      if(highlightedLocEls.length > 0) {
        const { top: elFromTop, height: elHeight } = ref.current.getBoundingClientRect()
        const startFromTop = highlightedLocEls[0].getBoundingClientRect().top
        const bottomFromTop = highlightedLocEls.at(-1).getBoundingClientRect().bottom
        if(bottomFromTop - startFromTop < elHeight) {
          ref.current.scrollTop = (startFromTop + bottomFromTop) / 2 - elFromTop - elHeight / 2
        } else {
          ref.current.scrollTop = Math.max(0, startFromTop - elFromTop - 20)
        }
      }
    },
    [ ref, selector ],
  )

  return onLoad

}

export default useOnPassageLoadScrollCallback