import { memo, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'

import { IS_EMBED } from '../../../utils/constants'
import { getEmbedMode, getEmbedSettings, setEmbedFullScreen, setToggleEmbedFullScreen } from '../../../graphql/links/embedLink'
import { HAS_FULLSCREEN_OPTION } from '../../../utils/misc'
import useGoUpdateProject from '../../../hooks/useGoUpdateProject'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import useUpdateEffectAsync from '../../../hooks/useUpdateEffectAsync'

// import Header from "../../common/Header"
import Project from '../project/Project'
import EmbedSettingsIconButton from './EmbedSettingsIconButton'
import EmbedHeader from './EmbedHeader'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  ${({ $embedOverflowHeight }) => !IS_EMBED ? `` : `
    padding-bottom: ${$embedOverflowHeight}px;
  `}

  ${({ $embedFullScreen }) => !$embedFullScreen ? `` : `
    background: white;
  `}

  ${({ $allowRichTextBoxScrolling }) => !$allowRichTextBoxScrolling ? `` : `
    .notes-module > div {
      height: 100%;
    }

    .flipeditor {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .flipeditor-editor-container {
      flex: 1;
      min-height: 0;
      overflow: auto;
    }
  `}

`

const FullScreenButton = styled(Button)`
  background: white;
  position: absolute;
  bottom: ${({ $embedOverflowHeight }) => $embedOverflowHeight + 10}px;
  align-self: center;
  z-index: 20;
  box-shadow:
    0 15px 40px white, 0 15px 40px white,
    15px 10px 40px white, 15px 10px 40px white,
    -15px 10px 40px white, -15px 10px 40px white,
    30px 5px 40px white, 30px 5px 40px white,
    -30px 5px 40px white, -30px 5px 40px white,
    30px 15px 40px white, 30px 15px 40px white,
    -30px 15px 40px white, -30px 15px 40px white
  ;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.faded};
  }
`

const EmbedModules = ({
  embedProjectId,
  embedSettings,
  embedOverflowHeight,
  update,
  ...otherProps
}) => {

  const [ embedFullScreen, toggleEmbedFullScreen ] = useSimpleToggle()

  const [ goCreateProject ] = useGoUpdateProject()

  useLayoutEffectAsync(
    () => {
      if(!embedProjectId) {
        goCreateProject({}, { skipNav: true })
        update()
      }
    },
    [],
  )

  useUpdateEffectAsync(() => setEmbedFullScreen(embedFullScreen), [ embedFullScreen ])

  useLayoutEffect(
    () => {
      setToggleEmbedFullScreen(toggleEmbedFullScreen)
      return () => setToggleEmbedFullScreen()
    },
    [],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  const [ allowRichTextBoxScrolling, setAllowRichTextBoxScrolling ] = useState(false)
  const getAllowRichTextBoxScrolling = useInstanceValue(allowRichTextBoxScrolling)
  useLayoutEffect(
    () => {
      const checkForHeightReduction = () => {
        if(getEmbedSettings().embedType !== `richtextbox`) return
        if(document.body.clientHeight === 499) return  // caused by browser non-render of scroll until iframe is in view; effect is wrongly turn on allowRichTextBoxScrolling

        const editorContainer = document.querySelector(`.DraftEditor-root`)
        if(!editorContainer) return

        const { height } = editorContainer.getBoundingClientRect()
        const CUTOFF_HEIGHT = 36  // The toolbar height - 2 pixel buffer
        const newAllowRichTextBoxScrolling = height + CUTOFF_HEIGHT > document.body.clientHeight

        if(getAllowRichTextBoxScrolling() !== newAllowRichTextBoxScrolling) {
          setAllowRichTextBoxScrolling(newAllowRichTextBoxScrolling)
        }
      }

      const interval = setInterval(checkForHeightReduction, 1000)
      return () => clearInterval(interval)
    },
    [],  // eslint-disable-line react-hooks/exhaustive-deps
  )


  if(!embedProjectId) return <Container />

  return (
    <Container
      $embedOverflowHeight={embedOverflowHeight}
      $embedFullScreen={embedFullScreen}
      $allowRichTextBoxScrolling={allowRichTextBoxScrolling}
    >

      {embedFullScreen &&
        <EmbedHeader
          instructions={embedSettings.instructions || ``}
          toggleEmbedFullScreen={toggleEmbedFullScreen}
        />
      }

      <Project
        {...otherProps}
        embedFullScreen={embedFullScreen}
        embedProjectId={embedProjectId}
        embedSettings={embedSettings}
        embedOverflowHeight={embedOverflowHeight}
      />

      {getEmbedMode() === `prep` &&
        <EmbedSettingsIconButton
          embedSettings={embedSettings}
          embedFullScreen={embedFullScreen}
        />
      }

      {!embedFullScreen && embedSettings.embedType === `modules` &&
        <FullScreenButton
          onClick={toggleEmbedFullScreen}
          variant="outlined"
          $embedOverflowHeight={embedOverflowHeight}
        >
          {HAS_FULLSCREEN_OPTION ? i18n("View Full Screen") : i18n("Enter Full Study Mode")}
        </FullScreenButton>
      }

    </Container>
  )

}

export default memo(EmbedModules)

/*

NOTES FOR MODULES
  - old setup needs to convert to new
    lookup -> notes
    read -> notes
    read (with divisions) -> outline
    scholar search -> initially nothing, switched by hand to a button to a search; an embed solution for easier usage? (e.g.)
  - old data needs to convert to new
  - new setup options
  - new way of doing data that takes new modules to come into account
  - EQUIP pages with Biblearc components of different sorts
    - To keep as-is
      - with read module
        - https://equip.biblearc.com/course/advent2020/63286
        - https://equip.biblearc.com/course/advent2020/62230
        - https://equip.biblearc.com/course/advent2020/62210
        - https://equip.biblearc.com/course/advent2020/62213
      - lookup (sampling)
        - https://equip.biblearc.com/course/interpretation/81107
        - https://equip.biblearc.com/course/interpretation/81717
        - https://equip.biblearc.com/course/person-of-christ/50354
      - outline (sampling)
        - https://equip.biblearc.com/course/paraphrase/29040
    - To change (but preserve previous student work)
      - https://equip.biblearc.com/course/interpretation/81171 (lookup)
    - To change (so as to clear previous student work)
      - https://equip.biblearc.com/course/treasury/84179 (lookup, read for context, notes)
      - https://equip.biblearc.com/course/interpretation/83351 (search example for word studies)
      - https://equip.biblearc.com/course/interpretation/83350 (read x2, lookup)
      - https://equip.biblearc.com/course/interpretation/80433 (search)
      - https://equip.biblearc.com/course/interpretation/83351 (search)
      - https://equip.biblearc.com/course/interpretation/106409 (search x2, notes)
      - https://equip.biblearc.com/course/arcing-2timothy/88325 (search)

      TODO:
        - search in different modes, view only, etc
        - adding other versions: for all types
        - click on page to dismiss popper?? (relevant with passages type, especially when there are multiple poppers; problematic if user wants to view popper contents while writing in answer)
        - get save to TOOLS working
          upon submission, saves a copy in TOOLS via backend (or updates it, when it already exists in TOOLS and is unedited); but only if this component option is on and it has been edited
            can be edited in TOOLS
            annotated in TOOLS as being submitted in whatever lesson/course (and, when relevant, that it has since been edited)
            makes it easy to jump to that course/lesson on EQUIP
        - make viewOnly like optional in terms of required to submit a lesson
        - import a copy of an existing module (option in a spot to replace one that is up without data)
          when clicked: AUTH
            check if the appropriate user is already logged in
              if not
                if logged in as another, log that person out
                log them in automatically by retrieving a login link
          Students can also import an existing module (to replace one that is up without data)
            when clicked... [do as above]
      TEST
        - double check: no editing when frozen
        - fix update bug where going from draft to live removes all modules
        - check that multiple Biblearc tasks on a single page does not corrupt data
        - Make sure I can use view only items on non-assignment pages
        - test on mobile
        - See commented embedFullScreen on usage of <BibleTextContainer>

*/

