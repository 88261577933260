import { useContext } from 'react'
import { StudyBibleItemInfoContext } from '../context/StudyBibleItemInfo'
import { useUnmount } from 'react-use'

const useSetStudyBibleItemInfo = () => {

  const { studyBibleItemInfo, setStudyBibleItemInfo } = useContext(StudyBibleItemInfoContext) || {}

  // For non-channel types (which use the ExpandPopover), we want it to disappear
  // if the component using this hook is unmounted. E.g. They navigate to another page.
  useUnmount(
    () => {
      const { studyBibleItem, anchorEl, onClose } = studyBibleItemInfo || {}
      const isChannelType = (studyBibleItem || {}).type === `CHANNEL`
      if(!isChannelType && anchorEl && onClose) {
        onClose({ keyOverride: `close-without-transition` })
      }
    }
  )

  return setStudyBibleItemInfo
}

export default useSetStudyBibleItemInfo
