import { memo } from 'react'
import styled from 'styled-components'
// import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import FormatSizeIcon from '@material-ui/icons/FormatSize'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'

import useDataQuery from '../../hooks/useDataQuery'
import useIsLoggedIn from '../../hooks/useIsLoggedIn'

import LinkIconButton from './LinkIconButton'
import AccountMenuButton from './AccountMenuButton'
import PassageDisplaySettingsPopover from '../passage/PassageDisplaySettingsPopover'
import AlertsPopover from '../common/AlertsPopover'
// import LinkButton from './LinkButton'

import alertsItemsQuery from '../../graphql/queries/alertsItems'

const StyledLinkIconButton = styled(LinkIconButton)`
  transition: transform .3s ease-in-out;
  position: relative;

  ${({ $inMenu }) => !$inMenu ? `` : `
    padding: 10px;

    .MuiSvgIcon-root {
      font-size: 20px;
    }
  `}

  ${({ $sliderSectionOpen }) => !$sliderSectionOpen ? `` : `
    transform: rotate(180deg);
  `}

  @media print {
    display: none;
  }
`

const StyledNotificationsIcon = styled(NotificationsNoneIcon)`
  color: ${({ theme }) => theme.palette.secondary.main};
`

const StyledFormatSizeIcon = styled(FormatSizeIcon)`
  ${({ $open, theme }) => !$open ? `` : `
    color: ${theme.palette.secondary.main};
  `}
`

const AlertsCount = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
  background-color: ${({ theme, $open }) => $open ? theme.palette.grey[700] : theme.palette.secondary.main};
  border: 1px solid white;
  color: white;
  font-size: 9px;
  font-weight: 700;
  border-radius: 3px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SecondaryHeaderItems = ({
  showBibleDisplaySettings,
  hideAlerts,
  accountMenuButtonReplacement,
  inMenu,
  displaySettingsAnchorEl,
  openDisplaySettings,
  closeDisplaySettings,
  alertsAnchorEl,
  openAlerts,
  closeAlerts,
  toggleDrawerOpen,
}) => {

  const isLoggedIn = useIsLoggedIn()
  // const { adminLevel } = useContext(LoggedInUserContext) || {}

  const { alertsItems: alertsItemsAndCount } = useDataQuery({
    alertsItemsQuery,
    pollInterval: 1000*60,
    skip: !isLoggedIn,
    dataOnError: null,
  })
  const { count: alertsItemsCount=0 } = alertsItemsAndCount || {}

  const NotificationsIconToUse = alertsAnchorEl ? StyledNotificationsIcon : NotificationsNoneIcon

  return (
    <>

      {!!showBibleDisplaySettings &&
        <StyledLinkIconButton
          className="SecondaryHeaderItems-displaySettingsButton"
          onClick={openDisplaySettings}
          $inMenu={inMenu}
        >
          <StyledFormatSizeIcon $open={!!displaySettingsAnchorEl} />
        </StyledLinkIconButton>
      }

      {!hideAlerts && isLoggedIn &&
        <StyledLinkIconButton
          className="SecondaryHeaderItems-alertsButton"
          onClick={openAlerts}
          $inMenu={inMenu}
        >
          <NotificationsIconToUse />
          {!!alertsItemsCount &&
            <AlertsCount $open={!!alertsAnchorEl}>
              {alertsItemsCount}
            </AlertsCount>
          }
        </StyledLinkIconButton>
      }

      {/* <StyledLinkButton
        to="/give-a-gift"
        color="primary"
        variant="contained"
        startIcon={<CardGiftcardIcon />}
        disableElevation
      >
        Give a Gift
      </StyledLinkButton> */}

      {(accountMenuButtonReplacement || <AccountMenuButton inMenu={inMenu} toggleDrawerOpen={toggleDrawerOpen} />)}

      <AlertsPopover
        anchorEl={alertsAnchorEl}
        onClose={closeAlerts}
        inMenu={inMenu}
      />

      <PassageDisplaySettingsPopover
        anchorEl={displaySettingsAnchorEl}
        onClose={closeDisplaySettings}
        inMenu={inMenu}
      />

    </>
  )
}

export default memo(SecondaryHeaderItems)