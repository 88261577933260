import { memo, useCallback, useRef, useMemo } from 'react'
import { i18n, i18nNumber } from "inline-i18n"
import styled from 'styled-components'
import { getLocFromRef } from "@bibletags/bibletags-versification"

import VersesChooserVerse from './VersesChooserVerse'

const Container = styled.div`
  padding: 0 7px;
`

const Chapter = styled.div`
  height: 30px;
  line-height: 15px;
  padding-top: 10px;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.grey[500]};

  ${({ onClick, theme }) => !onClick ? `` : `
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        color: ${theme.palette.grey[900]};
      }
    }
  `}
`

const Verses = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`

const VersesChooserChapter = ({
  bookId,
  chapter,
  startVerse,
  endVerse,
  skippedLocs,
  currentSelectionRefs,
  selectChapter,
  selectVerse,
}) => {

  const ref = useRef()

  const onChapterClick = useCallback(({ shiftKey }) => selectChapter({ chapter, shiftKey }), [ selectChapter, chapter ])

  const verses = useMemo(
    () => (
      Array(endVerse - startVerse + 1)
        .fill()
        .map((x, idx) => ({
          verse: idx + startVerse,
          disabled: skippedLocs.includes(getLocFromRef({ bookId, chapter, verse: idx + startVerse })),
        }))
    ),
    [ skippedLocs, bookId, chapter, startVerse, endVerse ],
  )

  const startLoc = currentSelectionRefs[0] ? getLocFromRef(currentSelectionRefs[0]) : ``
  const endLoc = currentSelectionRefs[1] ? getLocFromRef(currentSelectionRefs[1]) : startLoc

  return (
    <Container>

      <Chapter
        onClick={selectChapter && onChapterClick}
        ref={ref}
      >
        {i18n("Chapter {{number}}", { number: i18nNumber({ num: chapter, type: 'formal' }) })}
      </Chapter>

      <Verses>
        {verses.map(({ verse, disabled }) => {

          const loc = getLocFromRef({
            bookId,
            chapter,
            verse,
          })

          return (
            <VersesChooserVerse
              key={verse}
              chapter={chapter}
              verse={verse}
              isSelected={loc >= startLoc && loc <= endLoc}
              selectVerse={selectVerse}
              disabled={disabled}
            />
          )
        })}
      </Verses>

    </Container>
  )
}

export default memo(VersesChooserChapter)