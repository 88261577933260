import { useMemo } from "react"
import { isValidBibleSearch, isOriginalLanguageSearch } from "@bibletags/bibletags-ui-helper"

import useDataQuery from './useDataQuery'
import useVersionInfos from "./useVersionInfos"
import useAnalytics from './useAnalytics'
import { MAX_NUM_VERSIONS_TO_SEARCH_AT_ONCE } from '../utils/constants'

import bibleSearchResultsQuery from '../graphql/queries/bibleSearchResults'

const limit = 10

const useBibleSearchResults = ({
  searchText,
  skip,
  index=0,
  isInitialSearch=false,
}) => {

  const { nonOrigSelectedVersionInfos } = useVersionInfos({ restrictToTestamentSearchText: searchText })

  const isOrigLanguageSearch = isOriginalLanguageSearch(searchText)

  const { bibleSearchText, includeVersionIds, inVersionAbbrs, inVersionIds } = useMemo(
    () => {

      const versionIdsBySafeVersionAbbr = {}
      nonOrigSelectedVersionInfos.forEach(({ id, safeVersionAbbr }) => {
        versionIdsBySafeVersionAbbr[safeVersionAbbr] = id
      })
      const firstTranslationVersionId = (nonOrigSelectedVersionInfos[0] || {}).id
      let bibleSearchText = searchText
      const includeVersionIds = []
      let inVersionIds = []
      let inVersionAbbrs = []

      if(isOrigLanguageSearch) {
        if(/(?:^| )include:none(?: |$)/i.test(bibleSearchText)) {
          bibleSearchText = bibleSearchText.replace(/(?:^| )include:none(?= |$)/gi, '')
        } else if(/(?:^| )include:[A-Z0-9]{2,9}(?:,[A-Z0-9]{2,9})*(?: |$)/i.test(bibleSearchText)) {
          bibleSearchText = (
            bibleSearchText
              .replace(/((?:^| )include:[A-Z0-9]{2,9}(?:,[A-Z0-9]{2,9})*(?= |$))/gi, match => {
                const lowerCaseMatch = match.toLowerCase()
                includeVersionIds.push(...lowerCaseMatch.split(':')[1].split(','))
                return lowerCaseMatch
              })
          )
        } else if(firstTranslationVersionId) {
          bibleSearchText = `${bibleSearchText} include:${firstTranslationVersionId}`
          includeVersionIds.push(firstTranslationVersionId)
        }
        bibleSearchText = (
          bibleSearchText
            .replace(/((?:^| )in:(?:UHB|UGNT|LXX)(?:,(?:UHB|UGNT|LXX))*(?= |$))/gi, match => {
              inVersionAbbrs.push(...match.toUpperCase().split(':')[1].split(','))
              inVersionIds.push(...match.toLowerCase().split(':')[1].split(','))
              return match.toLowerCase()
            })
        )
      } else {
        bibleSearchText = bibleSearchText.replace(/((?:^| )in:[A-Z0-9]{2,9}(?:,[A-Z0-9]{2,9})*(?= |$))/gi, match => (
          match
            .split(/(in:|,| )/g)
            .map(inItem => {
              if(versionIdsBySafeVersionAbbr[inItem]) {
                inVersionAbbrs.push(inItem.toUpperCase())
                inVersionIds.push(inItem.toLowerCase())
                return versionIdsBySafeVersionAbbr[inItem]
              } else {
                return inItem
              }
            })
            .join('')
          )
        )
        if(inVersionAbbrs.length === 0) {
          const versionInfosToUse = (
            /(?:^| )same:(?:phrase|sentence|paragraph)(?: |$)/i.test(bibleSearchText)
              ? nonOrigSelectedVersionInfos.slice(0,1)
              : nonOrigSelectedVersionInfos.slice(0,MAX_NUM_VERSIONS_TO_SEARCH_AT_ONCE)
          )
          inVersionAbbrs = versionInfosToUse.map(({ safeVersionAbbr }) => safeVersionAbbr)
          inVersionIds = versionInfosToUse.map(({ id }) => id)
          bibleSearchText = `${bibleSearchText} in:${versionInfosToUse.map(({ id }) => id).join(',')}`
        }
      }

      return {
        bibleSearchText,
        includeVersionIds,
        inVersionAbbrs,
        inVersionIds,
      }
    },
    [ searchText, isOrigLanguageSearch, nonOrigSelectedVersionInfos ],
  )

  const { bibleSearchResults, error } = useDataQuery({
    bibleSearchResultsQuery,
    variables: {
      query: bibleSearchText,
      hebrewOrdering: false,
      offset: parseInt(index / limit, 10) * limit,
      limit,
    },
    skip: skip || !isValidBibleSearch({ query: bibleSearchText }) || nonOrigSelectedVersionInfos.length === 0,  // empty selectedVersionInfos indicates we are just loading
    dataOnError: {
      rowCountByBookId: [-1],
    },
  })

  const bibleSearchResult = useMemo(
    () => ((bibleSearchResults || {}).results || [])[index % limit],
    [ bibleSearchResults, index ],
  )

  useAnalytics({
    // get for the first row in each set to prevent duplicate reporting
    eventReady: !!bibleSearchResults && !isInitialSearch && index % limit === 0,
    deps: [ bibleSearchResults ],
    getEventObjs: () => {
      const numHitsByVersionId = {}
      bibleSearchResults.results.forEach(({ versionResults }) => {
        versionResults.slice(0, !isOrigLanguageSearch ? 1 : undefined).forEach(({ versionId }) => {
          numHitsByVersionId[versionId] = numHitsByVersionId[versionId] || 0
          numHitsByVersionId[versionId]++
        })
      })
      return Object.keys(numHitsByVersionId).map(versionId => ({
        action: `Search:${versionId}`,
        value: numHitsByVersionId[versionId],
      }))
    },
  })

  return {
    bibleSearchResults,
    bibleSearchResult,
    includeVersionIds,
    inVersionAbbrs,
    inVersionIds,
    bibleSearchError: error,
  }
}

export default useBibleSearchResults