import { gql } from '@apollo/client'

export default gql
`
  mutation setAccountSettings($input: AccountSettingsInput!, $updatedSince: Milliseconds) {
    setAccountSettings(input: $input, updatedSince: $updatedSince) {
      id
      value
    }
  }
`