import useEqualObjsMemo from "./useEqualObjsMemo"

import { getObjFromArrayOfObjs } from "../utils/misc"

const useObjFromArrayOfObjs = (array, key, valueKey) => {

  const itemsByKey = useEqualObjsMemo(
    () => getObjFromArrayOfObjs(array, key, valueKey),
    [ array, key, valueKey ],
  )

  return itemsByKey
}

export default useObjFromArrayOfObjs