import { memo, useCallback } from 'react'

import { MINIMUM_PASSAGE_COLUMN_WIDTH } from "../../../utils/constants"
import styledResizableDiv from '../../../utils/styledResizableDiv'
import useInstanceValue from "../../../hooks/useInstanceValue"

import PassageArea from "../../passage/PassageArea"
import PassageMinimizedPanel from "../../passage/PassageMinimizedPanel"

const Container = styledResizableDiv``

const ProjectPassageArea = ({
  project,
  getProjectSizes,
  goUpdateProject,
  width,
}) => {

  const setPassageBookmarks = useCallback(
    passageBookmarks => {
      goUpdateProject({
        passageBookmarks,
      })
    },
    [ goUpdateProject ],
  )

  const getPassageBookmarks = useInstanceValue(project.passageBookmarks)

  if(width < MINIMUM_PASSAGE_COLUMN_WIDTH) {
    return (
      <Container $width={width}>
        <PassageMinimizedPanel
          passageBookmarks={project.passageBookmarks}
          goUpdateProject={goUpdateProject}
          getProjectSizes={getProjectSizes}
        />
      </Container>
    )
  }

  return (
    <Container $width={width}>
      <PassageArea
        passageBookmarks={project.passageBookmarks}
        setPassageBookmarks={setPassageBookmarks}
        getPassageBookmarks={getPassageBookmarks}
        allowMultipleColumns={false}
        noMiniHeader
        // display options only across app? Yes, with different text size adj option for Heb and Greek
      />
    </Container>
  )
}


export default memo(ProjectPassageArea)