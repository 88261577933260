import { memo } from 'react'
import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'

import useSetEmbedOnTop from '../../hooks/useSetEmbedOnTop'

const StyledPopover = styled(Popover)`

  ${({ marginThreshold }) => !marginThreshold ? `` : `

    .MuiPopover-paper {
      max-width: calc(100% - ${marginThreshold * 2}px);
      min-width: ${marginThreshold}px;
      max-height: calc(100% - ${marginThreshold * 2}px);
      min-height: ${marginThreshold}px;
    }

  `}

`

const BasicPopover = ({
  open,
  ...otherProps
}) => {

  useSetEmbedOnTop(open)

  return (
    <StyledPopover
      open={open}
      {...otherProps}
    />
  )
}

export default memo(BasicPopover)