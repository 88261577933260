import { useState, useContext } from 'react'
import { getRefFromLoc, getCorrespondingRefs } from '@bibletags/bibletags-versification'
import { useApolloClient } from '@apollo/client'

import { OfflineVersionsContext } from '../context/LocalInfo'
import { OfflineSetupStatusContext } from '../context/LocalInfo'
import { getLXXVersionInfo, getOrigVersionInfo, getVersionIdForBibleTags } from '../utils/misc'
import useEqualObjsMemo from './useEqualObjsMemo'
import useInstanceValue from './useInstanceValue'
import useEffectAsync from './useEffectAsync'

import chapterQuery from '../graphql/queries/chapter'
import versionQuery from '../graphql/queries/version'

const usePreloadChapters = ({
  preloadInfo,
  onInvalidVersionId,
  skip,
}) => {

// alert('redo this with useVersionInfos')
  const client = useApolloClient()

  const offlineVersions = useContext(OfflineVersionsContext)
  const offlineSetupStatus = useContext(OfflineSetupStatusContext)

  const memoedPreloadInfo = useEqualObjsMemo(preloadInfo)
  const getMemoedPreloadInfo = useInstanceValue(memoedPreloadInfo)
  const nonOriginalVersionIds = useEqualObjsMemo([ ...new Set(preloadInfo.map(({ versionId }) => versionId)) ].filter(versionId => versionId !== 'original'))

  const getVersionByIdSync = () => {

    const versionById = {}

    if(nonOriginalVersionIds.every(versionId => {

      const { version } = client.readQuery({
        query: versionQuery,
        variables: {
          id: versionId,
        },
      }) || {}

      versionById[versionId] = version

      return !!version

    })) {
      versionById.original = getOrigVersionInfo()
      versionById.lxx = getLXXVersionInfo()
      return versionById
    }

    return null
  }

  const getBookIdChapterAndVersionIdCombos = ({ versionById }) => (

    // Figure out the chapters needed
    [ ...new Set(
      memoedPreloadInfo
        .map(({ fromLoc, toLoc, versionId }) => {

          const { bookId, chapter: fromChapter } = getCorrespondingRefs({
            baseVersion: {
              ref: getRefFromLoc(fromLoc),
              info: getOrigVersionInfo(),
            },
            lookupVersionInfo: versionById[versionId],
            directionToTryIfSkipped: `next`,
          })[0]

          const { chapter: toChapter } = getCorrespondingRefs({
            baseVersion: {
              ref: getRefFromLoc(toLoc),
              info: getOrigVersionInfo(),
            },
            lookupVersionInfo: versionById[versionId],
            directionToTryIfSkipped: `previous`,
          }).at(-1)

          return Array(toChapter - fromChapter + 1).fill().map((x, idx) => JSON.stringify({
            bookId,
            chapter: fromChapter + idx,
            versionId: getVersionIdForBibleTags({ versionId, bookId }),  // needs bibleTagsVersionId
          }))

        })
        .flat()
    )].map(JSON.parse)

  )

  const getChaptersReadySync = ({ bookIdChapterAndVersionIdCombos }) => (
    bookIdChapterAndVersionIdCombos.every(variables => (
      (client.readQuery({ query: chapterQuery, variables }) || {}).chapter
    ))
  )

  const [ ready, setReady ] = useState(() => {
    if(skip) return false
    const versionById = getVersionByIdSync()
    if(!versionById) return false
    const bookIdChapterAndVersionIdCombos = getBookIdChapterAndVersionIdCombos({ versionById })
    return getChaptersReadySync({ bookIdChapterAndVersionIdCombos })
  })

  useEffectAsync(
    async () => {
      if(skip) return

      let versionById = getVersionByIdSync()

      if(!versionById) {

        setReady(false)

        versionById = {}

        await Promise.all(
          nonOriginalVersionIds.map(async versionId => {

            const { data: { version }={} } = await client.query({
              query: versionQuery,
              variables: {
                id: versionId,
              },
            })

            if(version) {
              versionById[versionId] = version
            } else {
              onInvalidVersionId && onInvalidVersionId({ versionId })
            }

          })
        )

        versionById.original = getOrigVersionInfo()
        versionById.lxx = getLXXVersionInfo()

        if(getMemoedPreloadInfo() !== memoedPreloadInfo) return

      }

      const bookIdChapterAndVersionIdCombos = getBookIdChapterAndVersionIdCombos({ versionById })

      if(!getChaptersReadySync({ bookIdChapterAndVersionIdCombos })) {

        setReady(false)

        await Promise.all(
          bookIdChapterAndVersionIdCombos.map(async variables => {

            await client.query({
              query: chapterQuery,
              variables,
              context: {
                offlineVersions,
                offlineSetupStatus,
              },
            })

          })
        )

        if(getMemoedPreloadInfo() !== memoedPreloadInfo) return

      }

      setReady(true)

    },
    [ skip, memoedPreloadInfo ],
  )

  return ready

}

export default usePreloadChapters