import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

const DEBUG_ON = false

const Container = styled.div`
  position: absolute;
  z-index: 1;
  pointer-events: none;
`

const Rect = styled.div`
  position: absolute;

  ${!DEBUG_ON ? `` : `
    background: rgba(0, 0, 0, .1);
  `}

  ${({ $missingKeyData }) => !$missingKeyData ? `` : `
    background: rgba(255, 255, 0, .3);
  `}
`

const BibleMapPlaceDebuggerRect = ({
  style,
  approxRect,
  missingKeyData,
}) => {

  if(!DEBUG_ON && !missingKeyData) return null

  const {
    x: left,
    y: top,
    width,
    height,  
  } = approxRect

  return (
    <Container
      style={style}
    >
      <Rect
        $missingKeyData={missingKeyData}
        style={{
          left,
          top,
          width,
          height,
        }}
      />
    </Container>
  )
}

export default memo(BibleMapPlaceDebuggerRect)