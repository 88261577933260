import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { useHistory, useLocation } from "react-router-dom"

import {
  MODULE_TYPE_COLOR_MAP,
} from '../../../utils/constants'
import { getModuleTypeLabel } from '../../../utils/misc'

import CustomCheckbox from '../../common/CustomCheckbox'

const Container = styled.div`
  user-select: none;
  padding: 20px 0;
`

const Heading = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`

const ModuleType = styled.span`
  font-weight: bold;
  color: ${({ theme, $moduleType }) => theme.palette[MODULE_TYPE_COLOR_MAP[$moduleType]].main};
`

const studyModuleTypesToInclude = [
  "OUTLINE",
  "MARKUP",
  "DIAGRAMMING",
  "WORD-STUDY",
  "PHRASING",
  "DISCOURSE",
]

const getModuleTypes = search => (new URLSearchParams(search).get(`moduleType`) || ``).split(' ').filter(Boolean)

const ProjectsRightPanelModules = ({ ...props }) => {

  const history = useHistory()
  const location = useLocation()

  const moduleTypes = getModuleTypes(location.search)

  const toggleCheckbox = useCallback(
    ({ target }, checked) => {
      const moduleType = target.closest('.MuiFormControlLabel-root').getAttribute('data-moduletype')
      const searchParams = new URLSearchParams(window.location.search)
      const moduleTypes = getModuleTypes(window.location.search).filter(mType => mType !== moduleType)

      if(checked) {
        moduleTypes.push(moduleType)
      }

      if(moduleTypes.length > 0) {
        searchParams.set(`moduleType`, moduleTypes.join(' '))
      } else {
        searchParams.delete(`moduleType`)
      }

      history.replace({
        // pathname: window.location.pathname,
        search: searchParams.toString(),
      })
    },
    [ history ],
  )

  return (
    <Container>

      <Heading>
        {i18n("Study Modules")}
      </Heading>

      {studyModuleTypesToInclude.map(moduleType => (
        <CustomCheckbox
          key={moduleType}
          checked={moduleTypes.includes(moduleType)}
          onChange={toggleCheckbox}
          color="default"
          label={
            <ModuleType $moduleType={moduleType}>
              {getModuleTypeLabel({ type: moduleType })}
            </ModuleType>
          }
          data-moduletype={moduleType}
        />
      ))}

    </Container>
  )
}

export default memo(ProjectsRightPanelModules)