import { i18n } from 'inline-i18n'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import InsertLinkIcon from '@material-ui/icons/InsertLink'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import MinimizeIcon from '@material-ui/icons/Minimize'

const blockSection = {
  type: 'block',
  activeTabMode: 'block',
  style: { flexDirection: 'row' },
  shrinkMode: 'first',
  instructions: i18n("Tab"),
  buttons: [
    {
      label: 'H1',
      style: { fontWeight: 900 },
      //icon: 'looks_one',
      type: 'header-one',
      lang: {
        en: 'Section Heading',
      },
      group: 'header',
    },
    {
      label: 'H2',
      style: { fontWeight: 700 },
      // icon: 'looks_two',
      type: 'header-two',
      lang: {
        en: 'Major Heading',
      },
      group: 'header',
    },
    {
      label: 'H3',
      style: { fontWeight: 500 },
      // icon: 'looks_3',
      type: 'header-three',
      lang: {
        en: 'Minor Heading',
      },
      group: 'header',
    },
    {
      label: 'H4',
      style: { fontWeight: 400 },
      // icon: 'looks_4',
      type: 'header-four',
      lang: {
        en: 'Alternate Major Heading',
      },
      group: 'header',
    },
    {
      label: 'H5',
      style: { fontWeight: 300 },
      // icon: 'looks_5',
      type: 'header-five',
      lang: {
        en: 'Alternate Minor Heading',
      },
      group: 'header',
    },
    {
      label: 'H6',
      style: { fontWeight: 100 },
      // icon: 'looks_6',
      type: 'header-six',
      lang: {
        en: 'Attribution',
      },
      group: 'header',
    },
  ]
}

const inlineSection = {
  type: 'inline',
  activeTabMode: 'inline',
  style: { flexDirection: 'column' },
  shrinkMode: 'never',
  instructions: i18n("Tab"),
  buttons: [
    {
      Icon: FormatBoldIcon,
      type: 'BOLD',
      lang: {
        en: 'Bold',
      },
    },
    {
      Icon: FormatItalicIcon,
      type: 'ITALIC',
      lang: {
        en: 'Italic',
      },
    },
    // {
    //   icon: 'settings_ethernet',
    //   type: 'CODE',
    // },
    // {
    //   icon: 'format_strikethrough',
    //   type: 'STRIKETHROUGH',
    // },
    // {
    //   icon: 'format_underline',
    //   type: 'UNDERLINE',
    // },
    {
      Icon: InsertLinkIcon,
      type: 'LINK',
      lang: {
        en: 'Link',
      },
      isEntity: true,
    },
    // {
    //   icon: 'info',
    //   type: 'TERM',  // mouse-over/tap reveals tooltip; typcially indicated via dotted underline; a style may also display as footnotes
    // },
    // {
    //   icon: 'format_color_text',
    //   iconWithColor: true,
    //   type: 'COLOR1',
    //   group: 'color',
    // },
    // {
    //   icon: 'format_color_text',
    //   iconWithColor: true,
    //   type: 'COLOR2',
    //   group: 'color',
    // },
    // {
    //   icon: 'format_color_text',
    //   iconWithColor: true,
    //   type: 'COLOR3',
    //   group: 'color',
    // },
    // {
    //   icon: 'format_color_text',
    //   iconWithColor: true,
    //   type: 'COLOR4',
    //   group: 'color',
    // },
    // {
    //   icon: 'format_color_text',
    //   iconWithColor: true,
    //   type: 'COLOR5',
    //   group: 'color',
    // },
    // {
    //   icon: 'format_color_fill',
    //   iconWithColor: true,
    //   type: 'BGCOLOR1',
    //   group: 'bgcolor',
    // },
    // {
    //   icon: 'format_color_fill',
    //   iconWithColor: true,
    //   type: 'BGCOLOR2',
    //   group: 'bgcolor',
    // },
    // {
    //   icon: 'format_color_fill',
    //   iconWithColor: true,
    //   type: 'BGCOLOR3',
    //   group: 'bgcolor',
    // },
    // {
    //   icon: 'format_color_fill',
    //   iconWithColor: true,
    //   type: 'BGCOLOR4',
    //   group: 'bgcolor',
    // },
    // {
    //   icon: 'format_color_fill',
    //   iconWithColor: true,
    //   type: 'BGCOLOR5',
    //   group: 'bgcolor',
    // },
    // For superscript and subscript, have a way for the user to type the appropriate unicode characters 
    // (https://en.wikipedia.org/wiki/Unicode_subscripts_and_superscripts)
    // OR
    // Use <sup> and <sub>
    // ?
  ]
}

const specialBlockSection = {
  type: 'specialBlock',
  activeTabMode: 'block',
  style: { flexDirection: 'column' },
  instructions: '',  // see below
  buttons: [
    {
      Icon: FormatQuoteIcon,
      instructionsKey: '”',
      type: 'blockquote',
      lang: {
        en: 'Block Quote',
      },
    },
    {
      Icon: FormatListBulletedIcon,
      instructionsKey: '*',
      type: 'unordered-list-item',
      lang: {
        en: 'Bullet Points',
      },
    },
    {
      Icon: FormatListNumberedIcon,
      instructionsKey: '1',
      type: 'ordered-list-item',
      lang: {
        en: 'Numbered List',
      },
    },
    {
      Icon: MinimizeIcon,
      instructionsKey: 'fn',
      type: 'aside',  // i.e. a footnote
      lang: {
        en: 'Footnote',
      },
    },
    // {
    //   icon: 'code',
    //   instructionsKey: '<>',
    //   type: 'code-block',
    // },
    // {
    //   icon: 'remove',
    //   type: 'horizontal-rule',
    // },
    // {
    //   icon: 'table_chart',
    //   customType: 'table',  // does not natively exist
    // },
    // {
    //   icon: 'functions',
    //   customType: 'tex',  // see the draft-js example
    // },
  ]
}

const keys = specialBlockSection.buttons.map(button => button.instructionsKey)
specialBlockSection.instructions =
  keys.length > 1
    ? i18n(
      'Type {{all_but_last_key}} or {{last_key}}',
      {
        all_but_last_key: keys.slice(0, -1).join(' '),
        last_key: keys.slice(-1)[0],
      }
    )
    : i18n(
      'Type {{key}}',
      {
        key: keys[0],
      }
    )

const emoticonSection = {
  type: 'emoticon',
  instructions: i18n("Tab"),
  buttons: []
}

export default [ blockSection, inlineSection, specialBlockSection ]