import { useState } from 'react'
import { StyleSheet, Document, Page, View, Image, usePDF } from '@react-pdf/renderer'

import { downloadLink } from '../utils/misc'
import useEffectAsync from './useEffectAsync'

let printIframe

const pdfStyles = StyleSheet.create({
  view: {
    width: `100%`,
    height: `100%`,
    paddingBottom: 0,
  },
  img: {
    width: `100%`,
    objectFit: 'cover',
    objectPosition: 'left top',
  },
})

const useCreateAndActionPdf = ({
  pageSize,
  margins,
  orientation,
}) => {

  const [ actionInfo, setActionInfo ] = useState()
  const { headerDataUrl, dataUrls } = actionInfo || {}

  const document = (
    dataUrls
      ? (
        <Document>
          {dataUrls.map((dataUrl, idx) => (
            <Page
              key={idx}
              size={pageSize.toUpperCase()}
              wrap={false}
              orientation={orientation}
            >
              <View
                style={[
                  {
                    padding: margins,
                  },
                  pdfStyles.view,
                ]}
              >
                {idx === 0 &&
                  <Image
                    style={pdfStyles.img}
                    src={headerDataUrl}
                  />
                }
                <Image
                  style={pdfStyles.img}
                  src={dataUrl}
                />
              </View>
            </Page>
          ))}
        </Document>
      )
      : null
  )

  const [ instance, update ] = usePDF({ document })

  useEffectAsync(update, [ dataUrls ])

  useEffectAsync(
    () => {
      if(instance.url && actionInfo) {

        const { action, name, close } = actionInfo

        if(action === `print`) {
          if(!printIframe) {
            printIframe = window.document.createElement('iframe')
            window.document.body.appendChild(printIframe)
            printIframe.style.display = 'none'
            printIframe.onload = () => {
              setTimeout(() => {
                printIframe.focus()
                printIframe.contentWindow.print()
                setTimeout(() => {
                  // This timeout (mostly) keeps the print dialog up until it is actually ready to print.
                  // It certainly works better than print-js or using onafterprint or window.matchMedia('print').
                  // In unsatisfied, I could try waiting for a focus back on the main window or checking via a timeout every 100ms
                  setActionInfo()
                  close && close()
                })
              }, 1)
            }
          }
          printIframe.src = instance.url
        } else {
          downloadLink({
            href: instance.url,
            name: `${name}.pdf`,
          })
          setActionInfo()
          close && close()
        }

      }
    },
    [ instance.url ],
  )

  return {
    createAndActionPdf: setActionInfo,
    creatingPdf: !!actionInfo,
  }

}

export default useCreateAndActionPdf