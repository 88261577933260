import { memo, useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { useApolloClient } from '@apollo/client'

import useRefState from '../../hooks/useRefState'
import useMutationContext from '../../hooks/useMutationContext'

import FadedLoading from '../common/FadedLoading'
import StudyBibleFollowAddBroadcaster from './StudyBibleFollowAddBroadcaster'
import StudyBibleFollowAddSpeaker from './StudyBibleFollowAddSpeaker'

import sermonAudioChannelsQuery from '../../graphql/queries/sermonAudioChannels'

const SermonAudio = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 191px;
  max-height: min(500px, 100dvh - 300px);
`

const SermonAudioLogo = styled.img`
  height: 20px;
  margin: 50px 0 0;
  align-self: center;
  opacity: .3;
`

const StyledTextField = styled(TextField)`
  margin-top: 15px;

  .MuiInputBase-root {
    padding-right: 5px;
  }
`

const SermonAudioSearchResults = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
  margin: 0 -20px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const None = styled.div`
  font-style: italic;
  text-align: center;
  padding-top: 50px;
`

const Error = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-style: normal;
`

const ResultSection = styled.div`
`

const ResultHeading = styled.div`
  margin: 15px 20px 5px;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
`

const StudyBibleFollowAddSermonAudio = ({
  setChannel,
}) => {

  const [ search, setSearch, getSearch ] = useRefState(``)
  const [ searching, setSearching ] = useState(false)
  const [ error, setError ] = useState()
  const [ broadcasters, setBroadcasters ] = useState()
  const [ speakers, setSpeakers ] = useState()

  const client = useApolloClient()
  const context = useMutationContext()

  const onChangeSearch = useCallback(({ target }) => setSearch(target.value), [ setSearch ])

  const goSearch = useCallback(
    async () => {

      setError()

      let query = getSearch().replace(/  +/g, ` `).trim()

      if(query.length < 3) {
        setError(i18n("Enter at least 3 characters"))
        query = ``
      }

      if(!query) {
        setBroadcasters()
        setSpeakers()
        return
      }

      setSearching(true)

      try {

        const { data: { sermonAudioChannels } } = await client.query({
          query: sermonAudioChannelsQuery,
          variables: {
            query,
          },
          context,
        })

        setBroadcasters(sermonAudioChannels.broadcasters)
        setSpeakers(sermonAudioChannels.speakers)

      } catch(err) {
        console.error(err)
        setBroadcasters()
        setSpeakers()
        setError(`SermonAudio search error`)
        setSearching(false)
      }

      setSearching(false)

    },
    [ getSearch, client, context ],
  )

  const onKeyDownSearch = useCallback(
    async event => {

      if(event.key === `Enter`) {
        goSearch()
      }

    },
    [ goSearch ],
  )

  return (
    <SermonAudio>

      <StyledTextField
        label={i18n("Search for a church or speaker")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={goSearch}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        variant="outlined"
        value={search}
        onChange={onChangeSearch}
        onKeyDown={onKeyDownSearch}
        autoFocus
        color="secondary"
      />

      <SermonAudioSearchResults>

        {!!error &&
          <Error>
            {error}
          </Error>
        }

        {!!broadcasters && !!speakers && broadcasters.length + speakers.length === 0 &&
          <None>
            {i18n("None found.")}
          </None>
        }

        {!broadcasters && !speakers && !error &&
          <SermonAudioLogo src="/sermonaudio.svg" />
        }

        {(broadcasters || []).length > 0 &&
          <ResultSection>

            <ResultHeading>
              {i18n("Church or Ministry")}
            </ResultHeading>

            {broadcasters.map(broadcaster => (
              <StudyBibleFollowAddBroadcaster
                key={broadcaster.broadcasterID}
                broadcaster={broadcaster}
                onSelect={setChannel}
              />
            ))}

          </ResultSection>
        }

        {(speakers || []).length > 0 &&
          <ResultSection>

            <ResultHeading>
              {i18n("Speaker")}
            </ResultHeading>

            {speakers.map(speaker => (
              <StudyBibleFollowAddSpeaker
                key={speaker.speakerID}
                speaker={speaker}
                onSelect={setChannel}
              />
            ))}

          </ResultSection>
        }

        {searching && <FadedLoading size={30} />}

      </SermonAudioSearchResults>

    </SermonAudio>
  )
}

export default memo(StudyBibleFollowAddSermonAudio)