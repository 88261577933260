import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import SettingsIcon from '@material-ui/icons/Settings'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import useFormattingKeys from '../../../hooks/useFormattingKeys'
import useSimpleToggle from '../../../hooks/useSimpleToggle'

import LensSettingsDialog from './LensSettingsDialog'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 34px;
`

const DotsContainer = styled.div`
  min-width: 0;
  flex: 1;
  overflow: hidden;
`

const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $condensedMode }) => $condensedMode ? `flex-start` : `center`};
  padding: ${({ $inEditingMode, $condensedMode }) => ($inEditingMode || $condensedMode) ? `0 3px 0 12px` : `0 3px`};
  transition: transform .2s ease-in-out;
  ${({ $inEditingMode, $numLenses, $pageKeyIndex }) => {
    const maxShift = $inEditingMode ? (($numLenses-10) * -19 - 11) : (($numLenses-12) * -19 - 13)
    return `
      transform: translateX(${$numLenses >= 10 ? Math.min(Math.max(Math.max($pageKeyIndex - 5, 0) * -19, maxShift), 0) : 0}px);
    `
  }}
`

const Spacer = styled.div`
  width: 35px;
`

const DotContainer = styled.div`
  padding: 9px;
  border-radius: 100px;
  transition: background-color .15s ease-in-out;
  margin: 0 -2.5px;

  ${({ $selected, theme }) => $selected ? `` : `
    &:hover {
      cursor: pointer;
      background-color: ${theme.palette.grey[200]};
    }
  `}
`

const Dot = styled.div`
  width: 6px;
  height: 6px;
  ${({ $selected }) => !$selected ? `` : `
    transform: scale(1.5);
  `}
  border-radius: 10px;
  background-color: ${({ theme, $selected }) => theme.palette.grey[$selected ? 900 : 300]};
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
`

const StyledIconButton = styled(IconButton)`
  padding: 7px;
  margin: 0 5px;

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const MarkupKeyFooter = ({
  pageKeyIndex,
  filteredFormattingKeyInfos,
  formattingKeyInfosSetting,
  projectId,
  inEditingMode,
  setCurrentLensId,
}) => {

  const { formattingKeysById } = useFormattingKeys()
  const [ settingsOpen, toggleSettingsOpen ] = useSimpleToggle()

  const onDotClick = useCallback(
    ({ target }) => {
      const formattingKeyId = target.closest(`[data-id]`).getAttribute('data-id')
      setCurrentLensId(formattingKeyId)
    },
    [ setCurrentLensId ],
  )

  const condensedMode = filteredFormattingKeyInfos.length > (inEditingMode ? 7 : 11)

  return (
    <Container>

      {!condensedMode && inEditingMode && <Spacer />}

      <DotsContainer>
        <Dots
          $condensedMode={condensedMode}
          $pageKeyIndex={pageKeyIndex}
          $numLenses={filteredFormattingKeyInfos.length}
          $inEditingMode={inEditingMode}
        >
          {filteredFormattingKeyInfos.map(({ id }, idx) => (
            <Tooltip
              key={id}
              title={idx === pageKeyIndex ? `` : ((formattingKeysById[id] || {}).name || ``)}
            >
              <DotContainer
                $selected={idx === pageKeyIndex}
                onClick={onDotClick}
                data-id={id}
              >
                <Dot $selected={idx === pageKeyIndex} />
              </DotContainer>
            </Tooltip>
          ))}
        </Dots>
      </DotsContainer>

      {inEditingMode &&
        <>

          <Tooltip
            title={i18n("Manage Lenses", "", "markup")}
          >
            <StyledIconButton
              onClick={toggleSettingsOpen}
              tabIndex={-1}
            >
              <SettingsIcon />
            </StyledIconButton>
          </Tooltip>

          <LensSettingsDialog
            open={settingsOpen}
            onDone={toggleSettingsOpen}
            filteredFormattingKeyInfos={filteredFormattingKeyInfos}
            formattingKeyInfosSetting={formattingKeyInfosSetting}
            projectId={projectId}
          />

        </>
      }

    </Container>
  )
}

export default memo(MarkupKeyFooter)