import { useCallback } from 'react'

import useShortcuts from './useShortcuts'

import keyDownP from '../components/pages/project/shortcuts/keyDownP'

const keyDownFuncs = {
  keyDownP,
}

const keysToUseWithNonModuleFocusOnly = [
  "p",
]

const useProjectShortcuts = params => {

  const { printOrDownloadDialogOpen } = params

  const inCorrectContext = useCallback(
    event => {

      // check that we are not focused on a module
      if(
        keysToUseWithNonModuleFocusOnly.includes(event.key)
        && document.activeElement
        && document.activeElement.closest('[data-module-id], .notes-module')
      ) return false

      if(printOrDownloadDialogOpen && event.key !== `p`) return false

      return true

    },
    [ printOrDownloadDialogOpen ],
  )

  useShortcuts({
    keyDownFuncs,
    inCorrectContext,
    ...params,
  })

}

export default useProjectShortcuts