import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import MarkdownContainer from '../common/MarkdownContainer'

const Container = styled(MarkdownContainer)`
  padding: 0 15px 15px;
  font-size: ${({ $fromThumbnail }) => $fromThumbnail ? 15 : 16}px;
  flex: 1;
`

const StudyBiblePage = ({
  addQuotes,
  detail,
  fromThumbnail,
  className=``,
  ...otherProps
}) => {

  // const { text=``, info={}, ordering=0 } = detail
  const { text=`` } = detail

  return (
    <Container
      className={`StudyBiblePage-Container ${className}`}
      $fromThumbnail={fromThumbnail}
      {...otherProps}
    >
      {addQuotes ? i18n("“{{quote}}”", { quote: text }) : text}
    </Container>
  )
}

export default memo(StudyBiblePage)