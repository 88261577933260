import { useLayoutEffect } from 'react'

const useLayoutEffectAsync = (func, deps) => {

  useLayoutEffect(
    () => {
      func()
    },
    deps,  // eslint-disable-line react-hooks/exhaustive-deps
  )

}

export default useLayoutEffectAsync
