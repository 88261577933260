import { memo, useEffect, useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import { blurActiveElement, getBoundedValue } from '../../utils/misc'
import { getFinalPositionInfo, getMovingPositionInfo } from '../../hooks/useDotNotes'
import useGoUpdateModuleDot from '../../hooks/useGoUpdateModuleDot'
import useModalAnchor from '../../hooks/useModalAnchor'

import DotPopper from './DotPopper'

export const DOT_SIZE = 34

const StyledDiv = styled.div`
  position: absolute;
  background-color: ${({ $color, theme }) => (theme.palette.markupColors[$color] || theme.palette.markupColors.GREY).highlight};
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  border-radius: 100px;
  border: 2px solid rgb(255 255 255/0.75);
  box-shadow: 0 0 10px rgb(0 0 0/0.15);
  z-index: ${({ $open }) => $open ? 3 : 1};
  cursor: ${({ $moving }) => $moving ? `none` : `pointer`};
  touch-action: none;  // prevent scrolling while dragging
`

const Cover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgb(255 255 255/.65);
  cursor: default;
`

const Dot = ({
  projectId,
  moduleDot,
  containerRef,
  setPlacingInfo,
  inEditingMode,
  moduleWidth,
}) => {

  const [ style, setStyle ] = useState()
  const adjustments = useRef({})
  const startMoveEvent = useRef()
  const lastMoveEvent = useRef()
  const isMove = useRef(false)
  const ref = useRef()

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const [ goUpdateModuleDot, goDeleteModuleDot ] = useGoUpdateModuleDot({
    projectId,
    moduleDot: (
      moduleDot.unalteredModuleDot
      || moduleDot
    ),
  })

  const move = useCallback(
    event => {

      let positionInfo = getMovingPositionInfo(event, moduleDot.positionInfo, adjustments.current)

      isMove.current = (
        isMove.current
        || (
          startMoveEvent.current
          && (
            Math.sqrt(
              Math.abs(startMoveEvent.current.clientY - event.clientY) ** 2
              + Math.abs(startMoveEvent.current.clientX - event.clientX) ** 2
            ) >= 2
          )
        )
      )

      if(!startMoveEvent.current) {
        startMoveEvent.current = event
        const { left, top, width, height } = event.target.getBoundingClientRect()
        adjustments.current = {
          x: (left + width/2) - event.clientX,
          y: (top + height/2) - event.clientY,
        }
        positionInfo = getMovingPositionInfo(event, moduleDot.positionInfo, adjustments.current)
      }

      lastMoveEvent.current = event

      if(inEditingMode && isMove.current)  {
        setPlacingInfo({
          ...moduleDot,
          positionInfo,
        })
      }

    },
    [ moduleDot, setPlacingInfo, inEditingMode ],
  )

  const done = useCallback(
    () => {
      setPlacingInfo()
      if(isMove.current && lastMoveEvent.current) {
        const positionInfo = getFinalPositionInfo(lastMoveEvent.current, moduleDot.positionInfo, adjustments.current)
        if(positionInfo && inEditingMode) {
          goUpdateModuleDot({
            positionInfo,
          })
        }
      } else if(anchorEl) {
        closeModal()
      } else {
        openModal({ currentTarget: ref.current })
      }
      startMoveEvent.current = null
      lastMoveEvent.current = null
      isMove.current = false
      adjustments.current = {}
    },
    [ goUpdateModuleDot, setPlacingInfo, moduleDot, openModal, anchorEl, closeModal, inEditingMode ],
  )

  const onTouchStart = useCallback(
    event => {

      blurActiveElement()
      event.preventDefault()

      const onTouchEnd = event2 => {
        event2.preventDefault()
        document.body.removeEventListener('touchmove', onTouchMove)
        document.body.removeEventListener('touchend', onTouchEnd)
        document.body.removeEventListener('touchcancel', onTouchEnd)
        done()
      }

      const onTouchMove = ({ touches }) => {
        if(touches.length !== 1) {
          onTouchEnd()
        } else {
          move(touches[0])
        }
      }

      document.body.addEventListener('touchmove', onTouchMove)
      document.body.addEventListener('touchend', onTouchEnd)
      document.body.addEventListener('touchcancel', onTouchEnd)

    },
    [ done, move ],
  )

  const onMouseDown = useCallback(
    event => {

      blurActiveElement()
      event.preventDefault()

      const onMouseUp = () => {
        document.body.removeEventListener('mousemove', move)
        document.body.removeEventListener('mouseup', onMouseUp)
        document.body.removeEventListener('mouseleave', onMouseUp)
        done()
      }

      document.body.addEventListener('mousemove', move)
      document.body.addEventListener('mouseup', onMouseUp)
      document.body.addEventListener('mouseleave', onMouseUp)

    },
    [ done, move ],
  )

  // when DOM within the container changes, check to see if any elements need updating
  useEffect(
    () => {

      const containerEl = containerRef.current

      const determinePosition = () => {
        if(!containerRef.current) return

        const { selector, fractionTop, fractionLeft } = moduleDot.positionInfo || {}
        const containerEl = containerRef.current
        const containerElRect = containerEl.getBoundingClientRect()
        const moduleDotSelectorEl = containerEl.matches(selector) ? containerEl : containerEl.querySelector(selector)

        let top, left

        const setTopLeft = (el, placeBelow) => {
          const elRect = (el || containerEl).getBoundingClientRect()
          top = elRect.top - containerElRect.top + (fractionTop * elRect.height) - DOT_SIZE/2 + (placeBelow ? elRect.height : 0)
          left = elRect.left - containerElRect.left + (fractionLeft * elRect.width) - DOT_SIZE/2
        }

        if(moduleDotSelectorEl) {

          setTopLeft(moduleDotSelectorEl)

        } else if(/^\[data-word-loc="[^"]+"\]$/.test(selector)) {

          const loc = selector.replace(/^\[data-word-loc="|:[0-9]+"\]$/g, ``)
          const lastWordInVerseModuleDotSelectorEl = [ ...containerEl.querySelectorAll(`[data-word-loc^="${loc}:"]`) ].at(-1)
          const firstWordEl = containerEl.querySelector(`[data-word-loc]`)
          const firstLoc = firstWordEl && firstWordEl.getAttribute(`data-word-loc`).replace(/^\[data-word-loc="|:[0-9]+"\]$/g, ``)

          if(lastWordInVerseModuleDotSelectorEl) {
            // put on last word of the verse if verse still in passage
            setTopLeft(lastWordInVerseModuleDotSelectorEl)
          } else if(loc < firstLoc) {
            // put on first word of the passage if it comes before
            setTopLeft(firstWordEl)
          }

        }

        if(!top) {
          // put after passage if all else fails
          setTopLeft(
            [ ...containerEl.querySelectorAll(`[data-word-loc], [data-dot-container]`) ].pop(),
            true
          )
        }

        top = getBoundedValue(top, { min: 0, max: containerElRect.height - DOT_SIZE })
        left = getBoundedValue(left, { min: 0, max: containerElRect.width - DOT_SIZE })

        setStyle({
          left,
          top,
        })
      }

      const resizeObserver = new ResizeObserver(determinePosition)
      const mutationObserver = new MutationObserver(determinePosition)

      resizeObserver.observe(document.body)
      resizeObserver.observe(containerEl)
      mutationObserver.observe(
        containerEl,
        {
          subtree: true,
          childList: true,
          attributes: true,
          characterData: true,
        },
      )

      determinePosition()

      return () => {
        resizeObserver.unobserve(containerEl)
        mutationObserver.disconnect()
      }
    },
    [ moduleDot, containerRef.current ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  if(!style) return null

  return (
    <>

      {!!anchorEl && <Cover onClick={closeModal} />}

      <StyledDiv
        id={`Dot-StyledDiv-${moduleDot.id || `new`}`}
        ref={ref}
        $color={moduleDot.color}
        $moving={!!lastMoveEvent.current}
        $open={!!anchorEl}
        style={style}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        className="Dot-StyledDiv fade-when-passage-popper-open"
      />

      <DotPopper
        anchorEl={anchorEl}
        onClose={closeModal}
        moduleDot={moduleDot}
        goUpdateModuleDot={goUpdateModuleDot}
        goDeleteModuleDot={goDeleteModuleDot}
        inEditingMode={inEditingMode}
        moduleWidth={moduleWidth}
      />

    </>
  )
}

export default memo(Dot)