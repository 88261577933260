import { gql } from '@apollo/client'

import channel_fields from '../fragments/channel_fields'

export default gql
`
  mutation createChannel($input: CreateChannelInput!) {
    createChannel(input: $input) {
      ${channel_fields}
    }
  }
`